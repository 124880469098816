import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateProductSpec";
import ConfirmDelete from "./models/ConfirmDelete";
import Edit from "./models/EditProductSpec";
import {
  PRODUCT_SPEC_REQUEST,
  PRODUCT_SPEC_CREATE,
  PRODUCT_SPEC_DELETE,
  PRODUCT_SPEC_EDIT,
  PRODUCT_SPEC_STATE_CHANGE,
  PRODUCT_INFO_REQUEST,

} from "../../../../_utils/constants/ProductCatalog";

import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const ProductSpec = ({
  auth: { auth, isAuthenticated },
  ProductSpec: { valueList, isCreated, isDeleted, isEdited, isLoading },
  ProductInfo,
  fetchProductSpec,
  submitProductSpecCreate,
  submitProductSpecDelete,
  submitProductSpecEdit,
  changeReducerState,
  fetchProductInfo,
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [salesProductSpecName, setProductSpecName] = useState('');
  const [sortOrder, setSortOrder] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteProductSpecId, setDeleteProductSpecId] = useState('');
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState("");
  const [state, setState] = useState({
    "productInfoId": "",
    "unitPer1": "",
    "unitPer2": "",
    "cashBack": "",
    "buyingGroup": "",
    "royality": "",
    "tariff": "",
    "stocking": "",
    "custom": "",
    "fOB": "",
    "qualityRating": "",
    "warrantly": "",
    "altId": "",
    "productSourceId": "",
    "thickness": "",
    "iCN": "",
    "spreadRate": "",
    "privateLabelStyle": "",
    "privateLabelCollection": "",
    "display": "",
    "taxCode": "",
    "auditText": "",
    "isArchive": "",
  }
  )
  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchProductSpec(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      fetchProductInfo(auth[0].token);
    }
  }, [auth, isAuthenticated]);


  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("ProductSpec Created Successfully.");
      fetchProductSpec(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("ProductSpec Deleted Successfully.");
      fetchProductSpec(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("ProductSpec Data Updated Successfully.");
      fetchProductSpec(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value })

  }

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
    setShow(false)
    setIds("");
    setState({
      "productInfoId": "",
      "unitPer1": "",
      "unitPer2": "",
      "cashBack": "",
      "buyingGroup": "",
      "royality": "",
      "tariff": "",
      "stocking": "",
      "custom": "",
      "fOB": "",
      "qualityRating": "",
      "warrantly": "",
      "altId": "",
      "productSourceId": "",
      "thickness": "",
      "iCN": "",
      "spreadRate": "",
      "privateLabelStyle": "",
      "privateLabelCollection": "",
      "display": "",
      "taxCode": "",
      "auditText": "",
      "isArchive": "",
    })
  };

  // function called for input value change of create modal
  const handleSalesStageNameChange = (e) => {
    setProductSpecName(e.target.value);
  }
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (state.unitPer1 === '') {
      toast.error("UnitPer1 cannot be empty.");
    } else {
      submitProductSpecCreate({
        "data": {
          "ProductInfoId": parseInt(state.productInfoId),
          "UnitPer1": parseFloat(state.unitPer1),
          "UnitPer2": parseFloat(state.unitPer2),
          "CashBack": state.cashBack,
          "BuyingGroup": state.buyingGroup,
          "Royality": state.royality,
          "Tariff": state.tariff,
          "Stocking": state.stocking,
          "Custom": state.custom,
          "FOB": state.fOB,
          "QualityRating": state.qualityRating,
          "Warrantly": state.warrantly,
          "AltId": state.altId,
          "ProductSourceId": state.productSourceId,
          "Thickness": state.thickness,
          "ICN": state.iCN,
          "SpreadRate": parseFloat(state.spreadRate),
          "PrivateLabelStyle": state.privateLabelStyle,
          "PrivateLabelCollection": state.privateLabelCollection,
          "Display": state.display,
          "TaxCode": state.taxCode,
          "AuditText": state.auditText,
          "IsArchive": state.isArchive,
          "CreatedBy": auth[0].userId,
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteProductSpecId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteProductSpecId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitProductSpecDelete({
      "ProductSpecId": deleteProductSpecId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setState(data);
    setIds(data.productSpecId);
    setShow(true)
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (state.unitPer1 === '') {
      toast.error("UnitPer1 cannot be empty.");
    } else {

      submitProductSpecEdit({
        "data": {
          "ProductSpecId": ids,
          "ProductInfoId": parseInt(state.productInfoId),
          "UnitPer1": parseFloat(state.unitPer1),
          "UnitPer2": parseFloat(state.unitPer2),
          "CashBack": state.cashBack,
          "BuyingGroup": state.buyingGroup,
          "Royality": state.royality,
          "Tariff": state.tariff,
          "Stocking": state.stocking,
          "Custom": state.custom,
          "FOB": state.fOB,
          "QualityRating": state.qualityRating,
          "Warrantly": state.warrantly,
          "AltId": state.altId,
          "ProductSourceId": state.productSourceId,
          "Thickness": state.thickness,
          "ICN": state.iCN,
          "SpreadRate": parseInt(state.spreadRate),
          "PrivateLabelStyle": state.privateLabelStyle,
          "PrivateLabelCollection": state.privateLabelCollection,
          "Display": state.display,
          "TaxCode": state.taxCode,
          "AuditText": state.auditText,
          "IsArchive": state.isArchive,
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>ProductSpec</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`ProductSpec`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              salesStageName={salesProductSpecName}
              handleSalesStageNameChange={handleSalesStageNameChange}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
              handleChange={handleChange}
              state={state}
              ProductInfo={ProductInfo.valueList}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>UnitPer1</th>
                <th className="text-center">UnitPer2</th>
                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (

                  <tr key={index}>
                    <td>
                      {item.unitPer1}
                    </td>
                    <td width="150">
                      {item.unitPer2}
                    </td>
                    <td className="actions">
                      <div className="btn-group">
                        <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                        <ConfirmDelete variant="primary" title={`ProductSpec`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.productSpecId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                      </div>
                    </td>
                  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
      <Edit
        state={state}
        handleChange={handleChange}
        handleEditSaveClick={handleEditSaveClick}
        show={show}
        hideCreateModal={hideCreateModal}
        title={`ProductSpec`}
        ProductInfo={ProductInfo.valueList}
      />
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, ProductInfo, ProductSpec }) => {
  return {
    auth,
    ProductSpec,
    ProductInfo
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductInfo: (token) => dispatch({ type: PRODUCT_INFO_REQUEST, token }),
    fetchProductSpec: (token) => dispatch({ type: PRODUCT_SPEC_REQUEST, token }),
    submitProductSpecCreate: (data) => dispatch({ type: PRODUCT_SPEC_CREATE, payload: data }),
    submitProductSpecDelete: (data) => dispatch({ type: PRODUCT_SPEC_DELETE, payload: data }),
    submitProductSpecEdit: (data) => dispatch({ type: PRODUCT_SPEC_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: PRODUCT_SPEC_STATE_CHANGE }),


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductSpec);