import React, { Fragment, useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { CUSTOMER_LIST_REQUEST, CUSTOMER_CONTRACT_ADDED_LIST_REQUEST } from "../../../../_utils/constants/Customer";
import { STORE_INFO_REQUEST } from "../../../../_utils/constants/admin/StoreInfo";
import CustomerList from "../../customer/CustomerList";
import { Navigate } from "react-router-dom";

const GroupCustomer = ({
	groupContractCustomerId,
	auth: { auth, isAuthenticated },
	customerInfo: { customerList, totalCount, totalContractCount, customerContractAddedList, isLoading },
	StoreInfo: { storeInfo },
	handleClickGroupContractCheckbox,
	fetchCustomerList,
	fetchStoreInfo,
	fetchCustomerContractAddedList,
	salesPersonId,
	editPricingContractModalOpen
}) => {

	// states declaration
	const [sort, setSort] = useState("asc");
	const [page, setPage] = useState(1);
	const [paginate, setPaginate] = useState(20);
	const [listParamState, setListParamState] = useState({
		"sortOn": "",
		"salesperson": "",
		"shipToPropertyName": "",
		"shipToManagementCompany": "",
		"shipToAddress": "",
		"shipToState": "",
		"shipToCity": "",
		"StoreId": ""
	})
	//state for selected checkbox
	const [evt, setEvt] = useState();
	const [selectedId, setSelectedId] = useState();
	const [selectedData, setSelectedData] = useState();
	const [isListFiltered, setIsListFiltered] = useState(null);
	// const [isChange, setIsChange] = useState(false);

  // function called on any filter value change
	const handleFilterChange = (e) => {
		const { id, value } = e.target;
		setListParamState(
			{
				...listParamState,
				[id]: value
			}
		)
	}

	// function to call customer list api
	useEffect(() => {
		if (isAuthenticated) {
	  	fetchStoreInfo(auth[0].token);
			fetchCustomerList(auth[0].token, sort, page, paginate, listParamState, auth[0].userId, salesPersonId, isListFiltered ? editPricingContractModalOpen.groupPricingContractId : false, isListFiltered ? isListFiltered : false);
		}
	if (!isAuthenticated) return <Navigate to="/login" />;
	}, [auth, isAuthenticated, sort, page, paginate, listParamState]);

	useEffect(() => {
		if(customerContractAddedList.length > 0) {
			if(customerContractAddedList[0]?.isContractPricingAdded) {
				handleClickGroupContractCheckbox(evt, selectedId, selectedData, customerContractAddedList[0]?.isContractPricingAdded)
			} else {
				handleClickGroupContractCheckbox(evt, selectedId, selectedData, customerContractAddedList[0]?.isContractPricingAdded)
			}
		}
	}, [customerContractAddedList])

  // function to manage store list data fetched
  const storeList = useMemo(() => {
		if (storeInfo)
			return storeInfo.map(v => ({ label: v.storeName, value: v.storeId }))
	}, [storeInfo])

	const handleGroupContractCheckbox = (e, contactInfoId, cust) => {
		setEvt(e.target.checked)
		setSelectedId(contactInfoId)
		setSelectedData(cust)
		if(e.target.checked) {
			fetchCustomerContractAddedList(auth[0].token, sort, page, paginate, {"contactInfoId": cust.contactInfoId}, auth[0].userId, salesPersonId)
		} else {
			handleClickGroupContractCheckbox(e.target.checked, contactInfoId, cust, true)
		}
	}

	// handle contract selection in GC
	const handlePropertySelection = (e) => {
		setIsListFiltered(e.target.checked)
		if (e.target.checked) {
			fetchCustomerList(auth[0].token, sort, page, paginate, listParamState, auth[0].userId, salesPersonId, editPricingContractModalOpen.groupPricingContractId, e.target.checked);
		} else {
			fetchCustomerList(auth[0].token, sort, page, paginate, listParamState, auth[0].userId, salesPersonId, false, false);
		}
	}

  return(
	<Fragment>
	{/*{isLoading ? (<div className="loading-container"><div className="spinner-border"></div></div>) : null}*/}
	  <CustomerList
				customerList={customerList}
				totalCount={totalCount}
				setSort={setSort}
				setPaginate={setPaginate}
				setPage={setPage}
				page={page}
				paginate={paginate}
				listParamState={listParamState}
				handleFilterChange={handleFilterChange}
				storeList={storeList}
				checkGroupContractState={true}
				groupContractCustomerId={groupContractCustomerId}
				handleGroupContractCheckbox={handleGroupContractCheckbox}
				handlePropertySelection={handlePropertySelection}
			/>
		</Fragment>
	)
}
const mapStateToProps = ({ auth, customerInfo, StoreInfo }) => {
	return {
		auth, customerInfo, StoreInfo
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		fetchCustomerList: (token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked) => dispatch({ type: CUSTOMER_LIST_REQUEST, token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked }),
		fetchCustomerContractAddedList: (token, sort, page, paginate, listParamState, userId, salesPersonId) => dispatch({ type: CUSTOMER_CONTRACT_ADDED_LIST_REQUEST, token, sort, page, paginate, listParamState, userId, salesPersonId }),
		fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupCustomer);