import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";

const SelectOrderMaterial = ({
	handleOrderingModelOpen,
	handleOrderingModelClose,
	orderingUrl
}) => {

	return (
		<Fragment>
			<Modal show={handleOrderingModelOpen} size="fullscreen">
				<Modal.Header closeButton onClick={handleOrderingModelClose}>
					<h5 className="modal-title">Choose Specific Rooms to Order or Change Material Type Within a Room</h5>
				</Modal.Header>
				<Modal.Body className="p-0">
					{orderingUrl && <iframe 
						width="100%"
						height="550" 
						// style={{marginBottom:'30px'}}
						src={orderingUrl}
						// src={`http://192.168.1.108/ordering/ordering-system/select-plan.html?hash=9fc83117-88e2-4a09-a362-14c379cbe5d4&unittypeid=1329&contactinfoid=14774&uniqueChannelName=ee52c4c0-2b3b-44db-853e-e67f22c1234d`}
					/>}
				</Modal.Body>
			</Modal>
		</Fragment>
	)
};

export default SelectOrderMaterial;