import React from "react";
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

const CustomCurrencyInput = ({ label, handleChange, ids, validate, title, value, disable }) => {
  return (
    <Form.Group controlId={ids}>
      {label ? (
        <Form.Label>{label}<span className="text-danger">{validate ? "*" : null}</span></Form.Label>
      ) : null}
      <CurrencyInput allowDecimals={true} decimalSeparator="." className="form-control" decimalsLimit={12} prefix="$" name={ids} value={value} onValueChange={(value, name) => handleChange(value, name)} required={validate} disabled={disable} />
      {validate ? (
        <Form.Control.Feedback type="invalid">
          {label ? label : ids} is required
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  )
}

export default CustomCurrencyInput;