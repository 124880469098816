import React, { Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateNoteTemplate = ({
  title,
  createModalOpen,
  showCreateModal,
  hideCreateModal,
  noteTemplateName,
  handleNoteTemplateNameChange,
  noteTemplateText,
  handleNoteTemplateTextChange,
  handleCreateModalSubmit
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} >
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="noteTemplateName">
              <Form.Label>Enter New {title}</Form.Label>
              <Form.Control type="text" name="noteTemplateName" onChange={handleNoteTemplateNameChange} value={noteTemplateName} />
            </Form.Group>
            <div className="form-group">
              <label htmlFor="noteTemplateText">{title} Text</label>
              <textarea className="form-control" name="noteTemplateText" id="noteTemplateText" onChange={handleNoteTemplateTextChange} value={noteTemplateText} rows="8"></textarea>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateModalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreateNoteTemplate;