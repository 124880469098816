import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
// import Icon from "react-icons-kit";
// import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateProductType = ({
  title,
  createModalOpen,
  showCreateModal,
  hideCreateModal,
  handleCreateModalSubmit,
  handleChange,
  state,
  units,
  SoldBy,
  validated
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}>
        Create New
        {/* <Icon size={24} icon={ic_add} /> */}
      </Button>
      <Modal show={createModalOpen} size="xl">
        <Modal.Header>
          {/*<Modal.Title>Create {title}</Modal.Title>*/}
          <Modal.Title>Add New Product Structure</Modal.Title>
        </Modal.Header>
        <Form validated={validated} onSubmit={handleCreateModalSubmit} noValidate>
          <Modal.Body>
            <Row>
              <Col md={3}>
                <Form.Group controlId="productStructure">
                  <Form.Label>Category<span className="text-danger">*</span></Form.Label>
                  <Form.Control type="text" name="productStructure" onChange={handleChange} value={state.productStructure} required={true} />
                  <Form.Control.Feedback type="invalid">
                    Category is required
                  </Form.Control.Feedback>

                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="seamed">
                  <Form.Label>Seamed<span className="text-danger">*</span></Form.Label>
                  <Form.Select aria-label="Default select example" onChange={handleChange} value={state.seamed} required={true}>
                    <option value="" selected>Select your option</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Seemed is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="orderBy">
                  <Form.Label>Ordered By<span className="text-danger">*</span></Form.Label>
                  <Form.Select aria-label="Default select example" value={state.orderBy} onChange={handleChange} required={true}>
                    <option value="" selected>Select your option</option>
                    <option value="SY">SY</option>
                    <option value="SF">SF</option>
                    <option value="EA">EA</option>
                    <option value="LF">LF</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Ordered By is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="soldById">
                  <Form.Label>Sold By<span className="text-danger">*</span></Form.Label>
                  <Form.Select aria-label="soldById" name="soldById" onChange={handleChange} value={state.soldById} required={true}>
                    <option value="">Select SoldBy</option>
                    {SoldBy.map((s, i) => (
                      <option value={s.soldById} key={i}>{s.soldByName}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Sold By is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {/*             
            <hr />
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="unitId">
                  <Form.Label>Units</Form.Label>
                  <Form.Select aria-label="unitId" name="unitId" onChange={handleChange} value={state.unitId}>
                    <option>Select Unit</option>
                    {units.map((u, i) => (
                      <option value={u.unitId} key={i}>{u.unitName}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="procurement">
                  <Form.Label>Procurement</Form.Label>
                  <Form.Control type="text" name="procurement" onChange={handleChange} value={state.procurement} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="defaultGpm">
                  <Form.Label>DefaultGpm</Form.Label>
                  <Form.Control type="text" name="defaultGpm" onChange={handleChange} value={state.defaultGpm} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="invPrefix">
                  <Form.Label>InvPrefix</Form.Label>
                  <Form.Control type="text" name="invPrefix" onChange={handleChange} value={state.invPrefix} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-end">
              <Col>
                <Form.Group className="mb-3" controlId="defaultFreightCharges">
                  <Form.Label>DefaultFreightCharges</Form.Label>
                  <Form.Control type="text" name="defaultFreightCharges" onChange={handleChange} value={state.defaultFreightCharges} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="requirePoitemInfo">
                  <Form.Check
                    type="switch"
                    id="requirePoitemInfo"
                    label="RequirePoitemInfo"
                    onChange={handleChange}
                    value={state.requirePoitemInfo ? true : false}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="allowManualItemEntry">
                  <Form.Check
                    type="switch"
                    id="allowManualItemEntry"
                    label="AllowManualItemEntry"
                    onChange={handleChange}
                    value={state.allowManualItemEntry ? true : false}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="allowManualPicking">
                  <Form.Check
                    type="switch"
                    id="allowManualPicking"
                    label="AllowManualPicking"
                    onChange={handleChange}
                    value={state.allowManualPicking ? true : false}
                  />
                </Form.Group>
              </Col>
            </Row>
          */}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hideCreateModal}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  )
};

export default CreateProductType;