import React, { useEffect, useState } from "react";
import { Table, Row, Col, Image } from "react-bootstrap";
import { Icon } from 'react-icons-kit';
import { ic_check } from 'react-icons-kit/md/';
import { ic_camera_alt } from 'react-icons-kit/md/';

const PricingContractSummary = ({
  selectedCarpet,
  selectedLaborCarpet,
  selectedMaterialCarpet,
  selectedSheetVinyl,
  selectedLaborSheetVinyl,
  selectedMaterialSheetVinyl,
  selectedVinylPlank,
  selectedLaborVinylPlank,
  selectedMaterialVinylPlank,
  selectedWallbase,
  selectedLaborWallbase,
  selectedMaterialWallbase,
  selectedSundries,
  selectedLaborSundries,
  selectedMaterialSundries,
  selectedLabor
}) => {

  // state declaration
  const [wallbaseSubProductKit, setWallbaseSubProductKitName] = useState([])
  const [sundriesSubProductKit, setSundriesSubProductKitName] = useState([])

  // wallbase sub product data
  useEffect(() => {
    if ((selectedLaborWallbase && selectedLaborWallbase.length > 0) || (selectedMaterialWallbase && selectedMaterialWallbase.length > 0)) {
      let tempKitArray = [...selectedLaborWallbase, ...selectedMaterialWallbase]
      let tempArr = [...new Set(tempKitArray.map(data => data.subProductStructure))]
      setWallbaseSubProductKitName(tempArr)
    } else {
      setWallbaseSubProductKitName([])
    }
  }, [selectedLaborWallbase, selectedMaterialWallbase])

  // sundries sub product data
  useEffect(() => {
    if ((selectedLaborSundries && selectedLaborSundries.length > 0) || (selectedMaterialSundries && selectedMaterialSundries.length > 0)) {
      let tempKitArray = [...selectedLaborSundries, ...selectedMaterialSundries]
      let tempArr = [...new Set(tempKitArray.map(data => data.subProductStructure))]
      setSundriesSubProductKitName(tempArr)
    } else {
      setSundriesSubProductKitName([])
    }
  }, [selectedLaborSundries, selectedMaterialSundries])

  return (
    <div className="summary">
      <h3 className="text-capitalize">Summary</h3>

      {((selectedCarpet && selectedCarpet.length > 0) || (selectedLaborCarpet && selectedLaborCarpet.length > 0) || (selectedMaterialCarpet && selectedMaterialCarpet.length > 0)) ? (
        <div className="step">
          <h5 className="title">Carpets</h5>
          {(selectedCarpet && selectedCarpet.length > 0) ? (
            <Table responsive className="procatlist">
              <thead>
                <tr>
                  <th>Primary</th>
                  <th>Secondary</th>
                  <th>Stock</th>
                  <th>Recurring Stock</th>
                  <th>Store Name</th>
                  <th>Private Name</th>
                  <th>Manufacturer</th>
                  <th>Style</th>
                  <th>Color</th>
                  <th>Photo</th>
                  <th>Fiber Content</th>
                  <th>Face Weight</th>
                  <th>Current Cost</th>
                  <th>Future Cost 1</th>
                  <th>Future Cost 2</th>
                  <th>Price</th>
                  <th>GPM</th>
                </tr>
              </thead>
              <tbody>
                {selectedCarpet && selectedCarpet.map((item, index) => (
                  <tr key={index}>
                    <td>{(item.isPrimary ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{(item.isSecondary ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{(item.isStock ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{(item.isRecurringStock ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{_.get(item, "storeName", "")}</td>
                    <td>{_.get(item, "privateName", "")}</td>
                    <td>{_.get(item, "manufacturer", "")}</td>
                    <td>{_.get(item, "style", "")}</td>
                    <td>{_.get(item, "color", "")}</td>
                    <td>
                      {item.photoPath ? (
                        <Image width="75" src={item.photoPath} />
                      ) : (
                        <Icon size={24} icon={ic_camera_alt} />
                      )}
                    </td>
                    <td>{_.get(item, "fiber", "")}</td>
                    <td>{item.faceWidth ? parseFloat(item.faceWidth).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.totalFutureCost1 ? parseFloat(item.totalFutureCost1).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.totalFutureCost2 ? parseFloat(item.totalFutureCost2).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td width="110">{_.get(item, "gpm", "")}%</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : ""}
          {((selectedLaborCarpet && selectedLaborCarpet.length > 0) || (selectedMaterialCarpet && selectedMaterialCarpet.length > 0)) ? (
            <div>
              <h6 className="mb-2">Labor and accessory material pricing</h6>
              <Row>
                <Col md={8}>
                  <Table responsive className="procatlist table-borderless">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Current Cost</th>
                        <th>Price</th>
                        <th>GPM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedLaborCarpet && selectedLaborCarpet.map((item, index) => (
                        <tr key={index}>
                          <td>{_.get(item, "laborName", "")}</td>
                          <td width="110">{item.currentCost ? parseFloat(item.currentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{_.get(item, "gpm", "")}%</td>
                        </tr>
                      ))}
                      {selectedMaterialCarpet && selectedMaterialCarpet.map((item, index) => (
                        <tr key={index}>
                          <td>{_.get(item, "privateName", "")}</td>
                          <td width="110">{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{_.get(item, "gpm", "")}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          ) : ""}
        </div>
      ) : ""}

      {((selectedSheetVinyl && selectedSheetVinyl.length > 0) || (selectedLaborSheetVinyl && selectedLaborSheetVinyl.length > 0) || (selectedMaterialSheetVinyl && selectedMaterialSheetVinyl.length > 0)) ? (
        <div className="step">
          <h5 className="title">Sheet vinyl</h5>
          {(selectedSheetVinyl && selectedSheetVinyl.length > 0) ? (
            <Table responsive className="procatlist">
              <thead>
                <tr>
                  <th>Primary</th>
                  <th>Secondary</th>
                  <th>Stock</th>
                  <th>Recurring Stock</th>
                  <th>Store Name</th>
                  <th>Private Name</th>
                  <th>Manufacturer</th>
                  <th>Style</th>
                  <th>Color</th>
                  <th>Photo</th>
                  <th>Backing Type</th>
                  <th>Guage</th>
                  <th>Current Cost</th>
                  <th>Future Cost 1</th>
                  <th>Future Cost 2</th>
                  <th>Price</th>
                  <th>GPM</th>
                </tr>
              </thead>
              <tbody>
                {selectedSheetVinyl && selectedSheetVinyl.map((item, index) => (
                  <tr key={index}>
                    <td>{(item.isPrimary ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{(item.isSecondary ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{(item.isStock ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{(item.isRecurringStock ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{_.get(item, "storeName", "")}</td>
                    <td>{_.get(item, "privateName", "")}</td>
                    <td>{_.get(item, "manufacturer", "")}</td>
                    <td>{_.get(item, "style", "")}</td>
                    <td>{_.get(item, "color", "")}</td>
                    <td>
                      {item.photoPath ? (
                        <Image width="75" src={item.photoPath} />
                      ) : (
                        <Icon size={24} icon={ic_camera_alt} />
                      )}
                    </td>
                    <td>{_.get(item, "backingType", "")}</td>
                    <td>{item.guage ? parseFloat(item.guage).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.totalFutureCost1 ? parseFloat(item.totalFutureCost1).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.totalFutureCost2 ? parseFloat(item.totalFutureCost2).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td width="110">{_.get(item, "gpm", "")}%</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : ""}
          {((selectedLaborSheetVinyl && selectedLaborSheetVinyl.length > 0) || (selectedMaterialSheetVinyl && selectedMaterialSheetVinyl.length > 0)) ? (
            <div>
              <h6 className="mb-2">Labor and accessory material pricing</h6>
              <Row>
                <Col md={8}>
                  <Table responsive className="procatlist table-borderless">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Current Cost</th>
                        <th>Price</th>
                        <th>GPM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedLaborSheetVinyl && selectedLaborSheetVinyl.map((item, index) => (
                        <tr key={index}>
                          <td>{_.get(item, "laborName", "")}</td>
                          <td width="110">{item.currentCost ? parseFloat(item.currentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{_.get(item, "gpm", "")}%</td>
                        </tr>
                      ))}
                      {selectedMaterialSheetVinyl && selectedMaterialSheetVinyl.map((item, index) => (
                        <tr key={index}>
                          <td>{_.get(item, "privateName", "")}</td>
                          <td width="110">{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{_.get(item, "gpm", "")}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          ) : ""}
        </div>
      ) : ""}

      {((selectedVinylPlank && selectedVinylPlank.length > 0) || (selectedLaborVinylPlank && selectedLaborVinylPlank.length > 0) || (selectedMaterialVinylPlank && selectedMaterialVinylPlank.length > 0)) ? (
        <div className="step">
          <h5 className="title">Vinyl plank</h5>
          {(selectedVinylPlank && selectedVinylPlank.length > 0) ? (
            <Table responsive className="procatlist">
              <thead>
                <tr>
                  <th>Primary</th>
                  <th>Secondary</th>
                  <th>Stock</th>
                  <th>Recurring Stock</th>
                  <th>Store Name</th>
                  <th>Private Name</th>
                  <th>Manufacturer</th>
                  <th>Style</th>
                  <th>Color</th>
                  <th>Photo</th>
                  <th>WearLayer</th>
                  <th>Thickness</th>
                  <th>Current Cost</th>
                  <th>Future Cost 1</th>
                  <th>Future Cost 2</th>
                  <th>Price</th>
                  <th>GPM</th>
                </tr>
              </thead>
              <tbody>
                {selectedVinylPlank && selectedVinylPlank.map((item, index) => (
                  <tr key={index}>
                    <td>{(item.isPrimary ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{(item.isSecondary ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{(item.isStock ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{(item.isRecurringStock ? (<Icon icon={ic_check} />) : "")}</td>
                    <td>{_.get(item, "storeName", "")}</td>
                    <td>{_.get(item, "privateName", "")}</td>
                    <td>{_.get(item, "manufacturer", "")}</td>
                    <td>{_.get(item, "style", "")}</td>
                    <td>{_.get(item, "color", "")}</td>
                    <td>
                      {item.photoPath ? (
                        <Image width="75" src={item.photoPath} />
                      ) : (
                        <Icon size={24} icon={ic_camera_alt} />
                      )}
                    </td>
                    <td>{item.wearLayer ? parseFloat(item.wearLayer).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.thickness ? parseFloat(item.thickness).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.totalFutureCost1 ? parseFloat(item.totalFutureCost1).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td>{item.totalFutureCost2 ? parseFloat(item.totalFutureCost2).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                    <td width="110">{_.get(item, "gpm", "")}%</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : ""}
          {((selectedLaborVinylPlank && selectedLaborVinylPlank.length > 0) || (selectedMaterialVinylPlank && selectedMaterialVinylPlank.length > 0)) ? (
            <div>
              <h6 className="mb-2">Labor and accessory material pricing</h6>
              <Row>
                <Col md={8}>
                  <Table responsive className="procatlist table-borderless">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Current Cost</th>
                        <th>Price</th>
                        <th>GPM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedLaborVinylPlank && selectedLaborVinylPlank.map((item, index) => (
                        <tr key={index}>
                          <td>{_.get(item, "laborName", "")}</td>
                          <td width="110">{item.currentCost ? parseFloat(item.currentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{_.get(item, "gpm", "")}%</td>
                        </tr>
                      ))}
                      {selectedMaterialVinylPlank && selectedMaterialVinylPlank.map((item, index) => (
                        <tr key={index}>
                          <td>{_.get(item, "privateName", "")}</td>
                          <td width="110">{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                          <td width="110">{_.get(item, "gpm", "")}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          ) : ""}
        </div>
      ) : ""}

      {((selectedWallbase && selectedWallbase.length > 0) || (selectedLaborWallbase && selectedLaborWallbase.length > 0) || (selectedMaterialWallbase && selectedMaterialWallbase.length > 0)) ? (
        <div className="step">
          <h5 className="title">Wall base</h5>
          <Table responsive className="procatlist">
            <thead>
              <tr>
                <th>Primary</th>
                <th>Secondary</th>
                <th>Stock</th>
                <th>Recurring Stock</th>
                <th>Store Name</th>
                {/* <th>Sub product structure</th> */}
                <th>Private Name</th>
                <th>Manufacturer</th>
                <th>Style</th>
                <th>Color</th>
                <th>Photo</th>
                <th>Current Cost</th>
                <th>Future Cost 1</th>
                <th>Future Cost 2</th>
                <th>Price</th>
                <th>GPM</th>
              </tr>
            </thead>
            <tbody>
              {selectedWallbase && selectedWallbase.map((item, index) => (
                <tr key={index}>
                  <td>{(item.isPrimary ? (<Icon icon={ic_check} />) : "")}</td>
                  <td>{(item.isSecondary ? (<Icon icon={ic_check} />) : "")}</td>
                  <td>{(item.isStock ? (<Icon icon={ic_check} />) : "")}</td>
                  <td>{(item.isRecurringStock ? (<Icon icon={ic_check} />) : "")}</td>
                  <td>{_.get(item, "storeName", "")}</td>
                  {/* <td>{_.get(item, "subProductStructureName", "")}</td> */}
                  <td>{_.get(item, "privateName", "")}</td>
                  <td>{_.get(item, "manufacturer", "")}</td>
                  <td>{_.get(item, "style", "")}</td>
                  <td>{_.get(item, "color", "")}</td>
                  <td>
                    {item.photoPath ? (
                      <Image width="75" src={item.photoPath} />
                    ) : (
                      <Icon size={24} icon={ic_camera_alt} />
                    )}
                  </td>
                  <td>{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td>{item.totalFutureCost1 ? parseFloat(item.totalFutureCost1).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td>{item.totalFutureCost2 ? parseFloat(item.totalFutureCost2).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td width="110">{_.get(item, "gpm", "")}%</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {((selectedLaborWallbase && selectedLaborWallbase.length > 0) || (selectedMaterialWallbase && selectedMaterialWallbase.length > 0)) ? (
            <div>
              <h6 className="mb-2">Labor and accessory material pricing</h6>
              <Row>
                <Col md={8}>
                  <Table responsive className="procatlist material-pricing-table table-borderless">
                    <thead>
                      <tr>
                        <th></th>
                        <th style={{width:95 + 'px'}}>Current Cost</th>
                        <th style={{width:100 + 'px'}}>Price</th>
                        <th style={{width:110 + 'px'}}>GPM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr><td className="structure-title" colSpan="4">Product Sub Structure</td></tr>
                      {wallbaseSubProductKit && wallbaseSubProductKit.length > 0 ? wallbaseSubProductKit.map((selectedWallbaseData, selectedWallbaseposition) =>(
                        <tr key={selectedWallbaseposition}>
                          <td className="secondaryKit-data" colSpan="4">
                            <Table>
                              <tr><td colSpan="4">{selectedWallbaseData}</td></tr>
                              {selectedLaborWallbase && selectedLaborWallbase.map((item, index) => (
                                <tr key={index}>
                                  {selectedWallbaseData === item.subProductStructure ?
                                    <td colSpan="4" className="test">
                                      <Table className="subproduct-data-table">
                                        <tr>
                                          <td className="p-0">{_.get(item, "laborName", "")}</td>
                                          <td className="p-0" style={{width:90 + 'px'}}>{item.currentCost ? parseFloat(item.currentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                                          <td className="p-0" style={{width:100 + 'px'}}>{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                                          <td className="p-0" style={{width:100 + 'px'}}>{_.get(item, "gpm", "")}%</td>
                                        </tr>
                                      </Table>
                                    </td>
                                  : null}
                                </tr>
                              ))}
                              {selectedMaterialWallbase && selectedMaterialWallbase.map((item, index) => (
                                <tr key={index}>
                                  {selectedWallbaseData === item.subProductStructure ?
                                    <td colSpan="4" className="test2">
                                      <Table className="subproduct-data-table">
                                          <tr>
                                            <td className="p-0">{_.get(item, "privateName", "")}</td>
                                            <td className="p-0" style={{width:90 + 'px'}}>{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                                            <td className="p-0" style={{width:100 + 'px'}}>{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                                            <td className="p-0" style={{width:100 + 'px'}}>{_.get(item, "gpm", "")}%</td>
                                          </tr>
                                      </Table>
                                    </td>
                                  : null}
                                </tr>
                              ))}
                            </Table>
                          </td>
                        </tr>
                      )): null}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          ) : ""}
        </div>
      ) : ""}

      {(selectedSundries && selectedSundries.length > 0) ? (
        <div className="step">
          <h5 className="title">Miscellaneous sundries</h5>
          <Table responsive className="procatlist">
            <thead>
              <tr>
                <th>Display As Add On</th>
                {/* <th>Secondary</th> */}
                <th>Stock</th>
                <th>Recurring Stock</th>
                <th>Store Name</th>
                {/* <th>Sub product structure</th> */}
                <th>Private Name</th>
                <th>Manufacturer</th>
                <th>Style</th>
                <th>Color</th>
                <th>Photo</th>
                <th>Current Cost</th>
                <th>Future Cost 1</th>
                <th>Future Cost 2</th>
                <th>Price</th>
                <th>GPM</th>
              </tr>
            </thead>
            <tbody>
              {selectedSundries && selectedSundries.map((item, index) => (
                <tr key={index}>
                  <td>{(item.isPrimary ? (<Icon icon={ic_check} />) : "")}</td>
                  {/* <td>{(item.isSecondary ? (<Icon icon={ic_check} />) : "")}</td> */}
                  <td>{(item.isStock ? (<Icon icon={ic_check} />) : "")}</td>
                  <td>{(item.isRecurringStock ? (<Icon icon={ic_check} />) : "")}</td>
                  <td>{_.get(item, "storeName", "")}</td>
                  {/* <td>{_.get(item, "subProductStructureName", "")}</td> */}
                  <td>{_.get(item, "privateName", "")}</td>
                  <td>{_.get(item, "manufacturer", "")}</td>
                  <td>{_.get(item, "style", "")}</td>
                  <td>{_.get(item, "color", "")}</td>
                  <td>
                    {item.photoPath ? (
                      <Image width="75" src={item.photoPath} />
                    ) : (
                      <Icon size={24} icon={ic_camera_alt} />
                    )}
                  </td>
                  <td>{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td>{item.totalFutureCost1 ? parseFloat(item.totalFutureCost1).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td>{item.totalFutureCost2 ? parseFloat(item.totalFutureCost2).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td width="110">{_.get(item, "gpm", "")}%</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {((selectedLaborSundries && selectedLaborSundries.length > 0) || (selectedMaterialSundries && selectedMaterialSundries.length > 0)) ? (
            <div>
              <h6 className="mb-2">Labor and accessory material pricing</h6>
              <Row>
                <Col md={8}>
                  <Table responsive className="procatlist material-pricing-table table-borderless">
                    <thead>
                      <tr>
                        <th></th>
                        <th style={{width:95 + 'px'}}>Current Cost</th>
                        <th style={{width:100 + 'px'}}>Price</th>
                        <th style={{width:110 + 'px'}}>GPM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr><td className="structure-title" colSpan="4">Product Sub Structure</td></tr>
                      {sundriesSubProductKit && sundriesSubProductKit.length > 0 ? sundriesSubProductKit.map((selectedSundriesData, selectedSundriesPosition) => (
                        <tr key={selectedSundriesPosition}>
                          <td className="secondaryKit-data" colSpan="4">
                            <Table>
                              <tr><td colSpan="4">{selectedSundriesData}</td></tr>
                              {selectedLaborSundries && selectedLaborSundries.map((item, index) => (
                                <tr key={index}>
                                  {selectedSundriesData === item.subProductStructure ?
                                    (
                                    <td colSpan="4" className="test">
                                      <Table className="subproduct-data-table">
                                        <tr>
                                        <td className="p-0">{_.get(item, "laborName", "")}</td>
                                          <td className="p-0" style={{width:90 + 'px'}}>{item.currentCost ? parseFloat(item.currentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                                          <td className="p-0" style={{width:100 + 'px'}}>{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                                          <td className="p-0" style={{width:100 + 'px'}}>{_.get(item, "gpm", "")}%</td>
                                        </tr>
                                      </Table>
                                    </td>
                                    )
                                    : null
                                  }
                                </tr>
                              ))}
                              {selectedMaterialSundries && selectedMaterialSundries.map((item, index) => (
                                <tr key={index}>
                                  {selectedSundriesData === item.subProductStructure ?
                                    (
                                      <td colSpan="4" className="test">
                                        <Table className="subproduct-data-table">
                                          <tr>
                                            <td className="p-0">{_.get(item, "privateName", "")}</td>
                                            <td className="p-0" style={{width:90 + 'px'}}>{item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                                            <td className="p-0" style={{width:100 + 'px'}}>{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                                            <td className="p-0" style={{width:100 + 'px'}}>{_.get(item, "gpm", "")}%</td>
                                          </tr>
                                      </Table>
                                    </td>
                                    ) : null
                                  }
                                </tr>
                              ))}
                            </Table>
                          </td>
                        </tr>))
                      : null}
                      
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          ) : ""}
        </div>
      ) : ""}

      {(selectedLabor && selectedLabor.length > 0) ? (
        <div className="step">
          <h5 className="title">Miscellaneous labor</h5>
          <Table responsive className="procatlist">
            <thead>
              <tr>
                <th>Store Name</th>
                <th>Product Structure</th>
                <th>Labor Category</th>
                <th>Labor Name</th>
                <th>Unit</th>
                <th>Current Cost</th>
                <th>Future Cost 1</th>
                <th>Price</th>
                <th>GPM</th>
              </tr>
            </thead>
            <tbody>
              {selectedLabor && selectedLabor.map((item, index) => (
                <tr key={index}>
                  <td>{_.get(item, "storeName", "")}</td>
                  <td>{item.productStructure ? item.productStructure : item.productType}</td>
                  <td>{_.get(item, "laborCategory", "")}</td>
                  <td>{_.get(item, "laborName", "")}</td>
                  <td>{_.get(item, "unitName", "")}</td>
                  <td>{item.currentCost ? parseFloat(item.currentCost).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td>{item.futureCost1 ? parseFloat(item.futureCost1).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td width="110">{item.price ? parseFloat(item.price).toFixed(2) : parseFloat(0.00).toFixed(2)}</td>
                  <td width="110">{_.get(item, "gpm", "")}%</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : ""}
      
    </div>
  )
};

export default PricingContractSummary;