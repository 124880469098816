import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";

const PropertyList = ({
	handlePropertyModalOpen,
	handlePropertyModalClose,
	orderingUrl
}) => {

	return (
		<Fragment>
			<Modal show={handlePropertyModalOpen} className="customModalWidth" onHide={handlePropertyModalClose} centered id="orderplace">
				<Modal.Body className="p-0 text-center">
					{orderingUrl &&
						<iframe
							width="100%"
						  src={orderingUrl}
						  // src={`http://192.168.1.108/ordering/ordering-system/property-options.html?hash=b8fb02b4-b247-41ab-b4e2-5d6519b11e1b&contactinfoid=14774&uniqueChannelName=ee52c4c0-2b3b-44db-853e-e67f22c1234d`}
						/>
        	}
				</Modal.Body>
			</Modal>
		</Fragment>
	)
};

export default PropertyList;