import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  PRODUCT_SPEC_REQUEST,
  PRODUCT_SPEC_SUCCESS,
  PRODUCT_SPEC_FAILED,
  PRODUCT_SPEC_CREATE,
  PRODUCT_SPEC_CREATE_SUCCESS,
  PRODUCT_SPEC_CREATE_FAILED,
  PRODUCT_SPEC_DELETE,
  PRODUCT_SPEC_DELETE_SUCCESS,
  PRODUCT_SPEC_DELETE_FAILED,
  PRODUCT_SPEC_EDIT,
  PRODUCT_SPEC_EDIT_SUCCESS,
  PRODUCT_SPEC_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * ProductSpec Actions
 */

function* fetchProductSpec({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ProductSpec/GetProductSpec`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: PRODUCT_SPEC_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PRODUCT_SPEC_FAILED, payload: error.response });
    }
  }
}

function* createProductSpec(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ProductSpec/CreateProductSpec`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_SPEC_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_SPEC_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editProductSpec(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/Product/api/ProductSpec/UpdateProductSpec`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_SPEC_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_SPEC_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteProductSpec(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/ProductSpec/RemoveProductSpec?ProductSpecId=${encodeURIComponent(action.payload.ProductSpecId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: PRODUCT_SPEC_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_SPEC_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootProductSpecList() {
  yield all([
    takeLatest(PRODUCT_SPEC_REQUEST, fetchProductSpec),
    takeLatest(PRODUCT_SPEC_CREATE, createProductSpec),
    takeLatest(PRODUCT_SPEC_DELETE, deleteProductSpec),
    takeLatest(PRODUCT_SPEC_EDIT, editProductSpec),
  ]);
}