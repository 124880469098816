export const CONTACT_ROLL_LIST_REQUEST = "@CONTACT_ROLL_LIST_REQUEST";
export const CONTACT_ROLL_LIST_SUCCESS = "@CONTACT_ROLL_LIST_SUCCESS";
export const CONTACT_ROLL_LIST_FAILED = "@CONTACT_ROLL_LIST_FAILED";

export const CONTACT_ROLL_CREATE_REQUEST = "@CONTACT_ROLL_CREATE_REQUEST";
export const CONTACT_ROLL_CREATE_SUCCESS = "@CONTACT_ROLL_CREATE_SUCCESS";
export const CONTACT_ROLL_CREATE_FAILED = "@CONTACT_ROLL_CREATE_FAILED";

export const CONTACT_ROLL_EDIT_REQUEST = "@CONTACT_ROLL_EDIT_REQUEST";
export const CONTACT_ROLL_EDIT_SUCCESS = "@CONTACT_ROLL_EDIT_SUCCESS";
export const CONTACT_ROLL_EDIT_FAILED = "@CONTACT_ROLL_EDIT_FAILED";

export const CONTACT_ROLL_DELETE_REQUEST = "@CONTACT_ROLL_DELETE_REQUEST";
export const CONTACT_ROLL_DELETE_SUCCESS = "@CONTACT_ROLL_DELETE_SUCCESS";
export const CONTACT_ROLL_DELETE_FAILED = "@CONTACT_ROLL_DELETE_FAILED";

export const CONTACT_ROLL_STATE_CHANGE = "@CONTACT_ROLL_STATE_CHANGE";