import {
  PRODUCT_PRICE_REQUEST,
  PRODUCT_PRICE_SUCCESS,
  PRODUCT_PRICE_FAILED,
  PRODUCT_PRICE_CREATE,
  PRODUCT_PRICE_CREATE_SUCCESS,
  PRODUCT_PRICE_CREATE_FAILED,
  PRODUCT_PRICE_DELETE,
  PRODUCT_PRICE_DELETE_SUCCESS,
  PRODUCT_PRICE_DELETE_FAILED,
  PRODUCT_PRICE_EDIT,
  PRODUCT_PRICE_EDIT_SUCCESS,
  PRODUCT_PRICE_EDIT_FAILED,
  PRODUCT_PRICE_STATE_CHANGE,
} from "../../../_utils/constants/ProductCatalog";


const initialState = {
  valueList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const ProductPriceReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_PRICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case PRODUCT_PRICE_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        valueList: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case PRODUCT_PRICE_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case PRODUCT_PRICE_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case PRODUCT_PRICE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case PRODUCT_PRICE_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case PRODUCT_PRICE_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case PRODUCT_PRICE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case PRODUCT_PRICE_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case PRODUCT_PRICE_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case PRODUCT_PRICE_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case PRODUCT_PRICE_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case PRODUCT_PRICE_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default ProductPriceReducer;