import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Input from "../../../elements/CustomInput";
import { PRODUCT_TYPE_REQUEST } from "../../../../_utils/constants/ProductCatalog";
import { CUSTOMER_STATE_CHANGE } from "../../../../_utils/constants/Customer";
import { 
	PRICING_CONTRACT_CREATE_REQUEST,
	PRICING_CONTRACT_PRODUCT_LIST_REQUEST, 
	PRICING_CONTRACT_LABOR_LIST_REQUEST, 
	PRICING_CONTRACT_MATERIAL_LIST_REQUEST,
	PRICING_CONTRACT_LABOR_ITEM_LIST_REQUEST,
	PRICING_CONTRACT_DETAILS_REQUEST,
	GROUP_PRICING_CONTRACT_STATE_CHANGE,
	PRICING_CONTRACT_TRANSITION_LABOR_LIST_REQUEST,
	PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_REQUEST,
	PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST
} from "../../../../_utils/constants/PricingContract";
import { 
	CARPET_ID_DB, 
	SHEET_VINYL_ID_DB, 
	VINYL_PLANK_ID_DB, 
	WALLBASE_ID_DB, 
	INSTALLATION_ACCESSORIES_ID_DB,
	TRANSITION_ID_DB,
	GET_SUB_PRODUCT_REQUEST,
	GET_TRANSITION_SUB_PRODUCT_REQUEST
} from "../../../../_utils/constants/ProductCatalog";
import { connect } from "react-redux";
import PrimaryProductList from "./PrimaryProductList";
import PricingContractSummary from "./PricingContractSummary";
import { toast } from 'react-toastify';
import { constantIds } from "../../../../config";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

const PricingContract = ({
	auth: { auth, isAuthenticated },
	ProductType,
	pricingContract: { pricingContractData, productList, laborList, materialList, laborItemList, isCreateLoading, isCreated, isPricingContract, laborTransitionList, materialTransitionList, missingStairsLaborContractList },
	ProductType: { getSubProductData, getTransitionSubProductData },
	isContractPricingAdded,
	groupPricingContractId,
	storeId,
	contactInfoId,
	pricingContractModalOpen,
	showPricingContractModal,
	hidePricingContractModal,
	fetchProductType,
	fetchProductForPricingContract,
	fetchLaborForPricingContract,
	fetchMaterialForPricingContract,
  fetchLaborItemFilterItem,
	createPricingContract,
	fetchPricingContract,
	changeCustomerReducerState,
	changePricingContractReducerState,
	handleUpdateCustomerDetail,
	materialContractModalOpen,
	laborContractModalOpen,
	orderInstallationDetailId,
	saveContractForOrderDetails,
	fetchSubProductRequest,
	fetchTransitionLaborForPricingContract,
	fetchTransitionMaterialForPricingContract,
	fetchTransitionSubProductRequest,
	unitId,
	jobNum,
	fetchMissingLaborItemsInContract,
	selectedQuantityProduct
}) => {

	// states declarations
	const [selectedPrimaryProduct, setSelectPrimaryProduct] = useState([]);
	const [selectedPrimaryProductCopy, setSelectedPrimaryProductCopy] = useState([]);
	const [primaryProduct, setPrimaryProduct] = useState('');
	const [primaryProductCopy, setPrimaryProductCopy] = useState('');
	const [productListData, setProductListData] = useState([]);
	const [laborListData, setLaborListData] = useState([]);
	const [primaryProductLaborData, setPrimaryProductLaborData] = useState([]);
	const [primaryProductMaterialData, setPrimaryProductMaterialData] = useState([]);
	const [showLaborList, setShowLaborList] = useState(false);
	const [showSummary, setShowSummary] = useState(false);
	const [showSaveOnEdit, setShowSaveOnEdit] = useState(false);
	const [stateProductFilter, setStateProductFilter] = useState({
		"ProductStructureId": "",
		"StoreId": "",
		"ContactInfoId": "",
		"grpPricingContractId": "",
		"IsStock": "",
		"IsRecurringStock": "",
		"IsPrimary": "",
		"IsSecondary": "",
		"PrivateName": "",
		"Manufacturer": "",
		"Style": "",
		"Color": "",
		"Fiber": "",
		"faceWidth": "",
		"BackingType": "",
		"Guage": "",
		"WearLayer": "",
		"Thickness": "",
		"currentCost": "",
		"futureCost1": "",
		"futureCost2": "",
		"subProductStructureId": ""
	});
	const [stateLaborFilter, setStateLaborFilter] = useState({
		"laborName": "",
		"storeId": "",
		"ContactInfoId": "",
		"grpPricingContractId": "",
		"productTypeId": 0,
		"productStructureId": 0,
		"laborCategoryId": 0,
		"unitId": 0,
		"currentCost": 0,
		"futureCost1": 0,
		"increaseDate": "",
		"sortOn": "",
	});

	// Carpet modal states
	const [selectedCarpet, setSelectedCarpet] = useState([]);
	const [selectedCarpetIds, setSelectedCarpetIds] = useState([]);
	const [selectedLaborCarpet, setSelectedLaborCarpet] = useState([]);
	const [selectedLaborCarpetIds, setSelectedLaborCarpetIds] = useState([]);
	const [selectedMaterialCarpet, setSelectedMaterialCarpet] = useState([]);
	const [selectedMaterialCarpetIds, setSelectedMaterialCarpetIds] = useState([]);

	// Sheet Vinyl modal states
	const [selectedSheetVinyl, setSelectedSheetVinyl] = useState([]);
	const [selectedSheetVinylIds, setSelectedSheetVinylIds] = useState([]);
	const [selectedLaborSheetVinyl, setSelectedLaborSheetVinyl] = useState([]);
	const [selectedLaborSheetVinylIds, setSelectedLaborSheetVinylIds] = useState([]);
	const [selectedMaterialSheetVinyl, setSelectedMaterialSheetVinyl] = useState([]);
	const [selectedMaterialSheetVinylIds, setSelectedMaterialSheetVinylIds] = useState([]);

	// Vinyl Plank modal states
	const [selectedVinylPlank, setSelectedVinylPlank] = useState([]);
	const [selectedVinylPlankIds, setSelectedVinylPlankIds] = useState([]);
	const [selectedLaborVinylPlank, setSelectedLaborVinylPlank] = useState([]);
	const [selectedLaborVinylPlankIds, setSelectedLaborVinylPlankIds] = useState([]);
	const [selectedMaterialVinylPlank, setSelectedMaterialVinylPlank] = useState([]);
	const [selectedMaterialVinylPlankIds, setSelectedMaterialVinylPlankIds] = useState([]);

	// Wallbase modal states
	const [selectedWallbase, setSelectedWallbase] = useState([]);
	const [selectedWallbaseIds, setSelectedWallbaseIds] = useState([]);
	const [selectedLaborWallbase, setSelectedLaborWallbase] = useState([]);
	// const [selectedLaborWallbaseIds, setSelectedLaborWallbaseIds] = useState([]);
	// const [selectedLaborWallbaseKitIds, setSelectedLaborWallbaseKitIds] = useState([]);
	const [selectedLaborWallbaseLaborIds, setSelectedLaborWallbaseLaborIds] = useState([]);
	const [selectedMaterialWallbase, setSelectedMaterialWallbase] = useState([]);
	const [selectedMaterialWallbaseIds, setSelectedMaterialWallbaseIds] = useState([]);

	// Sundries modal states
	const [selectedSundries, setSelectedSundries] = useState([]);
	const [selectedSundriesIds, setSelectedSundriesIds] = useState([]);
	const [selectedLaborSundries, setSelectedLaborSundries] = useState([]);
	// const [selectedLaborSundriesIds, setSelectedLaborSundriesIds] = useState([]);
	const [selectedLaborSundriesLaborIds, setSelectedLaborSundriesLaborIds] = useState([]);
	const [selectedMaterialSundries, setSelectedMaterialSundries] = useState([]);
	const [selectedMaterialSundriesIds, setSelectedMaterialSundriesIds] = useState([]);
	
	// Transition modal states
	const [selectedLaborTransition, setSelectedLaborTransition] = useState([]);
	// const [selectedLaborTransitionIds, setSelectedLaborTransitionIds] = useState([]);
	const [selectedLaborTransitionLaborIds, setSelectedLaborTransitionLaborIds] = useState([]);
	const [selectedMaterialTransition, setSelectedMaterialTransition] = useState([]);
	const [selectedMaterialTransitionIds, setSelectedMaterialTransitionIds] = useState([]);

	// Labor modal states
	const [selectedLabor, setSelectedLabor] = useState([]);
	const [selectedLaborIds, setSelectedLaborIds] = useState([]);
	const [pricingContractSummaryData, setPricingContractSummaryData] = useState([]);

	// handle modal for all price and gpm values
	const [priceGpmModalOpen, setPriceGpmModalOpen] = useState(false);
	const [priceValue, setPriceValue] = useState();
	const [gpmValue, setGpmValue] = useState();

	// handle single primary products checkbox data
	const [carpetCheckboxData, setCarpetCheckboxData] = useState();
	const [carpetCheckboxFlag, setCarpetCheckboxFlag] = useState(false);
	const [sheetVinylCheckboxData, setSheetVinylCheckboxData] = useState();
	const [sheetVinylCheckboxFlag, setSheetVinylCheckboxFlag] = useState(false);
	const [vinylPlankCheckboxData, setVinylPlankCheckboxData] = useState();
	const [vinylPlankCheckboxFlag, setVinylPlankCheckboxFlag] = useState(false);

	// handle wallbase and sundries selected state for edit case
	const [wallbaseCheckboxData, setWallbaseCheckboxData] = useState();
	const [sundriesCheckboxData, setSundriesCheckboxData] = useState();

	// default value for GPM
	const defaultGpmValue = 10

	// handle value for tooltip
	const target = useRef(null);

	// handle missing stairs labor states
	const [missingStairsLaborIds, setMissingStairsLaborIds] = useState([])
	const [isMissingStairsLabor, setIsMissingStairsLabor] = useState(null)

	//handle missing stairs labor api response
	useEffect(() => {
		if (missingStairsLaborContractList.length > 0) {
			let tempMissingStairs = missingStairsLaborContractList[0].laborItemId ? missingStairsLaborContractList[0]?.laborItemId.filter(number=> number) : []
			setMissingStairsLaborIds(tempMissingStairs)
		}
	}, [missingStairsLaborContractList])

	// function to check whether pricing contract is created and to open summary with data
	useEffect(() => {
		if (isContractPricingAdded === true && !materialContractModalOpen) {
			setShowSummary(true);
			if(groupPricingContractId) {
				fetchPricingContract(auth[0].token, {'cutomerId': '', 'groupContractId': groupPricingContractId})
			} else {
				let checkFlag = (laborContractModalOpen || materialContractModalOpen) ? true : false
				fetchPricingContract(auth[0].token, {'cutomerId': contactInfoId[0], 'groupContractId': ''}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null)
			}
		}
	}, [isContractPricingAdded])

	// function called on page load to fetch product type data
	useEffect(() => {
		if (isAuthenticated) {
			fetchProductType(auth[0].token);
			fetchMissingLaborItemsInContract(auth[0].token, contactInfoId);
		}
	}, [auth, isAuthenticated]);

	// function to add group contract id for product pricing contract api
	useEffect(() => {
		if (groupPricingContractId) {
			setStateProductFilter({
				...stateProductFilter,
				"grpPricingContractId": groupPricingContractId ? groupPricingContractId : ""
			})
			setStateLaborFilter({
				...stateLaborFilter,
				"grpPricingContractId": groupPricingContractId ? groupPricingContractId : ""
			})
		}
	}, [groupPricingContractId])

	// function called on filter change for products
	useEffect(() => {
		if (stateProductFilter.ProductStructureId !== "") {
			let checkFlag = (laborContractModalOpen || materialContractModalOpen) ? true : false
			fetchProductForPricingContract(auth[0].token, stateProductFilter, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
		}
	}, [stateProductFilter])

	// function called on filter change for labor
	useEffect(() => {
		if (primaryProduct === 0) {
			let checkFlag = laborContractModalOpen ? true : false
			fetchLaborItemFilterItem(auth[0].token, stateLaborFilter, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
		}
		if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB || primaryProduct === TRANSITION_ID_DB || primaryProduct === WALLBASE_ID_DB) {
			fetchSubProductRequest({
				"productStructureId": parseInt(primaryProduct),
				"token": auth[0].token
			});
		}
	}, [stateLaborFilter, primaryProduct])

	const removeDuplicates = (array) => {
		return Array.from(new Set(array));
	}
	
	// pricing contract data fetched from api
	useEffect(() => {
		if(pricingContractData.length > 0) {
			const uniqueData = removeDuplicates(pricingContractData)
			setPricingContractSummaryData(uniqueData)
		}
	}, [pricingContractData])

	// fetching pricing contract data to show on edit
	useEffect(() => {
		if (pricingContractSummaryData.length > 0) {
			if (pricingContractSummaryData[0].lPricingContract.length > 0) {
				pricingContractSummaryData[0].lPricingContract.map((item) => {
					if (item.productStructureId === CARPET_ID_DB) {
						selectedCarpet.push(item);
						selectedCarpetIds.push(item.productId);
					} else if (item.productStructureId === SHEET_VINYL_ID_DB) {
						selectedSheetVinyl.push(item);
						selectedSheetVinylIds.push(item.productId);
					} else if (item.productStructureId === VINYL_PLANK_ID_DB) {
						selectedVinylPlank.push(item);
						selectedVinylPlankIds.push(item.productId);
					} else if (item.productStructureId === WALLBASE_ID_DB) {
						selectedWallbase.push(item);
						selectedWallbaseIds.push(item.productId);
					} else if (item.productStructureId === INSTALLATION_ACCESSORIES_ID_DB || item.productStructureId === TRANSITION_ID_DB) {
						selectedSundries.push(item);
						selectedSundriesIds.push(item.productId);
					}
				})
				let uniqueSelectedCarpet = _.uniqBy(selectedCarpet, "productId")
				let uniqueSelectedCarpetIds = _.uniq(selectedCarpetIds)
				let uniqueSelectedSheetVinyl = _.uniqBy(selectedSheetVinyl, "productId")
				let uniqueSelectedSheetVinylIds = _.uniq(selectedSheetVinylIds)
				let uniqueSelectedVinylPlank = _.uniqBy(selectedVinylPlank, "productId")
				let uniqueSelectedVinylPlankIds = _.uniq(selectedVinylPlankIds)
				let uniqueSelectedWallbase = _.uniqBy(selectedWallbase, "productId")
				let uniqueSelectedWallbaseIds = _.uniq(selectedWallbaseIds)
				let uniqueSelectedSundries = _.uniqBy(selectedSundries, "productId")
				let uniqueSelectedSundriesIds = _.uniq(selectedSundriesIds)

				setSelectedCarpet(uniqueSelectedCarpet);
				setSelectedSheetVinyl(uniqueSelectedSheetVinyl);
				setSelectedVinylPlank(uniqueSelectedVinylPlank);
				setSelectedWallbase(uniqueSelectedWallbase);
				setSelectedSundries(uniqueSelectedSundries);
				setSelectedCarpetIds(uniqueSelectedCarpetIds);
				setSelectedSheetVinylIds(uniqueSelectedSheetVinylIds);
				setSelectedVinylPlankIds(uniqueSelectedVinylPlankIds);
				setSelectedWallbaseIds(uniqueSelectedWallbaseIds);
				setSelectedSundriesIds(uniqueSelectedSundriesIds);
			}
			if (pricingContractSummaryData[0].lLaborPricingContract.length > 0) {
				pricingContractSummaryData[0].lLaborPricingContract.map((item) => {
					if (item.laborId === '' || !item.laborId) {
						selectedLabor.push(item);
						selectedLaborIds.push(item.laborItemId);
					} else if (item.productStructureId === CARPET_ID_DB) {
						selectedLaborCarpet.push(item);
						selectedLaborCarpetIds.push(item.laborItemId);
					} else if (item.productStructureId === SHEET_VINYL_ID_DB) {
						selectedLaborSheetVinyl.push(item);
						selectedLaborSheetVinylIds.push(item.laborItemId);
					} else if (item.productStructureId === VINYL_PLANK_ID_DB) {
						selectedLaborVinylPlank.push(item);
						selectedLaborVinylPlankIds.push(item.laborItemId);
					} else if (item.productStructureId === WALLBASE_ID_DB) {
						selectedLaborWallbase.push(item);
						// selectedLaborWallbaseIds.push(item.laborItemId);
						// selectedLaborWallbaseKitIds.push(item.kitId);
						selectedLaborWallbaseLaborIds.push(item.laborId);
					} else if (item.productStructureId === INSTALLATION_ACCESSORIES_ID_DB || item.productStructureId === TRANSITION_ID_DB) {
						if(materialContractModalOpen && item.productStructureId === TRANSITION_ID_DB) {
							selectedLaborTransition.push(item);
							// selectedLaborTransitionIds.push(item.laborItemId);
							selectedLaborTransitionLaborIds.push(item.laborId);
						} else {
							selectedLaborSundries.push(item);
							// selectedLaborSundriesIds.push(item.laborItemId);
							selectedLaborSundriesLaborIds.push(item.laborId);
						}
					}
				})
				let uniqueSelectedLabor = _.uniqBy(selectedLabor, "laborItemId")
				let uniqueSelectedLaborIds = _.uniq(selectedLaborIds)
				let uniqueSelectedLaborCarpet = _.uniqBy(selectedLaborCarpet, "laborItemId")
				let uniqueSelectedLaborCarpetIds = _.uniq(selectedLaborCarpetIds)
				let uniqueSelectedLaborSheetVinyl = _.uniqBy(selectedLaborSheetVinyl, "laborItemId")
				let uniqueSelectedLaborSheetVinylIds = _.uniq(selectedLaborSheetVinylIds)
				let uniqueSelectedLaborVinylPlank = _.uniqBy(selectedLaborVinylPlank, "laborItemId")
				let uniqueSelectedLaborVinylPlankIds = _.uniq(selectedLaborVinylPlankIds)
				// let uniqueSelectedLaborWallbase = _.uniqBy(selectedLaborWallbase, "laborItemId")
				let uniqueSelectedLaborWallbase = _.uniqBy(selectedLaborWallbase, "laborId")
				// let uniqueSelectedLaborWallbaseIds = _.uniq(selectedLaborWallbaseIds)
				// let uniqueSelectedLaborWallbaseKitIds = _.uniq(selectedLaborWallbaseKitIds)
				let uniqueSelectedLaborWallbaseLaborIds = _.uniq(selectedLaborWallbaseLaborIds)
				// let uniqueSelectedLaborSundries = _.uniqBy(selectedLaborSundries, "laborItemId")
				let uniqueSelectedLaborSundries = _.uniqBy(selectedLaborSundries, "laborId")
				// let uniqueSelectedLaborSundriesIds = _.uniq(selectedLaborSundriesIds)
				let uniqueSelectedLaborSundriesLaborIds = _.uniq(selectedLaborSundriesLaborIds)
				// let uniqueSelectedLaborTransition = _.uniqBy(selectedLaborTransition, "laborItemId")
				let uniqueSelectedLaborTransition = _.uniqBy(selectedLaborTransition, "laborId")
				// let uniqueSelectedLaborTransitionIds = _.uniq(selectedLaborTransitionIds)
				let uniqueSelectedLaborTransitionLaborIds = _.uniq(selectedLaborTransitionLaborIds)
				setSelectedLabor(uniqueSelectedLabor);
				setSelectedLaborIds(uniqueSelectedLaborIds);
				setSelectedLaborCarpet(uniqueSelectedLaborCarpet);
				setSelectedLaborSheetVinyl(uniqueSelectedLaborSheetVinyl);
				setSelectedLaborVinylPlank(uniqueSelectedLaborVinylPlank);
				setSelectedLaborWallbase(uniqueSelectedLaborWallbase);
				setSelectedLaborSundries(uniqueSelectedLaborSundries);
				setSelectedLaborTransition(uniqueSelectedLaborTransition);
				setSelectedLaborCarpetIds(uniqueSelectedLaborCarpetIds);
				setSelectedLaborSheetVinylIds(uniqueSelectedLaborSheetVinylIds);
				setSelectedLaborVinylPlankIds(uniqueSelectedLaborVinylPlankIds);
				// setSelectedLaborWallbaseIds(uniqueSelectedLaborWallbaseIds);
				// setSelectedLaborWallbaseKitIds(uniqueSelectedLaborWallbaseKitIds);
				setSelectedLaborWallbaseLaborIds(uniqueSelectedLaborWallbaseLaborIds);
				// setSelectedLaborSundriesIds(uniqueSelectedLaborSundriesIds);
				setSelectedLaborSundriesLaborIds(uniqueSelectedLaborSundriesLaborIds);
				// setSelectedLaborTransitionIds(uniqueSelectedLaborTransitionIds);
				setSelectedLaborTransitionLaborIds(uniqueSelectedLaborTransitionLaborIds);
			}
			if (pricingContractSummaryData[0].lMaterialPricingContract.length > 0) {
				pricingContractSummaryData[0].lMaterialPricingContract.map((item) => {
					if (item.productStructureId === CARPET_ID_DB) {
						selectedMaterialCarpet.push(item);
						selectedMaterialCarpetIds.push(item.materialId);
					} else if (item.productStructureId === SHEET_VINYL_ID_DB) {
						selectedMaterialSheetVinyl.push(item);
						selectedMaterialSheetVinylIds.push(item.materialId);
					} else if (item.productStructureId === VINYL_PLANK_ID_DB) {
						selectedMaterialVinylPlank.push(item);
						selectedMaterialVinylPlankIds.push(item.materialId);
					} else if (item.productStructureId === WALLBASE_ID_DB) {
						selectedMaterialWallbase.push(item);
						selectedMaterialWallbaseIds.push(item.materialId);
					} else if (item.productStructureId === INSTALLATION_ACCESSORIES_ID_DB || item.productStructureId === TRANSITION_ID_DB) {
						if(materialContractModalOpen && item.productStructureId === TRANSITION_ID_DB) {
							selectedMaterialTransition.push(item);
							selectedMaterialTransitionIds.push(item.materialId);
						} else {
							selectedMaterialSundries.push(item);
							selectedMaterialSundriesIds.push(item.materialId);
						}
					}
				})
				let uniqueSelectedMaterialCarpet = _.uniqBy(selectedMaterialCarpet, "materialId")
				let uniqueSelectedMaterialSheetVinyl = _.uniqBy(selectedMaterialSheetVinyl, "materialId")
				let uniqueSelectedMaterialVinylPlank = _.uniqBy(selectedMaterialVinylPlank, "materialId")
				let uniqueSelectedMaterialWallbase = _.uniqBy(selectedMaterialWallbase, "materialId")
				let uniqueSelectedMaterialSundries = _.uniqBy(selectedMaterialSundries, "materialId")
				let uniqueSelectedMaterialTransition = _.uniqBy(selectedMaterialTransition, "materialId")
				let uniqueSelectedMaterialCarpetIds = _.uniq(selectedMaterialCarpetIds)
				let uniqueSelectedMaterialSheetVinylIds = _.uniq(selectedMaterialSheetVinylIds)
				let uniqueSelectedMaterialVinylPlankIds = _.uniq(selectedMaterialVinylPlankIds)
				let uniqueSelectedMaterialWallbaseIds = _.uniq(selectedMaterialWallbaseIds)
				let uniqueSelectedMaterialSundriesIds = _.uniq(selectedMaterialSundriesIds)
				let uniqueSelectedMaterialTransitionIds = _.uniq(selectedMaterialTransitionIds)
				setSelectedMaterialCarpet(uniqueSelectedMaterialCarpet);
				setSelectedMaterialSheetVinyl(uniqueSelectedMaterialSheetVinyl);
				setSelectedMaterialVinylPlank(uniqueSelectedMaterialVinylPlank);
				setSelectedMaterialWallbase(uniqueSelectedMaterialWallbase);
				setSelectedMaterialSundries(uniqueSelectedMaterialSundries);
				setSelectedMaterialTransition(uniqueSelectedMaterialTransition);
				setSelectedMaterialCarpetIds(uniqueSelectedMaterialCarpetIds);
				setSelectedMaterialSheetVinylIds(uniqueSelectedMaterialSheetVinylIds);
				setSelectedMaterialVinylPlankIds(uniqueSelectedMaterialVinylPlankIds);
				setSelectedMaterialWallbaseIds(uniqueSelectedMaterialWallbaseIds);
				setSelectedMaterialSundriesIds(uniqueSelectedMaterialSundriesIds);
				setSelectedMaterialTransitionIds(uniqueSelectedMaterialTransitionIds);
			}
		}
	}, [pricingContractSummaryData])

	// handle all products selected material kit data
	const handleSelectedMaterialKitData = () => {
		// let tempSelectedMaterialKitData = [...selectedMaterialCarpet, ...selectedMaterialSheetVinyl, ...selectedMaterialVinylPlank, ...selectedMaterialWallbase]
		// return tempSelectedMaterialKitData
		if (primaryProduct === CARPET_ID_DB) {
			let tempSelectedMaterialKitData = [...selectedMaterialSheetVinyl, ...selectedMaterialVinylPlank]
			return tempSelectedMaterialKitData
		} else if (primaryProduct === SHEET_VINYL_ID_DB) {
			let tempSelectedMaterialKitData = [...selectedMaterialCarpet, ...selectedMaterialVinylPlank]
			return tempSelectedMaterialKitData
		} else if (primaryProduct === VINYL_PLANK_ID_DB) {
			let tempSelectedMaterialKitData = [...selectedMaterialCarpet, ...selectedMaterialSheetVinyl]
			return tempSelectedMaterialKitData
		} else if (primaryProduct === WALLBASE_ID_DB) {
			let tempSelectedMaterialKitData = [...selectedMaterialCarpet, ...selectedMaterialSheetVinyl, ...selectedMaterialVinylPlank]
			return tempSelectedMaterialKitData
		} else {
			let tempSelectedMaterialKitData = [...selectedMaterialCarpet, ...selectedMaterialSheetVinyl, ...selectedMaterialVinylPlank, ...selectedMaterialWallbase]
			return tempSelectedMaterialKitData
		}
	}

	// handle all products selected labor kit data
	const handleSelectedLaborKitData = () => {
		let tempSelectedLaborKitData = [...selectedLaborCarpet, ...selectedLaborSheetVinyl, ...selectedLaborVinylPlank, ...selectedLaborWallbase, ...selectedLaborSundries, ...selectedLaborTransition]
		return tempSelectedLaborKitData
	}

	// function to remove selected data to handle double selection for installation accessories
	const handleSelectedListing = (productListData) => {
		let modifiedData = []
		let tempSelectedMaterialKitData = handleSelectedMaterialKitData()

		let tempMaterialSelectedIds = []
		tempSelectedMaterialKitData.map(data => {
			tempMaterialSelectedIds.push(data.productId)
		})
		if (tempSelectedMaterialKitData && tempSelectedMaterialKitData.length > 0) {
			tempSelectedMaterialKitData.map(data => {
				productListData.map(value => {
					if (!tempMaterialSelectedIds.includes(value.productId)) {
						modifiedData.push(value)
					}
				})
			})
			tempSelectedMaterialKitData.map(data => {
				productList.map((value, index) => {
					if (tempMaterialSelectedIds.includes(value.productId)) {
						productList.splice(index, 1)
					}
				})
			})
			modifiedData = _.uniqBy(modifiedData, "productId")
			return modifiedData
		} else {
			return productListData
		}
	}

	// function to remove selected data to handle double selection for wallbase
	const handleSelectedList = (productListData) => {
		let modifiedData = []
		let tempSelectedMaterialKitData = handleSelectedMaterialKitData()

		let tempMaterialSelectedIds = []
		tempSelectedMaterialKitData.map(data => {
			tempMaterialSelectedIds.push(data.productId)
		})

		if (tempSelectedMaterialKitData && tempSelectedMaterialKitData.length > 0) {
			tempSelectedMaterialKitData.map(data => {
				productListData.map(value => {
					if (!tempMaterialSelectedIds.includes(value.productId)) {
						modifiedData.push(value)
					}
				})
			})
			tempSelectedMaterialKitData.map(data => {
				productList.map((value, index) => {
					if (tempMaterialSelectedIds.includes(value.productId)) {
						productList.splice(index, 1)
					}
				})
			})
			modifiedData = _.uniqBy(modifiedData, "productId")
			return modifiedData
		} else {
			return productListData
		}
	}

	// function called on product list change from api response
	useEffect(() => {
		let filteredArray = []
		if(productList.length > 0 && materialContractModalOpen) {
			const indexes = Array.from({ length: productList.length }, (_, i) => i).reverse();
			indexes.forEach(index => {
				if (productList[index].productStructureId === TRANSITION_ID_DB && primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
						productList.splice(index, 1);
				}
			});
			productList.map(data => {
				if (data.productStructureId === INSTALLATION_ACCESSORIES_ID_DB) {
					filteredArray.push(data)
				}
			})
		}
		if(filteredArray && materialContractModalOpen && primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
			setProductListData(filteredArray);
		} else {
			if (primaryProduct === WALLBASE_ID_DB) {
				let productListData =  handleSelectedList(productList);
				setProductListData(productListData);
			}
			if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
				let productListData =  handleSelectedListing(productList);
				setProductListData(productListData);
			}
			if (primaryProduct !== INSTALLATION_ACCESSORIES_ID_DB || primaryProduct !== WALLBASE_ID_DB) {
				setProductListData(productList);
			}
		}
	}, [productList])

	// handle misc. labor listing
	const handleMiscLaborData = (listing) => {
		let tempSelectedLaborKitData = handleSelectedLaborKitData()
		
		let tempLaborSelectedIds = []
		let modifiedData = []
		tempSelectedLaborKitData.map(data => {
			tempLaborSelectedIds.push(data.laborItemId)
		})

		laborItemList.map((value, index) => {
			if (tempLaborSelectedIds.includes(value.productId)) {
				laborItemList.splice(index, 1)
			}
		})

		if (tempSelectedLaborKitData && tempSelectedLaborKitData.length > 0) {
			tempSelectedLaborKitData.map(data => {
				listing.map(value => {
					if (!tempLaborSelectedIds.includes(value.laborItemId)) {
						modifiedData.push(value)
					}
				})
			})
			listing.map((value, index) => {
				if (tempLaborSelectedIds.includes(value.laborItemId)) {
					listing.splice(index, 1)
				}
			})
			modifiedData = _.uniqBy(modifiedData, "laborItemId")
			return modifiedData
		} else {
			return listing
		}
	}

	// function called on labor list change from api response
	useEffect(() => {
		// if (laborContractModalOpen) {
		// 	setLaborListData(laborItemList);
		// } else {
			// if (!primaryProduct) {
			// 	let miscLaborListing = handleMiscLaborData(laborItemList)
			// 	setLaborListData(miscLaborListing);
			// } else {
				let miscLaborListing = handleMiscLaborData(laborItemList)
				setLaborListData(miscLaborListing);
			// }
			
		// }
		// setLaborListData(laborItemList);
	}, [laborItemList])

	// handle listing for misc labor
	useEffect(() => {
		if (!primaryProduct && !materialContractModalOpen) {
			let tempSelectedLaborKitData = handleSelectedLaborKitData()
		
			let tempLaborSelectedIds = []
			tempSelectedLaborKitData.map(data => {
				tempLaborSelectedIds.push(data.laborItemId)
			})
			laborItemList.map((value, index) => {
				if (tempLaborSelectedIds.includes(value.laborItemId)) {
					laborItemList.splice(index, 1)
				}
			})

			laborListData.map((value, index) => {
				if (tempLaborSelectedIds.includes(value.laborItemId)) {
					laborListData.splice(index, 1)
				}
			})

		}
	}, [laborItemList, laborListData])

	// handle carpet labor list data
	const handleCarpetLaborData = (laborList) => {
		if (selectedLaborCarpet && selectedLaborCarpet.length > 0 && laborList && laborList.length > 0) {
			laborList.map(data => {
				selectedLaborCarpet.map(value => {
					if(data.laborId === value.laborId && data.laborItemId === value.laborItemId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductLaborData(laborList);
	}

	// handle sheet vinyl labor list data
	const handleSheetVinylLaborData = (laborList) => {
		if (selectedLaborSheetVinyl && selectedLaborSheetVinyl.length > 0 && laborList && laborList.length > 0) {
			laborList.map(data => {
				selectedLaborSheetVinyl.map(value => {
					if(data.laborId === value.laborId && data.laborItemId === value.laborItemId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductLaborData(laborList);
	}

	// handle vinyl plank labor list data
	const handleVinylPlankLaborData = (laborList) => {
		if (selectedLaborVinylPlank && selectedLaborVinylPlank.length > 0 && laborList && laborList.length > 0) {
			laborList.map(data => {
				selectedLaborVinylPlank.map(value => {
					if(data.laborId === value.laborId && data.laborItemId === value.laborItemId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductLaborData(laborList);
	}

	// handle wallbase labor list data
	const handleWallbaseLaborData = (laborList) => {
		if (selectedLaborWallbase && selectedLaborWallbase.length > 0 && laborList && laborList.length > 0) {
			laborList.map(data => {
				selectedLaborWallbase.map(value => {
					// if(data.laborId === value.laborId && data.laborItemId === value.laborItemId) {
					if(data.laborId === value.laborId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductLaborData(laborList);
	}

	// handle sundries labor list data
	const handleSundriesLaborData = (laborList) => {
			// let miscLaborListing = handleMiscLaborData(laborList)
		if (selectedLaborSundries && selectedLaborSundries.length > 0 && laborList && laborList.length > 0) {
			laborList.map(data => {
				selectedLaborSundries.map(value => {
					// if(data.laborId === value.laborId && data.laborItemId === value.laborItemId) {
					if(data.laborId === value.laborId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductLaborData(laborList);
	}

	// handle removal of selected data from labor kits of products
	const handleDuplicateOnLaborSelection = (data) => {
		let tempSelectedLaborKitData = handleSelectedLaborKitData()
		if (tempSelectedLaborKitData && tempSelectedLaborKitData.length > 0) {
			let tempLaborSelectedIds = []
			let tempSelectedProductIds = []
			let tempSelectedProductObject = []
			tempSelectedLaborKitData.map(data => {
				tempLaborSelectedIds.push(data.laborItemId)
				tempSelectedProductIds.push(data.productStructureId)
				tempSelectedProductObject.push({'productStructureId': data.productStructureId, 'laborItemId': data.laborItemId})
			})
			// laborList = laborList.filter(data => !tempLaborSelectedIds.includes(data.laborItemId))
			tempLaborSelectedIds.map(value => {
				laborList.map((data, index) => {
					if(tempLaborSelectedIds.includes(data.laborItemId) && !tempSelectedProductIds.includes(data.productStructureId)) {
						laborList.splice(index, 1)
					}
				})
			})
			if (isContractPricingAdded) {
				tempLaborSelectedIds.map(value => {
					laborList.map((data, index) => {
						tempSelectedProductObject.map((value, position) => {
							if (value.productStructureId !== data.productStructureId) {
								if (value.laborItemId === data.laborItemId) {
									laborList.splice(index, 1)
								}
							}
						})
					})
				})
			}
			// setPrimaryProductMaterialData(materialList)
		}
	}

	// function called on labor list for primary product change from api response
	useEffect(() => {
		if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen) {
			if(laborTransitionList && laborTransitionList.length > 0) {
				for(let data of laborTransitionList) {
					laborList.push(data)
				}
			}
		}
		if (materialContractModalOpen || laborContractModalOpen || !primaryProduct) {
			setPrimaryProductLaborData(laborList);
		}
		// setPrimaryProductLaborData(laborList);
		if (primaryProduct === CARPET_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleDuplicateOnLaborSelection(laborList)
			handleCarpetLaborData(laborList)
		}
		if (primaryProduct === SHEET_VINYL_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleDuplicateOnLaborSelection(laborList)
			handleSheetVinylLaborData(laborList)
		}
		if (primaryProduct === VINYL_PLANK_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleDuplicateOnLaborSelection(laborList)
			handleVinylPlankLaborData(laborList)
		}
		if (primaryProduct === WALLBASE_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleDuplicateOnLaborSelection(laborList)
			handleWallbaseLaborData(laborList)
		}
		if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleSundriesLaborData(laborList)
		}
	}, [laborList])

	// handle carpet material list data
	const handleCarpetMaterialData = (materialList) => {
		if (selectedMaterialCarpet && selectedMaterialCarpet.length > 0 && materialList && materialList.length > 0) {
			materialList.map(data => {
				selectedMaterialCarpet.map(value => {
					if(data.materialId === value.materialId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductMaterialData(materialList);
	}

	// handle sheet vinyl material list data
	const handleSheetVinylMaterialData = (materialList) => {
		if (selectedMaterialSheetVinyl && selectedMaterialSheetVinyl.length > 0 && materialList && materialList.length > 0) {
			materialList.map(data => {
				selectedMaterialSheetVinyl.map(value => {
					if(data.materialId === value.materialId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductMaterialData(materialList);
	}

	// handle vinyl plank material list data
	const handleVinylPlankMaterialData = (materialList) => {
		if (selectedMaterialVinylPlank && selectedMaterialVinylPlank.length > 0 && materialList && materialList.length > 0) {
			materialList.map(data => {
				selectedMaterialVinylPlank.map(value => {
					if(data.materialId === value.materialId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductMaterialData(materialList);
	}

	// handle wallbase material list data
	const handleWallbaseMaterialData = (materialList) => {
		if (selectedMaterialWallbase && selectedMaterialWallbase.length > 0 && materialList && materialList.length > 0) {
			materialList.map(data => {
				selectedMaterialWallbase.map(value => {
					if(data.materialId === value.materialId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductMaterialData(materialList);
	}

	// handle sundries material list data
	const handleSundriesMaterialData = (materialList) => {
		if (selectedMaterialSundries && selectedMaterialSundries.length > 0 && materialList && materialList.length > 0) {
			materialList.map(data => {
				selectedMaterialSundries.map(value => {
					if(data.materialId === value.materialId) {
						data.price = value.price
						data.gpm = value.gpm
					}
				})
			})
		}
		setPrimaryProductMaterialData(materialList);
	}

		// handle removal of selected data from material kits of products
		const handleDuplicateOnMaterialSelection = (data) => {
			let tempSelectedMaterialKitData = handleSelectedMaterialKitData()
			if (tempSelectedMaterialKitData && tempSelectedMaterialKitData.length > 0) {
				let tempMaterialSelectedIds = []
				let tempSelectedProductIds = []
				tempSelectedMaterialKitData.map(data => {
					tempMaterialSelectedIds.push(data.productId)
					tempSelectedProductIds.push(data.productStructureId)
				})
	
				tempMaterialSelectedIds.map((data, index) => {
					materialList.map((data, index) => {
						if (tempMaterialSelectedIds.includes(data.productId) && !tempSelectedProductIds.includes(data.productStructureId)) {				
							materialList.splice(index, 1)
						}
					})
				})
			}
		}

	// function called on material list for primary product change from api response
	useEffect(() => {
		if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen) {
			if(materialTransitionList && materialTransitionList.length > 0) {
				for(let data of materialTransitionList) {
					materialList.push(data)
				}
			}
		}
		if (materialContractModalOpen || laborContractModalOpen || !primaryProduct) {
			setPrimaryProductMaterialData(materialList)
		}
		// setPrimaryProductMaterialData(materialList);
		if (primaryProduct === CARPET_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleDuplicateOnMaterialSelection(materialList)
			handleCarpetMaterialData(materialList)
		}
		if (primaryProduct === SHEET_VINYL_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleDuplicateOnMaterialSelection(materialList)
			handleSheetVinylMaterialData(materialList)
		}
		if (primaryProduct === VINYL_PLANK_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleDuplicateOnMaterialSelection(materialList)
			handleVinylPlankMaterialData(materialList)
		}
		if (primaryProduct === WALLBASE_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleDuplicateOnMaterialSelection(materialList)
			handleWallbaseMaterialData(materialList)
		}
		if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			handleSundriesMaterialData(materialList)
		}
	}, [materialList])

	// handle duplicates on selection for wallbase product list to material kit
	useEffect(() => {
		if (selectedMaterialWallbase && selectedMaterialWallbase.length > 0 && selectedWallbase && selectedWallbase.length > 0 && primaryProduct === WALLBASE_ID_DB && !materialContractModalOpen) {
			let tempIds = []
			selectedWallbase.map(data => {
				tempIds.push(data.productId)
			})
			let tempMaterialData = []
			let tempMaterialId = []
			if (tempIds.length > 0) {
				let tempSelectedMaterialWallbase = selectedMaterialWallbase
				
				tempSelectedMaterialWallbase.map(data => {
					if (!tempIds.includes(data.productId)) {
						tempMaterialData.push(data)
						tempMaterialId.push(data.materialId)
					}
				})
			}
			setSelectedMaterialWallbase(tempMaterialData);
			setSelectedMaterialWallbaseIds(tempMaterialId);
		}
	}, [productList, selectedWallbase])

	// handle sub product structure response data
	const productSubStructure = useMemo(() => {
		if(getSubProductData) {
			if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen) {
				let modifiedArray = [...getSubProductData, ...getTransitionSubProductData]
				return modifiedArray.map(v => ({ label: v.productStructure, value: v.productStructureId }))
			} else {
				return getSubProductData.map(v => ({ label: v.productStructure, value: v.productStructureId }))
			}
		}
	}, [getSubProductData, getTransitionSubProductData])

	// transition labor data from api response
	useEffect(() => {
		if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen) {
			if(laborTransitionList && laborTransitionList.length > 0) {
				for(let data of laborTransitionList) {
					laborList.push(data)
				}
			}
			let laborData = [...primaryProductLaborData, ...laborList]
			// let uniqLaborData = _.uniqBy(laborData, "laborItemId")
			let uniqLaborData = _.uniqBy(laborData, "laborId")
			setPrimaryProductLaborData(uniqLaborData)
		}
		if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && materialContractModalOpen) {
			setPrimaryProductLaborData([...primaryProductLaborData])
		}
		if(primaryProduct === TRANSITION_ID_DB && materialContractModalOpen) {
			setPrimaryProductLaborData([...laborTransitionList])
		}
	}, [laborTransitionList])

	// transition material data from api response
	useEffect(() => {
		if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen) {
			if(materialTransitionList && materialTransitionList.length > 0) {
				for(let data of materialTransitionList) {
					materialList.push(data)
				}
			}
			let materialData = [...primaryProductMaterialData, ...materialList]
			let uniqMaterialData = _.uniqBy(materialData, "materialId")
			setPrimaryProductMaterialData(uniqMaterialData)
		}
		if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && materialContractModalOpen) {
			setPrimaryProductMaterialData([...primaryProductMaterialData])
		}
		if(primaryProduct === TRANSITION_ID_DB && materialContractModalOpen) {
			setPrimaryProductMaterialData([...materialTransitionList])
		}
	}, [materialTransitionList])

	// handle sundries material kit
	useEffect(() => {
		if (selectedSundries && selectedSundries.length > 0 && materialList && materialList.length > 0 && primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen && !laborContractModalOpen) {
			let tempMaterialSelectedIds = []
			selectedSundries.map(data => {
				tempMaterialSelectedIds.push(data.productId)
			})
			if (materialList && materialList.length > 0) {
				materialList = materialList.filter(data => !tempMaterialSelectedIds.includes(data.productId))
				setPrimaryProductMaterialData(materialList);
				if (selectedMaterialSundries && selectedMaterialSundries.length > 0) {
					let selectedMaterialSundriesData = selectedMaterialSundries
					let selectedData = []
					let selectedId = []
					// let selectedMaterialSundriesData = selectedMaterialSundries.filter(data => !tempMaterialSelectedIds.includes(data.productId))
					selectedMaterialSundriesData.map(data => {
						if (!tempMaterialSelectedIds.includes(data.productId)) {
							selectedData.push(data)
							selectedId.push(data.materialId)
						}
					})
					setSelectedMaterialSundries(selectedData)
					setSelectedMaterialSundriesIds(selectedId)
				}
			}
			let tempSelectedMaterialKitData = handleSelectedMaterialKitData()
			if (tempSelectedMaterialKitData && tempSelectedMaterialKitData.length > 0) {
				let tempMaterialSelectedIds = []
				tempSelectedMaterialKitData.map(data => {
					tempMaterialSelectedIds.push(data.productId)
				})
				materialList = materialList.filter(data => !tempMaterialSelectedIds.includes(data.productId))
				setPrimaryProductMaterialData(materialList)
			}
		}
	}, [materialList, selectedSundries])

	// handle misc. accessories kits data
	useEffect(() => {
		if (selectedMaterialSundries && selectedMaterialSundries.length > 0 && primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen) {
			let modifiedData = []
			let modifiedDataIds = []
			let tempSelectedMaterialKitData = handleSelectedMaterialKitData()

			let tempMaterialSelectedIds = []
			tempSelectedMaterialKitData.map(data => {
				tempMaterialSelectedIds.push(data.productId)
			})

			if (tempMaterialSelectedIds && tempMaterialSelectedIds.length > 0) {
				
				tempMaterialSelectedIds.map((data, index) => {
					materialList.map((data, index) => {
						if (tempMaterialSelectedIds.includes(data.productId)) {				
							materialList.splice(index, 1)
						}
					})
				})

				selectedMaterialSundries.map(value => {
					if (!tempMaterialSelectedIds.includes(value.productId)) {
						modifiedDataIds.push(value.materialId)
						modifiedData.push(value)
					}
				})
				modifiedData = Object.values(modifiedData)
				setSelectedMaterialSundries(modifiedData)
				modifiedDataIds = Object.values(modifiedDataIds)
				setSelectedMaterialSundriesIds(modifiedDataIds)
			}
		}
	}, [materialList, primaryProductMaterialData, selectedMaterialTransition])

	// Function to handle Primary and Secondary Selection
	const handleProductsSelection = (data, id) => {
		if (id === CARPET_ID_DB) {
			setCarpetCheckboxFlag(true)
			setCarpetCheckboxData(data)
			if(selectedCarpetIds.includes(data.productId)) {
				let newSelectedCarpetData = []
				if((!data.isPrimary && data.isSecondary) ||
						(data.isPrimary && !data.isSecondary) ||
						(data.isPrimary && data.isSecondary)
					) {
					newSelectedCarpetData = [...selectedCarpet, data]
				}
				if(!data.isPrimary && !data.isSecondary) {
					let tempData = selectedCarpet.filter(val => val.productId !== data.productId)
					let tempIds = selectedCarpetIds.filter(val => val !== data.productId)
					setSelectedCarpet([...tempData])
					setSelectedCarpetIds([...tempIds])
					newSelectedCarpetData = [...tempData]
				}

				let checkFlag = true
				if (data.isPrimary) {
					if (selectedCarpetIds.length > 0 && selectedCarpetIds.includes(data.productId)) {
						checkFlag = false
					}
				}

				if (checkFlag) {
					// for labor auto select
					if (newSelectedCarpetData.length > 0) {
						newSelectedCarpetData.map((carpetItem) => {
							const newSelectedLabor = selectedLaborCarpet.filter(element => element.storeId === carpetItem.storeId);
							setSelectedLaborCarpet(newSelectedLabor);
							const newSelectedLaborCarpetIds = [];
							if (newSelectedLabor.length > 0) {
								newSelectedLabor.map((i) => {
									newSelectedLaborCarpetIds.push(i.laborItemId);
								})
							}
							setSelectedLaborCarpetIds(newSelectedLaborCarpetIds);
						})
					} else {
						setSelectedLaborCarpet([]);
						setSelectedLaborCarpetIds([]);
					}

					// for material auto select
					if (newSelectedCarpetData.length > 0) {
						newSelectedCarpetData.map((carpetItem) => {
							const newSelectedMaterial = selectedMaterialCarpet.filter(element => element.storeId === carpetItem.storeId);
							setSelectedMaterialCarpet(newSelectedMaterial);
							const newSelectedMaterialCarpetIds = [];
							if (newSelectedMaterial.length > 0) {
								newSelectedMaterial.map((i) => {
									newSelectedMaterialCarpetIds.push(i.materialId);
								})
							}
							setSelectedMaterialCarpetIds(newSelectedMaterialCarpetIds);
						})
					} else {
						setSelectedMaterialCarpet([]);
						setSelectedMaterialCarpetIds([]);
					}
				}
				
			} else {
				setSelectedCarpet([...selectedCarpet, data]);
				setSelectedCarpetIds([...selectedCarpetIds, data.productId]);

				let checkFlag = true
				if (data.isPrimary) {
					if (selectedCarpetIds.length > 0 && !selectedCarpetIds.includes(data.productId)) {
						checkFlag = false
					}
				}

				if (checkFlag) {
					// for labor auto select
					if (laborList.length > 0) {
						laborList.map((laborItem) => {
							if (!selectedLaborCarpetIds.includes(laborItem.laborItemId)) {
								selectedLaborCarpet.push(laborItem);
								selectedLaborCarpetIds.push(laborItem.laborItemId);
							}
						})
						setSelectedLaborCarpet(selectedLaborCarpet);
						setSelectedLaborCarpetIds(selectedLaborCarpetIds);
					}

					// for material auto select
					if (materialList.length > 0) {
						materialList.map((materialItem) => {
							if (!selectedMaterialCarpetIds.includes(materialItem.materialId)) {
								selectedMaterialCarpet.push(materialItem);
								selectedMaterialCarpetIds.push(materialItem.materialId);
							}
						})
						setSelectedMaterialCarpet(selectedMaterialCarpet);
						setSelectedMaterialCarpetIds(selectedMaterialCarpetIds);
					}
				}
			}
		} else if (id === SHEET_VINYL_ID_DB) {
			setSheetVinylCheckboxFlag(true)
			setSheetVinylCheckboxData(data)
			if(selectedSheetVinylIds.includes(data.productId)) {

				let newSelectedSheetVinylData = []
				if((!data.isPrimary && data.isSecondary) ||
						(data.isPrimary && !data.isSecondary) ||
						(data.isPrimary && data.isSecondary)
					) {
					newSelectedSheetVinylData = [...selectedSheetVinyl, data]
				}
				if(!data.isPrimary && !data.isSecondary) {
					let tempData = selectedSheetVinyl.filter(val => val.productId !== data.productId)
					let tempIds = selectedSheetVinylIds.filter(val => val !== data.productId)
					setSelectedSheetVinyl([...tempData])
					setSelectedSheetVinylIds([...tempIds])
					newSelectedSheetVinylData = [...tempData]
				}

				let checkFlag = true
				if (data.isPrimary) {
					if (selectedSheetVinylIds.length > 0 && selectedSheetVinylIds.includes(data.productId)) {
						checkFlag = false
					}
				}

				if (checkFlag) {
					// for labor auto select
					if (newSelectedSheetVinylData.length > 0) {
						newSelectedSheetVinylData.map((sheetVinylItem) => {
							const newSelectedLabor = selectedLaborSheetVinyl.filter(element => element.storeId === sheetVinylItem.storeId);
							setSelectedLaborSheetVinyl(newSelectedLabor);
							const newSelectedLaborSheetVinylIds = [];
							if (newSelectedLabor.length > 0) {
								newSelectedLabor.map((i) => {
									newSelectedLaborSheetVinylIds.push(i.laborItemId);
								})
							}
							setSelectedLaborSheetVinylIds(newSelectedLaborSheetVinylIds);
						})
					} else {
						setSelectedLaborSheetVinyl([]);
						setSelectedLaborSheetVinylIds([]);
					}

					// for material auto select
					if (newSelectedSheetVinylData.length > 0) {
						newSelectedSheetVinylData.map((sheetVinylItem) => {
							const newSelectedMaterial = selectedMaterialSheetVinyl.filter(element => element.storeId === sheetVinylItem.storeId);
							setSelectedMaterialSheetVinyl(newSelectedMaterial);
							const newSelectedMaterialSheetVinylIds = [];
							if (newSelectedMaterial.length > 0) {
								newSelectedMaterial.map((i) => {
									newSelectedMaterialSheetVinylIds.push(i.materialId);
								})
							}
							setSelectedMaterialSheetVinylIds(newSelectedMaterialSheetVinylIds);
						})
					} else {
						setSelectedMaterialSheetVinyl([]);
						setSelectedMaterialSheetVinylIds([]);
					}
				}
			} else {
				setSelectedSheetVinyl([...selectedSheetVinyl, data]);
				setSelectedSheetVinylIds([...selectedSheetVinylIds, data.productId]);

				let checkFlag = true
				if (data.isPrimary) {
					if (selectedSheetVinylIds.length > 0 && !selectedSheetVinylIds.includes(data.productId)) {
						checkFlag = false
					}
				}

				if (checkFlag) {
					// for labor auto select
					if (laborList.length > 0) {
						laborList.map((laborItem) => {
							if (!selectedLaborSheetVinylIds.includes(laborItem.laborItemId)) {
								selectedLaborSheetVinyl.push(laborItem);
								selectedLaborSheetVinylIds.push(laborItem.laborItemId);
							}
						})
						setSelectedLaborSheetVinyl(selectedLaborSheetVinyl);
						setSelectedLaborSheetVinylIds(selectedLaborSheetVinylIds);
					}

					// for material auto select
					if (materialList.length > 0) {
						materialList.map((materialItem) => {
							if (!selectedMaterialSheetVinylIds.includes(materialItem.materialId)) {
								selectedMaterialSheetVinyl.push(materialItem);
								selectedMaterialSheetVinylIds.push(materialItem.materialId);
							}
						})
						setSelectedMaterialSheetVinyl(selectedMaterialSheetVinyl);
						setSelectedMaterialSheetVinylIds(selectedMaterialSheetVinylIds);
					}
				}
			}
		} else if (id === VINYL_PLANK_ID_DB) {
			setVinylPlankCheckboxFlag(true)
			setVinylPlankCheckboxData(data)
			if(selectedVinylPlankIds.includes(data.productId)) {

				let newSelectedVinylPlankData = []
				if((!data.isPrimary && data.isSecondary) ||
						(data.isPrimary && !data.isSecondary) ||
						(data.isPrimary && data.isSecondary)
					) {
					newSelectedVinylPlankData = [...selectedVinylPlank, data]
				}
				if(!data.isPrimary && !data.isSecondary) {
					let tempData = selectedVinylPlank.filter(val => val.productId !== data.productId)
					let tempIds = selectedVinylPlankIds.filter(val => val !== data.productId)
					setSelectedVinylPlank([...tempData])
					setSelectedVinylPlankIds([...tempIds])
					newSelectedVinylPlankData = [...tempData]
				}

				let checkFlag = true
				if (data.isPrimary) {
					if (selectedVinylPlankIds.length > 0 && selectedVinylPlankIds.includes(data.productId)) {
						checkFlag = false
					}
				}

				if (checkFlag) {
					// for labor auto select
					if (newSelectedVinylPlankData.length > 0) {
						newSelectedVinylPlankData.map((vinylPlankItem) => {
							const newSelectedLabor = selectedLaborVinylPlank.filter(element => element.storeId === vinylPlankItem.storeId);
							setSelectedLaborVinylPlank(newSelectedLabor);
							const newSelectedLaborVinylPlankIds = [];
							if (newSelectedLabor.length > 0) {
								newSelectedLabor.map((i) => {
									newSelectedLaborVinylPlankIds.push(i.laborItemId);
								})
							}
							setSelectedLaborVinylPlankIds(newSelectedLaborVinylPlankIds);
						})
					} else {
						setSelectedLaborVinylPlank([]);
						setSelectedLaborVinylPlankIds([]);
					}

					// for material auto select
					if (newSelectedVinylPlankData.length > 0) {
						newSelectedVinylPlankData.map((vinylPlankItem) => {
							const newSelectedMaterial = selectedMaterialVinylPlank.filter(element => element.storeId === vinylPlankItem.storeId);
							setSelectedMaterialVinylPlank(newSelectedMaterial);
							const newSelectedMaterialVinylPlankIds = [];
							if (newSelectedMaterial.length > 0) {
								newSelectedMaterial.map((i) => {
									newSelectedMaterialVinylPlankIds.push(i.materialId);
								})
							}
							setSelectedMaterialVinylPlankIds(newSelectedMaterialVinylPlankIds);
						})
					} else {
						setSelectedMaterialVinylPlank([]);
						setSelectedMaterialVinylPlankIds([]);
					}
				}
			} else {
				setSelectedVinylPlank([...selectedVinylPlank, data]);
				setSelectedVinylPlankIds([...selectedVinylPlankIds, data.productId]);

				let checkFlag = true
				if (data.isPrimary) {
					if (selectedVinylPlankIds.length > 0 && !selectedVinylPlankIds.includes(data.productId)) {
						checkFlag = false
					}
				}

				if (checkFlag) {
					// for labor auto select
					if (laborList.length > 0) {
						laborList.map((laborItem) => {
							if (!selectedLaborVinylPlankIds.includes(laborItem.laborItemId)) {
								selectedLaborVinylPlank.push(laborItem);
								selectedLaborVinylPlankIds.push(laborItem.laborItemId);
							}
						})
						setSelectedLaborVinylPlank(selectedLaborVinylPlank);
						setSelectedLaborVinylPlankIds(selectedLaborVinylPlankIds);
					}

					// for material auto select
					if (materialList.length > 0) {
						materialList.map((materialItem) => {
							if (!selectedMaterialVinylPlankIds.includes(materialItem.materialId)) {
								selectedMaterialVinylPlank.push(materialItem);
								selectedMaterialVinylPlankIds.push(materialItem.materialId);
							}
						})
						setSelectedMaterialVinylPlank(selectedMaterialVinylPlank);
						setSelectedMaterialVinylPlankIds(selectedMaterialVinylPlankIds);
					}
				}
			}
		} else if (id === WALLBASE_ID_DB) {
			setWallbaseCheckboxData(data)
			if(selectedWallbaseIds.includes(data.productId)) {
				let newSelectedWallbaseData = []
				let newSelectedWallbaseIds = []
				if( (data.isPrimary && data.isSecondary) || 
						(data.isPrimary && !data.isSecondary) || 
						(!data.isPrimary && data.isSecondary)
					) {
						newSelectedWallbaseData = [...selectedWallbase, data];
						newSelectedWallbaseIds = [...selectedWallbaseIds, data.productId];
						let uniqueSelected = _.uniqBy(newSelectedWallbaseData, "productId")
						let uniqueSelectedIds = _.uniq(newSelectedWallbaseIds)
						setSelectedWallbase(uniqueSelected);
						setSelectedWallbaseIds(uniqueSelectedIds);
					}
				if(!data.isPrimary && !data.isSecondary) {
					let tempData = selectedWallbase.filter(val => val.productId !== data.productId);
					let tempIds = selectedWallbaseIds.filter(val => val !== data.productId);
					setSelectedWallbase([...tempData]);
					setSelectedWallbaseIds([...tempIds]);
					newSelectedWallbaseData = [...tempData]
				}

				let tempSubPdtIds = []
				newSelectedWallbaseData.map(data => {
					tempSubPdtIds.push(data.subProductStructureId)
				})

				// for labor auto select
				if (newSelectedWallbaseData.length > 0) {
					newSelectedWallbaseData.map((WallbaseItem) => {
						const newSelectedLabor = selectedLaborWallbase.filter(element => element.storeId === WallbaseItem.storeId);
						const newSelectedLaborData = newSelectedLabor.filter(element => tempSubPdtIds.includes(element.subProductStructureId));
						setSelectedLaborWallbase(newSelectedLaborData);
						// const newSelectedLaborWallbaseIds = [];
						// const newSelectedLaborWallbaseKitIds = [];
						const newSelectedLaborWallbaseLaborIds = [];
						if (newSelectedLaborData.length > 0) {
							newSelectedLaborData.map((i) => {
								// newSelectedLaborWallbaseIds.push(i.laborItemId);
								// newSelectedLaborWallbaseKitIds.push(i.kitId);
								newSelectedLaborWallbaseLaborIds.push(i.laborId);
							})
						}
						// setSelectedLaborWallbaseIds(newSelectedLaborWallbaseIds);
						// setSelectedLaborWallbaseKitIds(newSelectedLaborWallbaseKitIds);
						setSelectedLaborWallbaseLaborIds(newSelectedLaborWallbaseLaborIds);
					})
				} else {
					setSelectedLaborWallbase([]);
					// setSelectedLaborWallbaseIds([]);
					// setSelectedLaborWallbaseKitIds([]);
					setSelectedLaborWallbaseLaborIds([]);
				}

				// for material auto select
				if (newSelectedWallbaseData.length > 0) {
					newSelectedWallbaseData.map((WallbaseItem) => {
						const newSelectedMaterial = selectedMaterialWallbase.filter(element => element.storeId === WallbaseItem.storeId);
						const newSelectedMaterialData = newSelectedMaterial.filter(element => tempSubPdtIds.includes(element.subProductStructureId));
						setSelectedMaterialWallbase(newSelectedMaterialData);
						const newSelectedMaterialWallbaseIds = [];
						if (newSelectedMaterialData.length > 0) {
							newSelectedMaterialData.map((i) => {
								newSelectedMaterialWallbaseIds.push(i.materialId);
							})
						}
						setSelectedMaterialWallbaseIds(newSelectedMaterialWallbaseIds);
					})
				} else {
					setSelectedMaterialWallbase([]);
					setSelectedMaterialWallbaseIds([]);
				}
			} else {
				setSelectedWallbase([...selectedWallbase, data]);
				setSelectedWallbaseIds([...selectedWallbaseIds, data.productId]);

				// for labor auto select
				if (laborList.length > 0) {
					laborList.map((laborItem) => {
						// if (!selectedLaborWallbaseIds.includes(laborItem.laborItemId)) {
						// 	selectedLaborWallbase.push(laborItem);
						// 	selectedLaborWallbaseIds.push(laborItem.laborItemId);
						// }
						// if (!selectedLaborWallbaseKitIds.includes(laborItem.kitId)) {
						// 	selectedLaborWallbase.push(laborItem);
						// 	selectedLaborWallbaseKitIds.push(laborItem.kitId);
						// }
						// if (!selectedLaborWallbaseLaborIds.includes(laborItem.laborId)) {
						// 	selectedLaborWallbase.push(laborItem);
						// 	selectedLaborWallbaseLaborIds.push(laborItem.laborId);
						// }
						if (laborItem.subProductStructureId === data.subProductStructureId) {
							if (!selectedLaborWallbaseLaborIds.includes(laborItem.laborId)) {
								selectedLaborWallbase.push(laborItem);
								selectedLaborWallbaseLaborIds.push(laborItem.laborId);
							}
						}
					})
					setSelectedLaborWallbase(selectedLaborWallbase);
					// setSelectedLaborWallbaseIds(selectedLaborWallbaseIds);
					// setSelectedLaborWallbaseKitIds(selectedLaborWallbaseKitIds);
					setSelectedLaborWallbaseLaborIds(selectedLaborWallbaseLaborIds);
				}

				// for material auto select
				if (materialList.length > 0) {
					materialList.map((materialItem) => {
						if (materialItem.subProductStructureId === data.subProductStructureId) {
							if (!selectedMaterialWallbaseIds.includes(materialItem.materialId)) {
								selectedMaterialWallbase.push(materialItem);
								selectedMaterialWallbaseIds.push(materialItem.materialId);
							}
						}
					})
					setSelectedMaterialWallbase(selectedMaterialWallbase);
					setSelectedMaterialWallbaseIds(selectedMaterialWallbaseIds);
				}
			}
		} else if (id === INSTALLATION_ACCESSORIES_ID_DB) {
			setSundriesCheckboxData(data)
			if(selectedSundriesIds.includes(data.productId)) {
				let newSelectedSundriesData = []
				let newSelectedSundriesIds = []
				if( (data.isPrimary && data.isSecondary) || 
						(data.isPrimary && !data.isSecondary) || 
						(!data.isPrimary && data.isSecondary)
					) {
						newSelectedSundriesData = [...selectedSundries, data];
						newSelectedSundriesIds = [...selectedSundriesIds, data.productId];
						let uniqueSelected = _.uniqBy(newSelectedSundriesData, "productId");
						let uniqueSelectedIds = _.uniq(newSelectedSundriesIds);
						setSelectedSundries(uniqueSelected);
						setSelectedSundriesIds(uniqueSelectedIds);
						localStorage.setItem("selectedSundriesList", JSON.stringify(tempData))
					}
				if(!data.isPrimary && !data.isSecondary) {
					let tempData = selectedSundries.filter(val => val.productId !== data.productId);
					let tempIds = selectedSundriesIds.filter(val => val !== data.productId);
					setSelectedSundries([...tempData]);
					setSelectedSundriesIds([...tempIds]);
					newSelectedSundriesData= [...tempData];
					localStorage.setItem("selectedSundriesList", JSON.stringify(tempData))
				}

				let tempSubPdtIds = []
					newSelectedSundriesData.map(data => {
						tempSubPdtIds.push(data.subProductStructureId)
				})
				
				// for labor auto select
				if (newSelectedSundriesData.length > 0) {
					newSelectedSundriesData.map((SundriesItem) => {
						const newSelectedLabor = selectedLaborSundries.filter(element => element.storeId === SundriesItem.storeId);
						const newSelectedLaborData = newSelectedLabor.filter(element => tempSubPdtIds.includes(element.subProductStructureId));
						setSelectedLaborSundries(newSelectedLaborData);
						// const newSelectedLaborSundriesIds = [];
						const newSelectedLaborSundriesLaborIds = [];
						if (newSelectedLaborData.length > 0) {
							newSelectedLaborData.map((i) => {
								// newSelectedLaborSundriesIds.push(i.laborItemId);
								newSelectedLaborSundriesLaborIds.push(i.laborId);
							})
						}
						setSelectedLaborSundriesLaborIds(newSelectedLaborSundriesLaborIds);
					})
				} else {
					setSelectedLaborSundries([]);
					// setSelectedLaborSundriesIds([]);
					setSelectedLaborSundriesLaborIds([]);
				}

				// for material auto select
				if (newSelectedSundriesData.length > 0) {
					newSelectedSundriesData.map((SundriesItem) => {
						const newSelectedMaterial = selectedMaterialSundries.filter(element => element.storeId === SundriesItem.storeId);
						const newSelectedMaterialData = newSelectedMaterial.filter(element => tempSubPdtIds.includes(element.subProductStructureId));
						setSelectedMaterialSundries(newSelectedMaterialData);
						const newSelectedMaterialSundriesIds = [];
						if (newSelectedMaterialData.length > 0) {
							newSelectedMaterialData.map((i) => {
								newSelectedMaterialSundriesIds.push(i.materialId);
							})
						}
						setSelectedMaterialSundriesIds(newSelectedMaterialSundriesIds);
					})
				} else {
					setSelectedMaterialSundries([]);
					setSelectedMaterialSundriesIds([]);
				}
			} else {
				setSelectedSundries([...selectedSundries, data]);
				setSelectedSundriesIds([...selectedSundriesIds, data.productId]);

				if (localStorage.getItem("selectedSundriesList")) {
					let tempData = JSON.parse(localStorage.getItem("selectedSundriesList"))
					tempData = tempData.filter(data => data)
					let isCheck = false
					tempData.map(value => {
						if (value.productId === data.productId) {
							isCheck = true
							value.price = data.price 	
							value.gpm = data.gpm 	
						}
					})
					
					if (isCheck) {
						localStorage.setItem("selectedSundriesList", JSON.stringify(tempData))
					} else {
						localStorage.setItem("selectedSundriesList", JSON.stringify([...selectedSundries, data]))							
					}
				}

				// for labor auto select
				if (laborList.length > 0) {
					laborList.map((laborItem) => {
						// if (!selectedLaborSundriesIds.includes(laborItem.laborItemId)) {
						if (laborItem.subProductStructureId === data.subProductStructureId) {
							if (!selectedLaborSundriesLaborIds.includes(laborItem.laborId)) {
								selectedLaborSundries.push(laborItem);
								// selectedLaborSundriesIds.push(laborItem.laborItemId);
								selectedLaborSundriesLaborIds.push(laborItem.laborId);
							}
						}
					})
					setSelectedLaborSundries(selectedLaborSundries);
					setSelectedLaborSundriesLaborIds(selectedLaborSundriesLaborIds);
				}

				// for material auto select
				if (materialList.length > 0) {
					materialList.map((materialItem) => {
						if (materialItem.subProductStructureId === data.subProductStructureId) {
							if (!selectedMaterialSundriesIds.includes(materialItem.materialId)) {
								selectedMaterialSundries.push(materialItem);
								selectedMaterialSundriesIds.push(materialItem.materialId);
							}
						}
					})
					setSelectedMaterialSundries(selectedMaterialSundries);
					setSelectedMaterialSundriesIds(selectedMaterialSundriesIds);
				}
			}
		} else if (id === 0) {
			if(selectedLaborIds.includes(data.laborItemId)) {
				const newSelectedLaborData = selectedLabor.filter(element => element.laborItemId !== data.laborItemId);
				setSelectedLabor(newSelectedLaborData);
				const newSelectedLaborIds = selectedLaborIds.filter(element => element !== data.laborItemId);
				setSelectedLaborIds(newSelectedLaborIds);
			} else {
				if(data?.isAddOn) {
					if (data?.storeId === storeId) {
						setSelectedLabor([...selectedLabor, data]);
						setSelectedLaborIds([...selectedLaborIds, data.laborItemId]);
					} else {
						toast.error("Store should be same as Customer store for Add Ons.");
					}
				} else {
					setSelectedLabor([...selectedLabor, data]);
					setSelectedLaborIds([...selectedLaborIds, data.laborItemId]);
				}
			}
		}
	}

	// function called on secondary click
	const handleSecondaryCheckboxChange = (e, value, id) => {
		if (value.hasOwnProperty("isSecondary")) {
			value.isSecondary = value.isSecondary === true ? false : true;
		}
		handleProductsSelection(value, id)
	}

	// function to handle Save material for order-confirmation
	const handleSaveMaterial = () => {
		if(primaryProduct === CARPET_ID_DB || primaryProduct === SHEET_VINYL_ID_DB || primaryProduct === VINYL_PLANK_ID_DB || primaryProduct === WALLBASE_ID_DB || primaryProduct === INSTALLATION_ACCESSORIES_ID_DB || primaryProduct === TRANSITION_ID_DB) {
			let storedProductList = JSON.parse(localStorage.getItem("selectedProductList"))
			let storedMaterialList = JSON.parse(localStorage.getItem("selectedMaterialList"))
			let storedLaborList = JSON.parse(localStorage.getItem("selectedLaborList"))
			let payload = {
				OrderMaterial: storedMaterialList,
				ProductOrder: storedProductList,
				LaborOrder: storedLaborList
			}
			let isClosed = selectedPrimaryProductCopy && selectedPrimaryProductCopy.length > 0 ? false : true
			saveContractForOrderDetails(payload, isClosed)
		}
	}

	// function to handle Save labor for order-confirmation
	const handleSaveLabor = () => {
		if(primaryProduct === 0) {
			let storedlaborList = JSON.parse(localStorage.getItem("selectedOrderLaborList"))
			let payload = {
				OrderMaterial: [],
				ProductOrder: [],
				LaborOrder: storedlaborList
			}
			saveContractForOrderDetails(payload, true)
		}
	}

	// handle reset product filter
	const handleResetProductFilter = (productId, storeId) => {
		setStateProductFilter({
			"ProductStructureId": productId,
			"StoreId": storeId,
			"ContactInfoId": groupPricingContractId ? "" : contactInfoId[0],
			"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
			"IsStock": "",
			"IsRecurringStock": "",
			"IsPrimary": "",
			"IsSecondary": "",
			"PrivateName": "",
			"Manufacturer": "",
			"Style": "",
			"Color": "",
			"Fiber": "",
			"faceWidth": "",
			"BackingType": "",
			"Guage": "",
			"WearLayer": "",
			"Thickness": "",
			"currentCost": "",
			"futureCost1": "",
			"futureCost2": "",
			"subProductStructureId": ""
		})
	}

	// function called on modal next button click
	const handleNextClick = () => {

		let isValid = true;
		// Checking validation
		if (selectedCarpet.length > 0 && selectedCarpet.some(data=>(!data.price || data.price === ""))) {
			toast.error("Please fill Carpet Price or GPM.");
			isValid = false;
		} else if ((selectedLaborCarpet.length > 0 || selectedMaterialCarpet.length > 0) && (selectedLaborCarpet.some(data=>(!data.price || data.price === "")) || selectedMaterialCarpet.some(data=>(!data.price || data.price === "")))) {
			toast.error("Please fill Carpet labor and accessory material Price or GPM.");
			isValid = false;
		} else if (selectedSheetVinyl.length > 0 && selectedSheetVinyl.some(data=>(!data.price || data.price === ""))) {
			toast.error("Please fill Sheet Vinyl Price or GPM.");
			isValid = false;
		} else if ((selectedLaborSheetVinyl.length > 0 || selectedMaterialSheetVinyl.length > 0) && (selectedLaborSheetVinyl.some(data=>(!data.price || data.price === "")) || selectedMaterialSheetVinyl.some(data=>(!data.price || data.price === "")))) {
			toast.error("Please fill Sheet Vinyl labor and accessory material Price or GPM.");
			isValid = false;
		} else if (selectedVinylPlank.length > 0 && selectedVinylPlank.some(data=>(!data.price || data.price === ""))) {
			toast.error("Please fill Vinyl Plank Price or GPM.");
			isValid = false;
		} else if ((selectedLaborVinylPlank.length > 0 || selectedMaterialVinylPlank.length > 0) && (selectedLaborVinylPlank.some(data=>(!data.price || data.price === "")) || selectedMaterialVinylPlank.some(data=>(!data.price || data.price === "")))) {
			toast.error("Please fill Vinyl Plank labor and accessory material Price or GPM.");
			isValid = false;
		} else if (selectedWallbase.length > 0 && selectedWallbase.some(data=>(!data.price || data.price === ""))) {
			toast.error("Please fill Wall Base Price or GPM.");
			isValid = false;
		} else if (selectedSundries.length > 0 && selectedSundries.some(data=>data.price === "")) {
			toast.error("Please fill Miscellaneous Sundries Price or GPM.");
			isValid = false;
		} else if (selectedLabor.length > 0 && selectedLabor.some(data=>(!data.price || data.price === ""))) {
			toast.error("Please fill Miscellaneous Labor Price or GPM.");
			isValid = false;
		} else if ((selectedLaborWallbase.length > 0 || selectedMaterialWallbase.length > 0) && (selectedLaborWallbase.some(data=>(!data.price || data.price === "")) || selectedMaterialWallbase.some(data=>(!data.price || data.price === "")))) {
			toast.error("Please fill Wallbase labor and accessory material Price or GPM.");
			isValid = false;
		} else if ((selectedLaborSundries.length > 0 || selectedMaterialSundries.length > 0) && (selectedLaborSundries.some(data=>(!data.price || data.price === "")) || selectedMaterialSundries.some(data=>(!data.price || data.price === "")))) {
			toast.error("Please fill Sundries labor and accessory material Price or GPM.");
			isValid = false;
		} else if (selectedCarpet.length > 0 && selectedCarpet.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if (((selectedLaborCarpet.length > 0 && selectedLaborCarpet.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))))) || (selectedMaterialCarpet.length > 0 && selectedMaterialCarpet.some(data=>(!isFinite(data.gpm) || !isFinite(data.price)))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if (selectedSheetVinyl.length > 0 && selectedSheetVinyl.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if ((selectedLaborSheetVinyl.length > 0 || selectedMaterialSheetVinyl.length > 0) && (selectedLaborSheetVinyl.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) || selectedMaterialSheetVinyl.some(data=>(!isFinite(data.gpm) || !isFinite(data.price)))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if (selectedVinylPlank.length > 0 && selectedVinylPlank.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if ((selectedLaborVinylPlank.length > 0 || selectedMaterialVinylPlank.length > 0) && (selectedLaborVinylPlank.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) || selectedMaterialVinylPlank.some(data=>(!isFinite(data.gpm) || !isFinite(data.price)))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if (selectedWallbase.length > 0 && selectedWallbase.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if ((selectedLaborWallbase.length > 0 || selectedMaterialWallbase.length > 0) && (selectedLaborWallbase.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) || selectedMaterialWallbase.some(data=>(!isFinite(data.gpm) || !isFinite(data.price)))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if (selectedSundries.length > 0 && selectedSundries.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if ((selectedLaborSundries.length > 0 || selectedMaterialSundries.length > 0) && (selectedLaborSundries.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) || selectedMaterialSundries.some(data=>(!isFinite(data.gpm) || !isFinite(data.price)))) && !materialContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		} else if (selectedLabor.length > 0 && selectedLabor.some(data=>(!isFinite(data.gpm) || !isFinite(data.price))) && !laborContractModalOpen) {
			toast.error("Price or GPM value cannot be infinity, kindly input a valid number or unselect the product to proceed.");
			isValid = false;
		}

		// handle material data
		if(materialContractModalOpen) {
			fetchPricingContract(auth[0].token, {'cutomerId': contactInfoId[0], 'groupContractId': ''}, true, unitId, jobNum)
			handleSaveMaterial()
		}

		// handle labor data
		if(laborContractModalOpen) {
			handleSaveLabor()
		}

		// handle missing Price/GPM for highlighted labors
		if (!laborContractModalOpen && !materialContractModalOpen && primaryProduct === 0) {
			let difference = missingStairsLaborIds.filter(x => !selectedLaborIds.includes(x));
			if (difference.length > 0) {
				toast.error("Missing Price/GPM for highlighted labors.")
				isValid = false
			}
		}

		// handle product validation with respective to default view
		if (selectedQuantityProduct && selectedQuantityProduct.length > 0 && !laborContractModalOpen && !materialContractModalOpen) {
			let filteredProductStructureId = selectedQuantityProduct
				.map(item => item.productStructureId)
				.filter(item => item)

			let filteredProductId = selectedQuantityProduct
				.map(item => item.productId)
				.filter(item => item)

			let alertMessage = <div>This product is applied on rooms in &quot;Default Layout&quot; and cannot be removed from pricing contract.<br /> Firstly, remove the product from the &quot;Default Layout&quot; and then from the pricing contract to save changes.</div>

			if (filteredProductStructureId.length > 0) {
				if (primaryProduct === CARPET_ID_DB && filteredProductStructureId.includes(primaryProduct)) {
					let tempSelectedIds = []
					selectedCarpet.map(data => {
						if (data.isPrimary) {
							tempSelectedIds.push(data.productId)
						}
					})
					if (tempSelectedIds.length < 1) {
						isValid = false
						toast.error(alertMessage)
					}
				}
				if (primaryProduct === SHEET_VINYL_ID_DB && filteredProductStructureId.includes(primaryProduct)) {
					let tempSelectedIds = []
					selectedSheetVinyl.map(data => {
						if (data.isPrimary) {
							tempSelectedIds.push(data.productId)
						}
					})
					if (tempSelectedIds.length < 1) {
						isValid = false
						toast.error(alertMessage)
					}
				}
				if (primaryProduct === VINYL_PLANK_ID_DB && filteredProductStructureId.includes(primaryProduct)) {
					let tempSelectedIds = []
					selectedVinylPlank.map(data => {
						if (data.isPrimary) {
							tempSelectedIds.push(data.productId)
						}
					})
					if (tempSelectedIds.length < 1) {
						isValid = false
						toast.error(alertMessage)
					}
				}
			}

			if (filteredProductId.length > 0) {
				if (primaryProduct === WALLBASE_ID_DB) {
					let tempCommonElements = filteredProductId.every(element => selectedWallbaseIds.includes(element))
					if (!tempCommonElements) {
						isValid = false
						toast.error(alertMessage)
					}
				}
			}
		}

		// If valid proceed next
		if (isValid === true) {
			if (selectedPrimaryProductCopy.length > 0) {
				let checkFlag = (laborContractModalOpen || materialContractModalOpen) ? true : false
				setPrimaryProduct(selectedPrimaryProductCopy[0]);
				handleResetProductFilter(selectedPrimaryProductCopy[0], storeId)
				fetchLaborForPricingContract(auth[0].token, {
					"ProductStructureId": selectedPrimaryProductCopy[0],
					"ProductStructureIdForLabor":0,
					"StoreId": storeId,
					"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
					"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
					"subProductStructureId": 0,
				}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
				fetchMaterialForPricingContract(auth[0].token, {
					"ProductStructureId": selectedPrimaryProductCopy[0],
					"StoreId": storeId,
					"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
					"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
					"subProductStructureId": 0,
				}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
				selectedPrimaryProductCopy.splice(0, 1);
				setSelectedPrimaryProductCopy(selectedPrimaryProductCopy);
				if (selectedPrimaryProductCopy.length === 0 && !materialContractModalOpen) {
					setPrimaryProductCopy(WALLBASE_ID_DB);
				}
			} else {
				let checkFlag = (laborContractModalOpen || materialContractModalOpen) ? true : false
				if (primaryProductCopy === WALLBASE_ID_DB && !materialContractModalOpen) {
					handleResetProductFilter(WALLBASE_ID_DB, storeId)
					fetchSubProductRequest({
						"productStructureId": parseInt(primaryProductCopy),
						"token": auth[0].token
					});
					fetchLaborForPricingContract(auth[0].token, {
						"ProductStructureId": primaryProductCopy,
						"ProductStructureIdForLabor":0,
						"StoreId": storeId,
						"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
						"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
						"subProductStructureId": 0,
					}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
					fetchMaterialForPricingContract(auth[0].token, {
						"ProductStructureId": primaryProductCopy,
						"StoreId": storeId,
						"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
						"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
						"subProductStructureId": 0,
					}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
					setPrimaryProduct(WALLBASE_ID_DB);
					setPrimaryProductCopy(INSTALLATION_ACCESSORIES_ID_DB);
				} else if (primaryProductCopy === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen) {
					setPrimaryProduct(INSTALLATION_ACCESSORIES_ID_DB);
					handleResetProductFilter(INSTALLATION_ACCESSORIES_ID_DB, storeId)
					fetchSubProductRequest({
						"productStructureId": parseInt(primaryProductCopy),
						"token": auth[0].token
					});
					fetchTransitionSubProductRequest({
						"productStructureId": TRANSITION_ID_DB,
						"token": auth[0].token
					});
					fetchLaborForPricingContract(auth[0].token, {
						"ProductStructureId": primaryProductCopy,
						"ProductStructureIdForLabor":0,
						"StoreId": storeId,
						"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
						"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
						"subProductStructureId": 0,
					}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
					fetchMaterialForPricingContract(auth[0].token, {
						"ProductStructureId": primaryProductCopy,
						"StoreId": storeId,
						"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
						"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
						"subProductStructureId": 0,
					}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
					fetchTransitionLaborForPricingContract(auth[0].token, {
						"ProductStructureId": TRANSITION_ID_DB,
						"ProductStructureIdForLabor":0,
						"StoreId": storeId,
						"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
						"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
						"subProductStructureId": 0,
					}, checkFlag);
					fetchTransitionMaterialForPricingContract(auth[0].token, {
						"ProductStructureId": TRANSITION_ID_DB,
						"StoreId": storeId,
						"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
						"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
						"subProductStructureId": 0,
					}, checkFlag);
					setPrimaryProductCopy("");
				} else if(showLaborList === true) {
					if(!materialContractModalOpen && !laborContractModalOpen) {
						setShowLaborList(false);
						setShowSummary(true);
					}
				} else {
					if (isContractPricingAdded === true) {
						setShowSaveOnEdit(true);
					}
					if(materialContractModalOpen && !laborContractModalOpen) {
						setShowLaborList(false);
					}
					if(!materialContractModalOpen && !laborContractModalOpen) {
						setPrimaryProduct(0);
						setShowLaborList(true);
						setStateLaborFilter({
							...stateLaborFilter,
							"storeId": storeId,
							"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0]
						})
					}
					if(!materialContractModalOpen && laborContractModalOpen) {
						setPrimaryProduct(0);
						setShowLaborList(true);
						setShowSummary(false);
						setStateLaborFilter({
							...stateLaborFilter,
							"storeId": storeId,
							"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0]
						})
					}
				}
			}
		}
	}

	// function called on modal close click and form section state changed back to 1
	const handleModalCloseClick = () => {
		hidePricingContractModal();
		setPricingContractSummaryData([]);

		if (isContractPricingAdded === false) {
			setPrimaryProduct('');
			setPrimaryProductCopy('');
			setSelectPrimaryProduct([]);
			setSelectedPrimaryProductCopy([]);
			setShowLaborList(false);
			setShowSummary(false);
			setSelectedCarpet([]);
			setSelectedCarpetIds([]);
			setSelectedLaborCarpet([]);
			setSelectedLaborCarpetIds([]);
			setSelectedMaterialCarpet([]);
			setSelectedMaterialCarpetIds([]);
			setSelectedSheetVinyl([]);
			setSelectedSheetVinylIds([]);
			setSelectedLaborSheetVinyl([]);
			setSelectedLaborSheetVinylIds([]);
			setSelectedMaterialSheetVinyl([]);
			setSelectedMaterialSheetVinylIds([]);
			setSelectedVinylPlank([]);
			setSelectedVinylPlankIds([]);
			setSelectedLaborVinylPlank([]);
			setSelectedLaborVinylPlankIds([]);
			setSelectedMaterialVinylPlank([]);
			setSelectedMaterialVinylPlankIds([]);
			setSelectedWallbase([]);
			setSelectedWallbaseIds([]);
			setSelectedSundries([]);
			setSelectedSundriesIds([]);
			setSelectedLabor([]);
			setSelectedLaborIds([]);
			// setSelectedLaborTransitionIds([]);
			setSelectedLaborTransitionLaborIds([]);
			setSelectedMaterialTransitionIds([]);
		} else {
			setPrimaryProduct('');
			setPrimaryProductCopy('');
			setSelectPrimaryProduct([]);
			setSelectedPrimaryProductCopy([]);
			setShowSummary(true);
			setShowSaveOnEdit(false);
		}
		localStorage.removeItem("selectedSundriesList")
		window.location.reload();
	}

	//function to clear state at product selection
	const handleClearState = () => {
		setSelectedCarpet([]);
		setSelectedCarpetIds([]);
		setSelectedLaborCarpet([]);
		setSelectedLaborCarpetIds([]);
		setSelectedMaterialCarpet([]);
		setSelectedMaterialCarpetIds([]);
		setSelectedSheetVinyl([]);
		setSelectedSheetVinylIds([]);
		setSelectedLaborSheetVinyl([]);
		setSelectedLaborSheetVinylIds([]);
		setSelectedMaterialSheetVinyl([]);
		setSelectedMaterialSheetVinylIds([]);
		setSelectedVinylPlank([]);
		setSelectedVinylPlankIds([]);
		setSelectedLaborVinylPlank([]);
		setSelectedLaborVinylPlankIds([]);
		setSelectedMaterialVinylPlank([]);
		setSelectedMaterialVinylPlankIds([]);
		setSelectedWallbase([]);
		setSelectedWallbaseIds([]);
		// setSelectedLaborWallbaseIds([]);
		// setSelectedLaborWallbaseKitIds([]);
		setSelectedLaborWallbaseLaborIds([]);
		setSelectedMaterialWallbaseIds([]);
		setSelectedSundries([]);
		setSelectedSundriesIds([]);
		// setSelectedLaborSundriesIds([]);
		setSelectedLaborSundriesLaborIds([]);
		setSelectedMaterialSundriesIds([]);
		setSelectedLabor([]);
		setSelectedLaborIds([]);
		setPricingContractSummaryData([]);	
		setLaborListData([]);
		setPrimaryProductLaborData([]);
	}

	// handle labor next click
	useEffect(() => {
		if(laborContractModalOpen) {
			handleNextClick();
		}
	}, [laborContractModalOpen])

	// function called on first model product type checkbox click
	const handleProductTypeChangeClick = (evt) => {
		if(!laborContractModalOpen) {
			if(selectedPrimaryProduct.length < 1) {
				handleClearState();
			}
			if (selectedPrimaryProduct.includes(parseInt(evt.target.value))) {
				const newPrimaryProduct = selectedPrimaryProduct.filter(element => element !== parseInt(evt.target.value));
				setSelectPrimaryProduct(newPrimaryProduct);
				setSelectedPrimaryProductCopy(newPrimaryProduct);
			} else {
				setSelectPrimaryProduct([...selectedPrimaryProduct, parseInt(evt.target.value)]);
				setSelectedPrimaryProductCopy([...selectedPrimaryProduct, parseInt(evt.target.value)]);
			}
		}
	}

	// function called on product checkbox click
	const handleCheckboxClick = (data, id) => {
		if (data.hasOwnProperty("isPrimary")) {
			data.isPrimary = data.isPrimary === true ? false : true;
		}
		handleProductsSelection(data, id)
	}

	// function called on primary product labor checkbox click
	const handleLaborCheckboxClick = (data, id) => {
		if (id === CARPET_ID_DB) {
			if(selectedLaborCarpetIds.includes(data.laborItemId)) {
				const newSelectedLaborCarpetData = selectedLaborCarpet.filter(element => element.laborItemId !== data.laborItemId);
				setSelectedLaborCarpet(newSelectedLaborCarpetData);
				const newSelectedLaborCarpetIds = selectedLaborCarpetIds.filter(element => element !== data.laborItemId);
				setSelectedLaborCarpetIds(newSelectedLaborCarpetIds);
			} else {
				setSelectedLaborCarpet([...selectedLaborCarpet, data]);
				setSelectedLaborCarpetIds([...selectedLaborCarpetIds, data.laborItemId]);
			}
		} else if (id === SHEET_VINYL_ID_DB) {
			if(selectedLaborSheetVinylIds.includes(data.laborItemId)) {
				const newSelectedLaborSheetVinylData = selectedLaborSheetVinyl.filter(element => element.laborItemId !== data.laborItemId);
				setSelectedLaborSheetVinyl(newSelectedLaborSheetVinylData);
				const newSelectedLaborSheetVinylIds = selectedLaborSheetVinylIds.filter(element => element !== data.laborItemId);
				setSelectedLaborSheetVinylIds(newSelectedLaborSheetVinylIds);
			} else {
				setSelectedLaborSheetVinyl([...selectedLaborSheetVinyl, data]);
				setSelectedLaborSheetVinylIds([...selectedLaborSheetVinylIds, data.laborItemId]);
			}
		} else if (id === VINYL_PLANK_ID_DB) {
			if(selectedLaborVinylPlankIds.includes(data.laborItemId)) {
				const newSelectedLaborVinylPlankData = selectedLaborVinylPlank.filter(element => element.laborItemId !== data.laborItemId);
				setSelectedLaborVinylPlank(newSelectedLaborVinylPlankData);
				const newSelectedLaborVinylPlankIds = selectedLaborVinylPlankIds.filter(element => element !== data.laborItemId);
				setSelectedLaborVinylPlankIds(newSelectedLaborVinylPlankIds);
			} else {
				setSelectedLaborVinylPlank([...selectedLaborVinylPlank, data]);
				setSelectedLaborVinylPlankIds([...selectedLaborVinylPlankIds, data.laborItemId]);
			}
		} else if (id === WALLBASE_ID_DB) {
			if(selectedLaborWallbaseLaborIds.includes(data.laborId)) {
				const newSelectedLaborWallbaseData = selectedLaborWallbase.filter(element => element.laborId !== data.laborId);
				setSelectedLaborWallbase(newSelectedLaborWallbaseData);
				const newSelectedLaborWallbaseLaborIds = selectedLaborWallbaseLaborIds.filter(element => element !== data.laborId);
				setSelectedLaborWallbaseLaborIds(newSelectedLaborWallbaseLaborIds);
			} else {
				setSelectedLaborWallbase([...selectedLaborWallbase, data]);
				setSelectedLaborWallbaseLaborIds([...selectedLaborWallbaseLaborIds, data.laborId]);
			}
		} else if (id === INSTALLATION_ACCESSORIES_ID_DB) {
			if(selectedLaborSundriesLaborIds.includes(data.laborId)) {
				const newSelectedLaborSundriesData = selectedLaborSundries.filter(element => element.laborId !== data.laborId);
				setSelectedLaborSundries(newSelectedLaborSundriesData);
				const newSelectedLaborSundriesLaborIds = selectedLaborSundriesLaborIds.filter(element => element !== data.laborId);
				setSelectedLaborSundriesLaborIds(newSelectedLaborSundriesLaborIds);
			} else {
				setSelectedLaborSundries([...selectedLaborSundries, data]);
				setSelectedLaborSundriesLaborIds([...selectedLaborSundriesLaborIds, data.laborId]);
			}
		}
	}

	// function called to check selected material listing
	const handleWallbaseMaterial = (data) => {
		let isFlag = false
		if (selectedWallbase.length > 0) {
			if (selectedMaterialWallbase.length > 0) {
				selectedWallbase.map(tempValue => {
					selectedMaterialWallbase.map(value => {
						if (selectedWallbaseIds.includes(data.productId) && selectedMaterialWallbase.includes(value.productId)) {
							isFlag = true
						}
						if (selectedWallbaseIds.includes(data.productId) && !selectedMaterialWallbase.includes(value.productId)) {
							isFlag = true
						}
						if (!selectedWallbaseIds.includes(data.productId) && selectedMaterialWallbase.includes(value.productId)) {
							isFlag = false
						}
					})
				})
			} else {
				let count = 0
				selectedWallbase.map(value => {
					if (selectedWallbaseIds.includes(data.productId)) {
						count += 1
					}
				})
				if (count > 0) {
					isFlag = true
				} else {
					isFlag = false
				}
			}
				
		}
		return isFlag
	}
	
	// function called on primary product material checkbox click
	const handleMaterialCheckboxClick = (data, id) => {
		if (id === CARPET_ID_DB) {
			if(selectedMaterialCarpetIds.includes(data.materialId)) {
				const newSelectedMaterialCarpetData = selectedMaterialCarpet.filter(element => element.materialId !== data.materialId);
				setSelectedMaterialCarpet(newSelectedMaterialCarpetData);
				const newSelectedMaterialCarpetIds = selectedMaterialCarpetIds.filter(element => element !== data.materialId);
				setSelectedMaterialCarpetIds(newSelectedMaterialCarpetIds);
			} else {
				setSelectedMaterialCarpet([...selectedMaterialCarpet, data]);
				setSelectedMaterialCarpetIds([...selectedMaterialCarpetIds, data.materialId]);
			}
		} else if (id === SHEET_VINYL_ID_DB) {
			if(selectedMaterialSheetVinylIds.includes(data.materialId)) {
				const newSelectedMaterialSheetVinylData = selectedMaterialSheetVinyl.filter(element => element.materialId !== data.materialId);
				setSelectedMaterialSheetVinyl(newSelectedMaterialSheetVinylData);
				const newSelectedMaterialSheetVinylIds = selectedMaterialSheetVinylIds.filter(element => element !== data.materialId);
				setSelectedMaterialSheetVinylIds(newSelectedMaterialSheetVinylIds);
			} else {
				setSelectedMaterialSheetVinyl([...selectedMaterialSheetVinyl, data]);
				setSelectedMaterialSheetVinylIds([...selectedMaterialSheetVinylIds, data.materialId]);
			}
		} else if (id === VINYL_PLANK_ID_DB) {
			if(selectedMaterialVinylPlankIds.includes(data.materialId)) {
				const newSelectedMaterialVinylPlankData = selectedMaterialVinylPlank.filter(element => element.materialId !== data.materialId);
				setSelectedMaterialVinylPlank(newSelectedMaterialVinylPlankData);
				const newSelectedMaterialVinylPlankIds = selectedMaterialVinylPlankIds.filter(element => element !== data.materialId);
				setSelectedMaterialVinylPlankIds(newSelectedMaterialVinylPlankIds);
			} else {
				setSelectedMaterialVinylPlank([...selectedMaterialVinylPlank, data]);
				setSelectedMaterialVinylPlankIds([...selectedMaterialVinylPlankIds, data.materialId]);
			}
		} else if (id === WALLBASE_ID_DB) {
			if(selectedMaterialWallbaseIds.includes(data.materialId)) {
				const newSelectedMaterialWallbaseData = selectedMaterialWallbase.filter(element => element.materialId !== data.materialId);
				setSelectedMaterialWallbase(newSelectedMaterialWallbaseData);
				const newSelectedMaterialWallbaseIds = selectedMaterialWallbaseIds.filter(element => element !== data.materialId);
				setSelectedMaterialWallbaseIds(newSelectedMaterialWallbaseIds);
			} else {
				let checkFlag = handleWallbaseMaterial(data)
				if (!checkFlag && !materialContractModalOpen) {
					setSelectedMaterialWallbase([...selectedMaterialWallbase, data]);
					setSelectedMaterialWallbaseIds([...selectedMaterialWallbaseIds, data.materialId]);
				}
				if (checkFlag && !materialContractModalOpen) {
					toast.error("GPM and Price already set for this product!")
				}
				if (materialContractModalOpen) {
					setSelectedMaterialWallbase([...selectedMaterialWallbase, data]);
					setSelectedMaterialWallbaseIds([...selectedMaterialWallbaseIds, data.materialId]);
				}
			}
		} else if (id === INSTALLATION_ACCESSORIES_ID_DB) {
			if(selectedMaterialSundriesIds.includes(data.materialId)) {
				const newSelectedMaterialSundriesData = selectedMaterialSundries.filter(element => element.materialId !== data.materialId);
				setSelectedMaterialSundries(newSelectedMaterialSundriesData);
				const newSelectedMaterialSundriesIds = selectedMaterialSundriesIds.filter(element => element !== data.materialId);
				setSelectedMaterialSundriesIds(newSelectedMaterialSundriesIds);
			} else {
				setSelectedMaterialSundries([...selectedMaterialSundries, data]);
				setSelectedMaterialSundriesIds([...selectedMaterialSundriesIds, data.materialId]);
			}
		}
	}

	// function to calculate GPM using formula
	const calculateGpm = (price, currentCost) => {
		return parseFloat((((price - currentCost) / price) * 100)).toFixed(2);
	}

	// function to calculate price using formula
	const calculatePrice = (gpm, currentCost) => {
		return parseFloat(((currentCost * 100) / (100 - gpm))).toFixed(2);
	}

	//calculate line total from price
	const calculateLineTotalFromPrice = (value, quantity) => {
		return parseFloat(value * quantity).toFixed(2)
	}

	// product list table price change
	const handlePriceChange = (evt, productId, currentCost) => {
		if (productList.length > 0) {
			// let allProductData = productList; //commented on 28 Nov for line item price calulcation issue resolving
			let allProductData = productListData;
			// Commented on 23rd November 2022, for not able to edit price fields 
			// Added this condition to avoid combined data
			if(materialContractModalOpen && primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
				allProductData = allProductData.filter(obj => obj.productStructureId === INSTALLATION_ACCESSORIES_ID_DB);
			}
			allProductData.map((item, index) => {
				if (item.productId === productId) {
					allProductData[index].price = evt.target.value;
					allProductData[index].gpm = calculateGpm(evt.target.value, currentCost);
					if(materialContractModalOpen && allProductData[index].quantity) {
						allProductData[index].linePrice = calculateLineTotalFromPrice(evt.target.value, allProductData[index].quantity);
					}
					if(materialContractModalOpen && !allProductData[index].quantity) {
						allProductData[index].quantity = parseFloat(allProductData[index].linePrice) / parseFloat(allProductData[index].price)
					}
					allProductData = Object.values(allProductData);
					setProductListData(allProductData);
				}
			})
			if (primaryProduct === CARPET_ID_DB) {
				if (selectedCarpetIds.includes(productId)) {
					let allSelectedCarpetData = selectedCarpet;
					selectedCarpet.map((item, index) => {
						if (item.productId === productId) {
							allSelectedCarpetData[index].price = evt.target.value;
							allSelectedCarpetData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedCarpetData = Object.values(allSelectedCarpetData);
							setSelectedCarpet(allSelectedCarpetData);
						}
					})
				}
			} else if (primaryProduct === SHEET_VINYL_ID_DB) {
				if (selectedSheetVinylIds.includes(productId)) {
					let allSelectedSheetVinylData = selectedSheetVinyl;
					selectedSheetVinyl.map((item, index) => {
						if (item.productId === productId) {
							allSelectedSheetVinylData[index].price = evt.target.value;
							allSelectedSheetVinylData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedSheetVinylData = Object.values(allSelectedSheetVinylData);
							setSelectedSheetVinyl(allSelectedSheetVinylData);
						}
					})
				}
			} else if (primaryProduct === VINYL_PLANK_ID_DB) {
				if (selectedVinylPlankIds.includes(productId)) {
					let allSelectedVinylPlankData = selectedVinylPlank;
					selectedVinylPlank.map((item, index) => {
						if (item.productId === productId) {
							allSelectedVinylPlankData[index].price = evt.target.value;
							allSelectedVinylPlankData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedVinylPlankData = Object.values(allSelectedVinylPlankData);
							setSelectedVinylPlank(allSelectedVinylPlankData);
						}
					})
				}
			} else if (primaryProduct === WALLBASE_ID_DB) {
				if (selectedWallbaseIds.includes(productId)) {
					let allSelectedWallbaseData = selectedWallbase;
					selectedWallbase.map((item, index) => {
						if (item.productId === productId) {
							allSelectedWallbaseData[index].price = evt.target.value;
							allSelectedWallbaseData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedWallbaseData = Object.values(allSelectedWallbaseData);
							setSelectedWallbase(allSelectedWallbaseData);
						}
					})
				}
			} else if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
				if (selectedSundriesIds.includes(productId)) {
					let allSelectedSundriesData = selectedSundries;
					selectedSundries.map((item, index) => {
						if (item.productId === productId) {
							allSelectedSundriesData[index].price = evt.target.value;
							if (constantIds.includes(productId) && parseFloat(evt.target.value) === 0) {
								allSelectedSundriesData[index].gpm = defaultGpmValue
							} else {
								allSelectedSundriesData[index].gpm = calculateGpm(evt.target.value, currentCost);
							}
							allSelectedSundriesData = Object.values(allSelectedSundriesData);
							localStorage.setItem("selectedSundriesList", JSON.stringify(allSelectedSundriesData))
							setSelectedSundries(allSelectedSundriesData);
						}
					})
				}
			}
		}
		if (laborItemList.length > 0 && primaryProduct === 0) {
			let allLaborData = laborItemList;
			laborItemList.map((item, index) => {
				if (item.laborItemId === productId) {
					allLaborData[index].price = evt.target.value;
					allLaborData[index].gpm = calculateGpm(evt.target.value, currentCost);
					if(laborContractModalOpen && allLaborData[index].quantity) {
						allLaborData[index].linePrice = calculateLineTotalFromPrice(evt.target.value, allLaborData[index].quantity);
					}
					if(laborContractModalOpen && !allLaborData[index].quantity) {
						allLaborData[index].quantity = parseFloat(allLaborData[index].linePrice) / parseFloat(allLaborData[index].price);
					}
					allLaborData = Object.values(allLaborData);
					setLaborListData(allLaborData);
				}
			})
			if (selectedLabor.length > 0) {
				if (selectedLaborCarpetIds.includes(productId)) {
					let allSelectedLaborData = selectedLabor;
					selectedLabor.map((item, index) => {
						if (item.laborItemId === productId) {
							allSelectedLaborData[index].price = evt.target.value;
							allSelectedLaborData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedLaborData = Object.values(allSelectedLaborData);
							setSelectedLabor(allSelectedLaborData);
						}
					})
				}
			}
		}
	}

	//handle primary and secondry checkbox in case of no values for carpet
	const handleCarpetCheckbox = (selectedCarpet) => {
		selectedCarpet.map((value, index) => {
			if(!value.isPrimary && !value.isSecondary) {
				selectedCarpet.splice(index, 1)
				let itemIndex = selectedCarpetIds.indexOf(value.productId)
				if (itemIndex > -1) {
					selectedCarpetIds.splice(itemIndex, 1)
				}
			}
		})
	}

	//handle primary and secondry checkbox in case of edit for carpet
	const handleCarpetPrimaryEdit = (selectedCarpetValue) => {
		let countIsPrimary = 0
		selectedCarpetValue.map(value => {
			if(value.isPrimary) {
				countIsPrimary += 1
				if(countIsPrimary > 1) {
					toast.error("Multiple primary items cannot be selected.")
					value.isPrimary = !value?.isPrimary
				}
			}
		})
	}

	//handle primary and secondry checkbox in case of no values for sheet vinyl
	const handleSheetVinylCheckbox = (selectedSheetVinyl) => {
		selectedSheetVinyl.map((value, index) => {
			if(!value.isPrimary && !value.isSecondary) {
				selectedSheetVinyl.splice(index, 1)
				let itemIndex = selectedSheetVinylIds.indexOf(value.productId)
				if (itemIndex > -1) {
					selectedSheetVinylIds.splice(itemIndex, 1)
				}
			}
		})
	}

	//handle primary and secondry checkbox in case of edit for sheet vinyl
	const handleSheetVinylPrimaryEdit = (selectedSheetVinylValue) => {
		let countIsPrimary = 0
		selectedSheetVinylValue.map(value => {
			if(value.isPrimary) {
				countIsPrimary += 1
				if(countIsPrimary > 1) {
					toast.error("Multiple primary items cannot be selected.")
					value.isPrimary = !value?.isPrimary
				}
			}
		})
	}

	//handle primary and secondry checkbox in case of no values for vinyl plank
	const handleVinylPlankCheckbox = (selectedVinylPlank) => {
		selectedVinylPlank.map((value, index) => {
			if(!value.isPrimary && !value.isSecondary) {
				selectedVinylPlank.splice(index, 1)
				let itemIndex = selectedVinylPlankIds.indexOf(value.productId)
				if (itemIndex > -1) {
					selectedVinylPlankIds.splice(itemIndex, 1)
				}
			}
		})
	}

	//handle primary and secondry checkbox in case of edit for vinyl plank
	const handleVinylPlankPrimaryEdit = (selectedVinylPlankValue) => {
		let countIsPrimary = 0
		selectedVinylPlankValue.map(value => {
			if(value.isPrimary) {
				countIsPrimary += 1
				if(countIsPrimary > 1) {
					toast.error("Multiple primary items cannot be selected.")
					value.isPrimary = !value?.isPrimary
				}
			}
		})
	}

	// handle reset state for selected products
	// const handleProductState = () => {
	// 	productList.map(data => {
	// 		if (data.isPrimary || data.isSecondary) {
	// 			data.isPrimary = false
	// 			data.isSecondary = false
	// 		}
	// 	})
	// 	productListData.map(data => {
	// 		if (data.isPrimary || data.isSecondary) {
	// 			data.isPrimary = false
	// 			data.isSecondary = false
	// 		}
	// 	})
	// }

	// handle carpet listing and its kits data
	useEffect(() => {
		if(selectedCarpet.length > 0 && productList.length > 0 && primaryProduct === CARPET_ID_DB && carpetCheckboxData) {
			handleCarpetCheckbox(selectedCarpet)
			if (isContractPricingAdded === true) {
				handleCarpetPrimaryEdit(selectedCarpet)
			}
			let countIsPrimary = 0
			selectedCarpet.map(value => {
				if(selectedCarpetIds.includes(carpetCheckboxData?.productId)) {
					if(value?.isPrimary) {
						countIsPrimary += 1
						if(countIsPrimary > 1) {
							value.isPrimary = false
							toast.error("Multiple primary items cannot be selected.")
							productList.map(data => {
								if (value.productId === data.productId) {
									data.isPrimary = false
									data.isSecondary = value?.isSecondary
								}
							})
							productListData.map(selectedData => {
								if (value.productId === selectedData.productId) {
									selectedData.isPrimary = false
									selectedData.isSecondary = value?.isSecondary
								}
							})
						}
					}
				}
			})
			handleCarpetCheckbox(selectedCarpet)
			setCarpetCheckboxFlag(false)
			let modified_arr = productList.map(obj => selectedCarpet.find(o => o.productId === obj.productId) || obj);
			setProductListData(modified_arr)
		}
		// if(selectedCarpet.length < 1) {
		// 	handleProductState()
		// }
	}, [selectedCarpet, productList, carpetCheckboxData, carpetCheckboxFlag])

	// handle sheet vinyl listing and its kits data
	useEffect(() => {
		if(selectedSheetVinyl.length > 0 && productList.length > 0 && primaryProduct === SHEET_VINYL_ID_DB && sheetVinylCheckboxData) {
			handleSheetVinylCheckbox(selectedCarpet)
			if (isContractPricingAdded === true) {
				handleSheetVinylPrimaryEdit(selectedSheetVinyl)
			}
			let countIsPrimary = 0
			selectedSheetVinyl.map(value => {
				if(selectedSheetVinylIds.includes(sheetVinylCheckboxData?.productId)) {
					if(value?.isPrimary) {
						countIsPrimary += 1
						if(countIsPrimary > 1) {
							value.isPrimary = false
							toast.error("Multiple primary items cannot be selected.")
							productList.map(data => {
								if (value.productId === data.productId) {
									data.isPrimary = false
									data.isSecondary = value?.isSecondary
								}
							})
							productListData.map(selectedData => {
								if (value.productId === selectedData.productId) {
									selectedData.isPrimary = false
									selectedData.isSecondary = value?.isSecondary
								}
							})
						}
					}
				}
			})
			handleSheetVinylCheckbox(selectedSheetVinyl)
			setSheetVinylCheckboxFlag(false)
			let modified_arr = productList.map(obj => selectedSheetVinyl.find(o => o.productId === obj.productId) || obj);
			setProductListData(modified_arr)
		}
		// if(selectedSheetVinyl.length < 1) {
		// 	handleProductState()
		// }
	}, [selectedSheetVinyl, productList, sheetVinylCheckboxData, sheetVinylCheckboxFlag])

	// handle vinyl plank and its kits data
	useEffect(() => {
		if(selectedVinylPlank.length > 0 && productList.length > 0 && primaryProduct === VINYL_PLANK_ID_DB && vinylPlankCheckboxData) {
			handleVinylPlankCheckbox(selectedVinylPlank)
			if (isContractPricingAdded === true) {
				handleVinylPlankPrimaryEdit(selectedVinylPlank)
			}
			let countIsPrimary = 0
			selectedVinylPlank.map(value => {
				if(selectedVinylPlankIds.includes(vinylPlankCheckboxData?.productId)) {
					if(value?.isPrimary) {
						countIsPrimary += 1
						if(countIsPrimary > 1) {
							value.isPrimary = false
							toast.error("Multiple primary items cannot be selected.")
							productList.map(data => {
								if (value.productId === data.productId) {
									data.isPrimary = false
									data.isSecondary = value?.isSecondary
								}
							})
							productListData.map(selectedData => {
								if (value.productId === selectedData.productId) {
									selectedData.isPrimary = false
									selectedData.isSecondary = value?.isSecondary
								}
							})
						}
					}
				}
			})
			handleVinylPlankCheckbox(selectedVinylPlank)
			setVinylPlankCheckboxFlag(false)
			let modified_arr = productList.map(obj => selectedVinylPlank.find(o => o.productId === obj.productId) || obj);
			setProductListData(modified_arr)
		}
		// if(selectedVinylPlank.length < 1) {
		// 	handleProductState()
		// }
	}, [selectedVinylPlank, productList, vinylPlankCheckboxData, vinylPlankCheckboxFlag])

	// handle wall base data
	useEffect(() => {
		if(selectedWallbase.length > 0 && productList.length > 0 && primaryProduct === WALLBASE_ID_DB) {
			if(wallbaseCheckboxData) {
				productList.map(data => {
					if(data.productId === wallbaseCheckboxData.productId) {
						data.isPrimary = wallbaseCheckboxData?.isPrimary
						data.isSecondary = wallbaseCheckboxData?.isSecondary
					}
				})
			}
			let modified_arr = productList.map(obj => selectedWallbase.find(o => o.productId === obj.productId) || obj);
			if(modified_arr.length > 0) {
				modified_arr.map((data, index) => {
						if(selectedWallbaseIds.includes(data.productId)) {
							data.isPrimary = data.isPrimary
							data.isSecondary = data.isSecondary
						} else {
							data.isPrimary = false
							data.isSecondary = false
						}
				})
			}
			setProductListData(modified_arr)
		}
	}, [selectedWallbase, productList, wallbaseCheckboxData])

	// handle sundires data
	useEffect(() => {
		if(selectedSundries.length > 0 && productList.length > 0 && primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
			if (localStorage.getItem("selectedSundriesList")) {
				let tempData = JSON.parse(localStorage.getItem("selectedSundriesList"))
				tempData = tempData.filter(data => data)
				if (tempData && tempData.length > 0) {
					tempData.map(data => {
						if (data.productId && data.price && data.gpm) {
							productList.map(value => {
								if (data.productId === value.productId) {
									value.isPrimary = data?.isPrimary
									value.isSecondary = data?.isSecondary
									value.price = data?.price
									value.gpm = data?.gpm
								}
							})
						}
					})
				}
			}
			
			if (sundriesCheckboxData) {
				productList.map(data => {
					if(data.productId === sundriesCheckboxData.productId) {
						data.isPrimary = sundriesCheckboxData?.isPrimary
						data.isSecondary = sundriesCheckboxData?.isSecondary
					}
				})
			}
			let modified_arr = productList.map(obj => selectedSundries.find(o => o.productId === obj.productId) || obj);
			
			productList.map(value => {
				selectedSundries.map(data => {
					if(value.productId === data.productId) {
						data.price = value.price
						data.gpm = value.gpm
						data.linePrice = value.linePrice
						data.quantity = value.quantity
						data.subProductStructureId = value.subProductStructureId
						data.subProductStructureName = value.subProductStructureName
					}
				})
			})

			productList.map(value => {
				modified_arr.map(data => {
					if(value.productId === data.productId) {
						data.price = value.price
						data.gpm = value.gpm
						data.linePrice = value.linePrice
						data.quantity = value.quantity
						data.subProductStructureId = value.subProductStructureId
						data.subProductStructureName = value.subProductStructureName
					}
				})
			})

			if(materialContractModalOpen) {
				let filteredArray = []
				if(modified_arr.length > 0) {
					modified_arr.map(data => {
						if(data.productStructureId === INSTALLATION_ACCESSORIES_ID_DB) {
							filteredArray.push(data)
						}
					})
				}
				//Commented on 24 November, 2022, for price change modifies listing
				// if(filteredArray.length > 0 && materialContractModalOpen) {
					// setProductListData(filteredArray);
				// }
			} else {
				if(modified_arr.length > 0) {
					modified_arr.map((data, index) => {
						if(selectedSundriesIds.includes(data.productId)) {
							data.isPrimary = data.isPrimary
							data.isSecondary = data.isSecondary
						} else {
							data.isPrimary = false
							data.isSecondary = false
						}
					})
				}
				setProductListData(modified_arr)
			}
			let productListTempData =  handleSelectedListing(productList);
			setProductListData(productListTempData)
		}
	}, [selectedSundries, productList, sundriesCheckboxData])

	// handle labor listing data
	useEffect(() => {
		if(showLaborList && laborItemList.length > 0 && selectedLabor.length > 0) {
			let modified_arr = laborItemList.map(obj => selectedLabor.find(o => o.laborItemId === obj.laborItemId) || obj);
			setLaborListData(modified_arr)
		}
	}, [showLaborList, selectedLabor, laborItemList])

	// handle labor price and gpm
	useEffect(() => {
		if(selectedLabor && selectedLabor.length > 0 && laborListData) {
			laborListData.map(value => {
				selectedLabor.map((data, i) => {
					if(data?.laborItemId === value?.laborItemId && value?.price && value?.gpm) {
						selectedLabor[i].price = value.price
						selectedLabor[i].gpm = value.gpm
					}
				})
			})
		}
	}, [laborListData, selectedLaborIds, selectedLabor])

	// handle product listing
	useEffect(() => {
		if(productListData && productListData.length > 0) {
			if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && !materialContractModalOpen) {
				let tempSelectedMaterialKitData = handleSelectedMaterialKitData()
				let tempMaterialSelectedIds = []
				tempSelectedMaterialKitData.map(data => {
					tempMaterialSelectedIds.push(data.productId)
				})
				productListData.map((value, index) => {
					if (tempMaterialSelectedIds.includes(value.productId)) {
						productListData.splice(index, 1)
					}
				})
				productList.map((value, index) => {
					if (tempMaterialSelectedIds.includes(value.productId)) {
						productList.splice(index, 1)
					}
				})
			}
			let selectedProductList = []
			let removeProductQuantity = []
			productListData.map(data => {
				if(data.quantity && (data.quantity.length > 0 || data.quantity > 0)) {
					selectedProductList.push({
						ProductId: data.productId,
						Quantity: parseFloat(data.quantity),
						GPM: parseFloat(data.gpm),
						LinePrice: parseFloat(data.linePrice),
						OrderInstallationDetailId: orderInstallationDetailId,
						CreatedBy: parseFloat(auth[0].userId),
						UnitPrice: parseFloat(data.price),
					})
				}
				if(data.quantity <= 0) {
					removeProductQuantity.push(data.ProductId)
				}
			})
			let storedProductData = JSON.parse(localStorage.getItem("selectedProductList") ? localStorage.getItem("selectedProductList") : false)
			if(storedProductData.length > 0) {
				let filterData = []
				storedProductData.map(data => {
					if(!(removeProductQuantity.includes(data.ProductId))) {
						filterData.push(data)
					}
				})
				storedProductData = filterData
			}
			if(storedProductData) {
				let updatedProductListData = [...storedProductData, ...selectedProductList]
				let reverseProductList = [...updatedProductListData].reverse()
				let filteredUpdatedProductListData = _.uniqBy(reverseProductList, "ProductId")
				localStorage.setItem("selectedProductList", JSON.stringify(filteredUpdatedProductListData))
			} else {
				localStorage.setItem("selectedProductList", JSON.stringify(selectedProductList))
			}
		}
	}, [productListData])

	// handle labor listing
	useEffect(() => {
		if(primaryProductLaborData && primaryProductLaborData.length > 0) {
			if(laborList && laborList.length > 0 && materialContractModalOpen && primaryProduct === TRANSITION_ID_DB) {
				laborList = [...primaryProductLaborData]
			}
			let selectedLaborList = []
			let removeLaborQuantity = []
			primaryProductLaborData.map(data => {
				if(data.quantity && (data.quantity.length > 0 || data.quantity > 0)) {
					selectedLaborList.push({
						LaborItemId: parseFloat(data.laborItemId),
						LaborId: parseFloat(data.laborId),
						Quantity: parseFloat(data.quantity),
						GPM: parseFloat(data.gpm),
						LinePrice: parseFloat(data.linePrice),
						OrderInstallationDetailId: orderInstallationDetailId,
						CreatedBy: parseFloat(auth[0].userId),
						UnitPrice: parseFloat(data.price),
					})
				}
				if(data.quantity <= 0) {
					removeLaborQuantity.push(data.LaborId)
				}
			})
			let storedLaborData = JSON.parse(localStorage.getItem("selectedLaborList") ? localStorage.getItem("selectedLaborList") : false)
			if(storedLaborData.length > 0) {
				let filterData = []
				storedLaborData.map(data => {
					if(!(removeLaborQuantity.includes(data.LaborId))) {
						filterData.push(data)
					}
				})
				storedLaborData = filterData
			}
			if(storedLaborData) {
				let updatedLaborListData = [...storedLaborData, ...selectedLaborList]
				let reverseLaborList = [...updatedLaborListData].reverse()
				let filteredUpdatedLaborListData = _.uniqBy(reverseLaborList, "LaborId")
				localStorage.setItem("selectedLaborList", JSON.stringify(filteredUpdatedLaborListData))
			} else {
				localStorage.setItem("selectedLaborList", JSON.stringify(selectedLaborList))
			}
		}
	}, [primaryProductLaborData])

	// handle material listing
	useEffect(() => {
		if(primaryProductMaterialData && primaryProductMaterialData.length > 0) {
			if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB && materialList && materialList.length > 0 && !materialContractModalOpen && !laborContractModalOpen) {
				materialList = materialList.filter(data => !selectedSundriesIds.includes(data.productId))
			}
			if(materialList && materialList.length > 0 && materialContractModalOpen && primaryProduct === TRANSITION_ID_DB) {
				materialList = [...primaryProductMaterialData]
			}
			let selectedMaterialList = []
			let removeMaterialQuantity = []
			primaryProductMaterialData.map(data => {
				if(data.quantity && (data.quantity.length > 0 || data.quantity > 0)) {
					selectedMaterialList.push({
						MaterialId: parseFloat(data.materialId),
						Product: parseFloat(data.productId),
						Quantity: parseFloat(data.quantity),
						GPM: parseFloat(data.gpm),
						LinePrice: parseFloat(data.linePrice),
						OrderInstallationDetailId: orderInstallationDetailId,
						CreatedBy: parseFloat(auth[0].userId),
						UnitPrice: parseFloat(data.price),
					})
				}
				if(data.quantity <= 0) {
					removeMaterialQuantity.push(data.materialId)
				}
			})
			let storedMaterialData = JSON.parse(localStorage.getItem("selectedMaterialList") ? localStorage.getItem("selectedMaterialList") : false)
			if(storedMaterialData.length > 0) {
				let filterData = []
				storedMaterialData.map(data => {
					if(!(removeMaterialQuantity.includes(data.MaterialId))) {
						filterData.push(data)
					}
				})
				storedMaterialData = filterData
			}
			if(storedMaterialData) {
				let updatedMaterialListData = [...storedMaterialData, ...selectedMaterialList]
				let reverseMaterialList = [...updatedMaterialListData].reverse()
				let filteredUpdatedMaterialListData = _.uniqBy(reverseMaterialList, "MaterialId")
				localStorage.setItem("selectedMaterialList", JSON.stringify(filteredUpdatedMaterialListData))
			} else {
				localStorage.setItem("selectedMaterialList", JSON.stringify(selectedMaterialList))
			}
		}
	}, [primaryProductMaterialData])

	// handle order labor list data
	useEffect(() => {
		if(laborListData && laborListData.length > 0) {
			let selectedLaborList = []
			let removeLaborQuantity = []
			laborListData.map(data => {
				if(data.quantity && (data.quantity.length > 0 || data.quantity > 0)) {
					selectedLaborList.push({
						LaborItemId: parseFloat(data.laborItemId),
						LaborId: parseFloat(data.laborId) ? parseFloat(data.laborId) : 0,
						Quantity: parseFloat(data.quantity),
						GPM: parseFloat(data.gpm),
						LinePrice: parseFloat(data.linePrice),
						OrderInstallationDetailId: orderInstallationDetailId,
						CreatedBy: parseFloat(auth[0].userId),
						UnitPrice: parseFloat(data.price),
					})
				}
				if(data.quantity <= 0) {
					removeLaborQuantity.push(data.laborItemId)
				}
			})
			let storedLaborData = JSON.parse(localStorage.getItem("selectedOrderLaborList") ? localStorage.getItem("selectedOrderLaborList") : false)
			if(storedLaborData.length > 0) {
				let filterData = []
				storedLaborData.map(data => {
					if(!(removeLaborQuantity.includes(data.laborItemId))) {
						filterData.push(data)
					}
				})
				storedLaborData = filterData
			}
			if(storedLaborData) {
				let updatedLaborListData = [...storedLaborData, ...selectedLaborList]
				let reverseLaborList = [...updatedLaborListData].reverse()
				let filteredUpdatedLaborListData = _.uniqBy(reverseLaborList, "LaborItemId")
				localStorage.setItem("selectedOrderLaborList", JSON.stringify(filteredUpdatedLaborListData))
			} else {
				localStorage.setItem("selectedOrderLaborList", JSON.stringify(selectedLaborList))
			}
		}
	}, [laborListData])
  
	// product list table GPM change
	const handleGpmChange = (evt, productId, currentCost) => {
		if (productList.length > 0) {
			let allProductData = productList;
			// Commented on 23rd November 2022, for not able to edit gpm fields
			// Added this condition to avoid combined data
			if(materialContractModalOpen && primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
				allProductData = allProductData.filter(obj => obj.productStructureId === INSTALLATION_ACCESSORIES_ID_DB);
			}
			allProductData.map((item, index) => {
				if (item.productId === productId) {
					allProductData[index].price = calculatePrice(evt.target.value, currentCost);
					allProductData[index].gpm = evt.target.value;
					allProductData = Object.values(allProductData);
					if(materialContractModalOpen && allProductData[index].quantity) {
						allProductData[index].linePrice = calculateLineTotalFromPrice(allProductData[index].price, allProductData[index].quantity);
					}
					if(materialContractModalOpen && !allProductData[index].quantity) {
						allProductData[index].quantity = parseFloat(allProductData[index].linePrice) / parseFloat(allProductData[index].price)
					}
					setProductListData(allProductData);
				}
			})
			if (primaryProduct === CARPET_ID_DB) {
				if (selectedCarpetIds.includes(productId)) {
					let allSelectedCarpetData = selectedCarpet;
					selectedCarpet.map((item, index) => {
						if (item.productId === productId) {
							allSelectedCarpetData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedCarpetData[index].gpm = evt.target.value;
							allSelectedCarpetData = Object.values(allSelectedCarpetData);
							setSelectedCarpet(allSelectedCarpetData);
						}
					})
				}
			} else if (primaryProduct === SHEET_VINYL_ID_DB) {
				if (selectedSheetVinylIds.includes(productId)) {
					let allSelectedSheetVinylData = selectedSheetVinyl;
					selectedSheetVinyl.map((item, index) => {
						if (item.productId === productId) {
							allSelectedSheetVinylData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedSheetVinylData[index].gpm = evt.target.value;
							allSelectedSheetVinylData = Object.values(allSelectedSheetVinylData);
							setSelectedSheetVinyl(allSelectedSheetVinylData);
						}
					})
				}
			} else if (primaryProduct === VINYL_PLANK_ID_DB) {
				if (selectedVinylPlankIds.includes(productId)) {
					let allSelectedVinylPlankData = selectedVinylPlank;
					selectedVinylPlank.map((item, index) => {
						if (item.productId === productId) {
							allSelectedVinylPlankData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedVinylPlankData[index].gpm = evt.target.value;
							allSelectedVinylPlankData = Object.values(allSelectedVinylPlankData);
							setSelectedVinylPlank(allSelectedVinylPlankData);
						}
					})
				}
			} else if (primaryProduct === WALLBASE_ID_DB) {
				if (selectedWallbaseIds.includes(productId)) {
					let allSelectedWallbaseData = selectedWallbase;
					selectedWallbase.map((item, index) => {
						if (item.productId === productId) {
							allSelectedWallbaseData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedWallbaseData[index].gpm = evt.target.value;
							allSelectedWallbaseData = Object.values(allSelectedWallbaseData);
							setSelectedWallbase(allSelectedWallbaseData);
						}
					})
				}
			} else if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
				if (selectedSundriesIds.includes(productId)) {
					let allSelectedSundriesData = selectedSundries;
					selectedSundries.map((item, index) => {
						if (item.productId === productId) {
							allSelectedSundriesData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedSundriesData[index].gpm = evt.target.value;
							allSelectedSundriesData = Object.values(allSelectedSundriesData);
							localStorage.setItem("selectedSundriesList", JSON.stringify(allSelectedSundriesData))
							setSelectedSundries(allSelectedSundriesData);
						}
					})
				}
			}
		}
		if (laborItemList.length > 0 && primaryProduct === 0) {
			let allLaborData = laborItemList;
			laborItemList.map((item, index) => {
				if (item.laborItemId === productId) {
					allLaborData[index].price = calculatePrice(evt.target.value, currentCost);
					allLaborData[index].gpm = evt.target.value;
					if(laborContractModalOpen && allLaborData[index].quantity) {
						allLaborData[index].linePrice = calculateLineTotalFromPrice(allLaborData[index].price, allLaborData[index].quantity);
					}
					if(laborContractModalOpen && !allLaborData[index].quantity) {
						allLaborData[index].quantity = parseFloat(allLaborData[index].linePrice) / parseFloat(allLaborData[index].price)
					}
					allLaborData = Object.values(allLaborData);
					setLaborListData(allLaborData);
				}
			})
			if (selectedLabor.length > 0) {
				if (selectedLaborCarpetIds.includes(productId)) {
					let allSelectedLaborData = selectedLabor;
					selectedLabor.map((item, index) => {
						if (item.laborItemId === productId) {
							allLaborData[index].price = calculatePrice(evt.target.value, currentCost);
							allLaborData[index].gpm = evt.target.value;
							allSelectedLaborData = Object.values(allSelectedLaborData);
							setSelectedLabor(allSelectedLaborData);
						}
					})
				}
			}
		}
	}

	//calculate line total from price for labor kit
	const calculateLineTotalFromPriceForLaborKit = (value, quantity) => {
		return parseFloat(value * quantity).toFixed(2)
	}

	// product list table labor price change
	const handleLaborPriceChange = (evt, laborItemId, currentCost, kitId, laborId) => {
		if (laborList.length > 0) {
			let allLaborData = laborList;
			laborList.map((item, index) => {
				if ((primaryProduct === WALLBASE_ID_DB || primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) ? item.laborId === laborId : item.laborItemId === laborItemId) {
					allLaborData[index].price = evt.target.value;
					allLaborData[index].gpm = calculateGpm(evt.target.value, currentCost);
					if(materialContractModalOpen && allLaborData[index].quantity) {
						allLaborData[index].linePrice = calculateLineTotalFromPriceForLaborKit(evt.target.value, allLaborData[index].quantity);
					}
					if(materialContractModalOpen && !allLaborData[index].quantity) {
						allLaborData[index].quantity = parseFloat(allLaborData[index].linePrice) / parseFloat(allLaborData[index].price);
					}
					allLaborData = Object.values(allLaborData);
					setPrimaryProductLaborData(allLaborData);
				}
			})
			if (primaryProduct === CARPET_ID_DB) {
				if (selectedLaborCarpetIds.includes(laborItemId)) {
					let allSelectedLaborCarpetData = selectedLaborCarpet;
					selectedLaborCarpet.map((item, index) => {
						if (item.laborItemId === laborItemId) {
							allSelectedLaborCarpetData[index].price = evt.target.value;
							allSelectedLaborCarpetData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedLaborCarpetData = Object.values(allSelectedLaborCarpetData);
							setSelectedLaborCarpet(allSelectedLaborCarpetData);
						}
					})
				}
			} else if (primaryProduct === SHEET_VINYL_ID_DB) {
				if (selectedLaborSheetVinylIds.includes(laborItemId)) {
					let allSelectedLaborSheetVinylData = selectedLaborSheetVinyl;
					selectedLaborSheetVinyl.map((item, index) => {
						if (item.laborItemId === laborItemId) {
							allSelectedLaborSheetVinylData[index].price = evt.target.value;
							allSelectedLaborSheetVinylData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedLaborSheetVinylData = Object.values(allSelectedLaborSheetVinylData);
							setSelectedLaborSheetVinyl(allSelectedLaborSheetVinylData);
						}
					})
				}
			} else if (primaryProduct === VINYL_PLANK_ID_DB) {
				if (selectedLaborVinylPlankIds.includes(laborItemId)) {
					let allSelectedLaborVinylPlankData = selectedLaborVinylPlank;
					selectedLaborVinylPlank.map((item, index) => {
						if (item.laborItemId === laborItemId) {
							allSelectedLaborVinylPlankData[index].price = evt.target.value;
							allSelectedLaborVinylPlankData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedLaborVinylPlankData = Object.values(allSelectedLaborVinylPlankData);
							setSelectedLaborVinylPlank(allSelectedLaborVinylPlankData);
						}
					})
				}
			} else if (primaryProduct === WALLBASE_ID_DB) {
				if (selectedLaborWallbaseLaborIds.includes(laborId)) {
					let allSelectedLaborWallbaseData = selectedLaborWallbase;
					selectedLaborWallbase.map((item, index) => {
						if (item.laborId === laborId) {
							allSelectedLaborWallbaseData[index].price = evt.target.value;
							allSelectedLaborWallbaseData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedLaborWallbaseData = Object.values(allSelectedLaborWallbaseData);
							setSelectedLaborWallbase(allSelectedLaborWallbaseData);
						}
					})
				}
			} else if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
				// if (selectedLaborSundriesIds.includes(laborItemId)) {
				if (selectedLaborSundriesLaborIds.includes(laborId)) {
					let allSelectedLaborSundriesData = selectedLaborSundries;
					selectedLaborSundries.map((item, index) => {
						// if (item.laborItemId === laborItemId) {
						if (item.laborId === laborId) {
							allSelectedLaborSundriesData[index].price = evt.target.value;
							allSelectedLaborSundriesData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedLaborSundriesData = Object.values(allSelectedLaborSundriesData);
							setSelectedLaborSundries(allSelectedLaborSundriesData);
						}
					})
				}
			} else if (primaryProduct === TRANSITION_ID_DB && materialContractModalOpen) {
				// if (selectedLaborTransitionIds.includes(laborItemId)) {
				if (selectedLaborTransitionLaborIds.includes(laborId)) {
					let allSelectedLaborTransitionData = selectedLaborTransition;
					selectedLaborTransition.map((item, index) => {
						// if (item.laborItemId === laborItemId) {
						if (item.laborId === laborId) {
							allSelectedLaborTransitionData[index].price = evt.target.value;
							allSelectedLaborTransitionData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedLaborTransitionData = Object.values(allSelectedLaborTransitionData);
							setSelectedLaborTransition(allSelectedLaborTransitionData);
						}
					})
				}
			}
		}
	}

	// product list table labor GPM change
	const handleLaborGpmChange = (evt, laborItemId, currentCost, kitId, laborId) => {
		if (laborList.length > 0) {
			let allLaborData = laborList;
			laborList.map((item, index) => {
				// if (item.laborItemId === laborItemId) {
				// if (item.laborItemId === laborItemId || item.kitId === kitId) {
				if ((primaryProduct === WALLBASE_ID_DB || primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) ? item.laborId === laborId : item.laborItemId === laborItemId) {
					allLaborData[index].price = calculatePrice(evt.target.value, currentCost);
					allLaborData[index].gpm = evt.target.value;
					if(materialContractModalOpen && allLaborData[index].quantity) {
						allLaborData[index].linePrice = calculateLineTotalFromPriceForLaborKit(allLaborData[index].price, allLaborData[index].quantity);
					}
					if(materialContractModalOpen && !allLaborData[index].quantity) {
						allLaborData[index].quantity = parseFloat(allLaborData[index].linePrice) / parseFloat(allLaborData[index].price);
					}
					allLaborData = Object.values(allLaborData);
					setPrimaryProductLaborData(allLaborData);
				}
			})
			if (primaryProduct === CARPET_ID_DB) {
				if (selectedLaborCarpetIds.includes(laborItemId)) {
					let allSelectedLaborCarpetData = selectedLaborCarpet;
					selectedLaborCarpet.map((item, index) => {
						if (item.laborItemId === laborItemId) {
							allSelectedLaborCarpetData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedLaborCarpetData[index].gpm = evt.target.value;
							allSelectedLaborCarpetData = Object.values(allSelectedLaborCarpetData);
							setSelectedLaborCarpet(allSelectedLaborCarpetData);
						}
					})
				}
			} else if (primaryProduct === SHEET_VINYL_ID_DB) {
				if (selectedLaborSheetVinylIds.includes(laborItemId)) {
					let allSelectedLaborSheetVinylData = selectedLaborSheetVinyl;
					selectedLaborSheetVinyl.map((item, index) => {
						if (item.laborItemId === laborItemId) {
							allSelectedLaborSheetVinylData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedLaborSheetVinylData[index].gpm = evt.target.value;
							allSelectedLaborSheetVinylData = Object.values(allSelectedLaborSheetVinylData);
							setSelectedLaborSheetVinyl(allSelectedLaborSheetVinylData);
						}
					})
				}
			} else if (primaryProduct === VINYL_PLANK_ID_DB) {
				if (selectedLaborVinylPlankIds.includes(laborItemId)) {
					let allSelectedLaborVinylPlankData = selectedLaborVinylPlank;
					selectedLaborVinylPlank.map((item, index) => {
						if (item.laborItemId === laborItemId) {
							allSelectedLaborVinylPlankData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedLaborVinylPlankData[index].gpm = evt.target.value;
							allSelectedLaborVinylPlankData = Object.values(allSelectedLaborVinylPlankData);
							setSelectedLaborVinylPlank(allSelectedLaborVinylPlankData);
						}
					})
				}
			} else if (primaryProduct === WALLBASE_ID_DB) {
				if (selectedLaborWallbaseLaborIds.includes(laborId)) {
					let allSelectedLaborWallbaseData = selectedLaborWallbase;
					selectedLaborWallbase.map((item, index) => {
						if (item.laborId === laborId) {
							allSelectedLaborWallbaseData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedLaborWallbaseData[index].gpm = evt.target.value;
							allSelectedLaborWallbaseData = Object.values(allSelectedLaborWallbaseData);
							setSelectedLaborWallbase(allSelectedLaborWallbaseData);
						}
					})
				}
			} else if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
				// if (selectedLaborSundriesIds.includes(laborItemId)) {
				if (selectedLaborSundriesLaborIds.includes(laborId)) {
					let allSelectedLaborSundriesData = selectedLaborSundries;
					selectedLaborSundries.map((item, index) => {
						// if (item.laborItemId === laborItemId) {
						if (item.laborId === laborId) {
							allSelectedLaborSundriesData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedLaborSundriesData[index].gpm = evt.target.value;
							allSelectedLaborSundriesData = Object.values(allSelectedLaborSundriesData);
							setSelectedLaborSundries(allSelectedLaborSundriesData);
						}
					})
				}
			} else if (primaryProduct === TRANSITION_ID_DB && materialContractModalOpen) {
				// if (selectedLaborTransitionIds.includes(laborItemId)) {
				if (selectedLaborTransitionLaborIds.includes(laborId)) {
					let allSelectedLaborTransitionData = selectedLaborTransition;
					selectedLaborTransition.map((item, index) => {
						// if (item.laborItemId === laborItemId) {
						if (item.laborId === laborId) {
							allSelectedLaborTransitionData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedLaborTransitionData[index].gpm = evt.target.value;
							allSelectedLaborTransitionData = Object.values(allSelectedLaborTransitionData);
							setSelectedLaborTransition(allSelectedLaborTransitionData);
						}
					})
				}
			}
		}
	}

	//calculate line total from price for material kit
	const calculateLineTotalFromPriceForMaterialKit = (value, quantity) => {
		return parseFloat(value * quantity).toFixed(2)
	}

	// product list table material price change
	const handleMaterialPriceChange = (evt, materialId, currentCost) => {
		if (materialList.length > 0) {
			let allMaterialData = materialList;
			materialList.map((item, index) => {
				if (item.materialId === materialId) {
					allMaterialData[index].price = evt.target.value;
					allMaterialData[index].gpm = calculateGpm(evt.target.value, currentCost);
					if(materialContractModalOpen && allMaterialData[index].quantity) {
						allMaterialData[index].linePrice = calculateLineTotalFromPriceForMaterialKit(evt.target.value, allMaterialData[index].quantity);
					}
					if(materialContractModalOpen && !allMaterialData[index].quantity) {
						allMaterialData[index].quantity = parseFloat(allMaterialData[index].linePrice) / parseFloat(allMaterialData[index].price);
					}
					allMaterialData = Object.values(allMaterialData);
					setPrimaryProductMaterialData(allMaterialData);
				}
			})
			if (primaryProduct === CARPET_ID_DB) {
				if (selectedMaterialCarpetIds.includes(materialId)) {
					let allSelectedMaterialCarpetData = selectedMaterialCarpet;
					selectedMaterialCarpet.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialCarpetData[index].price = evt.target.value;
							allSelectedMaterialCarpetData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedMaterialCarpetData = Object.values(allSelectedMaterialCarpetData);
							setSelectedMaterialCarpet(allSelectedMaterialCarpetData);
						}
					})
				}
			} else if (primaryProduct === SHEET_VINYL_ID_DB) {
				if (selectedMaterialSheetVinylIds.includes(materialId)) {
					let allSelectedMaterialSheetVinylData = selectedMaterialSheetVinyl;
					selectedMaterialSheetVinyl.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialSheetVinylData[index].price = evt.target.value;
							allSelectedMaterialSheetVinylData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedMaterialSheetVinylData = Object.values(allSelectedMaterialSheetVinylData);
							setSelectedMaterialSheetVinyl(allSelectedMaterialSheetVinylData);
						}
					})
				}
			} else if (primaryProduct === VINYL_PLANK_ID_DB) {
				if (selectedMaterialVinylPlankIds.includes(materialId)) {
					let allSelectedMaterialVinylPlankData = selectedMaterialVinylPlank;
					selectedMaterialVinylPlank.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialVinylPlankData[index].price = evt.target.value;
							allSelectedMaterialVinylPlankData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedMaterialVinylPlankData = Object.values(allSelectedMaterialVinylPlankData);
							setSelectedMaterialVinylPlank(allSelectedMaterialVinylPlankData);
						}
					})
				}
			} else if (primaryProduct === WALLBASE_ID_DB) {
				if (selectedMaterialWallbaseIds.includes(materialId)) {
					let allSelectedMaterialWallbaseData = selectedMaterialWallbase;
					selectedMaterialWallbase.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialWallbaseData[index].price = evt.target.value;
							allSelectedMaterialWallbaseData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedMaterialWallbaseData = Object.values(allSelectedMaterialWallbaseData);
							setSelectedMaterialWallbase(allSelectedMaterialWallbaseData);
						}
					})
				}
			} else if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
				if (selectedMaterialSundriesIds.includes(materialId)) {
					let allSelectedMaterialSundriesData = selectedMaterialSundries;
					selectedMaterialSundries.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialSundriesData[index].price = evt.target.value;
							allSelectedMaterialSundriesData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedMaterialSundriesData = Object.values(allSelectedMaterialSundriesData);
							setSelectedMaterialSundries(allSelectedMaterialSundriesData);
						}
					})
				}
			} else if (primaryProduct === TRANSITION_ID_DB && materialContractModalOpen) {
				if (selectedMaterialTransitionIds.includes(materialId)) {
					let allSelectedMaterialTransitionData = selectedMaterialTransition;
					selectedMaterialTransition.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialTransitionData[index].price = evt.target.value;
							allSelectedMaterialTransitionData[index].gpm = calculateGpm(evt.target.value, currentCost);
							allSelectedMaterialTransitionData = Object.values(allSelectedMaterialTransitionData);
							setSelectedMaterialTransition(allSelectedMaterialTransitionData);
						}
					})
				}
			}
		}
	}

	// product list table material GPM change
	const handleMaterialGpmChange = (evt, materialId, currentCost) => {
		if (materialList.length > 0) {
			let allMaterialData = materialList;
			materialList.map((item, index) => {
				if (item.materialId === materialId) {
					allMaterialData[index].price = calculatePrice(evt.target.value, currentCost);
					allMaterialData[index].gpm = evt.target.value;
					allMaterialData = Object.values(allMaterialData);
					if(materialContractModalOpen && allMaterialData[index].quantity) {
						allMaterialData[index].linePrice = calculateLineTotalFromPriceForMaterialKit(allMaterialData[index].price, allMaterialData[index].quantity);
					}
					if(materialContractModalOpen && !allMaterialData[index].quantity) {
						allMaterialData[index].quantity = parseFloat(allMaterialData[index].linePrice) / parseFloat(allMaterialData[index].price);
					}
					setPrimaryProductMaterialData(allMaterialData);
				}
			})
			if (primaryProduct === CARPET_ID_DB) {
				if (selectedMaterialCarpetIds.includes(materialId)) {
					let allSelectedMaterialCarpetData = selectedMaterialCarpet;
					selectedMaterialCarpet.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialCarpetData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedMaterialCarpetData[index].gpm = evt.target.value;
							allSelectedMaterialCarpetData = Object.values(allSelectedMaterialCarpetData);
							setSelectedMaterialCarpet(allSelectedMaterialCarpetData);
						}
					})
				}
			} else if (primaryProduct === SHEET_VINYL_ID_DB) {
				if (selectedMaterialSheetVinylIds.includes(materialId)) {
					let allSelectedMaterialSheetVinylData = selectedMaterialSheetVinyl;
					selectedMaterialSheetVinyl.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialSheetVinylData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedMaterialSheetVinylData[index].gpm = evt.target.value;
							allSelectedMaterialSheetVinylData = Object.values(allSelectedMaterialSheetVinylData);
							setSelectedMaterialSheetVinyl(allSelectedMaterialSheetVinylData);
						}
					})
				}
			} else if (primaryProduct === VINYL_PLANK_ID_DB) {
				if (selectedMaterialVinylPlankIds.includes(materialId)) {
					let allSelectedMaterialVinylPlankData = selectedMaterialVinylPlank;
					selectedMaterialVinylPlank.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialVinylPlankData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedMaterialVinylPlankData[index].gpm = evt.target.value;
							allSelectedMaterialVinylPlankData = Object.values(allSelectedMaterialVinylPlankData);
							setSelectedMaterialVinylPlank(allSelectedMaterialVinylPlankData);
						}
					})
				}
			} else if (primaryProduct === WALLBASE_ID_DB) {
				if (selectedMaterialWallbaseIds.includes(materialId)) {
					let allSelectedMaterialWallbaseData = selectedMaterialWallbase;
					selectedMaterialWallbase.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialWallbaseData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedMaterialWallbaseData[index].gpm = evt.target.value;
							allSelectedMaterialWallbaseData = Object.values(allSelectedMaterialWallbaseData);
							setSelectedMaterialWallbase(allSelectedMaterialWallbaseData);
						}
					})
				}
			} else if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
				if (selectedMaterialSundriesIds.includes(materialId)) {
					let allSelectedMaterialSundriesData = selectedMaterialSundries;
					selectedMaterialSundries.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialSundriesData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedMaterialSundriesData[index].gpm = evt.target.value;
							allSelectedMaterialSundriesData = Object.values(allSelectedMaterialSundriesData);
							setSelectedMaterialSundries(allSelectedMaterialSundriesData);
						}
					})
				}
			} else if (primaryProduct === TRANSITION_ID_DB && materialContractModalOpen) {
				if (selectedMaterialTransitionIds.includes(materialId)) {
					let allSelectedMaterialTransitionData = selectedMaterialTransition;
					selectedMaterialTransition.map((item, index) => {
						if (item.materialId === materialId) {
							allSelectedMaterialTransitionData[index].price = calculatePrice(evt.target.value, currentCost);
							allSelectedMaterialTransitionData[index].gpm = evt.target.value;
							allSelectedMaterialTransitionData = Object.values(allSelectedMaterialTransitionData);
							setSelectedMaterialTransition(allSelectedMaterialTransitionData);
						}
					})
				}
			}
		}
	}

	// function called to set filter for product
	const handleProductFilterChange = (evt) => {
		const { id, value } = evt.target;
		if(id === 'StoreId') {
			setStateProductFilter({
				...stateProductFilter,
				"subProductStructureId": 0,
				[id]: value
			})
		} else {
			setStateProductFilter({
				...stateProductFilter,
				[id]: value
			})
		}
		if (id === 'StoreId') {
			let checkFlag = (laborContractModalOpen || materialContractModalOpen) ? true : false
			fetchLaborForPricingContract(auth[0].token, {
				"ProductStructureId": stateProductFilter.ProductStructureId,
				"ProductStructureIdForLabor":0,
				"StoreId": value,
				"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
				"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
				"subProductStructureId": 0,
			}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
			fetchMaterialForPricingContract(auth[0].token, {
				"ProductStructureId": stateProductFilter.ProductStructureId,
				"StoreId": value,
				"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
				"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
				"subProductStructureId": 0,
			}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
			if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB || primaryProduct === TRANSITION_ID_DB) {
				fetchTransitionLaborForPricingContract(auth[0].token, {
					"ProductStructureId": TRANSITION_ID_DB,
					"ProductStructureIdForLabor":0,
					"StoreId": value,
					"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
					"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
					"subProductStructureId": 0,
				}, checkFlag);
				fetchTransitionMaterialForPricingContract(auth[0].token, {
					"ProductStructureId": TRANSITION_ID_DB,
					"StoreId": value,
					"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
					"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
					"subProductStructureId": 0,
				}, checkFlag);
			}
		}
		if(id === 'subProductStructureId') {
			let checkFlag = (laborContractModalOpen || materialContractModalOpen) ? true : false
			fetchMaterialForPricingContract(auth[0].token, {
				"ProductStructureId": stateProductFilter.ProductStructureId,
				"StoreId": stateProductFilter.StoreId,
				"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
				"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
				"subProductStructureId": value ? value : 0,
			}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);
			fetchLaborForPricingContract(auth[0].token, {
				"ProductStructureId": stateProductFilter.ProductStructureId,
				"ProductStructureIdForLabor":0,
				"StoreId": stateProductFilter.StoreId,
				"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
				"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
				"subProductStructureId": value ? value : 0,
			}, checkFlag, checkFlag ? unitId : null, checkFlag ? jobNum : null);	
			if(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB || primaryProduct === TRANSITION_ID_DB) {
				fetchTransitionLaborForPricingContract(auth[0].token, {
					"ProductStructureId": TRANSITION_ID_DB,
					"ProductStructureIdForLabor":0,
					"StoreId": stateProductFilter.StoreId,
					"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
					"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
					"subProductStructureId": value ? value : 0,
				}, checkFlag);
				fetchTransitionMaterialForPricingContract(auth[0].token, {
					"ProductStructureId": TRANSITION_ID_DB,
					"StoreId": stateProductFilter.StoreId,
					"ContactInfoId": groupPricingContractId ? 0 : contactInfoId[0],
					"grpPricingContractId": groupPricingContractId ? groupPricingContractId : "",
					"subProductStructureId": value ? value : 0,
				}, checkFlag);
			}
		}
	}

	// function called to set filter for product checkboxes
	const handleCheckboxSelect = (evt, type) => {
		if (evt.target.checked) {
			setStateProductFilter({
				...stateProductFilter,
				[type]: true
			})
		} else {
			setStateProductFilter({
				...stateProductFilter,
				[type]: ''
			})
		}
	}

	// function called to set filter for labor
	const handleLaborFilterChange = (evt) => {
		const { id, value } = evt.target;
		setStateLaborFilter({
			...stateLaborFilter,
			[id]: value
		})
	}
	
	// function to save data by api
	const handleSaveClick = () => {

		localStorage.removeItem("selectedSundriesList")
		let productPricing = [];
		let laborPricing = [];
		let materialPricing = [];

		// setting product data in product pricing
		if (selectedCarpet.length > 0) {
			selectedCarpet.map((item) => {
				productPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"productId": item.productId,
					"isPrimary": item.isPrimary,
					"isSecondary": item.isSecondary
				 })
			})
		}
		if (selectedSheetVinyl.length > 0) {
			selectedSheetVinyl.map((item) => {
				productPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"productId": item.productId,
					"isPrimary": item.isPrimary,
					"isSecondary": item.isSecondary
				 })
			})
		}
		if (selectedVinylPlank.length > 0) {
			selectedVinylPlank.map((item) => {
				productPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"productId": item.productId,
					"isPrimary": item.isPrimary,
					"isSecondary": item.isSecondary
				 })
			})
		}
		if (selectedWallbase.length > 0) {
			selectedWallbase.map((item) => {
				productPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"productId": item.productId,
					"isPrimary": item.isPrimary,
					"isSecondary": item.isSecondary
				 })
			})
		}
		if (selectedSundries.length > 0) {
			selectedSundries.map((item) => {
				productPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"productId": item.productId,
					"isPrimary": item.isPrimary,
					"isSecondary": item.isSecondary
				 })
			})
		}

		// setting labor data in labor pricing
		if (selectedLaborCarpet.length > 0) {
			selectedLaborCarpet.map((item) => {
				laborPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"LaborId": item.laborId ? item.laborId : "",
					"LaborItemId": item.laborItemId ? item.laborItemId : ""
				 })
			})
		}
		if (selectedLaborSheetVinyl.length > 0) {
			selectedLaborSheetVinyl.map((item) => {
				laborPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"LaborId": item.laborId ? item.laborId : "",
					"LaborItemId": item.laborItemId ? item.laborItemId : ""
				 })
			})
		}
		if (selectedLaborVinylPlank.length > 0) {
			selectedLaborVinylPlank.map((item) => {
				laborPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"LaborId": item.laborId ? item.laborId : "",
					"LaborItemId": item.laborItemId ? item.laborItemId : ""
				 })
			})
		}
		if (selectedLabor.length > 0) {
			selectedLabor.map((item) => {
				laborPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"LaborId": item.laborId ? item.laborId : "",
					"LaborItemId": item.laborItemId ? item.laborItemId : ""
				 })
			})
		}
		if (selectedLaborWallbase.length > 0) {
			selectedLaborWallbase.map((item) => {
				laborPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"LaborId": item.laborId ? item.laborId : "",
					"LaborItemId": item.laborItemId ? item.laborItemId : ""
				 })
			})
		}
		if (selectedLaborSundries.length > 0) {
			selectedLaborSundries.map((item) => {
				laborPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"LaborId": item.laborId ? item.laborId : "",
					"LaborItemId": item.laborItemId ? item.laborItemId : ""
				 })
			})
		}

		// setting material data in material pricing
		if (selectedMaterialCarpet.length > 0) {
			selectedMaterialCarpet.map((item) => {
				materialPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"MaterialId": item.materialId
				 })
			})
		}
		if (selectedMaterialSheetVinyl.length > 0) {
			selectedMaterialSheetVinyl.map((item) => {
				materialPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"MaterialId": item.materialId
				 })
			})
		}
		if (selectedMaterialVinylPlank.length > 0) {
			selectedMaterialVinylPlank.map((item) => {
				materialPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"MaterialId": item.materialId
				 })
			})
		}
		if (selectedMaterialWallbase.length > 0) {
			selectedMaterialWallbase.map((item) => {
				materialPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"MaterialId": item.materialId
				 })
			})
		}
		if (selectedMaterialSundries.length > 0) {
			selectedMaterialSundries.map((item) => {
				materialPricing.push({
					"price": item.price,
					"gpm": item.gpm,
					"MaterialId": item.materialId
				 })
			})
		}

		let rawData = {};
		if (groupPricingContractId) {
			rawData = {
				"ContactInfoIds": contactInfoId,
				"GroupPricingContractId":groupPricingContractId,
				"CreatedBy": auth[0].userId,
				"IsPrimary": false,
				"IsSecondary": false,
				"lPricingContract": productPricing,
				"lLaborPricingContract": laborPricing,
				"lMaterialPricingContract": materialPricing
			}
		} else {
			rawData = {
				"ContactInfoIds": contactInfoId,
				"CreatedBy": auth[0].userId,
				"IsPrimary": false,
				"IsSecondary": false,
				"lPricingContract": productPricing,
				"lLaborPricingContract": laborPricing,
				"lMaterialPricingContract": materialPricing
			}
		}
		createPricingContract(auth[0].token, rawData);
	}

	// function called on edit button click
	const handleEditClick = () => {
		selectedPrimaryProduct.push(CARPET_ID_DB, SHEET_VINYL_ID_DB, VINYL_PLANK_ID_DB);
		selectedPrimaryProductCopy.push(CARPET_ID_DB, SHEET_VINYL_ID_DB, VINYL_PLANK_ID_DB);
		setSelectPrimaryProduct(selectedPrimaryProduct);
		setSelectedPrimaryProductCopy(selectedPrimaryProductCopy);
		setShowSummary(false);
		localStorage.removeItem("selectedSundriesList")
		handleNextClick();
	}

	// function called to show success message for contract creation and closing the modal
	useMemo(()=> {
		if(isPricingContract === true) {
			if(isContractPricingAdded) {
				setShowSaveOnEdit(false);
				changeCustomerReducerState();
				changePricingContractReducerState();
				handleModalCloseClick();
				toast.success("Pricing contract edited successfully.");
			} 
			if(!isContractPricingAdded) {
				setShowSaveOnEdit(false);
				changeCustomerReducerState();
				changePricingContractReducerState();
				handleModalCloseClick();
				if(!groupPricingContractId) {
					handleUpdateCustomerDetail();
				}
				toast.success("Pricing contract created successfully.");
			}
		}
	}, [isPricingContract])

	useMemo(()=> {
		if (isCreated === true) {
			if (isContractPricingAdded === true) {
				setShowSaveOnEdit(false);
			}
			handleModalCloseClick();
		}
	}, [isCreated])

	// function called to show price/gpm modal
	const showPriceGpmModal = (value) => {
		setPriceGpmModalOpen(true);
	}

	// function called to open price modal
	const handleAllPrice = () => {
		showPriceGpmModal("Price");
	}

	// function called to open gpm modal
	const handleAllGpm = () => {
		showPriceGpmModal("Gpm");
	}

	// function called to hide price/gpm modal
	const hidePriceGpmModal = () => {
		setPriceGpmModalOpen(false);
		setPriceValue();
		setGpmValue();
	}

	// function to handle all price/gpm value
	const handlePriceGpmValue = () => {
		if (primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) {
			let tempIds = []
			if (priceValue && priceValue.length>0) {
				if (selectedSundries.length > 0) {
					selectedSundries.map((data, index) => {
						tempIds.push(data.productId)
						let tempGpm = calculateGpm(priceValue, data.currentCost)
						selectedSundries[index].price = priceValue
						selectedSundries[index].gpm = tempGpm
					})
				}
				if (productListData.length > 0 && tempIds.length > 0) {
					productListData.map((data, index) => {
						if (tempIds.includes(data.productId)) {
							tempIds.push(data.productId)
							let tempGpm = calculateGpm(priceValue, data.currentCost)
							productListData[index].price = priceValue
							productListData[index].gpm = tempGpm
						}
					})
				}
			}
			if (gpmValue && gpmValue.length>0) {
				if (selectedSundries.length > 0) {
					selectedSundries.map((data, index) => {
						tempIds.push(data.productId)
						let tempPrice = calculatePrice(gpmValue, data.currentCost)
						selectedSundries[index].price = tempPrice
						selectedSundries[index].gpm = gpmValue
					})
					if (productListData.length > 0 && tempIds.length > 0) {
						productListData.map((data, index) => {
							if (tempIds.includes(data.productId)) {
								tempIds.push(data.productId)
								let tempPrice = calculatePrice(gpmValue, data.currentCost)
								productListData[index].price = tempPrice
								productListData[index].gpm = gpmValue
							}
						})
					}
				}
			}
		}
		if(showLaborList) {
			if(priceValue && priceValue.length>0) {
				if(selectedLabor.length > 0) {
					selectedLabor.map((data, index) => {
						let tempGpm = calculateGpm(priceValue, data.currentCost)
						selectedLabor[index].price = priceValue
						selectedLabor[index].gpm = tempGpm
					})
				}
			}
			if(gpmValue && gpmValue.length>0) {
				if(selectedLabor.length > 0) {
					selectedLabor.map((data, index) => {
						let tempPrice = calculatePrice(gpmValue, data.currentCost)
						selectedLabor[index].price = tempPrice
						selectedLabor[index].gpm = gpmValue
					})
				}
			}
		}
		hidePriceGpmModal()
	}

	// function for handle change in price and gpm values
	const handlePriceGpmChange = (e, value) => {
		if(value === "Price") {
			setPriceValue(e.target.value)
		}
		if(value === "Gpm") {
			setGpmValue(e.target.value)
		}
	}

	// calculate total price from quantity
	const calculateLineTotalFromQuantity = (info, quantity) => {
		return parseFloat(info.price * quantity).toFixed(2)
	}

	// handle product quantity change
	const handleQuantityData = (value, data) => {
		if(productListData.length > 0) {
			let productListDetails = productListData
			productListDetails.map((info, index) => {
				if(info.productId === data.productId) {
					productListDetails[index].quantity = value
					if(info.price) {
						productListDetails[index].linePrice = calculateLineTotalFromQuantity(info, value)
					}
					productListDetails = Object.values(productListDetails)
					setProductListData(productListDetails)
				}
			})
		}
	}

	// handle quantity change for material
	const handleQuantityChange = (e, data, id) => {
		if(id === CARPET_ID_DB || id === SHEET_VINYL_ID_DB || id === VINYL_PLANK_ID_DB || id === WALLBASE_ID_DB || id === INSTALLATION_ACCESSORIES_ID_DB || id === TRANSITION_ID_DB) {
			handleQuantityData(e.target.value, data)
		}
	}

	// calculate total price from quantity for labor kit
	const calculateLineTotalFromQuantityForLaborKit = (info, quantity) => {
		return parseFloat(info.price * quantity).toFixed(2)
	}
	
	// handle product quantity change for labor kit
	const handleLaborkitQuantityData = (value, data) => {
		if(primaryProductLaborData.length > 0) {
			let productListDetails = primaryProductLaborData
			productListDetails.map((info, index) => {
				if(info.laborId === data.laborId) {
					productListDetails[index].quantity = value
					if(info.price) {
						productListDetails[index].linePrice = calculateLineTotalFromQuantityForLaborKit(info, value)
					}
					productListDetails = Object.values(productListDetails)
					setPrimaryProductLaborData(productListDetails)
				}
			})
		}
	}

	// handle quantity change for labor kit
	const handleLaborKitQuantityChange = (e, data, id) => {
		if(id === CARPET_ID_DB || id === SHEET_VINYL_ID_DB || id === VINYL_PLANK_ID_DB || id === WALLBASE_ID_DB || id === INSTALLATION_ACCESSORIES_ID_DB || id === TRANSITION_ID_DB) {
			handleLaborkitQuantityData(e.target.value, data)
		}
	}

	// calculate total price from quantity for material kit
	const calculateLineTotalFromQuantityForMaterialKit = (info, quantity) => {
		return parseFloat(info.price * quantity).toFixed(2)
	}
	
	// handle product quantity change for material kit
	const handleMaterialkitQuantityData = (value, data) => {
		if(primaryProductMaterialData.length > 0) {
			let productListDetails = primaryProductMaterialData
			productListDetails.map((info, index) => {
				if(info.materialId === data.materialId) {
					productListDetails[index].quantity = value ? value : 0
					if(info.price) {
						productListDetails[index].linePrice = calculateLineTotalFromQuantityForMaterialKit(info, value)
					}
					productListDetails = Object.values(productListDetails)
					setPrimaryProductMaterialData(productListDetails)
				}
			})
		}
	}

	// handle quantity change for material kit
	const handleMaterialKitQuantityChange = (e, data, id) => {
		if(id === CARPET_ID_DB || id === SHEET_VINYL_ID_DB || id === VINYL_PLANK_ID_DB || id === WALLBASE_ID_DB || id === INSTALLATION_ACCESSORIES_ID_DB || id === TRANSITION_ID_DB) {
			handleMaterialkitQuantityData(e.target.value, data)
		}
	}

	// calculate total price from quantity
	const calculateQuantityFromLineTotalForMaterial = (info, linePrice) => {
		return parseFloat(linePrice / info.price).toFixed(2)
	}

	// handle product line total change
	const handleLineTotalData = (value, data) => {
		if(productListData.length > 0) {
			let productListDetails = productListData
			productListDetails.map((info, index) => {
				if(info.productId === data.productId) {
					productListDetails[index].linePrice = value
					if(info.price) {
						productListDetails[index].quantity = calculateQuantityFromLineTotalForMaterial(info, value)
					}
					productListDetails = Object.values(productListDetails)
					setProductListData(productListDetails)
				}
			})
		}
	}

	// handle order labor line total change
	const handleLaborOrderLineTotalData = (value, data) => {
		if(laborListData.length > 0) {
			let laborListDetails = laborListData
			laborListDetails.map((info, index) => {
				if(info.laborItemId === data.laborItemId) {
					laborListDetails[index].linePrice = value
					if(info.price) {
						laborListDetails[index].quantity = calculateQuantityFromLineTotalForMaterial(info, value)
					}
					laborListDetails = Object.values(laborListDetails)
					setLaborListData(laborListDetails)
				}
			})
		}
	}

	// handle line total change for material
	const handlePrimaryProductLineTotalChange = (e, data, id) => {
		if(id === CARPET_ID_DB || id === SHEET_VINYL_ID_DB || id === VINYL_PLANK_ID_DB || id === WALLBASE_ID_DB || id === INSTALLATION_ACCESSORIES_ID_DB || id === TRANSITION_ID_DB) {
			handleLineTotalData(e.target.value, data)
		}
		if(id === 0) {
			handleLaborOrderLineTotalData(e.target.value, data)
		}
	}

	// calculate total price from quantity for labor kit
	const calculateQuantityFromLineTotalForLaborKit = (info, lineTotal) => {
		return parseFloat(lineTotal / info.price).toFixed(2)
	}
	
	// handle product LineTotal change for labor kit
	const handleLaborkitLineTotalData = (value, data) => {
		if(primaryProductLaborData.length > 0) {
			let productListDetails = primaryProductLaborData
			productListDetails.map((info, index) => {
				if(info.laborId === data.laborId) {
					productListDetails[index].linePrice = value
					if(info.price) {
						productListDetails[index].quantity = calculateQuantityFromLineTotalForLaborKit(info, value)
					}
					productListDetails = Object.values(productListDetails)
					setPrimaryProductLaborData(productListDetails)
				}
			})
		}
	}

	// handle Line Total change for labor kit
	const handlePrimaryProductLaborKitLineTotalChange = (e, data, id) => {
		if(id === CARPET_ID_DB || id === SHEET_VINYL_ID_DB || id === VINYL_PLANK_ID_DB) {
			handleLaborkitLineTotalData(e.target.value, data)
		}
	}

	// calculate total price from quantity for material kit
	const calculateQuantityFromLineTotalForMaterialKit = (info, lineTotal) => {
		return parseFloat(lineTotal / info.price).toFixed(2)
	}
	
	// handle product quantity change for material kit
	const handleMaterialkitLineTotalData = (value, data) => {
		if(primaryProductMaterialData.length > 0) {
			let productListDetails = primaryProductMaterialData
			productListDetails.map((info, index) => {
				if(info.materialId === data.materialId) {
					productListDetails[index].linePrice = value ? value : 0
					if(info.price) {
						productListDetails[index].quantity = calculateQuantityFromLineTotalForMaterialKit(info, value)
					}
					productListDetails = Object.values(productListDetails)
					setPrimaryProductMaterialData(productListDetails)
				}
			})
		}
	}

	// handle quantity change for material kit
	const handlePrimaryProductMaterialKitLineTotalChange = (e, data, id) => {
		if(id === CARPET_ID_DB || id === SHEET_VINYL_ID_DB || id === VINYL_PLANK_ID_DB) {
			handleMaterialkitLineTotalData(e.target.value, data)
		}
	}

	// calculate total price from quantity for order labor
	const calculateLineTotalFromQuantityForOrderLabor = (info, quantity) => {
		return parseFloat(quantity * info.price).toFixed(2)
	}
	
	// handle product quantity change for material kit
	const handleOrderLaborLineTotalData = (value, data) => {
		if(laborListData.length > 0) {
			let laborListDetails = laborListData
			laborListDetails.map((info, index) => {
				if(info.laborItemId === data.laborItemId) {
					laborListDetails[index].quantity = value ? value : 0
					if(info.price) {
						laborListDetails[index].linePrice = calculateLineTotalFromQuantityForOrderLabor(info, value)
					}
					laborListDetails = Object.values(laborListDetails)
					setLaborListData(laborListDetails)
				}
			})
		}
	}

	// handle order labor quantity change
	const handleOrderLaborQuantityChange = (e, item, id) => {
		if(id === 0) {
			handleOrderLaborLineTotalData(e.target.value, item)
		}
	}

	// handle missing stairs labor condition
	const handleStairsLabor = (bool) => {
		setIsMissingStairsLabor(bool)
	}

	// handle missing stairs for pricing contract button
	const handleMissingStairs = () => {
		if (missingStairsLaborIds && missingStairsLaborIds.length > 0) {
			if (isMissingStairsLabor === null) {
				let difference = missingStairsLaborIds.filter(x => !selectedLaborIds.includes(x));
				if (difference.length > 0) {
					return true;
				} else {
					return false;
				}
			} else {
				return isMissingStairsLabor;
			}
		}
	}

	return (
		<Fragment>
			<Button className="mb-2" variant="primary" onClick={showPricingContractModal}>
				Pricing Contract
			</Button>
			{!laborContractModalOpen && !materialContractModalOpen && isContractPricingAdded && handleMissingStairs() ?
				<span ref={target} className="pc-error">*</span> : null
			}
			<Overlay target={target.current} show={!laborContractModalOpen && !materialContractModalOpen && isContractPricingAdded && handleMissingStairs() ? true : false} placement="right">
        {(props) => (
          <Tooltip id="overlay" {...props} className="overlay-tooltip">
            Stairs prices missing in contract
          </Tooltip>
        )}
      </Overlay>
			<Modal id="pricing-contract" show={pricingContractModalOpen} size="" onHide={handleModalCloseClick} backdrop="static">
				<Modal.Header closeButton>
					<Modal.Title>Pricing Contract</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{(primaryProduct === '' && (isContractPricingAdded === false || materialContractModalOpen)) ? (
						<div className="step">
							<h6 className="text-center title">What materials would you like to {materialContractModalOpen ? 'add' : 'price'}?</h6>
							<Form className="select-material">
								{!materialContractModalOpen && !laborContractModalOpen && ProductType.valueList && ProductType.valueList.map((item, index) => (
									(item.productStructureId === CARPET_ID_DB || item.productStructureId === SHEET_VINYL_ID_DB || item.productStructureId === VINYL_PLANK_ID_DB) && (
										<Form.Check 
											key={index} 
											id={"product_type_select"+index} 
											type="checkbox" 
											name="product_type_select" 
											onChange={(e)=>handleProductTypeChangeClick(e)} 
											label={item.productStructure} 
											value={item.productStructureId} 
											checked={selectedPrimaryProduct.includes(item.productStructureId)} 
										/>
									)
								))}
								{materialContractModalOpen && !laborContractModalOpen && ProductType.valueList && ProductType.valueList.map((item, index) => (
									<Form.Check 
											key={index} 
											id={"product_type_select"+index} 
											type="checkbox" 
											name="product_type_select" 
											onChange={(e)=>handleProductTypeChangeClick(e)} 
											label={item.productStructure} 
											value={item.productStructureId} 
											checked={selectedPrimaryProduct.includes(item.productStructureId)} 
										/>
								))}
							</Form>
						</div>
					) : ""}
					{(primaryProduct === CARPET_ID_DB) ? (
						<PrimaryProductList
							storeId={storeId}
							productList={productListData}
							laborList={primaryProductLaborData}
							materialList={primaryProductMaterialData}
							primaryProductId={primaryProduct}
							primaryProductName={"carpet"}
							handleCheckboxClick={handleCheckboxClick}
							selectedIds={selectedCarpetIds}
							handlePriceChange={handlePriceChange}
							handleGpmChange={handleGpmChange}
							handleLaborCheckboxClick={handleLaborCheckboxClick}
							selectedLaborIds={selectedLaborCarpetIds}
							handleMaterialCheckboxClick={handleMaterialCheckboxClick}
							selectedMaterialIds={selectedMaterialCarpetIds}
							handleLaborPriceChange={handleLaborPriceChange}
							handleLaborGpmChange={handleLaborGpmChange}
							handleMaterialPriceChange={handleMaterialPriceChange}
							handleMaterialGpmChange={handleMaterialGpmChange}
							stateProductFilter={stateProductFilter}
							handleProductFilterChange={handleProductFilterChange}
							handleCheckboxSelect={handleCheckboxSelect}
							handleSecondaryCheckboxChange={handleSecondaryCheckboxChange}
							materialContractModalOpen={materialContractModalOpen}
							laborContractModalOpen={laborContractModalOpen}
							handleQuantityChange={handleQuantityChange}
							handleLaborKitQuantityChange={handleLaborKitQuantityChange}
							handleMaterialKitQuantityChange={handleMaterialKitQuantityChange}
							handlePrimaryProductLineTotalChange={handlePrimaryProductLineTotalChange}
							handlePrimaryProductMaterialKitLineTotalChange={handlePrimaryProductMaterialKitLineTotalChange}
							handlePrimaryProductLaborKitLineTotalChange={handlePrimaryProductLaborKitLineTotalChange}
						/>
					) : ""}
					{(primaryProduct === SHEET_VINYL_ID_DB) ? (
						<PrimaryProductList
							storeId={storeId}
							productList={productListData}
							laborList={primaryProductLaborData}
							materialList={primaryProductMaterialData}
							primaryProductId={primaryProduct}
							primaryProductName={"sheet vinyl"}
							handleCheckboxClick={handleCheckboxClick}
							selectedIds={selectedSheetVinylIds}
							handlePriceChange={handlePriceChange}
							handleGpmChange={handleGpmChange}
							handleLaborCheckboxClick={handleLaborCheckboxClick}
							selectedLaborIds={selectedLaborSheetVinylIds}
							handleMaterialCheckboxClick={handleMaterialCheckboxClick}
							selectedMaterialIds={selectedMaterialSheetVinylIds}
							handleLaborPriceChange={handleLaborPriceChange}
							handleLaborGpmChange={handleLaborGpmChange}
							handleMaterialPriceChange={handleMaterialPriceChange}
							handleMaterialGpmChange={handleMaterialGpmChange}
							stateProductFilter={stateProductFilter}
							handleProductFilterChange={handleProductFilterChange}
							handleCheckboxSelect={handleCheckboxSelect}
							handleSecondaryCheckboxChange={handleSecondaryCheckboxChange}
							materialContractModalOpen={materialContractModalOpen}
							laborContractModalOpen={laborContractModalOpen}
							handleQuantityChange={handleQuantityChange}
							handleLaborKitQuantityChange={handleLaborKitQuantityChange}
							handleMaterialKitQuantityChange={handleMaterialKitQuantityChange}
							handlePrimaryProductLineTotalChange={handlePrimaryProductLineTotalChange}
							handlePrimaryProductMaterialKitLineTotalChange={handlePrimaryProductMaterialKitLineTotalChange}
							handlePrimaryProductLaborKitLineTotalChange={handlePrimaryProductLaborKitLineTotalChange}
						/>
					) : ""}
					{(primaryProduct === VINYL_PLANK_ID_DB) ? (
						<PrimaryProductList
							storeId={storeId}
							productList={productListData}
							laborList={primaryProductLaborData}
							materialList={primaryProductMaterialData}
							primaryProductId={primaryProduct}
							primaryProductName={"vinyl plank"}
							handleCheckboxClick={handleCheckboxClick}
							selectedIds={selectedVinylPlankIds}
							handlePriceChange={handlePriceChange}
							handleGpmChange={handleGpmChange}
							handleLaborCheckboxClick={handleLaborCheckboxClick}
							selectedLaborIds={selectedLaborVinylPlankIds}
							handleMaterialCheckboxClick={handleMaterialCheckboxClick}
							selectedMaterialIds={selectedMaterialVinylPlankIds}
							handleLaborPriceChange={handleLaborPriceChange}
							handleLaborGpmChange={handleLaborGpmChange}
							handleMaterialPriceChange={handleMaterialPriceChange}
							handleMaterialGpmChange={handleMaterialGpmChange}
							stateProductFilter={stateProductFilter}
							handleProductFilterChange={handleProductFilterChange}
							handleCheckboxSelect={handleCheckboxSelect}
							handleSecondaryCheckboxChange={handleSecondaryCheckboxChange}
							materialContractModalOpen={materialContractModalOpen}
							laborContractModalOpen={laborContractModalOpen}
							handleQuantityChange={handleQuantityChange}
							handleLaborKitQuantityChange={handleLaborKitQuantityChange}
							handleMaterialKitQuantityChange={handleMaterialKitQuantityChange}
							handlePrimaryProductLineTotalChange={handlePrimaryProductLineTotalChange}
							handlePrimaryProductMaterialKitLineTotalChange={handlePrimaryProductMaterialKitLineTotalChange}
							handlePrimaryProductLaborKitLineTotalChange={handlePrimaryProductLaborKitLineTotalChange}
						/>
					) : ""}
					{(primaryProduct === WALLBASE_ID_DB) ? (
						<PrimaryProductList
							storeId={storeId}
							productList={productListData}
							primaryProductId={primaryProduct}
							primaryProductName={"wall base"}
							handleCheckboxClick={handleCheckboxClick}
							selectedIds={selectedWallbaseIds}
							selectedData={selectedWallbase}
							handlePriceChange={handlePriceChange}
							handleGpmChange={handleGpmChange}
							stateProductFilter={stateProductFilter}
							handleProductFilterChange={handleProductFilterChange}
							handleCheckboxSelect={handleCheckboxSelect}
							handleSecondaryCheckboxChange={handleSecondaryCheckboxChange}
							materialContractModalOpen={materialContractModalOpen}
							laborContractModalOpen={laborContractModalOpen}
							handleQuantityChange={handleQuantityChange}
							handleLaborKitQuantityChange={handleLaborKitQuantityChange}
							handleMaterialKitQuantityChange={handleMaterialKitQuantityChange}
							handlePrimaryProductLineTotalChange={handlePrimaryProductLineTotalChange}
							handlePrimaryProductMaterialKitLineTotalChange={handlePrimaryProductMaterialKitLineTotalChange}
							handlePrimaryProductLaborKitLineTotalChange={handlePrimaryProductLaborKitLineTotalChange}
							productSubStructure={productSubStructure}
							laborList={primaryProductLaborData}
							materialList={primaryProductMaterialData}
							// selectedLaborIds={selectedLaborWallbaseIds}
							// selectedLaborKitIds={selectedLaborWallbaseKitIds}
							selectedKitLaborIds={selectedLaborWallbaseLaborIds}
							selectedMaterialIds={selectedMaterialWallbaseIds}
							handleLaborCheckboxClick={handleLaborCheckboxClick}
							handleMaterialCheckboxClick={handleMaterialCheckboxClick}
							handleLaborPriceChange={handleLaborPriceChange}
							handleMaterialPriceChange={handleMaterialPriceChange}
							handleLaborGpmChange={handleLaborGpmChange}
							handleMaterialGpmChange={handleMaterialGpmChange}
						/>
					) : ""}
					{(primaryProduct === INSTALLATION_ACCESSORIES_ID_DB) ? (
						<PrimaryProductList
							storeId={storeId}
							productList={productListData}
							primaryProductId={primaryProduct}
							primaryProductName={materialContractModalOpen ? "installation accessories" : "miscellaneous sundries"}
							handleCheckboxClick={handleCheckboxClick}
							selectedIds={selectedSundriesIds}
							selectedData={selectedSundries}
							handlePriceChange={handlePriceChange}
							handleGpmChange={handleGpmChange}
							stateProductFilter={stateProductFilter}
							handleProductFilterChange={handleProductFilterChange}
							handleCheckboxSelect={handleCheckboxSelect}
							handleSecondaryCheckboxChange={handleSecondaryCheckboxChange}
							handleAllPrice={handleAllPrice}
							handleAllGpm={handleAllGpm}
							materialContractModalOpen={materialContractModalOpen}
							laborContractModalOpen={laborContractModalOpen}
							handleQuantityChange={handleQuantityChange}
							handleLaborKitQuantityChange={handleLaborKitQuantityChange}
							handleMaterialKitQuantityChange={handleMaterialKitQuantityChange}
							handlePrimaryProductLineTotalChange={handlePrimaryProductLineTotalChange}
							handlePrimaryProductMaterialKitLineTotalChange={handlePrimaryProductMaterialKitLineTotalChange}
							handlePrimaryProductLaborKitLineTotalChange={handlePrimaryProductLaborKitLineTotalChange}
							productSubStructure={productSubStructure}
							laborList={primaryProductLaborData}
							materialList={primaryProductMaterialData}
							// selectedLaborIds={selectedLaborSundriesIds}
							selectedKitLaborIds={selectedLaborSundriesLaborIds}
							selectedMaterialIds={selectedMaterialSundriesIds}
							handleLaborCheckboxClick={handleLaborCheckboxClick}
							handleMaterialCheckboxClick={handleMaterialCheckboxClick}
							handleLaborPriceChange={handleLaborPriceChange}
							handleMaterialPriceChange={handleMaterialPriceChange}
							handleLaborGpmChange={handleLaborGpmChange}
							handleMaterialGpmChange={handleMaterialGpmChange}
							
						/>
					) : ""}
					{(primaryProduct === TRANSITION_ID_DB) ? (
						<PrimaryProductList
							storeId={storeId}
							productList={productListData}
							primaryProductId={primaryProduct}
							primaryProductName={"transition"}
							handleCheckboxClick={handleCheckboxClick}
							selectedIds={selectedSundriesIds}
							selectedData={selectedSundries}
							handlePriceChange={handlePriceChange}
							handleGpmChange={handleGpmChange}
							stateProductFilter={stateProductFilter}
							handleProductFilterChange={handleProductFilterChange}
							handleCheckboxSelect={handleCheckboxSelect}
							handleSecondaryCheckboxChange={handleSecondaryCheckboxChange}
							handleAllPrice={handleAllPrice}
							handleAllGpm={handleAllGpm}
							materialContractModalOpen={materialContractModalOpen}
							laborContractModalOpen={laborContractModalOpen}
							handleQuantityChange={handleQuantityChange}
							handleLaborKitQuantityChange={handleLaborKitQuantityChange}
							handleMaterialKitQuantityChange={handleMaterialKitQuantityChange}
							handlePrimaryProductLineTotalChange={handlePrimaryProductLineTotalChange}
							handlePrimaryProductMaterialKitLineTotalChange={handlePrimaryProductMaterialKitLineTotalChange}
							handlePrimaryProductLaborKitLineTotalChange={handlePrimaryProductLaborKitLineTotalChange}
							productSubStructure={productSubStructure}
							laborList={primaryProductLaborData}
							materialList={primaryProductMaterialData}
							// selectedLaborIds={selectedLaborTransitionIds}
							selectedKitLaborIds={selectedLaborTransitionLaborIds}
							selectedMaterialIds={selectedMaterialTransitionIds}
							handleLaborCheckboxClick={handleLaborCheckboxClick}
							handleMaterialCheckboxClick={handleMaterialCheckboxClick}
							handleLaborPriceChange={handleLaborPriceChange}
							handleMaterialPriceChange={handleMaterialPriceChange}
							handleLaborGpmChange={handleLaborGpmChange}
							handleMaterialGpmChange={handleMaterialGpmChange}
						/>
					) : ""}
					{(showLaborList === true) ? (
						<PrimaryProductList
							storeId={storeId}
							productList={laborListData}
							primaryProductId={primaryProduct}
							primaryProductName={laborContractModalOpen ? 'labor' : 'miscellaneous labor'}
							handleCheckboxClick={handleCheckboxClick}
							selectedIds={selectedLaborIds}
							handlePriceChange={handlePriceChange}
							handleGpmChange={handleGpmChange}
							stateLaborFilter={stateLaborFilter}
							handleLaborFilterChange={handleLaborFilterChange}
							handleAllPrice={handleAllPrice}
							handleAllGpm={handleAllGpm}
							materialContractModalOpen={materialContractModalOpen}
							laborContractModalOpen={laborContractModalOpen}
							handleOrderLaborQuantityChange={handleOrderLaborQuantityChange}
							handlePrimaryProductLineTotalChange={handlePrimaryProductLineTotalChange}
							missingStairsLaborIds={missingStairsLaborIds}
							handleStairsLabor={handleStairsLabor}
						/>
					) : ""}
					{(showSummary === true) ? (
						<PricingContractSummary
							selectedCarpet={selectedCarpet}
							selectedLaborCarpet={selectedLaborCarpet}
							selectedMaterialCarpet={selectedMaterialCarpet}
							selectedSheetVinyl={selectedSheetVinyl}
							selectedLaborSheetVinyl={selectedLaborSheetVinyl}
							selectedMaterialSheetVinyl={selectedMaterialSheetVinyl}
							selectedVinylPlank={selectedVinylPlank}
							selectedLaborVinylPlank={selectedLaborVinylPlank}
							selectedMaterialVinylPlank={selectedMaterialVinylPlank}
							selectedWallbase={selectedWallbase}
							selectedLaborWallbase={selectedLaborWallbase}
							selectedMaterialWallbase={selectedMaterialWallbase}
							selectedSundries={selectedSundries}
							selectedLaborSundries={selectedLaborSundries}
							selectedMaterialSundries={selectedMaterialSundries}
							selectedLabor={selectedLabor}
							materialContractModalOpen={materialContractModalOpen}
							laborContractModalOpen={laborContractModalOpen}
						/>
					) : ""}
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					{(showSummary === false) ? (
						<Button variant="primary" onClick={handleNextClick} disabled={(selectedPrimaryProduct.length > 0 || laborContractModalOpen) ? false : true}>
							{(materialContractModalOpen || laborContractModalOpen) ? 'Submit': 'Next'}
						</Button>
					) : (
						(isContractPricingAdded === true && showSaveOnEdit === false) ? (
							<Button variant="primary" onClick={handleEditClick}>
								Edit
							</Button>
						) : (
							(isCreateLoading === true ? (
								<Button variant="primary">
									<div className="spinner-border"></div>
								</Button>
							) : (
								<Button variant="primary" onClick={handleSaveClick}>
									Save
								</Button>
							))
						)
					)}
					<Button variant="secondary" onClick={handleModalCloseClick}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
			{ priceGpmModalOpen ?  
				<Modal id="contract-confirmation" show={showPriceGpmModal} size="md">
					<Modal.Header closeButton onClick={hidePriceGpmModal}>
						<Modal.Title>Mass Update Price/GPM</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Input 
							label={`Price`} 
							ids={`Price`} 
							className="form-control"
							handleChange={e=>handlePriceGpmChange(e, 'Price')}
							disabled={gpmValue && gpmValue.length>0 ? true : false}
							validate={false}
						/>
						<Input 
							label={`GPM(%)`} 
							ids={'Gpm'} 
							className="form-control"
							handleChange={e=>handlePriceGpmChange(e, 'Gpm')}
							disabled={priceValue && priceValue.length>0 ? true : false}
							validate={false}
						/>
					</Modal.Body>
					<Modal.Footer className="justify-content-center">
						<Button variant="primary" onClick={handlePriceGpmValue}>Save</Button>
						<Button variant="secondary" onClick={hidePriceGpmModal}>Cancel</Button>
					</Modal.Footer>
				</Modal>
				: ""
			}
		</Fragment>
	)
};

const mapStateToProps = ({ auth, ProductType, pricingContract, customerInfo }) => {
	return {
		auth, ProductType, pricingContract, customerInfo
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		fetchProductType: (token) => dispatch({ type: PRODUCT_TYPE_REQUEST, token }),
		fetchProductForPricingContract: (token, listParamState, checkFlag, unitId, jobNum) => dispatch({type: PRICING_CONTRACT_PRODUCT_LIST_REQUEST, token, listParamState, checkFlag, unitId, jobNum }),
		fetchLaborForPricingContract: (token, listParamState, checkFlag, unitId, jobNum) => dispatch({type: PRICING_CONTRACT_LABOR_LIST_REQUEST, token, listParamState, checkFlag, unitId, jobNum }),
		fetchMaterialForPricingContract: (token, listParamState, checkFlag, unitId, jobNum) => dispatch({type: PRICING_CONTRACT_MATERIAL_LIST_REQUEST, token, listParamState, checkFlag, unitId, jobNum }),
		fetchLaborItemFilterItem: (token, listParamState, checkFlag, unitId, jobNum) => dispatch({ type: PRICING_CONTRACT_LABOR_ITEM_LIST_REQUEST, token, listParamState, checkFlag, unitId, jobNum }),
		createPricingContract: (token, data) => dispatch({type: PRICING_CONTRACT_CREATE_REQUEST, token, data }),
		fetchPricingContract: (token, data, checkFlag, unitId, jobNum) => dispatch({ type: PRICING_CONTRACT_DETAILS_REQUEST, token, data, checkFlag, unitId, jobNum }),
		changeCustomerReducerState: () => dispatch({ type: CUSTOMER_STATE_CHANGE }),
		changePricingContractReducerState: () => dispatch({ type: GROUP_PRICING_CONTRACT_STATE_CHANGE }),
		fetchSubProductRequest: (data) => dispatch({ type: GET_SUB_PRODUCT_REQUEST, data }),
		fetchTransitionSubProductRequest: (data) => dispatch({ type: GET_TRANSITION_SUB_PRODUCT_REQUEST, data }),
		fetchTransitionLaborForPricingContract: (token, listParamState, checkFlag) => dispatch({type: PRICING_CONTRACT_TRANSITION_LABOR_LIST_REQUEST, token, listParamState, checkFlag }),
		fetchTransitionMaterialForPricingContract: (token, listParamState, checkFlag) => dispatch({type: PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_REQUEST, token, listParamState, checkFlag }),
		fetchMissingLaborItemsInContract: (token, contactInfoId) => dispatch({ type: PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST, token, contactInfoId }),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(PricingContract);