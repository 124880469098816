import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, Button, InputGroup } from 'react-bootstrap';
// import { encrypted } from '../../../../_utils/helpers';
import { connect } from 'react-redux';
import Create from "./models/Create";
import ConfirmDelete from "./models/ConfirmDelete";
import {
  SHIP_PAYMENT_REQUEST,
  SHIP_PAYMENT_CREATE,
  SHIP_PAYMENT_DELETE,
  SHIP_PAYMENT_EDIT,
  SHIP_PAYMENT_STATE_CHANGE
} from "../../../../_utils/constants/ProductCatalog";
import Loader from "../../../layouts/Loader";
import { toast } from 'react-toastify';

const ShipPayment = ({
  auth: { auth, isAuthenticated },
  ShipPayment: { valueList, isCreated, isDeleted, isEdited, isLoading },
  fetchShipPayment,
  submitShipPaymentCreate,
  submitShipPaymentDelete,
  submitShipPaymentEdit,
  changeReducerState
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [deleteShipPaymentId, setDeleteShipPaymentId] = useState('');
  const [editShipPaymentId, setEditShipPaymentId] = useState('');
  const [editShipPayment, setEditShipPayment] = useState('');

  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchShipPayment(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("ShipPayment Created Successfully.");
      fetchShipPayment(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("ShipPayment Deleted Successfully.");
      fetchShipPayment(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("ShipPayment Data Updated Successfully.");
      fetchShipPayment(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
  };

  // function called for input value change of create modal
  const handleCreateModalChange = (e) => {
    setInputVal(e.target.value);
  }

  // function called for create age demographic create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (inputVal === '') {
      toast.error("ShipPayment name cannot be empty.");
    } else {
      submitShipPaymentCreate({
        "data": {
          "ShipPayment": inputVal,
          "CreatedBy": auth[0].userId
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteShipPaymentId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteShipPaymentId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    submitShipPaymentDelete({
      "ShipPaymentId": deleteShipPaymentId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setEditShipPaymentId(data.shipPaymentId);
    setEditShipPayment(data.shipPayment);
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (editShipPayment === '') {
      toast.error("ShipPayment name cannot be empty.");
    } else {
      submitShipPaymentEdit({
        "data": {
          "ShipPaymentId": data.shipPaymentId,
          "ShipPayment": editShipPayment,
        },
        "token": auth[0].token
      });
      setEditShipPaymentId('');
      setEditShipPayment('');
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">

          <div className="sub-header mt-5 mb-3">
            <h2>ShipPayment</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`ShipPayment`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              inputVal={inputVal}
              handleCreateModalChange={handleCreateModalChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>ShipPayment</th>
                <th width="50">Delete</th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (
                  <tr key={index}>
                    {editShipPaymentId === item.shipPaymentId ? (
                      <td>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control type="text" value={editShipPayment} onChange={(evt) => setEditShipPayment(evt.target.value)} />
                            <Button variant="outline-secondary">
                              <Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
                            </Button>
                          </InputGroup>
                        </Form.Group>
                      </td>
                    ) : (
                      <td>
                        {item.shipPayment}
                        <Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                      </td>
                    )}
                    <td>
                      <ConfirmDelete variant="primary" title={`ShipPayment`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.shipPaymentId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
    </MainWrapper>
  );
}

const mapStateToProps = ({ auth, ShipPayment }) => {
  return {
    auth,
    ShipPayment
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchShipPayment: (token) => dispatch({ type: SHIP_PAYMENT_REQUEST, token }),
    submitShipPaymentCreate: (data) => dispatch({ type: SHIP_PAYMENT_CREATE, payload: data }),
    submitShipPaymentDelete: (data) => dispatch({ type: SHIP_PAYMENT_DELETE, payload: data }),
    submitShipPaymentEdit: (data) => dispatch({ type: SHIP_PAYMENT_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: SHIP_PAYMENT_STATE_CHANGE })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShipPayment);