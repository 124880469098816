import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  CARRIER_REQUEST,
  CARRIER_SUCCESS,
  CARRIER_FAILED,
  CARRIER_CREATE,
  CARRIER_CREATE_SUCCESS,
  CARRIER_CREATE_FAILED,
  CARRIER_DELETE,
  CARRIER_DELETE_SUCCESS,
  CARRIER_DELETE_FAILED,
  CARRIER_EDIT,
  CARRIER_EDIT_SUCCESS,
  CARRIER_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * Carrier Actions
 */

function* fetchCarrier({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/Carrier/GetCarrier`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: CARRIER_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: CARRIER_FAILED, payload: error.response });
    }
  }
}

function* createCarrier(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/Carrier/CreateCarrier`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: CARRIER_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CARRIER_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editCarrier(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/Carrier/UpdateCarrier`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: CARRIER_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CARRIER_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteCarrier(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/Carrier/RemoveCarrier?CarrierId=${encodeURIComponent(action.payload.CarrierId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: CARRIER_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CARRIER_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootCarrierList() {
  yield all([
    takeLatest(CARRIER_REQUEST, fetchCarrier),
    takeLatest(CARRIER_CREATE, createCarrier),
    takeLatest(CARRIER_DELETE, deleteCarrier),
    takeLatest(CARRIER_EDIT, editCarrier),
  ]);
}