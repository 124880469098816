import React, { Fragment, useState, useMemo } from "react";
import { useDropzone } from 'react-dropzone';

const ImportImage = ({
		handleImageFile,
		value
	}) => {
	// states declarations
	const [myFiles, setMyFiles] = useState(value);

	// dropzone initialization function
	const { getRootProps, getInputProps, inputRef } = useDropzone({
		accept: {
			'image/png': ['.png']
		},
		onDrop: acceptedFiles => {
			acceptedFiles.map(file => setMyFiles(myFiles => [Object.assign(file)]))
		}
	});
	// function to remove all uploaded files
	const removeAll = () => {
		setMyFiles([]);
		inputRef.current.value = '';
	}
	useMemo(()=>{
		if(myFiles.length > 0) {
			handleImageFile(myFiles, value);
			removeAll();
		}
	}, [myFiles])
	return(
		<Fragment>
			<div>
				{value?.imagePath ?
					(<div {...getRootProps({ className: 'img' })}>
							<input {...getInputProps()} />
							<img src={value?.imagePath} />
					  </div>)
				 : (<div {...getRootProps({ className: 'dropzone-style img' })}>
							<input {...getInputProps()} />
							<p>Drag &apos;n&apos; drop some files here, or click to select files</p>
							<em>(Only *.png files will be accepted)</em>
				  	</div>
				  )
				}
			</div>
		</Fragment>
	)
}

export default ImportImage;