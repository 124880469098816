import React from "react";
import '../../../assets/css/order.css';
import MainWrapper from "../../MainWrapper";

const Order = () => {
  	return (
    	<MainWrapper>
			Order
		</MainWrapper>
  	);
}
export default Order;