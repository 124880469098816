export const ROLE_LIST_REQUEST = "@ROLE_LIST_REQUEST";
export const ROLE_LIST_SUCCESS = "@ROLE_LIST_SUCCESS";
export const ROLE_LIST_FAILED = "@EROLE_LIST_FAILED";

export const ROLE_CREATE_REQUEST = "@ROLE_CREATE_REQUEST";
export const ROLE_CREATE_SUCCESS = "@ROLE_CREATE_SUCCESS";
export const ROLE_CREATE_FAILED = "@ROLE_CREATE_FAILED";

export const ROLE_EDIT_REQUEST = "@ROLE_EDIT_REQUEST";
export const ROLE_EDIT_SUCCESS = "@ROLE_EDIT_SUCCESS";
export const ROLE_EDIT_FAILED = "@ROLE_EDIT_FAILED";

export const ROLE_DELETE_REQUEST = "@ROLE_DELETE_REQUEST";
export const ROLE_DELETE_SUCCESS = "@ROLE_DELETE_SUCCESS";
export const ROLE_DELETE_FAILED = "@ROLE_DELETE_FAILED";

export const ROLE_DROPDOWN_REQUEST ="ROLE_DROPDOWN_REQUEST";
export const ROLE_DROPDOWN_SUCCESS ="ROLE_DROPDOWN_SUCCESS";
export const ROLE_DROPDOWN_FAILED ="ROLE_DROPDOWN_FAILED";


export const ROLE_STATE_CHANGE = "@ROLE_STATE_CHANGE";
