export const ASSIGNED_ACCESS_REQUEST = "@ASSIGNED_ACCESS_REQUEST";
export const ASSIGNED_ACCESS_SUCCESS = "@ASSIGNED_ACCESS_SUCCESS";
export const ASSIGNED_ACCESS_FAILED = "@ASSIGNED_ACCESS_FAILED";

export const MENU_LIST_REQUEST = "@MENU_LIST_REQUEST";
export const MENU_LIST_SUCCESS = "@MENU_LIST_SUCCESS";
export const MENU_LIST_FAILED = "@MENU_LIST_FAILED";

export const USER_MENU_LIST_REQUEST = "@USER_MENU_LIST_REQUEST";
export const USER_MENU_LIST_SUCCESS = "@USER_MENU_LIST_SUCCESS";
export const USER_MENU_LIST_FAILED = "@USER_MENU_LIST_FAILED";