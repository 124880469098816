import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../config";
import { decrypted } from '../../_utils/helpers';
import { SESSION_EXPIRED } from "../../_utils/constants/Auth";
import {
  AGEDEMOGRAPHIC_FAILED,
  AGEDEMOGRAPHIC_SUCCESS,
  AGEDEMOGRAPHIC_REQUEST,
  AGEDEMOGRAPHIC_CREATE,
  AGEDEMOGRAPHIC_CREATE_SUCCESS,
  AGEDEMOGRAPHIC_CREATE_FAILED,
  AGEDEMOGRAPHIC_DELETE,
  AGEDEMOGRAPHIC_DELETE_SUCCESS,
  AGEDEMOGRAPHIC_DELETE_FAILED,
  AGEDEMOGRAPHIC_EDIT,
  AGEDEMOGRAPHIC_EDIT_SUCCESS,
  AGEDEMOGRAPHIC_EDIT_FAILED,
  ALTERNATE_NAMES_REQUEST,
  ALTERNATE_NAMES_SUCCESS,
  ALTERNATE_NAMES_FAILED,
  ALTERNATE_NAMES_CREATE,
  ALTERNATE_NAMES_CREATE_SUCCESS,
  ALTERNATE_NAMES_CREATE_FAILED,
  ALTERNATE_NAMES_DELETE,
  ALTERNATE_NAMES_DELETE_SUCCESS,
  ALTERNATE_NAMES_DELETE_FAILED,
  ALTERNATE_NAMES_EDIT,
  ALTERNATE_NAMES_EDIT_SUCCESS,
  ALTERNATE_NAMES_EDIT_FAILED,
  NOTETEMPLATES_REQUEST,
  NOTETEMPLATES_SUCCESS,
  NOTETEMPLATES_FAILED,
  SHIPPINGMETHODS_REQUEST,
  SHIPPINGMETHODS_SUCCESS,
  SHIPPINGMETHODS_FAILED,
  SHIPPINGMETHODS_CREATE,
  SHIPPINGMETHODS_CREATE_SUCCESS,
  SHIPPINGMETHODS_CREATE_FAILED,
  SHIPPINGMETHODS_DELETE,
  SHIPPINGMETHODS_DELETE_SUCCESS,
  SHIPPINGMETHODS_DELETE_FAILED,
  SHIPPINGMETHODS_EDIT,
  SHIPPINGMETHODS_EDIT_SUCCESS,
  SHIPPINGMETHODS_EDIT_FAILED,
  NOTETEMPLATES_CREATE_REQUEST,
  NOTETEMPLATES_CREATE_SUCCESS,
  NOTETEMPLATES_CREATE_FAILED,
  NOTETEMPLATES_DELETE,
  NOTETEMPLATES_DELETE_SUCCESS,
  NOTETEMPLATES_DELETE_FAILED,
  NOTETEMPLATES_EDIT,
  NOTETEMPLATES_EDIT_SUCCESS,
  NOTETEMPLATES_EDIT_FAILED,
  JOBTYPES_REQUEST,
  JOBTYPES_SUCCESS,
  JOBTYPES_FAILED,
  JOBTYPES_CREATE,
  JOBTYPES_CREATE_SUCCESS,
  JOBTYPES_CREATE_FAILED,
  JOBTYPES_DELETE,
  JOBTYPES_DELETE_SUCCESS,
  JOBTYPES_DELETE_FAILED,
  JOBTYPES_EDIT,
  JOBTYPES_EDIT_SUCCESS,
  JOBTYPES_EDIT_FAILED,
  FORMTEMPLATE_REQUEST,
  FORMTEMPLATE_SUCCESS,
  FORMTEMPLATE_FAILED,
  FORMTEMPLATE_CREATE,
  FORMTEMPLATE_CREATE_SUCCESS,
  FORMTEMPLATE_CREATE_FAILED,
  FORMTEMPLATE_DELETE,
  FORMTEMPLATE_DELETE_SUCCESS,
  FORMTEMPLATE_DELETE_FAILED,
  FORMTEMPLATE_EDIT,
  FORMTEMPLATE_EDIT_SUCCESS,
  FORMTEMPLATE_EDIT_FAILED,
  SALESSTAGE_REQUEST,
  SALESSTAGE_SUCCESS,
  SALESSTAGE_FAILED,
  SALESSTAGE_CREATE,
  SALESSTAGE_CREATE_SUCCESS,
  SALESSTAGE_CREATE_FAILED,
  SALESSTAGE_DELETE,
  SALESSTAGE_DELETE_SUCCESS,
  SALESSTAGE_DELETE_FAILED,
  SALESSTAGE_EDIT,
  SALESSTAGE_EDIT_SUCCESS,
  SALESSTAGE_EDIT_FAILED,
  REGION_REQUEST,
  REGION_SUCCESS,
  REGION_FAILED,
  REGION_CREATE,
  REGION_CREATE_SUCCESS,
  REGION_CREATE_FAILED,
  REGION_DELETE,
  REGION_DELETE_SUCCESS,
  REGION_DELETE_FAILED,
  REGION_EDIT,
  REGION_EDIT_SUCCESS,
  REGION_EDIT_FAILED,
  PRODUCTINTEREST_REQUEST,
  PRODUCTINTEREST_SUCCESS,
  PRODUCTINTEREST_FAILED,
  PRODUCTINTEREST_CREATE,
  PRODUCTINTEREST_CREATE_SUCCESS,
  PRODUCTINTEREST_CREATE_FAILED,
  PRODUCTINTEREST_DELETE,
  PRODUCTINTEREST_DELETE_SUCCESS,
  PRODUCTINTEREST_DELETE_FAILED,
  PRODUCTINTEREST_EDIT,
  PRODUCTINTEREST_EDIT_SUCCESS,
  PRODUCTINTEREST_EDIT_FAILED,
  CASHBACK_REQUEST,
  CASHBACK_SUCCESS,
  CASHBACK_FAILED,
  CASHBACK_CREATE,
  CASHBACK_CREATE_SUCCESS,
  CASHBACK_CREATE_FAILED,
  CASHBACK_DELETE,
  CASHBACK_DELETE_SUCCESS,
  CASHBACK_DELETE_FAILED,
  CASHBACK_EDIT,
  CASHBACK_EDIT_SUCCESS,
  CASHBACK_EDIT_FAILED,
  AREA_REQUEST,
  AREA_SUCCESS,
  AREA_FAILED,
  AREA_CREATE_REQUEST,
  AREA_CREATE_SUCCESS,
  AREA_CREATE_FAILED,
  AREA_EDIT_REQUEST,
  AREA_EDIT_SUCCESS,
  AREA_EDIT_FAILED,
  AREA_DELETE_REQUEST,
  AREA_DELETE_SUCCESS,
  AREA_DELETE_FAILED,
  BIN_LOCATION_REQUEST,
  BIN_LOCATION_SUCCESS,
  BIN_LOCATION_FAILED,
  BIN_LOCATION_CREATE,
  BIN_LOCATION_CREATE_SUCCESS,
  BIN_LOCATION_CREATE_FAILED,
  BIN_LOCATION_DELETE,
  BIN_LOCATION_DELETE_SUCCESS,
  BIN_LOCATION_DELETE_FAILED,
  BIN_LOCATION_EDIT,
  BIN_LOCATION_EDIT_SUCCESS,
  BIN_LOCATION_EDIT_FAILED,
  COMPETITORS_REQUEST,
  COMPETITORS_SUCCESS,
  COMPETITORS_FAILED,
  COMPETITORS_CREATE,
  COMPETITORS_CREATE_SUCCESS,
  COMPETITORS_CREATE_FAILED,
  COMPETITORS_DELETE,
  COMPETITORS_DELETE_SUCCESS,
  COMPETITORS_DELETE_FAILED,
  COMPETITORS_EDIT,
  COMPETITORS_EDIT_SUCCESS,
  COMPETITORS_EDIT_FAILED,
  FORM_FAILED,
  FORM_SUCCESS,
  FORM_REQUEST,
  FORM_CREATE,
  FORM_CREATE_SUCCESS,
  FORM_CREATE_FAILED,
  FORM_DELETE,
  FORM_DELETE_SUCCESS,
  FORM_DELETE_FAILED,
  FORM_EDIT,
  FORM_EDIT_SUCCESS,
  FORM_EDIT_FAILED,
  IDSTATUS_REQUEST,
  IDSTATUS_SUCCESS,
  IDSTATUS_FAILED,
  IDSTATUS_CREATE,
  IDSTATUS_CREATE_SUCCESS,
  IDSTATUS_CREATE_FAILED,
  IDSTATUS_DELETE,
  IDSTATUS_DELETE_SUCCESS,
  IDSTATUS_DELETE_FAILED,
  IDSTATUS_EDIT,
  IDSTATUS_EDIT_SUCCESS,
  IDSTATUS_EDIT_FAILED,
  LOST_REQUEST,
  LOST_SUCCESS,
  LOST_FAILED,
  LOST_CREATE,
  LOST_CREATE_SUCCESS,
  LOST_CREATE_FAILED,
  LOST_DELETE,
  LOST_DELETE_SUCCESS,
  LOST_DELETE_FAILED,
  LOST_EDIT,
  LOST_EDIT_SUCCESS,
  LOST_EDIT_FAILED,
  WON_REQUEST,
  WON_SUCCESS,
  WON_FAILED,
  WON_CREATE,
  WON_CREATE_SUCCESS,
  WON_CREATE_FAILED,
  WON_DELETE,
  WON_DELETE_SUCCESS,
  WON_DELETE_FAILED,
  WON_EDIT,
  WON_EDIT_SUCCESS,
  WON_EDIT_FAILED,
  SICCODE_REQUEST,
  SICCODE_SUCCESS,
  SICCODE_FAILED,
  SICCODE_CREATE,
  SICCODE_CREATE_SUCCESS,
  SICCODE_CREATE_FAILED,
  SICCODE_DELETE,
  SICCODE_DELETE_SUCCESS,
  SICCODE_DELETE_FAILED,
  SICCODE_EDIT,
  SICCODE_EDIT_SUCCESS,
  SICCODE_EDIT_FAILED,
  MARKET_SECTOR_REQUEST,
  MARKET_SECTOR_SUCCESS,
  MARKET_SECTOR_FAILED,
  MARKET_SECTOR_CREATE,
  MARKET_SECTOR_CREATE_SUCCESS,
  MARKET_SECTOR_CREATE_FAILED,
  MARKET_SECTOR_DELETE,
  MARKET_SECTOR_DELETE_SUCCESS,
  MARKET_SECTOR_DELETE_FAILED,
  MARKET_SECTOR_EDIT,
  MARKET_SECTOR_EDIT_SUCCESS,
  MARKET_SECTOR_EDIT_FAILED,
  BUYING_GROUP_REQUEST,
  BUYING_GROUP_SUCCESS,
  BUYING_GROUP_FAILED,
  BUYING_GROUP_CREATE,
  BUYING_GROUP_CREATE_SUCCESS,
  BUYING_GROUP_CREATE_FAILED,
  BUYING_GROUP_DELETE,
  BUYING_GROUP_DELETE_SUCCESS,
  BUYING_GROUP_DELETE_FAILED,
  BUYING_GROUP_EDIT,
  BUYING_GROUP_EDIT_SUCCESS,
  BUYING_GROUP_EDIT_FAILED,
  WAREHOUSE_REQUEST,
  WAREHOUSE_SUCCESS,
  WAREHOUSE_FAILED,
  WAREHOUSE_CREATE,
  WAREHOUSE_CREATE_SUCCESS,
  WAREHOUSE_CREATE_FAILED,
  WAREHOUSE_DELETE,
  WAREHOUSE_DELETE_SUCCESS,
  WAREHOUSE_DELETE_FAILED,
  WAREHOUSE_EDIT,
  WAREHOUSE_EDIT_SUCCESS,
  WAREHOUSE_EDIT_FAILED,
  VENDOR_TYPE_REQUEST,
  VENDOR_TYPE_SUCCESS,
  VENDOR_TYPE_FAILED,
  VENDOR_TYPE_CREATE,
  VENDOR_TYPE_CREATE_SUCCESS,
  VENDOR_TYPE_CREATE_FAILED,
  VENDOR_TYPE_DELETE,
  VENDOR_TYPE_DELETE_SUCCESS,
  VENDOR_TYPE_DELETE_FAILED,
  VENDOR_TYPE_EDIT,
  VENDOR_TYPE_EDIT_SUCCESS,
  VENDOR_TYPE_EDIT_FAILED,
  MARKETING_REQUEST,
  MARKETING_SUCCESS,
  MARKETING_FAILED,
  MARKETING_CREATE,
  MARKETING_CREATE_SUCCESS,
  MARKETING_CREATE_FAILED,
  MARKETING_DELETE,
  MARKETING_DELETE_SUCCESS,
  MARKETING_DELETE_FAILED,
  MARKETING_EDIT,
  MARKETING_EDIT_SUCCESS,
  MARKETING_EDIT_FAILED,
  VENDOR_REQUEST,
  VENDOR_SUCCESS,
  VENDOR_FAILED,
  VENDOR_CREATE,
  VENDOR_CREATE_SUCCESS,
  VENDOR_CREATE_FAILED,
  VENDOR_DELETE,
  VENDOR_DELETE_SUCCESS,
  VENDOR_DELETE_FAILED,
  VENDOR_EDIT,
  VENDOR_EDIT_SUCCESS,
  VENDOR_EDIT_FAILED,
} from "../../_utils/constants/ValueList";

function* fetchArea({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/Area/GetAllArea`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: AREA_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: AREA_FAILED, payload: error.response });
    }
  }
}

function* fetchAgeDemographic({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/AgeDemographic/GetAgeDemographics`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: AGEDEMOGRAPHIC_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: AGEDEMOGRAPHIC_FAILED, payload: error.response });
    }
  }
}
function* fetchAlternateNames({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/AlternativeName/GetAlternativeName`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: ALTERNATE_NAMES_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: ALTERNATE_NAMES_FAILED, payload: error.response });
    }
  }
}
function* fetchNoteTemplates({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/valuelist/api/itemnotetemplate/getallitemnotetemplate`, {
      headers: {
        Authorization: `Bearer ${token}`, "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": `${API_URL}`,
        "Access-Control-Allow-Methods": "OPTIONS,GET"
      }
    });

    yield put({ type: NOTETEMPLATES_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: NOTETEMPLATES_FAILED, payload: error.response });
    }
  }
}
function* fetchShippingMethods({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/ShippingMethod/GetShippingMethod`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: SHIPPINGMETHODS_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: SHIPPINGMETHODS_FAILED, payload: error.response });
    }
  }
}
function* fetchJobTypes({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/JobType/GetAllJobType`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: JOBTYPES_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: JOBTYPES_FAILED, payload: error.response });
    }
  }
}
function* fetchFormTemplates({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/FormTemplate/GetAllFormTemplate`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: FORMTEMPLATE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: FORMTEMPLATE_FAILED, payload: error.response });
    }
  }
}
function* fetchSalesStage({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/SalesStage/GetAllSalesStage`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: SALESSTAGE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: SALESSTAGE_FAILED, payload: error.response });
    }
  }
}
function* fetchRegion({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/Region/GetAllRegion`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: REGION_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: REGION_FAILED, payload: error.response });
    }
  }
}
function* fetchProductInterest({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/ProductInterest/GetAllProductInterest`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: PRODUCTINTEREST_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PRODUCTINTEREST_FAILED, payload: error.response });
    }
  }
}
function* fetchCashback({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/Cashback/GetAllCashback`, {
      headers: {
        Authorization: `Bearer ${token}`, "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": `${API_URL}`,
        "Access-Control-Allow-Methods": "OPTIONS,GET"
      }
    });
    yield put({ type: CASHBACK_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: CASHBACK_FAILED, payload: error.response });
    }
  }
}

function* createArea(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Area/CreateArea`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: AREA_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: AREA_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}


function* createAgeDemographic(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/AgeDemographic/CreateAgeDemographics`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: AGEDEMOGRAPHIC_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: AGEDEMOGRAPHIC_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createAlternateNames(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/AlternativeName/CreateAlternativeName`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: ALTERNATE_NAMES_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: ALTERNATE_NAMES_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createNotetemplate(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/valuelist/api/itemnotetemplate/createitemnotetemplate`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`,
        "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": `${API_URL}`,
        "Access-Control-Allow-Methods": "OPTIONS,POST"
      },
    }
    );
    yield put({
      type: NOTETEMPLATES_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: NOTETEMPLATES_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createShippingMethod(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/ShippingMethod/CreateShippingMethod`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SHIPPINGMETHODS_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SHIPPINGMETHODS_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createJobType(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/JobType/CreateJobType`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: JOBTYPES_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: JOBTYPES_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createFormTemplate(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/FormTemplate/CreateFormTemplate`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: FORMTEMPLATE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: FORMTEMPLATE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createSalesStage(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/SalesStage/CreateSalesStage`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SALESSTAGE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SALESSTAGE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createRegion(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Region/CreateRegion`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: REGION_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: REGION_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createProductInterest(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/ProductInterest/CreateProductInterest`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCTINTEREST_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCTINTEREST_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createCashback(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Cashback/CreateCashback`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: CASHBACK_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CASHBACK_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}

function* editAlternateNames(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/AlternativeName/UpdateAlternativeName`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: ALTERNATE_NAMES_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: ALTERNATE_NAMES_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}

function* editAgeDemographic(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/AgeDemographic/UpdateAgeDemographics`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: AGEDEMOGRAPHIC_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: AGEDEMOGRAPHIC_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* editShippingMethod(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/ShippingMethod/UpdateShippingMethod`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SHIPPINGMETHODS_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SHIPPINGMETHODS_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* editNoteTemplate(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/valuelist/api/itemnotetemplate/updateitemnotetemplate`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: NOTETEMPLATES_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: NOTETEMPLATES_EDIT_FAILED,
        payload: decrypted(error.response.data.body)
      });
    }
  }
}
function* editJobType(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/ValueList/api/JobType/UpdateJobType`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: JOBTYPES_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: JOBTYPES_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* editFormTemplate(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/FormTemplate/UpdateFormTemplate`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: FORMTEMPLATE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: FORMTEMPLATE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* editSalesStage(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/SalesStage/UpdateSalesStage`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SALESSTAGE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SALESSTAGE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* editRegion(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Region/UpdateRegion`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: REGION_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: REGION_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* editProductInterest(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/ProductInterest/UpdateProductInterest`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCTINTEREST_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCTINTEREST_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* editCashback(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Cashback/UpdateCashback`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: CASHBACK_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CASHBACK_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* editArea(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Area/UpdateArea`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: AREA_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: AREA_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteAgeDemographic(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/AgeDemographic/RemoveAgeDemographics?AgeDemographicsId=${encodeURIComponent(action.payload.ageDemographicsId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: AGEDEMOGRAPHIC_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: AGEDEMOGRAPHIC_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteAlternateNames(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/AlternativeName/RemoveAlternativeName?AlternativeNamesId=${encodeURIComponent(action.payload.AlternativeNamesId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: ALTERNATE_NAMES_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: ALTERNATE_NAMES_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteArea(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Area/RemoveArea?AreaId=${encodeURIComponent(action.payload.AreaId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: AREA_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: AREA_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteShippingMethod(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/ShippingMethod/RemoveShippingMethod?MethodId=${encodeURIComponent(action.payload.methodId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: SHIPPINGMETHODS_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SHIPPINGMETHODS_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteNoteTemplate(action) {

  try {
    const response = yield call(
      axios.post,
      `${API_URL}/valuelist/api/itemnotetemplate/RemoveItemNoteTemplate?ItemNoteId=${encodeURIComponent(action.payload.itemNoteId)}`, '', {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );

    yield put({
      type: NOTETEMPLATES_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: NOTETEMPLATES_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteJobTypes(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/JobType/RemoveJobType?JobTypeId=${encodeURIComponent(action.payload.jobTypeId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: JOBTYPES_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: JOBTYPES_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteFormTemplate(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/FormTemplate/RemoveFormTemplate?FormTemplateId=${encodeURIComponent(action.payload.formTemplateId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: FORMTEMPLATE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: FORMTEMPLATE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteSalesStage(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/SalesStage/RemoveSalesStage?SalesStageId=${encodeURIComponent(action.payload.salesStageId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: SALESSTAGE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SALESSTAGE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteRegion(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Region/RemoveRegion?RegionId=${encodeURIComponent(action.payload.regionId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: REGION_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: REGION_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteProductInterest(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/ProductInterest/RemoveProductInterest?ProductInterestId=${encodeURIComponent(action.payload.productInterestId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: PRODUCTINTEREST_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCTINTEREST_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteCashback(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Cashback/RemoveCashback?cashbackId=${encodeURIComponent(action.payload.cashbackId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: CASHBACK_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CASHBACK_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

/*****
 * Bin Location Actions
 */

function* fetchBinLocation({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/BinLocation/GetAllBinLocation`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: BIN_LOCATION_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: BIN_LOCATION_FAILED, payload: error.response });
    }
  }
}

function* createBinLocation(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/BinLocation/CreateBinLocation`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: BIN_LOCATION_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: BIN_LOCATION_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editBinLocation(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/BinLocation/UpdateBinLocation`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: BIN_LOCATION_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: BIN_LOCATION_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteBinLocation(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/BinLocation/RemoveBinLocation?BinLocationId=${encodeURIComponent(action.payload.BinLocationId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: BIN_LOCATION_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: BIN_LOCATION_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}


/*****
 * Competitors Actions
 */

function* fetchCompetitors({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/Competitor/GetAllCompetitor`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: COMPETITORS_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: COMPETITORS_FAILED, payload: error.response });
    }
  }
}

function* createCompetitors(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Competitor/CreateCompetitor`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: COMPETITORS_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: COMPETITORS_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editCompetitors(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Competitor/UpdateCompetitor`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: COMPETITORS_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: COMPETITORS_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteCompetitors(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Competitor/RemoveCompetitor?CompetitorId=${encodeURIComponent(action.payload.CompetitorId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: COMPETITORS_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: COMPETITORS_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}


/*****
 * Form Actions
 */

function* fetchForm({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/Form/GetAllForm`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: FORM_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: FORM_FAILED, payload: error.response });
    }
  }
}

function* createForm(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Form/CreateForm`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: FORM_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: FORM_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editForm(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Form/UpdateForm`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: FORM_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: FORM_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteForm(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Form/RemoveForm?FormId=${encodeURIComponent(action.payload.FormId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: FORM_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: FORM_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

/*****
 * Id Status Actions
 */

function* fetchIdStatus({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/IdStatus/GetAllIdStatus`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: IDSTATUS_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: IDSTATUS_FAILED, payload: error.response });
    }
  }
}

function* createIdStatus(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/IdStatus/CreateIdStatus`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: IDSTATUS_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: IDSTATUS_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editIdStatus(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/IdStatus/UpdateIdStatus`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: IDSTATUS_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: IDSTATUS_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteIdStatus(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/IdStatus/RemoveIdStatus?StatusId=${encodeURIComponent(action.payload.StatusId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: IDSTATUS_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: IDSTATUS_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

/*****
 * Lost Actions
 */

function* fetchLost({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/Lost/GetAllLost`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: LOST_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: LOST_FAILED, payload: error.response });
    }
  }
}

function* createLost(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Lost/CreateLost`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: LOST_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LOST_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editLost(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Lost/UpdateLost`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: LOST_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LOST_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteLost(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Lost/RemoveLost?LostId=${encodeURIComponent(action.payload.LostId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: LOST_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LOST_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

/*****
 * Won Actions
 */

function* fetchWon({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/Won/GetAllWon`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: WON_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: WON_FAILED, payload: error.response });
    }
  }
}

function* createWon(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Won/CreateWon`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: WON_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: WON_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editWon(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Won/UpdateWon`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: WON_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: WON_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteWon(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Won/RemoveWon?ReasonWonId=${encodeURIComponent(action.payload.ReasonWonId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: WON_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: WON_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

/*****
 * SicCode Actions
 */

function* fetchSicCode({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/SicCode/GetAllSicCode`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: SICCODE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: SICCODE_FAILED, payload: error.response });
    }
  }
}

function* createSicCode(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/SicCode/CreateSicCode`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SICCODE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SICCODE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editSicCode(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/SicCode/UpdateSicCode`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SICCODE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SICCODE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteSicCode(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/SicCode/RemoveSicCode?SicCodesId=${encodeURIComponent(action.payload.SicCodesId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: SICCODE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SICCODE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

/*****
 * MarketSector Actions
 */

function* fetchMarketSector({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/MarketSector/GetAllMarketSector`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: MARKET_SECTOR_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: MARKET_SECTOR_FAILED, payload: error.response });
    }
  }
}

function* createMarketSector(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/MarketSector/CreateMarketSector`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: MARKET_SECTOR_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: MARKET_SECTOR_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editMarketSector(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/MarketSector/UpdateMarketSector`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: MARKET_SECTOR_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: MARKET_SECTOR_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteMarketSector(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/MarketSector/RemoveMarketSector?MarketSectorId=${encodeURIComponent(action.payload.MarketSectorId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: MARKET_SECTOR_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: MARKET_SECTOR_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
/*****
 * BuyingGroup Actions
 */

function* fetchBuyingGroup({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/BuyingGroup/GetAllBuyingGroup`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: BUYING_GROUP_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: BUYING_GROUP_FAILED, payload: error.response });
    }
  }
}

function* createBuyingGroup(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/BuyingGroup/CreateBuyingGroup`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: BUYING_GROUP_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: BUYING_GROUP_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editBuyingGroup(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/BuyingGroup/UpdateBuyingGroup`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: BUYING_GROUP_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: BUYING_GROUP_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteBuyingGroup(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/BuyingGroup/RemoveBuyingGroup?BuyingGroupId=${encodeURIComponent(action.payload.BuyingGroupId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: BUYING_GROUP_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: BUYING_GROUP_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
/*****
 * Warehouse Actions
 */

function* fetchWarehouse({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/Warehouse/GetWarehouse`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: WAREHOUSE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: WAREHOUSE_FAILED, payload: error.response });
    }
  }
}

function* createWarehouse(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Warehouse/CreateWarehouse`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: WAREHOUSE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: WAREHOUSE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editWarehouse(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Warehouse/UpdateWarehouse`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: WAREHOUSE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: WAREHOUSE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteWarehouse(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Warehouse/RemoveWarehouse?WarehouseId=${encodeURIComponent(action.payload.WarehouseId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: WAREHOUSE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: WAREHOUSE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
/*****
 * Vender Type Actions
 */

function* fetchVendorType({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/VendorType/GetVendorType`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: VENDOR_TYPE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: VENDOR_TYPE_FAILED, payload: error.response });
    }
  }
}

function* createVendorType(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/VendorType/CreateVendorType`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: VENDOR_TYPE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: VENDOR_TYPE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editVendorType(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/VendorType/UpdateVendorType`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: VENDOR_TYPE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: VENDOR_TYPE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteVendorType(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/VendorType/RemoveVendorType?VendorTypeId=${encodeURIComponent(action.payload.VendorTypeId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: VENDOR_TYPE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: VENDOR_TYPE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
/*****
 * Marketings Actions
 */

function* fetchMarketing({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/Marketing/GetAllMarketing`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: MARKETING_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: MARKETING_FAILED, payload: error.response });
    }
  }
}

function* createMarketing(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Marketing/CreateMarketing`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: MARKETING_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: MARKETING_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editMarketing(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Marketing/UpdateMarketing`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: MARKETING_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: MARKETING_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteMarketing(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Marketing/RemoveMarketing?MarketingId=${encodeURIComponent(action.payload.MarketingId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: MARKETING_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: MARKETING_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

/*****
 * Vendor Actions
 */

function* fetchVendor({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Vendor/api/Vendor/GetAllVendor`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: VENDOR_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: VENDOR_FAILED, payload: error.response });
    }
  }
}

function* createVendor(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Vendor/CreateVendor`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: VENDOR_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: VENDOR_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editVendor(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/Vendor/UpdateVendor`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: VENDOR_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: VENDOR_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteVendor(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/Vendor/RemoveVendor?VendorId=${encodeURIComponent(action.payload.VendorId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: VENDOR_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: VENDOR_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
export default function* rootValueList() {
  yield all([
    takeLatest(AGEDEMOGRAPHIC_REQUEST, fetchAgeDemographic),
    takeLatest(AGEDEMOGRAPHIC_CREATE, createAgeDemographic),
    takeLatest(ALTERNATE_NAMES_CREATE, createAlternateNames),
    takeLatest(NOTETEMPLATES_CREATE_REQUEST, createNotetemplate),
    takeLatest(AGEDEMOGRAPHIC_DELETE, deleteAgeDemographic),
    takeLatest(AGEDEMOGRAPHIC_EDIT, editAgeDemographic),
    takeLatest(ALTERNATE_NAMES_DELETE, deleteAlternateNames),
    takeLatest(ALTERNATE_NAMES_EDIT, editAlternateNames),
    takeLatest(ALTERNATE_NAMES_REQUEST, fetchAlternateNames),
    takeLatest(NOTETEMPLATES_REQUEST, fetchNoteTemplates),
    takeLatest(SHIPPINGMETHODS_REQUEST, fetchShippingMethods),
    takeLatest(SHIPPINGMETHODS_CREATE, createShippingMethod),
    takeLatest(SHIPPINGMETHODS_DELETE, deleteShippingMethod),
    takeLatest(SHIPPINGMETHODS_EDIT, editShippingMethod),
    takeLatest(NOTETEMPLATES_DELETE, deleteNoteTemplate),
    takeLatest(NOTETEMPLATES_EDIT, editNoteTemplate),
    takeLatest(JOBTYPES_REQUEST, fetchJobTypes),
    takeLatest(JOBTYPES_CREATE, createJobType),
    takeLatest(JOBTYPES_DELETE, deleteJobTypes),
    takeLatest(JOBTYPES_EDIT, editJobType),
    takeLatest(FORMTEMPLATE_REQUEST, fetchFormTemplates),
    takeLatest(FORMTEMPLATE_CREATE, createFormTemplate),
    takeLatest(FORMTEMPLATE_DELETE, deleteFormTemplate),
    takeLatest(FORMTEMPLATE_EDIT, editFormTemplate),
    takeLatest(SALESSTAGE_REQUEST, fetchSalesStage),
    takeLatest(SALESSTAGE_CREATE, createSalesStage),
    takeLatest(SALESSTAGE_DELETE, deleteSalesStage),
    takeLatest(SALESSTAGE_EDIT, editSalesStage),
    takeLatest(REGION_REQUEST, fetchRegion),
    takeLatest(REGION_CREATE, createRegion),
    takeLatest(REGION_DELETE, deleteRegion),
    takeLatest(REGION_EDIT, editRegion),
    takeLatest(PRODUCTINTEREST_REQUEST, fetchProductInterest),
    takeLatest(PRODUCTINTEREST_CREATE, createProductInterest),
    takeLatest(PRODUCTINTEREST_DELETE, deleteProductInterest),
    takeLatest(PRODUCTINTEREST_EDIT, editProductInterest),
    takeLatest(CASHBACK_REQUEST, fetchCashback),
    takeLatest(CASHBACK_CREATE, createCashback),
    takeLatest(CASHBACK_DELETE, deleteCashback),
    takeLatest(CASHBACK_EDIT, editCashback),
    takeLatest(AREA_REQUEST, fetchArea),
    takeLatest(AREA_CREATE_REQUEST, createArea),
    takeLatest(AREA_DELETE_REQUEST, deleteArea),
    takeLatest(AREA_EDIT_REQUEST, editArea),
    takeLatest(BIN_LOCATION_REQUEST, fetchBinLocation),
    takeLatest(BIN_LOCATION_CREATE, createBinLocation),
    takeLatest(BIN_LOCATION_DELETE, deleteBinLocation),
    takeLatest(BIN_LOCATION_EDIT, editBinLocation),
    takeLatest(COMPETITORS_REQUEST, fetchCompetitors),
    takeLatest(COMPETITORS_CREATE, createCompetitors),
    takeLatest(COMPETITORS_DELETE, deleteCompetitors),
    takeLatest(COMPETITORS_EDIT, editCompetitors),
    takeLatest(FORM_REQUEST, fetchForm),
    takeLatest(FORM_CREATE, createForm),
    takeLatest(FORM_DELETE, deleteForm),
    takeLatest(FORM_EDIT, editForm),
    takeLatest(IDSTATUS_REQUEST, fetchIdStatus),
    takeLatest(IDSTATUS_CREATE, createIdStatus),
    takeLatest(IDSTATUS_DELETE, deleteIdStatus),
    takeLatest(IDSTATUS_EDIT, editIdStatus),
    takeLatest(LOST_REQUEST, fetchLost),
    takeLatest(LOST_CREATE, createLost),
    takeLatest(LOST_DELETE, deleteLost),
    takeLatest(LOST_EDIT, editLost),
    takeLatest(WON_REQUEST, fetchWon),
    takeLatest(WON_CREATE, createWon),
    takeLatest(WON_DELETE, deleteWon),
    takeLatest(WON_EDIT, editWon),
    takeLatest(SICCODE_REQUEST, fetchSicCode),
    takeLatest(SICCODE_CREATE, createSicCode),
    takeLatest(SICCODE_DELETE, deleteSicCode),
    takeLatest(SICCODE_EDIT, editSicCode),
    takeLatest(MARKET_SECTOR_REQUEST, fetchMarketSector),
    takeLatest(MARKET_SECTOR_CREATE, createMarketSector),
    takeLatest(MARKET_SECTOR_DELETE, deleteMarketSector),
    takeLatest(MARKET_SECTOR_EDIT, editMarketSector),
    takeLatest(BUYING_GROUP_REQUEST, fetchBuyingGroup),
    takeLatest(BUYING_GROUP_CREATE, createBuyingGroup),
    takeLatest(BUYING_GROUP_DELETE, deleteBuyingGroup),
    takeLatest(BUYING_GROUP_EDIT, editBuyingGroup),
    takeLatest(WAREHOUSE_REQUEST, fetchWarehouse),
    takeLatest(WAREHOUSE_CREATE, createWarehouse),
    takeLatest(WAREHOUSE_DELETE, deleteWarehouse),
    takeLatest(WAREHOUSE_EDIT, editWarehouse),
    takeLatest(VENDOR_TYPE_REQUEST, fetchVendorType),
    takeLatest(VENDOR_TYPE_CREATE, createVendorType),
    takeLatest(VENDOR_TYPE_DELETE, deleteVendorType),
    takeLatest(VENDOR_TYPE_EDIT, editVendorType),
    takeLatest(MARKETING_REQUEST, fetchMarketing),
    takeLatest(MARKETING_CREATE, createMarketing),
    takeLatest(MARKETING_DELETE, deleteMarketing),
    takeLatest(MARKETING_EDIT, editMarketing),
    takeLatest(VENDOR_REQUEST, fetchVendor),
    takeLatest(VENDOR_CREATE, createVendor),
    takeLatest(VENDOR_DELETE, deleteVendor),
    takeLatest(VENDOR_EDIT, editVendor),
  ]);
}