import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';

const EditProductPriceDetail = ({
  title,
  show,
  hideCreateModal,
  handleEditSaveClick,
  handleChange,
  state,
  ProductPrice
}) => {

  return (
    <Fragment>

      <Modal show={show} size="xl">
        <Modal.Header>Edit {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="productPriceId">
                  <Form.Label>ProductPrice</Form.Label>
                  <Form.Select aria-label="productPriceId" name="productPriceId" onChange={handleChange} value={state.productPriceId}>
                    <option>Select ProductPrice</option>
                    {ProductPrice.map((u, i) => (
                      <option value={u.productPriceId} key={i}>{u.priceType}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="startCost">
                  <Form.Label>StartCost</Form.Label>
                  <Form.Control type="text" name="startCost" onChange={handleChange} value={state.startCost} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="finish">
                  <Form.Label>Finish</Form.Label>
                  <Form.Control type="text" name="finish" onChange={handleChange} value={state.finish} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="cost">
                  <Form.Label>Cost</Form.Label>
                  <Form.Control type="text" name="cost" onChange={handleChange} value={state.cost} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="lastCost">
                  <Form.Label>LastCost</Form.Label>
                  <Form.Control type="text" name="lastCost" onChange={handleChange} value={state.lastCost} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="futureCost">
                  <Form.Label>FutureCost</Form.Label>
                  <Form.Control type="text" name="futureCost" onChange={handleChange} value={state.futureCost} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="futureDate">
                  <Form.Label>FutureDate</Form.Label>
                  <Form.Control type="date" name="futureDate" onChange={handleChange} value={state.futureDate} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="loads">
                  <Form.Label>Loads</Form.Label>
                  <Form.Control type="text" name="loads" onChange={handleChange} value={state.loads} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="promoBegin">
                  <Form.Label>PromoBegin</Form.Label>
                  <Form.Control type="text" name="promoBegin" onChange={handleChange} value={state.promoBegin} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="promotionCost">
                  <Form.Label>PromotionCost</Form.Label>
                  <Form.Control type="text" name="promotionCost" onChange={handleChange} value={state.promotionCost} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="frieght1">
                  <Form.Label>Frieght1</Form.Label>
                  <Form.Control type="text" name="frieght1" onChange={handleChange} value={state.frieght1} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="frieght2">
                  <Form.Label>Frieght2</Form.Label>
                  <Form.Control type="text" name="frieght2" onChange={handleChange} value={state.frieght2} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="tax">
                  <Form.Label>Tax</Form.Label>
                  <Form.Control type="text" name="tax" onChange={handleChange} value={state.tax} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="finalCost">
                  <Form.Label>FinalCost</Form.Label>
                  <Form.Control type="text" name="finalCost" onChange={handleChange} value={state.finalCost} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="sell">
                  <Form.Label>Sell</Form.Label>
                  <Form.Control type="text" name="sell" onChange={handleChange} value={state.sell} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="gpm">
                  <Form.Label>Gpm</Form.Label>
                  <Form.Control type="text" name="gpm" onChange={handleChange} value={state.gpm} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default EditProductPriceDetail;