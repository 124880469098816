import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateProductPriceDetail = ({
  title,
  createModalOpen,
  showCreateModal,
  hideCreateModal,
  handleCreateModalSubmit,
  handleChange,
  state,
  Manufacturer,
  vendor,
  ProductType
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} size="xl">
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="manufacturerId">
                  <Form.Label>Manufacturer</Form.Label>
                  <Form.Select aria-label="manufacturerId" name="manufacturerId" onChange={handleChange} value={state.manufacturerId}>
                    <option>Select Manufacturer</option>
                    {Manufacturer.map((u, i) => (
                      <option value={u.manufacturerId} key={i}>{u.manufacturer}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="vendorId">
                  <Form.Label>Vendor</Form.Label>
                  <Form.Select aria-label="vendorId" name="vendorId" onChange={handleChange} value={state.vendorId}>
                    <option>Select Vendor</option>
                    {vendor.map((u, i) => (
                      <option value={u.vendorId} key={i}>{u.companyName}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="productTypeId">
                  <Form.Label>ProductType</Form.Label>
                  <Form.Select aria-label="productTypeId" name="productTypeId" onChange={handleChange} value={state.productTypeId}>
                    <option>Select ProductType</option>
                    {ProductType.map((u, i) => (
                      <option value={u.productTypeId} key={i}>{u.productType}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="width">
                  <Form.Label>Width</Form.Label>
                  <Form.Control type="text" name="width" onChange={handleChange} value={state.width} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="length">
                  <Form.Label>Length</Form.Label>
                  <Form.Control type="text" name="length" onChange={handleChange} value={state.length} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="volume">
                  <Form.Label>Volume</Form.Label>
                  <Form.Control type="text" name="volume" onChange={handleChange} value={state.volume} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="productStyle">
                  <Form.Label>ProductStyle</Form.Label>
                  <Form.Control type="text" name="productStyle" onChange={handleChange} value={state.productStyle} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="modelNumber">
                  <Form.Label>ModelNumber</Form.Label>
                  <Form.Control type="text" name="modelNumber" onChange={handleChange} value={state.modelNumber} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="collections">
                  <Form.Label>Collections</Form.Label>
                  <Form.Control type="text" name="collections" onChange={handleChange} value={state.collections} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateModalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreateProductPriceDetail;