import {
  EMPLOYEE_LIST_REQUEST,
  EMPLOYEE_LIST_SUCCESS,
  EMPLOYEE_LIST_FAILED,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_CREATE_FAILED,
  EMPLOYEE_EDIT_REQUEST,
  EMPLOYEE_EDIT_SUCCESS,
  EMPLOYEE_EDIT_FAILED,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_FAILED,
  EMPLOYEE_PASSWORD_REQUEST,
  EMPLOYEE_PASSWORD_SUCCESS,
  EMPLOYEE_PASSWORD_FAILED,
  EMPLOYEE_DROPDOWN_REQUEST,
  EMPLOYEE_DROPDOWN_SUCCESS,
  EMPLOYEE_DROPDOWN_FAILED,
  EMPLOYEE_STATE_CHANGE
} from "../../../_utils/constants/Employee";

const initialState = {
  employeeList: [],
  productStructures: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const EmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case EMPLOYEE_LIST_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        employeeList: decryptedData.result,
        isLoading: false,
        errors: {}
      }
    case EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload
      }
      case EMPLOYEE_DROPDOWN_REQUEST:
        
        return {
          ...state,
          isLoading: true,
          isSuccess: false,
          errors: {}
      
        }
      case EMPLOYEE_DROPDOWN_SUCCESS:
        var decryptedData = action.payload;
        return {
          ...state,
          productStructures: decryptedData.result,
          isLoading: false,
          isSuccess: true,
          errors: {}
        }
      case EMPLOYEE_DROPDOWN_FAILED:
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          errors: action.payload
        }
      case EMPLOYEE_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case EMPLOYEE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case EMPLOYEE_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }
      case EMPLOYEE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case EMPLOYEE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case EMPLOYEE_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }


    case EMPLOYEE_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case EMPLOYEE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case EMPLOYEE_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case EMPLOYEE_EDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case EMPLOYEE_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case EMPLOYEE_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }
    case EMPLOYEE_STATE_CHANGE:
      return {
        ...state,
        employeeList: [],
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
    };
  return state;
}

export default EmployeeReducer;