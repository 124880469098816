import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  FORM_VALUE_LIST_REQUEST,
  FORM_VALUE_LIST_SUCCESS,
  FORM_VALUE_LIST_FAILED,
  FORM_VALUE_LIST_DELETE_REQUEST,
  FORM_VALUE_LIST_DELETE_SUCCESS,
  FORM_VALUE_LIST_DELETE_FAILED,
  FORM_VALUE_LIST_CREATE_REQUEST,
  FORM_VALUE_LIST_CREATE_SUCCESS,
  FORM_VALUE_LIST_CREATE_FAILED,
  FORM_VALUE_LIST_EDIT_REQUEST,
  FORM_VALUE_LIST_EDIT_SUCCESS,
  FORM_VALUE_LIST_EDIT_FAILED
} from "../../../_utils/constants/ValueList";


function* fetchFormValueListItem({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/FormValueList/GetAllFormValueList`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put({ type: FORM_VALUE_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: FORM_VALUE_LIST_FAILED, payload: error.response });
    }
  }
}

function* deleteFormValueListItem(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/FormValueList/RemoveFormValueList?FormValueListId=${encodeURIComponent(action.payload.FormValueListId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: FORM_VALUE_LIST_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: FORM_VALUE_LIST_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createFormValueList(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/FormValueList/CreateFormValueList`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: FORM_VALUE_LIST_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: FORM_VALUE_LIST_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editFormValueList(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/FormValueList/UpdateFormValueList`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: FORM_VALUE_LIST_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: FORM_VALUE_LIST_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
export default function* rootFormValueListItem() {
  yield all([
    takeLatest(FORM_VALUE_LIST_REQUEST, fetchFormValueListItem),
    takeLatest(FORM_VALUE_LIST_DELETE_REQUEST, deleteFormValueListItem),
    takeLatest(FORM_VALUE_LIST_CREATE_REQUEST, createFormValueList),
    takeLatest(FORM_VALUE_LIST_EDIT_REQUEST, editFormValueList)
  ]);
}