import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';

const EditProductSpec = ({
  title,
  show,
  hideCreateModal,
  handleEditSaveClick,
  handleChange,
  state,
  ProductInfo
}) => {

  return (
    <Fragment>

      <Modal show={show} size="xl">
        <Modal.Header>Edit {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="productInfoId">
                  <Form.Label>ProductInfoId</Form.Label>
                  <Form.Select aria-label="productInfoId" name="productInfoId" onChange={handleChange} value={state.productInfoId}>
                    <option>Select ProductInfoId</option>
                    {ProductInfo.map((p, i) => (
                      <option key={i} value={p.productInfoId}>{p.productStyle}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="unitPer1">
                  <Form.Label>UnitPer1</Form.Label>
                  <Form.Control type="text" name="unitPer1" onChange={handleChange} value={state.unitPer1} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="unitPer2">
                  <Form.Label>UnitPer2</Form.Label>
                  <Form.Control type="text" name="unitPer2" onChange={handleChange} value={state.unitPer2} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="cashBack">
                  <Form.Label>CashBack</Form.Label>
                  <Form.Control type="text" name="cashBack" onChange={handleChange} value={state.cashBack} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="buyingGroup">
                  <Form.Label>BuyingGroup</Form.Label>
                  <Form.Control type="text" name="buyingGroup" onChange={handleChange} value={state.buyingGroup} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="royality">
                  <Form.Label>Royality</Form.Label>
                  <Form.Control type="text" name="royality" onChange={handleChange} value={state.royality} />
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="tariff">
                  <Form.Label>Tariff</Form.Label>
                  <Form.Control type="text" name="tariff" onChange={handleChange} value={state.tariff} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="stocking">
                  <Form.Check
                    type="switch"
                    id="stocking"
                    label="Stocking"
                    onChange={handleChange}
                    checked={state.stocking}

                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="custom">
                  <Form.Label>Custom</Form.Label>
                  <Form.Control type="text" name="custom" onChange={handleChange} value={state.custom} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="fOB">
                  <Form.Label>FOB</Form.Label>
                  <Form.Control type="text" name="fOB" onChange={handleChange} value={state.fOB} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="qualityRating">
                  <Form.Label>QualityRating</Form.Label>
                  <Form.Control type="text" name="qualityRating" onChange={handleChange} value={state.qualityRating} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="warrantly">
                  <Form.Label>Warrantly</Form.Label>
                  <Form.Control type="text" name="warrantly" onChange={handleChange} value={state.warrantly} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="altId">
                  <Form.Label>AltId</Form.Label>
                  <Form.Control type="text" name="altId" onChange={handleChange} value={state.altId} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="productSourceId">
                  <Form.Label>ProductSourceId</Form.Label>
                  <Form.Control type="text" name="productSourceId" onChange={handleChange} value={state.productSourceId} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="thickness">
                  <Form.Label>Thickness</Form.Label>
                  <Form.Control type="text" name="thickness" onChange={handleChange} value={state.thickness} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="iCN">
                  <Form.Label>ICN</Form.Label>
                  <Form.Control type="text" name="iCN" onChange={handleChange} value={state.iCN} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="spreadRate">
                  <Form.Label>SpreadRate</Form.Label>
                  <Form.Control type="text" name="spreadRate" onChange={handleChange} value={state.spreadRate} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="privateLabelStyle">
                  <Form.Label>PrivateLabelStyle</Form.Label>
                  <Form.Control type="text" name="privateLabelStyle" onChange={handleChange} value={state.privateLabelStyle} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="privateLabelCollection">
                  <Form.Label>PrivateLabelCollection</Form.Label>
                  <Form.Control type="text" name="privateLabelCollection" onChange={handleChange} value={state.privateLabelCollection} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="display">
                  <Form.Label>Display</Form.Label>
                  <Form.Control type="text" name="display" onChange={handleChange} value={state.display} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="taxCode">
                  <Form.Label>TaxCode</Form.Label>
                  <Form.Control type="text" name="taxCode" onChange={handleChange} value={state.taxCode} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="auditText">
                  <Form.Label>AuditText</Form.Label>
                  <Form.Control type="text" name="auditText" onChange={handleChange} value={state.auditText} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="isArchive">
                  <Form.Check
                    type="switch"
                    id="isArchive"
                    label="IsArchive"
                    onChange={handleChange}
                    checked={state.isArchive}

                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default EditProductSpec;