export const PRICING_CONTRACT_CREATE_REQUEST = "@PRICING_CONTRACT_CREATE_REQUEST";
export const PRICING_CONTRACT_CREATE_SUCCESS = "@PRICING_CONTRACT_CREATE_SUCCESS";
export const PRICING_CONTRACT_CREATE_FAILED = "@PRICING_CONTRACT_CREATE_FAILED";

export const PRICING_CONTRACT_PRODUCT_LIST_REQUEST = "@PRICING_CONTRACT_PRODUCT_LIST_REQUEST";
export const PRICING_CONTRACT_PRODUCT_LIST_SUCCESS = "@PRICING_CONTRACT_PRODUCT_LIST_SUCCESS";
export const PRICING_CONTRACT_PRODUCT_LIST_FAILED = "@PRICING_CONTRACT_PRODUCT_LIST_FAILED";

export const PRICING_CONTRACT_LABOR_LIST_REQUEST = "@PRICING_CONTRACT_LABOR_LIST_REQUEST";
export const PRICING_CONTRACT_LABOR_LIST_SUCCESS = "@PRICING_CONTRACT_LABOR_LIST_SUCCESS";
export const PRICING_CONTRACT_LABOR_LIST_FAILED = "@PRICING_CONTRACT_LABOR_LIST_FAILED";

export const PRICING_CONTRACT_MATERIAL_LIST_REQUEST = "@PRICING_CONTRACT_MATERIAL_LIST_REQUEST";
export const PRICING_CONTRACT_MATERIAL_LIST_SUCCESS = "@PRICING_CONTRACT_MATERIAL_LIST_SUCCESS";
export const PRICING_CONTRACT_MATERIAL_LIST_FAILED = "@PRICING_CONTRACT_MATERIAL_LIST_FAILED";

export const PRICING_CONTRACT_TRANSITION_LABOR_LIST_REQUEST = "@PRICING_CONTRACT_TRANSITION_LABOR_LIST_REQUEST";
export const PRICING_CONTRACT_TRANSITION_LABOR_LIST_SUCCESS = "@PRICING_CONTRACT_TRANSITION_LABOR_LIST_SUCCESS";
export const PRICING_CONTRACT_TRANSITION_LABOR_LIST_FAILED = "@PRICING_CONTRACT_TRANSITION_LABOR_LIST_FAILED";

export const PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_REQUEST = "@PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_REQUEST";
export const PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_SUCCESS = "@PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_SUCCESS";
export const PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_FAILED = "@PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_FAILED";

export const PRICING_CONTRACT_LABOR_ITEM_LIST_REQUEST = "@PRICING_CONTRACT_LABOR_ITEM_LIST_REQUEST";
export const PRICING_CONTRACT_LABOR_ITEM_LIST_SUCCESS = "@PRICING_CONTRACT_LABOR_ITEM_LIST_SUCCESS";
export const PRICING_CONTRACT_LABOR_ITEM_LIST_FAILED = "@PRICING_CONTRACT_LABOR_ITEM_LIST_FAILED";

export const PRICING_CONTRACT_DETAILS_REQUEST = "@PRICING_CONTRACT_DETAILS_REQUEST";
export const PRICING_CONTRACT_DETAILS_SUCCESS = "@PRICING_CONTRACT_DETAILS_SUCCESS";
export const PRICING_CONTRACT_DETAILS_FAILED = "@PRICING_CONTRACT_DETAILS_FAILED";

export const PRICING_CONTRACT_STATE_CHANGE = "@PRICING_CONTRACT_STATE_CHANGE";

export const GROUP_PRICING_CONTRACT_PRODUCT_LIST_REQUEST = "@GROUP_PRICING_CONTRACT_PRODUCT_LIST_REQUEST";
export const GROUP_PRICING_CONTRACT_PRODUCT_LIST_SUCCESS = "@GROUP_PRICING_CONTRACT_PRODUCT_LIST_SUCCESS";
export const GROUP_PRICING_CONTRACT_PRODUCT_LIST_FAILED = "@GROUP_PRICING_CONTRACT_PRODUCT_LIST_FAILED";

export const GROUP_PRICING_CONTRACT_CREATE_REQUEST = "@GROUP_PRICING_CONTRACT_CREATE_REQUEST";
export const GROUP_PRICING_CONTRACT_CREATE_SUCCESS = "@GROUP_PRICING_CONTRACT_CREATE_SUCCESS";
export const GROUP_PRICING_CONTRACT_CREATE_FAILED = "@GROUP_PRICING_CONTRACT_CREATE_FAILED";

export const GROUP_PRICING_CONTRACT_SAVE_PROPERTY_REQUEST = "@GROUP_PRICING_CONTRACT_SAVE_PROPERTY_REQUEST";
export const GROUP_PRICING_CONTRACT_SAVE_PROPERTY_SUCCESS = "@GROUP_PRICING_CONTRACT_SAVE_PROPERTY_SUCCESS";
export const GROUP_PRICING_CONTRACT_SAVE_PROPERTY_FAILED = "@GROUP_PRICING_CONTRACT_SAVE_PROPERTY_FAILED";

export const GROUP_PRICING_CONTRACT_UPDATE_REQUEST = "@GROUP_PRICING_CONTRACT_UPDATE_REQUEST";
export const GROUP_PRICING_CONTRACT_UPDATE_SUCCESS = "@GROUP_PRICING_CONTRACT_UPDATE_SUCCESS";
export const GROUP_PRICING_CONTRACT_UPDATE_FAILED = "@GROUP_PRICING_CONTRACT_UPDATE_FAILED";

export const GROUP_PRICING_CONTRACT_DELETE_REQUEST = "@GROUP_PRICING_CONTRACT_DELETE_REQUEST";
export const GROUP_PRICING_CONTRACT_DELETE_SUCCESS = "@GROUP_PRICING_CONTRACT_DELETE_SUCCESS";
export const GROUP_PRICING_CONTRACT_DELETE_FAILED = "@GROUP_PRICING_CONTRACT_DELETE_FAILED";

export const GROUP_PRICING_CONTRACT_FILTER_REQUEST = "@GROUP_PRICING_CONTRACT_FILTER_REQUEST";
export const GROUP_PRICING_CONTRACT_FILTER_SUCCESS = "@GROUP_PRICING_CONTRACT_FILTER_SUCCESS";
export const GROUP_PRICING_CONTRACT_FILTER_FAILED = "@GROUP_PRICING_CONTRACT_FILTER_FAILED";

export const CUSTOMER_ORDER_MATERIAL_REQUEST = "@CUSTOMER_ORDER_MATERIAL_REQUEST";
export const CUSTOMER_ORDER_MATERIAL_SUCCESS = "@CUSTOMER_ORDER_MATERIAL_SUCCESS";
export const CUSTOMER_ORDER_MATERIAL_FAILED = "@CUSTOMER_ORDER_MATERIAL_FAILED";

export const CUSTOMER_ESTIMATES_REQUEST = "@CUSTOMER_PRODUCT_PRICE_LIST_REQUEST";
export const CUSTOMER_ESTIMATES_SUCCESS = "@CUSTOMER_PRODUCT_PRICE_LIST_SUCCESS";
export const CUSTOMER_ESTIMATES_FAILED = "@CUSTOMER_PRODUCT_PRICE_LIST_FAILED";

export const CUSTOMER_ORDER_INSTALLATION_REQUEST = "@CUSTOMER_ORDER_INSTALLATION_REQUEST";
export const CUSTOMER_ORDER_INSTALLATION_SUCCESS = "@CUSTOMER_ORDER_INSTALLATION_SUCCESS";
export const CUSTOMER_ORDER_INSTALLATION_FAILED = "@CUSTOMER_ORDER_INSTALLATION_FAILED";

export const CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST = "@CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST";
export const CUSTOMER_ORDER_MATERIAL_DELETE_SUCCESS = "@CUSTOMER_ORDER_MATERIAL_DELETE_SUCCESS";
export const CUSTOMER_ORDER_MATERIAL_DELETE_FAILED = "@CUSTOMER_ORDER_MATERIAL_DELETE_FAILED";

export const CUSTOMER_ORDER_LABOR_DELETE_REQUEST = "@CUSTOMER_ORDER_LABOR_DELETE_REQUEST";
export const CUSTOMER_ORDER_LABOR_DELETE_SUCCESS = "@CUSTOMER_ORDER_LABOR_DELETE_SUCCESS";
export const CUSTOMER_ORDER_LABOR_DELETE_FAILED = "@CUSTOMER_ORDER_LABOR_DELETE_FAILED";

export const PRICING_CONTRACT_ORDER_STATE_CHANGE = "@PRICING_CONTRACT_ORDER_STATE_CHANGE";

export const GROUP_PRICING_CONTRACT_STATE_CHANGE = "@GROUP_PRICING_CONTRACT_STATE_CHANGE";

export const PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST = "@PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST";
export const PRICING_CONTRACT_MISSING_STAIRS_LABOR_SUCCESS = "@PRICING_CONTRACT_MISSING_STAIRS_LABOR_SUCCESS";
export const PRICING_CONTRACT_MISSING_STAIRS_LABOR_FAILED = "@PRICING_CONTRACT_MISSING_STAIRS_LABOR_FAILED";

export const PRICING_CONTRACT_QUANTITY_SET_PRODUCT_REQUEST = "@PRICING_CONTRACT_QUANTITY_SET_PRODUCT_REQUEST";
export const PRICING_CONTRACT_QUANTITY_SET_PRODUCT_SUCCESS = "@PRICING_CONTRACT_QUANTITY_SET_PRODUCT_SUCCESS";
export const PRICING_CONTRACT_QUANTITY_SET_PRODUCT_FAILED = "@PRICING_CONTRACT_QUANTITY_SET_PRODUCT_FAILED";