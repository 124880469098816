import {
  MATERIAL_ADDONS_REQUEST,
  MATERIAL_ADDONS_SUCCESS,
  MATERIAL_ADDONS_FAILED,
  LABOR_ADDONS_REQUEST,
  LABOR_ADDONS_SUCCESS,
  LABOR_ADDONS_FAILED,
  DEFAULT_PRODUCT_STRUCTURE_REQUEST,
  DEFAULT_PRODUCT_STRUCTURE_SUCCESS,
  DEFAULT_PRODUCT_STRUCTURE_FAILED,
  KIT_CREATE,
  KIT_CREATE_SUCCESS,
  KIT_CREATE_FAILED,
  KIT_REQUEST,
  KIT_SUCCESS,
  KIT_FAILED,
  KIT_DELETE,
  KIT_DELETE_SUCCESS,
  KIT_DELETE_FAILED,
  KIT_EDIT_REQUEST,
  KIT_EDIT_SUCCESS,
  KIT_EDIT_FAILED,
  KIT_UPDATE_REQUEST,
  KIT_UPDATE_SUCCESS,
  KIT_UPDATE_FAILED,
  KIT_CREATED_REQUEST,
  KIT_CREATED_SUCCESS,
  KIT_CREATED_FAILED,
  KIT_STATE_CHANGE
} from "../../_utils/constants/Kits";


const initialState = {
  materialAddons: [],
  laborAddons: [],
  productStructures: [],
  kits: [],
  kit: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEditedList: false,
  isUpdated: false,
  createdMessage: "",
  errors: {},
}

const kitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MATERIAL_ADDONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        createdMessage: "",
        errors: {}
      }
    case MATERIAL_ADDONS_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        materialAddons: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case MATERIAL_ADDONS_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case KIT_REQUEST:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isCreated: false,
        isDeleted: false,
        isEditedList: false,
        isUpdated: false,
        createdMessage: "",
        errors: {}
      }
    case KIT_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        kits: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case KIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case KIT_EDIT_REQUEST:
      return {
        ...state,
        isEditedList: false,
        createdMessage: "",
        errors: {}
      }
    case KIT_EDIT_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        kit: decryptedData.result,
        createdMessage: "",
        isEditedList: true,
        errors: {}
      }
    case KIT_EDIT_FAILED:
      return {
        ...state,
        isEditedList: false,
        errors: action.payload
      }
    case KIT_CREATED_REQUEST:
      return {
        ...state,
        errors: {},
        isEditedList: false,
      }
    case KIT_CREATED_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        createdMessage: decryptedData.responsestatus,
        isEditedList: false,
        errors: {}
      }
    case KIT_CREATED_FAILED:
      return {
        ...state,
        errors: action.payload,
        isEditedList: false,
      }
    case KIT_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        createdMessage: "",
        errors: {}
      }
    case KIT_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case KIT_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }
    case KIT_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        createdMessage: "",
        errors: {}
      }
    case KIT_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case KIT_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case LABOR_ADDONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        createdMessage: "",
        errors: {}
      }
    case LABOR_ADDONS_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        laborAddons: decryptedData.result,
        isLoading: false,
        isSuccess: true,

        errors: {}
      }
    case LABOR_ADDONS_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case DEFAULT_PRODUCT_STRUCTURE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case DEFAULT_PRODUCT_STRUCTURE_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        productStructures: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case DEFAULT_PRODUCT_STRUCTURE_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case KIT_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        errors: {}
      }
    case KIT_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        errors: {}
      }
    case KIT_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isUpdated: false,
        errors: action.error
      }
    case KIT_STATE_CHANGE:
      return {
        isLoading: false,
        isUpdated: false,
        isSuccess: false,
        isCreated: false,
        isDeleted: false,
        isEditedList: false,
        createdMessage: "",
      }
  }
  return state;
}

export default kitsReducer;