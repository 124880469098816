import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateSoldBy";
import ConfirmDelete from "./models/ConfirmDelete";
// import { encrypted } from '../../../../_utils/helpers';
import {
  SOLD_BY_REQUEST,
  SOLD_BY_CREATE,
  SOLD_BY_DELETE,
  SOLD_BY_EDIT,
  SOLD_BY_STATE_CHANGE
} from "../../../../_utils/constants/ProductCatalog";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const SoldBy = ({
  auth: { auth, isAuthenticated },
  SoldBy: { valueList, isCreated, isDeleted, isEdited, isLoading },
  fetchSoldBy,
  submitSoldByCreate,
  submitSoldByDelete,
  submitSoldByEdit,
  changeReducerState
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [salesSoldByName, setSoldByName] = useState('');
  const [sortOrder, setSortOrder] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteSoldById, setDeleteSoldById] = useState('');
  const [editSoldById, setEditSoldById] = useState('');
  const [editSoldByName, setEditSoldByName] = useState('');
  const [editSortOrder, setEditSortOrder] = useState('');

  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchSoldBy(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("SoldBy Created Successfully.");
      fetchSoldBy(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("SoldBy Deleted Successfully.");
      fetchSoldBy(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("SoldBy Data Updated Successfully.");
      fetchSoldBy(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
  };

  // function called for input value change of create modal
  const handleSalesStageNameChange = (e) => {
    setSoldByName(e.target.value);
  }
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (salesSoldByName === '') {
      toast.error("SOLD_BY name cannot be empty.");
    } else {
      submitSoldByCreate({
        "data": {
          "SoldByName": salesSoldByName,
          "SoldByCode": sortOrder,
          "CreatedBy": auth[0].userId
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteSoldById(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteSoldById('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitSoldByDelete({
      "SoldById": deleteSoldById,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setEditSoldById(data.soldById);
    setEditSoldByName(data.soldByName);
    setEditSortOrder(data.soldByCode);
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (editSoldByName === '') {
      toast.error("SOLD_BY name cannot be empty.");
    } else {
      submitSoldByEdit({
        "data": {
          "SoldById": data.soldById,
          "SoldByName": editSoldByName,
          "SoldByCode": editSortOrder
        },
        "token": auth[0].token
      });
      setEditSoldById('');
      setEditSoldByName('');
      setEditSortOrder('');
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>SoldBy</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`SoldBy`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              salesStageName={salesSoldByName}
              handleSalesStageNameChange={handleSalesStageNameChange}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>SoldByName</th>
                <th className="text-center">SoldByCode</th>
                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (
                  editSoldById === item.soldById ? (
                    <tr key={index}>
                      <td>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control type="text" value={editSoldByName} onChange={(evt) => setEditSoldByName(evt.target.value)} />
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td width="150">
                        <Form.Group>
                          <InputGroup>
                            <Form.Control type="text" value={editSortOrder} onChange={(evt) => setEditSortOrder(evt.target.value)} />
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td className="actions">
                        <div className="btn-group">
                          <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
                          <ConfirmDelete variant="primary" title={`SoldBy`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.soldById)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr key={index}>
                      <td>
                        {item.soldByName}
                      </td>
                      <td width="150">
                        {item.soldByCode}
                      </td>
                      <td className="actions">
                        <div className="btn-group">
                          <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                          <ConfirmDelete variant="primary" title={`SoldBy`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.soldById)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                        </div>
                      </td>
                    </tr>
                  )
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, SoldBy }) => {
  return {
    auth,
    SoldBy
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSoldBy: (token) => dispatch({ type: SOLD_BY_REQUEST, token }),
    submitSoldByCreate: (data) => dispatch({ type: SOLD_BY_CREATE, payload: data }),
    submitSoldByDelete: (data) => dispatch({ type: SOLD_BY_DELETE, payload: data }),
    submitSoldByEdit: (data) => dispatch({ type: SOLD_BY_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: SOLD_BY_STATE_CHANGE })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SoldBy);