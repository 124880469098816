import React, { useEffect, useMemo, Fragment, useState } from "react";
import { Button, Form, Table, Row, Col, Image } from "react-bootstrap";
import { Icon } from 'react-icons-kit';
import { ic_camera_alt } from 'react-icons-kit/md/';
import Input from "../../../elements/CustomInput";
import Select from "../../../elements/CustomSelect";
import Checkbox from "../../../elements/CustomCheckBox";
import { connect } from "react-redux";
import { STORE_INFO_REQUEST } from "../../../../_utils/constants/admin/StoreInfo";
import { LABOR_CATEGORY_REQUEST } from "../../../../_utils/constants/Labor";
import { PRODUCT_TYPE_REQUEST, UNIT_REQUEST } from "../../../../_utils/constants/ProductCatalog";
import { 
  CARPET_ID_DB, 
  SHEET_VINYL_ID_DB, 
  VINYL_PLANK_ID_DB, 
  WALLBASE_ID_DB,
  INSTALLATION_ACCESSORIES_ID_DB,
  TRANSITION_ID_DB
} from "../../../../_utils/constants/ProductCatalog";

const PrimaryProductList = ({
  auth: { auth, isAuthenticated },
  productList,
  laborList,
  materialList,
  primaryProductId,
  primaryProductName,
  LaborCategory: { laborCategory },
  ProductType,
  unit,
  StoreInfo: { storeInfo },
  storeId,
  selectedIds,
  selectedLaborIds,
  selectedMaterialIds,
  stateLaborFilter,
  stateProductFilter,
  fetchLaborCategory,
  fetchProductType,
  fetchUnit,
  fetchStoreInfo,
  handleCheckboxClick,
  handlePriceChange,
  handleGpmChange,
  handleLaborCheckboxClick,
  handleMaterialCheckboxClick,
  handleLaborPriceChange,
  handleLaborGpmChange,
  handleMaterialPriceChange,
  handleMaterialGpmChange,
  handleLaborFilterChange,
  handleProductFilterChange,
  handleCheckboxSelect,
  handleSecondaryCheckboxChange,
  handleAllPrice,
  materialContractModalOpen,
  laborContractModalOpen,
  handleQuantityChange,
  handleLaborKitQuantityChange,
  handleMaterialKitQuantityChange,
  handlePrimaryProductLineTotalChange,
  handlePrimaryProductMaterialKitLineTotalChange,
  handlePrimaryProductLaborKitLineTotalChange,
  handleOrderLaborQuantityChange,
  productSubStructure,
  // selectedLaborKitIds,
  selectedKitLaborIds,
  selectedData,
  missingStairsLaborIds,
  handleStairsLabor
}) => {

  const [subProductKitName, setSubProductKitName] = useState([])
  
  // function to call customer detail api
  useEffect(() => {
    if (isAuthenticated) {
      fetchStoreInfo(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  // function to call labor product and unit list data api for filter dropdowns
  useEffect(() => {
    if (isAuthenticated && primaryProductId === 0) {
      fetchLaborCategory(auth[0].token);
      fetchProductType(auth[0].token);
      fetchUnit(auth[0].token);
    }
  }, [auth, isAuthenticated, primaryProductId])

  // function to manage store list data fetched
  const storeList = useMemo(() => {
    if (storeInfo)
      return storeInfo.map(v => ({ label: v.storeName, value: v.storeId }))
  }, [storeInfo])

  // function to manage product structure list data fetched
  const productStructure = useMemo(() => {
    if (ProductType)
      return ProductType.valueList.map(v => ({ label: v.productStructure, value: v.productStructureId }))
  }, [ProductType])

  // function to manage unit list data fetched
  const unitList = useMemo(() => {
    if (unit.valueList)
      return unit.valueList.map(v => ({ label: v.unitName, value: v.unitId }))
  }, [unit])
  
  // function to manage labor list data fetched
  const laborCategories = useMemo(() => {
    if (laborCategory)
      return laborCategory.map(v => ({ label: v.laborCategory, value: v.laborCategoryId }))
  }, [laborCategory])

  // function to handle labor kit price and gpm data
  const setPrice =(price) => {
    return (price) ? price : ''
  }

  // function to handle material kit price and gpm data
  const setPriceforMaterialKit =(price) => {
    return (price) ? price : ''
  }

  // handle kit sub product structure
  useEffect(() => {
    if ((primaryProductId === WALLBASE_ID_DB || primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === TRANSITION_ID_DB) && (laborList && laborList.length > 0) || (materialList && materialList.length > 0) && (selectedIds && selectedIds.length > 0) && (selectedData && selectedData.length > 0)) {
      let tempIds = []
      selectedData.map(data => {
        tempIds.push(data.subProductStructureId)
      })
      let tempKitArray = [...laborList, ...materialList]
      tempKitArray = tempKitArray.filter(data => tempIds.includes(data.subProductStructureId))
      let tempArr = [...new Set(tempKitArray.map(data => data.subProductStructure))]
      setSubProductKitName(tempArr)
    } else {
      setSubProductKitName([])
    }
  }, [laborList, materialList, selectedIds, selectedData])

  // handle stairs check condition 
  const handleStairsCheck = (item) => {
    let foundStairs = false;
    if (selectedIds.length > 0 && missingStairsLaborIds.length > 0) {
      let isItemSelected = selectedIds.find(element => element === parseInt(item.laborItemId));
      let isItemInMissing = missingStairsLaborIds.find(mnt => mnt === parseInt(item.laborItemId));
      if(isItemSelected ===undefined && isItemInMissing){
        foundStairs = true;
        handleStairsLabor(true)
      }
    }
    if (selectedIds.length === 0 && missingStairsLaborIds.length > 0) {
      let isItemInMissing = missingStairsLaborIds.find(mnt => mnt === parseInt(item.laborItemId));
      if (isItemInMissing) {
        foundStairs = true;
        handleStairsLabor(true)
      }
    }
    return foundStairs;
  }

  return (
    <div className="step">
      
      {(primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB || primaryProductId === WALLBASE_ID_DB) ? (
        <h6 className="title my-2 float-start">Which {primaryProductName} would you like to {materialContractModalOpen ? 'add' : 'show the customer'}?</h6>
      ) : (
        <h6 className="title my-2 float-start">
          {(!materialContractModalOpen && !laborContractModalOpen) ? 
            `Are there any miscellaneous ${primaryProductId === 0 ? 'labor services' : primaryProductId === INSTALLATION_ACCESSORIES_ID_DB ? 'sundries' : 'transition'} you would like to price?`
          : `Which ${primaryProductName} ${!laborContractModalOpen ? 'would' : 'services'} you like to add?`}
        </h6>
      )}
      {((primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === 0) && (!materialContractModalOpen && !laborContractModalOpen)) ? (
        <Button className="mb-2 float-end" variant="primary" onClick={handleAllPrice} disabled={selectedIds.length>0 ? false : true}>
          Update Price
        </Button>
      ) : ""}
      <Table bordered responsive className="text-center procatlist w-100">
        <thead>
          <tr>
            {(primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB || primaryProductId === WALLBASE_ID_DB || primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === TRANSITION_ID_DB) ? (
              <Fragment>
                { materialContractModalOpen || laborContractModalOpen ?
                  <th>
                    <Form.Group controlId="Quantity">
                      <Form.Label>Quantity</Form.Label>
                    </Form.Group>
                  </th> : ""
                }
                { materialContractModalOpen || laborContractModalOpen ?
                  <th>
                    <Form.Group controlId="Unit">
                      <Form.Label>Unit</Form.Label>
                    </Form.Group>
                  </th> : ""
                }
                <th>
                  <Form.Group controlId="IsPrimary">
                    <Form.Label>
                    {primaryProductId === INSTALLATION_ACCESSORIES_ID_DB ? "Display as Add On" : "Primary"}
                    </Form.Label>
                    {/*<Form.Check type="checkbox" onChange={(evt)=>handleCheckboxSelect(evt, 'IsPrimary')} ids="IsPrimary" />*/}
                  </Form.Group>
                </th>
                {(primaryProductId === INSTALLATION_ACCESSORIES_ID_DB) ? null :
                    <th>
                      <Form.Group controlId="IsSecondary">
                        <Form.Label>Secondary</Form.Label>
                        {/*<Form.Check type="checkbox" onChange={(evt)=>handleCheckboxSelect(evt, 'IsSecondary')} ids="IsSecondary" />*/}
                      </Form.Group>
                    </th>
                  }
                <th>
                  <Form.Group controlId="IsStock">
                    <Form.Label>Stock</Form.Label>
                    <Form.Check type="checkbox" onChange={(evt)=>handleCheckboxSelect(evt, 'IsStock')} ids="IsStock" />
                  </Form.Group>
                </th>
                <th>
                  <Form.Group controlId="IsRecurringStock">
                    <Form.Label>Recurring Stock</Form.Label>
                    <Form.Check type="checkbox" onChange={(evt)=>handleCheckboxSelect(evt, 'IsRecurringStock')} ids="IsRecurringStock" />
                  </Form.Group>
                </th>
              </Fragment>
              ) : (
              <Fragment>
                { laborContractModalOpen ?
                  <th>
                    <Form.Group controlId="Quantity">
                      <Form.Label>Quantity</Form.Label>
                    </Form.Group>
                  </th> : ""
                }
                { !laborContractModalOpen ?
                  <th>
                    <Form.Label>{primaryProductId === 0 ? "Display as Add On" : "Check to Select"}</Form.Label>
                  </th> : ""
                }
              </Fragment>
            )}
            {!laborContractModalOpen ? 
              <th>
                <Form.Group>
                  {(primaryProductId === 0) ? (
                    <Select label={`Store Name`} result={storeList} ids="storeId" handleChange={handleLaborFilterChange} value={stateLaborFilter.storeId} validate={false} />
                    ) : (
                    <Select label={`Store Name`} result={storeList} ids="StoreId" handleChange={handleProductFilterChange} value={stateProductFilter.StoreId} validate={false} />  
                  )}
                </Form.Group>
              </th> : "" }
            {(primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB || primaryProductId === WALLBASE_ID_DB || primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === TRANSITION_ID_DB) ? (
              <Fragment>
                <th><Input label={`Private Name`} ids={`PrivateName`} handleChange={handleProductFilterChange} value={stateProductFilter.PrivateName} validate={false} /></th>
                <th><Input label={`Manufacturer`} ids={`Manufacturer`} handleChange={handleProductFilterChange} value={stateProductFilter.Manufacturer} validate={false} /></th>
                <th><Input label={`Style`} ids={`Style`} handleChange={handleProductFilterChange} value={stateProductFilter.Style} validate={false} /></th>
                <th><Input label={`Color`} ids={`Color`} handleChange={handleProductFilterChange} value={stateProductFilter.Color} validate={false} /></th>
                <th><Form.Label>Photo</Form.Label></th>
              </Fragment>
            ) : (
              <Fragment>
                <th><Select label={`Product Structure`} result={productStructure} ids="productStructureId" handleChange={handleLaborFilterChange} value={stateLaborFilter.productStructureId} validate={false} /></th>
                <th><Select label={`Labor Category`} result={laborCategories} ids="laborCategoryId" handleChange={handleLaborFilterChange} value={stateLaborFilter.laborCategoryId} validate={false} /></th>
                <th><Input label={`Labor Name`} ids={`laborName`} handleChange={handleLaborFilterChange} value={stateLaborFilter.laborName} validate={false} /></th>
                <th><Select label={`Unit`} result={unitList} ids="unitId" handleChange={handleLaborFilterChange} value={stateLaborFilter.unitId} validate={false} /></th>
              </Fragment>
            )}
            {(primaryProductId === CARPET_ID_DB) ? (
              <Fragment>
                <th><Select label={`Fiber Content`} result={[{ label: "PET", value: "PET" }, { label: "Nylon", value: "Nylon" }]} ids="Fiber" handleChange={handleProductFilterChange} value={stateProductFilter.Fiber} validate={false} /></th>
                <th><Input label={`Face Weight`} ids={`faceWidth`} handleChange={handleProductFilterChange} value={stateProductFilter.faceWidth} validate={false} /></th>
              </Fragment>
            ) : ""}
            {(primaryProductId === SHEET_VINYL_ID_DB) ? (
              <Fragment>
                <th><Select label={`Backing Type`} result={[{ label: "Fiberglass", value: "Fiberglass" }, { label: "Felt", value: "Felt" }]} ids="BackingType" handleChange={handleProductFilterChange} value={stateProductFilter.BackingType} validate={false} /></th>
                <th><Input label={`Guage`} ids={`Guage`} handleChange={handleProductFilterChange} value={stateProductFilter.Guage} validate={false} /></th>
              </Fragment>
            ) : ""}
            {(primaryProductId === VINYL_PLANK_ID_DB) ? (
              <Fragment>
                <th><Input label={`WearLayer`} ids={`WearLayer`} handleChange={handleProductFilterChange} value={stateProductFilter.WearLayer} validate={false} /></th>
                <th><Input label={`Thickness`} ids={`Thickness`} handleChange={handleProductFilterChange} value={stateProductFilter.Thickness} validate={false} /></th>
              </Fragment>
            ) : ""}
            {primaryProductId === 0 ? (
              <Fragment>
                <th>
                  <Input label={`Current Cost`} ids="currentCost" handleChange={handleLaborFilterChange} value={stateLaborFilter.currentCost} validate={false} />
                </th>
                {!laborContractModalOpen && 
                <th>
                  <Input label={`Future Cost 1`} ids="futureCost1" handleChange={handleLaborFilterChange} value={stateLaborFilter.futureCost1} validate={false} />
                </th>}
              </Fragment>
            ) : (
              <Fragment>
                <th><Input label={`Current Cost`} ids={`currentCost`} handleChange={handleProductFilterChange} value={stateProductFilter.currentCost} validate={false} /></th>
                {!materialContractModalOpen && 
                  <th><Input label={`Future Cost 1`} ids={`futureCost1`} handleChange={handleProductFilterChange} value={stateProductFilter.futureCost1} validate={false} /></th>
                }
              </Fragment>
            )}
            {((primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB || primaryProductId === WALLBASE_ID_DB) && !materialContractModalOpen) ? (
              <th><Input label={`Future Cost 2`} ids={`futureCost2`} handleChange={handleProductFilterChange} value={stateProductFilter.futureCost2} validate={false} /></th>
            ) : ""}
            <th>
              <Form.Label>Price</Form.Label>
            </th>
            <th>
              <Form.Label>GPM</Form.Label>
            </th>
            { materialContractModalOpen || laborContractModalOpen ?
              <th>
                <Form.Group controlId="Linetotal">
                  <Form.Label>Line Total</Form.Label>
                </Form.Group>
              </th> : ""
            }
          </tr>
        </thead>
        <tbody>
          {productList && productList.map((item, index) => (
            <tr key={index} className={!primaryProductId && !laborContractModalOpen && !materialContractModalOpen && handleStairsCheck(item) ? "pricing-contract-table-row" : null}>
              {(primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB || primaryProductId === WALLBASE_ID_DB || primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === TRANSITION_ID_DB) ? (
                <Fragment>
                  { materialContractModalOpen || laborContractModalOpen ?
                    <td>
                      <Input 
                        ids={`quantity`} 
                        validate={false}
                        handleChange={e=>handleQuantityChange(e, item, primaryProductId)}
                        value={item.quantity ? item.quantity : ""}
                      />
                    </td> : ""
                  }
                  { materialContractModalOpen || laborContractModalOpen ?
                    <td>{_.get(item, "unitName", "")}</td> : ""
                  }
                  <td>
                    <Form.Group controlId="isPrimary">
                      <Form.Check 
                        disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                        type="checkbox" 
                        checked={(item.isPrimary) ? item.isPrimary : false} 
                        onChange={e=>handleCheckboxClick(item, primaryProductId)}
                      />
                    </Form.Group>
                  </td>
                  {(primaryProductId === INSTALLATION_ACCESSORIES_ID_DB) ? null : 
                    <td>
                      <Form.Group controlId="isSecondary">
                        <Form.Check 
                          disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                          type="checkbox" 
                          checked={(item.isSecondary) ? item.isSecondary : false} 
                          onChange={e=>handleSecondaryCheckboxChange(e, item, primaryProductId)}
                        />
                      </Form.Group>
                    </td>
                  }
                  <td>
                    <Form.Group controlId="stock">
                      <Form.Check type="checkbox" disabled={true} checked={(item.isStock) ? item.isStock : false} />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="recurringStock">
                      <Form.Check type="checkbox" disabled={true} checked={(item.isRecurringStock) ? item.isRecurringStock : false} />
                    </Form.Group>
                  </td>
                </Fragment>
              ) : (
                <Fragment>
                   { laborContractModalOpen ?
                    <td><Input 
                      ids={`laborQuantity`} 
                      validate={false}
                      handleChange={e=>handleOrderLaborQuantityChange(e, item, primaryProductId)}
                      value={item.quantity ? item.quantity : ""}
                    /></td> : ""
                  }
                  { !laborContractModalOpen ?
                    <td>
                      <Checkbox 
                        ids="StoreId" 
                        validate={false} 
                        handleChange={()=>handleCheckboxClick(item, primaryProductId)}
                        value={selectedIds.includes(item.productId ? item.productId : item.laborItemId)} 
                      />
                    </td> : ""
                  }
                </Fragment>
              )}
              {!laborContractModalOpen ? <td>{_.get(item, "storeName", "")}</td> : ""}
              {(primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB || primaryProductId === WALLBASE_ID_DB || primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === TRANSITION_ID_DB) ? (
                <Fragment>
                  <td>{_.get(item, "privateName", "")}</td>
                  <td>{_.get(item, "manufacturer", "")}</td>
                  <td>{_.get(item, "style", "")}</td>
                  <td>{_.get(item, "color", "")}</td>
                  <td>
                    <Button variant="link">
                      {item.photoPath ? (
                        <Image width="75" src={item.photoPath} />
                      ) : (
                        <Icon size={24} icon={ic_camera_alt} />
                      )}
                    </Button>
                  </td>
                </Fragment>
              ) : (
                <Fragment>
                  <td>{item.productType ? item.productType : item.productStructure}</td>
                  <td>{_.get(item, "laborCategory", "")}</td>
                  <td>{_.get(item, "laborName", "")}</td>
                  <td>{_.get(item, "unitName", "")}</td>
                </Fragment>
              )}
              {(primaryProductId === CARPET_ID_DB) ? (
                <Fragment>
                  <td>{_.get(item, "fiber", "")}</td>
                  <td>{item.faceWidth ? parseFloat(item.faceWidth).toFixed(2) : '0.00'}</td>
                </Fragment>
              ) : ""}
              {(primaryProductId === SHEET_VINYL_ID_DB) ? (
                <Fragment>
                  <td>{_.get(item, "backingType", "")}</td>
                  <td>{item.guage ? parseFloat(item.guage).toFixed(2) : '0.00'}</td>
                </Fragment>
              ) : ""}
              {(primaryProductId === VINYL_PLANK_ID_DB) ? (
                <Fragment>
                  <td>{item.wearLayer ? parseFloat(item.wearLayer).toFixed(2) : '0.00'}</td>
                  <td>{item.thickness ? parseFloat(item.thickness).toFixed(2) : '0.00'}</td>
                </Fragment>
              ) : ""}
              <td>
                {item.totalCurrentCost ? parseFloat(item.totalCurrentCost).toFixed(2) : (item.currentCost ? parseFloat(item.currentCost).toFixed(2) : '0.00')}
              </td>
              {(!laborContractModalOpen && !materialContractModalOpen) &&
                <td>
                  {item.totalFutureCost1 ? parseFloat(item.totalFutureCost1).toFixed(2) : (item.futureCost1 ? parseFloat(item.futureCost1).toFixed(2) : '0.00')}
                </td>
              }
              {((primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB || primaryProductId === WALLBASE_ID_DB) && (!materialContractModalOpen)) ? (
                <td>
                  {item.totalFutureCost2 ? parseFloat(item.totalFutureCost2).toFixed(2) : (item.futureCost2 ? parseFloat(item.futureCost2).toFixed(2) : '0.00')}
                </td>
              ) : ""}
              <td>
                <Input 
                  ids={`price`} 
                  validate={false} 
                  handleChange={(evt)=>handlePriceChange(evt, item.productId ? item.productId : item.laborItemId, item.totalCurrentCost ? item.totalCurrentCost : item.currentCost)} 
                  value={item.price} 
                />
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Input 
                    ids={`gpm`} 
                    validate={false} 
                    handleChange={(evt)=>handleGpmChange(evt, item.productId ? item.productId : item.laborItemId, item.totalCurrentCost ? item.totalCurrentCost : item.currentCost)} 
                    value={item.gpm} 
                  /> <span className="ms-2">%</span>
                </div>
              </td>
                { materialContractModalOpen || laborContractModalOpen ?
                  <td>
                  <Input 
                    ids={`linetotal`} 
                    validate={false}
                    handleChange={(e)=>handlePrimaryProductLineTotalChange(e, item, primaryProductId)} 
                    value={item.linePrice ? item.linePrice : ""} 
                  /></td> : ""
                }
            </tr>
          ))}
        </tbody>
      </Table>
      {(primaryProductId !== 0 && (primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB)) ? (
        <div>
          <h6 className="text-center title mb-4">{materialContractModalOpen ? 'Associated sundries and labor' : 'Labor and accessory material pricing'}</h6>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Table responsive className="procatlist table-borderless">
                <tbody>
                  <tr>
                  { materialContractModalOpen || laborContractModalOpen ? <td width="100">Quantity</td> : "" }
                  { materialContractModalOpen || laborContractModalOpen ? <td>Unit</td> : "" }
                    <td colSpan="2">Check to Select</td>
                    <td>Cost</td>
                    <td>Price</td>
                    <td>GPM</td>
                  { materialContractModalOpen || laborContractModalOpen ? <td>Line Total</td> : "" }
                  </tr>
                  {laborList && laborList.map((laborItem, laborIndex) => (
                    <tr key={laborIndex}>
                      { materialContractModalOpen || laborContractModalOpen ?
                        <td>
                          <Input 
                            ids={`laborKitQuantity`} 
                            validate={false}
                            value={laborItem.quantity ? laborItem.quantity : ""}
                            handleChange={e=>handleLaborKitQuantityChange(e, laborItem, primaryProductId)}
                          />
                        </td> : "" 
                      }
                      { materialContractModalOpen || laborContractModalOpen ?
                        <td>{laborItem.unitName}</td> : ""
                      }
                      {(primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB) ?
                        <td width="5">
                          <Checkbox 
                            ids="laborId" 
                            validate={false} 
                            disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                            handleChange={()=>handleLaborCheckboxClick(laborItem, primaryProductId)}
                            value={selectedLaborIds.includes(laborItem.laborItemId)}
                          />
                        </td>
                      : (<td width="5">
                          <Checkbox 
                            ids="laborId" 
                            validate={false} 
                            disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                            handleChange={()=>handleLaborCheckboxClick(laborItem, primaryProductId)}
                            value={selectedKitLaborIds.includes(laborItem.laborId)}
                          />
                        </td>)
                      }
                      <td>{laborItem.laborName}</td>
                      <td>{laborItem.currentCost}</td>
                      <td width="110">
                        <Input 
                          ids={`laborPrice`} 
                          validate={false} 
                          handleChange={(evt)=>handleLaborPriceChange(evt, laborItem.laborItemId, laborItem.currentCost, laborItem.kitId, laborItem.laborId)} 
                          value={setPrice(laborItem.price)}
                        />
                      </td>
                      <td width="110">
                        <div className="d-flex align-items-center">
                          <Input 
                            ids={`laborGpm`} 
                            validate={false} 
                            handleChange={(evt)=>handleLaborGpmChange(evt, laborItem.laborItemId, laborItem.currentCost, laborItem.kitId, laborItem.laborId)} 
                            value={setPrice(laborItem.gpm)}
                          /><span className="ms-2">%</span>
                        </div>
                      </td>
                      { materialContractModalOpen || laborContractModalOpen ?
                        <td width="110">
                          <Input 
                            ids={`assosciatedLaborTotal`} 
                            validate={false}
                            handleChange={(e)=>handlePrimaryProductLaborKitLineTotalChange(e, laborItem, primaryProductId)}
                            value={laborItem.linePrice ? laborItem.linePrice : ""}
                          />
                        </td> : ""
                      }
                    </tr>
                  ))}
                  {materialList && materialList.map((materialItem, materialIndex) => (
                    <tr key={materialIndex}>
                      { materialContractModalOpen || laborContractModalOpen ?
                        <td><Input 
                          ids={`materialKitQuantity`} 
                          validate={false}
                          handleChange={e=>handleMaterialKitQuantityChange(e, materialItem, primaryProductId)}
                          value={materialItem.quantity ? materialItem.quantity : ""}
                        /></td> : ""
                      }
                      { materialContractModalOpen || laborContractModalOpen ?
                        <td>{materialItem.unitName}</td> : ""
                      }
                      <td width="5">
                        <Checkbox 
                          ids="materialId" 
                          validate={false} 
                          disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                          handleChange={()=>handleMaterialCheckboxClick(materialItem, primaryProductId)}
                          value={selectedMaterialIds.includes(materialItem.materialId)}
                        />
                      </td>
                      <td>{materialItem.privateName}</td>
                      <td>{materialItem.totalCurrentCost ? materialItem.totalCurrentCost : materialItem.currentCost}</td>
                      <td width="110">
                        <Input 
                          ids={`materialPrice`} 
                          validate={false} 
                          handleChange={(evt)=>handleMaterialPriceChange(evt, materialItem.materialId, materialItem.totalCurrentCost ? materialItem.totalCurrentCost : materialItem.currentCost)} 
                          value={setPriceforMaterialKit(materialItem.price)} 
                        />
                      </td>
                      <td width="110">
                        <div className="d-flex align-items-center">
                          <Input 
                            ids={`materialGpm`} 
                            validate={false} 
                            handleChange={(evt)=>handleMaterialGpmChange(evt, materialItem.materialId, materialItem.totalCurrentCost ? materialItem.totalCurrentCost : materialItem.currentCost)} 
                            value={setPriceforMaterialKit(materialItem.gpm)} 
                          /><span className="ms-2">%</span>
                        </div>
                      </td>
                      { materialContractModalOpen || laborContractModalOpen ?
                        <td width="110">
                          <Input 
                            ids={`assosciatedMaterialLineTotal`} 
                            validate={false}
                            handleChange={(e)=>handlePrimaryProductMaterialKitLineTotalChange(e, materialItem, primaryProductId)}
                            value={materialItem.linePrice ? materialItem.linePrice : ""}
                          />
                        </td> : ""
                      }
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      ) : ""}
      {(primaryProductId !== 0 && (primaryProductId === WALLBASE_ID_DB || primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === TRANSITION_ID_DB) && materialContractModalOpen === undefined) ? (
        <div>
          <h6 className="text-center title mb-4">{materialContractModalOpen ? 'Associated sundries and labor' : 'Labor and accessory material pricing'}</h6>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Table responsive className="procatlist material-pricing-table table-borderless">
                <tbody>
                  <tr>
                  { materialContractModalOpen || laborContractModalOpen ? <td width="100">Quantity</td> : "" }
                  { materialContractModalOpen || laborContractModalOpen ? <td>Unit</td> : "" }
                    <td>Check to Select</td>
                    <td style={{width:95 + 'px'}}>Cost</td>
                    <td style={{width:100 + 'px'}}>Price</td>
                    <td style={{width:110 + 'px'}}>GPM</td>
                  { materialContractModalOpen || laborContractModalOpen ? <td>Line Total</td> : "" }
                  </tr>
                  {primaryProductId === WALLBASE_ID_DB || primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === TRANSITION_ID_DB ? 
                    <tr><td className="structure-title" colSpan="7">Product Sub Structure</td></tr> : null
                  }
                  {subProductKitName && subProductKitName.length> 0 && subProductKitName.map((subProductData, subProductIndex) =>(
                  // {selectedIds.length > 0 && subProductKitName && subProductKitName.length> 0 && subProductKitName.map((subProductData, subProductIndex) =>(
                    <tr key={subProductIndex}>
                      <td className="secondaryKit-data" colSpan="7">
                        <Table>
                          <tr><td colSpan="7">{subProductData}</td></tr>
                          {laborList && laborList.map((laborItem, laborIndex) => (
                            <tr key={laborIndex}>
                              {subProductData === laborItem.subProductStructure ?
                              <td colSpan="7">
                                <Table className="subproduct-data-table">
                                    <tr>
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td>
                                          <Input 
                                            ids={`laborKitQuantity`} 
                                            validate={false}
                                            value={laborItem.quantity ? laborItem.quantity : ""}
                                            handleChange={e=>handleLaborKitQuantityChange(e, laborItem, primaryProductId)}
                                          />
                                        </td> : "" 
                                      }
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td>{laborItem.unitName}</td> : ""
                                      }
                                      {(primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB) ?
                                        <td className="p-0">
                                          <div className="text-nowrap d-flex align-items-center p-0">
                                            <Checkbox
                                              ids="laborId" 
                                              validate={false} 
                                              disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                                              handleChange={()=>handleLaborCheckboxClick(laborItem, primaryProductId)}
                                              value={selectedLaborIds.includes(laborItem.laborItemId)}
                                            />
                                            <label className="ms-1" style={{marginTop:3 + 'px'}}>{laborItem.laborName}</label>
                                          </div>
                                        </td>
                                      : (<td className="p-0">
                                          <div className="text-nowrap d-flex align-items-center p-0">
                                            <Checkbox
                                              ids="laborId" 
                                              validate={false} 
                                              disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                                              handleChange={()=>handleLaborCheckboxClick(laborItem, primaryProductId)}
                                              value={selectedKitLaborIds.includes(laborItem.laborId)}
                                            />
                                            <label className="ms-1" style={{marginTop:3 + 'px'}}>{laborItem.laborName}</label>
                                          </div>
                                        </td>
                                      )
                                      }
                                      <td className="p-0" style={{width:90 + 'px'}}>{laborItem.currentCost}</td>
                                      <td className="p-0" style={{width:100 + 'px'}}>
                                        <Input 
                                          ids={`laborPrice`} 
                                          validate={false} 
                                          handleChange={(evt)=>handleLaborPriceChange(evt, laborItem.laborItemId, laborItem.currentCost, laborItem.kitId, laborItem.laborId)} 
                                          value={setPrice(laborItem.price)}
                                        />
                                      </td>
                                      <td className="p-0" style={{width:100 + 'px'}}>
                                        <div className="d-flex align-items-center">
                                          <Input 
                                            ids={`laborGpm`} 
                                            validate={false} 
                                            handleChange={(evt)=>handleLaborGpmChange(evt, laborItem.laborItemId, laborItem.currentCost, laborItem.kitId, laborItem.laborId)} 
                                            value={setPrice(laborItem.gpm)}
                                          /><span className="ms-2">%</span>
                                        </div>
                                      </td>
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td className="p-0" style={{width:100 + 'px'}}>
                                          <Input 
                                            ids={`assosciatedLaborTotal`} 
                                            validate={false}
                                            handleChange={(e)=>handlePrimaryProductLaborKitLineTotalChange(e, laborItem, primaryProductId)}
                                            value={laborItem.linePrice ? laborItem.linePrice : ""}
                                          />
                                        </td> : ""
                                      }
                                    </tr>
                                </Table>
                              </td> : null}
                            </tr>
                          ))}
                          {materialList && materialList.map((materialItem, materialIndex) => (
                            <tr key={materialIndex}>
                              {(subProductData === materialItem.subProductStructure && primaryProductId === INSTALLATION_ACCESSORIES_ID_DB ? !(selectedIds.includes(materialItem.productId)) : subProductData === materialItem.subProductStructure) ?
                              <td colSpan="7">
                                <Table className="subproduct-data-table2">
                                    <tr>
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td><Input 
                                          ids={`materialKitQuantity`} 
                                          validate={false}
                                          handleChange={e=>handleMaterialKitQuantityChange(e, materialItem, primaryProductId)}
                                          value={materialItem.quantity ? materialItem.quantity : ""}
                                        /></td> : ""
                                      }
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td>{materialItem.unitName}</td> : ""
                                      }
                                      <td className="p-0">
                                        <div className="text-nowrap d-flex align-items-center p-0">
                                          <Checkbox 
                                            ids="materialId" 
                                            validate={false} 
                                            disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                                            handleChange={()=>handleMaterialCheckboxClick(materialItem, primaryProductId)}
                                            value={selectedMaterialIds.includes(materialItem.materialId)}
                                          />
                                          <label className="ms-1" style={{marginTop:3 + 'px'}}>{materialItem.privateName}</label>
                                        </div>
                                      </td>
                                      <td className="p-0" style={{width:90 + 'px'}}>{materialItem.totalCurrentCost ? materialItem.totalCurrentCost : materialItem.currentCost}</td>
                                      <td className="p-0" style={{width:100 + 'px'}}>
                                        <Input 
                                          ids={`materialPrice`} 
                                          validate={false} 
                                          handleChange={(evt)=>handleMaterialPriceChange(evt, materialItem.materialId, materialItem.totalCurrentCost ? materialItem.totalCurrentCost : materialItem.currentCost)} 
                                          value={setPriceforMaterialKit(materialItem.price)} 
                                        />
                                      </td>
                                      <td className="p-0" style={{width:100 + 'px'}}>
                                        <div className="d-flex align-items-center">
                                          <Input 
                                            ids={`materialGpm`} 
                                            validate={false} 
                                            handleChange={(evt)=>handleMaterialGpmChange(evt, materialItem.materialId, materialItem.totalCurrentCost ? materialItem.totalCurrentCost : materialItem.currentCost)} 
                                            value={setPriceforMaterialKit(materialItem.gpm)} 
                                          /><span className="ms-2">%</span>
                                        </div>
                                      </td>
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td className="p-0" style={{width:100 + 'px'}}>
                                          <Input 
                                            ids={`assosciatedMaterialLineTotal`} 
                                            validate={false}
                                            handleChange={(e)=>handlePrimaryProductMaterialKitLineTotalChange(e, materialItem, primaryProductId)}
                                            value={materialItem.linePrice ? materialItem.linePrice : ""}
                                          />
                                        </td> : ""
                                      }
                                    </tr>
                                  {/* : null} */}
                                </Table>
                              </td> : null}
                            </tr>
                          ))}
                        </Table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      ) : ""}
      {/* Order kits data for secondary products */}
      {(primaryProductId !== 0 && (primaryProductId === WALLBASE_ID_DB || primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === TRANSITION_ID_DB) && materialContractModalOpen) ? (
        <div>
          <h6 className="text-center title mb-4">{materialContractModalOpen ? 'Associated sundries and labor' : 'Labor and accessory material pricing'}</h6>
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <Table responsive className="procatlist orderKits-data-table table-borderless">
                <tbody>
                  <tr>
                    <td style={{width:155 + 'px'}}></td>
                  { materialContractModalOpen || laborContractModalOpen ? <td style={{width:100 + 'px'}}>Quantity</td> : "" }
                  { materialContractModalOpen || laborContractModalOpen ? <td align="center" style={{width:50 + 'px'}}>Unit</td> : "" }
                    <td style={{width:300 + 'px'}}>Check to Select</td>
                    <td style={{width:100 + 'px'}}>Cost</td>
                    <td style={{width:100 + 'px'}}>Price</td>
                    <td style={{width:100 + 'px'}}>GPM</td>
                  { materialContractModalOpen || laborContractModalOpen ? <td style={{width:100 + 'px'}}>Line Total</td> : "" }
                  </tr>
                  {primaryProductId === WALLBASE_ID_DB || primaryProductId === INSTALLATION_ACCESSORIES_ID_DB || primaryProductId === TRANSITION_ID_DB ? 
                    <tr><td colSpan="8">Product Sub Structure</td></tr> : null
                  }
                  {subProductKitName && subProductKitName.length> 0 && subProductKitName.map((subProductData, subProductIndex) =>(
                    <tr key={subProductIndex}>
                      <td className="secondaryKit-data" colSpan="8">
                        <Table>
                          <tr><td colSpan="8">{subProductData}</td></tr>
                          {laborList && laborList.map((laborItem, laborIndex) => (
                            <tr key={laborIndex}>
                              {subProductData === laborItem.subProductStructure ?
                              <td colSpan="8">
                                <Table className="subproduct-data-table">
                                  {/* {subProductData === laborItem.subProductStructure ? */}
                                    <tr>
                                      {/* {"--"+laborItem.subProductStructure+"--"} */}
                                      <td style={{width:150 + 'px'}}>&nbsp;</td>
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td style={{width:100 + 'px'}}>
                                          <Input 
                                            ids={`laborKitQuantity`} 
                                            validate={false}
                                            value={laborItem.quantity ? laborItem.quantity : ""}
                                            handleChange={e=>handleLaborKitQuantityChange(e, laborItem, primaryProductId)}
                                          />
                                        </td> : "" 
                                      }
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td align="center" style={{width:50 + 'px'}}>{laborItem.unitName}</td> : ""
                                      }
                                      {(primaryProductId === CARPET_ID_DB || primaryProductId === SHEET_VINYL_ID_DB || primaryProductId === VINYL_PLANK_ID_DB) ?
                                        <td style={{width:300 + 'px'}} className="p-0">
                                          <div className="text-nowrap d-flex align-items-center p-0">
                                            <Checkbox
                                              ids="laborId" 
                                              validate={false} 
                                              disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                                              handleChange={()=>handleLaborCheckboxClick(laborItem, primaryProductId)}
                                              value={selectedLaborIds.includes(laborItem.laborItemId)}
                                            />
                                            <label className="ms-1" style={{marginTop:3 + 'px'}}>{laborItem.laborName}</label>
                                          </div>
                                        </td>
                                      : (<td className="p-0" style={{width:300 + 'px'}}>
                                          <div className="text-nowrap d-flex align-items-center p-0">
                                            <Checkbox
                                              ids="laborId" 
                                              validate={false} 
                                              disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                                              handleChange={()=>handleLaborCheckboxClick(laborItem, primaryProductId)}
                                              value={selectedKitLaborIds.includes(laborItem.laborId)}
                                            />
                                            <label className="ms-1" style={{marginTop:3 + 'px'}}>{laborItem.laborName}</label>
                                          </div>
                                        </td>
                                      )
                                      }
                                      <td className="p-0" style={{width:90 + 'px'}}>{laborItem.currentCost}</td>
                                      <td className="p-0" style={{width:100 + 'px'}}>
                                        <Input 
                                          ids={`laborPrice`} 
                                          validate={false} 
                                          handleChange={(evt)=>handleLaborPriceChange(evt, laborItem.laborItemId, laborItem.currentCost, laborItem.kitId, laborItem.laborId)} 
                                          value={setPrice(laborItem.price)}
                                        />
                                      </td>
                                      <td className="p-0" style={{width:100 + 'px'}}>
                                        <div className="d-flex align-items-center">
                                          <Input 
                                            ids={`laborGpm`} 
                                            validate={false} 
                                            handleChange={(evt)=>handleLaborGpmChange(evt, laborItem.laborItemId, laborItem.currentCost, laborItem.kitId, laborItem.laborId)} 
                                            value={setPrice(laborItem.gpm)}
                                          /><span className="ms-1">%</span>
                                        </div>
                                      </td>
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td className="p-0" style={{width:100 + 'px'}}>
                                          <Input 
                                            ids={`assosciatedLaborTotal`} 
                                            validate={false}
                                            handleChange={(e)=>handlePrimaryProductLaborKitLineTotalChange(e, laborItem, primaryProductId)}
                                            value={laborItem.linePrice ? laborItem.linePrice : ""}
                                          />
                                        </td> : ""
                                      }
                                    </tr>
                                </Table>
                              </td> : null}
                            </tr>
                          ))}
                          {materialList && materialList.map((materialItem, materialIndex) => (
                            <tr key={materialIndex}>
                              {subProductData === materialItem.subProductStructure ?
                              <td colSpan="8">
                                <Table className="subproduct-data-table2">
                                    <tr>
                                      <td style={{width:150 + 'px'}}>&nbsp;</td>
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td style={{width:100 + 'px'}}><Input 
                                          ids={`materialKitQuantity`} 
                                          validate={false}
                                          handleChange={e=>handleMaterialKitQuantityChange(e, materialItem, primaryProductId)}
                                          value={materialItem.quantity ? materialItem.quantity : ""}
                                        /></td> : ""
                                      }
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td align="center" style={{width:50 + 'px'}}>{materialItem.unitName}</td> : ""
                                      }
                                      <td style={{width:300 + 'px'}} className="p-0">
                                        <div className="text-nowrap d-flex align-items-center p-0">
                                          <Checkbox 
                                            ids="materialId" 
                                            validate={false} 
                                            disabled={laborContractModalOpen || materialContractModalOpen ? true : false}
                                            handleChange={()=>handleMaterialCheckboxClick(materialItem, primaryProductId)}
                                            value={selectedMaterialIds.includes(materialItem.materialId)}
                                          />
                                          <label className="ms-1" style={{marginTop:3 + 'px'}}>{materialItem.privateName}</label>
                                        </div>
                                      </td>
                                      <td className="p-0" style={{width:90 + 'px'}}>{materialItem.totalCurrentCost ? materialItem.totalCurrentCost : materialItem.currentCost}</td>
                                      <td className="p-0" style={{width:100 + 'px'}}>
                                        <Input 
                                          ids={`materialPrice`} 
                                          validate={false} 
                                          handleChange={(evt)=>handleMaterialPriceChange(evt, materialItem.materialId, materialItem.totalCurrentCost ? materialItem.totalCurrentCost : materialItem.currentCost)} 
                                          value={setPriceforMaterialKit(materialItem.price)} 
                                        />
                                      </td>
                                      <td className="p-0" style={{width:100 + 'px'}}>
                                        <div className="d-flex align-items-center">
                                          <Input 
                                            ids={`materialGpm`} 
                                            validate={false} 
                                            handleChange={(evt)=>handleMaterialGpmChange(evt, materialItem.materialId, materialItem.totalCurrentCost ? materialItem.totalCurrentCost : materialItem.currentCost)} 
                                            value={setPriceforMaterialKit(materialItem.gpm)} 
                                          /><span className="ms-2">%</span>
                                        </div>
                                      </td>
                                      { materialContractModalOpen || laborContractModalOpen ?
                                        <td className="p-0" style={{width:100 + 'px'}}>
                                          <Input 
                                            ids={`assosciatedMaterialLineTotal`} 
                                            validate={false}
                                            handleChange={(e)=>handlePrimaryProductMaterialKitLineTotalChange(e, materialItem, primaryProductId)}
                                            value={materialItem.linePrice ? materialItem.linePrice : ""}
                                          />
                                        </td> : ""
                                      }
                                    </tr>
                                </Table>
                              </td> : null}
                            </tr>
                          ))}
                        </Table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      ) : ""}
    </div>
  )
};

const mapStateToProps = ({ auth, ProductType, unit, StoreInfo, LaborCategory }) => {
  return {
    auth, ProductType, unit, StoreInfo, LaborCategory
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductType: (token) => dispatch({ type: PRODUCT_TYPE_REQUEST, token }),
    fetchUnit: (token) => dispatch({ type: UNIT_REQUEST, token }),
    fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
    fetchLaborCategory: (token) => dispatch({ type: LABOR_CATEGORY_REQUEST, token }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrimaryProductList);