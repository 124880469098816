import React, { Fragment, useEffect, useState } from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import { connect } from 'react-redux';
import { STORE_INFO_IMPORT_CUSTOMER_REQUEST } from "../../../../_utils/constants/admin/StoreInfo";
import { toast } from 'react-toastify';

const UnitMix = ({
		auth: { auth },
		StoreInfo: { dataImportLoading, isDataImported, successfulImportedRecords, duplicatedImportedRecords },
		unitMixModalOpen,
		hideUnitMixModal,
		handleUnitMixFile,
		handleNewImportFile,
		submitUnitMixModel,
		importedFileData,
		importedFileMessageData,
		saveFileMessageData
	}) => {

	// states declarations
	const [unitMix, setUnitMix] = useState();
	const [successData, setSuccessData] = useState();
	const [openWarningModal, setOpenWarningModal] = useState(false);
	const [isEnableSave, setIsEnableSave] = useState(false);
	// const [isDuplicate, setIsDuplicate] = useState(false);
	const [check, setCheck] = useState(false);

	const handleModifiedFileData = (importedFileData) => {
		let uniqueUnitType = _.uniqBy(importedFileData, "UnitType")
		
		if(importedFileData.length > 0 && uniqueUnitType.length > 0) {
			let modifiedFileDataObj = {}
			let modifiedFileDataArr = []
			importedFileData.map(value => {
				uniqueUnitType.map(data => {
					if(value?.UnitType && data?.UnitType && value?.UnitType === data?.UnitType) {
						modifiedFileDataArr.push(value)
						modifiedFileDataObj[value?.UnitType] = modifiedFileDataArr 
					}
				})
			})
			let newArrayDataOfObject = Object.entries(modifiedFileDataObj)
			if(newArrayDataOfObject.length > 0) {
				newArrayDataOfObject.map(data => {
					let filteredArray = data[1].filter(value => value?.UnitType === data[0] && value?.UnitType);
					let modifiedArray = []
					filteredArray.map(data => {
						if(data.UnitName) {
							modifiedArray.push(data)
						}
					})
					data[1] =  modifiedArray
				})
			}
			setUnitMix(newArrayDataOfObject)
		}
	}

	useEffect(() => {
	  if(importedFileData) {
			setCheck(false)
	  	toast.success("File imported successfully.");
	    // setUnitMix(importedFileData)
			handleModifiedFileData(importedFileData)
	  } else {
	  	setUnitMix()
	  }
	}, [importedFileData])

	useEffect(() => {
	  if(importedFileMessageData) {
	  	toast.error(importedFileMessageData?.errorMessage);
	  	if(importedFileMessageData?.errorMessage) {
	  		setUnitMix()
	  	}
	  }
	}, [importedFileMessageData])

	// Function to change unit mix data according to api
	const handleUnitMixDataForApi = (data) => {
		let tempData = _.cloneDeep(unitMix)
		let tempArray = []
		tempData.map((val, i) => {
			tempArray.push(val[1])
		})
		return tempArray.flat()
	}

	useEffect(() => {
		if(saveFileMessageData) {
			setSuccessData(saveFileMessageData)
			toast.success(saveFileMessageData?.totalSuccess + " File imported successfully.");
			let unitMixValue = handleUnitMixDataForApi(unitMix)
			setUnitMix(unitMixValue)
		} else {
			setSuccessData()
		}
	}, [saveFileMessageData])

	useEffect(() => {
		if (unitMix && unitMix.length > 0 && check) {
			let unitMixValue = handleUnitMixDataForApi(unitMix)
			let emptyCount = 0
			for (let data of unitMixValue) {
				if (data.UnitType === "") {
					emptyCount +=1
				}
			}
			if (emptyCount > 0) {
				toast.error("Unit type cannot be empty.")
				setIsEnableSave(true)
			} else {
				setIsEnableSave(false)
			}
		}
	}, [unitMix])

	const handleUnitMixChange = (e, data, index, filtervalue) => {
		setCheck(true)
		if(filtervalue === "unitName") {
			unitMix.map((val, i) => {
				if(i===index) {
					unitMix[i][0] = e.target.value
					let tempArr = Object.values(unitMix)
					setUnitMix(tempArr)
					if(val[1] && val[1].length > 0) {
						val[1].map((data, index) => {
							unitMix[i][1][index].UnitType = e.target.value
							let tempUnitArr = Object.values(unitMix)
							setUnitMix(tempUnitArr)
						})
					}
				}
			})
		}
		if(filtervalue === "unitType") {
			if(e.target.value.length < 1) {
				toast.error("Data once removed and saved will not be accessible any more. Are you sure you want to remove?");
			}
			let tempArr = _.cloneDeep(unitMix)
			tempArr.map((val, i) => {
				if(i===index) {
					if(val[1] && val[1].length > 0) {
						val[1].map((value, key) => {
							if(key === data) {
								val[1][key].UnitName = e.target.value
								tempArr = Object.values(tempArr)
								setUnitMix(tempArr)
							}
						})
					}
				}
			})
		}
	}

	const handleunitSaveClick = (e) => {
		setCheck(false)
		let unitMixValue = handleUnitMixDataForApi(unitMix)
		let filteredList = unitMixValue.filter(data => data.UnitName)
		handleUnitMixFile(filteredList)
	}
	const handleImportNewClick = () => {
		setOpenWarningModal(true)
	}
	const handleWarningModalOpen = () => {
		setOpenWarningModal(true)
	}
	const handleWarningModalClose = () => {
		setOpenWarningModal(false)
	}
	const handleOverwriteConfirmation = () => {
		setOpenWarningModal(false)
		handleNewImportFile()
	}
	return(
		<Fragment>
			<Modal 
				show={unitMixModalOpen}
				size="md"
			>
				<Form>
					<Modal.Header closeButton onClick={hideUnitMixModal}>
						<Modal.Title>Unit Mix</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{!_.isEmpty(successData) ? 
							<div>
								<div>TotalDuplicate: {successData?.totalDuplicate}</div>
								<div>TotalSuccess: {successData?.totalSuccess}</div>
							</div> 
							:
						<div> 
						<div className="overflow-auto unit-mix-table-block" style={{ maxHeight: '500px'}}>
							<Table responsive bordered>
								<thead>
									<tr>
										<th className="w-50">Unit Type</th>
										<th className="w-50">Unit Number</th>
									</tr>
								</thead>
								<tbody>
								{unitMix && _.map(unitMix, (res, i) => (
									<tr key={i}>
										<td>
											<input 
												className="form-control form-control-sm"
												value={res[0]}
												onChange={e=>handleUnitMixChange(e, res, i, "unitName")} 
											/>
										</td>
										<td className="p-0">
										<div className="unit-number-list small p-2">
										{res[1] && res[1].length > 0 && res[1].map((data, key) => (
											<div key={key}>
												<input 
													className="form-control form-control-sm unit-number-box" 
													value={data?.UnitName} 
													onChange={e=>handleUnitMixChange(e, key, i, "unitType")} 
												/>
											</div>
										))}
										</div>
										</td>
								</tr>
								))}
								</tbody>
							</Table>
						</div>
						{/* <div>Data imported successfully - {importedFileMessageData?.totalSuccess}</div>
						<div>Duplicate data found - {importedFileMessageData?.totalDuplicate}</div> */}
						</div>
					}
					</Modal.Body>
					<Modal.Footer>
						{!isEnableSave && 
							<Button variant="primary" onClick={e=>handleunitSaveClick(e)}>
								Save
							</Button>
						}
						<Button variant="secondary" onClick={hideUnitMixModal}>
							Cancel
						</Button>
						{!_.isEmpty(successData) ? "" :
							<Button variant="primary" onClick={handleImportNewClick}>
								Import New
							</Button>
						}
					</Modal.Footer>
				</Form>
			</Modal>
			{ openWarningModal ?  
				<Modal id="contract-confirmation" show={handleWarningModalOpen} size="md">
					<Modal.Header closeButton onClick={handleWarningModalClose}>
						<Modal.Title>Warning</Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center">
						<h5 className="title my-3">It will overwrite and erase the previous unit mix.</h5>
						<p>Are you sure to proceed?</p>
					</Modal.Body>
					<Modal.Footer className="justify-content-center">
						<Button variant="primary" onClick={handleOverwriteConfirmation}>Yes</Button>
						<Button variant="secondary" onClick={handleWarningModalClose}>No</Button>
					</Modal.Footer>
				</Modal>
				: ""
			}
		</Fragment>
	)
}

const mapStateToProps = ({ auth, StoreInfo }) => {
	return {
		auth,
		StoreInfo
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		submitImportCustomer: (data) => dispatch({ type: STORE_INFO_IMPORT_CUSTOMER_REQUEST, data })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitMix);