import React from "react";
import { Routes, Route } from "react-router-dom";
import StoreList from "../components/pages/admin/storeinfo/StoreList";
{/* Commented for removing roll functionality */}
// import ContactRollList from "../components/pages/admin/contactroll/ContactRollList";
import EmployeeList from "../components/pages/admin/employee/EmployeeList";
import RoleList from "../components/pages/admin/role/RoleList";
import CustomerApproval from "../components/pages/admin/customerapproval/CustomerApproval";
import SetPassword from "../components/pages/admin/setPassword/setPassword";

const AdminRoute = () => {
  return (
    <Routes>
      <Route path="/admin/product-catalog/store-list" exact element={<StoreList />} />
      {/* Commented for removing roll functionality */}
      {/* <Route path="/admin/Roll/category" exact element={<ContactRollList />} /> */}
      <Route path="/employee" exact element={<EmployeeList />} />
      <Route path="/role" exact element={<RoleList />} />
      <Route path="/customer-approval" exact element={<CustomerApproval />} />
      <Route path="/setPassword" exact element={<SetPassword/>} />
    </Routes>
  );
};

export default AdminRoute;