import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateProductPriceDetail";
import ConfirmDelete from "./models/ConfirmDelete";
import Edit from "./models/EditProductPriceDetail";
import {
  PRODUCT_PRICE_DETAIL_REQUEST,
  PRODUCT_PRICE_DETAIL_CREATE,
  PRODUCT_PRICE_DETAIL_DELETE,
  PRODUCT_PRICE_DETAIL_EDIT,
  PRODUCT_PRICE_DETAIL_STATE_CHANGE,
  PRODUCT_PRICE_REQUEST,
} from "../../../../_utils/constants/ProductCatalog";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const ProductPriceDetail = ({
  auth: { auth, isAuthenticated },
  ProductPriceDetail: { valueList, isCreated, isDeleted, isEdited, isLoading },
  ProductPrice,
  fetchProductPrice,
  fetchProductPriceDetail,
  submitProductPriceDetailCreate,
  submitProductPriceDetailDelete,
  submitProductPriceDetailEdit,
  changeReducerState,

}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [salesProductPriceDetailName, setProductPriceDetailName] = useState('');
  const [sortOrder, setSortOrder] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteProductPriceDetailId, setDeleteProductPriceDetailId] = useState('');
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState("");
  const [state, setState] = useState({
    "productPriceId": "",
    "startCost": "",
    "finish": "",
    "cost": "",
    "lastCost": "",
    "futureCost": "",
    "futureDate": "",
    "loads": "",
    "promoBegin": "",
    "promotionCost": "",
    "frieght1": "",
    "frieght2": "",
    "tax": "",
    "finalCost": "",
    "sell": "",
    "gpm": ""
  })
  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchProductPriceDetail(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      fetchProductPrice(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("ProductPriceDetail Created Successfully.");
      fetchProductPriceDetail(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("ProductPriceDetail Deleted Successfully.");
      fetchProductPriceDetail(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("ProductPriceDetail Data Updated Successfully.");
      fetchProductPriceDetail(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value })

  }

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
    setShow(false)
    setIds("");
    setState({
      "productPriceId": "",
      "startCost": "",
      "finish": "",
      "cost": "",
      "lastCost": "",
      "futureCost": "",
      "futureDate": "",
      "loads": "",
      "promoBegin": "",
      "promotionCost": "",
      "frieght1": "",
      "frieght2": "",
      "tax": "",
      "finalCost": "",
      "sell": "",
      "gpm": ""
    })
  };

  // function called for input value change of create modal
  const handleSalesStageNameChange = (e) => {
    setProductPriceDetailName(e.target.value);
  }
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (state.startCost === '') {
      toast.error("StartCost cannot be empty.");
    } else {
      submitProductPriceDetailCreate({
        "data": {
          "ProductPriceId": parseInt(state.productPriceId),
          "StartCost": parseFloat(state.startCost),
          "Finish": parseFloat(state.finish),
          "Cost": parseFloat(state.cost),
          "LastCost": parseFloat(state.lastCost),
          "FutureCost": parseFloat(state.futureCost),
          "FutureDate": state.futureDate,
          "Loads": parseFloat(state.loads),
          "PromoBegin": state.promoBegin,
          "PromotionCost": parseFloat(state.promotionCost),
          "Frieght1": parseFloat(state.frieght1),
          "Frieght2": parseFloat(state.frieght2),
          "Tax": parseFloat(state.tax),
          "FinalCost": parseFloat(state.finalCost),
          "Sell": parseFloat(state.sell),
          "CreatedBy": auth[0].userId
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteProductPriceDetailId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteProductPriceDetailId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitProductPriceDetailDelete({
      "ProductPriceDetailId": deleteProductPriceDetailId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setState(data);
    setIds(data.ProductPriceDetailId);
    setShow(true)
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (state.startCost === '') {
      toast.error("StartCost cannot be empty.");
    } else {
      submitProductPriceDetailEdit({
        "data": {
          "ProductPriceDetailId": ids,
          "ProductPriceId": parseInt(state.productPriceId),
          "StartCost": parseFloat(state.startCost),
          "Finish": parseFloat(state.finish),
          "Cost": parseFloat(state.cost),
          "LastCost": parseFloat(state.lastCost),
          "FutureCost": parseFloat(state.futureCost),
          "FutureDate": state.futureDate,
          "Loads": parseFloat(state.loads),
          "PromoBegin": state.promoBegin,
          "PromotionCost": parseFloat(state.promotionCost),
          "Frieght1": parseFloat(state.frieght1),
          "Frieght2": parseFloat(state.frieght2),
          "Tax": parseFloat(state.tax),
          "FinalCost": parseFloat(state.finalCost),
          "Sell": parseFloat(state.sell),
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>ProductPriceDetail</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`ProductPriceDetail`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              salesStageName={salesProductPriceDetailName}
              handleSalesStageNameChange={handleSalesStageNameChange}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
              handleChange={handleChange}
              state={state}
              ProductPrice={ProductPrice.valueList}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>StartCost</th>
                <th className="text-center">Finish</th>
                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (

                  <tr key={index}>
                    <td>
                      {item.startCost}
                    </td>
                    <td width="150">
                      {item.finish}
                    </td>
                    <td className="actions">
                      <div className="btn-group">
                        <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                        <ConfirmDelete variant="primary" title={`ProductPriceDetail`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.productPriceDetailId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                      </div>
                    </td>
                  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
      <Edit
        state={state}
        handleChange={handleChange}
        handleEditSaveClick={handleEditSaveClick}
        show={show}
        hideCreateModal={hideCreateModal}
        title={`ProductPriceDetail`}
        ProductPrice={ProductPrice.valueList}
      />
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, ProductPrice, ProductPriceDetail }) => {
  return {
    auth,
    ProductPriceDetail,
    ProductPrice,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductPriceDetail: (token) => dispatch({ type: PRODUCT_PRICE_DETAIL_REQUEST, token }),
    submitProductPriceDetailCreate: (data) => dispatch({ type: PRODUCT_PRICE_DETAIL_CREATE, payload: data }),
    submitProductPriceDetailDelete: (data) => dispatch({ type: PRODUCT_PRICE_DETAIL_DELETE, payload: data }),
    submitProductPriceDetailEdit: (data) => dispatch({ type: PRODUCT_PRICE_DETAIL_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: PRODUCT_PRICE_DETAIL_STATE_CHANGE }),
    fetchProductPrice: (token) => dispatch({ type: PRODUCT_PRICE_REQUEST, token }),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceDetail);