export const REGISTER_USER_REQUEST = "@REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "@REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "@REGISTER_USER_FAILED";

export const LOGIN_USER_REQUEST = "@LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "@LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "@LOGIN_USER_FAILED";

export const FORGOT_PASSWORD_REQUEST = "@FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "@FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "@FORGOT_PASSWORD_FAILED";

export const LOGOUT_REQUEST = '@LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@LOGOUT_SUCCESS';

export const SESSION_EXPIRED = '@SESSION_EXPIRED';

export const ORDER_USER_REQUEST = "@ORDER_USER_REQUEST";
export const ORDER_USER_SUCCESS = "@ORDER_USER_SUCCESS";
export const ORDER_USER_FAILED = "@ORDER_USER_FAILED";

export const AUTH_STATE_CHANGE = "@AUTH_STATE_CHANGE";

export const USER_ALREADY_EXIST_REQUEST = "@USER_ALREADY_EXIST_REQUEST";
export const USER_ALREADY_EXIST_SUCCESS = "@USER_ALREADY_EXIST_SUCCESS";
export const USER_ALREADY_EXIST_FAILED = "@USER_ALREADY_EXIST_FAILED";

export const USER_ALREADY_EXIST_STATE_CHANGE = "@USER_ALREADY_EXIST_STATE_CHANGE";