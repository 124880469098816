import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  PHONE_REQUEST,
  PHONE_SUCCESS,
  PHONE_FAILED,
  PHONE_CREATE,
  PHONE_CREATE_SUCCESS,
  PHONE_CREATE_FAILED,
  PHONE_DELETE,
  PHONE_DELETE_SUCCESS,
  PHONE_DELETE_FAILED,
  PHONE_EDIT,
  PHONE_EDIT_SUCCESS,
  PHONE_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * Phone Actions
 */

function* fetchPhone({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/Phone/GetAllPhone`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: PHONE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PHONE_FAILED, payload: error.response });
    }
  }
}

function* createPhone(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/Phone/CreatePhone`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PHONE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PHONE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editPhone(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/Product/api/Phone/UpdatePhone`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PHONE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PHONE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deletePhone(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/Phone/RemovePhone?PhoneId=${encodeURIComponent(action.payload.PhoneId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: PHONE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PHONE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootPhoneList() {
  yield all([
    takeLatest(PHONE_REQUEST, fetchPhone),
    takeLatest(PHONE_CREATE, createPhone),
    takeLatest(PHONE_DELETE, deletePhone),
    takeLatest(PHONE_EDIT, editPhone),
  ]);
}