import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateProduct";
import ConfirmDelete from "./models/ConfirmDelete";
import Edit from "./models/EditProduct";
import {
  PRODUCT_REQUEST,
  PRODUCT_CREATE,
  PRODUCT_DELETE,
  PRODUCT_EDIT,
  PRODUCT_STATE_CHANGE,
  PRODUCT_INFO_REQUEST,

} from "../../../../_utils/constants/ProductCatalog";

import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const Product = ({
  auth: { auth, isAuthenticated },
  Product: { valueList, isCreated, isDeleted, isEdited, isLoading },
  ProductInfo,
  fetchProduct,
  submitProductCreate,
  submitProductDelete,
  submitProductEdit,
  changeReducerState,
  fetchProductInfo,
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [salesProductName, setProductName] = useState('');
  const [sortOrder, setSortOrder] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState('');
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState("");
  const [state, setState] = useState({
    "colors": "",
    "privateColor": "",
    "colorNumber": "",
    "sku": "",
    "alternateId": "",
    "slot": "",
    "dropingColor": "",
    "glaccountId": "",
    "productInfoId": "",
  }
  )
  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchProduct(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      fetchProductInfo(auth[0].token);
    }
  }, [auth, isAuthenticated]);


  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("Product Created Successfully.");
      fetchProduct(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("Product Deleted Successfully.");
      fetchProduct(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("Product Data Updated Successfully.");
      fetchProduct(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value })

  }

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
    setShow(false)
    setIds("");
    setState({
      "colors": "",
      "privateColor": "",
      "colorNumber": "",
      "sku": "",
      "alternateId": "",
      "slot": "",
      "dropingColor": "",
      "glaccountId": "",
      "productInfoId": "",
    })
  };

  // function called for input value change of create modal
  const handleSalesStageNameChange = (e) => {
    setProductName(e.target.value);
  }
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (state.colors === '') {
      toast.error("Colors cannot be empty.");
    } else {
      submitProductCreate({
        "data": {
          "Colors": state.colors,
          "PrivateColor": state.privateColor,
          "ColorNumber": state.colorNumber,
          "Sku": state.sku,
          "AlternateId": state.alternateId,
          "Slot": state.slot,
          "DropingColor": state.dropingColor,
          "GlaccountId": parseInt(state.glaccountId),
          "ProductInfoId": parseInt(state.productInfoId),
          "CreatedBy": auth[0].userId,
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteProductId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteProductId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitProductDelete({
      "ProductId": deleteProductId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setState(data);
    setIds(data.productId);
    setShow(true)
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (state.colors === '') {
      toast.error("Colors cannot be empty.");
    } else {

      submitProductEdit({
        "data": {
          "ProductId": ids,
          "Colors": state.colors,
          "PrivateColor": state.privateColor,
          "ColorNumber": state.colorNumber,
          "Sku": state.sku,
          "AlternateId": state.alternateId,
          "Slot": state.slot,
          "DropingColor": state.dropingColor,
          "GlaccountId": parseInt(state.glaccountId),
          "ProductInfoId": parseInt(state.productInfoId),
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>Product</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`Product`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              salesStageName={salesProductName}
              handleSalesStageNameChange={handleSalesStageNameChange}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
              handleChange={handleChange}
              state={state}
              ProductInfo={ProductInfo.valueList}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Colors</th>
                <th className="text-center">PrivateColor</th>
                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (

                  <tr key={index}>
                    <td>
                      {item.colors}
                    </td>
                    <td width="150">
                      {item.privateColor}
                    </td>
                    <td className="actions">
                      <div className="btn-group">
                        <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                        <ConfirmDelete variant="primary" title={`Product`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.productId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                      </div>
                    </td>
                  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
      <Edit
        state={state}
        handleChange={handleChange}
        handleEditSaveClick={handleEditSaveClick}
        show={show}
        hideCreateModal={hideCreateModal}
        title={`Product`}
        ProductInfo={ProductInfo.valueList}
      />
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, ProductInfo, Product }) => {
  return {
    auth,
    Product,
    ProductInfo
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductInfo: (token) => dispatch({ type: PRODUCT_INFO_REQUEST, token }),
    fetchProduct: (token) => dispatch({ type: PRODUCT_REQUEST, token }),
    submitProductCreate: (data) => dispatch({ type: PRODUCT_CREATE, payload: data }),
    submitProductDelete: (data) => dispatch({ type: PRODUCT_DELETE, payload: data }),
    submitProductEdit: (data) => dispatch({ type: PRODUCT_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: PRODUCT_STATE_CHANGE }),


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Product);