import React, { useState, memo, useEffect, useRef } from "react";
import Sidebar from "./layouts/Sidebar";
import Header from "./layouts/Header";

const MainWrapper = memo(({ children }) => {
  const [isActive, setActive] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (isActive) {
          setActive(false)
        }
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const handleIsActive = (bool) => {
    setActive(bool);
  };

  return (
    <div className={`wrapper ${isActive ? "open" : ""}`}>
      <Sidebar handleIsActive={handleIsActive} isActive={isActive} />
      <div className="asideRight" ref={menuRef}>
        <Header handleIsActive={handleIsActive} isActive={isActive} />
        {children}
      </div>
    </div>
  );
});

export default MainWrapper;
