import React, { Fragment, useEffect } from "react";
import MainWrapper from "../../MainWrapper";
import '../../../assets/css/Main.css';
import { Row, Col, Card, Table } from 'react-bootstrap';
import contacticon from '../../../assets/images/contact-icon.png';
import proposalicon from '../../../assets/images/proposal-icon.png';
import ordersicon from '../../../assets/images/orders-icon.png';
import invoiceicon from '../../../assets/images/invoice-icon.png';
import reporticon from '../../../assets/images/report-icon.png';
import { connect } from 'react-redux';
import Loader from "../../layouts/Loader";
import { toast } from 'react-toastify';
import { ORDER_USER_REQUEST } from "../../../_utils/constants/Auth";
import { Navigate } from "react-router-dom";

const Dashboard = ({ auth: { isAuthenticated } }) => {

  useEffect(() => {
    let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))
    if (isAuthenticated && isLoggedIn) {
      toast.success("Login success.")
      localStorage.setItem('isLoggedIn', false)
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Fragment>
        <Loader loading={false}>
          <Row className="nav-cards mb-4">
            <Col>
              <Card>
                <Card.Body className="d-flex align-items-center">
                  <div className="info">
                    <h5 className="title mb-0">Contacts</h5>
                    <h1 className="mb-0">500</h1>
                  </div>
                  <Card.Img variant="top" className="icon" src={contacticon} />
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body className="d-flex align-items-center">
                  <div className="info">
                    <h5 className="title mb-0">Proposals</h5>
                    <h1 className="mb-0">500</h1>
                  </div>
                  <Card.Img variant="top" className="icon" src={proposalicon} />
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body className="d-flex align-items-center">
                  <div className="info">
                    <h5 className="title mb-0">Orders</h5>
                    <h1 className="mb-0">500</h1>
                  </div>
                  <Card.Img variant="top" className="icon" src={ordersicon} />
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body className="d-flex align-items-center">
                  <div className="info">
                    <h5 className="title mb-0">Invoices A/R</h5>
                    <h1 className="mb-0">500</h1>
                  </div>
                  <Card.Img variant="top" className="icon" src={invoiceicon} />
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body className="d-flex align-items-center">
                  <div className="info">
                    <h5 className="title mb-0">Reports</h5>
                    <h1 className="mb-0">500</h1>
                  </div>
                  <Card.Img variant="top" className="icon" src={reporticon} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <h4 className="title">To Do</h4>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th>Due Date</th>
                <th>Due Time</th>
                <th>Text</th>
                <th>Created Form</th>
                <th>Sold To</th>
                <th>Ship To</th>
                <th>Job Description</th>
                <th>Next Action</th>
                <th>Completed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          <h4 className="title">Job Mail/Alerts</h4>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th></th>
                <th>Type</th>
                <th>Date</th>
                <th>Priority</th>
                <th>Action Required</th>
                <th>From</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>

          <Row>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <h5 className="title">Bank Statment Balance</h5>
                  <Table bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Account</th>
                        <th>Account Name</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body className="d-flex align-items-center">
                  <h5 className="title">Cash Collected Today</h5>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Loader>
      </Fragment>

    </MainWrapper>
  );
}
const mapStateToProps = ({ auth }) => {
  return {
    auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCookie: (data) => dispatch({ type: ORDER_USER_REQUEST, data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);