import React, { Fragment, memo } from "react";
import { Modal, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateFormValue = ({
  title,
  createModalOpen,
  showCreateModal,
  hideCreateModal,
  state,
  handleChange,
  handleSubmit
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} >
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="ValueList">
              <Form.Label>Value List Type</Form.Label>
              <Form.Control type="text" name="ValueList" value={state.ValueList} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="ValueListItemHeader">
              <Form.Label>Value List Header</Form.Label>
              <Form.Control type="text" name="ValueListItemHeader" value={state.ValueListItemHeader} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Field1">
              <Form.Label>Field 1</Form.Label>
              <Form.Control type="text" name="Field1" value={state.Field1} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Field2">
              <Form.Label>Field 2</Form.Label>
              <Form.Control type="text" name="Field2" value={state.Field2} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Field3">
              <Form.Label>Field 3</Form.Label>
              <Form.Control type="text" name="Field3" value={state.Field3} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Field4">
              <Form.Label>Field 4</Form.Label>
              <Form.Control type="text" name="Field4" value={state.Field4} onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Field5">
              <Form.Label>Field 5</Form.Label>
              <Form.Control type="text" name="Field5" value={state.Field5} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default memo(CreateFormValue);