import {
  PRODUCT_CATALOG_COPY_PRODUCT,
  PRODUCT_CATALOG_COPY_PRODUCT_SUCCESS,
  PRODUCT_CATALOG_COPY_PRODUCT_FAILED
} from "../../../_utils/constants/ProductCatalog";
import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";

function* copyProduct(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/product/api/product/CopyProductToStore`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_CATALOG_COPY_PRODUCT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_CATALOG_COPY_PRODUCT_FAILED,
        payload: error.response
      });
    }
  }
}


export default function* rootCopyProduct() {
  yield all([
    takeLatest(PRODUCT_CATALOG_COPY_PRODUCT, copyProduct)
  ]);
}