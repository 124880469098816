import React, { Fragment, useMemo } from "react";
import { Table } from 'react-bootstrap';
import Icon from "react-icons-kit";
import { ic_mode_edit } from 'react-icons-kit/md';
import ConfirmDelete from "../../../elements/ConfirmDelete";
import Select from "../../../elements/CustomSelect";
import {
  WALLBASE_ID_DB,
  INSTALLATION_ACCESSORIES_ID_DB,
  TRANSITION_ID_DB
} from "../../../../_utils/constants/ProductCatalog";

const KitTable = ({
  productStructure,
  storeList,
  kitList,
  handleListChange,
  showConfirmDeleteModal,
  hideConfirmDeleteModal,
  handleConfirmDeleteSubmit,
  confirmDeleteModal,
  handleEdit,
  productSubStructure,
  listFilter
}) => {

  // handle show sub product list
  const showSubProductList = useMemo(() => {
    if(listFilter && (parseInt(listFilter.ProductTypeId) === WALLBASE_ID_DB || parseInt(listFilter.ProductTypeId) === INSTALLATION_ACCESSORIES_ID_DB || parseInt(listFilter.ProductTypeId) === TRANSITION_ID_DB)) {
      return true
    } else {
      return false
    }
  }, [listFilter])
  return (
    <Fragment>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>
              <Select label={`Store Name`} result={storeList} ids="StoreId" handleChange={handleListChange} value={listFilter.StoreId} validate={false} />
            </th>
            <th>
              <Select label={`Product Structure`} result={productStructure} handleChange={handleListChange} value={listFilter.ProductTypeId} ids="ProductTypeId" validate={false} />
            </th>
            <th>
              <Select label={`Sub Product Structure`} result={showSubProductList ? productSubStructure : []} handleChange={handleListChange} value={listFilter.subProductTypeId} ids="subProductTypeId" validate={false} />
            </th>
            <th width="50" className="actions">
              <div className="d-flex">
                <span>Edit</span>
                <span>Delete</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {kitList ? kitList.map((k, i) => (
            <tr key={i}>
              <td>{k.storeName}</td>
              <td>{k.productStructure}</td>
              <td>{k.subProductStructure}</td>
              <td>
                <div className="btn-group">
                  <Icon
                    role="button"
                    className="w-54 d-flex justify-content-center"
                    size={18}
                    icon={ic_mode_edit}
                    onClick={(e) => handleEdit(e, k)}
                  />
                  <ConfirmDelete
                    variant="primary"
                    title={`Kit`}
                    showConfirmDeleteModal={() => showConfirmDeleteModal(k.kitId)}
                    hideConfirmDeleteModal={hideConfirmDeleteModal}
                    handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
                    confirmDeleteModal={confirmDeleteModal}
                  />
                </div>
              </td>
            </tr>
          )) : null}

        </tbody>
      </Table>

    </Fragment>
  )
}

export default KitTable