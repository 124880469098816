import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  LABOR_CATEGORY_REQUEST,
  LABOR_CATEGORY_SUCCESS,
  LABOR_CATEGORY_FAILED,
  LABOR_CATEGORY_CREATE,
  LABOR_CATEGORY_CREATE_SUCCESS,
  LABOR_CATEGORY_CREATE_FAILED,
  LABOR_CATEGORY_DELETE,
  LABOR_CATEGORY_DELETE_SUCCESS,
  LABOR_CATEGORY_DELETE_FAILED,
  LABOR_CATEGORY_EDIT,
  LABOR_CATEGORY_EDIT_SUCCESS,
  LABOR_CATEGORY_EDIT_FAILED,
} from "../../../_utils/constants/Labor";

function* fetchLaborCategory(action) {

  try {
    const response = yield call(axios.get, `${API_URL}/Labor/api/LaborCategory/GetLaborCategory`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: LABOR_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: LABOR_CATEGORY_FAILED, payload: error.response });
    }
  }
}
function* createLaborCategory(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Labor/api/LaborCategory/CreateLaborCategory`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: LABOR_CATEGORY_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LABOR_CATEGORY_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editLaborCategory(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/Labor/api/LaborCategory/UpdateLaborCategory`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: LABOR_CATEGORY_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LABOR_CATEGORY_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteLaborCategory(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Labor/api/LaborCategory/RemoveLaborCategory?laborCategoryId=${encodeURIComponent(action.payload.laborCategoryId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: LABOR_CATEGORY_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LABOR_CATEGORY_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
export default function* rootLaborCategory() {
  yield all([
    takeLatest(LABOR_CATEGORY_REQUEST, fetchLaborCategory),
    takeLatest(LABOR_CATEGORY_CREATE, createLaborCategory),
    takeLatest(LABOR_CATEGORY_DELETE, deleteLaborCategory),
    takeLatest(LABOR_CATEGORY_EDIT, editLaborCategory),
  ])
}