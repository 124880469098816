import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import AddEditContractForm from "./AddEditContractForm";
import GroupCustomer from "./GroupCustomer";
import { connect } from "react-redux";
import "../pricingContract.css";
import { SALES_PERSON_CATEGORY_REQUEST } from "../../../../_utils/constants/SalesPerson";
import { STORE_INFO_REQUEST } from "../../../../_utils/constants/admin/StoreInfo";
import { toast } from 'react-toastify';
import PricingContract from "../../customer/models/PricingContract";
import { GROUP_PRICING_CONTRACT_CREATE_REQUEST, GROUP_PRICING_CONTRACT_STATE_CHANGE, GROUP_PRICING_CONTRACT_SAVE_PROPERTY_REQUEST } from "../../../../_utils/constants/PricingContract";

const CreatePricingContract = ({
	createPricingContractModalOpen,
	auth: { auth, isAuthenticated },
	pricingContract: { isGroupPricingContractCreated, groupPricingContractId, groupErrors, errorFlag, isProcessing, groupPCPropertyId },
	SalesPersonCategory: { salesPersonCategory },
	StoreInfo: { storeInfo },
	showCreatePricingContractModal,
	hideCreatePricingContractModal,
	CreateGroupPricingContractRequest,
	fetchSalesPersonCategory,
	changeGroupReducerState,
	fetchStoreInfo,
	editPricingContractModalOpen,
	groupContractId,
	pricingContractSummaryData,
	SaveGroupPCSaveProperty
}) => {
	
	// states declaration
	const [contractPageName, setContractPageName] = useState('AddGrpContact');
	const [contractName, setContractName] = useState('');
	const [salesPersonId, setSalesPersonId] = useState('');
	const [storeId, setStoreId] = useState(0);
	const [groupContractCustomerId, setGroupContractCustomerId] = useState([]);
	const [pricingContractModalOpen, setpricingContractModalOpen] = useState(true);
	const [isSummaryContactsInfo, setIsSummaryContactsInfo] = useState(false);
	const [isContractConfirmationModal, setIsContractConfirmationModal] = useState(false);
	const [confirmedState, setConfirmedState] = useState();
	const [tempSalesPersonId, setTempSalesPersonId] = useState();

	// function called on page load to load store and sales person category data
	useEffect(() => {
    if (isAuthenticated) {
			fetchStoreInfo(auth[0].token);
			fetchSalesPersonCategory(auth[0].token);
    }
  }, [isAuthenticated, auth])

	// function on store data response to get store id of denver
	useEffect(() => {
		if (storeInfo.length > 0) {
			storeInfo.map((store) => {
				if (store.storeName === "Denver") {
					setStoreId(store.storeId);
				}
			})
		}
	}, [storeInfo])

	// use effect function called after api success
  useEffect(() => {
    if (isGroupPricingContractCreated === true) {
      changeGroupReducerState();
    }
  }, [isGroupPricingContractCreated, auth]);

	useEffect(() => {
		if(editPricingContractModalOpen) {
			setContractName(editPricingContractModalOpen.contractName)
			setSalesPersonId(editPricingContractModalOpen.salesPersonId)
			setTempSalesPersonId(editPricingContractModalOpen.salesPersonId)
		} else {
			setContractName("")
			setSalesPersonId("")
		}
	}, [editPricingContractModalOpen])

	useEffect(() => {
		if(pricingContractSummaryData && pricingContractSummaryData[0]) {
			setGroupContractCustomerId(pricingContractSummaryData[0].contactInfoIds)
			if(pricingContractSummaryData[0].contactInfoIds.length > 0) {
				setIsSummaryContactsInfo(true)
			}
		}
	}, [pricingContractSummaryData])

	useEffect(() => {
		if(_.isEmpty(groupErrors) && !errorFlag && isGroupPricingContractCreated) {
			setContractPageName("CustomerList");
    }
		if(errorFlag) {
			if (groupErrors.data && groupErrors.data.result && groupErrors.data.result.length > 0) {
				toast.error(groupErrors.data.result[0]);
			} else if (groupErrors.data?.title) {
				toast.error(groupErrors.data.title);
			} else {
				toast.error('Error');
			}
    }
	}, [groupErrors, errorFlag])

	useEffect(() => {
		if (groupPCPropertyId && groupPCPropertyId.length > 0) {
			if (contractPageName !== 'PricingContract') {
				toast.success(groupPCPropertyId[0])
			}
		}
	}, [groupPCPropertyId])

	// function to open pricing contract modals flow
	const showPricingContractModal = () => {
		setpricingContractModalOpen(true);
	}

	// function to close pricing contract modals flow
	const hidePricingContractModal = () => {
		setpricingContractModalOpen(false);
		setContractPageName('AddGrpContact');
		setSalesPersonId('');
		setContractName('');
	}

	// function to manage salesperson list data fetched
	const salesPersonList = useMemo(() => {
		if (salesPersonCategory)
			return salesPersonCategory.map(v => ({ label: v.userName, value: v.userId }))
	}, [salesPersonCategory])

	// function to set contract name
	const handleContactChange = (evt) => {
		setContractName(evt.target.value)
	}

	// function to select salesperson of the contract
	const handleSelectChange = (evt) => {
		setSalesPersonId(evt.target.value);
		setTempSalesPersonId(evt.target.value);
	}

	const handleContractConfirmationModalOpen = (data) => {
		setConfirmedState(data)
		setIsContractConfirmationModal(true)
	}

	const handleContractConfirmationModalClose = () => {
		setIsContractConfirmationModal(false)
	}

	const handleContractConfirmation = () => {
		setGroupContractCustomerId([...groupContractCustomerId, parseInt(confirmedState.contactInfoId)]);
		setConfirmedState()
		setIsContractConfirmationModal(false)
	}

	// function to check customer ids for making group contract
	const handleClickGroupContractCheckbox = (e, id, data, isAdded) => {
		if(e) {
			if(!isAdded) {
				setGroupContractCustomerId([...groupContractCustomerId, parseInt(id)]);
			} else {
				handleContractConfirmationModalOpen(data);
			}
		} else {
			setGroupContractCustomerId(groupContractCustomerId.filter(element => element !== id));
		}
	}

	// Function to check if integer
	const checkIfInteger = (variable) => {
		if (typeof variable === 'number' && Number.isInteger(variable)) {
			return true
		} else {
			return false
		}
	};

	const removeDuplicates = (array) => {
		return Array.from(new Set(array));
	}

	// Function to save properties on click
	const handleSavePropertiesClick = () => {
		let isInteger = checkIfInteger(groupPricingContractId)
		let tempId = null

		if (isInteger) {
			tempId = groupPricingContractId
		} else {
			tempId = parseInt(groupPricingContractId)[0]
		}

		const uniqueData = removeDuplicates(groupContractCustomerId)
		let payload = {
			"GroupPricingContractId": tempId,
			"ContactInfoIds": uniqueData,
			"CreatedBy": auth[0].userId
		}
		SaveGroupPCSaveProperty(auth[0].token, payload)
	}

	// function called on next button click
	const handleNextClick = () => {
		if (contractPageName === 'AddGrpContact') {
			if ((contractName === '' || salesPersonId === '') && contractPageName === 'AddGrpContact') {
				toast.error("Please fill Contract Name and select Salesperson.");
			} else {
				CreateGroupPricingContractRequest(auth[0].token, {
					"ContractName":contractName,
					"SalesPersonId":salesPersonId,
					"CreatedBy":auth[0].userId,
					"GroupPricingContractId": editPricingContractModalOpen ? editPricingContractModalOpen.groupPricingContractId : 0,
				})
				hidePricingContractModal();
			}
		} else if (contractPageName === 'CustomerList') {
			handleSavePropertiesClick()
			if (groupContractCustomerId.length === 0) {
				toast.error("Please select any property.");
			} else {
				hideCreatePricingContractModal();
				setContractPageName("PricingContract");
				setpricingContractModalOpen(true);
			}
		}
	}

	return (
		<Fragment>
			<Button variant="primary" onClick={showCreatePricingContractModal}>
        Add New Pricing Contracts
			</Button>
			<Modal id="pricing-contract" show={createPricingContractModalOpen} size="xl">
				<Form>
					<Modal.Header closeButton  onClick={()=>(hideCreatePricingContractModal(),hidePricingContractModal(),setGroupContractCustomerId([]),setContractPageName('AddGrpContact'),setSalesPersonId(''),setContractName(''),setIsSummaryContactsInfo(false))}>
						<Modal.Title>Pricing Contract</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{ contractPageName === "AddGrpContact" ?
							<AddEditContractForm 
								handleSelectChange={handleSelectChange}
								handleContactChange={handleContactChange}
								contractName={contractName}
								salesPersonId={salesPersonId}
								salesPersonList={salesPersonList}
							/> : null 
						}
						{ contractPageName === "CustomerList" ? 
							<GroupCustomer
								groupContractCustomerId={groupContractCustomerId}
								handleClickGroupContractCheckbox={handleClickGroupContractCheckbox}
								salesPersonId={tempSalesPersonId}
								editPricingContractModalOpen={editPricingContractModalOpen}
							/> : ""
						}
					</Modal.Body>
					<Modal.Footer className="justify-content-center">
						{ contractPageName === "CustomerList" ?
							<Button type="button" variant="primary" onClick={handleSavePropertiesClick}>
								Save Properties
							</Button> : ""
						}
						<Button type="button" variant="primary" onClick={handleNextClick}>
							Next
						</Button>
						<Button variant="secondary" onClick={()=>(hideCreatePricingContractModal(),setGroupContractCustomerId([]),setSalesPersonId(''),setContractPageName('AddGrpContact'),setIsSummaryContactsInfo(false),setContractName(''))}>Cancel</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			{ contractPageName === "PricingContract" ? 
				<PricingContract
					storeId={storeId}
					contactInfoId={groupContractCustomerId}
					pricingContractModalOpen={pricingContractModalOpen}
					isContractPricingAdded={isSummaryContactsInfo}
					groupPricingContractId={groupPricingContractId}
					showPricingContractModal={showPricingContractModal}
					hidePricingContractModal={()=>(hidePricingContractModal(),setGroupContractCustomerId([]),setContractPageName('AddGrpContact'),setSalesPersonId(''),setContractName(''),setIsSummaryContactsInfo(false))}
				/> : ""
			}
			{ isContractConfirmationModal ?  
				<Modal id="contract-confirmation" show={handleContractConfirmationModalOpen} size="md">
					<Modal.Header closeButton onClick={handleContractConfirmationModalClose}>
						<Modal.Title>Contract Already Created</Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center">
						<h5 className="title my-3">The contract is already created for the user.</h5>
						<p className="mb-0">If you proceed the existing contract will be get deleted.</p>
						<p>Are you sure to proceed?</p>
					</Modal.Body>
					<Modal.Footer className="justify-content-center">
						<Button variant="primary" onClick={handleContractConfirmation}>Yes</Button>
						<Button variant="secondary" onClick={handleContractConfirmationModalClose}>No</Button>
					</Modal.Footer>
				</Modal>
				: ""
			}
		</Fragment>
	)
};

const mapStateToProps = ({ auth, pricingContract, SalesPersonCategory, StoreInfo }) => {
  return {
    auth, pricingContract, SalesPersonCategory, StoreInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    CreateGroupPricingContractRequest: (token, data) => dispatch({ type: GROUP_PRICING_CONTRACT_CREATE_REQUEST, token, data }),
		fetchSalesPersonCategory: (token) => dispatch({ type: SALES_PERSON_CATEGORY_REQUEST, token }),
		fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
		changeGroupReducerState: () => dispatch({ type: GROUP_PRICING_CONTRACT_STATE_CHANGE }),
    SaveGroupPCSaveProperty: (token, data) => dispatch({ type: GROUP_PRICING_CONTRACT_SAVE_PROPERTY_REQUEST, token, data }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatePricingContract);