import {
  MANUFACTURER_REQUEST,
  MANUFACTURER_SUCCESS,
  MANUFACTURER_FAILED,
  MANUFACTURER_CREATE,
  MANUFACTURER_CREATE_SUCCESS,
  MANUFACTURER_CREATE_FAILED,
  MANUFACTURER_DELETE,
  MANUFACTURER_DELETE_SUCCESS,
  MANUFACTURER_DELETE_FAILED,
  MANUFACTURER_EDIT,
  MANUFACTURER_EDIT_SUCCESS,
  MANUFACTURER_EDIT_FAILED,
  MANUFACTURER_STATE_CHANGE,
} from "../../../_utils/constants/ProductCatalog";


const initialState = {
  valueList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const ManufacturerReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANUFACTURER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case MANUFACTURER_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        valueList: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case MANUFACTURER_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case MANUFACTURER_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case MANUFACTURER_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case MANUFACTURER_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case MANUFACTURER_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case MANUFACTURER_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case MANUFACTURER_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case MANUFACTURER_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case MANUFACTURER_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case MANUFACTURER_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case MANUFACTURER_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default ManufacturerReducer;