import React from "react";
import "../../assets/css/auth.css";
import { Form, Button } from "react-bootstrap";

function ResetPassword() {
  return (
    <div className="forgot-wrapper">
      <Form className="text-center">
        <h2 className="sarina mb-5">NancyERP</h2>
        <h4 className="title mb-3">Reset your password?</h4>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="password" placeholder="Current Password" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="password" placeholder="New Password" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control type="password" placeholder="Confirm Password" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Button variant="primary w-100" type="submit">
            Submit
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default ResetPassword;
