import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, Button, InputGroup } from 'react-bootstrap';
// import { encrypted } from '../../../../_utils/helpers';
import { connect } from 'react-redux';
import Create from "./models/Create";
import ConfirmDelete from "./models/ConfirmDelete";
import {
  MANUFACTURER_REQUEST,
  MANUFACTURER_CREATE,
  MANUFACTURER_DELETE,
  MANUFACTURER_EDIT,
  MANUFACTURER_STATE_CHANGE
} from "../../../../_utils/constants/ProductCatalog";
import Loader from "../../../layouts/Loader";
import { toast } from 'react-toastify';

const Manufacturer = ({
  auth: { auth, isAuthenticated },
  Manufacturer: { valueList, isCreated, isDeleted, isEdited, isLoading },
  fetchManufacturer,
  submitManufacturerCreate,
  submitManufacturerDelete,
  submitManufacturerEdit,
  changeReducerState
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [deleteManufacturerId, setDeleteManufacturerId] = useState('');
  const [editManufacturerId, setEditManufacturerId] = useState('');
  const [editManufacturer, setEditManufacturer] = useState('');

  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchManufacturer(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("Manufacturer Created Successfully.");
      fetchManufacturer(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("Manufacturer Deleted Successfully.");
      fetchManufacturer(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("Manufacturer Data Updated Successfully.");
      fetchManufacturer(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
  };

  // function called for input value change of create modal
  const handleCreateModalChange = (e) => {
    setInputVal(e.target.value);
  }

  // function called for create age demographic create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (inputVal === '') {
      toast.error("Manufacturer name cannot be empty.");
    } else {
      submitManufacturerCreate({
        "data": {
          "Manufacturer": inputVal,
          "CreatedBy": auth[0].userId
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteManufacturerId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteManufacturerId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    submitManufacturerDelete({
      "ManufacturerId": deleteManufacturerId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setEditManufacturerId(data.manufacturerId);
    setEditManufacturer(data.manufacturer);
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (editManufacturer === '') {
      toast.error("Manufacturer name cannot be empty.");
    } else {
      submitManufacturerEdit({
        "data": {
          "ManufacturerId": data.manufacturerId,
          "Manufacturer": editManufacturer,
        },
        "token": auth[0].token
      });
      setEditManufacturerId('');
      setEditManufacturer('');
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">

          <div className="sub-header mt-5 mb-3">
            <h2>Manufacturer</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`Manufacturer`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              inputVal={inputVal}
              handleCreateModalChange={handleCreateModalChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Manufacturer</th>
                <th width="50">Delete</th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (
                  <tr key={index}>
                    {editManufacturerId === item.manufacturerId ? (
                      <td>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control type="text" value={editManufacturer} onChange={(evt) => setEditManufacturer(evt.target.value)} />
                            <Button variant="outline-secondary">
                              <Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
                            </Button>
                          </InputGroup>
                        </Form.Group>
                      </td>
                    ) : (
                      <td>
                        {item.manufacturer}
                        <Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                      </td>
                    )}
                    <td>
                      <ConfirmDelete variant="primary" title={`Manufacturer`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.manufacturerId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
    </MainWrapper>
  );
}

const mapStateToProps = ({ auth, Manufacturer }) => {
  return {
    auth,
    Manufacturer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchManufacturer: (token) => dispatch({ type: MANUFACTURER_REQUEST, token }),
    submitManufacturerCreate: (data) => dispatch({ type: MANUFACTURER_CREATE, payload: data }),
    submitManufacturerDelete: (data) => dispatch({ type: MANUFACTURER_DELETE, payload: data }),
    submitManufacturerEdit: (data) => dispatch({ type: MANUFACTURER_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: MANUFACTURER_STATE_CHANGE })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Manufacturer);