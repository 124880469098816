import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import indexSaga from './saga/indexSaga';
import authReducer from './reducers/AuthReducer';
import encryptReducer from "./reducers/EncryptReducer";
import descryptReducer from "./reducers/DecryptReducer";
import valueListReducer from "./reducers/ValueListReducer";
import formValueListItem from "./reducers/valuelist/FormValueListReducer";
import UnitReducer from "./reducers/productcatalog/UnitReducer";
import CarrierReducer from "./reducers/productcatalog/CarrierReducer";
import ProductSourceReducer from "./reducers/productcatalog/ProductSourceReducer";
import PropertyReducer from "./reducers/productcatalog/PropertyReducer";
import ShipReducer from "./reducers/productcatalog/ShipReducer";
import ShipPaymentReducer from "./reducers/productcatalog/ShipPaymentReducer";
import ManufacturerReducer from "./reducers/productcatalog/ManufacturerReducer";
import PaymentAccountReducer from "./reducers/productcatalog/PaymentAccountReducer";
import SoldByReducer from "./reducers/productcatalog/SoldByReducer";
import ProductTypeReducer from "./reducers/productcatalog/ProductTypeReducer";
import ProductPriceReducer from "./reducers/productcatalog/ProductPriceReducer";
import ProductPriceDetailReducer from "./reducers/productcatalog/ProductPriceDetailReducer";
import ProductInfoReducer from "./reducers/productcatalog/ProductInfoReducer";
import PhoneReducer from "./reducers/productcatalog/PhoneReducer";
import NoteReducer from "./reducers/productcatalog/NoteReducer";
import ContactsReducer from "./reducers/productcatalog/ContactsReducer";
import ProductSpecReducer from "./reducers/productcatalog/ProductSpecReducer";
import ProductInstalledPriceReducer from "./reducers/productcatalog/ProductInstalledPriceReducer";
import ProductReducer from "./reducers/productcatalog/ProductReducer";
import PersonalUserReducer from "./reducers/valuelist/PersonalUserReducer";
import AdminReducer from "./reducers/AdminReducer";
import StoreInfoReducer from "./reducers/admin/StoreInfoReducer";
import ProductCatalogReducer from "./reducers/productcatalog/ProductCatalogReducer";
import LaborCategoryReducer from "./reducers/labor/LaborCategoryReducer";
import LaborItemReducer from "./reducers/labor/LaborItemReducer";
import kitsReducer from "./reducers/KitsReducer";
import CopyProductReducer from "./reducers/productcatalog/CopyProductReducer";
import CustomerInfoReducer from "./reducers/customer/CustomerInfoReducer";
import PricingContractReducer from "./reducers/customer/PricingContractReducer";
import SalesPersonCategoryReducer from "./reducers/salesperson/SalesPersonCategoryReducer";
import ContactRollReducer from "./reducers/contactroll/ContactRollReducer";
import EmployeeReducer from "./reducers/employee/EmployeeReducer";
import RoleReducer  from "./reducers/role/RoleReducer"
import CustomerApprovalReducer  from "./reducers/CustomerApproval/customerApprovalReducer"

const sagaMiddleware = createSagaMiddleware();

const middleware =
  process.env.NODE_ENV === 'development'
    ? compose(composeWithDevTools(applyMiddleware(sagaMiddleware, createLogger())))
    : applyMiddleware(sagaMiddleware);

export default createStore(
  combineReducers({
    auth: authReducer,
    encrypt: encryptReducer,
    decrypt: descryptReducer,
    valueList: valueListReducer,
    formValueListItem: formValueListItem,
    unit: UnitReducer,
    carrier: CarrierReducer,
    ProductSource: ProductSourceReducer,
    Property: PropertyReducer,
    Ship: ShipReducer,
    ShipPayment: ShipPaymentReducer,
    Manufacturer: ManufacturerReducer,
    PaymentAccount: PaymentAccountReducer,
    SoldBy: SoldByReducer,
    ProductType: ProductTypeReducer,
    ProductPrice: ProductPriceReducer,
    ProductPriceDetail: ProductPriceDetailReducer,
    ProductInfo: ProductInfoReducer,
    Phone: PhoneReducer,
    Note: NoteReducer,
    Contacts: ContactsReducer,
    ProductSpec: ProductSpecReducer,
    ProductInstalledPrice: ProductInstalledPriceReducer,
    Product: ProductReducer,
    PersonalUser: PersonalUserReducer,
    Admin: AdminReducer,
    StoreInfo: StoreInfoReducer,
    ProductCatalog: ProductCatalogReducer,
    LaborCategory: LaborCategoryReducer,
    LaborItem: LaborItemReducer,
    kits: kitsReducer,
    copyProduct: CopyProductReducer,
    customerInfo: CustomerInfoReducer,
    pricingContract: PricingContractReducer,
    SalesPersonCategory: SalesPersonCategoryReducer,
    ContactRoll: ContactRollReducer,
    Employee:EmployeeReducer,
    Role:RoleReducer,
    CustomerApproval: CustomerApprovalReducer
  }),
  middleware
);

sagaMiddleware.run(indexSaga);