import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateProductInstalledPrice";
import ConfirmDelete from "./models/ConfirmDelete";
import Edit from "./models/EditProductInstalledPrice";
import {
  PRODUCT_INSTALLED_PRICE_REQUEST,
  PRODUCT_INSTALLED_PRICE_CREATE,
  PRODUCT_INSTALLED_PRICE_DELETE,
  PRODUCT_INSTALLED_PRICE_EDIT,
  PRODUCT_INSTALLED_PRICE_STATE_CHANGE,
  PRODUCT_INFO_REQUEST,

} from "../../../../_utils/constants/ProductCatalog";

import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const ProductInstalledPrice = ({
  auth: { auth, isAuthenticated },
  ProductInstalledPrice: { valueList, isCreated, isDeleted, isEdited, isLoading },
  ProductInfo,
  fetchProductInstalledPrice,
  submitProductInstalledPriceCreate,
  submitProductInstalledPriceDelete,
  submitProductInstalledPriceEdit,
  changeReducerState,
  fetchProductInfo,
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [salesProductInstalledPriceName, setProductInstalledPriceName] = useState('');
  const [sortOrder, setSortOrder] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteProductInstalledPriceId, setDeleteProductInstalledPriceId] = useState('');
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState("");
  const [state, setState] = useState({
    "labor": "",
    "productId": "",
    "underlay": "",
    "adhesiveRetails": "",
    "taxRate": "",
    "taxTypeId": "",
    "mat": "",
    "pad": "",
    "adhesiveCosts": "",
    "dollarAmount": "",
    "percenatge": "",
    "costNone": "",
    "sell": "",
    "gpm": "",
    "isRound": true,
    "loadStartDate": "",
    "loadEndDate": "",
    "load": "",
    "priceFor": "",
    "saleStartDate": "",
    "saleEndDate": "",
  }
  )
  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchProductInstalledPrice(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      fetchProductInfo(auth[0].token);
    }
  }, [auth, isAuthenticated]);


  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("ProductInstalledPrice Created Successfully.");
      fetchProductInstalledPrice(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("ProductInstalledPrice Deleted Successfully.");
      fetchProductInstalledPrice(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("ProductInstalledPrice Data Updated Successfully.");
      fetchProductInstalledPrice(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value })

  }

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
    setShow(false)
    setIds("");
    setState({
      "labor": "",
      "productId": "",
      "underlay": "",
      "adhesiveRetails": "",
      "taxRate": "",
      "taxTypeId": "",
      "mat": "",
      "pad": "",
      "adhesiveCosts": "",
      "dollarAmount": "",
      "percenatge": "",
      "costNone": "",
      "sell": "",
      "gpm": "",
      "isRound": true,
      "loadStartDate": "",
      "loadEndDate": "",
      "load": "",
      "priceFor": "",
      "saleStartDate": "",
      "saleEndDate": "",
    })
  };

  // function called for input value change of create modal
  const handleSalesStageNameChange = (e) => {
    setProductInstalledPriceName(e.target.value);
  }
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (state.labor === '') {
      toast.error("Labor cannot be empty.");
    } else {
      submitProductInstalledPriceCreate({
        "data": {
          "Labor": parseFloat(state.labor),
          "ProductId": parseFloat(state.productId),
          "Underlay": state.underlay,
          "AdhesiveRetails": parseFloat(state.adhesiveRetails),
          "TaxRate": parseFloat(state.taxRate),
          "TaxTypeId": parseInt(state.taxTypeId),
          "Mat": parseFloat(state.mat),
          "Pad": parseFloat(state.pad),
          "AdhesiveCosts": parseFloat(state.adhesiveCosts),
          "DollarAmount": parseFloat(state.dollarAmount),
          "Percenatge": parseFloat(state.percenatge),
          "CostNone": state.costNone,
          "Sell": parseFloat(state.sell),
          "Gpm": parseFloat(state.gpm),
          "IsRound": state.isRound ? true : false,
          "LoadStartDate": state.loadStartDate,
          "LoadEndDate": state.loadEndDate,
          "Load": parseFloat(state.load),
          "PriceFor": state.priceFor,
          "SaleStartDate": state.saleStartDate,
          "SaleEndDate": state.saleEndDate,
          "CreatedBy": auth[0].userId,
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteProductInstalledPriceId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteProductInstalledPriceId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitProductInstalledPriceDelete({
      "ProductInstalledPriceId": deleteProductInstalledPriceId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setState(data);
    setIds(data.productInstalledPriceId);
    setShow(true)
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (state.labor === '') {
      toast.error("Labor cannot be empty.");
    } else {

      submitProductInstalledPriceEdit({
        "data": {
          "ProductInstalledPriceId": ids,
          "Labor": parseFloat(state.labor),
          "ProductId": parseFloat(state.productId),
          "Underlay": state.underlay,
          "AdhesiveRetails": parseFloat(state.adhesiveRetails),
          "TaxRate": parseFloat(state.taxRate),
          "TaxTypeId": parseInt(state.taxTypeId),
          "Mat": parseFloat(state.mat),
          "Pad": parseFloat(state.pad),
          "AdhesiveCosts": parseFloat(state.adhesiveCosts),
          "DollarAmount": parseFloat(state.dollarAmount),
          "Percenatge": parseFloat(state.percenatge),
          "CostNone": state.costNone,
          "Sell": parseFloat(state.sell),
          "Gpm": parseFloat(state.gpm),
          "IsRound": state.isRound ? true : false,
          "LoadStartDate": state.loadStartDate,
          "LoadEndDate": state.loadEndDate,
          "Load": parseFloat(state.load),
          "PriceFor": state.priceFor,
          "SaleStartDate": state.saleStartDate,
          "SaleEndDate": state.saleEndDate,
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>ProductInstalledPrice</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`ProductInstalledPrice`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              salesStageName={salesProductInstalledPriceName}
              handleSalesStageNameChange={handleSalesStageNameChange}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
              handleChange={handleChange}
              state={state}
              ProductInfo={ProductInfo.valueList}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Labor</th>
                <th className="text-center">Product</th>
                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (

                  <tr key={index}>
                    <td>
                      {item.labor}
                    </td>
                    <td width="150">
                      {item.productId}
                    </td>
                    <td className="actions">
                      <div className="btn-group">
                        <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                        <ConfirmDelete variant="primary" title={`ProductInstalledPrice`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.productInstalledPriceId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                      </div>
                    </td>
                  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
      <Edit
        state={state}
        handleChange={handleChange}
        handleEditSaveClick={handleEditSaveClick}
        show={show}
        hideCreateModal={hideCreateModal}
        title={`ProductInstalledPrice`}
        ProductInfo={ProductInfo.valueList}
      />
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, ProductInfo, ProductInstalledPrice }) => {
  return {
    auth,
    ProductInstalledPrice,
    ProductInfo
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductInfo: (token) => dispatch({ type: PRODUCT_INFO_REQUEST, token }),
    fetchProductInstalledPrice: (token) => dispatch({ type: PRODUCT_INSTALLED_PRICE_REQUEST, token }),
    submitProductInstalledPriceCreate: (data) => dispatch({ type: PRODUCT_INSTALLED_PRICE_CREATE, payload: data }),
    submitProductInstalledPriceDelete: (data) => dispatch({ type: PRODUCT_INSTALLED_PRICE_DELETE, payload: data }),
    submitProductInstalledPriceEdit: (data) => dispatch({ type: PRODUCT_INSTALLED_PRICE_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: PRODUCT_INSTALLED_PRICE_STATE_CHANGE }),


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductInstalledPrice);