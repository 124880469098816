import React from "react";
import { Form } from 'react-bootstrap';

const CustomFileInput = ({ label, handleFileChange, ids, validate, title }) => {
  return (
    <Form.Group className="mb-3" controlId={ids}>
      <Form.Label>{label}<span className="text-danger">{validate ? "*" : null}</span></Form.Label>
      <Form.Control type="file" name="Files" onChange={handleFileChange} required={validate} title={title ?? "No file chosen"} />
      {validate ? (
        <Form.Control.Feedback type="invalid">
          {label ? label : ids} is required
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  )
}

export default CustomFileInput;