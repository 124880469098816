import React, { Fragment } from "react";
import { Table } from 'react-bootstrap';
import Icon from "react-icons-kit";
import { ic_mode_edit } from 'react-icons-kit/md';
import ConfirmDelete from "../../../elements/ConfirmDelete";
import "../../../../App.css";
import _ from "lodash";
const CustomTable = ({
  column,
  employeeListing,
  showConfirmDeleteModal,
  hideConfirmDeleteModal,
  handleConfirmDeleteSubmit,
  confirmDeleteModal,
  handleEdit
}) => {

  return (
    <Fragment>
      <Table responsive bordered>
        <thead className="th">
          <tr>
            {/* {column ? column.map((cl, i) => (
              <th key={i}>{cl}</th>
            )) : (<th>Employee Name</th>)} */}
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email Name</th>
            <th>Status</th>
          
            <th width="50" className="actions">
              <div className="d-flex">
                <span>Edit</span>
                <span>Delete</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(employeeListing) ? _.map(employeeListing,(value) => (
            <tr key={value.rollId}>
              <td>{value.firstName}</td>
              <td>{value.lastName}</td>
              <td>{value.email}</td>
              <td>{value.status}</td>

              <td>
                <div className="btn-group">
                  <Icon role="button" className="w-54 d-flex justify-content-center" size={18} icon={ic_mode_edit} onClick={(e) => handleEdit(e, value)} />
                  <ConfirmDelete
                    variant="primary"
                    title={`Employee`}
                    showConfirmDeleteModal={() => showConfirmDeleteModal(value.employeeId)}
                    hideConfirmDeleteModal={hideConfirmDeleteModal}
                    handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
                    confirmDeleteModal={confirmDeleteModal}
                  />
                </div>
              </td>
            </tr>
          )) : null}

        </tbody>
      </Table>
    </Fragment>
  )
}


export default CustomTable;