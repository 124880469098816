import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';
import AddDynamicFields from "./AddDynamicFields";
import {
  WALLBASE_ID_DB,
  INSTALLATION_ACCESSORIES_ID_DB,
  TRANSITION_ID_DB
} from "../../../../../_utils/constants/ProductCatalog";

const EditProductType = ({
  title,
  show,
  hideCreateModal,
  handleEditSaveClick,
  handleChange,
  state,
  units,
  SoldBy,
  validated,
  handleInputChange,
  handleRemoveClick,
  handleAddClick,
  formFieldsList,
  handleDropdownChange
}) => {

  return (
    <Fragment>
      <Modal show={show} size="xl">
        <Modal.Header closeButton onClick={hideCreateModal}>
          <Modal.Title>Edit Product Structure</Modal.Title>
        </Modal.Header>
        <Form validated={validated} onSubmit={handleEditSaveClick} noValidate>
          <Modal.Body>
            <Row>
              <Col md={3}>
                <Form.Group controlId="productStructure">
                  <Form.Label>Category</Form.Label>
                  <Form.Control disabled={true} type="text" name="productStructure" onChange={handleChange} value={state.productStructure} required={true} />
                  <Form.Control.Feedback type="invalid">
                    Category is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="seamed">
                  <Form.Label>Seamed</Form.Label>
                  <Form.Select onChange={handleChange} value={state.seamed} required={true}>
                    <option value="" disabled selected>Select your option</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Seamed is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="orderByUnitId">
                  <Form.Label>Ordered By</Form.Label>
                  <Form.Select aria-label="Default select example" value={state.orderByUnitId} onChange={handleChange} required={true}>
                    {/* <option value="" disabled selected>Select your option</option>
                    <option value="SY">SY</option>
                    <option value="SF">SF</option>
                    <option value="EA">EA</option>
                    <option value="LF">LF</option> */}
                    {SoldBy.map((s, i) => (
                      <option value={s.soldById} key={i}>{s.soldByName}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Ordered By is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="soldByUnitId">
                  <Form.Label>Sold By</Form.Label>
                  <Form.Select aria-label="soldById" name="soldById" onChange={handleChange} value={state.soldByUnitId} required={true}>
                    <option value="" disabled selected>Select your option</option>
                    {/*Commented on 29th November for all dropdown similar functionality*/}
                    {/* <option>Select SoldBy</option> */}
                    {SoldBy.map((s, i) => (
                      <option value={s.soldById} key={i}>{s.soldByName}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Sold By is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {/* <hr />
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="unitId">
                  <Form.Label>Units</Form.Label>
                  <Form.Select aria-label="unitId" name="unitId" onChange={handleChange} value={state.unitId}>
                    <option>Select Unit</option>
                    {units.map((u, i) => (
                      <option value={u.unitId} key={i}>{u.unitName}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="procurement">
                  <Form.Label>Procurement</Form.Label>
                  <Form.Control type="text" name="procurement" onChange={handleChange} value={state.procurement} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="invPrefix">
                  <Form.Label>InvPrefix</Form.Label>
                  <Form.Control type="text" name="invPrefix" onChange={handleChange} value={state.invPrefix} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="defaultGpm">
                  <Form.Label>DefaultGpm</Form.Label>
                  <Form.Control type="text" name="defaultGpm" onChange={handleChange} value={state.defaultGpm} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-end">
              <Col>
                <Form.Group className="mb-3" controlId="defaultFreightCharges">
                  <Form.Label>DefaultFreightCharges</Form.Label>
                  <Form.Control type="text" name="defaultFreightCharges" onChange={handleChange} value={state.defaultFreightCharges} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="requirePoitemInfo">
                  <Form.Check
                    type="switch"
                    id="requirePoitemInfo"
                    label="RequirePoitemInfo"
                    onChange={handleChange}
                    checked={state.requirePoitemInfo}

                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="allowManualItemEntry">
                  <Form.Check
                    type="switch"
                    id="allowManualItemEntry"
                    label="AllowManualItemEntry"
                    onChange={handleChange}
                    checked={state.allowManualItemEntry}

                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="allowManualPicking">
                  <Form.Check
                    type="switch"
                    id="allowManualPicking"
                    label="AllowManualPicking"
                    onChange={handleChange}
                    checked={state.allowManualPicking}

                  />
                </Form.Group>
              </Col>
            </Row>
           */}
            {(state.productStructureId === WALLBASE_ID_DB || state.productStructureId === INSTALLATION_ACCESSORIES_ID_DB || state.productStructureId === TRANSITION_ID_DB) ?
              <AddDynamicFields
                handleInputChange={handleInputChange}
                handleRemoveClick={handleRemoveClick}
                handleAddClick={handleAddClick}
                formFieldsList={formFieldsList}
                handleDropdownChange={handleDropdownChange}
                SoldBy={SoldBy}
              /> : null
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hideCreateModal}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Fragment>
  )
};

export default EditProductType;