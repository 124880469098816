import React from "react";
import '../../../assets/css/order.css';
import MainWrapper from "../../MainWrapper";

const ProposalsList = () => {
  return (
    <MainWrapper>
      Proposals List
    </MainWrapper>
  );
}
export default ProposalsList;