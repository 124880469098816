import React, { Fragment, useState, useMemo, useEffect } from "react";
import MainWrapper from "../../../../MainWrapper";
import Loader from "../.././../../layouts/Loader";
import { Link, useNavigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back } from 'react-icons-kit/md';
import CustomTable from "./CustomTable";
import CustomButton from "../../../../elements/CustomButton";
import AddEdit from "./AddEdit";
import { connect } from "react-redux";
import {
  LABOR_CATEGORY_REQUEST,
  LABOR_CATEGORY_CREATE,
  LABOR_CATEGORY_DELETE,
  LABOR_CATEGORY_EDIT,
  LABOR_CATEGORY_STATE_CHANGE
} from "../../../../../_utils/constants/Labor";
import { toast } from 'react-toastify';

const List = ({
  auth: { auth, isAuthenticated },
  LaborCategory: { laborCategory, isCreated, isDeleted, isEdited, isLoading },
  fetchLaborCategory,
  CreateLaborCategoryRequest,
  changeReducerState,
  submitLaborCategoryDelete,
  submitLaborCategoryEdit,

}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [state, setState] = useState({ "laborCategory": "" })
  const [laborId, setlaborId] = useState("");

  const navigate = useNavigate();

  // handle user auth
  useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login")
		}
	}, [auth, isAuthenticated])
  
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({
      ...state,
      [id]: value
    })
  }
  const handleEdit = (e, is) => {
    e.preventDefault();
    setlaborId(is.laborCategoryId);
    setState(is)
    setIsOpen(true);
  }
  const handleModal = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen)
    setValidated(false)
  }
  const hideModel = () => {
    setIsOpen(false)
    setValidated(false);
    setlaborId("");
    setState({ "laborCategory": "" })
  }


  useEffect(() => {
    if (isAuthenticated) {
      fetchLaborCategory(auth[0].token)
    }

  }, [isAuthenticated, auth])

  // use effect function called after api success
  useMemo(() => {
    if (isCreated === true) {
      toast.success("Labor category created successfully.");
      changeReducerState();
      hideModel();
    }
    if (isDeleted === true) {
      toast.success("Labor category deleted successfully.");
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("Labor category updated successfully.")
      changeReducerState();
      hideModel();
    }
    if (isAuthenticated) {
      fetchLaborCategory(auth[0].token)
    }
  }, [isCreated, isDeleted, isEdited]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      if (laborId) {
        var requestData =
        {
          "data": {
            "laborCategory": state.laborCategory,
            "laborCategoryId": laborId
          },
          "token": auth[0].token
        }
        submitLaborCategoryEdit(requestData)
      } else {
        var requestData = {
          "data": {
            "laborCategory": state.laborCategory,
            "CreatedBy": auth[0].userId
          },
          "token": auth[0].token
        }
        CreateLaborCategoryRequest(requestData);
      }

    }
    setValidated(true)
  }

  const column = ["Category"];
  const data = useMemo(() => laborCategory, [laborCategory])
  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteCategoryId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteCategoryId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    submitLaborCategoryDelete({
      "laborCategoryId": deleteCategoryId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }
  const editForm = useMemo(() => laborId, [laborId])
  return (
    <Fragment>
      <MainWrapper>
        <Loader loading={isLoading}>
          <div className="container">
            <div className="sub-header mt-5 mb-3">
              <h2>Labor Categories</h2>
              <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
              <CustomButton
                handleClick={handleModal}
                variant="primary"
              >Create New
              </CustomButton>
            </div>
            <CustomTable
              laborCategory={data}
              column={column}
              showConfirmDeleteModal={showConfirmDeleteModal}
              hideConfirmDeleteModal={hideConfirmDeleteModal}
              handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
              confirmDeleteModal={confirmDeleteModal}
              handleEdit={handleEdit}

            />
          </div>
        </Loader>
        <AddEdit
          isOpen={isOpen}
          size={'sm'}
          state={state}
          handleChange={handleChange}
          validated={validated}
          handleSubmit={handleSubmit}
          hideModel={hideModel}
          laborId={editForm}
          isLoading={isLoading}
        />
      </MainWrapper>
    </Fragment>
  )
}

const mapStateToProps = ({ auth, LaborCategory }) => {
  return {
    auth, LaborCategory
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLaborCategory: (token) => dispatch({ type: LABOR_CATEGORY_REQUEST, token }),
    changeReducerState: () => dispatch({ type: LABOR_CATEGORY_STATE_CHANGE }),
    CreateLaborCategoryRequest: (data) => dispatch({ type: LABOR_CATEGORY_CREATE, payload: data }),
    submitLaborCategoryDelete: (data) => dispatch({ type: LABOR_CATEGORY_DELETE, payload: data }),
    submitLaborCategoryEdit: (data) => dispatch({ type: LABOR_CATEGORY_EDIT, payload: data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(List);