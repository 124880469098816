import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { ENCRYPT_REQUEST, ENCRYPT_SUCCESS, ENCRYPT_FAILED } from '../../_utils/constants/Encrypt';
import {API_URL} from "../../config";

function* fetchEncrypt({ data }) {
  try {
    const response = yield call(axios.get, `${API_URL}/api/Test/encrypt?str=${data}`);
    yield put({ type: ENCRYPT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ENCRYPT_FAILED, payload: error.response });
  }
}



export default function* rootEncryption() {
  yield all([
    takeLatest(ENCRYPT_REQUEST, fetchEncrypt)
  ]);
}