import React from "react";
import Icon from "react-icons-kit";
import '../../../assets/css/Contacts.css';
import MainWrapper from "../../MainWrapper";
import { ic_delete_outline, ic_mode_edit, ic_keyboard_arrow_left, ic_keyboard_arrow_right } from 'react-icons-kit/md';
import { Table, Form, Button } from "react-bootstrap";

const Contacts = () => {
	return (
		<MainWrapper>
			<div className="table-search">
				<Form.Group className="d-flex align-items-center">
					<Form.Label className="me-2">Show </Form.Label>
					<Form.Select>
						<option value="1">10</option>
						<option value="2">20</option>
						<option value="3">50</option>
					</Form.Select>
					<Form.Label className="ms-2">Entries</Form.Label>
				</Form.Group>

				<Form.Group className="d-flex align-items-center ms-auto">
					<Form.Label className="me-2">Search</Form.Label>
					<Form.Control></Form.Control>
				</Form.Group>
			</div>
			<Table bordered responsive className="text-center">
				<thead>
					<tr>
						<th>
							<Form.Group controlId="Stock">
								<Form.Label>Stock</Form.Label>
								<Form.Check type="checkbox" />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Recurring Stock</Form.Label>
								<Form.Check type="checkbox" />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Product Structure</Form.Label>
								<Form.Select aria-label="Default select example" placeholder="select">
									<option disabled selected>Select</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</Form.Select>
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Store Name</Form.Label>
								<Form.Select aria-label="Default select example">
									<option disabled selected>Select</option>
									<option value="1">One</option>
									<option value="2">Two</option>
									<option value="3">Three</option>
								</Form.Select>
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Private Name</Form.Label>
								<Form.Control />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Manufacturer</Form.Label>
								<Form.Control />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Style</Form.Label>
								<Form.Control />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Color</Form.Label>
								<Form.Control />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Current Cost</Form.Label>
								<Form.Control type="number" />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Current Freight</Form.Label>
								<Form.Control type="number" />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Current Cost 1</Form.Label>
								<Form.Control type="number" />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Current Freight 1</Form.Label>
								<Form.Control type="number" />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Increase Date</Form.Label>
								<Form.Control type="date" />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Current Cost 2</Form.Label>
								<Form.Control type="number" />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Current Freight 2</Form.Label>
								<Form.Control type="number" />
							</Form.Group>
						</th>
						<th>
							<Form.Group controlId="RecurringStock">
								<Form.Label>Increase Date</Form.Label>
								<Form.Control type="date" />
							</Form.Group>
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="Stock">
								<Form.Check type="checkbox" />
							</Form.Group>
						</td>
						<td>Sheet Vinyl</td>
						<td>Denver</td>
						<td>Gypsum</td>
						<td>Engineered Floors</td>
						<td>Presideio Tonal</td>
						<td>Wrought Iron</td>
						<td>8.41</td>
						<td>0.58</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="number" />
							</Form.Group>
						</td>
						<td>
							<Form.Group controlId="RecurringStock">
								<Form.Control type="date" />
							</Form.Group>
						</td>
						<td>
							<div className="d-flex">
								<Button variant="flat" className="px-1 py-0">
									<Icon size={18} icon={ic_mode_edit} />
								</Button>
								<Button variant="light" className="delete px-1 py-0">
									<Icon size={18} icon={ic_delete_outline} />
								</Button>
								<Button variant="primary" className="px-2 py-0">Kit</Button>
							</div>
						</td>
					</tr>
				</tbody>
			</Table>

			<div className="pagination">
				<p>Showing 1 to 10 of 57 entries</p>
				<div className="btn-group">
					<Button disabled="disabled">Previous</Button>
					<Button disabled="disabled"><Icon size={18} icon={ic_keyboard_arrow_left} /></Button>
					<Button className="active">01</Button>
					<Button>02</Button>
					<Button>03</Button>
					<Button>04</Button>
					<Button>05</Button>
					<Button disabled="disabled"><Icon size={18} icon={ic_keyboard_arrow_right} /></Button>
					<Button disabled="disabled">Next</Button>
				</div>
			</div>
		</MainWrapper>
	);
}
export default Contacts;