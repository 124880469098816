import React, { memo } from "react";
import { Form } from 'react-bootstrap';

const DateInput = ({ label, handleChange, ids, value, validate }) => {
  return (
    <Form.Group controlId={ids}>
      {label ? (
        <Form.Label>{label}<span className="text-danger">{validate ? "*" : null}</span></Form.Label>
      ) : null}

      <Form.Control type="date" name={ids} onChange={handleChange} value={value} required={validate} />
      {validate ? (
        <Form.Control.Feedback type="invalid">
          {label ? label : ids} is required
        </Form.Control.Feedback>
      ) : null}

    </Form.Group>
  )
}

export default memo(DateInput);