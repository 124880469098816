import {
  CONTACT_ROLL_LIST_REQUEST,
  CONTACT_ROLL_LIST_SUCCESS,
  CONTACT_ROLL_LIST_FAILED,
  CONTACT_ROLL_CREATE_REQUEST,
  CONTACT_ROLL_CREATE_SUCCESS,
  CONTACT_ROLL_CREATE_FAILED,
  CONTACT_ROLL_EDIT_REQUEST,
  CONTACT_ROLL_EDIT_SUCCESS,
  CONTACT_ROLL_EDIT_FAILED,
  CONTACT_ROLL_DELETE_REQUEST,
  CONTACT_ROLL_DELETE_SUCCESS,
  CONTACT_ROLL_DELETE_FAILED,
  CONTACT_ROLL_STATE_CHANGE
} from "../../../_utils/constants/ContactRoll";


const initialState = {
  contactRollList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const ContactRollReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_ROLL_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case CONTACT_ROLL_LIST_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        contactRollList: decryptedData.result,
        isLoading: false,
        errors: {}
      }
    case CONTACT_ROLL_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload
      }
      case CONTACT_ROLL_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case CONTACT_ROLL_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case CONTACT_ROLL_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case CONTACT_ROLL_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case CONTACT_ROLL_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case CONTACT_ROLL_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case CONTACT_ROLL_EDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case CONTACT_ROLL_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case CONTACT_ROLL_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }
    case CONTACT_ROLL_STATE_CHANGE:
      return {
        ...state,
        contactRollList: [],
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
    };
  return state;
}

export default ContactRollReducer;