import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, Button, InputGroup } from 'react-bootstrap';
// import { encrypted } from '../../../../_utils/helpers';
import { connect } from 'react-redux';
import Create from "./models/Create";
import ConfirmDelete from "./models/ConfirmDelete";
import {
	CASHBACK_REQUEST,
	CASHBACK_CREATE,
	CASHBACK_DELETE,
	CASHBACK_EDIT,
	CASHBACK_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const Cashback = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchCashbacks,
	submitCashbackCreate,
	submitCashbackDelete,
	submitCashbackEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [inputVal, setInputVal] = useState('');
	const [deleteCashbackId, setDeleteCashbackId] = useState('');
	const [editCashbackId, setEditCashbackId] = useState('');
	const [editCashbackName, setEditCashbackName] = useState('');

	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchCashbacks(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("Cashback Created Successfully.");
			fetchCashbacks(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Cashback Deleted Successfully.");
			fetchCashbacks(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Cashback Data Updated Successfully.");
			fetchCashbacks(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleCreateModalChange = (e) => {
		setInputVal(e.target.value);
	}

	// function called for create Cashback create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (inputVal === '') {
			toast.error("Cashback name cannot be empty.");
		} else {
			submitCashbackCreate({
				"data": {
					"vendorId": inputVal,
					"createdBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteCashbackId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteCashbackId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete Cashback delete api
	const handleConfirmDeleteSubmit = () => {
		submitCashbackDelete({
			"cashbackId": deleteCashbackId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditCashbackId(data.cashbackId);
		setEditCashbackName(data.vendorId);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editCashbackName === '') {
			toast.error("Cashback name cannot be empty.");
		} else {
			submitCashbackEdit({
				"data": {
					"cashbackId": data.cashbackId,
					"vendorId": editCashbackName
				},
				"token": auth[0].token
			});
			setEditCashbackId('');
			setEditCashbackName('');
		}
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<ToastContainer />
					<div className="sub-header mt-5 mb-3">
						<h2>Cashbacks</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Cashback`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							inputVal={inputVal}
							handleCreateModalChange={handleCreateModalChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Cashbacks Name</th>
								<th width="50" className="actions">
									<div className="d-flex">
										<span>Delete</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									<tr key={index}>
										{editCashbackId === item.cashbackId ? (
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editCashbackName} onChange={(evt) => setEditCashbackName(evt.target.value)} />
														<Button variant="outline-secondary">
															<Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
														</Button>
													</InputGroup>
												</Form.Group>
											</td>
										) : (
											<td>
												{item.vendorId}
												<Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
											</td>
										)}
										<td>
											<ConfirmDelete variant="primary" title={`Cashback`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.cashbackId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={2}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	)
}

const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchCashbacks: (token) => dispatch({ type: CASHBACK_REQUEST, token }),
		submitCashbackCreate: (data) => dispatch({ type: CASHBACK_CREATE, payload: data }),
		submitCashbackDelete: (data) => dispatch({ type: CASHBACK_DELETE, payload: data }),
		submitCashbackEdit: (data) => dispatch({ type: CASHBACK_EDIT, payload: data }),
		changeReducerState: () => dispatch({ type: CASHBACK_STATE_CHANGE })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Cashback);