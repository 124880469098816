export const SALES_PERSON_CATEGORY_REQUEST = "@SALES_PERSON_CATEGORY_REQUEST";
export const SALES_PERSON_CATEGORY_SUCCESS = "@SALES_PERSON_CATEGORY_SUCCESS";
export const SALES_PERSON_CATEGORY_FAILED = "@SALES_PERSON_CATEGORY_FAILED";

export const SALES_PERSON_CATEGORY_CREATE = "@SALES_PERSON_CATEGORY_CREATE";
export const SALES_PERSON_CATEGORY_CREATE_SUCCESS = "@SALES_PERSON_CATEGORY_CREATE_SUCCESS";
export const SALES_PERSON_CATEGORY_CREATE_FAILED = "@SALES_PERSON_CATEGORY_CREATE_FAILED";

export const SALES_PERSON_CATEGORY_DELETE = "@SALES_PERSON_CATEGORY_DELETE";
export const SALES_PERSON_CATEGORY_DELETE_SUCCESS = "@SALES_PERSON_CATEGORY_DELETE_SUCCESS";
export const SALES_PERSON_CATEGORY_DELETE_FAILED = "@SALES_PERSON_CATEGORY_DELETE_FAILED";

export const SALES_PERSON_CATEGORY_EDIT = "@SALES_PERSON_CATEGORY_EDIT";
export const SALES_PERSON_CATEGORY_EDIT_SUCCESS = "@SALES_PERSON_CATEGORY_EDIT_SUCCESS";
export const SALES_PERSON_CATEGORY_EDIT_FAILED = "@SALES_PERSON_CATEGORY_EDIT_FAILED";

export const SALES_PERSON_CATEGORY_STATE_CHANGE = "@SALES_PERSON_CATEGORY_STATE_CHANGE";


export const SALES_PERSON_ITEM_REQUEST = "@SALES_PERSON_ITEM_REQUEST";
export const SALES_PERSON_ITEM_SUCCESS = "@SALES_PERSON_ITEM_SUCCESS";
export const SALES_PERSON_ITEM_FAILED = "@SALES_PERSON_ITEM_FAILED";

export const SALES_PERSON_ITEM_CREATE = "@SALES_PERSON_ITEM_CREATE";
export const SALES_PERSON_ITEM_CREATE_SUCCESS = "@SALES_PERSON_ITEM_CREATE_SUCCESS";
export const SALES_PERSON_ITEM_CREATE_FAILED = "@SALES_PERSON_ITEM_CREATE_FAILED";

export const SALES_PERSON_ITEM_DELETE = "@SALES_PERSON_ITEM_DELETE";
export const SALES_PERSON_ITEM_DELETE_SUCCESS = "@SALES_PERSON_ITEM_DELETE_SUCCESS";
export const SALES_PERSON_ITEM_DELETE_FAILED = "@SALES_PERSON_ITEM_DELETE_FAILED";

export const SALES_PERSON_ITEM_EDIT = "@SALES_PERSON_ITEM_EDIT";
export const SALES_PERSON_ITEM_EDIT_SUCCESS = "@SALES_PERSON_ITEM_EDIT_SUCCESS";
export const SALES_PERSON_ITEM_EDIT_FAILED = "@SALES_PERSON_ITEM_EDIT_FAILED";

export const SALES_PERSON_ITEM_STATE_CHANGE = "@SALES_PERSON_ITEM_STATE_CHANGE";

export const SALES_PERSON_ITEM_UPDATE = "@SALES_PERSON_ITEM_UPDATE";
export const SALES_PERSON_ITEM_UPDATE_SUCCESS = "@SALES_PERSON_ITEM_UPDATE_SUCCESS";
export const SALES_PERSON_ITEM_UPDATE_FAILED = "@SALES_PERSON_ITEM_UPDATE_FAILED";

export const SALES_PERSON_ITEM_FILTER_REQUEST = "@SALES_PERSON_ITEM_FILTER_REQUEST";
export const SALES_PERSON_ITEM_FILTER_SUCCESS = "@SALES_PERSON_ITEM_FILTER_SUCCESS";
export const SALES_PERSON_ITEM_FILTER_FAILED = "@SALES_PERSON_ITEM_FILTER_FAILED";

export const SALES_PERSON_COMMISSION_REQUEST = "@SALES_PERSON_COMMISSION_REQUEST";
export const SALES_PERSON_COMMISSION_SUCCESS = "@SALES_PERSON_COMMISSION_SUCCESS";
export const SALES_PERSON_COMMISSION_FAILED = "@SALES_PERSON_COMMISSION_FAILED";

export const SALES_PERSON_REQUEST = "@SALES_PERSON_REQUEST";
export const SALES_PERSON_SUCCESS = "@SALES_PERSON_SUCCESS";
export const SALES_PERSON_FAILED = "@SALES_PERSON_FAILED";