export const AGEDEMOGRAPHIC_REQUEST = "AGEDEMOGRAPHIC_REQUEST";
export const AGEDEMOGRAPHIC_SUCCESS = "AGEDEMOGRAPHIC_SUCCESS";
export const AGEDEMOGRAPHIC_FAILED = "AGEDEMOGRAPHIC_FAILED";

export const AGEDEMOGRAPHIC_CREATE = "AGEDEMOGRAPHIC_CREATE";
export const AGEDEMOGRAPHIC_CREATE_SUCCESS = "AGEDEMOGRAPHIC_CREATE_SUCCESS";
export const AGEDEMOGRAPHIC_CREATE_FAILED = "AGEDEMOGRAPHIC_CREATE_FAILED";

export const AGEDEMOGRAPHIC_DELETE = "AGEDEMOGRAPHIC_DELETE";
export const AGEDEMOGRAPHIC_DELETE_SUCCESS = "AGEDEMOGRAPHIC_DELETE_SUCCESS";
export const AGEDEMOGRAPHIC_DELETE_FAILED = "AGEDEMOGRAPHIC_DELETE_FAILED";

export const AGEDEMOGRAPHIC_EDIT = "@AGEDEMOGRAPHIC_EDIT";
export const AGEDEMOGRAPHIC_EDIT_SUCCESS = "@AGEDEMOGRAPHIC_EDIT_SUCCESS";
export const AGEDEMOGRAPHIC_EDIT_FAILED = "@AGEDEMOGRAPHIC_EDIT_FAILED";

export const AGEDEMOGRAPHIC_STATE_CHANGE = "@AGEDEMOGRAPHIC_STATE_CHANGE";

export const ALTERNATE_NAMES_REQUEST = "@ALTERNATE_NAMES_REQUEST";
export const ALTERNATE_NAMES_SUCCESS = "@ALTERNATE_NAMES_SUCCESS";
export const ALTERNATE_NAMES_FAILED = "@ALTERNATE_NAMES_FAILED";

export const ALTERNATE_NAMES_CREATE = "@ALTERNATE_NAMES_CREATE";
export const ALTERNATE_NAMES_CREATE_SUCCESS = "@ALTERNATE_NAMES_CREATE_SUCCESS";
export const ALTERNATE_NAMES_CREATE_FAILED = "@ALTERNATE_NAMES_CREATE_FAILED";

export const ALTERNATE_NAMES_DELETE = "@ALTERNATE_NAMES_DELETE";
export const ALTERNATE_NAMES_DELETE_SUCCESS = "@ALTERNATE_NAMES_DELETE_SUCCESS";
export const ALTERNATE_NAMES_DELETE_FAILED = "@ALTERNATE_NAMES_DELETE_FAILED";

export const ALTERNATE_NAMES_EDIT = "@ALTERNATE_NAMES_EDIT";
export const ALTERNATE_NAMES_EDIT_SUCCESS = "@ALTERNATE_NAMES_EDIT_SUCCESS";
export const ALTERNATE_NAMES_EDIT_FAILED = "@ALTERNATE_NAMES_EDIT_FAILED";

export const ALTERNATE_NAMES_STATE_CHANGE = "@ALTERNATE_NAMES_STATE_CHANGE";

export const NOTETEMPLATES_REQUEST = "@NOTETEMPLATES_REQUEST";
export const NOTETEMPLATES_SUCCESS = "@NOTETEMPLATES_SUCCESS";
export const NOTETEMPLATES_FAILED = "@NOTETEMPLATES_FAILED";

export const NOTETEMPLATES_CREATE_REQUEST = "@NOTETEMPLATES_CREATE_REQUEST";
export const NOTETEMPLATES_CREATE_SUCCESS = "@NOTETEMPLATES_CREATE_SUCCESS";
export const NOTETEMPLATES_CREATE_FAILED = "@NOTETEMPLATES_CREATE_FAILED";

export const NOTETEMPLATES_STATE_CHANGE = "@NOTETEMPLATES_STATE_CHANGE";

export const NOTETEMPLATES_EDIT = "@NOTETEMPLATES_EDIT";
export const NOTETEMPLATES_EDIT_SUCCESS = "@NOTETEMPLATES_EDIT_SUCCESS";
export const NOTETEMPLATES_EDIT_FAILED = "@NOTETEMPLATES_EDIT_FAILED";

export const NOTETEMPLATES_DELETE = "@NOTETEMPLATES_DELETE";
export const NOTETEMPLATES_DELETE_SUCCESS = "@NOTETEMPLATES_DELETE_SUCCESS";
export const NOTETEMPLATES_DELETE_FAILED = "@NOTETEMPLATES_DELETE_FAILED";

export const SHIPPINGMETHODS_REQUEST = "SHIPPINGMETHODS_REQUEST";
export const SHIPPINGMETHODS_SUCCESS = "SHIPPINGMETHODS_SUCCESS";
export const SHIPPINGMETHODS_FAILED = "SHIPPINGMETHODS_FAILED";

export const SHIPPINGMETHODS_CREATE = "SHIPPINGMETHODS_CREATE";
export const SHIPPINGMETHODS_CREATE_SUCCESS = "SHIPPINGMETHODS_CREATE_SUCCESS";
export const SHIPPINGMETHODS_CREATE_FAILED = "SHIPPINGMETHODS_CREATE_FAILED";

export const SHIPPINGMETHODS_DELETE = "SHIPPINGMETHODS_DELETE";
export const SHIPPINGMETHODS_DELETE_SUCCESS = "SHIPPINGMETHODS_DELETE_SUCCESS";
export const SHIPPINGMETHODS_DELETE_FAILED = "SHIPPINGMETHODS_DELETE_FAILED";

export const SHIPPINGMETHODS_EDIT = "SHIPPINGMETHODS_EDIT";
export const SHIPPINGMETHODS_EDIT_SUCCESS = "SHIPPINGMETHODS_EDIT_SUCCESS";
export const SHIPPINGMETHODS_EDIT_FAILED = "SHIPPINGMETHODS_EDIT_FAILED";

export const SHIPPINGMETHODS_STATE_CHANGE = "SHIPPINGMETHODS_STATE_CHANGE";

export const JOBTYPES_REQUEST = "JOBTYPES_REQUEST";
export const JOBTYPES_SUCCESS = "JOBTYPES_SUCCESS";
export const JOBTYPES_FAILED = "JOBTYPES_FAILED";

export const JOBTYPES_CREATE = "JOBTYPES_CREATE";
export const JOBTYPES_CREATE_SUCCESS = "JOBTYPES_CREATE_SUCCESS";
export const JOBTYPES_CREATE_FAILED = "JOBTYPES_CREATE_FAILED";

export const JOBTYPES_DELETE = "JOBTYPES_DELETE";
export const JOBTYPES_DELETE_SUCCESS = "JOBTYPES_DELETE_SUCCESS";
export const JOBTYPES_DELETE_FAILED = "JOBTYPES_DELETE_FAILED";

export const JOBTYPES_EDIT = "JOBTYPES_EDIT";
export const JOBTYPES_EDIT_SUCCESS = "JOBTYPES_EDIT_SUCCESS";
export const JOBTYPES_EDIT_FAILED = "JOBTYPES_EDIT_FAILED";

export const JOBTYPES_STATE_CHANGE = "JOBTYPES_STATE_CHANGE";

export const FORMTEMPLATE_REQUEST = "FORMTEMPLATE_REQUEST";
export const FORMTEMPLATE_SUCCESS = "FORMTEMPLATE_SUCCESS";
export const FORMTEMPLATE_FAILED = "FORMTEMPLATE_FAILED";

export const FORMTEMPLATE_CREATE = "FORMTEMPLATE_CREATE";
export const FORMTEMPLATE_CREATE_SUCCESS = "FORMTEMPLATE_CREATE_SUCCESS";
export const FORMTEMPLATE_CREATE_FAILED = "FORMTEMPLATE_CREATE_FAILED";

export const FORMTEMPLATE_DELETE = "FORMTEMPLATE_DELETE";
export const FORMTEMPLATE_DELETE_SUCCESS = "FORMTEMPLATE_DELETE_SUCCESS";
export const FORMTEMPLATE_DELETE_FAILED = "FORMTEMPLATE_DELETE_FAILED";

export const FORMTEMPLATE_EDIT = "FORMTEMPLATE_EDIT";
export const FORMTEMPLATE_EDIT_SUCCESS = "FORMTEMPLATE_EDIT_SUCCESS";
export const FORMTEMPLATE_EDIT_FAILED = "FORMTEMPLATE_EDIT_FAILED";

export const FORMTEMPLATE_STATE_CHANGE = "FORMTEMPLATE_STATE_CHANGE";

export const SALESSTAGE_REQUEST = "SALESSTAGE_REQUEST";
export const SALESSTAGE_SUCCESS = "SALESSTAGE_SUCCESS";
export const SALESSTAGE_FAILED = "SALESSTAGE_FAILED";

export const SALESSTAGE_CREATE = "SALESSTAGE_CREATE";
export const SALESSTAGE_CREATE_SUCCESS = "SALESSTAGE_CREATE_SUCCESS";
export const SALESSTAGE_CREATE_FAILED = "SALESSTAGE_CREATE_FAILED";

export const SALESSTAGE_DELETE = "SALESSTAGE_DELETE";
export const SALESSTAGE_DELETE_SUCCESS = "SALESSTAGE_DELETE_SUCCESS";
export const SALESSTAGE_DELETE_FAILED = "SALESSTAGE_DELETE_FAILED";

export const SALESSTAGE_EDIT = "SALESSTAGE_EDIT";
export const SALESSTAGE_EDIT_SUCCESS = "SALESSTAGE_EDIT_SUCCESS";
export const SALESSTAGE_EDIT_FAILED = "SALESSTAGE_EDIT_FAILED";

export const SALESSTAGE_STATE_CHANGE = "SALESSTAGE_STATE_CHANGE";

export const REGION_REQUEST = "REGION_REQUEST";
export const REGION_SUCCESS = "REGION_SUCCESS";
export const REGION_FAILED = "REGION_FAILED";

export const REGION_CREATE = "REGION_CREATE";
export const REGION_CREATE_SUCCESS = "REGION_CREATE_SUCCESS";
export const REGION_CREATE_FAILED = "REGION_CREATE_FAILED";

export const REGION_DELETE = "REGION_DELETE";
export const REGION_DELETE_SUCCESS = "REGION_DELETE_SUCCESS";
export const REGION_DELETE_FAILED = "REGION_DELETE_FAILED";

export const REGION_EDIT = "REGION_EDIT";
export const REGION_EDIT_SUCCESS = "REGION_EDIT_SUCCESS";
export const REGION_EDIT_FAILED = "REGION_EDIT_FAILED";

export const REGION_STATE_CHANGE = "REGION_STATE_CHANGE";

export const PRODUCTINTEREST_REQUEST = "PRODUCTINTEREST_REQUEST";
export const PRODUCTINTEREST_SUCCESS = "PRODUCTINTEREST_SUCCESS";
export const PRODUCTINTEREST_FAILED = "PRODUCTINTEREST_FAILED";

export const PRODUCTINTEREST_CREATE = "PRODUCTINTEREST_CREATE";
export const PRODUCTINTEREST_CREATE_SUCCESS = "PRODUCTINTEREST_CREATE_SUCCESS";
export const PRODUCTINTEREST_CREATE_FAILED = "PRODUCTINTEREST_CREATE_FAILED";

export const PRODUCTINTEREST_DELETE = "PRODUCTINTEREST_DELETE";
export const PRODUCTINTEREST_DELETE_SUCCESS = "PRODUCTINTEREST_DELETE_SUCCESS";
export const PRODUCTINTEREST_DELETE_FAILED = "PRODUCTINTEREST_DELETE_FAILED";

export const PRODUCTINTEREST_EDIT = "PRODUCTINTEREST_EDIT";
export const PRODUCTINTEREST_EDIT_SUCCESS = "PRODUCTINTEREST_EDIT_SUCCESS";
export const PRODUCTINTEREST_EDIT_FAILED = "PRODUCTINTEREST_EDIT_FAILED";

export const PRODUCTINTEREST_STATE_CHANGE = "PRODUCTINTEREST_STATE_CHANGE";

// export const VENDOR_REQUEST = "VENDOR_REQUEST";
// export const VENDOR_SUCCESS = "VENDOR_SUCCESS";
// export const VENDOR_FAILED = "VENDOR_FAILED";

// export const VENDOR_CREATE = "VENDOR_CREATE";
// export const VENDOR_CREATE_SUCCESS = "VENDOR_CREATE_SUCCESS";
// export const VENDOR_CREATE_FAILED = "VENDOR_CREATE_FAILED";

// export const VENDOR_DELETE = "VENDOR_DELETE";
// export const VENDOR_DELETE_SUCCESS = "VENDOR_DELETE_SUCCESS";
// export const VENDOR_DELETE_FAILED = "VENDOR_DELETE_FAILED";

// export const VENDOR_EDIT = "VENDOR_EDIT";
// export const VENDOR_EDIT_SUCCESS = "VENDOR_EDIT_SUCCESS";
// export const VENDOR_EDIT_FAILED = "VENDOR_EDIT_FAILED";

// export const VENDOR_STATE_CHANGE = "VENDOR_STATE_CHANGE";


export const CASHBACK_REQUEST = "@CASHBACK_REQUEST";
export const CASHBACK_SUCCESS = "@CASHBACK_SUCCESS";
export const CASHBACK_FAILED = "@CASHBACK_FAILED";

export const CASHBACK_CREATE = "@CASHBACK_CREATE";
export const CASHBACK_CREATE_SUCCESS = "@CASHBACK_CREATE_SUCCESS";
export const CASHBACK_CREATE_FAILED = "@CASHBACK_CREATE_FAILED";

export const CASHBACK_DELETE = "@CASHBACK_DELETE";
export const CASHBACK_DELETE_SUCCESS = "@CASHBACK_DELETE_SUCCESS";
export const CASHBACK_DELETE_FAILED = "@CASHBACK_DELETE_FAILED";

export const CASHBACK_EDIT = "@CASHBACK_EDIT";
export const CASHBACK_EDIT_SUCCESS = "@CASHBACK_EDIT_SUCCESS";
export const CASHBACK_EDIT_FAILED = "@CASHBACK_EDIT_FAILED";

export const CASHBACK_STATE_CHANGE = "@CASHBACK_STATE_CHANGE";

export const FORM_REQUEST = "@FORM_REQUEST";
export const FORM_SUCCESS = "@FORM_SUCCESS";
export const FORM_FAILED = "@FORM_FAILED";

export const FORM_CREATE = "@FORM_CREATE";
export const FORM_CREATE_SUCCESS = "@FORM_CREATE_SUCCESS";
export const FORM_CREATE_FAILED = "@FORM_CREATE_FAILED";

export const FORM_DELETE = "@FORM_DELETE";
export const FORM_DELETE_SUCCESS = "@FORM_DELETE_SUCCESS";
export const FORM_DELETE_FAILED = "@FORM_DELETE_FAILED";

export const FORM_EDIT = "@FORM_EDIT";
export const FORM_EDIT_SUCCESS = "@FORM_EDIT_SUCCESS";
export const FORM_EDIT_FAILED = "@FORM_EDIT_FAILED";

export const FORM_STATE_CHANGE = "@FORM_STATE_CHANGE";

export const AREA_REQUEST = "@AREA_REQUEST";
export const AREA_SUCCESS = "@AREA_SUCCESS";
export const AREA_FAILED = "@AREA_FAILED";

export const AREA_CREATE_REQUEST = "@AREA_CREATE_REQUEST";
export const AREA_CREATE_SUCCESS = "@AREA_CREATE_SUCCESS";
export const AREA_CREATE_FAILED = "@AREA_CREATE_FAILED";

export const AREA_EDIT_REQUEST = "@AREA_EDIT_REQUEST";
export const AREA_EDIT_SUCCESS = "@AREA_EDIT_SUCCESS";
export const AREA_EDIT_FAILED = "@AREA_EDIT_FAILED";

export const AREA_DELETE_REQUEST = "@AREA_DELETE_REQUEST";
export const AREA_DELETE_SUCCESS = "@AREA_DELETE_SUCCESS";
export const AREA_DELETE_FAILED = "@AREA_DELETE_FAILED";

export const AREA_CHANGE = "@AREA_STATE_CHANGE";


export const FORM_VALUE_LIST_REQUEST = "@FORM_VALUE_LIST_REQUEST";
export const FORM_VALUE_LIST_SUCCESS = "@FORM_VALUE_LIST_SUCCESS";
export const FORM_VALUE_LIST_FAILED = "@FORM_VALUE_LIST_FAILED";

export const FORM_VALUE_LIST_CREATE_REQUEST = "@FORM_VALUE_LIST_CREATE_REQUEST";
export const FORM_VALUE_LIST_CREATE_SUCCESS = "@FORM_VALUE_LIST_CREATE_SUCCESS";
export const FORM_VALUE_LIST_CREATE_FAILED = "@FORM_VALUE_LIST_CREATE_FAILED";

export const FORM_VALUE_LIST_EDIT_REQUEST = "@FORM_VALUE_LIST_EDIT_REQUEST";
export const FORM_VALUE_LIST_EDIT_SUCCESS = "@FORM_VALUE_LIST_EDIT_SUCCESS";
export const FORM_VALUE_LIST_EDIT_FAILED = "@FORM_VALUE_LIST_EDIT_FAILED";

export const FORM_VALUE_LIST_DELETE_REQUEST = "@FORM_VALUE_LIST_DELETE_REQUEST";
export const FORM_VALUE_LIST_DELETE_SUCCESS = "@FORM_VALUE_LIST_DELETE_SUCCESS";
export const FORM_VALUE_LIST_DELETE_FAILED = "@FORM_VALUE_LIST_DELETE_FAILED";

export const FORM_VALUE_LIST_CHANGE = "@FORM_VALUE_LIST_STATE_CHANGE";


export const BIN_LOCATION_REQUEST = "@BIN_LOCATION_REQUEST";
export const BIN_LOCATION_SUCCESS = "@BIN_LOCATION_SUCCESS";
export const BIN_LOCATION_FAILED = "@BIN_LOCATION_FAILED";

export const BIN_LOCATION_CREATE = "@BIN_LOCATION_CREATE";
export const BIN_LOCATION_CREATE_SUCCESS = "@BIN_LOCATION_CREATE_SUCCESS";
export const BIN_LOCATION_CREATE_FAILED = "@BIN_LOCATION_CREATE_FAILED";

export const BIN_LOCATION_DELETE = "@BIN_LOCATION_DELETE";
export const BIN_LOCATION_DELETE_SUCCESS = "@BIN_LOCATION_DELETE_SUCCESS";
export const BIN_LOCATION_DELETE_FAILED = "@BIN_LOCATION_DELETE_FAILED";

export const BIN_LOCATION_EDIT = "@BIN_LOCATION_EDIT";
export const BIN_LOCATION_EDIT_SUCCESS = "@BIN_LOCATION_EDIT_SUCCESS";
export const BIN_LOCATION_EDIT_FAILED = "@BIN_LOCATION_EDIT_FAILED";

export const BIN_LOCATION_STATE_CHANGE = "@BIN_LOCATION_STATE_CHANGE";


export const COMPETITORS_REQUEST = "@COMPETITORS_REQUEST";
export const COMPETITORS_SUCCESS = "@COMPETITORS_SUCCESS";
export const COMPETITORS_FAILED = "@COMPETITORS_FAILED";

export const COMPETITORS_CREATE = "@COMPETITORS_CREATE";
export const COMPETITORS_CREATE_SUCCESS = "@COMPETITORS_CREATE_SUCCESS";
export const COMPETITORS_CREATE_FAILED = "@COMPETITORS_CREATE_FAILED";

export const COMPETITORS_DELETE = "@COMPETITORS_DELETE";
export const COMPETITORS_DELETE_SUCCESS = "@COMPETITORS_DELETE_SUCCESS";
export const COMPETITORS_DELETE_FAILED = "@COMPETITORS_DELETE_FAILED";

export const COMPETITORS_EDIT = "@COMPETITORS_EDIT";
export const COMPETITORS_EDIT_SUCCESS = "@COMPETITORS_EDIT_SUCCESS";
export const COMPETITORS_EDIT_FAILED = "@COMPETITORS_EDIT_FAILED";

export const COMPETITORS_STATE_CHANGE = "@COMPETITORS_STATE_CHANGE";


export const IDSTATUS_REQUEST = "@IDSTATUS_REQUEST";
export const IDSTATUS_SUCCESS = "@IDSTATUS_SUCCESS";
export const IDSTATUS_FAILED = "@IDSTATUS_FAILED";

export const IDSTATUS_CREATE = "@IDSTATUS_CREATE";
export const IDSTATUS_CREATE_SUCCESS = "@IDSTATUS_CREATE_SUCCESS";
export const IDSTATUS_CREATE_FAILED = "@IDSTATUS_CREATE_FAILED";

export const IDSTATUS_DELETE = "@IDSTATUS_DELETE";
export const IDSTATUS_DELETE_SUCCESS = "@IDSTATUS_DELETE_SUCCESS";
export const IDSTATUS_DELETE_FAILED = "@IDSTATUS_DELETE_FAILED";

export const IDSTATUS_EDIT = "@IDSTATUS_EDIT";
export const IDSTATUS_EDIT_SUCCESS = "@IDSTATUS_EDIT_SUCCESS";
export const IDSTATUS_EDIT_FAILED = "@IDSTATUS_EDIT_FAILED";

export const IDSTATUS_STATE_CHANGE = "@IDSTATUS_STATE_CHANGE";

export const LOST_REQUEST = "@LOST_REQUEST";
export const LOST_SUCCESS = "@LOST_SUCCESS";
export const LOST_FAILED = "@LOST_FAILED";

export const LOST_CREATE = "@LOST_CREATE";
export const LOST_CREATE_SUCCESS = "@LOST_CREATE_SUCCESS";
export const LOST_CREATE_FAILED = "@LOST_CREATE_FAILED";

export const LOST_DELETE = "@LOST_DELETE";
export const LOST_DELETE_SUCCESS = "@LOST_DELETE_SUCCESS";
export const LOST_DELETE_FAILED = "@LOST_DELETE_FAILED";

export const LOST_EDIT = "@LOST_EDIT";
export const LOST_EDIT_SUCCESS = "@LOST_EDIT_SUCCESS";
export const LOST_EDIT_FAILED = "@LOST_EDIT_FAILED";

export const LOST_STATE_CHANGE = "@LOST_STATE_CHANGE";

export const WON_REQUEST = "@WON_REQUEST";
export const WON_SUCCESS = "@WON_SUCCESS";
export const WON_FAILED = "@WON_FAILED";

export const WON_CREATE = "@WON_CREATE";
export const WON_CREATE_SUCCESS = "@WON_CREATE_SUCCESS";
export const WON_CREATE_FAILED = "@WON_CREATE_FAILED";

export const WON_DELETE = "@WON_DELETE";
export const WON_DELETE_SUCCESS = "@WON_DELETE_SUCCESS";
export const WON_DELETE_FAILED = "@WON_DELETE_FAILED";

export const WON_EDIT = "@WON_EDIT";
export const WON_EDIT_SUCCESS = "@WON_EDIT_SUCCESS";
export const WON_EDIT_FAILED = "@WON_EDIT_FAILED";

export const WON_STATE_CHANGE = "@WON_STATE_CHANGE";


export const SICCODE_REQUEST = "@SICCODE_REQUEST";
export const SICCODE_SUCCESS = "@SICCODE_SUCCESS";
export const SICCODE_FAILED = "@SICCODE_FAILED";

export const SICCODE_CREATE = "@SICCODE_CREATE";
export const SICCODE_CREATE_SUCCESS = "@SICCODE_CREATE_SUCCESS";
export const SICCODE_CREATE_FAILED = "@SICCODE_CREATE_FAILED";

export const SICCODE_DELETE = "@SICCODE_DELETE";
export const SICCODE_DELETE_SUCCESS = "@SICCODE_DELETE_SUCCESS";
export const SICCODE_DELETE_FAILED = "@SICCODE_DELETE_FAILED";

export const SICCODE_EDIT = "@SICCODE_EDIT";
export const SICCODE_EDIT_SUCCESS = "@SICCODE_EDIT_SUCCESS";
export const SICCODE_EDIT_FAILED = "@SICCODE_EDIT_FAILED";

export const SICCODE_STATE_CHANGE = "@SICCODE_STATE_CHANGE";


export const MARKET_SECTOR_REQUEST = "@MARKET_SECTOR_REQUEST";
export const MARKET_SECTOR_SUCCESS = "@MARKET_SECTOR_SUCCESS";
export const MARKET_SECTOR_FAILED = "@MARKET_SECTOR_FAILED";

export const MARKET_SECTOR_CREATE = "@MARKET_SECTOR_CREATE";
export const MARKET_SECTOR_CREATE_SUCCESS = "@MARKET_SECTOR_CREATE_SUCCESS";
export const MARKET_SECTOR_CREATE_FAILED = "@MARKET_SECTOR_CREATE_FAILED";

export const MARKET_SECTOR_DELETE = "@MARKET_SECTOR_DELETE";
export const MARKET_SECTOR_DELETE_SUCCESS = "@MARKET_SECTOR_DELETE_SUCCESS";
export const MARKET_SECTOR_DELETE_FAILED = "@MARKET_SECTOR_DELETE_FAILED";

export const MARKET_SECTOR_EDIT = "@MARKET_SECTOR_EDIT";
export const MARKET_SECTOR_EDIT_SUCCESS = "@MARKET_SECTOR_EDIT_SUCCESS";
export const MARKET_SECTOR_EDIT_FAILED = "@MARKET_SECTOR_EDIT_FAILED";

export const MARKET_SECTOR_STATE_CHANGE = "@MARKET_SECTOR_STATE_CHANGE";

export const BUYING_GROUP_REQUEST = "@BUYING_GROUP_REQUEST";
export const BUYING_GROUP_SUCCESS = "@BUYING_GROUP_SUCCESS";
export const BUYING_GROUP_FAILED = "@BUYING_GROUP_FAILED";

export const BUYING_GROUP_CREATE = "@BUYING_GROUP_CREATE";
export const BUYING_GROUP_CREATE_SUCCESS = "@BUYING_GROUP_CREATE_SUCCESS";
export const BUYING_GROUP_CREATE_FAILED = "@BUYING_GROUP_CREATE_FAILED";

export const BUYING_GROUP_DELETE = "@BUYING_GROUP_DELETE";
export const BUYING_GROUP_DELETE_SUCCESS = "@BUYING_GROUP_DELETE_SUCCESS";
export const BUYING_GROUP_DELETE_FAILED = "@BUYING_GROUP_DELETE_FAILED";

export const BUYING_GROUP_EDIT = "@BUYING_GROUP_EDIT";
export const BUYING_GROUP_EDIT_SUCCESS = "@BUYING_GROUP_EDIT_SUCCESS";
export const BUYING_GROUP_EDIT_FAILED = "@BUYING_GROUP_EDIT_FAILED";

export const BUYING_GROUP_STATE_CHANGE = "@BUYING_GROUP_STATE_CHANGE";

export const WAREHOUSE_REQUEST = "@WAREHOUSE_REQUEST";
export const WAREHOUSE_SUCCESS = "@WAREHOUSE_SUCCESS";
export const WAREHOUSE_FAILED = "@WAREHOUSE_FAILED";

export const WAREHOUSE_CREATE = "@WAREHOUSE_CREATE";
export const WAREHOUSE_CREATE_SUCCESS = "@WAREHOUSE_CREATE_SUCCESS";
export const WAREHOUSE_CREATE_FAILED = "@WAREHOUSE_CREATE_FAILED";

export const WAREHOUSE_DELETE = "@WAREHOUSE_DELETE";
export const WAREHOUSE_DELETE_SUCCESS = "@WAREHOUSE_DELETE_SUCCESS";
export const WAREHOUSE_DELETE_FAILED = "@WAREHOUSE_DELETE_FAILED";

export const WAREHOUSE_EDIT = "@WAREHOUSE_EDIT";
export const WAREHOUSE_EDIT_SUCCESS = "@WAREHOUSE_EDIT_SUCCESS";
export const WAREHOUSE_EDIT_FAILED = "@WAREHOUSE_EDIT_FAILED";

export const WAREHOUSE_STATE_CHANGE = "@WAREHOUSE_STATE_CHANGE";


export const VENDOR_TYPE_REQUEST = "@VENDOR_TYPE_REQUEST";
export const VENDOR_TYPE_SUCCESS = "@VENDOR_TYPE_SUCCESS";
export const VENDOR_TYPE_FAILED = "@VENDOR_TYPE_FAILED";

export const VENDOR_TYPE_CREATE = "@VENDOR_TYPE_CREATE";
export const VENDOR_TYPE_CREATE_SUCCESS = "@VENDOR_TYPE_CREATE_SUCCESS";
export const VENDOR_TYPE_CREATE_FAILED = "@VENDOR_TYPE_CREATE_FAILED";

export const VENDOR_TYPE_DELETE = "@VENDOR_TYPE_DELETE";
export const VENDOR_TYPE_DELETE_SUCCESS = "@VENDOR_TYPE_DELETE_SUCCESS";
export const VENDOR_TYPE_DELETE_FAILED = "@VENDOR_TYPE_DELETE_FAILED";

export const VENDOR_TYPE_EDIT = "@VENDOR_TYPE_EDIT";
export const VENDOR_TYPE_EDIT_SUCCESS = "@VENDOR_TYPE_EDIT_SUCCESS";
export const VENDOR_TYPE_EDIT_FAILED = "@VENDOR_TYPE_EDIT_FAILED";

export const VENDOR_TYPE_STATE_CHANGE = "@VENDOR_TYPE_STATE_CHANGE";

export const MARKETING_REQUEST = "@MARKETING_REQUEST";
export const MARKETING_SUCCESS = "@MARKETING_SUCCESS";
export const MARKETING_FAILED = "@MARKETING_FAILED";

export const MARKETING_CREATE = "@MARKETING_CREATE";
export const MARKETING_CREATE_SUCCESS = "@MARKETING_CREATE_SUCCESS";
export const MARKETING_CREATE_FAILED = "@MARKETING_CREATE_FAILED";

export const MARKETING_DELETE = "@MARKETING_DELETE";
export const MARKETING_DELETE_SUCCESS = "@MARKETING_DELETE_SUCCESS";
export const MARKETING_DELETE_FAILED = "@MARKETING_DELETE_FAILED";

export const MARKETING_EDIT = "@MARKETING_EDIT";
export const MARKETING_EDIT_SUCCESS = "@MARKETING_EDIT_SUCCESS";
export const MARKETING_EDIT_FAILED = "@MARKETING_EDIT_FAILED";

export const MARKETING_STATE_CHANGE = "@MARKETING_STATE_CHANGE";

export const VENDOR_REQUEST = "@VENDOR_REQUEST";
export const VENDOR_SUCCESS = "@VENDOR_SUCCESS";
export const VENDOR_FAILED = "@VENDOR_FAILED";

export const VENDOR_CREATE = "@VENDOR_CREATE";
export const VENDOR_CREATE_SUCCESS = "@VENDOR_CREATE_SUCCESS";
export const VENDOR_CREATE_FAILED = "@VENDOR_CREATE_FAILED";

export const VENDOR_DELETE = "@VENDOR_DELETE";
export const VENDOR_DELETE_SUCCESS = "@VENDOR_DELETE_SUCCESS";
export const VENDOR_DELETE_FAILED = "@VENDOR_DELETE_FAILED";

export const VENDOR_EDIT = "@VENDOR_EDIT";
export const VENDOR_EDIT_SUCCESS = "@VENDOR_EDIT_SUCCESS";
export const VENDOR_EDIT_FAILED = "@VENDOR_EDIT_FAILED";

export const VENDOR_STATE_CHANGE = "@VENDOR_STATE_CHANGE";



export const PERSONAL_USER_REQUEST = "@PERSONAL_USER_REQUEST";
export const PERSONAL_USER_SUCCESS = "@PERSONAL_USER_SUCCESS";
export const PERSONAL_USER_FAILED = "@PERSONAL_USER_FAILED";

export const PERSONAL_USER_CREATE = "@PERSONAL_USER_CREATE";
export const PERSONAL_USER_CREATE_SUCCESS = "@PERSONAL_USER_CREATE_SUCCESS";
export const PERSONAL_USER_CREATE_FAILED = "@PERSONAL_USER_CREATE_FAILED";

export const PERSONAL_USER_DELETE = "@PERSONAL_USER_DELETE";
export const PERSONAL_USER_DELETE_SUCCESS = "@PERSONAL_USER_DELETE_SUCCESS";
export const PERSONAL_USER_DELETE_FAILED = "@PERSONAL_USER_DELETE_FAILED";

export const PERSONAL_USER_EDIT = "@PERSONAL_USER_EDIT";
export const PERSONAL_USER_EDIT_SUCCESS = "@PERSONAL_USER_EDIT_SUCCESS";
export const PERSONAL_USER_EDIT_FAILED = "@PERSONAL_USER_EDIT_FAILED";

export const PERSONAL_USER_STATE_CHANGE = "@PERSONAL_USER_STATE_CHANGE";



