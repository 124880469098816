import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';

const EditProductInstalledPrice = ({
  title,
  show,
  hideCreateModal,
  handleEditSaveClick,
  handleChange,
  state,
  ProductInfo
}) => {

  return (
    <Fragment>

      <Modal show={show} size="xl">
        <Modal.Header>Edit {title}</Modal.Header>
        <Modal.Body>
          <Form>

            <Row>

              <Col>
                <Form.Group className="mb-3" controlId="labor">
                  <Form.Label>Labor</Form.Label>
                  <Form.Control type="text" name="labor" onChange={handleChange} value={state.labor} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="productId">
                  <Form.Label>Product</Form.Label>
                  <Form.Select aria-label="productId" name="productId" onChange={handleChange} value={state.productId}>
                    <option>Select ProductInfoId</option>
                    <option value="1">Product 1</option>
                    <option value="2">Product 2</option>
                    <option value="3">Product 3</option>

                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="underlay">
                  <Form.Label>Underlay</Form.Label>
                  <Form.Control type="text" name="underlay" onChange={handleChange} value={state.underlay} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="adhesiveRetails">
                  <Form.Label>AdhesiveRetails</Form.Label>
                  <Form.Control type="text" name="adhesiveRetails" onChange={handleChange} value={state.adhesiveRetails} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="taxRate">
                  <Form.Label>TaxRate</Form.Label>
                  <Form.Control type="text" name="taxRate" onChange={handleChange} value={state.taxRate} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="taxTypeId">
                  <Form.Label>TaxTypeId</Form.Label>
                  <Form.Control type="text" name="taxTypeId" onChange={handleChange} value={state.taxTypeId} />
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="mat">
                  <Form.Label>Mat</Form.Label>
                  <Form.Control type="text" name="mat" onChange={handleChange} value={state.mat} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="pad">
                  <Form.Label>Pad</Form.Label>
                  <Form.Control type="text" name="pad" onChange={handleChange} value={state.pad} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="adhesiveCosts">
                  <Form.Label>AdhesiveCosts</Form.Label>
                  <Form.Control type="text" name="adhesiveCosts" onChange={handleChange} value={state.adhesiveCosts} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="dollarAmount">
                  <Form.Label>DollarAmount</Form.Label>
                  <Form.Control type="text" name="dollarAmount" onChange={handleChange} value={state.dollarAmount} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="percenatge">
                  <Form.Label>Percenatge</Form.Label>
                  <Form.Control type="text" name="percenatge" onChange={handleChange} value={state.percenatge} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="costNone">
                  <Form.Label>CostNone</Form.Label>
                  <Form.Control type="text" name="costNone" onChange={handleChange} value={state.costNone} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="sell">
                  <Form.Label>Sell</Form.Label>
                  <Form.Control type="text" name="sell" onChange={handleChange} value={state.sell} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="gpm">
                  <Form.Label>Gpm</Form.Label>
                  <Form.Control type="text" name="gpm" onChange={handleChange} value={state.gpm} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="isRound">
                  <Form.Label>IsRound</Form.Label>
                  <Form.Control type="text" name="isRound" onChange={handleChange} value={state.isRound} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="loadStartDate">
                  <Form.Label>LoadStartDate</Form.Label>
                  <Form.Control type="date" name="loadStartDate" onChange={handleChange} value={state.loadStartDate} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="loadEndDate">
                  <Form.Label>LoadEndDate</Form.Label>
                  <Form.Control type="date" name="loadEndDate" onChange={handleChange} value={state.loadEndDate} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="load">
                  <Form.Label>Load</Form.Label>
                  <Form.Control type="text" name="load" onChange={handleChange} value={state.load} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="priceFor">
                  <Form.Label>PriceFor</Form.Label>
                  <Form.Control type="text" name="priceFor" onChange={handleChange} value={state.priceFor} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="saleStartDate">
                  <Form.Label>SaleStartDate</Form.Label>
                  <Form.Control type="date" name="saleStartDate" onChange={handleChange} value={state.saleStartDate} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="saleEndDate">
                  <Form.Label>SaleEndDate</Form.Label>
                  <Form.Control type="date" name="saleEndDate" onChange={handleChange} value={state.saleEndDate} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default EditProductInstalledPrice;