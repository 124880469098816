import React, { Fragment, useEffect, useState, useMemo } from "react";
import MainWrapper from "../../../../MainWrapper";
import Loader from "../.././../../layouts/Loader";
import { Link, useNavigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back } from 'react-icons-kit/md';
import CustomTable from "./CustomTable";
import CustomButton from "../../../../elements/CustomButton";
import AddEdit from "./AddEdit";
import { connect } from "react-redux";
import {
  SALES_PERSON_CATEGORY_REQUEST,
  SALES_PERSON_CATEGORY_CREATE,
  SALES_PERSON_CATEGORY_DELETE,
  SALES_PERSON_CATEGORY_EDIT,
  SALES_PERSON_CATEGORY_STATE_CHANGE
} from "../../../../../_utils/constants/SalesPerson";
import { STORE_INFO_REQUEST } from "../../../../../_utils/constants/admin/StoreInfo";

import { toast } from 'react-toastify';

const List = ({
  auth: { auth, isAuthenticated },
  SalesPersonCategory: { salesPersonCategory, isCreated, isDeleted, isEdited, isLoading, errors },
  StoreInfo: {storeInfo},
  fetchSalesPersonCategory,
  CreateSalesPersonCategoryRequest,
  changeReducerState,
  submitSalesPersonCategoryDelete,
  submitSalesPersonCategoryEdit,
  fetchStoreInfo
}) => {
	const [isOpen, setIsOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [state, setState] = useState({ "salesPersonCategory": ""})
  const [salesPersonId, setSalesPersonId] = useState("");
  

  const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login")
		}
    if (isAuthenticated) {
      // fetchSalesPersonCategory(auth[0].token)
      fetchStoreInfo(auth[0].token);
    }
  }, [isAuthenticated])

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({
      [id]: value
    });
  }
  const handleEdit = (e, is) => {
    e.preventDefault();
    setSalesPersonId(is.userId);
    setState(is)
    setIsOpen(true);
  }
  const handleModal = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen)
    setValidated(false)
  }
  const hideModel = () => {
    setIsOpen(false)
    setValidated(false);
    setSalesPersonId("");
    setState({ "salesPersonCategory": "" })
  }
  // use effect function called after api success
  useMemo(() => {
    if (isAuthenticated) {
      if (isCreated === true) {
        toast.success("Salesperson created successfully.");
        changeReducerState();
        hideModel();
      }
      if (isDeleted === true) {
        toast.success("Salesperson deleted successfully.");
        changeReducerState();
      }
      if (isEdited === true) {
        toast.success("Salesperson updated successfully.")
        changeReducerState();
        hideModel();
      }
      fetchSalesPersonCategory(auth[0].token)
    }
  }, [isCreated, isDeleted, isEdited]);
  useMemo(() => {
    if(!_.isEmpty(errors)) {
      toast.error(errors.data.result[0])
      changeReducerState();
    }
  }, [errors])
  const column = ["Name"];
  const data = useMemo(() => salesPersonCategory, [salesPersonCategory]);
  const editForm = useMemo(() => salesPersonId, [salesPersonId])

  // function to manage store list data fetched
  const storeList = useMemo(() => {
    if (storeInfo)
      return storeInfo.map(v => ({ label: v.storeName, value: v.storeId }))
  }, [storeInfo])

  const manageCommissionData = (salesStoreCommission ,editId) => {
    // Remove null and undefined elements
    const filteredData = salesStoreCommission.filter(item => item !== null && item !== undefined);

    // Convert numbers to decimal with 2 decimal places
    let newData = filteredData.map(item => {
        if (item && item?.StoreId) {
        if(editId){
          return {
              "IsDeleted": false,
                StoreId: parseInt(item.StoreId),
                Commission: parseFloat(item?.Commission || 0).toFixed(1),
                OverHead: parseFloat(item?.OverHead || 0).toFixed(1),
                SalesCommissionId: parseInt(item?.SalesCommissionId || 0),
            };
        }
        else{
            return {
                "IsDeleted": false,
                StoreId: parseInt(item.StoreId),
                Commission: parseFloat(item?.Commission || 0).toFixed(1),
                OverHead: parseFloat(item?.OverHead || 0).toFixed(1),
            };
        }
      }
        // return item;
    });
    newData = newData.filter(item => item !== null && item !== undefined);
    return newData;
  }


  const handleSubmit = (event, postData) => {
    // console.log('handleSubmit', event, postData)
    event.preventDefault();
    
    const form = event.currentTarget;
    // console.log('handleSubmit preventDefault', form.checkValidity(), salesPersonId)
    if (form.checkValidity() === false) {
      // Array to store the names of invalid fields
      // const invalidFields = [];
  
      // // Iterate over form elements and check validity
      // for (let i = 0; i < form.elements.length; i++) {
      //   const element = form.elements[i];

      //   if (element.tagName === 'INPUT' && !element.checkValidity()) {
      //     console.log('---i----', i, element.tagName, element.name, element.value)
      //     invalidFields.push(element.name);

      //   }
      // }
      // // Log or handle the invalid fields
      // console.log('Invalid fields:', invalidFields);


      event.preventDefault();
      event.stopPropagation();
    } else {
      if (salesPersonId) {
        var requestData =
        {
          "data": {
            "UserName": postData?.userName || state.salesPersonCategory,
            "UserId": salesPersonId,
            "CreatedBy": auth[0].userId,
            "SalesCommission" : manageCommissionData(postData?.salesStoreCommission, salesPersonId)
          },
          "token": auth[0].token
        }
        submitSalesPersonCategoryEdit(requestData)
      } else {
        var requestData = {
          "data": {
            "UserName": state.salesPersonCategory,
            "RoleId":"3",
            "CreatedBy": auth[0].userId,
            "SalesCommission" : manageCommissionData(postData?.salesStoreCommission, null)
          },
          "token": auth[0].token
        }
        CreateSalesPersonCategoryRequest(requestData);
      }

    }
    setValidated(true)
  }
  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteCategoryId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteCategoryId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    submitSalesPersonCategoryDelete({
      "salesPersonId": deleteCategoryId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  

  return(
  	<Fragment>
  		<MainWrapper>
  			<Loader loading={isLoading}>
          <div className="container">
            <div className="sub-header mt-5 mb-3">
              <h2>Salesperson</h2>
              <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
              <CustomButton
                handleClick={handleModal}
                variant="primary"
              >Create New
              </CustomButton>
            </div>
            <CustomTable
              salesPersonCategory={data}
              column={column}
              showConfirmDeleteModal={showConfirmDeleteModal}
              hideConfirmDeleteModal={hideConfirmDeleteModal}
              handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
              confirmDeleteModal={confirmDeleteModal}
              handleEdit={handleEdit}
            />
          </div>
        </Loader>
        {isOpen ? <AddEdit
          isOpen={isOpen}
          auth={auth}
          size={'lg'}
          state={state}
          handleChange={handleChange}
          validated={validated}
          handleSubmit={handleSubmit}
          hideModel={hideModel}
          salesPersonId={editForm}
          isLoading={isLoading}
          storeList={storeList}
        /> : null}
  		</MainWrapper>
  	</Fragment>
  )
}

const mapStateToProps = ({ auth, SalesPersonCategory, StoreInfo }) => {
  return {
    auth, SalesPersonCategory, StoreInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSalesPersonCategory: (token) => dispatch({ type: SALES_PERSON_CATEGORY_REQUEST, token }),
    changeReducerState: () => dispatch({ type: SALES_PERSON_CATEGORY_STATE_CHANGE }),
    CreateSalesPersonCategoryRequest: (data) => dispatch({ type: SALES_PERSON_CATEGORY_CREATE, payload: data }),
    submitSalesPersonCategoryDelete: (data) => dispatch({ type: SALES_PERSON_CATEGORY_DELETE, payload: data }),
    submitSalesPersonCategoryEdit: (data) => dispatch({ type: SALES_PERSON_CATEGORY_EDIT, payload: data }),
    fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(List);