import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  ROLE_LIST_REQUEST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_FAILED,
  ROLE_CREATE_REQUEST,
  ROLE_CREATE_SUCCESS,
  ROLE_CREATE_FAILED,
  ROLE_EDIT_REQUEST,
  ROLE_EDIT_SUCCESS,
  ROLE_EDIT_FAILED,
  ROLE_DELETE_REQUEST,
  ROLE_DELETE_SUCCESS,
  ROLE_DELETE_FAILED,
  ROLE_DROPDOWN_REQUEST,
  ROLE_DROPDOWN_SUCCESS,
  ROLE_DROPDOWN_FAILED,
} from "../../../_utils/constants/Role";

function* fetchRoleList(action) {
  try {
    const response = yield call(axios.get, `${API_URL}/User/api/Roles/GetRoleList`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: ROLE_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: ROLE_LIST_FAILED, payload: error.response });
    }
  }
}

function* fetchRoleDropdownDataList(action) {
  try {
    const response = yield call(axios.get, `${API_URL}/User/api/RolePermission/GetMenuPermission`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: ROLE_DROPDOWN_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: ROLE_DROPDOWN_FAILED, payload: error.response });
    }
  }
}

function* roleCreateRequest(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/Roles/CreateRole`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: ROLE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: ROLE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}

function* roleEditRequest(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/Roles/UpdateRole`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: ROLE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: ROLE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}

function* roleDeleteRequest(action) {
 try {
    const response = yield call(
      axios.delete,
      `${API_URL}/User/api/Roles/RemoveRole?RoleId=${encodeURIComponent(action.payload.roleIdTable)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: ROLE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: ROLE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

export default function* rootRole() {
  yield all([
    takeLatest(ROLE_LIST_REQUEST, fetchRoleList ),
    takeLatest(ROLE_DROPDOWN_REQUEST,fetchRoleDropdownDataList),
    takeLatest(ROLE_CREATE_REQUEST, roleCreateRequest),
    takeLatest(ROLE_DELETE_REQUEST, roleDeleteRequest),
    takeLatest(ROLE_EDIT_REQUEST, roleEditRequest),
  ])
}