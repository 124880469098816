import React, { useState, useEffect, useMemo } from "react";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md/';
import { Link } from "react-router-dom";
import Title from "../../elements/Title";
import MainWrapper from "../../MainWrapper";
import CreatePricingContract from './models/CreatePricingContract';
import { Table, Button } from "react-bootstrap";
import Input from "../../elements/CustomInput";
import { connect } from "react-redux";
import { 
	GROUP_PRICING_CONTRACT_PRODUCT_LIST_REQUEST, 
	GROUP_PRICING_CONTRACT_STATE_CHANGE,
	GROUP_PRICING_CONTRACT_DELETE_REQUEST,
	PRICING_CONTRACT_DETAILS_REQUEST,
	GROUP_PRICING_CONTRACT_FILTER_REQUEST
} from "../../../_utils/constants/PricingContract";
import ConfirmDelete from "../../elements/ConfirmDelete";
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from "react-router-dom";

const PricingContracts = ({
	auth: { auth, isAuthenticated },
	pricingContract: { groupPricingContractData, isGroupPricingContractCreated, groupPricingContractId, isGroupPricingContractUpdated, pricingContractData, isGroupPricingContractDeleted, groupErrors, isProcessing },
  fetchGroupPricingContractData,
	GroupPricingContractDeleteRequest,
	changeGroupReducerState,
	fetchPricingContract,
	pricingContractFilterList
}) => {

	// States declaration
	const [createPricingContractModalOpen, setCreatePricingContractModalOpen] = useState(false);
	const [editPricingContractModalOpen, setEditPricingContractModalOpen] = useState();
	const [deleteCategoryId, setDeleteCategoryId] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [groupContractId, setGroupContractId] = useState();
	const [isBool, setIsBool] = useState(false);
	const [pricingContractSummaryData, setPricingContractSummaryData] = useState([]);
	const [listParamState, setListParamState] = useState({
		"contractName": "",
		"salesPerson": ""
	})

	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login")
		}
	}, [auth, isAuthenticated])

	// function called on page load to fetch pricing contract list
	useEffect(() => {
    if (isAuthenticated) {
      fetchGroupPricingContractData(auth[0].token)
    }
    if (!isAuthenticated) return <Navigate to="/login" />;
  }, [isAuthenticated, auth])

  const groupData = useMemo(() => groupPricingContractData, [groupPricingContractData]);

  // function called on any filter value change
	const handleFilterChange = (e) => {
		const { id, value } = e.target;
		setListParamState(
			{
				...listParamState,
				[id]: value
			}
		)
	}

	useEffect(() => {
		if(isAuthenticated) {	
			if(_.isEmpty(listParamState.contractName) && _.isEmpty(listParamState.salesPerson)) {
				fetchGroupPricingContractData(auth[0].token)
			} else {
				pricingContractFilterList(auth[0].token, listParamState)
			}
		}
	}, [listParamState])

	// function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteCategoryId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteCategoryId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    GroupPricingContractDeleteRequest({
      "groupPricingContractId": deleteCategoryId,
      "token": auth[0].token
    });
  }

	// function called to show success message for contract creation and closing the modal
	useEffect(()=> {
		if (isAuthenticated) {
			if (isGroupPricingContractCreated === true) {
				if(groupPricingContractId) {
					setGroupContractId(groupPricingContractId)
					setIsBool(true)
				}
				changeGroupReducerState();
			}
			if (isGroupPricingContractDeleted === true) {
				toast.success("Pricing contract deleted successfully.");
				changeGroupReducerState();
				hideConfirmDeleteModal();
			}
			fetchGroupPricingContractData(auth[0].token)
		}
	}, [isGroupPricingContractCreated, isGroupPricingContractDeleted])

	useEffect(() => {
		if(pricingContractData[0] && editPricingContractModalOpen) {
			setPricingContractSummaryData(pricingContractData)
			setGroupContractId(pricingContractData[0].contactInfoIds)
		}
	}, [pricingContractData])

	useEffect(() => {
		if(editPricingContractModalOpen && groupPricingContractId && isBool) {
			fetchPricingContract(auth[0].token, {'cutomerId': '', 'groupContractId': groupPricingContractId});
			setIsBool(false);
		}
	}, [editPricingContractModalOpen, groupContractId, isBool])

	// function to open create pricing contract modal
	const showCreatePricingContractModal = () => {
		setCreatePricingContractModalOpen(true);
	};

	// function to close create pricing contract modal
	const hideCreatePricingContractModal = () => {
		setCreatePricingContractModalOpen(false);
		setEditPricingContractModalOpen("");
	};

	const handleGrpPricingContractEdit = (data) => {
		setCreatePricingContractModalOpen(true);
		setEditPricingContractModalOpen(data);
	}

	return (
		<MainWrapper>
		{isProcessing ? (<div className="loading-container"><div className="spinner-border"></div></div>) : null}
			<div className="container">
				<div className="sub-header mt-5 mb-3">
					<Title>Pricing Contracts</Title>
					<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
					<CreatePricingContract
						createPricingContractModalOpen={createPricingContractModalOpen}
						showCreatePricingContractModal={showCreatePricingContractModal}
						hideCreatePricingContractModal={hideCreatePricingContractModal}
						editPricingContractModalOpen={editPricingContractModalOpen}
						groupContractId={groupContractId}
						pricingContractSummaryData={pricingContractSummaryData}
					/>
				</div>
				<Table>
					<thead>
						<tr>
							<th width="300">
								<Input label={`Salesperson`} ids={`salesPerson`} value={listParamState.salesperson} handleChange={handleFilterChange} validate={false} />
							</th>
							<th width="300">
								<Input label={`Contract Name`} ids={`contractName`} value={listParamState.contractname} handleChange={handleFilterChange} validate={false} />
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{!_.isEmpty(groupData) ? _.map(groupData, (value, index) => (
							<tr key={index}>
								<td>{value.salesPersonName}</td>
								<td>{value.contractName}</td>
								<td align="right">
									<div className="d-inline-flex">
										<Button 
											variant="flat" 
											className="px-1 py-0" 
											onClick={e=>handleGrpPricingContractEdit(value)}
										>
	            				<Icon size={18} icon={ic_mode_edit} />
	                	</Button>
	                	<ConfirmDelete 
											variant="primary"
											title={`group pricing contract`}
											showConfirmDeleteModal={() => showConfirmDeleteModal(value.groupPricingContractId)}
											hideConfirmDeleteModal={hideConfirmDeleteModal}
											handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
											confirmDeleteModal={confirmDeleteModal}
										/>
									</div>
								</td>
							</tr>
							)) : null}
					</tbody>
				</Table>
			</div>
		</MainWrapper>
	);
};

const mapStateToProps = ({ auth, pricingContract }) => {
  return {
    auth, pricingContract
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroupPricingContractData: (token) => dispatch({ type: GROUP_PRICING_CONTRACT_PRODUCT_LIST_REQUEST, token }),
		GroupPricingContractDeleteRequest: (data) => dispatch({ type: GROUP_PRICING_CONTRACT_DELETE_REQUEST, data }),
    changeGroupReducerState: () => dispatch({ type: GROUP_PRICING_CONTRACT_STATE_CHANGE }),
		fetchPricingContract: (token, data) => dispatch({ type: PRICING_CONTRACT_DETAILS_REQUEST, token, data }),
		pricingContractFilterList: (token, listParamState) => dispatch({ type: GROUP_PRICING_CONTRACT_FILTER_REQUEST, token, listParamState }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(PricingContracts);