import {
  SOLD_BY_REQUEST,
  SOLD_BY_SUCCESS,
  SOLD_BY_FAILED,
  SOLD_BY_CREATE,
  SOLD_BY_CREATE_SUCCESS,
  SOLD_BY_CREATE_FAILED,
  SOLD_BY_DELETE,
  SOLD_BY_DELETE_SUCCESS,
  SOLD_BY_DELETE_FAILED,
  SOLD_BY_EDIT,
  SOLD_BY_EDIT_SUCCESS,
  SOLD_BY_EDIT_FAILED,
  SOLD_BY_STATE_CHANGE,
} from "../../../_utils/constants/ProductCatalog";


const initialState = {
  valueList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const SoldByReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOLD_BY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case SOLD_BY_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        valueList: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case SOLD_BY_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case SOLD_BY_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case SOLD_BY_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case SOLD_BY_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case SOLD_BY_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case SOLD_BY_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case SOLD_BY_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case SOLD_BY_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case SOLD_BY_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case SOLD_BY_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case SOLD_BY_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default SoldByReducer;