import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import React from "react";
import {Link} from "react-router-dom";
import Icon from "react-icons-kit";
import {ic_arrow_back} from 'react-icons-kit/md';
import {Tabs, Tab, Row, Col, Card, Form} from 'react-bootstrap';

const Defaults = () => {
  	return (
    	<MainWrapper>
    		<div className="sub-header mt-5 mb-3">
				<h2>Conditions</h2>
				<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Admin List</Link>
			</div>
			<Tabs defaultActiveKey="general" id="uncontrolled-tab-example" className="">
				 	<Tab eventKey="general" title="General" className="px-3 pt-3 pb-1 admin-tabs">
					    <Row className="mb-2">
					    	<Col md={4}>
					    		<Card>
					    			<Card.Body>
					    				<h6 className="card-title">Select the Default Check Method</h6>
					    				<Row>
					    					<Col md={6}>
					    						<Form.Check type="radio" label="Hand Written Checks" />
					    					</Col>
					    					<Col md={6}>
					    						<Form.Check type="radio" label="Printed Checks" />
					    					</Col>
					    				</Row>
					    			</Card.Body>
					    		</Card>
					    	</Col>
					    	<Col md={4}>B</Col>
					    	<Col md={4}>C</Col>
					    </Row>
				  	</Tab>
				  	<Tab eventKey="accounting" title="Accounting" className="p-3 admin-tabs">
					    
				  	</Tab>
				  	<Tab eventKey="printing" title="Printing" className="p-3 admin-tabs">
					    
				  	</Tab>
				</Tabs>
		</MainWrapper>
  	);
}
export default Defaults;