import {
  PERSONAL_USER_REQUEST,
  PERSONAL_USER_SUCCESS,
  PERSONAL_USER_FAILED,
  PERSONAL_USER_DELETE,
  PERSONAL_USER_DELETE_SUCCESS,
  PERSONAL_USER_DELETE_FAILED,
  PERSONAL_USER_CREATE,
  PERSONAL_USER_CREATE_SUCCESS,
  PERSONAL_USER_CREATE_FAILED,
  PERSONAL_USER_EDIT,
  PERSONAL_USER_EDIT_SUCCESS,
  PERSONAL_USER_EDIT_FAILED
} from "../../../_utils/constants/ValueList";
import _ from 'lodash';

const initialState = {
  personalUser: [],
  isLoading: false,
  isCreated: false,
  isEdited: false,
  isDeleted: false,
  errors: {},
  success: false,
};


const PersonalUserReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PERSONAL_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {},
        success: false,
        isDeleted: false,
        isCreated: false,
        isEdited: false,
        personalUser: []
      }
    case PERSONAL_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        personalUser: _.get(payload, "result", []),
        success: true,
        errors: {},
      }
    case PERSONAL_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        errors: _.get(payload, "data", {}),
      }
    case PERSONAL_USER_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case PERSONAL_USER_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case PERSONAL_USER_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: payload
      }
    case PERSONAL_USER_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case PERSONAL_USER_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case PERSONAL_USER_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: payload
      }
    case PERSONAL_USER_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case PERSONAL_USER_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case PERSONAL_USER_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: payload
      }
    default:
      return state;
  }
}

export default PersonalUserReducer;