import React, { Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateJobTypes = ({
  title,
	createModalOpen,
	showCreateModal,
	hideCreateModal,
	jobTypeName,
	handleJobTypeNameChange,
	jobTypeColor,
	handleJobTypeColorChange,
	jobTypeStatus,
	handleJobTypeStatusChange,
	jobActiveStatus,
	handleActiveStatusChange,
	handleCreateModalSubmit
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} >
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="jobTypeName">
              <Form.Label>Enter New {title}</Form.Label>
              <Form.Control type="text" name="jobTypeName" onChange={handleJobTypeNameChange} value={jobTypeName} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="jobTypeColor">
              <Form.Label>Sales Calendar Color</Form.Label>
              <Form.Control type="text" name="jobTypeColor" onChange={handleJobTypeColorChange} value={jobTypeColor} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="jobTypeStatus">
              <Form.Label>Default</Form.Label>
              <Form.Control type="text" name="jobTypeStatus" onChange={handleJobTypeStatusChange} value={jobTypeStatus} />
            </Form.Group>
						<Form.Group className="mb-3" controlId="jobActiveStatus">
							<Form.Check type="checkbox" label="Active Status" onChange={handleActiveStatusChange} checked={jobActiveStatus} />
						</Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateModalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreateJobTypes;