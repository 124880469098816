import {
  PRODUCT_CATALOG_REQUEST,
  PRODUCT_CATALOG_SUCCESS,
  PRODUCT_CATALOG_FAILED,
  PRODUCT_CATALOG_CREATE,
  PRODUCT_CATALOG_CREATE_SUCCESS,
  PRODUCT_CATALOG_CREATE_FAILED,
  PRODUCT_CATALOG_DELETE,
  PRODUCT_CATALOG_DELETE_SUCCESS,
  PRODUCT_CATALOG_DELETE_FAILED,
  PRODUCT_CATALOG_EDIT,
  PRODUCT_CATALOG_EDIT_SUCCESS,
  PRODUCT_CATALOG_EDIT_FAILED,
  PRODUCT_CATALOG_STATE_CHANGE,
  PRODUCT_CATALOG_UPDATE,
  PRODUCT_CATALOG_UPDATE_SUCCESS,
  PRODUCT_CATALOG_UPDATE_FAILED
} from "../../../_utils/constants/ProductCatalog";


const initialState = {
  products: [],
  product: [],
  totalCount: 0,
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
  isEditedData: [],
};

const ProductCatalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_CATALOG_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case PRODUCT_CATALOG_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        products: decryptedData.result,
        totalCount: decryptedData.TotalCount,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case PRODUCT_CATALOG_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case PRODUCT_CATALOG_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case PRODUCT_CATALOG_CREATE_SUCCESS:
      return {
        ...state,
        product: action.payload.result,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case PRODUCT_CATALOG_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.error ? action.error : action?.payload?.data?.error
      }

    case PRODUCT_CATALOG_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case PRODUCT_CATALOG_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case PRODUCT_CATALOG_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case PRODUCT_CATALOG_EDIT:
    case PRODUCT_CATALOG_UPDATE:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case PRODUCT_CATALOG_EDIT_SUCCESS:
    case PRODUCT_CATALOG_UPDATE_SUCCESS:
      return {
        ...state,
        isEditedData: action.payload.result,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case PRODUCT_CATALOG_EDIT_FAILED:
    case PRODUCT_CATALOG_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error ? action.error : action?.payload?.data?.error
      }

    case PRODUCT_CATALOG_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default ProductCatalogReducer;