import React, { Fragment } from "react";
import Model from "../../../../elements/CustomModel";
import { Modal } from "react-bootstrap";
import ModelBody from "../../../../elements/ModelBody";
import ModelFooter from "../../../../elements/ModelFooter";
import Form from "../../../../elements/CustomForm";
import Input from "../../../../elements/CustomInput";
import Button from "../../../../elements/CustomButton";
const AddEdit = ({
  isOpen,
  size,
  hideModel,
  handleSubmit,
  validated,
  handleChange,
  state,
  laborId,
  isLoading
}) => {

  return (
    <Fragment>
      <Model isOpen={isOpen} size={size}>
        <Modal.Header closeButton onClick={hideModel}>
          <Modal.Title>{laborId ? 'Edit Labor Category' : "Add Labor Category"}</Modal.Title>
        </Modal.Header>
        <Form handleSubmit={handleSubmit} validated={validated}>
          <ModelBody>
            <Input label={"Name"} ids={'laborCategory'} handleChange={handleChange} value={state.laborCategory} validate={true} />
          </ModelBody>
          <ModelFooter>
            <Button handleClick={hideModel} variant="secondary" type="button">
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (<div className="spinner-border"></div>) : "Save"}
            </Button>
          </ModelFooter>
        </Form>
      </Model>
    </Fragment>
  )
}

export default AddEdit;