import React, { Fragment, useState, useMemo, useEffect } from "react";
import MainWrapper from "../../../../MainWrapper";
import Loader from "../.././../../layouts/Loader";
import { Link, useNavigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back } from 'react-icons-kit/md';
import CustomButton from "../../../../elements/CustomButton";
import AddEdit from "./AddEdit";
import Table from "./ItemTable";
import { connect } from "react-redux";
import {
  LABOR_CATEGORY_REQUEST,
  LABOR_ITEM_REQUEST,
  LABOR_ITEM_CREATE,
  LABOR_ITEM_DELETE,
  LABOR_ITEM_EDIT,
  LABOR_ITEM_UPDATE,
  LABOR_ITEM_STATE_CHANGE,
  LABOR_ITEM_FILTER_REQUEST
} from "../../../../../_utils/constants/Labor";
import {
  PRODUCT_TYPE_REQUEST,
  UNIT_REQUEST,
} from "../../../../../_utils/constants/ProductCatalog";
import {
  STORE_INFO_REQUEST,
} from "../../../../../_utils/constants/admin/StoreInfo";
import { toast } from 'react-toastify';

const List = ({
  auth: { auth, isAuthenticated },
  LaborItem: { laborItem, isCreated, isDeleted, isEdited, isLoading, totalCount },
  LaborCategory: { laborCategory },
  ProductType,
  unit,
  StoreInfo: { storeInfo },
  fetchLaborItem,
  fetchLaborFilterItem,
  CreateLaborItemRequest,
  changeReducerState,
  submitLaborItemDelete,
  submitLaborItemEdit,
  fetchLaborCategory,
  fetchProductType,
  fetchUnit,
  fetchStoreInfo,
  submitLaborItemUpdate
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [product_id, setProduct_id] = useState("");
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [stateFilter, setStateFilter] = useState({
    "laborName": "",
    "storeId": 0,
    "productTypeId": 0,
    "productStructureId": 0,
    "laborCategoryId": 0,
    "unitId": 0,
    "currentCost": 0,
    "futureCost1": 0,
    "increaseDate": "",
    "sortOn": "",
  })
  const [state, setState] = useState({
    "laborName": "",
    "storeId": 0,
    "productStructureId": 0,
    "laborCategoryId": 0,
    "unitId": 0,
    "currentCost": 0,
    "isAddOn": false
  })
  const [updateState, setUpdateState] = useState({
    "currentCost": 0,
    "futureCost1": 0,
    "increaseDate": ""
  })
  const [laborId, setlaborId] = useState("");

  const navigate = useNavigate();

  // handle user auth
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login")
    }
  }, [auth, isAuthenticated])
  
  const handleChange = (e) => {
    const { id, value, checked } = e.target;
    if (id === 'isAddOn') {
      setState({
        ...state,
        [id]: checked
      })
    } else {
      setState({
        ...state,
        [id]: value
      })
    }
  }
  const handleFilterChange = (e) => {
    const { id, value } = e.target;
    setStateFilter({
      ...stateFilter,
      [id]: value
    })
  }


  const handleEdit = (e, is) => {
    e.preventDefault();
    setlaborId(is.laborItemId);
    setState(is)
    setIsOpen(true);
  }
  const handleModal = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen)
    setValidated(false)
  }
  const hideModel = () => {
    setIsOpen(false)
    setValidated(false);
    setlaborId("");
    setState({
      "laborName": "",
      "storeId": "",
      "productTypeId": "",
      "laborCategoryId": "",
      "unitId": "",
      "currentCost": 0,
      "isAddOn": false,
    });
    setStateFilter({
      "laborName": "",
      "storeId": 0,
      "productTypeId": 0,
      "productStructureId": 0,
      "laborCategoryId": 0,
      "unitId": 0,
      "currentCost": 0,
      "futureCost1": 0,
      "increaseDate": "",
      "sortOn": "",
    });
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchLaborCategory(auth[0].token)
    }

  }, [isAuthenticated, auth])
  useEffect(() => {
    if (isAuthenticated) {

      fetchLaborItem(auth[0].token, sort, page, paginate, stateFilter)
    }

  }, [isAuthenticated, auth, paginate])
  useEffect(() => {
    if (isAuthenticated) {
      fetchLaborFilterItem(auth[0].token, sort, page, paginate, stateFilter)
    }

  }, [isAuthenticated, auth, stateFilter])

  useEffect(() => {
    if (isAuthenticated) {
      fetchProductType(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      fetchUnit(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      fetchStoreInfo(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  const productStructure = useMemo(() => {
    if (ProductType)
      return ProductType.valueList.map(v => ({ label: v.productStructure, value: v.productStructureId }))
  }, [ProductType])
  const unitList = useMemo(() => {
    if (unit.valueList)
      return unit.valueList.map(v => ({ label: v.unitName, value: v.unitId }))
  }, [unit])
  const storeList = useMemo(() => {
    if (storeInfo)
      return storeInfo.map(v => ({ label: v.storeName, value: v.storeId }))
  }, [storeInfo])
  const laborCategories = useMemo(() => {
    if (laborCategory)
      return laborCategory.map(v => ({ label: v.laborCategory, value: v.laborCategoryId }))
  }, [laborCategory])

  // use effect function called after api success
  useMemo(() => {
    if (isAuthenticated) {
      if (isCreated === true) {
        toast.success("Labor item created successfully.");
        changeReducerState();
        hideModel();
      }
      if (isDeleted === true) {
        toast.success("Labor item deleted successfully.");
        changeReducerState();
      }
      if (isEdited === true) {
        toast.success("Labor item updated successfully.")
        changeReducerState();
        hideModel()
        // setProduct_id("")
      }
      fetchLaborItem(auth[0].token, sort, page, paginate, stateFilter)

    }

  }, [isCreated, isDeleted, isEdited, isAuthenticated]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      if (laborId) {
        var requestData =
        {
          "data": {
            "LaborName": state.laborName,
            "StoreId": parseInt(state.storeId),
            "ProductStructureId": parseInt(state.productStructureId),
            "LaborCategoryId": parseInt(state.laborCategoryId),
            "UnitId": parseInt(state.unitId),
            "CurrentCost": parseFloat(state.currentCost).toFixed(2),
            "laborItemId": laborId,
            "ActionType": "",
            "IsAddon": state.isAddOn
          },
          "token": auth[0].token
        }
        submitLaborItemEdit(requestData)
      } else {
        var requestData = {
          "data": {
            "LaborName": state.laborName,
            "StoreId": parseInt(state.storeId),
            "ProductStructureId": parseInt(state.productStructureId),
            "LaborCategoryId": parseInt(state.laborCategoryId),
            "UnitId": parseInt(state.unitId),
            "CurrentCost": parseFloat(state.currentCost).toFixed(2),
            "CreatedBy": auth[0].userId,
            "IsAddon": state.isAddOn
          },
          "token": auth[0].token
        }
        CreateLaborItemRequest(requestData);
      }

    }
    setValidated(true)
  }

  const column = ["Item"];
  const data = useMemo(() => laborItem, [laborItem])
  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteItemId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteItemId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    submitLaborItemDelete({
      "laborItemId": deleteItemId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }
  const editForm = useMemo(() => laborId, [laborId])
  const handleChangeCurrency = (value, name) => {

    setState(
      {
        ...state,
        [name]: value
      }
    )
  }
  const handleFilterChangeCurrency = (value, name) => {
    setStateFilter({
      ...stateFilter,
      [name]: value ? value : ""
    })
  }
  const handleCurrencyUpdateChange = (value, name, prod) => {

    setProduct_id(prod.laborItemId);
    var dataUpdate =
    {
      "laborItemId": prod.laborItemId,
      "currentCost": prod.currentCost,
      "futureCost1": prod.futureCost1,
      "increaseDate": prod.increaseDate,
      "ActionType": "UpdateCast"
    }
    dataUpdate[name] = value;

    setUpdateState(dataUpdate)
    var requestData = {
      "data": dataUpdate,
      "token": auth[0].token
    }
    submitLaborItemUpdate(requestData);
  }
  const handleUpdateChange = (e, prod) => {
    const { id, value } = e.target;
    setProduct_id(prod.laborItemId);
    var dataUpdate =
    {
      "laborItemId": prod.laborItemId,
      "currentCost": prod.currentCost,
      "futureCost1": prod.futureCost1,
      "increaseDate": prod.increaseDate,
      "ActionType": "UpdateCast"
    }
    dataUpdate[id] = value;
    setUpdateState(dataUpdate)
    var requestData = {
      "data": dataUpdate,
      "token": auth[0].token
    }
    submitLaborItemUpdate(requestData);
  }
  return (
    <Fragment>
      <MainWrapper>
        <Loader loading={isLoading}>
          <div className="container">
            <div className="sub-header mt-5 mb-3">
              <h2>Labor Items</h2>
              <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
              <CustomButton
                handleClick={handleModal}
                variant="primary"
              >Add New Labor Item
              </CustomButton>
            </div>
            <Table
              laborItem={data}
              column={column}
              showConfirmDeleteModal={showConfirmDeleteModal}
              hideConfirmDeleteModal={hideConfirmDeleteModal}
              handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
              confirmDeleteModal={confirmDeleteModal}
              handleEdit={handleEdit}
              stateFilter={stateFilter}
              handleFilterChange={handleFilterChange}
              productStructure={productStructure}
              units={unitList}
              storeList={storeList}
              laborCategories={laborCategories}
              handleFilterChangeCurrency={handleFilterChangeCurrency}
              handleCurrencyUpdateChange={handleCurrencyUpdateChange}
              updateState={updateState}
              handleUpdateChange={handleUpdateChange}
              product_id={product_id}
              setSort={setSort}
              setPaginate={setPaginate}
              setPage={setPage}
              page={page}
              paginate={paginate}
              totalCount={totalCount}
            />
          </div>
        </Loader>
        <AddEdit
          isOpen={isOpen}
          size={'lg'}
          state={state}
          handleChange={handleChange}
          validated={validated}
          handleSubmit={handleSubmit}
          hideModel={hideModel}
          laborId={editForm}
          productStructure={productStructure}
          units={unitList}
          storeList={storeList}
          laborCategories={laborCategories}
          handleChangeCurrency={handleChangeCurrency}
          isLoading={isLoading}
        />
      </MainWrapper>
    </Fragment>
  )
}


const mapStateToProps = ({ auth, LaborItem, ProductType, unit, StoreInfo, LaborCategory }) => {
  return {
    auth, LaborItem, ProductType, unit, StoreInfo, LaborCategory
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLaborItem: (token, sort, page, paginate, stateFilter) => dispatch({ type: LABOR_ITEM_REQUEST, token, sort, page, paginate, stateFilter }),
    fetchLaborFilterItem: (token, sort, page, paginate, stateFilter) => dispatch({ type: LABOR_ITEM_FILTER_REQUEST, token, sort, page, paginate, stateFilter }),
    changeReducerState: () => dispatch({ type: LABOR_ITEM_STATE_CHANGE }),
    CreateLaborItemRequest: (data) => dispatch({ type: LABOR_ITEM_CREATE, payload: data }),
    submitLaborItemDelete: (data) => dispatch({ type: LABOR_ITEM_DELETE, payload: data }),
    submitLaborItemEdit: (data) => dispatch({ type: LABOR_ITEM_EDIT, payload: data }),
    submitLaborItemUpdate: (data) => dispatch({ type: LABOR_ITEM_UPDATE, payload: data }),
    fetchProductType: (token) => dispatch({ type: PRODUCT_TYPE_REQUEST, token }),
    fetchUnit: (token) => dispatch({ type: UNIT_REQUEST, token }),
    fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
    fetchLaborCategory: (token) => dispatch({ type: LABOR_CATEGORY_REQUEST, token }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(List);