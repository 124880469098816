import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';

const EditNote = ({
  title,
  show,
  hideCreateModal,
  handleEditSaveClick,
  handleChange,
  state,
}) => {

  return (
    <Fragment>

      <Modal show={show} size="xl">
        <Modal.Header>Edit {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="contactName">
                  <Form.Label>ContactName</Form.Label>
                  <Form.Control type="text" name="contactName" onChange={handleChange} value={state.contactName} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="contactType">
                  <Form.Label>ContactType</Form.Label>
                  <Form.Control type="text" name="contactType" onChange={handleChange} value={state.contactType} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="contactDetail">
                  <Form.Label>ContactDetail</Form.Label>
                  <Form.Control type="text" name="contactDetail" onChange={handleChange} value={state.contactDetail} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="contactFor">
                  <Form.Label>ContactFor</Form.Label>
                  <Form.Control type="text" name="contactFor" onChange={handleChange} value={state.contactFor} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="contactForPersonId">
                  <Form.Label>ContactForPersonId</Form.Label>
                  <Form.Control type="text" name="contactForPersonId" onChange={handleChange} value={state.contactForPersonId} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default EditNote;