import {
  FORM_VALUE_LIST_REQUEST,
  FORM_VALUE_LIST_SUCCESS,
  FORM_VALUE_LIST_FAILED,
  FORM_VALUE_LIST_DELETE_REQUEST,
  FORM_VALUE_LIST_DELETE_SUCCESS,
  FORM_VALUE_LIST_DELETE_FAILED,
  FORM_VALUE_LIST_CREATE_REQUEST,
  FORM_VALUE_LIST_CREATE_SUCCESS,
  FORM_VALUE_LIST_CREATE_FAILED,
  FORM_VALUE_LIST_EDIT_REQUEST,
  FORM_VALUE_LIST_EDIT_SUCCESS,
  FORM_VALUE_LIST_EDIT_FAILED
} from "../../../_utils/constants/ValueList";
import _ from 'lodash';

const initialState = {
  formValueList: [],
  isLoading: false,
  isCreated: false,
  isEdited: false,
  isDeleted: false,
  errors: {},
  success: false,
};


const formValueListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FORM_VALUE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {},
        success: false,
        isDeleted: false,
        isCreated: false,
        isEdited: false,
        formValueList: []
      }
    case FORM_VALUE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formValueList: _.get(payload, "result", []),
        success: true,
        errors: {},
      }
    case FORM_VALUE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        errors: _.get(payload, "data", {}),
      }
    case FORM_VALUE_LIST_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case FORM_VALUE_LIST_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case FORM_VALUE_LIST_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: payload
      }
    case FORM_VALUE_LIST_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case FORM_VALUE_LIST_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case FORM_VALUE_LIST_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: payload
      }
    case FORM_VALUE_LIST_EDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case FORM_VALUE_LIST_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case FORM_VALUE_LIST_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: payload
      }
    default:
      return state;
  }
}

export default formValueListReducer;