import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, Button, InputGroup } from 'react-bootstrap';
// import { encrypted } from '../../../../_utils/helpers';
import { connect } from 'react-redux';
import Create from "./models/Create";
import ConfirmDelete from "./models/ConfirmDelete";
import {
	SHIPPINGMETHODS_REQUEST,
	SHIPPINGMETHODS_CREATE,
	SHIPPINGMETHODS_DELETE,
	SHIPPINGMETHODS_EDIT,
	SHIPPINGMETHODS_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const ShippingMethods = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchShippingMethods,
	submitShippingMethodCreate,
	submitShippingMethodDelete,
	submitShippingMethodEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [inputVal, setInputVal] = useState('');
	const [deleteShippingMethodId, setDeleteShippingMethodId] = useState('');
	const [editShippingMethodId, setEditShippingMethodId] = useState('');
	const [editShippingMethodName, setEditShippingMethodName] = useState('');

	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchShippingMethods(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("Shipping Method Created Successfully.");
			fetchShippingMethods(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Shipping Method Deleted Successfully.");
			fetchShippingMethods(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Shipping Method Data Updated Successfully.");
			fetchShippingMethods(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleCreateModalChange = (e) => {
		setInputVal(e.target.value);
	}

	// function called for create shipping method create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (inputVal === '') {
			toast.error("Shipping Method name cannot be empty.");
		} else {
			submitShippingMethodCreate({
				"data": {
					"Method": inputVal,
					"CreatedBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteShippingMethodId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteShippingMethodId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete shipping method delete api
	const handleConfirmDeleteSubmit = () => {
		submitShippingMethodDelete({
			"methodId": deleteShippingMethodId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditShippingMethodId(data.methodId);
		setEditShippingMethodName(data.method);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editShippingMethodName === '') {
			toast.error("Shipping Method name cannot be empty.");
		} else {
			submitShippingMethodEdit({
				"data": {
					"MethodId": data.methodId,
					"Method": editShippingMethodName
				},
				"token": auth[0].token
			});
			setEditShippingMethodId('');
			setEditShippingMethodName('');
		}
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<ToastContainer />
					<div className="sub-header mt-5 mb-3">
						<h2>Shipping Methods</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Shipping Method`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							inputVal={inputVal}
							handleCreateModalChange={handleCreateModalChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Methods Name</th>
								<th width="50" className="actions">
									<div className="d-flex">
										<span>Delete</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									<tr key={index}>
										{editShippingMethodId === item.methodId ? (
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editShippingMethodName} onChange={(evt) => setEditShippingMethodName(evt.target.value)} />
														<Button variant="outline-secondary">
															<Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
														</Button>
													</InputGroup>
												</Form.Group>
											</td>
										) : (
											<td>
												{item.method}
												<Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
											</td>
										)}
										<td>
											<ConfirmDelete variant="primary" title={`Shipping Method`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.methodId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={2}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	)
}

const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchShippingMethods: (token) => dispatch({ type: SHIPPINGMETHODS_REQUEST, token }),
		submitShippingMethodCreate: (data) => dispatch({ type: SHIPPINGMETHODS_CREATE, payload: data }),
		submitShippingMethodDelete: (data) => dispatch({ type: SHIPPINGMETHODS_DELETE, payload: data }),
		submitShippingMethodEdit: (data) => dispatch({ type: SHIPPINGMETHODS_EDIT, payload: data }),
		changeReducerState: () => dispatch({ type: SHIPPINGMETHODS_STATE_CHANGE })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ShippingMethods);