import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { connect } from "react-redux";
import { useParams, Navigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import MainWrapper from "../../MainWrapper";
import { CUSTOMER_LIST_REQUEST } from "../../../_utils/constants/Customer";
import Table from "./estimatesModels/estimateTable";
import { CUSTOMER_ESTIMATES_REQUEST } from "../../../_utils/constants/PricingContract";
import "../../../assets/css/estimates.css";
import standardinteriorslogo from "../../../assets/images/standardinteriors2.png";
import StandardLogoLV from "../../../assets/images/Standard-Logo-LV.png";
import StandardLogoAZ from "../../../assets/images/Standard-Logo-AZ.png";
import StandardLogoCO from "../../../assets/images/Standard-Logo-CO.png";

const Estimates = ({
  auth: { auth, isAuthenticated },
  customerInfo: { customerList, isLoading },
  pricingContract: { estimates },
  fetchCustomerList,
  fetchCustomerEstimates,
}) => {
  const { id } = useParams();
  const [customerInfo, setCustomerInfo] = useState({});
  const [estimatesData, setEstimatesData] = useState([]);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    if (isAuthenticated) {
      fetchCustomerList(
        auth[0].token,
        "asc",
        1,
        1,
        { contactInfoId: id },
        auth[0].userId,
        false,
        false,
        false
      );
      fetchCustomerEstimates(auth[0].token, id);
    }
    if (!isAuthenticated) return <Navigate to="/login" />;
  }, [auth, isAuthenticated]);

  useEffect(() => {
    if (estimates.length > 0) {
      setEstimatesData(estimates);
    }
  }, [estimates]);

  useEffect(() => {
    if (customerList.length > 0) {
      if (customerList[0].storeId === 12) {
        setImageUrl(StandardLogoCO);
      } else if (customerList[0].storeId === 58) {
        setImageUrl(StandardLogoAZ);
      } else if (customerList[0].storeId === 78) {
        setImageUrl(StandardLogoLV);
      } else {
        setImageUrl(standardinteriorslogo);
      }
      setCustomerInfo(customerList[0]);
    }
  }, [customerList]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <MainWrapper className="page-print-container">
      <Button onClick={handlePrint}>Print this out!</Button>
      <div
        ref={componentRef}
        className="print-container"
        style={{ margin: "0", padding: "0" }}
      >
        <img src={imageUrl} alt="Standard Logo" className="logo" />
        <h1>Estimate Prepared For</h1>
        <h3>{customerInfo.shipToPropertyName}</h3>
        <p>Estimates valid for 30 days</p>
        <Table estimatesData={estimatesData} />
        <div className="pagebreak" />
        <p>
          * All pricing is subject to labor minimum charges and/or trip charges.
          Pricing is good for 30 days after receipt and is subject to change.
        </p>
        <p>
          Change Orders: Price includes standard floor preparation, defined as
          light skimcoating of hairline cracks or small recesses (less than
          2&quot; in diameter and 1/2&quot; in depth). Anything above is
          atypical and will incur additional floor prep costs. All changes
          /additional costs will be quoted for approval prior to commencement of
          additional work. Price does not include moving furniture, toilets,
          appliances, or modifications to any other unit finishes, electronics,
          or plumbing. Furniture, toilet, or appliance moving must be requested
          before day of install, and is limited to specific items. Additional
          costs will be added to order. Warranty: Standard Interiors warrants
          all labor for a period of one year, with the exception of LVT plank
          installations that are warranted for two years. All materials are
          warranted by the manufacturer.
        </p>
      </div>
    </MainWrapper>
  );
};

const mapStateToProps = ({ auth, customerInfo, pricingContract }) => {
  return {
    auth,
    customerInfo,
    pricingContract,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomerList: (
      token,
      sort,
      page,
      paginate,
      listParamState,
      userId,
      salesPersonId,
      GrpPcId,
      isChecked
    ) =>
      dispatch({
        type: CUSTOMER_LIST_REQUEST,
        token,
        sort,
        page,
        paginate,
        listParamState,
        userId,
        salesPersonId,
        GrpPcId,
        isChecked,
      }),
    fetchCustomerEstimates: (token, contactInfoId) =>
      dispatch({ type: CUSTOMER_ESTIMATES_REQUEST, token, contactInfoId }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Estimates);
