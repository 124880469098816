import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  PRODUCT_PRICE_DETAIL_REQUEST,
  PRODUCT_PRICE_DETAIL_SUCCESS,
  PRODUCT_PRICE_DETAIL_FAILED,
  PRODUCT_PRICE_DETAIL_CREATE,
  PRODUCT_PRICE_DETAIL_CREATE_SUCCESS,
  PRODUCT_PRICE_DETAIL_CREATE_FAILED,
  PRODUCT_PRICE_DETAIL_DELETE,
  PRODUCT_PRICE_DETAIL_DELETE_SUCCESS,
  PRODUCT_PRICE_DETAIL_DELETE_FAILED,
  PRODUCT_PRICE_DETAIL_EDIT,
  PRODUCT_PRICE_DETAIL_EDIT_SUCCESS,
  PRODUCT_PRICE_DETAIL_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * ProductPriceDetail Actions
 */

function* fetchProductPriceDetail({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ProductPriceDetail/GetProductPriceDetail`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: PRODUCT_PRICE_DETAIL_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PRODUCT_PRICE_DETAIL_FAILED, payload: error.response });
    }
  }
}

function* createProductPriceDetail(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ProductPriceDetail/CreateProductPriceDetail`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_PRICE_DETAIL_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_PRICE_DETAIL_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editProductPriceDetail(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ProductPriceDetail/UpdateProductPriceDetail`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_PRICE_DETAIL_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_PRICE_DETAIL_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteProductPriceDetail(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/ProductPriceDetail/RemoveProductPriceDetail?ProductPriceDetailId=${encodeURIComponent(action.payload.ProductPriceDetailId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: PRODUCT_PRICE_DETAIL_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_PRICE_DETAIL_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootProductPriceDetailList() {
  yield all([
    takeLatest(PRODUCT_PRICE_DETAIL_REQUEST, fetchProductPriceDetail),
    takeLatest(PRODUCT_PRICE_DETAIL_CREATE, createProductPriceDetail),
    takeLatest(PRODUCT_PRICE_DETAIL_DELETE, deleteProductPriceDetail),
    takeLatest(PRODUCT_PRICE_DETAIL_EDIT, editProductPriceDetail),
  ]);
}