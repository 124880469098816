import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  ORDER_USER_REQUEST,
  // ORDER_USER_SUCCESS,
  ORDER_USER_FAILED,
  USER_ALREADY_EXIST_REQUEST,
  USER_ALREADY_EXIST_SUCCESS,
  USER_ALREADY_EXIST_FAILED
} from '../../_utils/constants/Auth';
import axios from 'axios';
import { SIGN_API_URL } from "../../config";
//import { decrypted } from "../../_utils/helpers";

function* Login({ data }) {
  try {
    const response = yield call(axios.post, `${SIGN_API_URL}/authentication/api/login/signin`, data, {
      header: {
        "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": `${SIGN_API_URL}`,
        "Access-Control-Allow-Methods": "OPTIONS,POST"
      }
    });
    yield put({ type: LOGIN_USER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: LOGIN_USER_FAILED, payload: error.response });
  }
}

function* fetchCookie(details) {
  try {
    var name = details.data;

    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        var response = c.substring(nameEQ.length, c.length);
        var data = JSON.parse(response);
        document.cookie = name + "=;domain=s10drd.com" + "; path=/;";
      yield put({ type: LOGIN_USER_REQUEST, data });
    }
  } catch (error) {
    yield put({ type: ORDER_USER_FAILED, payload: error.response });
  }

  return null;
}

function* logout() {

  yield put({ type: LOGOUT_SUCCESS });
}

function* userAlreadyExist({ data }) {
  try {
    const response = yield call(axios.get, `${SIGN_API_URL}/User/api/Signup/UserAlreadyExist?EmailId=${data.EmailId}`, {
      header: {
        "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": `${SIGN_API_URL}`,
        "Access-Control-Allow-Methods": "OPTIONS,POST"
      }
    });
    yield put({ type: USER_ALREADY_EXIST_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER_ALREADY_EXIST_FAILED, payload: error.response });
  }
}

export default function* rootAuth() {
  yield all([
    takeLatest(LOGIN_USER_REQUEST, Login),
    takeLatest(ORDER_USER_REQUEST, fetchCookie),
    takeLatest(LOGOUT_REQUEST, logout),
    takeLatest(USER_ALREADY_EXIST_REQUEST, userAlreadyExist),
  ]);
}