import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  CONTACT_ROLL_LIST_REQUEST,
  CONTACT_ROLL_LIST_SUCCESS,
  CONTACT_ROLL_LIST_FAILED,
  CONTACT_ROLL_CREATE_REQUEST,
  CONTACT_ROLL_CREATE_SUCCESS,
  CONTACT_ROLL_CREATE_FAILED,
  CONTACT_ROLL_EDIT_REQUEST,
  CONTACT_ROLL_EDIT_SUCCESS,
  CONTACT_ROLL_EDIT_FAILED,
  CONTACT_ROLL_DELETE_REQUEST,
  CONTACT_ROLL_DELETE_SUCCESS,
  CONTACT_ROLL_DELETE_FAILED,
} from "../../../_utils/constants/ContactRoll";

function* fetchContactRollList(action) {

  try {
    const response = yield call(axios.get, `${API_URL}/User/api/ContactsRoll/GetContactsRollList`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: CONTACT_ROLL_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: CONTACT_ROLL_LIST_FAILED, payload: error.response });
    }
  }
}
function* contactRollCreateRequest(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/ContactsRoll/CreateContactsRoll`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: CONTACT_ROLL_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CONTACT_ROLL_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* contactRollEditRequest(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/User/api/ContactsRoll/UpdateContactsRoll`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: CONTACT_ROLL_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CONTACT_ROLL_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* contactRollDeleteRequest(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/User/api/ContactsRoll/RemoveContactsRoll?rollId=${encodeURIComponent(action.payload.contactRollId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: CONTACT_ROLL_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: CONTACT_ROLL_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
export default function* rootContactRoll() {
  yield all([
    takeLatest(CONTACT_ROLL_LIST_REQUEST, fetchContactRollList),
    takeLatest(CONTACT_ROLL_CREATE_REQUEST, contactRollCreateRequest),
    takeLatest(CONTACT_ROLL_DELETE_REQUEST, contactRollDeleteRequest),
    takeLatest(CONTACT_ROLL_EDIT_REQUEST, contactRollEditRequest),
  ])
}