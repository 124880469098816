import React from "react";
import { Routes, Route } from "react-router-dom";
import Customer from "../components/pages/customer/Customer";
import CustomerDetail from "../components/pages/customer/CustomerDetail";
import OrderConfirmation from "../components/pages/placeOrder/OrderConfirmation";
import Estimates from "../components/pages/customer/Estimates";

const CustomerRoute = () => {
  return (
    <Routes>
      <Route path="/customer-list" exact element={<Customer />} />
      <Route path="/customer-list/customer-detail/:id" exact element={<CustomerDetail />} />
      <Route path="/place-order/order-confirmation/contactInfoId=:contactInfoId/unitId=:unitId/jobNum=:jobNum" exact element={<OrderConfirmation />} />
      <Route path="/estimating-customer-info" exact element={<Customer />} />
      <Route path="/sale-customer-info" exact element={<Customer />} />
      <Route path="/customer-list/customer-detail/estimates/:id" exact element={<Estimates />} />
    </Routes>
  );
};

export default CustomerRoute;