import {
  PRODUCT_TYPE_REQUEST,
  PRODUCT_TYPE_SUCCESS,
  PRODUCT_TYPE_FAILED,
  PRODUCT_TYPE_CREATE,
  PRODUCT_TYPE_CREATE_SUCCESS,
  PRODUCT_TYPE_CREATE_FAILED,
  PRODUCT_TYPE_DELETE,
  PRODUCT_TYPE_DELETE_SUCCESS,
  PRODUCT_TYPE_DELETE_FAILED,
  PRODUCT_TYPE_EDIT,
  PRODUCT_TYPE_EDIT_SUCCESS,
  PRODUCT_TYPE_EDIT_FAILED,
  PRODUCT_TYPE_STATE_CHANGE,
  SUB_PRODUCT_TYPE_REQUEST,
  SUB_PRODUCT_TYPE_SUCCESS,
  SUB_PRODUCT_TYPE_FAILED,
  GET_SUB_PRODUCT_REQUEST,
  GET_SUB_PRODUCT_SUCCESS,
  GET_SUB_PRODUCT_FAILED,
  GET_SUB_PRODUCT_MATERIAL_REQUEST,
  GET_SUB_PRODUCT_MATERIAL_SUCCESS,
  GET_SUB_PRODUCT_MATERIAL_FAILED,
  GET_TRANSITION_SUB_PRODUCT_REQUEST,
  GET_TRANSITION_SUB_PRODUCT_SUCCESS,
  GET_TRANSITION_SUB_PRODUCT_FAILED
} from "../../../_utils/constants/ProductCatalog";


const initialState = {
  valueList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
  subProductValue: [],
  getSubProductData: [],
  getSubProductMaterialData: [],
  getTransitionSubProductData: [],
  isCreatedData: [],
  isEditedData: [],
};

const ProductTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case PRODUCT_TYPE_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        valueList: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case PRODUCT_TYPE_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case PRODUCT_TYPE_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case PRODUCT_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        isCreatedData: action.payload.result,
        errors: {}
      }
    case PRODUCT_TYPE_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case PRODUCT_TYPE_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case PRODUCT_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case PRODUCT_TYPE_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case PRODUCT_TYPE_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case PRODUCT_TYPE_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        isEditedData: action.payload.result,
        errors: {}
      }
    case PRODUCT_TYPE_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }
    case SUB_PRODUCT_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case SUB_PRODUCT_TYPE_SUCCESS:
      var decryptedData = action?.payload;
      return {
        ...state,
        subProductValue: decryptedData?.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case SUB_PRODUCT_TYPE_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action?.payload
      }
      case GET_SUB_PRODUCT_REQUEST:
        return {
          ...state,
          isLoading: true,
          isSuccess: false,
          errors: {}
        }
      case GET_SUB_PRODUCT_SUCCESS:
        var decryptedData = action?.payload;
        return {
          ...state,
          getSubProductData: decryptedData?.result,
          isLoading: false,
          isSuccess: true,
          errors: {}
        }
      case GET_SUB_PRODUCT_FAILED:
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          errors: action?.payload
        }
      case GET_SUB_PRODUCT_MATERIAL_REQUEST:
        return {
          ...state,
          isLoading: true,
          isSuccess: false,
          errors: {}
        }
      case GET_SUB_PRODUCT_MATERIAL_SUCCESS:
        var decryptedData = action?.payload;
        return {
          ...state,
          getSubProductMaterialData: decryptedData?.result,
          isLoading: false,
          isSuccess: true,
          errors: {}
        }
      case GET_SUB_PRODUCT_MATERIAL_FAILED:
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          errors: action?.payload
        }
      case GET_TRANSITION_SUB_PRODUCT_REQUEST:
        return {
          ...state,
          isLoading: true,
          isSuccess: false,
          errors: {}
        }
      case GET_TRANSITION_SUB_PRODUCT_SUCCESS:
        var decryptedData = action?.payload;
        return {
          ...state,
          getTransitionSubProductData: decryptedData?.result,
          isLoading: false,
          isSuccess: true,
          errors: {}
        }
      case GET_TRANSITION_SUB_PRODUCT_FAILED:
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          errors: action?.payload
        }

    case PRODUCT_TYPE_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default ProductTypeReducer;