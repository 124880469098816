import React, { Fragment, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import MainWrapper from "../../../MainWrapper";
import { Link, useNavigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back } from 'react-icons-kit/md';
import Loader from ".././../../layouts/Loader";
import CustomButton from "../../../elements/CustomButton";
import {
  ROLE_LIST_REQUEST,
  ROLE_CREATE_REQUEST,
  ROLE_EDIT_REQUEST,
  ROLE_DELETE_REQUEST,
  ROLE_DROPDOWN_REQUEST,
  ROLE_STATE_CHANGE
} from "../../../../_utils/constants/Role";
import CustomTable from "./CustomTable";
import AddEdit from "./AddEdit";
import { toast } from 'react-toastify';

const RoleList = ({
  auth: { auth, isAuthenticated },
  Role: { roleList, productStructures, isCreated, isDeleted, isEdited, isLoading, errors, isDeletedData },
  fetchRoleList,
  fetchRoleDropdownDataList,
  roleCreateRequest,
  roleEditRequest,
  roleDeleteRequest,
  changeReducerState,
}) => {

  // state declaration
  const [isOpen, setIsOpen] = useState(false)
  const [validated, setValidated] = useState(false);
  const [roleListing, setRoleListing] = useState([])
  const [productStructure, setProductStructure] = useState([])
  const [state, setState] = useState({
    "roleName": "",
    "parentRoleId": "",
    "roleId": "",
    "menuId": [],
    "roleType": "",
    "rolesId": ""
  })
  const [erpMenuData, setErpMenuData] = useState([])
  const [orderMenuData, setOrderMenuData] = useState([])
  const [roleType, setRoleType] = useState([])
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [deleteCategoryId, setDeleteCategoryId] = useState("")
  const navigate = useNavigate()
  const [disableMenuValue, setDisableMenuValue] = useState([])

  // Check the authentication and fetch apis
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login")
    }
    if (isAuthenticated) {
      fetchRoleList(auth[0].token)
      fetchRoleDropdownDataList(auth[0].token)
    }
  }, [isAuthenticated, auth])

  // Check and set the role list state
  useMemo(() => {
    if (roleList.length > 0) {
      setRoleListing(roleList)
    }
  }, [roleList])

  // Check and set the product structure state
  useEffect(() => {
    if (productStructures.length > 0) {
      setProductStructure(productStructures)
      setErpMenuData(productStructures[0]?.erpMenuList[0]?.permission)
      setOrderMenuData(productStructures[0]?.orderMenuList[0]?.permission)
      let tempRole = []
      if (productStructures[0]?.role_Type.length > 0) {
        productStructures[0]?.role_Type.map( data => {
          tempRole.push({'label': data?.roleName, 'value': data?.roleId})
        })
      }
      setRoleType(tempRole)
    }
  }, [productStructures])

  // Function to handle state for disabling menu
  useEffect(() => {
    if (state.menuId && state.menuId.length > 0) {
      // Temporary array containing the required menu names
      let tempArray = ['Estimating Customer info', 'Sales Customer info']
      let isPresent = tempArray.some(item => state.menuId.some(obj => obj.menuName === item))
      if (isPresent) {
        let menuNames = tempArray.filter(item => !state.menuId.some(obj => obj.menuName === item))
        setDisableMenuValue(menuNames)
      } else {
        setDisableMenuValue([])
      }
    }
  }, [state])

  // function to hide modal for add/edit
  const hideModel = () => {
    setIsOpen(false)
    setValidated(false)
    setState({
      "roleName": "",
      "parentRoleId": "",
      "roleId": "",
      "menuId": [],
      "roleType": "",
      "rolesId": ""
    })
    setDisableMenuValue([])
  }

  // function to hide close modal for add/edit
  const hideCloseModel = () => {
    hideModel()
    window.location.reload()
  }

  // use effect function called after api success
  useMemo(() => {
    if (isAuthenticated) {
      fetchRoleDropdownDataList(auth[0].token);
    }
    [auth, isAuthenticated];
    if (isCreated === true) {
      toast.success("Role created successfully.");
      changeReducerState();
      hideModel();
    }
    if (isDeleted === true) {
      if (isDeletedData.responsestatus === 'Error') {
        if (isDeletedData.result.length > 0) {
          toast.error(isDeletedData.result[0]);
        } else {
          toast.error('Error')
        }
      } else {
        toast.success("Role deleted successfully.");
        changeReducerState();
      }
    }
    if (isEdited === true) {
      toast.success("Role  updated successfully.")
      changeReducerState();
      hideModel();
    }
    if (isAuthenticated) {
      fetchRoleList(auth[0].token)
    }
  }, [isCreated, isDeleted, isEdited]);

  //Function to handle error from response
  useMemo(() => {
    if (!_.isEmpty(errors)) {
      toast.error(errors.data.result[0])
      changeReducerState();
      fetchRoleList(auth[0].token)
    }
  }, [errors])

  // Edit method code start here
  const handleEdit = (e, data) => {
    let tempParentRoleId = null
    if (roleType && roleType.length > 0) {
      roleType.map( roleData => {
        if (roleData.label === data.roleType) {
          tempParentRoleId = roleData.value
        }
      })
    }
    setState({
      "roleName": data.roleName,
      "parentRoleId": tempParentRoleId,
      "roleId": data.roleId,
      "menuId": data.roleAccess?.permission,
      "roleType": data.roleType,
      "rolesId": data.rolesId
    })
    setIsOpen(true)
  }

  // function to show modal for add/edit
  const handleModal = (e) => {
    e.preventDefault()
    setIsOpen(true)
    setValidated(false)
  }

  // function to handle input change
  const handleChange = (e) => {
    const { id, value } = e.target
    let tempRole = null
    let tempRoleId = null
    if (id === 'parentRoleId') {
      if (roleType && roleType.length > 0) {
        roleType.map( roleData => {
          if (roleData.value === parseInt(value)) {
            tempRole = roleData.label
            tempRoleId = roleData.value
          }
        })
      }
      if (parseInt(state.parentRoleId) === tempRoleId) {
        setState({...state, [id]: value, 'roleType': tempRole})
      } else {
        setState({...state, [id]: value, 'roleType': tempRole, 'menuId': []})
      }
    }
    if (id === 'roleName') {
      setState({...state, [id]: value})
    }
  }

  // function to manage payload data for add and edit roles
  const payloadData = (tempRole) => {
    let tempRolesId = ""
    if (parseInt(state.rolesId)) {
      tempRolesId = state.rolesId
    } else {
      tempRolesId = tempRole
    }
    let data = {
      "RoleName": state.roleName,
      "ParentRoleId": parseInt(state.parentRoleId),
      "CreatedBy": auth[0].userId,
      "CreatedBy": auth[0].userId,
      "roleAccess": {
        'menuId': null,
        'permission': state.menuId,
        'rolesId': tempRolesId,
      },
    }
    if (parseInt(state.roleId) > -1) {
      data.RoleId = state.roleId
    }
    let requestData = {
      "data": data,
      "token": auth[0].token
    }
    return requestData
  }

  // function to handle form submit
  const handleSubmit = (e) => {
    e.preventDefault()

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let isValid = true
      if (state.roleName.toLowerCase() === 'employee' || state.roleName.toLowerCase() === 'customer') {
        isValid = false
        toast.error('Rolename cannot be same as role type')
      }

      if (isValid) {
        if (state.menuId && state.menuId.length > 0) {
          let payload = null
          if (state.roleType === 'Employee') {
            let tempRolesId = productStructure[0].erpMenuList[0].rolesId
            payload = payloadData(tempRolesId)
          } else {
            let tempRolesId = productStructure[0].orderMenuList[0].rolesId
            payload = payloadData(tempRolesId)
          }
          if (state.roleId) {
            roleEditRequest(payload)
          } else {
            roleCreateRequest(payload)
          }
        }
      }
    }
    setValidated(true)
  }

  //function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteCategoryId(id)
    setConfirmDeleteModal(true)
  };

  //function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteCategoryId('')
    setConfirmDeleteModal(false)
  };

  //function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    roleDeleteRequest({
      "roleIdTable": deleteCategoryId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function to role permissons
  const handlePermissions = (e, data) => {
    const { checked } = e.target
    let tempPermissions = state.menuId
    if (checked) {
      tempPermissions.push(data)
    } else {
      tempPermissions = tempPermissions.filter(value => value.menuId !== data.menuId)
    }
    setState({...state, 'menuId' : tempPermissions})
  }

  return (
    <Fragment>
      <MainWrapper>
        <Loader loading={isLoading}>
          <div className="container">
            <div className="sub-header mt-5 mb-3">
              <h2>Role List</h2>
              <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
              <CustomButton
                handleClick={handleModal}
                variant="primary"
              >Create New
              </CustomButton>
            </div>
            <CustomTable
              roleListing={roleListing}
              showConfirmDeleteModal={showConfirmDeleteModal}
              hideConfirmDeleteModal={hideConfirmDeleteModal}
              handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
              confirmDeleteModal={confirmDeleteModal}
              handleEdit={handleEdit}
            />

          </div>
        </Loader>
        <AddEdit
          erpMenuData={erpMenuData}
          orderMenuData={orderMenuData}
          roleType={roleType}
          isOpen={isOpen}
          size={'sm'}
          state={state}
          handleChange={handleChange}
          validated={validated}
          handleSubmit={handleSubmit}
          hideModel={hideModel}
          isLoading={isLoading}
          handlePermissions={handlePermissions}
          disableMenuValue={disableMenuValue}
          hideCloseModel={hideCloseModel}
        />
      </MainWrapper>
    </Fragment>
  )
}

const mapStateToProps = ({ auth, Role, ProductType }) => {
  return {
    auth, Role, ProductType
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoleList: (token) => dispatch({ type: ROLE_LIST_REQUEST, token }),
    fetchRoleDropdownDataList: (token) => dispatch({ type: ROLE_DROPDOWN_REQUEST, token }),
    changeReducerState: () => dispatch({ type: ROLE_STATE_CHANGE }),
    roleCreateRequest: (data) => dispatch({ type: ROLE_CREATE_REQUEST, payload: data }),
    roleDeleteRequest: (data) => dispatch({ type: ROLE_DELETE_REQUEST, payload: data }),
    roleEditRequest: (data) => dispatch({ type: ROLE_EDIT_REQUEST, payload: data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);