import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  SESSION_EXPIRED,
  ORDER_USER_REQUEST,
  ORDER_USER_SUCCESS,
  AUTH_STATE_CHANGE,
  USER_ALREADY_EXIST_REQUEST,
  USER_ALREADY_EXIST_SUCCESS,
  USER_ALREADY_EXIST_FAILED,
  USER_ALREADY_EXIST_STATE_CHANGE
} from '../../_utils/constants/Auth';
import _ from 'lodash';

const auth = localStorage.getItem('geoErp') && localStorage.getItem('geoErp') !== 'undefined' ? JSON.parse(localStorage.getItem('geoErp')) : {};

const initialState = {
  auth,
  isAuthenticated: !_.isEmpty(auth),
  isLoading: false,
  isProcessing: false,
  isSessionExpired: false,
  errors: {},
  isUserExist: [],
  userErrors: {},
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_USER_REQUEST:
    case ORDER_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSessionExpired: false,
        errors: {},
      };
    case LOGIN_USER_SUCCESS:
    case ORDER_USER_SUCCESS:
      localStorage.setItem('geoErp', JSON.stringify(payload.result));
      return {
        ...state,
        auth: payload.result,
        isAuthenticated: true,
        isLoading: false,
        isProcessing: false,
        isSessionExpired: false,
      };
    case LOGIN_USER_FAILED:

      return {
        ...state,
        errors: payload.data,
        isLoading: false,
        isProcessing: false,
        isSessionExpired: false,
      };
    case AUTH_STATE_CHANGE:
      return {
        ...state,
        isLoading: false,
        isProcessing: false,
        isSessionExpired: false,
        errors: {},
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSessionExpired: false,
      };

    case LOGOUT_SUCCESS:
      document.cookie = 'orderingLogin=; path=/; domain=dev.s10drd.com; expires=expires=Thu, 01-Jan-70 00:00:01 GMT';
      localStorage.removeItem('geoErp');
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        auth: {},
        isSessionExpired: false,
      };
    case SESSION_EXPIRED:
      document.cookie = 'orderingLogin=; path=/; domain=dev.s10drd.com; expires=expires=Thu, 01-Jan-70 00:00:01 GMT';
      localStorage.removeItem('geoErp');
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        auth: {},
        isSessionExpired: true,
      };

      case USER_ALREADY_EXIST_REQUEST:
        return {
          ...state,
          isUserExist: false,
          userErrors: {}
        }
      case USER_ALREADY_EXIST_SUCCESS:
        var decryptedData = action.payload.result
        return {
          ...state,
          isUserExist: decryptedData,
          userErrors: {}
        }
      case USER_ALREADY_EXIST_FAILED:
        return {
          ...state,
          userErrors: action.error
        }

      case USER_ALREADY_EXIST_STATE_CHANGE:
        return {
          ...state,
          isUserExist: [],
          userErrors: {},
        }
    default:
      return state;
  }

};

export default authReducer;