import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back } from 'react-icons-kit/md';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreatePersonalUser";
import ConfirmDelete from "./models/ConfirmDelete";
// import { encrypted } from '../../../../_utils/helpers';
import {
  PERSONAL_USER_REQUEST,
  PERSONAL_USER_CREATE,
  PERSONAL_USER_DELETE,
  PERSONAL_USER_EDIT,
  PERSONAL_USER_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const PersonalUser = ({
  auth: { auth, isAuthenticated },
  PersonalUser: { personalUser, isCreated, isDeleted, isEdited, isLoading },
  fetchPersonalUser,
  submitPersonalUserCreate,
  submitPersonalUserDelete,
  submitPersonalUserEdit,
  changeReducerState
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deletePersonalUserId, setDeletePersonalUserId] = useState('');
  const [state, setState] = useState({
    "personalName": "",
    "password": "",
    "title": "",
    "email": "",
  })
  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchPersonalUser(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  // use effect function called after api success
  useEffect(() => {

    if (isCreated === true) {
      toast.success("Personal name created successfully.");
      fetchPersonalUser(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("Personal name deleted successfully.");
      fetchPersonalUser(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("Personal name updated successfully.");
      fetchPersonalUser(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
  };



  // function called for create job type create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (state.personalName === '') {
      toast.error("PersonalName cannot be empty.");
    } else {
      submitPersonalUserCreate({
        "data": {
          "PersonalName": state.personalName,
          "Password": state.password,
          "Title": state.title,
          "Email": state.email,
          "CreatedBy": auth[0].userId
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value })
  }
  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {

    setDeletePersonalUserId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeletePersonalUserId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete job type delete api
  const handleConfirmDeleteSubmit = () => {
    submitPersonalUserDelete({
      "PersonalUserId": deletePersonalUserId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>Users</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
            <Create
              variant="primary"
              title={`Personal User`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              handleCreateModalSubmit={handleCreateModalSubmit}
              handleChange={handleChange}
              state={state}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th className="text-center">Title</th>
                <th>Name</th>
                <th className="text-center">Email</th>
                <th width="75" className="actions">
                  <div className="d-flex">


                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {personalUser && personalUser.length > 0 ? (
                personalUser.map((item, index) => (

                  <tr key={index}>
                    <td width="100">
                      {item.title}
                    </td>
                    <td>
                      {item.personalName}
                    </td>
                    <td width="200">
                      {item.email}
                    </td>

                    <td className="actions">
                      <div className="btn-group">
                        {/* <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} /> */}
                        <ConfirmDelete variant="primary" title={`PersonalNames `} showConfirmDeleteModal={() => showConfirmDeleteModal(item.personalUserId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                      </div>
                    </td>
                  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, PersonalUser }) => {
  return {
    auth,
    PersonalUser
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPersonalUser: (token) => dispatch({ type: PERSONAL_USER_REQUEST, token }),
    submitPersonalUserCreate: (data) => dispatch({ type: PERSONAL_USER_CREATE, payload: data }),
    submitPersonalUserDelete: (data) => dispatch({ type: PERSONAL_USER_DELETE, payload: data }),
    submitPersonalUserEdit: (data) => dispatch({ type: PERSONAL_USER_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: PERSONAL_USER_STATE_CHANGE })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonalUser);