import React from "react";
import { Modal } from 'react-bootstrap';

const ModelHeader = ({ children }) => {
  return (
    <Modal.Footer>
      {children}
    </Modal.Footer>
  )
}


export default ModelHeader;