import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { DECRYPT_REQUEST, DECRYPT_SUCCESS, DECRYPT_FAILED } from '../../_utils/constants/Decrypt';
import {API_URL} from "../../config";

function* fetchDecrypt({ data }) {
  try {
    const value = `"${data}"`;
    const response = yield call(axios.post, `${API_URL}/api/Test/decrypt`,value,{headers:{ "Content-Type": "application/json"}});
    
    yield put({ type: DECRYPT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: DECRYPT_FAILED, payload: error.response });
  }
}



export default function* rootDecryption() {
  yield all([
    takeLatest(DECRYPT_REQUEST, fetchDecrypt)
  ]);
}