import React from "react";
import '../../../assets/css/order.css';
import { Icon } from "react-icons-kit";
// import {Link} from "react-router-dom";
import { ic_print, ic_delete, ic_call_merge } from "react-icons-kit/md";
import MainWrapper from "../../MainWrapper";
import {Tabs, Tab, Row, Col, Card, Form, Table, Button, ListGroup} from 'react-bootstrap';

const ProductPricing = () => {
	return (
    	<MainWrapper>
    		<Row>
    			<Col md={9}>
    				<Card className="mb-3">
    					<Card.Body>
    						<Card.Title className="form-title">Product Information</Card.Title>
    						<Form>
    							<Row>
    								<Col md={6}>
    									<Row>
    										<Col md={6}>
											  	<Form.Group controlId="" className="form-group">
												    <Form.Label>Manufacturer</Form.Label>
												    <Form.Control type="text" />
											  	</Form.Group>
    										</Col>
    										<Col md={6}>
											  	<Form.Group controlId="" className="form-group">
												    <Form.Label>Vendor</Form.Label>
												    <Form.Control type="text" />
											  	</Form.Group>
    										</Col>
    									</Row>
    								</Col>
    								<Col md={6}>
    									<Row>
    										<Col md={3}>
											  	<Form.Group controlId="" className="form-group">
												    <Form.Label>Product Type</Form.Label>
												    <Form.Control type="text" />
											  	</Form.Group>
    										</Col>
    										<Col md={3}>
											  	<Form.Group controlId="" className="form-group">
												    <Form.Label>Unit</Form.Label>
												    <Form.Control type="text" />
											  	</Form.Group>
    										</Col>
    										<Col md={3}>
											  	<Form.Group controlId="" className="form-group">
												    <Form.Label>Width(Inches)</Form.Label>
												    <Form.Control type="text" />
											  	</Form.Group>
    										</Col>
    										<Col md={3}>
											  	<Form.Group controlId="" className="form-group">
												    <Form.Label>Length(Inches)</Form.Label>
												    <Form.Control type="text" />
											  	</Form.Group>
    										</Col>
    									</Row>
    								</Col>
    							</Row>
    							<Row>
    								<Col md={6}>
										<Form.Group controlId="" className="form-group">
											<Form.Label>Style</Form.Label>
											<Form.Control as="textarea" style={{ height: '50px' }} />
										</Form.Group>
    								</Col>
    								<Col md={2}>
										<Form.Group controlId="" className="form-group">
											<Form.Label>Style/Model Number</Form.Label>
											<Form.Control as="textarea" style={{ height: '50px' }} />
										</Form.Group>
    								</Col>
    								<Col md={4}>
										<Form.Group controlId="" className="form-group">
											<Form.Label>Collection</Form.Label>
											<Form.Control as="textarea" style={{ height: '50px' }} />
										</Form.Group>
    								</Col>
    							</Row>
    						</Form>
    					</Card.Body>
    				</Card>

    				<Tabs defaultActiveKey="lookup" id="uncontrolled-tab-example" className="">
						<Tab eventKey="lookup" title="Lookup" className="px-3 pt-3 pb-1 mb-3">
							<Table bordered hover responsive>
								<thead>
									<tr>
										<th>Item Number</th>
										<th>Slot</th>
										<th>Product ID#</th>
										<th>UPC#</th>
										<th>Sell Price</th>
										<th>Sell w/o Tax</th>
										<th>Sale</th>
										<th>% Off</th>
										<th>Future Sell</th>
										<th>Future Date</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>CASTLE ROCKS UnpollishedAD03UP</td>
										<td></td>
										<td>10</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</Table>
							<Row>
								<Col md={4}>
									<Form.Group controlId="" className="d-flex align-items-center mb-3">
										<Form.Label className="pe-2 mb-0">Display</Form.Label>
										<Form.Control type="text" />
									</Form.Group>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="entry" title="Entry" className="p-3 mb-3">
							<Table bordered hover responsive>
								<thead>
									<tr>
										<th>Color</th>
										<th>Private Color</th>
										<th>Color Number</th>
										<th>SKU</th>
										<th>Alternate ID</th>
										<th>Slot</th>
										<th>Product ID#</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Line ID</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td>10</td>
										<td className="actions">
											<span className="tag" style={{ background: '#a5a2f5' }}>D</span>
											<span className="tag" style={{ background: '#a5a2f5' }}>i</span>
											<span className="tag">UPC</span>
											<span className="tag me-3">UPC CLEAR</span>
											<Button variant="light" className="me-3"><Icon icon={ic_print} /></Button>
											<Button variant="light" className="delete"><Icon icon={ic_delete} /></Button>
										</td>
									</tr>
									<tr>
										<td>CASTLE ROCKS Unpollished AD03UP</td>
										<td></td>
										<td>AD03UP</td>
										<td>AD0324BJ</td>
										<td>DalTile x</td>
										<td></td>
										<td>10</td>
										<td className="actions">
											<span className="tag" style={{ background: '#a5a2f5' }}>D</span>
											<span className="tag" style={{ background: '#a5a2f5' }}>i</span>
											<span className="tag">UPC</span>
											<span className="tag me-3">UPC CLEAR</span>
											<Button variant="light" className="me-3"><Icon icon={ic_print} /></Button>
											<Button variant="light" className="delete"><Icon icon={ic_delete} /></Button>
										</td>
									</tr>
								</tbody>
							</Table>
							<Row>
								<Col md={6}>
									<Row className="mb-3">
										<Col md={3}>
											<Form.Group controlId="" className="form-group">
											    <Form.Label>Modification Date</Form.Label>
											    <Form.Control type="text" />
										  	</Form.Group>
    									</Col>
										<Col md={3}>
											<Form.Group controlId="" className="form-group">
											    <Form.Label>Modification Time</Form.Label>
											    <Form.Control type="text" />
										  	</Form.Group>
    									</Col>
										<Col md={3}>
											<Form.Group controlId="" className="form-group">
											    <Form.Label>Creation Date</Form.Label>
											    <Form.Control type="text" />
										  	</Form.Group>
    									</Col>
										<Col md={3}>
											<Form.Group controlId="" className="form-group">
											    <Form.Label>Creation Time</Form.Label>
											    <Form.Control type="text" />
										  	</Form.Group>
    									</Col>
									</Row>
									<Row className="mb-3">
										<Col md={6}>
											<Form.Group controlId="" className="form-group">
											    <Form.Label>Private Label Style Name</Form.Label>
											    <Form.Control type="text" />
										  	</Form.Group>
    									</Col>
										<Col md={6}>
											<Form.Group controlId="" className="form-group">
											    <Form.Label>Private Label Collection</Form.Label>
											    <Form.Control type="text" />
										  	</Form.Group>
    									</Col>
									</Row>
									<Row>
										<Col md={6}>
											<Form.Group controlId="" className="form-group">
											    <Form.Label>Display</Form.Label>
											    <Form.Control type="text" />
										  	</Form.Group>
    									</Col>
										<Col md={3}>
											<Form.Group controlId="" className="form-group">
											    <Form.Label>Tax Code</Form.Label>
											    <Form.Control type="text" />
										  	</Form.Group>
    									</Col>
										<Col md={3}>
											<Form.Group controlId="" className="form-group">
											    <Form.Label>Audit Text</Form.Label>
											    <Form.Control type="text" />
										  	</Form.Group>
    									</Col>
									</Row>
								</Col>
								<Col md={2}>
									<ListGroup className="check-list">
										<ListGroup.Item><Form.Check  type="checkbox" label="Non-Taxed" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Return Inventory" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Display Discount" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Print Price" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Archive" /></ListGroup.Item>
									</ListGroup>
								</Col>
								<Col md={2}>
									<ListGroup className="check-list">
										<ListGroup.Item><Form.Check  type="checkbox" label="Show Manufacturer" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="No Inventory Req" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Discountinue Line" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Use Box Quantity" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Lock Cost" /></ListGroup.Item>
									</ListGroup>
								</Col>
								<Col md={2}>
									<ListGroup className="check-list">
										<ListGroup.Item><Form.Check  type="checkbox" label="Use SKU" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Don't Use Private" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Rec/Del Automatic" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Not Preferred" /></ListGroup.Item>
										<ListGroup.Item><Form.Check  type="checkbox" label="Show Style/ Model#" /></ListGroup.Item>
									</ListGroup>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="prices" title="Prices" className="p-3 mb-3">
							<Card className="mb-3">
    							<Card.Body>
    								<Card.Title className="form-title">Costs/ Sell Price</Card.Title>
									<Table responsive bordered className="large-grid">
										<tbody>
											<tr>
												<td colSpan="5">Color</td>
												<td colSpan="4">Private Color</td>
												<td colSpan="2">Units Per Ctn</td>
												<td colSpan="2">Color Dropped</td>
												<td colSpan="2">Product ID#</td>
												<td colSpan="3"></td>
											</tr>
											<tr>
												<th className="border-0" width="60"></th>
												<th className="border-0">Start</th>
												<th className="border-0">Finish</th>
												<th className="border-0">Cost</th>
												<th className="border-0">Last Cost</th>
												<th className="border-end-0">Future Cost</th>
												<th className="border-0">Future Date</th>
												<th className="border-0">Load</th>
												<th className="border-0">Promo Begin</th>
												<th className="border-end-0">Promo End</th>
												<th className="border-0"></th>
												<th className="border-end-0">Promo Cost</th>
												<th className="border-0">Frt 1</th>
												<th className="border-end-0">Frt 2</th>
												<th className="border-0">Tax</th>
												<th className="border-end-0">Final Cost</th>
												<th className="border-0">Sell</th>
												<th className="border-0">GPM</th>
											</tr>
											<tr>
												<td className="border-0" colSpan="5">Line ID</td>
												<td colSpan="4"></td>
												<td className="border-end-0">Uts Per</td>
												<td className="border-0">9.96</td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0">PID#</td>
												<td className="border-0">10</td>
												<td className="border-end-0"><Button>Group</Button></td>
												<td className="border-0">
													<div className="d-flex">
														<Form.Check label="Edit" className="me-2"></Form.Check><Form.Check label="Lock"></Form.Check>
													</div>
												</td>
												<td className="border-0"><Button>Group</Button></td>
											</tr>
											<tr>
												<td className="border-0">1.</td>
												<td className="border-0">1.00</td>
												<td className="border-0">0.99</td>
												<td className="border-0">$3.99</td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0" rowSpan="4"></td>
												<td className="border-end-1" rowSpan="4"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0">$3.99</td>
												<td className="border-0"></td>
												<td className="border-0"><Button variant="light" className="delete"><Icon icon={ic_delete}/></Button></td>
											</tr>
											<tr>
												<td className="border-0">2.</td>
												<td className="border-0">1.00</td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"><Button variant="light" className="delete"><Icon icon={ic_delete}/></Button></td>
											</tr>
											<tr>
												<td className="border-0">3.</td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"><Button variant="light" className="delete"><Icon icon={ic_delete}/></Button></td>
											</tr>
											<tr>
												<td className="border-0">4.</td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"><Button variant="light" className="delete"><Icon icon={ic_delete}/></Button></td>
											</tr>

											<tr>
												<td className="border-0" colSpan="5">CASTLE ROCK Unpolished AD03UP</td>
												<td colSpan="4"></td>
												<td className="border-end-0">Uts Per</td>
												<td className="border-0">9.96</td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0">PID#</td>
												<td className="border-0">10</td>
												<td className="border-end-0"><Button>Group</Button></td>
												<td className="border-0">
													<div className="d-flex">
														<Form.Check label="Edit" className="me-2"></Form.Check><Form.Check label="Lock"></Form.Check>
													</div>
												</td>
												<td className="border-0"><Button>Group</Button></td>
											</tr>
											<tr>
												<td className="border-0">1.</td>
												<td className="border-0">1.00</td>
												<td className="border-0">0.99</td>
												<td className="border-0">$3.99</td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0" rowSpan="4"></td>
												<td className="border-end-1" rowSpan="4"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0">$3.99</td>
												<td className="border-0"></td>
												<td className="border-0"><Button variant="light" className="delete"><Icon icon={ic_delete}/></Button></td>
											</tr>
											<tr>
												<td className="border-0">2.</td>
												<td className="border-0">1.00</td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"><Button variant="light" className="delete"><Icon icon={ic_delete}/></Button></td>
											</tr>
											<tr>
												<td className="border-0">3.</td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"><Button variant="light" className="delete"><Icon icon={ic_delete}/></Button></td>
											</tr>
											<tr>
												<td className="border-0">4.</td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-end-0"></td>
												<td className="border-0"></td>
												<td className="border-0"><Button variant="light" className="delete"><Icon icon={ic_delete}/></Button></td>
											</tr>
										</tbody>
									</Table>
								</Card.Body>
							</Card>
							<Row className="mb-3">
								<Col md={6}>
									<Card className="mb-3">
    									<Card.Body>
    										<Card.Title className="form-title">Installed Price Additions</Card.Title>
											<Table responsive className="large-grid">
												<tbody>
													<tr>
														<th></th>
														<th>Labor</th>
														<th>Prod ID</th>
														<th>Underlay</th>
														<th>Prod ID</th>
														<th>Adhesive</th>
														<th>Tax Rate</th>
														<th>Tax Type</th>
														<th>Action</th>
													</tr>
													<tr>
														<td>Retails</td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td><Button variant="light" className="delete"><Icon icon={ic_delete}/></Button></td>
													</tr>
												</tbody>
											</Table>

											<Table responsive className="large-grid">
												<tbody>
													<tr>
														<th></th>
														<th>Mat</th>
														<th>Pad</th>
														<th>Adhesive</th>
													</tr>
													<tr>
														<td>Costs</td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
												</tbody>
											</Table>
										</Card.Body>
									</Card>
								</Col>
								<Col md={6}>
									<Table responsive className="large-grid">
										<tbody>
											<tr>
												<th>Color</th>
												<th>Group</th>
												<th>Sell</th>
												<th>Labor</th>
												<th>Underlay</th>
												<th>Adhesive</th>
												<th>Installed</th>
												<th>Action</th>
											</tr>
											<tr>
												<td>Line ID</td>
												<td>Retail</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td><Button>Group</Button></td>
											</tr>
											<tr>
												<td>Line ID</td>
												<td>Designer</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td><Button>Group</Button></td>
											</tr>
											<tr>
												<td>Line ID</td>
												<td>Employee</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td><Button>Group</Button></td>
											</tr>
										</tbody>
									</Table>
									<Button className="py-1">Cost History</Button>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row className="mb-3">
    									<Col md={6}>
											<Form.Group controlId="" className="form-group">
												<Form.Label>Modification Date</Form.Label>
												<Form.Control type="text" />
											</Form.Group>
    									</Col>
    									<Col md={6}>
											<Form.Group controlId="" className="form-group">
												<Form.Label>Modification Time</Form.Label>
												<Form.Control type="text" />
											</Form.Group>
    									</Col>
    								</Row>
    								<Row>
    									<Col md={6}>
											<Form.Group controlId="" className="form-group">
												<Form.Label>Creation Date</Form.Label>
												<Form.Control type="text" />
											</Form.Group>
    									</Col>
    									<Col md={6}>
											<Form.Group controlId="" className="form-group">
												<Form.Label>Creation Time</Form.Label>
												<Form.Control type="text" />
											</Form.Group>
    									</Col>
    								</Row>
								</Col>
								<Col className="mb-3">
									<Card>
										<Card.Body>
    										<Card.Title className="form-title">Date</Card.Title>
											<Row className="mb-3">
		    									<Col md={6}>
													<Form.Group controlId="" className="form-group">
														<Form.Label>Start Date</Form.Label>
														<Form.Control type="text" />
													</Form.Group>
		    									</Col>
		    									<Col md={6}>
													<Form.Group controlId="" className="form-group">
														<Form.Label>End Date</Form.Label>
														<Form.Control type="text" />
													</Form.Group>
		    									</Col>
		    								</Row>
		    								<Row>
		    									<Col md={6}>
													<Form.Group controlId="" className="form-group">
														<Form.Label>Load</Form.Label>
														<Form.Control type="text" />
													</Form.Group>
		    									</Col>
		    									<Col md={6}></Col>
		    								</Row>
										</Card.Body>
									</Card>
								</Col>
								<Col className="mb-3">
									<Card>
										<Card.Body>
    										<Card.Title className="form-title">Discount Cost</Card.Title>
											<Row className="mb-3">
		    									<Col md={6}>
													<Form.Group controlId="" className="form-group">
														<Form.Label>Sell</Form.Label>
														<Form.Control type="text" />
													</Form.Group>
		    									</Col>
		    									<Col md={6}>
													<Form.Group controlId="" className="form-group">
														<Form.Label>%Off</Form.Label>
														<Form.Control type="text" />
													</Form.Group>
		    									</Col>
		    								</Row>
		    								<Row>
		    									<Col md={12}>
													<Button className="p-1 me-1">Set 1</Button>
		    									
													<Button className="p-1 me-1">Set 1</Button>
		    									
													<Button className="p-1 me-1">Set 1</Button>
		    									
													<Button className="p-1 me-1">Set 1</Button>
		    									</Col>
		    								</Row>
										</Card.Body>
									</Card>
								</Col>
								<Col className="mb-3">
									<Card>
										<Card.Body>
											<Card.Title className="form-title">Overhead</Card.Title>
											<Row className="mb-3">
		    									<Col md={6}>
													<Form.Group controlId="" className="form-group">
														<Form.Label>Sell</Form.Label>
														<Form.Control type="text" />
													</Form.Group>
		    									</Col>
		    									<Col md={6}>
													<Form.Group controlId="" className="form-group">
														<Form.Label>GPM</Form.Label>
														<Form.Control type="text" />
													</Form.Group>
		    									</Col>
		    								</Row>
		    								<Row>
		    									<Col md={6}>
		    										<Form.Group>
		    											<Form.Check type="radio" label="Round"/>
		    										</Form.Group>
		    									</Col>
		    									<Col md={6}>
													<Button className="py-1">Set</Button>
		    									</Col>
		    								</Row>
										</Card.Body>
									</Card>
								</Col>
								<Col className="mb-3">
									<Card>
										<Card.Body>
											<Card.Title className="form-title">Overhead</Card.Title>
											<Row className="mb-3">
		    									<Col md={6}>
													<Form.Group controlId="" className="form-group">
														<Form.Label>Dollar Amount</Form.Label>
														<Form.Control type="text" />
													</Form.Group>
		    									</Col>
		    									<Col md={6}>
													<Form.Group controlId="" className="form-group">
														<Form.Label>Percentage</Form.Label>
														<Form.Control type="text" />
													</Form.Group>
		    									</Col>
		    								</Row>
		    								<Row>
		    									<Col md={6}>
		    										<Form.Group>
		    											<Form.Check type="radio" label="Cost"/>
		    										</Form.Group>
		    									</Col>
		    									<Col md={6}>
													<Form.Group>
		    											<Form.Check type="radio" label="None"/>
		    										</Form.Group>
		    									</Col>
		    								</Row>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="sale" title="Sale" className="p-3 mb-3">
							<Table responsive bordered className="large-grid">
								<tbody>
									<tr>
										<td colSpan="5">Color</td>
										<td className="border-end-0" colSpan="4">Private Color</td>
										<td className="border-0"></td>
									</tr>
									<tr>
										<th className="border-0" width="60"></th>
										<th className="border-0">Start</th>
										<th className="border-0">Finish</th>
										<th className="border-0">Cost</th>
										<th className="border-0">Sell</th>
										<th className="border-end-0">Sale</th>
										<th className="border-0">Percent Off</th>
										<th className="border-0">Sale Start</th>
										<th className="border-0">Sale End</th>
										<th className="border-0"></th>		
									</tr>
									<tr>
										<td className="border-0" colSpan="5">Line ID</td>
										<td className="border-end-0" colSpan="4"></td>
										<td className="border-end-0" rowSpan="5">
											<Form.Group className="d-flex">
												<Form.Check className="me-2" type="radio" label="Edit"/>
												<Form.Check type="radio" label="Lock"/>
											</Form.Group>
										</td>
									</tr>
									<tr>
										<td className="border-0">1.</td>
										<td className="border-0">1.00</td>
										<td className="border-0">0.99</td>
										<td className="border-0">$3.99</td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td colSpan="2" rowSpan="4"></td>
									</tr>
									<tr>
										<td className="border-0">2.</td>
										<td className="border-0">1.00</td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
									</tr>
									<tr>
										<td className="border-0">3.</td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
									</tr>
									<tr>
										<td className="border-0">4.</td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
									</tr>

									<tr>
										<td className="border-0" colSpan="5">CASTLE ROCK Unpolished AD03UP</td>
										<td className="border-end-0" colSpan="4"></td>
										<td className="border-end-0" rowSpan="5">
										<Form.Group className="d-flex">
										<Form.Check className="me-2" type="radio" label="Edit"/>
										<Form.Check type="radio" label="Lock"/>
										</Form.Group>
										</td>
									</tr>
									<tr>
										<td className="border-0">1.</td>
										<td className="border-0">1.00</td>
										<td className="border-0">0.99</td>
										<td className="border-0">$3.99</td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td colSpan="2" rowSpan="4"></td>
									</tr>
									<tr>
										<td className="border-0">2.</td>
										<td className="border-0">1.00</td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
									</tr>
									<tr>
										<td className="border-0">3.</td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
									</tr>
									<tr>
										<td className="border-0">4.</td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
										<td className="border-0"></td>
									</tr>
								</tbody>
							</Table>
						</Tab>
						<Tab eventKey="notes" title="Notes" className="p-3 mb-3">
							<Row>
								<Col md={9}>
									<Form.Group className="mb-3">
										<Form.Label>General</Form.Label>
										<Form.Control as="textarea" style={{ height: '200px' }} />
									</Form.Group>
									<Form.Group>
										<Form.Label>Installation</Form.Label>
										<Form.Control as="textarea" style={{ height: '200px' }} />
									</Form.Group>
								</Col>
								<Col md={3}>
									<Form.Label>Specifications</Form.Label>
									<Card style={{height: 'calc(100% - 31px)', overflow: 'auto', minHeight: '300px' }}>
										<Card.Body style={{position:'absolute', width: '100%'}}>
											<p className="mb-1">AbrasiveWearWarranty:</p>
											<p className="mb-1">AbsorptionScale:</p>
											<p className="mb-1">BackingType:</p>
											<p className="mb-1">BaseUnit: SF</p>
											<p className="mb-1">CCADivCollectionName:</p>
											<p className="mb-1">CCADivDateStopped:</p>
											<p className="mb-1">CCADivStoppedReason:</p>
											<p className="mb-1">CCADivStyleName:</p>
											<p className="mb-1">CCADivStyleNumber:</p>
											<p className="mb-1">Click:</p>
											<p className="mb-1">Commercial:</p>
											<p className="mb-1">CommerciallyApproved:</p>
											<p className="mb-1">CountryOfOrigin:</p>
											<p className="mb-1">Cut:</p>
											<p className="mb-1">Density:</p>
											<p className="mb-1">DivisionCode:</p>
											<p className="mb-1">DivisionName:</p>
											<p className="mb-1">DPLHPL:</p>
											<p className="mb-1">EdgeProfile:</p>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Tab>
						<Tab eventKey="groups" title="Groups" className="p-3 mb-3">
							<Table responsive className="large-grid">
								<tbody>
									<tr>
										<th>Item Number</th>
										<th>Product ID #</th>
										<th>UPC #</th>
										<th width="100">Group</th>
									</tr>
									<tr>
										<td>Line ID</td>
										<td>10</td>
										<td></td>
										<td><Button>Group</Button></td>
									</tr>
									<tr>
										<td>CASTLE ROCK Unpolished AD03UP</td>
										<td>13</td>
										<td></td>
										<td><Button>Group</Button></td>
									</tr>
									<tr>
										<td>CHATEAU CRAME Unpolished AD01UP</td>
										<td>11</td>
										<td></td>
										<td><Button>Group</Button></td>
									</tr>
									<tr>
										<td>WEST TOWER Unpolished AD02UP</td>
										<td>12</td>
										<td></td>
										<td><Button>Group</Button></td>
									</tr>
								</tbody>
							</Table>
							<Table responsive className="large-grid mb-0">
								<tbody>
									<tr>
										<th>Color</th>
										<th>Group</th>
										<th>Sell</th>
										<th>Labor</th>
										<th>Underlay</th>
										<th>Adhesive</th>
										<th>Installed</th>
									</tr>
									<tr>
										<td>Line ID</td>
										<td>Retail</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>Line ID</td>
										<td>Designer</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>Line ID</td>
										<td>Employee</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>Line ID</td>
										<td>Installer</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>Line ID</td>
										<td>Legacy</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</Table>
						</Tab>
						<Tab eventKey="inventory" title="Inventory" className="p-3 mb-3">
							<Table responsive className="large-grid mb-0">
								<tbody>
									<tr>
										<th>Item Number</th>
										<th>Disable PO</th>
										<th>Product ID #</th>
										<th>Peak Level</th>
										<th>Reorder Level</th>
										<th>Open To Buy</th>
										<th>On Hold</th>
										<th>Sellable</th>
										<th>On Hand</th>
										<th>Available Procurement</th>
									</tr>
									<tr>
										<td>Line ID</td>
										<td><Form.Check type="checkbox"/></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td><Form.Check type="checkbox"/></td>
									</tr>
									<tr>
										<td>CASTLE ROCK Unpolished AD03UP</td>
										<td><Form.Check type="checkbox"/></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td><Form.Check type="checkbox"/></td>
									</tr>
									<tr>
										<td>CHATEAU CRAME Unpolished AD01UP</td>
										<td><Form.Check type="checkbox"/></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td><Form.Check type="checkbox"/></td>
									</tr>
									<tr>
										<td>WEST TOWER Unpolished AD02UP</td>
										<td><Form.Check type="checkbox"/></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td><Form.Check type="checkbox"/></td>
									</tr>
								</tbody>
							</Table>
						</Tab>
						<Tab eventKey="activity" title="Activity" className="p-3 mb-3">
							<Table responsive className="large-grid mb-0">
								<tbody>
									<tr>
										<th>Color</th>
										<th></th>
										<th></th>
										<th>Archive</th>
										<th>Alt ID</th>
										<th>Product ID #</th>
										<th>Proposals</th>
										<th>Orders</th>
										<th>Invoices</th>
										<th>Inventory</th>
										<th>Merge</th>
										<th>Delete</th>
									</tr>
									<tr>
										<td>Line ID</td>
										<td>cDate</td>
										<td>Audit Text</td>
										<td><Form.Check type="checkbox"/></td>
										<td></td>
										<td>10</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td><Button variant="light"><Icon icon={ic_call_merge} /></Button></td>
										<td><Button variant="light" className="delete"><Icon icon={ic_delete} /></Button></td>
									</tr>
									<tr>
										<td>CASTLE ROCK Unpolished AD03UP</td>
										<td>12/15/2015</td>
										<td></td>
										<td><Form.Check type="checkbox"/></td>
										<td>DalTile</td>
										<td>13</td>
										<td>Yes</td>
										<td>Yes</td>
										<td>Yes</td>
										<td></td>
										<td><Button variant="light"><Icon icon={ic_call_merge} /></Button></td>
										<td><Button variant="light" className="delete"><Icon icon={ic_delete} /></Button></td>
									</tr>
									<tr>
										<td>CHATEAU CRAME Unpolished AD01UP</td>
										<td>12/15/2015</td>
										<td></td>
										<td><Form.Check type="checkbox"/></td>
										<td>DalTile</td>
										<td>11</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td><Button variant="light"><Icon icon={ic_call_merge} /></Button></td>
										<td><Button variant="light" className="delete"><Icon icon={ic_delete} /></Button></td>
									</tr>
									<tr>
										<td>WEST TOWER Unpolished AD02UP</td>
										<td>12/15/2015</td>
										<td></td>
										<td><Form.Check type="checkbox"/></td>
										<td>DalTile</td>
										<td>12</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td><Button variant="light"><Icon icon={ic_call_merge} /></Button></td>
										<td><Button variant="light" className="delete"><Icon icon={ic_delete} /></Button></td>
									</tr>
								</tbody>
							</Table>
						</Tab>
						<Tab eventKey="kit" title="Kit" className="p-3 mb-3">
						</Tab>
					</Tabs>
    			</Col>
    			<Col md={3}>
    				<Card className="mb-3">
    					<Card.Body>
    						<Card.Title className="form-title">Product Information</Card.Title>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Type</Form.Label></Col>
	    							<Col md={8}><Form.Control as="textarea"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group>
	    						<Row>
	    							<Col md={4}><Form.Label>Units Per</Form.Label></Col>
	    							<Col md={4}><Form.Control type="text" className="mb-2"></Form.Control></Col>
	    							<Col md={4}><Form.Control type="text" className="mb-2"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Cash Back</Form.Label></Col>
	    							<Col md={8}><Form.Control as="textarea"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Buying Group</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Royalty</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Tariff</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Stocking</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Custom</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>FOB</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Quality Rating</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Warranty</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Alt ID</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Source</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Thinkness</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group className="mb-2">
	    						<Row>
	    							<Col md={4}><Form.Label>Style Number</Form.Label></Col>
	    							<Col md={8}><Form.Control type="text"></Form.Control></Col>
	    						</Row>
	    					</Form.Group>
    						<Form.Group>
	    						<Row>
	    							<Col md={4}><Form.Label>Spread rate</Form.Label></Col>
	    							<Col md={4}><Form.Control type="text"></Form.Control></Col>
	    							<Col md={4}><Form.Label>Spread rate</Form.Label></Col>
	    						</Row>
	    					</Form.Group>
    					</Card.Body>
    				</Card>
    			</Col>
    		</Row>
      		
    	</MainWrapper>
  	);
}
export default ProductPricing;