import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, Button, InputGroup } from 'react-bootstrap';
// import { encrypted } from '../../../../_utils/helpers';
import { connect } from 'react-redux';
import Create from "./models/Create";
import ConfirmDelete from "./models/ConfirmDelete";
import {
	WAREHOUSE_REQUEST,
	WAREHOUSE_CREATE,
	WAREHOUSE_DELETE,
	WAREHOUSE_EDIT,
	WAREHOUSE_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { toast } from 'react-toastify';

const Warehouses = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchWarehouse,
	submitWarehouseCreate,
	submitWarehouseDelete,
	submitWarehouseEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [inputVal, setInputVal] = useState('');
	const [deleteWarehouseId, setDeleteWarehouseId] = useState('');
	const [editWarehouseId, setEditWarehouseId] = useState('');
	const [editWarehouse, setEditWarehouse] = useState('');

	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchWarehouse(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("Warehouse Created Successfully.");
			fetchWarehouse(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Warehouse Deleted Successfully.");
			fetchWarehouse(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Warehouse Data Updated Successfully.");
			fetchWarehouse(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleCreateModalChange = (e) => {
		setInputVal(e.target.value);
	}

	// function called for create age demographic create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (inputVal === '') {
			toast.error("Warehouse name cannot be empty.");
		} else {
			submitWarehouseCreate({
				"data": {
					"WarehouseName": inputVal,
					"CreatedBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteWarehouseId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteWarehouseId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete age demographic delete api
	const handleConfirmDeleteSubmit = () => {
		submitWarehouseDelete({
			"WarehouseId": deleteWarehouseId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditWarehouseId(data.warehouseId);
		setEditWarehouse(data.warehouseName);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editWarehouse === '') {
			toast.error("Warehouse name cannot be empty.");
		} else {
			submitWarehouseEdit({
				"data": {
					"WarehouseId": data.warehouseId,
					"WarehouseName": editWarehouse,
				},
				"token": auth[0].token
			});
			setEditWarehouseId('');
			setEditWarehouse('');
		}
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">

					<div className="sub-header mt-5 mb-3">
						<h2>Warehouse</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Warehouse`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							inputVal={inputVal}
							handleCreateModalChange={handleCreateModalChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Warehouse</th>
								<th width="50">Delete</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									<tr key={index}>
										{editWarehouseId === item.warehouseId ? (
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editWarehouse} onChange={(evt) => setEditWarehouse(evt.target.value)} />
														<Button variant="outline-secondary">
															<Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
														</Button>
													</InputGroup>
												</Form.Group>
											</td>
										) : (
											<td>
												{item.warehouseName}
												<Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
											</td>
										)}
										<td>
											<ConfirmDelete variant="primary" title={`Warehouse`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.warehouseId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={2}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	);
}

const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchWarehouse: (token) => dispatch({ type: WAREHOUSE_REQUEST, token }),
		submitWarehouseCreate: (data) => dispatch({ type: WAREHOUSE_CREATE, payload: data }),
		submitWarehouseDelete: (data) => dispatch({ type: WAREHOUSE_DELETE, payload: data }),
		submitWarehouseEdit: (data) => dispatch({ type: WAREHOUSE_EDIT, payload: data }),
		changeReducerState: () => dispatch({ type: WAREHOUSE_STATE_CHANGE })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Warehouses);