import React from "react";


const Title = ({ children }) => {
  return (
    <h2>{children}</h2>
  )
}


export default Title;