import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  EMPLOYEE_LIST_REQUEST,
  EMPLOYEE_LIST_SUCCESS,
  EMPLOYEE_LIST_FAILED,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_CREATE_FAILED,
  EMPLOYEE_EDIT_REQUEST,
  EMPLOYEE_EDIT_SUCCESS,
  EMPLOYEE_EDIT_FAILED,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_FAILED,
  EMPLOYEE_PASSWORD_REQUEST,
  EMPLOYEE_PASSWORD_SUCCESS,
  EMPLOYEE_PASSWORD_FAILED,
  EMPLOYEE_DROPDOWN_REQUEST,
  EMPLOYEE_DROPDOWN_SUCCESS,
  EMPLOYEE_DROPDOWN_FAILED,
} from "../../../_utils/constants/Employee";

function* fetchEmployeeList(action) {

  try {
    const response = yield call(axios.get, `${API_URL}/User/api/Employee/GetEmployeeList`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: EMPLOYEE_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: EMPLOYEE_LIST_FAILED, payload: error.response });
    }
  }
}

function* fetchEmployeeDropdownDataList(action) {
  try {
    const response = yield call(axios.get, `${API_URL}/User/api/Roles/GetRoleList?type=Employee`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: EMPLOYEE_DROPDOWN_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: EMPLOYEE_DROPDOWN_FAILED, payload: error.response });
    }
  }
}

function* employeeCreateRequest(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/Employee/EmployeeSignUp`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: EMPLOYEE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: EMPLOYEE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
//this method used to set password post data.
function* employeePasswordRequest(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/Employee/SetUserPassword`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: EMPLOYEE_PASSWORD_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: EMPLOYEE_PASSWORD_FAILED,
        payload: error.response
      });
    }
  }
}

function* employeeEditRequest(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/Employee/UpdateEmployeeDetails`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: EMPLOYEE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: EMPLOYEE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}

function* employeeDeleteRequest(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/User/api/Employee/DeleteEmployeeDetails?EmployeeId=${encodeURIComponent(action.payload.employeeId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: EMPLOYEE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: EMPLOYEE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}

export default function* rootEmployee() {
  yield all([
    takeLatest(EMPLOYEE_LIST_REQUEST, fetchEmployeeList ),
    takeLatest(EMPLOYEE_DROPDOWN_REQUEST, fetchEmployeeDropdownDataList ),
    takeLatest(EMPLOYEE_CREATE_REQUEST, employeeCreateRequest),
    takeLatest(EMPLOYEE_PASSWORD_REQUEST, employeePasswordRequest),
    takeLatest(EMPLOYEE_DELETE_REQUEST, employeeDeleteRequest),
    takeLatest(EMPLOYEE_EDIT_REQUEST, employeeEditRequest),
  ])
}