import React, { Fragment, useState, useEffect, useMemo} from "react";
import { connect } from "react-redux";
import { EMPLOYEE_PASSWORD_REQUEST, EMPLOYEE_STATE_CHANGE } from "../../../../_utils/constants/Employee";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../../../App.css";
import { Container, Button, Row, Col} from 'react-bootstrap';
import { Icon } from 'react-icons-kit'
import {eye} from 'react-icons-kit/icomoon/eye'
import {eyeBlocked} from 'react-icons-kit/icomoon/eyeBlocked'

const SetPassword = ({
  auth: { auth, isAuthenticated },
  Employee: { employeeList, isCreated, isDeleted, isEdited, isLoading, errors},
  employeePasswordRequest,
  changeReducerState
}) => {

  const [email, setEmail] = useState()
  const [input, setInput] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false)
  const passwordErrroMessage = "Password needs to be a minimum of 8 characters and should include a combination of a number, a special character, one uppercase and one lowercase letter."
  const regexFormat = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  // handle params from url
  const handleUrlParam = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let email = params.get('email');
    setEmail(email)
  }
  useEffect(() => {
    handleUrlParam()
    if (isAuthenticated) {
      localStorage.removeItem("geoErp")
    }
  }, [])
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useMemo(() => {
    if (isCreated === true) {
      toast.success("Your account is all set! Please Login to continue.");
      changeReducerState();
      setTimeout(() =>{
      localStorage.removeItem('geoErp');
      window.location.href = "/login"
      },4000);
    }
  }, [isCreated]);
  useMemo(() => {
    if (!_.isEmpty(errors)) {
      if(typeof (errors.data.result[0]) === "object"){
        toast.error('Please fill the correct form')
      }else{
        let errmsg = errors.data.result[0].split(':');
        toast.error(errmsg[1])
      }
      changeReducerState();
    }
  }, [errors])
  // handle password validation
  const handlePasswordValidation = (value) => {
    if (!(regexFormat.test(value))) {
      setError({...error, 'password': passwordErrroMessage})
    } else {
      setError({...error, 'password': ''})
    }
  }
  // handle form submit
  const handleSubmit = (event) => { 
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      var requestData = {
        "data": {
          "Email": email,
          "Password": input.password,
        },
      }
      if (input.password === '') {
        toast.error('Password is required')
      }
      if (input.password.length > 0) {
        handlePasswordValidation(input.password)
      }
      if (input.password !== input.confirmPassword) {
        toast.error('Password and Confirm Password does not match.')
      }
      if (email && (regexFormat.test(input.password)) && (regexFormat.test(input.confirmPassword))) {
        employeePasswordRequest(requestData);
      }
    }
  }
  // handle password eye icon
  const handlePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  }
  // handle confirm password eye icon
  const handleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility(!confirmPasswordVisibility)
  }
  return (
    <Fragment>
			<ToastContainer />
      <Container>
        <Row className="mt-5">
          <Col md={{span:4, offset:4}} className="shadow p-5">
            <h4 className="title text-center mb-3">Set Password</h4>
            <form className="formField">
              <div className="">
                <input
                  id="email-set-password"
                  type="text"
                  name="email"
                  placeholder="Enter User Email"
                  value={email}
                  onChange={onInputChange}
                  required={true}
                  className="form-control"
                  disabled={true}
                  readOnly={true} />
                {error.username && <span className="custom-error">{error.username}</span>}
              </div>

              <div className="mt-3 input-group">
                <input type={passwordVisibility ? 'text' : 'password'} name="password" placeholder="Enter Password" value={input.password} onChange={onInputChange} className="form-control" required={true} />
                  <span className="input-group-text"  onClick={handlePasswordVisibility}>
                    {passwordVisibility ? <Icon icon={eye} /> : <Icon icon={eyeBlocked} />}
                  </span>
              </div>
              {error.password && <span className="custom-error">{error.password}</span>}

              <div className="mt-3 input-group">
                <input
                  type={confirmPasswordVisibility ? 'text' : 'password'}
                  name="confirmPassword"
                  placeholder="Enter Confirm Password"
                  value={input.confirmPassword}
                  onChange={onInputChange}
                  className="form-control"
                  required={true} />
                  <span className="input-group-text" onClick={handleConfirmPasswordVisibility}>
                    {confirmPasswordVisibility ? <Icon icon={eye} /> : <Icon icon={eyeBlocked} />}
                  </span>
              </div>
              {error.confirmPassword && (
                <span className="custom-error">{error.confirmPassword}</span>
              )}
              
              <div className="text-center mt-3">
                <Button className="btn" variant="primary" onClick={handleSubmit}>
                  {isLoading ? (<div className="spinner-border"></div>) : "Save"}
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

const mapStateToProps = ({ auth, Employee }) => {
  return {
    auth, Employee
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeReducerState: () => dispatch({ type: EMPLOYEE_STATE_CHANGE }),
    employeePasswordRequest: (data) => dispatch({ type: EMPLOYEE_PASSWORD_REQUEST, payload: data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
