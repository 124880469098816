import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateVendor";
import ConfirmDelete from "./models/ConfirmDelete";
// import { encrypted } from '../../../../_utils/helpers';
import {
	VENDOR_REQUEST,
	VENDOR_CREATE,
	VENDOR_DELETE,
	VENDOR_EDIT,
	VENDOR_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const Vendor = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchVendor,
	submitJobTypeCreate,
	submitJobTypeDelete,
	submitJobTypeEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [jobTypeName, setJobTypeName] = useState('');
	const [jobTypeColor, setJobTypeColor] = useState("");
	const [jobTypeStatus, setJobTypeStatus] = useState("");
	const [jobActiveStatus, setJobActiveStatus] = useState(true);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [deleteJobTypeId, setDeleteJobTypeId] = useState('');
	const [editJobTypeId, setEditJobTypeId] = useState('');
	const [editJobTypeName, setEditJobTypeName] = useState('');
	const [editJobTypeColor, setEditJobTypeColor] = useState('');
	const [editJobTypeStatus, setEditJobTypeStatus] = useState('');
	// const [ids, setIds] = useState("");
	const [state, setState] = useState({
		CompanyName: "",
		Account1: "",
		Address1: "",
		City1: "",
		State1: "",
		ZipCode1: "",
		Country1: "",
		NameForPaymentPrint1: "",
		CarrierId1: 0,
		Account2: "",
		Address2: "",
		City2: "",
		State2: "",
		ZipCode2: "",
		Country2: "",
		NameForPaymentPrint2: "",
		CarrierId2: 0,
		IsAcceptes: true,
		CreatedBy: auth[0].userId
	})
	const handleChange = (e) => {
		const { id, value } = e.target;
		setState({ ...state, [id]: (id === 'CarrierId1' || id === 'CarrierId2') ? parseInt(value) : value });
	};
	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchVendor(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("Vendor Created Successfully.");
			fetchVendor(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Vendor Deleted Successfully.");
			fetchVendor(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Vendor Data Updated Successfully.");
			fetchVendor(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleJobTypeNameChange = (e) => {
		setJobTypeName(e.target.value);
	}
	const handleJobTypeColorChange = (e) => {
		setJobTypeColor(e.target.value);
	}
	const handleJobTypeStatusChange = (e) => {
		setJobTypeStatus(e.target.value);
	}
	const handleActiveStatusChange = () => {
		setJobActiveStatus(!jobActiveStatus);
	}

	// function called for create job type create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (state.CompanyName === '') {
			toast.error("Vendor name cannot be empty.");
		} else {
			submitJobTypeCreate({
				"data": state,
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteJobTypeId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteJobTypeId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete job type delete api
	const handleConfirmDeleteSubmit = () => {
		submitJobTypeDelete({
			"vendorId": deleteJobTypeId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditJobTypeId(data.vendorId);
		setEditJobTypeName(data.jobType);
		setEditJobTypeColor(data.jobTypeColor);
		setEditJobTypeStatus(data.jobTypeStatus);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editJobTypeName === '') {
			toast.error("Vendor name cannot be empty.");
		} else {
			submitJobTypeEdit({
				"data": {
					"JobTypeId": editJobTypeId,
					"JobType": editJobTypeName,
					"JobTypeColor": editJobTypeColor,
					"JobTypeStatus": editJobTypeStatus,
					"ActiveStatus": data.activeStatus
				},
				"token": auth[0].token
			});
			setEditJobTypeId('');
			setEditJobTypeName('');
			setEditJobTypeColor('');
			setEditJobTypeStatus('');
		}
	}

	// function to call update api for status change
	const handleUpdateActiveStatus = (data) => {
		submitJobTypeEdit({
			"data": {
				"vendorId": data.vendorId,
				"jobType": data.jobType,
				"jobTypeColor": data.jobTypeColor,
				"jobTypeStatus": data.jobTypeStatus,
				"activeStatus": (data.activeStatus === "InActive" || data.ActiveStatus === false) ? "Active" : "InActive"
			},
			"token": auth[0].token
		});
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<ToastContainer />
					<div className="sub-header mt-5 mb-3">
						<h2>Vendors</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Vendor`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							jobTypeName={jobTypeName}
							handleJobTypeNameChange={handleJobTypeNameChange}
							jobTypeColor={jobTypeColor}
							handleJobTypeColorChange={handleJobTypeColorChange}
							jobTypeStatus={jobTypeStatus}
							handleJobTypeStatusChange={handleJobTypeStatusChange}
							jobActiveStatus={jobActiveStatus}
							handleActiveStatusChange={handleActiveStatusChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
							state={state}
							setState={setState}
							handleChange={handleChange}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Vendor</th>
								<th className="text-center">Account</th>
								<th className="text-center">Address</th>
								<th width="75" className="actions">
									<div className="d-flex">
										<span>Disable</span>
										<span>Edit</span>
										<span>Delete</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									editJobTypeId === item.vendorId ? (
										<tr key={index}>
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editJobTypeName} onChange={(evt) => setEditJobTypeName(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td width="200">
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editJobTypeColor} onChange={(evt) => setEditJobTypeColor(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td width="100">
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editJobTypeStatus} onChange={(evt) => setEditJobTypeStatus(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td className="actions">
												<div className="btn-group">
													<Form.Group className="mt-2 w-54 d-flex justify-content-center" controlId="formBasicCheckbox">
														<Form.Check role="button" type="checkbox" label="" checked={(item.activeStatus === "InActive" || item.activeStatus === false) ? false : true} onChange={() => handleUpdateActiveStatus(item)} />
													</Form.Group>
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
													<ConfirmDelete variant="primary" title={`Vendors `} showConfirmDeleteModal={() => showConfirmDeleteModal(item.vendorId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									) : (
										<tr key={index}>
											<td>
												{item.companyName}
											</td>
											<td width="200">
												{item.account1}
											</td>
											<td width="100">
												{item.address1}
											</td>
											<td className="actions">
												<div className="btn-group">
													<Form.Group className="mt-2 w-54 d-flex justify-content-center" controlId="formBasicCheckbox">
														<Form.Check role="button" type="checkbox" label="" checked={(item.activeStatus === "InActive" || item.activeStatus === false) ? false : true} onChange={() => handleUpdateActiveStatus(item)} />
													</Form.Group>
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
													<ConfirmDelete variant="primary" title={`Vendors `} showConfirmDeleteModal={() => showConfirmDeleteModal(item.vendorId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									)
								))
							) : (
								<tr>
									<td colSpan={4}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	)
}

const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchVendor: (token) => dispatch({ type: VENDOR_REQUEST, token }),
		submitJobTypeCreate: (data) => dispatch({ type: VENDOR_CREATE, payload: data }),
		submitJobTypeDelete: (data) => dispatch({ type: VENDOR_DELETE, payload: data }),
		submitJobTypeEdit: (data) => dispatch({ type: VENDOR_EDIT, payload: data }),
		changeReducerState: () => dispatch({ type: VENDOR_STATE_CHANGE })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Vendor);