import { all } from 'redux-saga/effects';
import rootAuth from './authSaga';
import rootEncryption from './encryptSaga';
import rootDecryption from "./decryptSaga";
import rootValueList from "./valueListSaga";
import rootFormValueListItem from "./valuelist/formValueListSaga"
import rootUnitList from "./productcatalog/unitSaga"
import rootCarrierList from "./productcatalog/carrierSaga"
import rootProductSourceList from "./productcatalog/productSourceSaga"
import rootPropertyList from "./productcatalog/propertySaga"
import rootShipList from "./productcatalog/shipSaga"
import rootShipPaymentList from "./productcatalog/shipPaymentSaga"
import rootManufacturerList from "./productcatalog/manufacturerSaga"
import rootPaymentAccountList from "./productcatalog/paymentAccountSaga"
import rootSoldByList from "./productcatalog/soldBySaga"
import rootProductTypeList from "./productcatalog/productTypeSaga"
import rootProductPriceList from "./productcatalog/productPriceSaga"
import rootProductPriceDetailList from "./productcatalog/productPriceDetailSaga"
import rootProductInfoList from "./productcatalog/productInfoSaga"
import rootPhoneList from "./productcatalog/phoneSaga"
import rootNoteList from "./productcatalog/noteSaga"
import rootContactsList from "./productcatalog/contactsSaga"
import rootProductSpecList from "./productcatalog/productSpecSaga"
import rootProductInstalledPriceList from "./productcatalog/productInstalledPriceSaga"
import rootProductList from "./productcatalog/productSaga"
import rootPersonalUserItem from "./valuelist/personalUserSaga"
import rootAdmin from "./adminSaga"
import rootStoreInfo from "./admin/storeInfoSaga"
import rootProductCatalogList from "./productcatalog/productCatalogSaga"
import rootLaborCategory from "./labor/laborCategorySaga";
import rootLaborItem from "./labor/laborItemSaga";
import rootKits from "./kitsSaga";
import rootCopyProduct from "./productcatalog/copyProductSaga";
import rootCustomerInfo from "./customer/customerInfoSaga";
import rootPricingContract from "./customer/pricingContractSaga";
import rootSalesPersonCategory from "./salesperson/salesPersonCategorySaga";
import rootContactRoll from "./contactroll/contactRollSaga";
import rootEmployee from "./employee/employeeSaga"
import rootRole from "./role/roleSaga"
import rootCustomerApprovalList from "./CustomerApproval/customerApprovalSaga"

export default function* indexSaga() {
  yield all([
    rootEncryption(),
    rootDecryption(),
    rootAuth(),
    rootValueList(),
    rootFormValueListItem(),
    rootUnitList(),
    rootCarrierList(),
    rootProductSourceList(),
    rootPropertyList(),
    rootShipList(),
    rootShipPaymentList(),
    rootManufacturerList(),
    rootPaymentAccountList(),
    rootSoldByList(),
    rootProductTypeList(),
    rootProductPriceList(),
    rootProductPriceDetailList(),
    rootProductInfoList(),
    rootPhoneList(),
    rootNoteList(),
    rootContactsList(),
    rootProductSpecList(),
    rootProductInstalledPriceList(),
    rootProductList(),
    rootPersonalUserItem(),
    rootAdmin(),
    rootStoreInfo(),
    rootProductCatalogList(),
    rootLaborCategory(),
    rootLaborItem(),
    rootKits(),
    rootCopyProduct(),
    rootCustomerInfo(),
    rootPricingContract(),
    rootSalesPersonCategory(),
    rootContactRoll(),
    rootEmployee(),
    rootRole(),
    rootCustomerApprovalList()
  ]);
}