import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import React from "react";
import { Link } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_add, ic_delete_outline } from 'react-icons-kit/md';
import { Button, Table, Form } from 'react-bootstrap';

const InvoiceFlags = () => {
    return (
        <MainWrapper>
            <div className="container">
	    		<div className="sub-header mt-5 mb-3">
	    			<h2>Invoice Flags</h2>
	    			<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
	    			<Button variant="primary"><Icon size={24} icon={ic_add} /></Button>
	    		</div>
	    		<Table responsive bordered>
				  	<thead>
					    <tr>
					    	<th>Flag Name</th>
						    <th className="text-center">Graphics</th>
						    <th width="50" className="actions">
						    	<div className="d-flex">
							    	<span>Delete</span>
						    	</div>
						    </th>
					    </tr>
				  	</thead>
				  	<tbody>
					    <tr>
					    	<td>
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td width="200">
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td className="actions">
					      		<div className="btn-group">
						      		<Button variant="light" className="delete w-54"><Icon size={24} icon={ic_delete_outline} /></Button>
					      		</div>
					      	</td>
					    </tr>
					    <tr>
					    	<td>
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td width="200">
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td className="actions">
					      		<div className="btn-group">
						      		<Button variant="light" className="delete w-54"><Icon size={24} icon={ic_delete_outline} /></Button>
					      		</div>
					      	</td>
					    </tr>
					    <tr>
					    	<td>
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td width="200">
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td className="actions">
					      		<div className="btn-group">
						      		<Button variant="light" className="delete w-54"><Icon size={24} icon={ic_delete_outline} /></Button>
					      		</div>
					      	</td>
					    </tr>
					    <tr>
					    	<td>
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td width="200">
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td className="actions">
					      		<div className="btn-group">
						      		<Button variant="light" className="delete w-54"><Icon size={24} icon={ic_delete_outline} /></Button>
					      		</div>
					      	</td>
					    </tr>
					    <tr>
					    	<td>
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td width="200">
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td className="actions">
					      		<div className="btn-group">
						      		<Button variant="light" className="delete w-54"><Icon size={24} icon={ic_delete_outline} /></Button>
					      		</div>
					      	</td>
					    </tr>
					    <tr>
					    	<td>
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td width="200">
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td className="actions">
					      		<div className="btn-group">
						      		<Button variant="light" className="delete w-54"><Icon size={24} icon={ic_delete_outline} /></Button>
					      		</div>
					      	</td>
					    </tr>
					    <tr>
					    	<td>
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td width="200">
						      	<Form.Control 
							      	type="text" 
							      	id="inputPassword5" 
							      	aria-describedby="passwordHelpBlock"
	  							/>
  							</td>
					      	<td className="actions">
					      		<div className="btn-group">
						      		<Button variant="light" className="delete w-54"><Icon size={24} icon={ic_delete_outline} /></Button>
					      		</div>
					      	</td>
					    </tr>
				  	</tbody>
				</Table>
    		</div>
        </MainWrapper>
    )
}

export default InvoiceFlags;