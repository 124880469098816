import React, { useState, useEffect } from "react";
import "../../assets/css/auth.css";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
  USER_ALREADY_EXIST_REQUEST,
  USER_ALREADY_EXIST_STATE_CHANGE,
} from "../../_utils/constants/Auth";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Forgot = ({
  auth: {
    isUserExist,
    userErrors: { userErrors },
  },
  userAlreadyExist,
  changeState,
}) => {
  const navigate = useNavigate();

  // state declaration
  const [state, setState] = useState({
    EmailId: "",
  });
  const [loading, setLoading] = useState(false);

  // useEffect hook to handle response when `isUserExist` changes
  useEffect(() => {
    if (isUserExist) {
      setLoading(false);
      if (isUserExist.length > 0) {
        if (isUserExist[0] === "True") {
          // Show success toast notification
          toast.success("Success", {
            autoClose: 3000,
          });
          setTimeout(() => {
            // Navigate to setPassword page with email as a query parameter
            navigate(`/setPassword?email=${state?.EmailId}`);
            changeState();
          }, 3000);
        } else {
          // Show error toast notification
          toast.error("Failed");
          changeState();
        }
      }
    }
  }, [isUserExist]);

  // useEffect hook to handle userErrors
  useEffect(() => {
    if (userErrors && userErrors.length > 0) {
      setLoading(false);
      toast.error(userErrors);
      changeState();
    }
  }, [userErrors]);

  // Handle input change and update the state
  const handleChange = ({ target: { id, value } }) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the email field is empty
    if (!state.EmailId) {
      toast.error("Email field cannot be empty!");
      return; // Stop the form submission if it's empty
    }
    setLoading(true);
    userAlreadyExist(state);
  };

  return (
    <div className="forgot-wrapper">
      <ToastContainer />

      <Form className="text-center" onSubmit={handleSubmit}>
        <h2 className="sarina mb-5">NancyERP</h2>
        <h4 className="title">Forgot your password?</h4>
        <p>Enter your email address.</p>
        {/* Email input field */}
        <Form.Group className="mb-3" controlId="EmailId">
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={handleChange}
          />
        </Form.Group>

        {/* Submit button */}
        <Form.Group
          className="mb-3"
          controlId="formBasicCheckbox"
          type="submit"
        >
          <Button variant="primary w-100" type="submit">
            {loading ? <div className="spinner-border"></div> : "Submit"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

// The component is connected to the Redux store to retrieve and update state
const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

// The component is connected to the Redux store to dispatch actions
const mapDispatchToProps = (dispatch) => {
  return {
    userAlreadyExist: (data) =>
      dispatch({ type: USER_ALREADY_EXIST_REQUEST, data }),
    changeState: () => dispatch({ type: USER_ALREADY_EXIST_STATE_CHANGE }),
  };
};

// Export the Forgot component as the default export
export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
