import React, { Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateFormTemplate = ({
    title,
    createModalOpen,
    showCreateModal,
    hideCreateModal,
    formName,
    handleFormNameChange,
    templateName,
    handleTemplateNameChange,
    templateText,
    handleTemplateTextChange,
    handleCreateModalSubmit
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} >
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Form Name</Form.Label>
              <Form.Control type="text" name="formName" onChange={handleFormNameChange} value={formName} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="templateName">
              <Form.Label>Template Name</Form.Label>
              <Form.Control type="text" name="templateName" onChange={handleTemplateNameChange} value={templateName} />
            </Form.Group>
						<div className="form-group mb-3">
              <label htmlFor="templateText">Template Text</label>
              <textarea className="form-control" name="templateText" id="templateText" onChange={handleTemplateTextChange} value={templateText} rows="5"></textarea>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateModalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreateFormTemplate;