import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  SALES_PERSON_CATEGORY_REQUEST,
  SALES_PERSON_CATEGORY_SUCCESS,
  SALES_PERSON_CATEGORY_FAILED,
  SALES_PERSON_CATEGORY_CREATE,
  SALES_PERSON_CATEGORY_CREATE_SUCCESS,
  SALES_PERSON_CATEGORY_CREATE_FAILED,
  SALES_PERSON_CATEGORY_DELETE,
  SALES_PERSON_CATEGORY_DELETE_SUCCESS,
  SALES_PERSON_CATEGORY_DELETE_FAILED,
  SALES_PERSON_CATEGORY_EDIT,
  SALES_PERSON_CATEGORY_EDIT_SUCCESS,
  SALES_PERSON_CATEGORY_EDIT_FAILED,
  SALES_PERSON_COMMISSION_REQUEST,
  SALES_PERSON_COMMISSION_SUCCESS,
  SALES_PERSON_COMMISSION_FAILED,
  SALES_PERSON_REQUEST,
  SALES_PERSON_SUCCESS,
  SALES_PERSON_FAILED,
} from "../../../_utils/constants/SalesPerson";

function* fetchSalesPersonCategory(action) {

  try {
    const response = yield call(axios.get, `${API_URL}/User/api/User/GetSalesPerson`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: SALES_PERSON_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: SALES_PERSON_CATEGORY_FAILED, payload: error.response });
    }
  }
}

function* fetchSalesCommission(action) {

  try {
    const response = yield call(axios.get, `${API_URL}/User/api/User/GetSalesPersonById?UserId=${action.userId}`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: SALES_PERSON_COMMISSION_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: SALES_PERSON_COMMISSION_FAILED, payload: error.response });
    }
  }
}

function* fetchSalesPersonByStoreId(action) {

  try {
    const response = yield call(axios.get, `${API_URL}/User/api/User/GetSalesPersonByStoreId?StoreId=${action.storeId}`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: SALES_PERSON_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: SALES_PERSON_FAILED, payload: error.response });
    }
  }
}

function* createSalesPersonCategory(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/User/api/User/CreateSalesPerson`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SALES_PERSON_CATEGORY_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SALES_PERSON_CATEGORY_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editSalesPersonCategory(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/User/api/User/UpdateSalesPerson`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SALES_PERSON_CATEGORY_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SALES_PERSON_CATEGORY_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteSalesPersonCategory(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/User/api/User/RemoveSalesPerson?UserId=${encodeURIComponent(action.payload.salesPersonId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: SALES_PERSON_CATEGORY_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SALES_PERSON_CATEGORY_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
export default function* rootSalesPersonCategory() {
  yield all([
    takeLatest(SALES_PERSON_CATEGORY_REQUEST, fetchSalesPersonCategory),
    takeLatest(SALES_PERSON_CATEGORY_CREATE, createSalesPersonCategory),
    takeLatest(SALES_PERSON_CATEGORY_DELETE, deleteSalesPersonCategory),
    takeLatest(SALES_PERSON_CATEGORY_EDIT, editSalesPersonCategory),
    takeLatest(SALES_PERSON_COMMISSION_REQUEST, fetchSalesCommission),
    takeLatest(SALES_PERSON_REQUEST, fetchSalesPersonByStoreId),
  ])
}