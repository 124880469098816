import React, { useState, useEffect, useMemo } from "react";
import Title from "../../elements/Title";
import { Icon } from "react-icons-kit";
import { Link, useNavigate } from "react-router-dom";
import { ic_arrow_back } from "react-icons-kit/md";
import { connect } from "react-redux";
import MainWrapper from "../../MainWrapper";
import ExistingOrdersList from "./ExistingOrdersList";
import { STORE_INFO_REQUEST } from "../../../_utils/constants/admin/StoreInfo";
import { CUSTOMER_EXISTING_ORDER_LIST_REQUEST, SAVE_SELECTED_EXISTING_ORDER_REQUEST } from "../../../_utils/constants/Customer";
import CustomButton from "../../elements/CustomButton";
import { toast } from "react-toastify";

const ExistingOrders = ({
	auth: { auth, isAuthenticated },
	customerInfo: { existingOrderList, totalCount, isLoading, savedExistingOrder },
	StoreInfo: { storeInfo },
	fetchExistingOrdersList,
	fetchStoreInfo,
	saveExistingOrdersList
}) => {

	// states declaration
	const [checkFlag, setCheckFlag] = useState(false);
	const [selectedOrderedData, setSelectedOrderedData] = useState([]);
	const [savedJobNumber, setSavedJobNumber] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedIds, setSelectedIds] = useState([]);
	const [sort, setSort] = useState("desc");
	const [page, setPage] = useState(1);
	const [paginate, setPaginate] = useState(20);
	const [localTime, setLocalTime] = useState();
	const [localTimeZone, setLocalTimeZone] = useState();
	const [listParamState, setListParamState] = useState({
		"sortOn": "",
		"jobNumber": "",
		"salesPerson": "",
		"shipToPropertyName": "",
		"unitName": "",
		"installationDate": "",
		"StoreId": ""
		// "orderedDate": ""
	})

	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login")
		}
	}, [auth, isAuthenticated])

	// function to call store api
	useEffect(() => {
		if (isAuthenticated) {
			fetchStoreInfo(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// function to call existing order list api
	useEffect(() => {
		if (isAuthenticated) {
			fetchExistingOrdersList(auth[0].token, sort, page, paginate, listParamState);
			setSelectAll(false)
		}
	}, [auth, isAuthenticated, sort, page, paginate, listParamState]);

	// handle api response
	useEffect(() => {
		if (savedExistingOrder && savedExistingOrder.length > 0) {
			if (savedExistingOrder[0] === 'success') {
				toast.success('Success')
				fetchExistingOrdersList(auth[0].token, sort, page, paginate, listParamState);
			} else {
				toast.error(savedExistingOrder[0])
			}
		}
	}, [savedExistingOrder])

	// handle timezone calculation
	useEffect(() => {
		const clientTimezoneOffset = new Date().getTimezoneOffset();
		setLocalTime(clientTimezoneOffset);
		const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		setLocalTimeZone(clientTimezone);
	}, [])

	// function called to show updated listing
	const existingOrderListing = useMemo(() => {
		if(existingOrderList && localStorage.getItem('Order-Listing')) {
			let arr = []
			localStorage.removeItem('Order-Listing')
			return arr
		} else {
			return existingOrderList
		}
	} ,[existingOrderList])

	// function to handle selection of existing order data
	useMemo(() => {
		if (existingOrderList && existingOrderListing.length > 0) {
			existingOrderList.map(item => {
				if (item.OdooStatus === 'Quotation created successfully.' || item.OdooStatus === 'Sales Order created successfully.') {
					item.IsChecked = true
					savedJobNumber.push(item.JobNo)
					selectedIds.push(item.JobNo)
				} else if (selectedIds.includes(item.JobNo)) {
					item.IsChecked = true
				} else {
					item.IsChecked = false
				}
			})
		}
	}, [existingOrderListing])

	// function called on any filter value change
	const handleFilterChange = (e) => {
		const { id, value } = e.target;
		if (id === 'jobNumber' || id === 'shipToPropertyName' || id === 'unitName' || id === 'installationDate' || id === 'Salesperson' || id === 'StoreId') {
			// if (id === 'jobNumber' || id === 'shipToPropertyName' || id === 'orderedDate' || id === 'unitName' || id === 'installationDate' || id === 'Salesperson' || id === 'StoreId') {
			if (value.length > 0) {
				setPaginate(200)
			} else {
				setPaginate(20)
			}
		}
		setListParamState(
			{
				...listParamState,
				[id]: value
			}
		)
	}

	// function to manage store list data fetched
	const storeList = useMemo(() => {
		if (storeInfo)
			return storeInfo.map(v => ({ label: v.storeName, value: v.storeName }))
	}, [storeInfo])

	// function to modify listing on order selection
	const handleOrderListing = (isChecked, data) => {
		setCheckFlag(isChecked)
		if (existingOrderListing && existingOrderListing.length > 0) {
			existingOrderListing.map(item => {
				if (!item.IsChecked && (item.JobNo === data.JobNo)) {
					item.IsChecked = isChecked
					selectedIds.push(item.JobNo)
				} else if (item.IsChecked && (item.JobNo === data.JobNo)) {
					item.IsChecked = isChecked
					const index = selectedIds.indexOf(item.JobNo);
					if (index > -1) {
						selectedIds.splice(index, 1);
					}
				}
			})
		}
		if (existingOrderList && existingOrderList.length > 0) {
			existingOrderList.map(item => {
				if (!item.IsChecked && (item.JobNo === data.JobNo)) {
					item.IsChecked = isChecked
				} else if (item.IsChecked && (item.JobNo === data.JobNo)) {
					item.IsChecked = isChecked
				}
			})
		}
		let uniqueSelectedIds = _.uniq(selectedIds)
		setSelectedIds(uniqueSelectedIds)
	}

	// function called on job number selection
	const handleCheckboxClick = (e, data) => {
		handleOrderListing(e.target.checked, data)
		setSelectedOrderedData(data)
	}

	// function to modify listing on order selection
	const handleAllOrderListing = (isChecked) => {
		if (existingOrderListing && existingOrderListing.length > 0) {
			existingOrderListing.map(item => {
				if (!savedJobNumber.includes(item.JobNo)) {
					if (isChecked) {
						item.IsChecked = isChecked
						selectedIds.push(item.JobNo)
					} else {
						item.IsChecked = isChecked
						const index = selectedIds.indexOf(item.JobNo);
						if (index > -1) {
							selectedIds.splice(index, 1);
						}
					}
				}
			})
		}
		if (existingOrderList && existingOrderList.length > 0) {
			existingOrderList.map(item => {
				if (!savedJobNumber.includes(item.JobNo)) {
					if (isChecked) {
						item.IsChecked = isChecked
					} else {
						item.IsChecked = isChecked
					}
				}
			})
		}
		setCheckFlag(isChecked)
		let uniqueSelectedIds = _.uniq(selectedIds)
		setSelectedIds(uniqueSelectedIds)
	}

	// function called to handle checkbox selection
	const handleAllCheckboxSelect = (e) => {
		setSelectAll(e.target.checked)
		handleAllOrderListing(e.target.checked)
	}

	// function called to filter checked data
	const handleFilterCheckedData = () => {
		let tempIds = selectedIds.filter(element => !savedJobNumber.includes(element))
		tempIds = _.uniq(tempIds)
		return tempIds
	}

	// function called for processing orders
	const handleSaveClick = () => {
		let tempIds = handleFilterCheckedData()
		if (tempIds.length > 0) {
			let payload = {
				'JobNo': tempIds,
				'time': localTime,
				'timezone': localTimeZone
			}
			saveExistingOrdersList(auth[0].token, payload)
		} else {
			toast.error('Please select atleast one order to be processed')
		}
	}

  return (
    <MainWrapper>
			{isLoading ? (<div className="loading-container"><div className="spinner-border"></div></div>) : null}
			<div className="container">
				<div className="sub-header mt-5 mb-3">
					<Title>Existing Orders</Title>
					<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
					<CustomButton variant="primary" handleClick={handleSaveClick}>Process Orders</CustomButton>
				</div>
				<ExistingOrdersList	
					existingOrderListing={existingOrderListing}
					totalCount={totalCount}
					setSort={setSort}
					setPaginate={setPaginate}
					setPage={setPage}
					page={page}
					paginate={paginate}
					listParamState={listParamState}
					handleFilterChange={handleFilterChange}
					storeList={storeList}
					handleCheckboxClick={handleCheckboxClick}
					checkFlag={checkFlag}
					selectedOrderedData={selectedOrderedData}
					handleAllCheckboxSelect={handleAllCheckboxSelect}
					savedJobNumber={savedJobNumber}
					setSavedJobNumber={setSavedJobNumber}
					selectAll={selectAll}
					setSelectedIds={setSelectedIds}
					selectedIds={selectedIds}
				/>
			</div>
		</MainWrapper>
  )
}

const mapStateToProps = ({ auth, customerInfo, StoreInfo }) => {
	return {
		auth, customerInfo, StoreInfo
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		fetchExistingOrdersList: (token, sort, page, paginate, listParamState) => dispatch({ type: CUSTOMER_EXISTING_ORDER_LIST_REQUEST, token, sort, page, paginate, listParamState }),
		fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
		saveExistingOrdersList: (token, payload) => dispatch({ type: SAVE_SELECTED_EXISTING_ORDER_REQUEST, token, payload }),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ExistingOrders);