import React, { Fragment } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from 'prop-types';
import ClipLoader from "react-spinners/ClipLoader";

const Loader = ({ loading = true, children }) => {
  return (
    <Fragment>
      {loading ? (
        <LoadingOverlay active={loading} spinner={<ClipLoader color={'#2D831F'} />} text="Loading..." />
      ) : (
        children
      )}
    </Fragment>
  );
};

Loader.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default Loader;
