import {
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_FAILED,
  PRODUCT_CREATE,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAILED,
  PRODUCT_DELETE,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILED,
  PRODUCT_EDIT,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_EDIT_FAILED,
  PRODUCT_STATE_CHANGE,
} from "../../../_utils/constants/ProductCatalog";


const initialState = {
  valueList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case PRODUCT_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        valueList: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case PRODUCT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case PRODUCT_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case PRODUCT_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case PRODUCT_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case PRODUCT_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case PRODUCT_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case PRODUCT_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case PRODUCT_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case PRODUCT_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default ProductReducer;