import React, { Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateSoldBy = ({
  title,
  createModalOpen,
  showCreateModal,
  hideCreateModal,
  salesStageName,
  handleSalesStageNameChange,
  sortOrder,
  handleSortOrderChange,
  handleCreateModalSubmit
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} >
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="salesStageName">
              <Form.Label>Enter New {title}</Form.Label>
              <Form.Control type="text" name="salesStageName" onChange={handleSalesStageNameChange} value={salesStageName} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="sortOrder">
              <Form.Label>SoldByCode</Form.Label>
              <Form.Control type="text" name="sortOrder" onChange={handleSortOrderChange} value={sortOrder} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateModalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreateSoldBy;