import {
  LABOR_CATEGORY_REQUEST,
  LABOR_CATEGORY_SUCCESS,
  LABOR_CATEGORY_FAILED,
  LABOR_CATEGORY_CREATE,
  LABOR_CATEGORY_CREATE_SUCCESS,
  LABOR_CATEGORY_CREATE_FAILED,
  LABOR_CATEGORY_DELETE,
  LABOR_CATEGORY_DELETE_SUCCESS,
  LABOR_CATEGORY_DELETE_FAILED,
  LABOR_CATEGORY_EDIT,
  LABOR_CATEGORY_EDIT_SUCCESS,
  LABOR_CATEGORY_EDIT_FAILED,
  LABOR_CATEGORY_STATE_CHANGE,
} from "../../../_utils/constants/Labor";


const initialState = {
  laborCategory: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const LaborCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LABOR_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case LABOR_CATEGORY_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        laborCategory: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case LABOR_CATEGORY_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case LABOR_CATEGORY_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case LABOR_CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case LABOR_CATEGORY_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case LABOR_CATEGORY_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case LABOR_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case LABOR_CATEGORY_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case LABOR_CATEGORY_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case LABOR_CATEGORY_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case LABOR_CATEGORY_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case LABOR_CATEGORY_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default LaborCategoryReducer;