import React from "react";
import "../../assets/css/auth.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-left">
          <div className="top">
            <h2 className="sarina">NancyERP</h2>
          </div>
          <div className="bottom">
            <p>Register Your Account</p>
          </div>
        </div>
        <div className="login-right">
          <Form>
            <h3 className="title">Signup</h3>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="First Name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control type="text" placeholder="Last Name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control type="email" placeholder="Email" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control type="text" placeholder="Phone No." />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control as="textarea" rows={3} placeholder="Address" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control type="password" placeholder="Confirm Password" />
            </Form.Group>

            <Form.Group className="mb-3 d-flex" controlId="formBasicPassword">
              <Form.Select className="me-2" aria-label="Default select example">
                <option>Date</option>
                <option value="1">01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="3">04</option>
                <option value="3">05</option>
                <option value="3">06</option>
                <option value="3">07</option>
                <option value="3">08</option>
                <option value="3">09</option>
                <option value="3">10</option>
                <option value="3">11</option>
                <option value="3">12</option>
                <option value="3">13</option>
                <option value="3">14</option>
                <option value="3">15</option>
                <option value="3">16</option>
                <option value="3">17</option>
                <option value="3">18</option>
                <option value="3">19</option>
                <option value="3">20</option>
                <option value="3">21</option>
                <option value="3">22</option>
                <option value="3">23</option>
                <option value="3">24</option>
                <option value="3">25</option>
                <option value="3">26</option>
                <option value="3">27</option>
                <option value="3">28</option>
                <option value="3">29</option>
                <option value="3">30</option>
                <option value="3">31</option>
              </Form.Select>
              <Form.Select className="me-2" aria-label="Default select example">
                <option>Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March </option>
                <option value="3">April</option>
                <option value="3">May</option>
                <option value="3">June</option>
                <option value="3">July</option>
                <option value="3">August</option>
                <option value="3">September</option>
                <option value="3">October</option>
                <option value="3">November</option>
                <option value="3">December</option>
              </Form.Select>
              <Form.Select aria-label="Default select example">
                <option>Year</option>
                <option value="1">2000</option>
                <option value="2">2001</option>
                <option value="3">2002 </option>
                <option value="3">2003</option>
                <option value="3">2004</option>
                <option value="3">2005</option>
                <option value="3">2006</option>
                <option value="3">2007</option>
                <option value="3">2008</option>
                <option value="3">2009</option>
                <option value="3">2010</option>
                <option value="3">2011</option>
              </Form.Select>
            </Form.Group>

            <Form.Group
              className="mb-3 d-flex align-items-center"
              controlId="formBasicCheckbox"
            >
              <Button variant="primary w-100" type="submit">
                Register
              </Button>
            </Form.Group>

            <hr className="my-4" />

            <Form.Group
              className="mb-3 d-flex justify-content-center"
              controlId="formBasicCheckbox"
            >
              <Link className="ms-2" to="/">
                Already have an Account?
              </Link>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Register;
