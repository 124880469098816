import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../config";
import { SESSION_EXPIRED } from "../../_utils/constants/Auth";
import {
  ASSIGNED_ACCESS_REQUEST,
  ASSIGNED_ACCESS_SUCCESS,
  ASSIGNED_ACCESS_FAILED,
  MENU_LIST_REQUEST,
  MENU_LIST_SUCCESS,
  MENU_LIST_FAILED,
  USER_MENU_LIST_REQUEST,
  USER_MENU_LIST_SUCCESS,
  USER_MENU_LIST_FAILED
} from "../../_utils/constants/admin/Admin";

function* fetchAssignedAccessLavel(action) {

  try {
    const response = yield call(axios.get, `${API_URL}/User/api/RolePermission/GetRolePermission?roleId=${1}`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: ASSIGNED_ACCESS_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: ASSIGNED_ACCESS_FAILED, payload: error.response });
    }
  }
}
function* fetchMenuList(action) {

  try {
    const response = yield call(axios.get, `${API_URL}/User/api/RolePermission/GetRolePermission?roleId=${1}`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: MENU_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: MENU_LIST_FAILED, payload: error.response });
    }
  }
}
function* fetchUserMenuList(action) {
  try {
    const response = yield call(axios.get, `${API_URL}/User/api/RolePermission/GetRolePermissionByRoleId?roleId=${action.roleId}&userId=${action.userId}`, { headers: { Authorization: `Bearer ${action.token}` } });
    yield put({ type: USER_MENU_LIST_SUCCESS, payload: response?.data });
  } catch (error) {
    if (error && error?.response && error?.response?.status && error?.response?.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: USER_MENU_LIST_FAILED, payload: error?.response });
    }
  }
}
export default function* rootAdmin() {
  yield all([
    takeLatest(ASSIGNED_ACCESS_REQUEST, fetchAssignedAccessLavel),
    takeLatest(MENU_LIST_REQUEST, fetchMenuList),
    takeLatest(USER_MENU_LIST_REQUEST, fetchUserMenuList),
  ])
}