import {
	PRICING_CONTRACT_CREATE_REQUEST,
	PRICING_CONTRACT_CREATE_SUCCESS,
	PRICING_CONTRACT_CREATE_FAILED,
	PRICING_CONTRACT_PRODUCT_LIST_REQUEST,
	PRICING_CONTRACT_PRODUCT_LIST_SUCCESS,
	PRICING_CONTRACT_PRODUCT_LIST_FAILED,
	PRICING_CONTRACT_LABOR_LIST_REQUEST,
	PRICING_CONTRACT_LABOR_LIST_SUCCESS,
	PRICING_CONTRACT_LABOR_LIST_FAILED,
	PRICING_CONTRACT_MATERIAL_LIST_REQUEST,
	PRICING_CONTRACT_MATERIAL_LIST_SUCCESS,
	PRICING_CONTRACT_MATERIAL_LIST_FAILED,
	PRICING_CONTRACT_LABOR_ITEM_LIST_REQUEST,
	PRICING_CONTRACT_LABOR_ITEM_LIST_SUCCESS,
	PRICING_CONTRACT_LABOR_ITEM_LIST_FAILED,
	PRICING_CONTRACT_DETAILS_REQUEST,
	PRICING_CONTRACT_DETAILS_SUCCESS,
	PRICING_CONTRACT_DETAILS_FAILED,
	PRICING_CONTRACT_STATE_CHANGE,
	GROUP_PRICING_CONTRACT_PRODUCT_LIST_REQUEST,
	GROUP_PRICING_CONTRACT_PRODUCT_LIST_SUCCESS,
	GROUP_PRICING_CONTRACT_PRODUCT_LIST_FAILED,
	GROUP_PRICING_CONTRACT_CREATE_REQUEST,
	GROUP_PRICING_CONTRACT_CREATE_SUCCESS,
	GROUP_PRICING_CONTRACT_CREATE_FAILED,
	GROUP_PRICING_CONTRACT_UPDATE_REQUEST,
	GROUP_PRICING_CONTRACT_UPDATE_SUCCESS,
	GROUP_PRICING_CONTRACT_UPDATE_FAILED,
	GROUP_PRICING_CONTRACT_DELETE_REQUEST,
	GROUP_PRICING_CONTRACT_DELETE_SUCCESS,
	GROUP_PRICING_CONTRACT_DELETE_FAILED,
	GROUP_PRICING_CONTRACT_FILTER_REQUEST,
	GROUP_PRICING_CONTRACT_FILTER_SUCCESS,
	GROUP_PRICING_CONTRACT_FILTER_FAILED,
	GROUP_PRICING_CONTRACT_STATE_CHANGE,
	CUSTOMER_ORDER_MATERIAL_REQUEST,
	CUSTOMER_ORDER_MATERIAL_SUCCESS,
	CUSTOMER_ORDER_MATERIAL_FAILED,
	CUSTOMER_ORDER_INSTALLATION_REQUEST,
	CUSTOMER_ORDER_INSTALLATION_SUCCESS,
	CUSTOMER_ORDER_INSTALLATION_FAILED,
	PRICING_CONTRACT_ORDER_STATE_CHANGE,
	CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST,
	CUSTOMER_ORDER_MATERIAL_DELETE_SUCCESS,
	CUSTOMER_ORDER_MATERIAL_DELETE_FAILED,
	CUSTOMER_ORDER_LABOR_DELETE_REQUEST,
	CUSTOMER_ORDER_LABOR_DELETE_SUCCESS,
	CUSTOMER_ORDER_LABOR_DELETE_FAILED,
	CUSTOMER_ESTIMATES_REQUEST,
	CUSTOMER_ESTIMATES_SUCCESS,
	CUSTOMER_ESTIMATES_FAILED,
	PRICING_CONTRACT_TRANSITION_LABOR_LIST_REQUEST,
	PRICING_CONTRACT_TRANSITION_LABOR_LIST_SUCCESS,
	PRICING_CONTRACT_TRANSITION_LABOR_LIST_FAILED,
	PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_REQUEST,
	PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_SUCCESS,
	PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_FAILED,
	PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST,
	PRICING_CONTRACT_MISSING_STAIRS_LABOR_SUCCESS,
	PRICING_CONTRACT_MISSING_STAIRS_LABOR_FAILED,
	PRICING_CONTRACT_QUANTITY_SET_PRODUCT_REQUEST,
	PRICING_CONTRACT_QUANTITY_SET_PRODUCT_SUCCESS,
	PRICING_CONTRACT_QUANTITY_SET_PRODUCT_FAILED,
	GROUP_PRICING_CONTRACT_SAVE_PROPERTY_REQUEST,
	GROUP_PRICING_CONTRACT_SAVE_PROPERTY_SUCCESS,
	GROUP_PRICING_CONTRACT_SAVE_PROPERTY_FAILED

} from "../../../_utils/constants/PricingContract";

const initialState = {
	productList: [],
	laborList: [],
	materialList: [],
	laborItemList: [],
	pricingContractData: {},
	isLoading: false,
	isSuccess: false,
	isCreateLoading: false,
	isCreated: false,
	isPricingContract: false,
	errors: {},
	groupPricingContractData: [],
	groupPricingContractId: '',
	isGroupPricingContractCreated: false,
	isGroupPricingContractUpdated: false,
	isGroupPricingContractDeleted: false,
	isProcessing: false,
	isCheck: false,
	groupErrors: {},
	errorFlag: false,
	orderMaterialData: [],
	orderInstallationData: [],
	deletedOrderMaterialData: [],
	deletedOrderLaborData: [],
	orderErrors: {},
	laborTransitionList: [],
	materialTransitionList: [],
	missingStairsLaborContractList: [],
	getQuantityProduct: [],
	customerProductPriceList: [],
	estimates: [],
	groupPCPropertyId: []
};

const PricingContractReducer = (state = initialState, action) => {
	switch (action.type) {
		// case CUSTOMER_PRODUCT_PRICE_LIST_REQUEST:
		// 	return {
		// 		...state,
		// 		isLoading: true,
		// 		customerEstimates: [],
		// 	}
		// case CUSTOMER_PRODUCT_PRICE_LIST_SUCCESS:
		// 	return {
		// 		...state,
		// 		customerEstimates: action.payload.result,
		// 		errors: {},
		// 	}
		// case CUSTOMER_PRODUCT_PRICE_LIST_FAILED:
		// 	return {
		// 		...state,
		// 		isLoading: false,
		// 		errors: action.payload,
		// 	}
		case CUSTOMER_ESTIMATES_REQUEST:
			return {
				...state,
				isLoading: true,
				estimates: [],
			}
		case CUSTOMER_ESTIMATES_SUCCESS:
			return {
				...state,
				estimates: action.payload.result,
				errors: {},
			}
		case CUSTOMER_ESTIMATES_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload,
			}
		case PRICING_CONTRACT_CREATE_REQUEST:
			return {
				...state,
				isPricingContract: false,
				isCreateLoading: true
			}
		case PRICING_CONTRACT_CREATE_SUCCESS:
			return {
				...state,
				isPricingContract: true,
				isCreateLoading: false
			}
		case PRICING_CONTRACT_CREATE_FAILED:
			return {
				...state,
				isPricingContract: false,
				isCreateLoading: false
			}

    case PRICING_CONTRACT_PRODUCT_LIST_REQUEST:
			return {
				...state,
				productList: [],
				isCreated: false,
        isLoading: true,
        isSuccess: false,
        errors: {},
			}
		case PRICING_CONTRACT_PRODUCT_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				productList: decryptedData.result,
				isCreated: false,
        isLoading: false,
        isSuccess: true,
        errors: {},
			}
		case PRICING_CONTRACT_PRODUCT_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				isCreated: false,
				isSuccess: false,
				errors: action.payload
			}

		case PRICING_CONTRACT_LABOR_LIST_REQUEST:
			return {
				...state,
				laborList: [],
				isCreated: false,
        isLoading: true,
        isSuccess: false,
        errors: {},
			}
		case PRICING_CONTRACT_LABOR_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				laborList: decryptedData.result,
				isCreated: false,
        isLoading: false,
        isSuccess: true,
        errors: {},
			}
		case PRICING_CONTRACT_LABOR_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				isCreated: false,
				isSuccess: false,
				errors: action.payload
			}

		case PRICING_CONTRACT_MATERIAL_LIST_REQUEST:
			return {
				...state,
				materialList: [],
				isCreated: false,
        isLoading: true,
        isSuccess: false,
        errors: {},
			}
		case PRICING_CONTRACT_MATERIAL_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				materialList: decryptedData.result,
				isCreated: false,
        isLoading: false,
        isSuccess: true,
        errors: {},
			}
		case PRICING_CONTRACT_MATERIAL_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				isCreated: false,
				isSuccess: false,
				errors: action.payload
			}

		case PRICING_CONTRACT_LABOR_ITEM_LIST_REQUEST:
			return {
				...state,
				laborItemList: [],
				isCreated: false,
				isLoading: true,
				isSuccess: false,
				errors: {},
			}
		case PRICING_CONTRACT_LABOR_ITEM_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				laborItemList: decryptedData.result,
				isCreated: false,
				isLoading: false,
				isSuccess: true,
				errors: {},
			}
		case PRICING_CONTRACT_LABOR_ITEM_LIST_FAILED:
			return {
				...state,
				laborItemList: [],
				isCreated: false,
				isLoading: false,
				isSuccess: false,
				errors: action.payload
			}

		case PRICING_CONTRACT_DETAILS_REQUEST:
			return {
				...state,
				isLoading: false,
				isCreated: false,
				pricingContractData: {}
			}
		case PRICING_CONTRACT_DETAILS_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				isLoading: true,
				pricingContractData: decryptedData.result,
				isCreated: false
			}
		case PRICING_CONTRACT_DETAILS_FAILED:
			return {
				...state,
				isLoading: false,
				pricingContractData: {},
				isCreated: false
			}

    case PRICING_CONTRACT_STATE_CHANGE:
		return {
			...state,
			productList: [],
			laborList: [],
			materialList: [],
			isCreated: false,
      isLoading: false,
      isSuccess: false,
      isPricingContract: false,
      errors: {},
		}

		case GROUP_PRICING_CONTRACT_PRODUCT_LIST_REQUEST:
			return {
				...state,
				groupPricingContractData: [],
        isProcessing: true,
        isCheck: false,
        groupErrors: {},
			}
		case GROUP_PRICING_CONTRACT_PRODUCT_LIST_SUCCESS:
			var decryptedGroupData = action.payload;
			return {
				...state,
				groupPricingContractData: decryptedGroupData.result,
        isProcessing: false,
        isCheck: true,
        groupErrors: {},
			}
		case GROUP_PRICING_CONTRACT_PRODUCT_LIST_FAILED:
			return {
				...state,
				isProcessing: false,
				isCheck: false,
				groupErrors: action.payload
			}

		case GROUP_PRICING_CONTRACT_CREATE_REQUEST:
			return {
				...state,
        isProcessing: true,
				groupPricingContractId: '',
        isGroupPricingContractCreated: false,
        groupErrors: {},
				errorFlag: false
			}
		case GROUP_PRICING_CONTRACT_CREATE_SUCCESS:
			var decryptedGroupData = action.payload;
			return {
				...state,
        isProcessing: false,
        isGroupPricingContractCreated: true,
				groupPricingContractId: parseInt(decryptedGroupData.result),
        groupErrors: {},
				errorFlag: false
			}
		case GROUP_PRICING_CONTRACT_CREATE_FAILED:
			return {
				...state,
				isProcessing: false,
				isGroupPricingContractCreated: false,
				groupPricingContractId: '',
				groupErrors: action.payload,
				errorFlag: true
			}

		case GROUP_PRICING_CONTRACT_UPDATE_REQUEST:
			return {
				...state,
        isProcessing: true,
				groupPricingContractId: '',
        isGroupPricingContractUpdated: false,
        groupErrors: {},
			}
		case GROUP_PRICING_CONTRACT_UPDATE_SUCCESS:
			var decryptedGroupData = action.payload;
			return {
				...state,
        isProcessing: false,
        isGroupPricingContractDeleted: true,
				groupPricingContractId: parseInt(decryptedGroupData.result),
        groupErrors: {},
			}
		case GROUP_PRICING_CONTRACT_UPDATE_FAILED:
			return {
				...state,
				isProcessing: false,
				isGroupPricingContractUpdated: false,
				groupPricingContractId: '',
				groupErrors: action.payload
			}

		case GROUP_PRICING_CONTRACT_DELETE_REQUEST:
			return {
				...state,
        isProcessing: true,
				groupPricingContractId: '',
        isGroupPricingContractUpdated: false,
        groupErrors: {},
			}
		case GROUP_PRICING_CONTRACT_DELETE_SUCCESS:
			var decryptedGroupData = action.payload;
			return {
				...state,
        isProcessing: false,
        isGroupPricingContractDeleted: true,
				groupPricingContractId: parseInt(decryptedGroupData.result),
        groupErrors: {},
			}
		case GROUP_PRICING_CONTRACT_DELETE_FAILED:
			return {
				...state,
				isProcessing: false,
				isGroupPricingContractUpdated: false,
				groupPricingContractId: '',
				groupErrors: action.payload
			}

		case GROUP_PRICING_CONTRACT_FILTER_REQUEST:
			return {
				...state,
				groupPricingContractData: [],
        isProcessing: true,
        isCheck: false,
        groupErrors: {},
			}
		case GROUP_PRICING_CONTRACT_FILTER_SUCCESS:
			var decryptedGroupData = action.payload;
			return {
				...state,
				groupPricingContractData: decryptedGroupData.result,
        isProcessing: false,
        isCheck: true,
        groupErrors: {},
			}
		case GROUP_PRICING_CONTRACT_FILTER_FAILED:
			return {
				...state,
				isProcessing: false,
				isCheck: false,
				groupErrors: action.payload
			}

		case GROUP_PRICING_CONTRACT_STATE_CHANGE:
		return {
			...state,
			isGroupPricingContractCreated: false,
			isGroupPricingContractUpdated: false,
			isGroupPricingContractDeleted: false,
			isProcessing: false,
			isCheck: false,
			isPricingContract: false,
			groupErrors: {},
		}
		case CUSTOMER_ORDER_MATERIAL_REQUEST:
			return {
				...state,
        isProcessing: true,
        groupErrors: {},
			}
		case CUSTOMER_ORDER_MATERIAL_SUCCESS:
			var decryptedOrderData = action.payload;
			return {
				...state,
				orderMaterialData: decryptedOrderData.result,
        isProcessing: false,
        groupErrors: {},
			}
		case CUSTOMER_ORDER_MATERIAL_FAILED:
			return {
				...state,
				isProcessing: false,
				orderErrors: action.payload
			}
		case CUSTOMER_ORDER_INSTALLATION_REQUEST:
			return {
				...state,
				isProcessing: true,
				groupErrors: {},
			}
		case CUSTOMER_ORDER_INSTALLATION_SUCCESS:
			var decryptedOrderData = action.payload;
			return {
				...state,
				orderInstallationData: decryptedOrderData.result,
				isProcessing: false,
				groupErrors: {},
			}
		case CUSTOMER_ORDER_INSTALLATION_FAILED:
			return {
				...state,
				isProcessing: false,
				orderErrors: action.payload
			}
		case CUSTOMER_ORDER_MATERIAL_DELETE_REQUEST:
			return {
				...state,
				isProcessing: true,
				groupErrors: {},
			}
		case CUSTOMER_ORDER_MATERIAL_DELETE_SUCCESS:
			var decryptedOrderData = action.payload;
			return {
				...state,
				deletedOrderMaterialData: decryptedOrderData.result,
				isProcessing: false,
				groupErrors: {},
			}
		case CUSTOMER_ORDER_MATERIAL_DELETE_FAILED:
			return {
				...state,
				isProcessing: false,
				orderErrors: action.payload
			}
		case CUSTOMER_ORDER_LABOR_DELETE_REQUEST:
			return {
				...state,
				isProcessing: true,
				groupErrors: {},
			}
		case CUSTOMER_ORDER_LABOR_DELETE_SUCCESS:
			var decryptedOrderData = action.payload;
			return {
				...state,
				deletedOrderLaborData: decryptedOrderData.result,
				isProcessing: false,
				groupErrors: {},
			}
		case CUSTOMER_ORDER_LABOR_DELETE_FAILED:
			return {
				...state,
				isProcessing: false,
				orderErrors: action.payload
			}
		case PRICING_CONTRACT_TRANSITION_LABOR_LIST_REQUEST:
			return {
				...state,
				laborTransitionList: [],
				isCreated: false,
				isLoading: true,
				isSuccess: false,
				errors: {},
			}
		case PRICING_CONTRACT_TRANSITION_LABOR_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				laborTransitionList: decryptedData.result,
				isCreated: false,
				isLoading: false,
				isSuccess: true,
				errors: {},
			}
		case PRICING_CONTRACT_TRANSITION_LABOR_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				isCreated: false,
				isSuccess: false,
				errors: action.payload
			}

		case PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_REQUEST:
			return {
				...state,
				materialTransitionList: [],
				isCreated: false,
				isLoading: true,
				isSuccess: false,
				errors: {},
			}
		case PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_SUCCESS:
			var decryptedData = action.payload;
			return {
				...state,
				materialTransitionList: decryptedData.result,
				isCreated: false,
				isLoading: false,
				isSuccess: true,
				errors: {},
			}
		case PRICING_CONTRACT_TRANSITION_MATERIAL_LIST_FAILED:
			return {
				...state,
				isLoading: false,
				isCreated: false,
				isSuccess: false,
				errors: action.payload
			}
		case PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST:
			return {
				...state,
				isLoading: true,
				missingStairsLaborContractList: []
			}
		case PRICING_CONTRACT_MISSING_STAIRS_LABOR_SUCCESS:
			let decryptedLaborData = action.payload;
			return {
				...state,
				isLoading: false,
				missingStairsLaborContractList: decryptedLaborData.result,
				errors: {},
			}
		case PRICING_CONTRACT_MISSING_STAIRS_LABOR_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case PRICING_CONTRACT_QUANTITY_SET_PRODUCT_REQUEST:
			return {
				...state,
				isLoading: true,
				getQuantityProduct: []
			}
		case PRICING_CONTRACT_QUANTITY_SET_PRODUCT_SUCCESS:
			let decryptedQuantityProductData = action.payload;
			return {
				...state,
				isLoading: false,
				getQuantityProduct: decryptedQuantityProductData.result,
				errors: {},
			}
		case PRICING_CONTRACT_QUANTITY_SET_PRODUCT_FAILED:
			return {
				...state,
				isLoading: false,
				errors: action.payload
			}
		case PRICING_CONTRACT_ORDER_STATE_CHANGE:
		return {
			...state,
			orderMaterialData: [],
			orderInstallationData: [],
			isProcessing: false,
			orderErrors: {},
		}
		case GROUP_PRICING_CONTRACT_SAVE_PROPERTY_REQUEST:
			return {
				...state,
        isProcessing: true,
        groupErrors: {},
				errorFlag: false
			}
		case GROUP_PRICING_CONTRACT_SAVE_PROPERTY_SUCCESS:
			var decryptedGroupData = action.payload;
			return {
				...state,
        isProcessing: false,
				groupPCPropertyId: decryptedGroupData.result,
        groupErrors: {},
				errorFlag: false
			}
		case GROUP_PRICING_CONTRACT_SAVE_PROPERTY_FAILED:
			return {
				...state,
				isProcessing: false,
				groupErrors: action.payload,
				errorFlag: true
			}
	};
	return state;
};


export default PricingContractReducer;