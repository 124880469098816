import {
  STORE_INFO_REQUEST,
  STORE_INFO_SUCCESS,
  STORE_INFO_FAILED,
  STORE_INFO_CREATE,
  STORE_INFO_CREATE_SUCCESS,
  STORE_INFO_CREATE_FAILED,
  STORE_INFO_DELETE,
  STORE_INFO_DELETE_SUCCESS,
  STORE_INFO_DELETE_FAILED,
  STORE_INFO_EDIT,
  STORE_INFO_EDIT_SUCCESS,
  STORE_INFO_EDIT_FAILED,
  STORE_INFO_IMPORT_CUSTOMER_REQUEST,
  STORE_INFO_IMPORT_CUSTOMER_SUCCESS,
  STORE_INFO_IMPORT_CUSTOMER_FAILED,
  STORE_INFO_STATE_CHANGE
} from "../../../_utils/constants/admin/StoreInfo";

const initialState = {
  storeInfo: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  dataImportLoading: false,
  isDataImported: false,
  successfulImportedRecords: "",
  duplicatedImportedRecords: "",
  errors: {},
}

const StoreInfoReducer = (state = initialState, action) => {
  switch (action.type) {

    case STORE_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case STORE_INFO_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        storeInfo: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case STORE_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }

    case STORE_INFO_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case STORE_INFO_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case STORE_INFO_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case STORE_INFO_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case STORE_INFO_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case STORE_INFO_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }

    case STORE_INFO_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case STORE_INFO_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case STORE_INFO_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case STORE_INFO_IMPORT_CUSTOMER_REQUEST:
      return {
        ...state,
        dataImportLoading: true,
        isDataImported: false,
        successfulImportedRecords: "",
        duplicatedImportedRecords: "",
        errors: {}
      }
    case STORE_INFO_IMPORT_CUSTOMER_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        dataImportLoading: false,
        isDataImported: true,
        successfulImportedRecords: decryptedData.result[0].totalSuccess,
        duplicatedImportedRecords: decryptedData.result[0].totalDuplicate,
        errors: {}
      }
    case STORE_INFO_IMPORT_CUSTOMER_FAILED:
      return {
        ...state,
        dataImportLoading: false,
        isDataImported: false,
        successfulImportedRecords: "",
        duplicatedImportedRecords: "",
        errors: action.error
      }

    case STORE_INFO_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
        isDataImported: false,
        successfulImportedRecords: "",
        duplicatedImportedRecords: "",
        dataImportLoading: false
      }
    default:
      return state;
  }
}

export default StoreInfoReducer;