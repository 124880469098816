import React, { Fragment } from "react";
import { Table } from 'react-bootstrap';
import Icon from "react-icons-kit";
import { ic_mode_edit } from 'react-icons-kit/md';
import ConfirmDelete from "../../../elements/ConfirmDelete";
import _ from "lodash";

const CustomTable = ({
  roleListing,
  showConfirmDeleteModal,
  hideConfirmDeleteModal,
  handleConfirmDeleteSubmit,
  confirmDeleteModal,
  handleEdit
}) => {


  return (
    <Fragment>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Role Name</th>
            <th>Role Type</th>
            <th width="50" className="actions">
              <div className="d-flex">
                <span>Edit</span>
                <span>Delete</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(roleListing) ? _.map(roleListing, (value) => (
            <tr key={value.roleId}>
              <td>{value.roleName}</td>
              <td>{value.roleType}</td>
              <td>
                <div className="btn-group">
                  <Icon role="button" className="w-54 d-flex justify-content-center" size={18} icon={ic_mode_edit} onClick={(e) => handleEdit(e, value)} />
                  <ConfirmDelete
                    variant="primary"
                    title={`Employee`}
                    showConfirmDeleteModal={() => showConfirmDeleteModal(value.roleId)}
                    hideConfirmDeleteModal={hideConfirmDeleteModal}
                    handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
                    confirmDeleteModal={confirmDeleteModal}
                  />
                </div>
              </td>
            </tr>
          )) : null}
        </tbody>
      </Table>
    </Fragment>
  )
}
export default CustomTable;