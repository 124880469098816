import {
  CUSTOMER_APPROVAL_LIST_REQUEST,
  CUSTOMER_APPROVAL_LIST_SUCCESS,
  CUSTOMER_APPROVAL_LIST_FAILED,
  CUSTOMER_APPROVAL_UPDATE_REQUEST,
  CUSTOMER_APPROVAL_UPDATE_SUCCESS,
  CUSTOMER_APPROVAL_UPDATE_FAILED,
  CUSTOMER_APPROVAL_REJECT_REQUEST,
  CUSTOMER_APPROVAL_REJECT_SUCCESS,
  CUSTOMER_APPROVAL_REJECT_FAILED,
  CUSTOMER_APPROVAL_STATE_CHANGE,
  CUSTOMER_APPROVAL_DELETE_REQUEST,
  CUSTOMER_APPROVAL_DELETE_SUCCESS,
  CUSTOMER_APPROVAL_DELETE_FAILED
} from "../../../_utils/constants/CustomerApproval";

const initialState = {
  customerApprovalList: [],
  customerUpdationData: [],
  customerRejectedData: [],
  isLoading: false,
  errors: {},
  customerDeletedData: [],
  customerExistData: [],
};

const CustomerApprovalReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_APPROVAL_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {}
      }
    case CUSTOMER_APPROVAL_LIST_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        customerApprovalList: decryptedData.result,
        isLoading: false,
        errors: {}
      }
    case CUSTOMER_APPROVAL_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload
      }
    case CUSTOMER_APPROVAL_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {}
      }
    case CUSTOMER_APPROVAL_UPDATE_SUCCESS:
      return {
        ...state,
        customerUpdationData: action?.payload?.result,
        customerExistData: action?.payload?.error,
        isLoading: false,
        errors: {}
      }
    case CUSTOMER_APPROVAL_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload
      }
    case CUSTOMER_APPROVAL_REJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {}
      }
    case CUSTOMER_APPROVAL_REJECT_SUCCESS:
      return {
        ...state,
        customerRejectedData: action?.payload?.result,
        isLoading: false,
        errors: {}
      }
    case CUSTOMER_APPROVAL_REJECT_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload
      }
    case CUSTOMER_APPROVAL_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {}
      }
    case CUSTOMER_APPROVAL_DELETE_SUCCESS:
      return {
        ...state,
        customerDeletedData: action?.payload?.result,
        isLoading: false,
        errors: {}
      }
    case CUSTOMER_APPROVAL_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: action.payload
      }
    case CUSTOMER_APPROVAL_STATE_CHANGE:
      return {
        ...state,
        customerUpdationData: [],
        customerRejectedData: [],
        customerRejectedData: [],
        isLoading: false,
        errors: {},
      }
    }
  return state;
};

export default CustomerApprovalReducer;