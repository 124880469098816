import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  LABOR_ITEM_REQUEST,
  LABOR_ITEM_SUCCESS,
  LABOR_ITEM_FAILED,
  LABOR_ITEM_CREATE,
  LABOR_ITEM_CREATE_SUCCESS,
  LABOR_ITEM_CREATE_FAILED,
  LABOR_ITEM_DELETE,
  LABOR_ITEM_DELETE_SUCCESS,
  LABOR_ITEM_DELETE_FAILED,
  LABOR_ITEM_EDIT,
  LABOR_ITEM_EDIT_SUCCESS,
  LABOR_ITEM_EDIT_FAILED,
  LABOR_ITEM_UPDATE,
  LABOR_ITEM_UPDATE_SUCCESS,
  LABOR_ITEM_UPDATE_FAILED,
  LABOR_ITEM_FILTER_REQUEST,
  LABOR_ITEM_FILTER_SUCCESS,
  LABOR_ITEM_FILTER_FAILED
} from "../../../_utils/constants/Labor";

function* fetchLaborItem({ token, sort, page, paginate, stateFilter }) {

  try {
    const response = yield call(axios.get, `${API_URL}/Labor/api/LaborItem/GetLaborItem?PageIndex=${page}&PageSize=${paginate}&sort=${sort}&sortOn=${stateFilter.sortOn}&productTypeId=${stateFilter.productTypeId}&storeId=${stateFilter.storeId}&laborCategoryId=${stateFilter.laborCategoryId}&laborName=${stateFilter.laborName}&unitId=${stateFilter.unitId}&currentCost=${parseInt(stateFilter.currentCost) !== 0 && stateFilter.currentCost !== 'undefined' ? stateFilter.currentCost : " "}&futureCost1=${stateFilter.futureCost1 && stateFilter.futureCost1 !== 'undefined' ? stateFilter.futureCost1 : " "}&IncreaseDate=${stateFilter.increaseDate ? stateFilter.increaseDate : " "}`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: LABOR_ITEM_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: LABOR_ITEM_FAILED, payload: error.response });
    }
  }
}
function* fetchLaborFilterItem({ token, sort, page, paginate, stateFilter }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Labor/api/LaborItem/GetLaborItem?PageIndex=${page}&PageSize=${paginate}&sort=${sort}&sortOn=${stateFilter.sortOn}&productStructureId=${stateFilter.productStructureId && stateFilter.productStructureId !== 'undefined' ? stateFilter.productStructureId : 0}&storeId=${stateFilter.storeId && stateFilter.storeId !== 'undefined' ? stateFilter.storeId : 0}&laborCategoryId=${stateFilter.laborCategoryId && stateFilter.laborCategoryId !== 'undefined' ? stateFilter.laborCategoryId : 0}&laborName=${stateFilter.laborName}&unitId=${stateFilter.unitId && stateFilter.unitId !== 'undefined' ? stateFilter.unitId : 0}&currentCost=${parseInt(stateFilter.currentCost) !== 0 && stateFilter.currentCost !== 'undefined' ? stateFilter.currentCost : " "}&futureCost1=${stateFilter.futureCost1 && stateFilter.futureCost1 !== 'undefined' ? stateFilter.futureCost1 : " "}&IncreaseDate=${stateFilter.increaseDate ? stateFilter.increaseDate : " "}`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: LABOR_ITEM_FILTER_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: LABOR_ITEM_FILTER_FAILED, payload: error.response });
    }
  }
}
function* createLaborItem(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Labor/api/LaborItem/CreateLaborItem`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: LABOR_ITEM_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LABOR_ITEM_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editLaborItem(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/Labor/api/LaborItem/UpdateLaborItem`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: LABOR_ITEM_UPDATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LABOR_ITEM_UPDATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* updateLaborItem(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/Labor/api/LaborItem/UpdateLaborItem`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: LABOR_ITEM_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LABOR_ITEM_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteLaborItem(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Labor/api/LaborItem/RemoveLaborItem?laborItemId=${encodeURIComponent(action.payload.laborItemId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: LABOR_ITEM_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: LABOR_ITEM_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
export default function* rootLaborItem() {
  yield all([
    takeLatest(LABOR_ITEM_REQUEST, fetchLaborItem),
    takeLatest(LABOR_ITEM_FILTER_REQUEST, fetchLaborFilterItem),
    takeLatest(LABOR_ITEM_CREATE, createLaborItem),
    takeLatest(LABOR_ITEM_DELETE, deleteLaborItem),
    takeLatest(LABOR_ITEM_EDIT, editLaborItem),
    takeLatest(LABOR_ITEM_UPDATE, updateLaborItem),
  ])
}