import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  PRODUCT_TYPE_REQUEST,
  PRODUCT_TYPE_SUCCESS,
  PRODUCT_TYPE_FAILED,
  PRODUCT_TYPE_CREATE,
  PRODUCT_TYPE_CREATE_SUCCESS,
  PRODUCT_TYPE_CREATE_FAILED,
  PRODUCT_TYPE_DELETE,
  PRODUCT_TYPE_DELETE_SUCCESS,
  PRODUCT_TYPE_DELETE_FAILED,
  PRODUCT_TYPE_EDIT,
  PRODUCT_TYPE_EDIT_SUCCESS,
  PRODUCT_TYPE_EDIT_FAILED,
  SUB_PRODUCT_TYPE_REQUEST,
  SUB_PRODUCT_TYPE_SUCCESS,
  SUB_PRODUCT_TYPE_FAILED,
  GET_SUB_PRODUCT_REQUEST,
  GET_SUB_PRODUCT_SUCCESS,
  GET_SUB_PRODUCT_FAILED,
  GET_SUB_PRODUCT_MATERIAL_REQUEST,
  GET_SUB_PRODUCT_MATERIAL_SUCCESS,
  GET_SUB_PRODUCT_MATERIAL_FAILED,
  GET_TRANSITION_SUB_PRODUCT_REQUEST,
  GET_TRANSITION_SUB_PRODUCT_SUCCESS,
  GET_TRANSITION_SUB_PRODUCT_FAILED
} from "../../../_utils/constants/ProductCatalog";

/*****
 * ProductType Actions
 */

function* fetchProductType({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ProductStructure/GetProductStructure`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: PRODUCT_TYPE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PRODUCT_TYPE_FAILED, payload: error.response });
    }
  }
}

function* createProductType(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ProductStructure/CreateProductStructure`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_TYPE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_TYPE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editProductType(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ProductStructure/UpdateProductStructure`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_TYPE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_TYPE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteProductType(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/ProductStructure/RemoveProductStructure?ProductStructureId=${encodeURIComponent(action.payload.ProductStructureId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: PRODUCT_TYPE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_TYPE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* fetchSubProductType({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ProductStructure/GetProductStructureWithSubProdStructure`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: SUB_PRODUCT_TYPE_SUCCESS, payload: response?.data });
  } catch (error) {
    if (error && error?.response && error?.response.status && error?.response?.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: SUB_PRODUCT_TYPE_FAILED, payload: error?.response });
    }
  }
}
function* fetchSubProductRequest({ data }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ProductStructure/GetSubProductStructure?IsDefault=&ProductStructureId=${data?.productStructureId}`, { headers: { Authorization: `Bearer ${data?.token}` } });
    yield put({ type: GET_SUB_PRODUCT_SUCCESS, payload: response?.data });
  } catch (error) {
    if (error && error?.response && error?.response.status && error?.response?.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: GET_SUB_PRODUCT_FAILED, payload: error?.response });
    }
  }
}
function* fetchTransitionSubProductRequest({ data }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ProductStructure/GetSubProductStructure?IsDefault=&ProductStructureId=${data?.productStructureId}`, { headers: { Authorization: `Bearer ${data?.token}` } });
    yield put({ type: GET_TRANSITION_SUB_PRODUCT_SUCCESS, payload: response?.data });
  } catch (error) {
    if (error && error?.response && error?.response.status && error?.response?.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: GET_TRANSITION_SUB_PRODUCT_FAILED, payload: error?.response });
    }
  }
}
function* fetchSubProductMaterialRequest({ data }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ProductStructure/GetSubProductStructure?IsDefault=&ProductStructureId=${data?.productStructureId}`, { headers: { Authorization: `Bearer ${data?.token}` } });
    yield put({ type: GET_SUB_PRODUCT_MATERIAL_SUCCESS, payload: response?.data });
  } catch (error) {
    if (error && error?.response && error?.response.status && error?.response?.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: GET_SUB_PRODUCT_MATERIAL_FAILED, payload: error?.response });
    }
  }
}
export default function* rootProductTypeList() {
  yield all([
    takeLatest(PRODUCT_TYPE_REQUEST, fetchProductType),
    takeLatest(PRODUCT_TYPE_CREATE, createProductType),
    takeLatest(PRODUCT_TYPE_DELETE, deleteProductType),
    takeLatest(PRODUCT_TYPE_EDIT, editProductType),
    takeLatest(SUB_PRODUCT_TYPE_REQUEST, fetchSubProductType),
    takeLatest(GET_SUB_PRODUCT_REQUEST, fetchSubProductRequest),
    takeLatest(GET_TRANSITION_SUB_PRODUCT_REQUEST, fetchTransitionSubProductRequest),
    takeLatest(GET_SUB_PRODUCT_MATERIAL_REQUEST, fetchSubProductMaterialRequest),
  ]);
}