import {
  UNIT_REQUEST,
  UNIT_SUCCESS,
  UNIT_FAILED,
  UNIT_CREATE,
  UNIT_CREATE_SUCCESS,
  UNIT_CREATE_FAILED,
  UNIT_DELETE,
  UNIT_DELETE_SUCCESS,
  UNIT_DELETE_FAILED,
  UNIT_EDIT,
  UNIT_EDIT_SUCCESS,
  UNIT_EDIT_FAILED,
  UNIT_STATE_CHANGE,
} from "../../../_utils/constants/ProductCatalog";


const initialState = {
  valueList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const UnitReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case UNIT_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        valueList: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case UNIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case UNIT_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case UNIT_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case UNIT_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case UNIT_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case UNIT_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case UNIT_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case UNIT_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case UNIT_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case UNIT_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case UNIT_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default UnitReducer;