import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  PAYMENT_ACCOUNT_REQUEST,
  PAYMENT_ACCOUNT_SUCCESS,
  PAYMENT_ACCOUNT_FAILED,
  PAYMENT_ACCOUNT_CREATE,
  PAYMENT_ACCOUNT_CREATE_SUCCESS,
  PAYMENT_ACCOUNT_CREATE_FAILED,
  PAYMENT_ACCOUNT_DELETE,
  PAYMENT_ACCOUNT_DELETE_SUCCESS,
  PAYMENT_ACCOUNT_DELETE_FAILED,
  PAYMENT_ACCOUNT_EDIT,
  PAYMENT_ACCOUNT_EDIT_SUCCESS,
  PAYMENT_ACCOUNT_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * PaymentAccount Actions
 */

function* fetchPaymentAccount({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Admin/api/PaymentAccount/GetPaymentAccount`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: PAYMENT_ACCOUNT_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PAYMENT_ACCOUNT_FAILED, payload: error.response });
    }
  }
}

function* createPaymentAccount(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Admin/api/PaymentAccount/CreatePaymentAccount`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PAYMENT_ACCOUNT_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PAYMENT_ACCOUNT_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editPaymentAccount(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Admin/api/PaymentAccount/UpdatePaymentAccount`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PAYMENT_ACCOUNT_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PAYMENT_ACCOUNT_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deletePaymentAccount(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Admin/api/PaymentAccount/RemovePaymentAccount?PaymentAccountId=${encodeURIComponent(action.payload.PaymentAccountId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: PAYMENT_ACCOUNT_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PAYMENT_ACCOUNT_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootPaymentAccountList() {
  yield all([
    takeLatest(PAYMENT_ACCOUNT_REQUEST, fetchPaymentAccount),
    takeLatest(PAYMENT_ACCOUNT_CREATE, createPaymentAccount),
    takeLatest(PAYMENT_ACCOUNT_DELETE, deletePaymentAccount),
    takeLatest(PAYMENT_ACCOUNT_EDIT, editPaymentAccount),
  ]);
}