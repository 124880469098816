import {
  LABOR_ITEM_REQUEST,
  LABOR_ITEM_SUCCESS,
  LABOR_ITEM_FAILED,
  LABOR_ITEM_CREATE,
  LABOR_ITEM_CREATE_SUCCESS,
  LABOR_ITEM_CREATE_FAILED,
  LABOR_ITEM_DELETE,
  LABOR_ITEM_DELETE_SUCCESS,
  LABOR_ITEM_DELETE_FAILED,
  LABOR_ITEM_EDIT,
  LABOR_ITEM_EDIT_SUCCESS,
  LABOR_ITEM_EDIT_FAILED,
  LABOR_ITEM_UPDATE,
  LABOR_ITEM_UPDATE_SUCCESS,
  LABOR_ITEM_UPDATE_FAILED,
  LABOR_ITEM_STATE_CHANGE,
  LABOR_ITEM_FILTER_REQUEST,
  LABOR_ITEM_FILTER_SUCCESS,
  LABOR_ITEM_FILTER_FAILED
} from "../../../_utils/constants/Labor";


const initialState = {
  laborItem: [],
  totalCount: 0,
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const LaborItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case LABOR_ITEM_REQUEST:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: {}
      }
    case LABOR_ITEM_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        laborItem: decryptedData.result,
        totalCount: decryptedData.TotalCount,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case LABOR_ITEM_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case LABOR_ITEM_FILTER_REQUEST:
      return {
        ...state,

        isSuccess: false,
        errors: {}
      }
    case LABOR_ITEM_FILTER_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        laborItem: decryptedData.result,
        totalCount: decryptedData.TotalCount,

        isSuccess: true,
        errors: {}
      }
    case LABOR_ITEM_FILTER_FAILED:
      return {
        ...state,

        isSuccess: false,
        errors: action.payload
      }
    case LABOR_ITEM_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case LABOR_ITEM_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case LABOR_ITEM_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case LABOR_ITEM_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case LABOR_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case LABOR_ITEM_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case LABOR_ITEM_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case LABOR_ITEM_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case LABOR_ITEM_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }
    case LABOR_ITEM_UPDATE:
      return {
        ...state,
        isEdited: false,
        errors: {}
      }
    case LABOR_ITEM_UPDATE_SUCCESS:
      return {
        ...state,

        isEdited: true,
        errors: {}
      }
    case LABOR_ITEM_UPDATE_FAILED:
      return {
        ...state,

        isEdited: false,
        errors: action.error
      }

    case LABOR_ITEM_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default LaborItemReducer;