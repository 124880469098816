import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  CUSTOMER_APPROVAL_LIST_REQUEST,
  CUSTOMER_APPROVAL_LIST_SUCCESS,
  CUSTOMER_APPROVAL_LIST_FAILED,
  CUSTOMER_APPROVAL_UPDATE_REQUEST,
  CUSTOMER_APPROVAL_UPDATE_SUCCESS,
  CUSTOMER_APPROVAL_UPDATE_FAILED,
  CUSTOMER_APPROVAL_REJECT_REQUEST,
  CUSTOMER_APPROVAL_REJECT_SUCCESS,
  CUSTOMER_APPROVAL_REJECT_FAILED,
  CUSTOMER_APPROVAL_DELETE_REQUEST,
  CUSTOMER_APPROVAL_DELETE_SUCCESS,
  CUSTOMER_APPROVAL_DELETE_FAILED
} from "../../../_utils/constants/CustomerApproval";

function* fetchCustomerApprovalList(action) {
  try {
    let value = (action && action?.listParamState) ? action?.listParamState : 0
    const response = yield call(axios.get, `${API_URL}/User/api/Signup/GetSignupRequest?Email=${value.email}&FirstName=${value.firstName}&LastName=${value.lastName}&PageIndex=${value.pageIndex}&PageSize=${value.pageSize}`, { headers: { Authorization: `Bearer ${action?.token}` } });
    yield put({ type: CUSTOMER_APPROVAL_LIST_SUCCESS, payload: response?.data });
  } catch (error) {
    if (error && error?.response && error?.response?.status && error?.response?.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: CUSTOMER_APPROVAL_LIST_FAILED, payload: error?.response });
    }
  }
}
function* updateCustomerApprovalStatus(action) {
  try {
    let value = (action && action?.data && action?.data?.customerSignupId) ? action?.data?.customerSignupId : 0
    const response = yield call(axios.post, `${API_URL}/User/api/Signup/ApproveSignupRequest?SignupRequestId=${value}`, {}, { headers: { Authorization: `Bearer ${action?.token}` } });
    yield put({ type: CUSTOMER_APPROVAL_UPDATE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error?.response && error?.response?.status && error?.response?.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: CUSTOMER_APPROVAL_UPDATE_FAILED, payload: error?.response });
    }
  }
}
function* rejectCustomerApprovalRequest(action) {
  try {
    let value = (action && action?.data && action?.data?.customerSignupId) ? action?.data?.customerSignupId : 0
    const response = yield call(axios.post, `${API_URL}/User/api/Signup/RejectSignupRequest?SignupRequestId=${value}`, {}, { headers: { Authorization: `Bearer ${action?.token}` } });
    yield put({ type: CUSTOMER_APPROVAL_REJECT_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error?.response && error?.response?.status && error?.response?.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: CUSTOMER_APPROVAL_REJECT_FAILED, payload: error?.response });
    }
  }
}
function* deleteCustomerApprovalRequest(action) {
  try {
    let value = (action && action?.data && action?.data?.email) ? action?.data?.email : ""
    const response = yield call(axios.delete, `${API_URL}/User/api/Signup/DeleteCustomer?EmailId=${value}`, { headers: { Authorization: `Bearer ${action?.token}` } });
    yield put({ type: CUSTOMER_APPROVAL_DELETE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error?.response && error?.response?.status && error?.response?.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: CUSTOMER_APPROVAL_DELETE_FAILED, payload: error?.response });
    }
  }
}
export default function* rootCustomerApprovalList() {
  yield all([
    takeLatest(CUSTOMER_APPROVAL_LIST_REQUEST, fetchCustomerApprovalList),
    takeLatest(CUSTOMER_APPROVAL_UPDATE_REQUEST, updateCustomerApprovalStatus),
    takeLatest(CUSTOMER_APPROVAL_REJECT_REQUEST, rejectCustomerApprovalRequest),
    takeLatest(CUSTOMER_APPROVAL_DELETE_REQUEST, deleteCustomerApprovalRequest),
  ])
}