import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  SHIP_REQUEST,
  SHIP_SUCCESS,
  SHIP_FAILED,
  SHIP_CREATE,
  SHIP_CREATE_SUCCESS,
  SHIP_CREATE_FAILED,
  SHIP_DELETE,
  SHIP_DELETE_SUCCESS,
  SHIP_DELETE_FAILED,
  SHIP_EDIT,
  SHIP_EDIT_SUCCESS,
  SHIP_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * Ship Actions
 */

function* fetchShip({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/Ship/GetAllShip`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: SHIP_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: SHIP_FAILED, payload: error.response });
    }
  }
}

function* createShip(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/Ship/CreateShip`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SHIP_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SHIP_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editShip(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/Ship/UpdateShip`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SHIP_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SHIP_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteShip(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/Ship/RemoveShip?ShipId=${encodeURIComponent(action.payload.ShipId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: SHIP_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SHIP_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootShipList() {
  yield all([
    takeLatest(SHIP_REQUEST, fetchShip),
    takeLatest(SHIP_CREATE, createShip),
    takeLatest(SHIP_DELETE, deleteShip),
    takeLatest(SHIP_EDIT, editShip),
  ]);
}