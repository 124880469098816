import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateNotetemplate";
import ConfirmDelete from "./models/ConfirmDelete";
// import { encrypted } from '../../../../_utils/helpers';
import {
	NOTETEMPLATES_REQUEST,
	NOTETEMPLATES_CREATE_REQUEST,
	NOTETEMPLATES_STATE_CHANGE,
	NOTETEMPLATES_DELETE,
	NOTETEMPLATES_EDIT
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const NoteTemplates = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isLoading, isCreated, isDeleted, isEdited },
	fetchNoteTemplates,
	submitNoteTemplatesCreate,
	changeReducerState,
	submitNoteTemplatesDelete,
	submitNoteTemplatesEdit
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [noteTemplateName, setNoteTemplateName] = useState('');
	const [noteTemplateText, setNoteTemplateText] = useState('');
	const [editNoteTemplateId, setEditNoteTemplateId] = useState('');
	const [editNoteTemplateName, setEditNoteTemplateName] = useState('');
	const [editNoteTemplateText, setEditNoteTemplateText] = useState('');
	const [deleteNoteTemplateId, setDeleteNoteTemplateId] = useState('');

	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchNoteTemplates(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("Note template Created Successfully.");
			fetchNoteTemplates(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Note template Deleted Successfully.");
			fetchNoteTemplates(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Note template Data Updated Successfully.");
			fetchNoteTemplates(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleNoteTemplateNameChange = (e) => {
		setNoteTemplateName(e.target.value);
	}
	const handleNoteTemplateTextChange = (e) => {
		setNoteTemplateText(e.target.value);
	}

	// function called for create age demographic create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (noteTemplateName === '') {
			toast.error("Note template name cannot be empty.");
		} else {
			submitNoteTemplatesCreate({
				"data": {
					"itemNoteName": noteTemplateName,
					"itemNoteText": noteTemplateText,
					"createdBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteNoteTemplateId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteNoteTemplateId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete age demographic delete api
	const handleConfirmDeleteSubmit = () => {
		submitNoteTemplatesDelete({
			"itemNoteId": deleteNoteTemplateId,
			"token": auth[0].token
		});

		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditNoteTemplateId(data.itemNoteId);
		setEditNoteTemplateName(data.itemNoteName);
		setEditNoteTemplateText(data.itemNoteText);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editNoteTemplateName === '') {
			toast.error("Note template name cannot be empty.");
		} else {
			submitNoteTemplatesEdit({
				"data": {
					"itemNoteId": editNoteTemplateId,
					"itemNoteName": editNoteTemplateName,
					"itemNoteText": editNoteTemplateText
				},
				"token": auth[0].token
			});
			setEditNoteTemplateId('');
			setEditNoteTemplateName('');
			setEditNoteTemplateText('');
		}
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<ToastContainer />
					<div className="sub-header mt-5 mb-3">
						<h2>Note Templates</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Note Template`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							noteTemplateName={noteTemplateName}
							handleNoteTemplateNameChange={handleNoteTemplateNameChange}
							noteTemplateText={noteTemplateText}
							handleNoteTemplateTextChange={handleNoteTemplateTextChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Type</th>
								<th>Info</th>
								<th width="50" className="actions">
									<div className="d-flex">
										<span>Edit</span>
										<span>Delete</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									editNoteTemplateId === item.itemNoteId ? (
										<tr key={index}>
											<td width="200">
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editNoteTemplateName} onChange={(evt) => setEditNoteTemplateName(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editNoteTemplateText} onChange={(evt) => setEditNoteTemplateText(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td className="actions">
												<div className="btn-group">
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
													<ConfirmDelete variant="primary" title={`Note Template`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.itemNoteId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									) : (
										<tr key={index}>
											<td width="200">
												{item.itemNoteName}
											</td>
											<td>
												{item.itemNoteText}
											</td>
											<td className="actions">
												<div className="btn-group">
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
													<ConfirmDelete variant="primary" title={`Form Template`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.itemNoteId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									)
								))
							) : (
								<tr>
									<td colSpan={2}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	)
}
const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchNoteTemplates: (token) => dispatch({ type: NOTETEMPLATES_REQUEST, token }),
		submitNoteTemplatesCreate: (data) => dispatch({ type: NOTETEMPLATES_CREATE_REQUEST, payload: data }),
		changeReducerState: () => dispatch({ type: NOTETEMPLATES_STATE_CHANGE }),
		submitNoteTemplatesDelete: (data) => dispatch({ type: NOTETEMPLATES_DELETE, payload: data }),
		submitNoteTemplatesEdit: (data) => dispatch({ type: NOTETEMPLATES_EDIT, payload: data }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(NoteTemplates);