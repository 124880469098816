import React, { useState, useEffect } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Button, Table, Alert, Form, InputGroup } from 'react-bootstrap';
import {
	ALTERNATE_NAMES_REQUEST, ALTERNATE_NAMES_CREATE, ALTERNATE_NAMES_STATE_CHANGE
	, ALTERNATE_NAMES_DELETE, ALTERNATE_NAMES_EDIT
} from "../../../../_utils/constants/ValueList";
import { connect } from 'react-redux';
import Create from "./models/Create";
import ConfirmDelete from "./models/ConfirmDelete";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../../../layouts/Loader";
const AlternateNames = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchAlterNateNames,
	submitAlterNateNamesCreate,
	submitAlterNateNamesDelete,
	submitAlterNateNamesEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showCreateModalError, setShowCreateModalError] = useState(false);
	const [createModalErrorMessage, setCreateModalErrorMessage] = useState('');
	const [inputVal, setInputVal] = useState('');
	const [editAlternateNamesId, setEditAlternateNamesId] = useState('');
	const [editAlternateNamesName, setEditAlternateNamesName] = useState('');
	const [deleteAlternateNamesId, setDeleteAlternateNamesId] = useState('');
	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchAlterNateNames(auth[0].token);
		}
	}, [auth, isAuthenticated]);
	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("AlternateNames Created Successfully.");
			fetchAlterNateNames(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("AlternateNames Deleted Successfully.");
			fetchAlterNateNames(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("AlternateNames Data Updated Successfully.");
			fetchAlterNateNames(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);
	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleCreateModalChange = (e) => {
		setInputVal(e.target.value);
	}

	// function to clear error message from create modal
	const handleClearCreateModalError = () => {
		setShowCreateModalError(false);
		setCreateModalErrorMessage('');
	}

	// function called for create alternate name create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (inputVal === '') {
			setShowCreateModalError(true);
			setCreateModalErrorMessage('Alternate name cannot be empty.');
		} else {
			submitAlterNateNamesCreate({
				"data": {
					"AlternativeNames": inputVal,
					"AlternativeNamesOrder": 1,
					"CreatedBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}
	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditAlternateNamesId(data.alternativeNamesId);
		setEditAlternateNamesName(data.alternativeNames);
	}
	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editAlternateNamesName === '') {
			toast.error("Age Demographic name cannot be empty.");
		} else {
			submitAlterNateNamesEdit({
				"data": {
					"AlternativeNamesId": data.alternativeNamesId,
					"AlternativeNames": editAlternateNamesName,
					"AlternativeNamesOrder": data.alternativeNamesOrder
				},
				"token": auth[0].token
			});
			setEditAlternateNamesId('');
			setEditAlternateNamesName('');
		}
	}
	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteAlternateNamesId(id);
		setConfirmDeleteModal(true);
	};
	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteAlternateNamesId('');
		setConfirmDeleteModal(false);
	};
	// function called for delete age demographic delete api
	const handleConfirmDeleteSubmit = () => {
		submitAlterNateNamesDelete({
			"AlternativeNamesId": deleteAlternateNamesId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}
	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<ToastContainer />
					<div className="sub-header mt-5 mb-3">
						<h2>Alternate Name</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create variant="primary" showCreateModal={showCreateModal} title={`AlterNate Names`} hideCreateModal={hideCreateModal} handleCreateModalSubmit={handleCreateModalSubmit} handleCreateModalChange={handleCreateModalChange} createModalOpen={createModalOpen} inputVal={inputVal} showCreateModalError={showCreateModalError} createModalErrorMessage={createModalErrorMessage} handleClearCreateModalError={handleClearCreateModalError} />

					</div>
					{showError === true && (
						<Alert variant="danger" onClose={() => (setShowError(false), setErrorMessage(''))} dismissible>
							<p className="mb-0">{errorMessage}</p>
						</Alert>
					)}
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Alternate Name</th>
								<th width="50">Delete</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									<tr key={index}>
										{editAlternateNamesId === item.alternativeNamesId ? (
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editAlternateNamesName} onChange={(evt) => setEditAlternateNamesName(evt.target.value)} />
														<Button variant="outline-secondary">
															<Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
														</Button>
													</InputGroup>
												</Form.Group>
											</td>
										) : (
											<td>
												{item.alternativeNames}
												<Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
											</td>
										)}
										<td>
											<ConfirmDelete variant="primary" title={`Age Demographic`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.alternativeNamesId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={2}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	);
}
const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchAlterNateNames: (token) => dispatch({ type: ALTERNATE_NAMES_REQUEST, token }),
		submitAlterNateNamesCreate: (data) => dispatch({ type: ALTERNATE_NAMES_CREATE, payload: data }),
		changeReducerState: () => dispatch({ type: ALTERNATE_NAMES_STATE_CHANGE }),
		submitAlterNateNamesDelete: (data) => dispatch({ type: ALTERNATE_NAMES_DELETE, payload: data }),
		submitAlterNateNamesEdit: (data) => dispatch({ type: ALTERNATE_NAMES_EDIT, payload: data })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AlternateNames);
