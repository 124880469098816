import {
  AGEDEMOGRAPHIC_FAILED,
  AGEDEMOGRAPHIC_SUCCESS,
  AGEDEMOGRAPHIC_REQUEST,
  AGEDEMOGRAPHIC_CREATE,
  AGEDEMOGRAPHIC_CREATE_SUCCESS,
  AGEDEMOGRAPHIC_CREATE_FAILED,
  AGEDEMOGRAPHIC_DELETE,
  AGEDEMOGRAPHIC_DELETE_SUCCESS,
  AGEDEMOGRAPHIC_DELETE_FAILED,
  AGEDEMOGRAPHIC_EDIT,
  AGEDEMOGRAPHIC_EDIT_SUCCESS,
  AGEDEMOGRAPHIC_EDIT_FAILED,
  AGEDEMOGRAPHIC_STATE_CHANGE,
  ALTERNATE_NAMES_REQUEST,
  ALTERNATE_NAMES_SUCCESS,
  ALTERNATE_NAMES_FAILED,
  ALTERNATE_NAMES_CREATE,
  ALTERNATE_NAMES_CREATE_SUCCESS,
  ALTERNATE_NAMES_CREATE_FAILED,
  ALTERNATE_NAMES_DELETE,
  ALTERNATE_NAMES_DELETE_SUCCESS,
  ALTERNATE_NAMES_DELETE_FAILED,
  ALTERNATE_NAMES_EDIT,
  ALTERNATE_NAMES_EDIT_SUCCESS,
  ALTERNATE_NAMES_EDIT_FAILED,
  ALTERNATE_NAMES_STATE_CHANGE,
  NOTETEMPLATES_REQUEST,
  NOTETEMPLATES_SUCCESS,
  NOTETEMPLATES_FAILED,
  SHIPPINGMETHODS_REQUEST,
  SHIPPINGMETHODS_SUCCESS,
  SHIPPINGMETHODS_FAILED,
  SHIPPINGMETHODS_CREATE,
  SHIPPINGMETHODS_CREATE_SUCCESS,
  SHIPPINGMETHODS_CREATE_FAILED,
  SHIPPINGMETHODS_DELETE,
  SHIPPINGMETHODS_DELETE_SUCCESS,
  SHIPPINGMETHODS_DELETE_FAILED,
  SHIPPINGMETHODS_EDIT,
  SHIPPINGMETHODS_EDIT_SUCCESS,
  SHIPPINGMETHODS_EDIT_FAILED,
  SHIPPINGMETHODS_STATE_CHANGE,
  NOTETEMPLATES_CREATE_REQUEST,
  NOTETEMPLATES_CREATE_SUCCESS,
  NOTETEMPLATES_CREATE_FAILED,
  NOTETEMPLATES_STATE_CHANGE,
  NOTETEMPLATES_DELETE,
  NOTETEMPLATES_DELETE_SUCCESS,
  NOTETEMPLATES_DELETE_FAILED,
  NOTETEMPLATES_EDIT,
  NOTETEMPLATES_EDIT_SUCCESS,
  NOTETEMPLATES_EDIT_FAILED,
  JOBTYPES_REQUEST,
  JOBTYPES_SUCCESS,
  JOBTYPES_FAILED,
  JOBTYPES_CREATE,
  JOBTYPES_CREATE_SUCCESS,
  JOBTYPES_CREATE_FAILED,
  JOBTYPES_DELETE,
  JOBTYPES_DELETE_SUCCESS,
  JOBTYPES_DELETE_FAILED,
  JOBTYPES_EDIT,
  JOBTYPES_EDIT_SUCCESS,
  JOBTYPES_EDIT_FAILED,
  JOBTYPES_STATE_CHANGE,
  FORMTEMPLATE_REQUEST,
  FORMTEMPLATE_SUCCESS,
  FORMTEMPLATE_FAILED,
  FORMTEMPLATE_CREATE,
  FORMTEMPLATE_CREATE_SUCCESS,
  FORMTEMPLATE_CREATE_FAILED,
  FORMTEMPLATE_DELETE,
  FORMTEMPLATE_DELETE_SUCCESS,
  FORMTEMPLATE_DELETE_FAILED,
  FORMTEMPLATE_EDIT,
  FORMTEMPLATE_EDIT_SUCCESS,
  FORMTEMPLATE_EDIT_FAILED,
  FORMTEMPLATE_STATE_CHANGE,
  SALESSTAGE_REQUEST,
  SALESSTAGE_SUCCESS,
  SALESSTAGE_FAILED,
  SALESSTAGE_CREATE,
  SALESSTAGE_CREATE_SUCCESS,
  SALESSTAGE_CREATE_FAILED,
  SALESSTAGE_DELETE,
  SALESSTAGE_DELETE_SUCCESS,
  SALESSTAGE_DELETE_FAILED,
  SALESSTAGE_EDIT,
  SALESSTAGE_EDIT_SUCCESS,
  SALESSTAGE_EDIT_FAILED,
  SALESSTAGE_STATE_CHANGE,
  REGION_REQUEST,
  REGION_SUCCESS,
  REGION_FAILED,
  REGION_CREATE,
  REGION_CREATE_SUCCESS,
  REGION_CREATE_FAILED,
  REGION_DELETE,
  REGION_DELETE_SUCCESS,
  REGION_DELETE_FAILED,
  REGION_EDIT,
  REGION_EDIT_SUCCESS,
  REGION_EDIT_FAILED,
  REGION_STATE_CHANGE,
  PRODUCTINTEREST_REQUEST,
  PRODUCTINTEREST_SUCCESS,
  PRODUCTINTEREST_FAILED,
  PRODUCTINTEREST_CREATE,
  PRODUCTINTEREST_CREATE_SUCCESS,
  PRODUCTINTEREST_CREATE_FAILED,
  PRODUCTINTEREST_DELETE,
  PRODUCTINTEREST_DELETE_SUCCESS,
  PRODUCTINTEREST_DELETE_FAILED,
  PRODUCTINTEREST_EDIT,
  PRODUCTINTEREST_EDIT_SUCCESS,
  PRODUCTINTEREST_EDIT_FAILED,
  PRODUCTINTEREST_STATE_CHANGE,
  CASHBACK_REQUEST,
  CASHBACK_SUCCESS,
  CASHBACK_FAILED,
  CASHBACK_CREATE,
  CASHBACK_CREATE_SUCCESS,
  CASHBACK_CREATE_FAILED,
  CASHBACK_DELETE,
  CASHBACK_DELETE_SUCCESS,
  CASHBACK_DELETE_FAILED,
  CASHBACK_EDIT,
  CASHBACK_EDIT_SUCCESS,
  CASHBACK_EDIT_FAILED,
  CASHBACK_STATE_CHANGE,
  FORM_FAILED,
  FORM_SUCCESS,
  FORM_REQUEST,
  FORM_CREATE,
  FORM_CREATE_SUCCESS,
  FORM_CREATE_FAILED,
  FORM_DELETE,
  FORM_DELETE_SUCCESS,
  FORM_DELETE_FAILED,
  FORM_EDIT,
  FORM_EDIT_SUCCESS,
  FORM_EDIT_FAILED,
  FORM_STATE_CHANGE,
  AREA_REQUEST,
  AREA_SUCCESS,
  AREA_FAILED,
  AREA_CREATE_REQUEST,
  AREA_CREATE_SUCCESS,
  AREA_CREATE_FAILED,
  AREA_EDIT_REQUEST,
  AREA_EDIT_SUCCESS,
  AREA_EDIT_FAILED,
  AREA_DELETE_REQUEST,
  AREA_DELETE_SUCCESS,
  AREA_DELETE_FAILED,
  AREA_CHANGE,
  BIN_LOCATION_REQUEST,
  BIN_LOCATION_SUCCESS,
  BIN_LOCATION_FAILED,
  BIN_LOCATION_CREATE,
  BIN_LOCATION_CREATE_SUCCESS,
  BIN_LOCATION_CREATE_FAILED,
  BIN_LOCATION_DELETE,
  BIN_LOCATION_DELETE_SUCCESS,
  BIN_LOCATION_DELETE_FAILED,
  BIN_LOCATION_EDIT,
  BIN_LOCATION_EDIT_SUCCESS,
  BIN_LOCATION_EDIT_FAILED,
  BIN_LOCATION_STATE_CHANGE,
  COMPETITORS_REQUEST,
  COMPETITORS_SUCCESS,
  COMPETITORS_FAILED,
  COMPETITORS_CREATE,
  COMPETITORS_CREATE_SUCCESS,
  COMPETITORS_CREATE_FAILED,
  COMPETITORS_DELETE,
  COMPETITORS_DELETE_SUCCESS,
  COMPETITORS_DELETE_FAILED,
  COMPETITORS_EDIT,
  COMPETITORS_EDIT_SUCCESS,
  COMPETITORS_EDIT_FAILED,
  COMPETITORS_STATE_CHANGE,
  IDSTATUS_REQUEST,
  IDSTATUS_SUCCESS,
  IDSTATUS_FAILED,
  IDSTATUS_CREATE,
  IDSTATUS_CREATE_SUCCESS,
  IDSTATUS_CREATE_FAILED,
  IDSTATUS_DELETE,
  IDSTATUS_DELETE_SUCCESS,
  IDSTATUS_DELETE_FAILED,
  IDSTATUS_EDIT,
  IDSTATUS_EDIT_SUCCESS,
  IDSTATUS_EDIT_FAILED,
  IDSTATUS_STATE_CHANGE,
  LOST_REQUEST,
  LOST_SUCCESS,
  LOST_FAILED,
  LOST_CREATE,
  LOST_CREATE_SUCCESS,
  LOST_CREATE_FAILED,
  LOST_DELETE,
  LOST_DELETE_SUCCESS,
  LOST_DELETE_FAILED,
  LOST_EDIT,
  LOST_EDIT_SUCCESS,
  LOST_EDIT_FAILED,
  LOST_STATE_CHANGE,
  WON_REQUEST,
  WON_SUCCESS,
  WON_FAILED,
  WON_CREATE,
  WON_CREATE_SUCCESS,
  WON_CREATE_FAILED,
  WON_DELETE,
  WON_DELETE_SUCCESS,
  WON_DELETE_FAILED,
  WON_EDIT,
  WON_EDIT_SUCCESS,
  WON_EDIT_FAILED,
  WON_STATE_CHANGE,
  SICCODE_REQUEST,
  SICCODE_SUCCESS,
  SICCODE_FAILED,
  SICCODE_CREATE,
  SICCODE_CREATE_SUCCESS,
  SICCODE_CREATE_FAILED,
  SICCODE_DELETE,
  SICCODE_DELETE_SUCCESS,
  SICCODE_DELETE_FAILED,
  SICCODE_EDIT,
  SICCODE_EDIT_SUCCESS,
  SICCODE_EDIT_FAILED,
  SICCODE_STATE_CHANGE,
  MARKET_SECTOR_REQUEST,
  MARKET_SECTOR_SUCCESS,
  MARKET_SECTOR_FAILED,
  MARKET_SECTOR_CREATE,
  MARKET_SECTOR_CREATE_SUCCESS,
  MARKET_SECTOR_CREATE_FAILED,
  MARKET_SECTOR_DELETE,
  MARKET_SECTOR_DELETE_SUCCESS,
  MARKET_SECTOR_DELETE_FAILED,
  MARKET_SECTOR_EDIT,
  MARKET_SECTOR_EDIT_SUCCESS,
  MARKET_SECTOR_EDIT_FAILED,
  MARKET_SECTOR_STATE_CHANGE,
  BUYING_GROUP_REQUEST,
  BUYING_GROUP_SUCCESS,
  BUYING_GROUP_FAILED,
  BUYING_GROUP_CREATE,
  BUYING_GROUP_CREATE_SUCCESS,
  BUYING_GROUP_CREATE_FAILED,
  BUYING_GROUP_DELETE,
  BUYING_GROUP_DELETE_SUCCESS,
  BUYING_GROUP_DELETE_FAILED,
  BUYING_GROUP_EDIT,
  BUYING_GROUP_EDIT_SUCCESS,
  BUYING_GROUP_EDIT_FAILED,
  BUYING_GROUP_STATE_CHANGE,
  WAREHOUSE_REQUEST,
  WAREHOUSE_SUCCESS,
  WAREHOUSE_FAILED,
  WAREHOUSE_CREATE,
  WAREHOUSE_CREATE_SUCCESS,
  WAREHOUSE_CREATE_FAILED,
  WAREHOUSE_DELETE,
  WAREHOUSE_DELETE_SUCCESS,
  WAREHOUSE_DELETE_FAILED,
  WAREHOUSE_EDIT,
  WAREHOUSE_EDIT_SUCCESS,
  WAREHOUSE_EDIT_FAILED,
  WAREHOUSE_STATE_CHANGE,
  VENDOR_TYPE_REQUEST,
  VENDOR_TYPE_SUCCESS,
  VENDOR_TYPE_FAILED,
  VENDOR_TYPE_CREATE,
  VENDOR_TYPE_CREATE_SUCCESS,
  VENDOR_TYPE_CREATE_FAILED,
  VENDOR_TYPE_DELETE,
  VENDOR_TYPE_DELETE_SUCCESS,
  VENDOR_TYPE_DELETE_FAILED,
  VENDOR_TYPE_EDIT,
  VENDOR_TYPE_EDIT_SUCCESS,
  VENDOR_TYPE_EDIT_FAILED,
  VENDOR_TYPE_STATE_CHANGE,
  MARKETING_REQUEST,
  MARKETING_SUCCESS,
  MARKETING_FAILED,
  MARKETING_CREATE,
  MARKETING_CREATE_SUCCESS,
  MARKETING_CREATE_FAILED,
  MARKETING_DELETE,
  MARKETING_DELETE_SUCCESS,
  MARKETING_DELETE_FAILED,
  MARKETING_EDIT,
  MARKETING_EDIT_SUCCESS,
  MARKETING_EDIT_FAILED,
  MARKETING_STATE_CHANGE,
  VENDOR_REQUEST,
  VENDOR_SUCCESS,
  VENDOR_FAILED,
  VENDOR_CREATE,
  VENDOR_CREATE_SUCCESS,
  VENDOR_CREATE_FAILED,
  VENDOR_DELETE,
  VENDOR_DELETE_SUCCESS,
  VENDOR_DELETE_FAILED,
  VENDOR_EDIT,
  VENDOR_EDIT_SUCCESS,
  VENDOR_EDIT_FAILED,
  VENDOR_STATE_CHANGE,

} from "../../_utils/constants/ValueList";
// import { decrypted } from '../../_utils/helpers';

const initialState = {
  valueList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const valueListReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGEDEMOGRAPHIC_REQUEST:
    case FORM_REQUEST:
    case ALTERNATE_NAMES_REQUEST:
    case NOTETEMPLATES_REQUEST:
    case SHIPPINGMETHODS_REQUEST:
    case JOBTYPES_REQUEST:
    case FORMTEMPLATE_REQUEST:
    case SALESSTAGE_REQUEST:
    case REGION_REQUEST:
    case PRODUCTINTEREST_REQUEST:
    case CASHBACK_REQUEST:
    case AREA_REQUEST:
    case BIN_LOCATION_REQUEST:
    case COMPETITORS_REQUEST:
    case IDSTATUS_REQUEST:
    case LOST_REQUEST:
    case WON_REQUEST:
    case SICCODE_REQUEST:
    case MARKET_SECTOR_REQUEST:
    case BUYING_GROUP_REQUEST:
    case WAREHOUSE_REQUEST:
    case VENDOR_TYPE_REQUEST:
    case MARKETING_REQUEST:
    case VENDOR_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case AGEDEMOGRAPHIC_SUCCESS:
    case ALTERNATE_NAMES_SUCCESS:
    case NOTETEMPLATES_SUCCESS:
    case SHIPPINGMETHODS_SUCCESS:
    case JOBTYPES_SUCCESS:
    case FORMTEMPLATE_SUCCESS:
    case SALESSTAGE_SUCCESS:
    case REGION_SUCCESS:
    case PRODUCTINTEREST_SUCCESS:
    case CASHBACK_SUCCESS:
    case FORM_SUCCESS:
    case AREA_SUCCESS:
    case BIN_LOCATION_SUCCESS:
    case COMPETITORS_SUCCESS:
    case IDSTATUS_SUCCESS:
    case LOST_SUCCESS:
    case WON_SUCCESS:
    case SICCODE_SUCCESS:
    case MARKET_SECTOR_SUCCESS:
    case BUYING_GROUP_SUCCESS:
    case WAREHOUSE_SUCCESS:
    case VENDOR_TYPE_SUCCESS:
    case MARKETING_SUCCESS:
    case VENDOR_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        valueList: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case AGEDEMOGRAPHIC_FAILED:
    case ALTERNATE_NAMES_FAILED:
    case NOTETEMPLATES_FAILED:
    case SHIPPINGMETHODS_FAILED:
    case JOBTYPES_FAILED:
    case FORMTEMPLATE_FAILED:
    case SALESSTAGE_FAILED:
    case REGION_FAILED:
    case PRODUCTINTEREST_FAILED:
    case CASHBACK_FAILED:
    case FORM_FAILED:
    case AREA_FAILED:
    case BIN_LOCATION_FAILED:
    case COMPETITORS_FAILED:
    case IDSTATUS_FAILED:
    case LOST_FAILED:
    case WON_FAILED:
    case SICCODE_FAILED:
    case MARKET_SECTOR_FAILED:
    case BUYING_GROUP_FAILED:
    case WAREHOUSE_FAILED:
    case VENDOR_TYPE_FAILED:
    case MARKETING_FAILED:
    case VENDOR_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }

    case AGEDEMOGRAPHIC_CREATE:
    case ALTERNATE_NAMES_CREATE:
    case NOTETEMPLATES_CREATE_REQUEST:
    case SHIPPINGMETHODS_CREATE:
    case JOBTYPES_CREATE:
    case FORMTEMPLATE_CREATE:
    case SALESSTAGE_CREATE:
    case REGION_CREATE:
    case PRODUCTINTEREST_CREATE:
    case CASHBACK_CREATE:
    case FORM_CREATE:
    case AREA_CREATE_REQUEST:
    case BIN_LOCATION_CREATE:
    case COMPETITORS_CREATE:
    case IDSTATUS_CREATE:
    case LOST_CREATE:
    case WON_CREATE:
    case SICCODE_CREATE:
    case MARKET_SECTOR_CREATE:
    case BUYING_GROUP_CREATE:
    case WAREHOUSE_CREATE:
    case VENDOR_TYPE_CREATE:
    case MARKETING_CREATE:
    case VENDOR_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case AGEDEMOGRAPHIC_CREATE_SUCCESS:
    case ALTERNATE_NAMES_CREATE_SUCCESS:
    case NOTETEMPLATES_CREATE_SUCCESS:
    case SHIPPINGMETHODS_CREATE_SUCCESS:
    case JOBTYPES_CREATE_SUCCESS:
    case FORMTEMPLATE_CREATE_SUCCESS:
    case SALESSTAGE_CREATE_SUCCESS:
    case REGION_CREATE_SUCCESS:
    case PRODUCTINTEREST_CREATE_SUCCESS:
    case CASHBACK_CREATE_SUCCESS:
    case FORM_CREATE_SUCCESS:
    case AREA_CREATE_SUCCESS:
    case BIN_LOCATION_CREATE_SUCCESS:
    case COMPETITORS_CREATE_SUCCESS:
    case IDSTATUS_CREATE_SUCCESS:
    case LOST_CREATE_SUCCESS:
    case WON_CREATE_SUCCESS:
    case SICCODE_CREATE_SUCCESS:
    case MARKET_SECTOR_CREATE_SUCCESS:
    case BUYING_GROUP_CREATE_SUCCESS:
    case WAREHOUSE_CREATE_SUCCESS:
    case VENDOR_TYPE_CREATE_SUCCESS:
    case MARKETING_CREATE_SUCCESS:
    case VENDOR_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case AGEDEMOGRAPHIC_CREATE_FAILED:
    case ALTERNATE_NAMES_CREATE_FAILED:
    case NOTETEMPLATES_CREATE_FAILED:
    case SHIPPINGMETHODS_CREATE_FAILED:
    case JOBTYPES_CREATE_FAILED:
    case FORMTEMPLATE_CREATE_FAILED:
    case SALESSTAGE_CREATE_FAILED:
    case REGION_CREATE_FAILED:
    case PRODUCTINTEREST_CREATE_FAILED:
    case CASHBACK_CREATE_FAILED:
    case FORM_CREATE_FAILED:
    case AREA_CREATE_FAILED:
    case BIN_LOCATION_CREATE_FAILED:
    case COMPETITORS_CREATE_FAILED:
    case IDSTATUS_CREATE_FAILED:
    case LOST_CREATE_FAILED:
    case WON_CREATE_FAILED:
    case SICCODE_CREATE_FAILED:
    case MARKET_SECTOR_CREATE_FAILED:
    case BUYING_GROUP_CREATE_FAILED:
    case WAREHOUSE_CREATE_FAILED:
    case VENDOR_TYPE_CREATE_FAILED:
    case MARKETING_CREATE_FAILED:
    case VENDOR_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case AGEDEMOGRAPHIC_DELETE:
    case ALTERNATE_NAMES_DELETE:
    case SHIPPINGMETHODS_DELETE:
    case NOTETEMPLATES_DELETE:
    case JOBTYPES_DELETE:
    case FORMTEMPLATE_DELETE:
    case SALESSTAGE_DELETE:
    case REGION_DELETE:
    case PRODUCTINTEREST_DELETE:
    case CASHBACK_DELETE:
    case FORM_DELETE:
    case AREA_DELETE_REQUEST:
    case BIN_LOCATION_DELETE:
    case COMPETITORS_DELETE:
    case IDSTATUS_DELETE:
    case LOST_DELETE:
    case WON_DELETE:
    case SICCODE_DELETE:
    case MARKET_SECTOR_DELETE:
    case BUYING_GROUP_DELETE:
    case WAREHOUSE_DELETE:
    case VENDOR_TYPE_DELETE:
    case MARKETING_DELETE:
    case VENDOR_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case AGEDEMOGRAPHIC_DELETE_SUCCESS:
    case ALTERNATE_NAMES_DELETE_SUCCESS:
    case SHIPPINGMETHODS_DELETE_SUCCESS:
    case NOTETEMPLATES_DELETE_SUCCESS:
    case JOBTYPES_DELETE_SUCCESS:
    case FORMTEMPLATE_DELETE_SUCCESS:
    case SALESSTAGE_DELETE_SUCCESS:
    case REGION_DELETE_SUCCESS:
    case PRODUCTINTEREST_DELETE_SUCCESS:
    case CASHBACK_DELETE_SUCCESS:
    case FORM_DELETE_SUCCESS:
    case AREA_DELETE_SUCCESS:
    case BIN_LOCATION_DELETE_SUCCESS:
    case COMPETITORS_DELETE_SUCCESS:
    case IDSTATUS_DELETE_SUCCESS:
    case LOST_DELETE_SUCCESS:
    case WON_DELETE_SUCCESS:
    case SICCODE_DELETE_SUCCESS:
    case MARKET_SECTOR_DELETE_SUCCESS:
    case BUYING_GROUP_DELETE_SUCCESS:
    case WAREHOUSE_DELETE_SUCCESS:
    case VENDOR_TYPE_DELETE_SUCCESS:
    case MARKETING_DELETE_SUCCESS:
    case VENDOR_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case AGEDEMOGRAPHIC_DELETE_FAILED:
    case ALTERNATE_NAMES_DELETE_FAILED:
    case SHIPPINGMETHODS_DELETE_FAILED:
    case NOTETEMPLATES_DELETE_FAILED:
    case JOBTYPES_DELETE_FAILED:
    case FORMTEMPLATE_DELETE_FAILED:
    case SALESSTAGE_DELETE_FAILED:
    case REGION_DELETE_FAILED:
    case PRODUCTINTEREST_DELETE_FAILED:
    case CASHBACK_DELETE_FAILED:
    case FORM_DELETE_FAILED:
    case AREA_DELETE_FAILED:
    case BIN_LOCATION_DELETE_FAILED:
    case COMPETITORS_DELETE_FAILED:
    case IDSTATUS_DELETE_FAILED:
    case LOST_DELETE_FAILED:
    case WON_DELETE_FAILED:
    case SICCODE_DELETE_FAILED:
    case MARKET_SECTOR_DELETE_FAILED:
    case BUYING_GROUP_DELETE_FAILED:
    case WAREHOUSE_DELETE_FAILED:
    case VENDOR_TYPE_DELETE_FAILED:
    case MARKETING_DELETE_FAILED:
    case VENDOR_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case NOTETEMPLATES_EDIT:
    case AGEDEMOGRAPHIC_EDIT:
    case ALTERNATE_NAMES_EDIT:
    case SHIPPINGMETHODS_EDIT:
    case JOBTYPES_EDIT:
    case FORMTEMPLATE_EDIT:
    case SALESSTAGE_EDIT:
    case REGION_EDIT:
    case PRODUCTINTEREST_EDIT:
    case CASHBACK_EDIT:
    case FORM_EDIT:
    case AREA_EDIT_REQUEST:
    case BIN_LOCATION_EDIT:
    case COMPETITORS_EDIT:
    case IDSTATUS_EDIT:
    case LOST_EDIT:
    case WON_EDIT:
    case SICCODE_EDIT:
    case MARKET_SECTOR_EDIT:
    case BUYING_GROUP_EDIT:
    case WAREHOUSE_EDIT:
    case VENDOR_TYPE_EDIT:
    case MARKETING_EDIT:
    case VENDOR_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case AGEDEMOGRAPHIC_EDIT_SUCCESS:
    case ALTERNATE_NAMES_EDIT_SUCCESS:
    case SHIPPINGMETHODS_EDIT_SUCCESS:
    case NOTETEMPLATES_EDIT_SUCCESS:
    case JOBTYPES_EDIT_SUCCESS:
    case FORMTEMPLATE_EDIT_SUCCESS:
    case SALESSTAGE_EDIT_SUCCESS:
    case REGION_EDIT_SUCCESS:
    case PRODUCTINTEREST_EDIT_SUCCESS:
    case CASHBACK_EDIT_SUCCESS:
    case FORM_EDIT_SUCCESS:
    case AREA_EDIT_SUCCESS:
    case BIN_LOCATION_EDIT_SUCCESS:
    case COMPETITORS_EDIT_SUCCESS:
    case IDSTATUS_EDIT_SUCCESS:
    case LOST_EDIT_SUCCESS:
    case WON_EDIT_SUCCESS:
    case SICCODE_EDIT_SUCCESS:
    case MARKET_SECTOR_EDIT_SUCCESS:
    case BUYING_GROUP_EDIT_SUCCESS:
    case WAREHOUSE_EDIT_SUCCESS:
    case VENDOR_TYPE_EDIT_SUCCESS:
    case MARKETING_EDIT_SUCCESS:
    case VENDOR_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case AGEDEMOGRAPHIC_EDIT_FAILED:
    case ALTERNATE_NAMES_EDIT_FAILED:
    case SHIPPINGMETHODS_EDIT_FAILED:
    case NOTETEMPLATES_EDIT_FAILED:
    case JOBTYPES_EDIT_FAILED:
    case FORMTEMPLATE_EDIT_FAILED:
    case SALESSTAGE_EDIT_FAILED:
    case REGION_EDIT_FAILED:
    case PRODUCTINTEREST_EDIT_FAILED:
    case CASHBACK_EDIT_FAILED:
    case FORM_EDIT_FAILED:
    case AREA_EDIT_FAILED:
    case BIN_LOCATION_EDIT_FAILED:
    case COMPETITORS_EDIT_FAILED:
    case IDSTATUS_EDIT_FAILED:
    case LOST_EDIT_FAILED:
    case WON_EDIT_FAILED:
    case SICCODE_EDIT_FAILED:
    case MARKET_SECTOR_EDIT_FAILED:
    case BUYING_GROUP_EDIT_FAILED:
    case WAREHOUSE_EDIT_FAILED:
    case VENDOR_TYPE_EDIT_FAILED:
    case MARKETING_EDIT_FAILED:
    case VENDOR_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case AGEDEMOGRAPHIC_STATE_CHANGE:
    case NOTETEMPLATES_STATE_CHANGE:
    case SHIPPINGMETHODS_STATE_CHANGE:
    case JOBTYPES_STATE_CHANGE:
    case FORMTEMPLATE_STATE_CHANGE:
    case SALESSTAGE_STATE_CHANGE:
    case REGION_STATE_CHANGE:
    case PRODUCTINTEREST_STATE_CHANGE:
    case CASHBACK_STATE_CHANGE:
    case FORM_STATE_CHANGE:
    case ALTERNATE_NAMES_STATE_CHANGE:
    case AREA_CHANGE:
    case BIN_LOCATION_STATE_CHANGE:
    case COMPETITORS_STATE_CHANGE:
    case IDSTATUS_STATE_CHANGE:
    case LOST_STATE_CHANGE:
    case WON_STATE_CHANGE:
    case SICCODE_STATE_CHANGE:
    case MARKET_SECTOR_STATE_CHANGE:
    case BUYING_GROUP_STATE_CHANGE:
    case WAREHOUSE_STATE_CHANGE:
    case VENDOR_TYPE_STATE_CHANGE:
    case MARKETING_STATE_CHANGE:
    case VENDOR_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default valueListReducer;