import React, { Fragment, useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useDropzone } from 'react-dropzone';
import Icon from 'react-icons-kit';
import { close } from 'react-icons-kit/fa';
import { connect } from 'react-redux';
import { STORE_INFO_IMPORT_CUSTOMER_REQUEST } from "../../../../../_utils/constants/admin/StoreInfo";

const ImportCustomer = ({
	auth: { auth },
	StoreInfo: { dataImportLoading, isDataImported, successfulImportedRecords, duplicatedImportedRecords },
	importModalOpen,
	showImportModal,
	hideImportModal,
	importStoreId,
	submitImportCustomer
}) => {

	// states declarations
	const [myFiles, setMyFiles] = useState([]);

	// dropzone initialization function
	const { getRootProps, getInputProps, inputRef } = useDropzone({
		accept: {
			'text/csv': ['.csv'],
			'application/vnd': ['.xls', '.xlsx']
		},
		onDrop: acceptedFiles => {
			acceptedFiles.map(file => setMyFiles(myFiles => [...myFiles, Object.assign(file)]))
		}
	});

	// function to remove a file from uploaded file list
	const removeFile = (file) => () => {
		const newFiles = [...myFiles];
		newFiles.splice(newFiles.indexOf(file), 1);
		setMyFiles(newFiles);
	}

	// function to remove all uploaded files
	const removeAll = () => {
		setMyFiles([]);
		inputRef.current.value = '';
	}

	// function called on file uploads
	const files = myFiles.map(file => (
		<li key={file.path} className="mb-2">
			{file.path} <Icon role='button' className="float-end" onClick={removeFile(file)} size={16} icon={close} />
		</li>
	));

	const handleImportCustomerSubmit = (e) => {
		e.preventDefault();
		var formdata = new FormData();
		formdata.append("StoreId", importStoreId);
		formdata.append("UserId", auth[0].userId);
		myFiles.map((item) => (
			formdata.append("files", item, item.name)
		))
		submitImportCustomer({
			'token': auth[0].token,
			'data': formdata
		});
	}
	useEffect(() => {
		if (isDataImported === true) {
			removeAll();
		}
	}, [isDataImported])

	return (
		<Fragment>
			<Button className="btn-sm" variant="primary" onClick={showImportModal}>
				Import Customers
			</Button>
			<Modal show={importModalOpen} size="md">
				<Form onSubmit={handleImportCustomerSubmit}>
					<Modal.Header>
						<Modal.Title>Import New Customers</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<section className="container">
							<div {...getRootProps({ className: 'dropzone-style' })}>
								<input {...getInputProps()} />
								<p>Drag &apos;n&apos; drop some files here, or click to select files</p>
								<em>(Only *.xls, *.xlsx and *.csv files will be accepted)</em>
							</div>
							{files.length > 0 && (
								<div>
									<aside className="mt-3">
										<Row>
											<Col xs="8">
												<h5>Uploaded Files</h5>
											</Col>
											<Col xs="4">
												<div role="button" className="float-end" onClick={removeAll}>Remove All</div>
											</Col>
										</Row>
										<ul>{files}</ul>
									</aside>
								</div>
							)}
							{(successfulImportedRecords !== "" && duplicatedImportedRecords !== "") ? (
								<aside className="mt-3">
									<Row>
										<Col>
											<h6>Data imported successfully - {successfulImportedRecords}</h6>
										</Col>
									</Row>
									<Row>
										<Col>
											<h6>Duplicate data found - {duplicatedImportedRecords}</h6>
										</Col>
									</Row>
									<ul>{files}</ul>
								</aside>
							) : ""}
						</section>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={hideImportModal}>
							Close
						</Button>
						{dataImportLoading ? (
							<Button variant="primary" type="button">
								<div className="spinner-border"></div>
							</Button>
						) : (
							<Button variant="primary" type="submit">
								Save
							</Button>
						)}
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	)
};

const mapStateToProps = ({ auth, StoreInfo }) => {
	return {
		auth,
		StoreInfo
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		submitImportCustomer: (data) => dispatch({ type: STORE_INFO_IMPORT_CUSTOMER_REQUEST, data })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportCustomer);