import React, { Fragment } from "react";
import Customer from "../customer/Customer";

const PlaceOrder = ({handlePropertyClick}) => {

  return (
    <Fragment>
			<Customer 
				handlePropertyClick={true}
			/>
		</Fragment>
  )
}

export default PlaceOrder;