import React, { useState, useMemo, useEffect } from "react";
import MainWrapper from "../../MainWrapper";
import Title from "../../elements/Title";
import { Icon } from "react-icons-kit";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ic_arrow_back } from "react-icons-kit/md";
import { connect } from "react-redux";
import { CUSTOMER_LIST_REQUEST } from "../../../_utils/constants/Customer";
import { STORE_INFO_REQUEST } from "../../../_utils/constants/admin/StoreInfo";
import CustomerList from "./CustomerList";

const Customer = ({
	auth: { auth, isAuthenticated },
	customerInfo: { customerList, totalCount },
	StoreInfo: { storeInfo },
	fetchCustomerList,
	fetchStoreInfo,
	handlePropertyClick
}) => {

	// states declaration
	const [sort, setSort] = useState("asc");
	const [page, setPage] = useState(1);
	const [paginate, setPaginate] = useState(20);
	const [listParamState, setListParamState] = useState({
		"sortOn": "",
		"salesPerson": "",
		"shipToPropertyName": "",
		"shipToManagementCompany": "",
		"shipToAddress": "",
		"shipToState": "",
		"shipToCity": "",
		"StoreId": ""
	})

	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login")
		}
	}, [auth, isAuthenticated])

	// function called to show updated listing
	const customerListing = useMemo(() => {
		if(customerList && localStorage.getItem('Customer-Listing')) {
			let arr = []
			localStorage.removeItem('Customer-Listing')
			return arr
		} else {
			return customerList
		}
	} ,[customerList]) 

	// function to check whether user is authenticated or not, if yes then call store list api
	useMemo(() => {
    if (!isAuthenticated) return <Navigate to="/login" />;
		else fetchStoreInfo(auth[0].token);
  }, [auth, isAuthenticated]);

	// function to call customer list api
	useEffect(() => {
		if (isAuthenticated) {
			fetchCustomerList(auth[0].token, sort, page, paginate, listParamState, auth[0].userId, false, false, false);
		}
	}, [auth, isAuthenticated, sort, page, paginate, listParamState]);

	// function called on any filter value change
	const handleFilterChange = (e) => {
		const { id, value } = e.target;
		setListParamState(
			{
				...listParamState,
				[id]: value
			}
		)
	}

	// function to manage store list data fetched
	const storeList = useMemo(() => {
		if (storeInfo)
			return storeInfo.map(v => ({ label: v.storeName, value: v.storeId }))
	}, [storeInfo])
	
	return (
		<MainWrapper>
			<div className="container">
				<div className="sub-header mt-5 mb-3">
					<Title>Customer List</Title>
					<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
				</div>
				<CustomerList
					customerList={customerListing}
					totalCount={totalCount}
					setSort={setSort}
					setPaginate={setPaginate}
					setPage={setPage}
					page={page}
					paginate={paginate}
					listParamState={listParamState}
					handleFilterChange={handleFilterChange}
					storeList={storeList}
					handlePropertyClick={handlePropertyClick}
				/>
			</div>
		</MainWrapper>
	)
}
const mapStateToProps = ({ auth, customerInfo, StoreInfo }) => {
	return {
		auth, customerInfo, StoreInfo
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		fetchCustomerList: (token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked) => dispatch({ type: CUSTOMER_LIST_REQUEST, token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked }),
		fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Customer);