import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_delete_outline } from 'react-icons-kit/md';

const ConfirmDelete = ({ showConfirmDeleteModal, hideConfirmDeleteModal, confirmDeleteModal, handleConfirmDeleteSubmit, title }) => {

	return (
		<Fragment>
			{/* <Button variant="primary" className="delete" onClick={showConfirmDeleteModal}>
				<Icon size={24} icon={ic_delete_outline} />
			</Button> */}
			<Button variant="light" className="delete p-0 w-54" onClick={showConfirmDeleteModal}><Icon size={18} icon={ic_delete_outline} /></Button>
			<Modal show={confirmDeleteModal} >
				<Modal.Header>Delete {title}</Modal.Header>
				<Modal.Body>
					Are you sure you want to delete it?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={hideConfirmDeleteModal}>
						No
					</Button>
					<Button variant="primary" onClick={handleConfirmDeleteSubmit} id="model_delete" name="model_delete">
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	)
};

export default ConfirmDelete;