import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  NOTE_REQUEST,
  NOTE_SUCCESS,
  NOTE_FAILED,
  NOTE_CREATE,
  NOTE_CREATE_SUCCESS,
  NOTE_CREATE_FAILED,
  NOTE_DELETE,
  NOTE_DELETE_SUCCESS,
  NOTE_DELETE_FAILED,
  NOTE_EDIT,
  NOTE_EDIT_SUCCESS,
  NOTE_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * Note Actions
 */

function* fetchNote({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/Note/GetAllNote`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: NOTE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: NOTE_FAILED, payload: error.response });
    }
  }
}

function* createNote(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/Note/CreateNote`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: NOTE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: NOTE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editNote(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/Product/api/Note/UpdateNote`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: NOTE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: NOTE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteNote(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/Note/RemoveNote?NotesId=${encodeURIComponent(action.payload.NotesId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: NOTE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: NOTE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootNoteList() {
  yield all([
    takeLatest(NOTE_REQUEST, fetchNote),
    takeLatest(NOTE_CREATE, createNote),
    takeLatest(NOTE_DELETE, deleteNote),
    takeLatest(NOTE_EDIT, editNote),
  ]);
}