import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, Button, InputGroup } from 'react-bootstrap';
// import { encrypted } from '../../../../_utils/helpers';
import { connect } from 'react-redux';
import Create from "./models/Create";
import ConfirmDelete from "./models/ConfirmDelete";
import {
	SICCODE_REQUEST,
	SICCODE_CREATE,
	SICCODE_DELETE,
	SICCODE_EDIT,
	SICCODE_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { toast } from 'react-toastify';

const SICCodes = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchSicCode,
	submitSicCodeCreate,
	submitSicCodeDelete,
	submitSicCodeEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [inputVal, setInputVal] = useState('');
	const [deleteSicCodeId, setDeleteSicCodeId] = useState('');
	const [editSicCodeId, setEditSicCodeId] = useState('');
	const [editSicCodeName, setEditSicCodeName] = useState('');

	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchSicCode(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("SicCode Created Successfully.");
			fetchSicCode(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("SicCode Deleted Successfully.");
			fetchSicCode(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("SicCode Data Updated Successfully.");
			fetchSicCode(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleCreateModalChange = (e) => {
		setInputVal(e.target.value);
	}

	// function called for create age demographic create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (inputVal === '') {
			toast.error("SicCode name cannot be empty.");
		} else {
			submitSicCodeCreate({
				"data": {
					"SicCodes": inputVal,
					"CreatedBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteSicCodeId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteSicCodeId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete age demographic delete api
	const handleConfirmDeleteSubmit = () => {
		submitSicCodeDelete({
			"SicCodesId": deleteSicCodeId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditSicCodeId(data.sicCodesId);
		setEditSicCodeName(data.sicCodes);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editSicCodeName === '') {
			toast.error("SicCode name cannot be empty.");
		} else {
			submitSicCodeEdit({
				"data": {
					"SicCodesId": data.sicCodesId,
					"SicCodes": editSicCodeName,
				},
				"token": auth[0].token
			});
			setEditSicCodeId('');
			setEditSicCodeName('');
		}
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">

					<div className="sub-header mt-5 mb-3">
						<h2>SicCode</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`SicCode`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							inputVal={inputVal}
							handleCreateModalChange={handleCreateModalChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>SicCode</th>
								<th width="50">Delete</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									<tr key={index}>
										{editSicCodeId === item.sicCodesId ? (
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editSicCodeName} onChange={(evt) => setEditSicCodeName(evt.target.value)} />
														<Button variant="outline-secondary">
															<Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
														</Button>
													</InputGroup>
												</Form.Group>
											</td>
										) : (
											<td>
												{item.sicCodes}
												<Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
											</td>
										)}
										<td>
											<ConfirmDelete variant="primary" title={`SicCode`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.sicCodesId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={2}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	);
}

const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchSicCode: (token) => dispatch({ type: SICCODE_REQUEST, token }),
		submitSicCodeCreate: (data) => dispatch({ type: SICCODE_CREATE, payload: data }),
		submitSicCodeDelete: (data) => dispatch({ type: SICCODE_DELETE, payload: data }),
		submitSicCodeEdit: (data) => dispatch({ type: SICCODE_EDIT, payload: data }),
		changeReducerState: () => dispatch({ type: SICCODE_STATE_CHANGE })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SICCodes);