import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

const AddDynamicFields = ({
  handleInputChange,
  handleRemoveClick,
  handleAddClick,
  formFieldsList,
  handleDropdownChange,
  SoldBy
}) => {

  return (
    <div className="AddDynamicFields">
      {formFieldsList.map((x, i) => {
        return (
          <div className="box mt-2" key={i}>
            <Row>
              <Col md={3}>
                <Form.Group controlId="subProductCategory">
                  <Form.Label>Product sub structure</Form.Label>
                  <Form.Control 
                    disabled={false} 
                    type="text" 
                    name="subProductCategory" 
                    placeholder="Enter product sub structure"
                    onChange={e => handleInputChange(e, i)} 
                    value={x.subProductCategory} 
                    required={true} 
                  />
                  <Form.Control.Feedback type="invalid">
                    Category is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="isSeamed">
                  <Form.Label>Seamed</Form.Label>
                  <Form.Select 
                    aria-label="Default select example" 
                    value={x.isSeamed} 
                    onChange={e => handleDropdownChange(e, i)} 
                    required={true}
                  >
                    <option value="" disabled selected>Select your option</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Seamed is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="orderedBy">
                  <Form.Label>Ordered By</Form.Label>
                  <Form.Select 
                    aria-label="Default select example" 
                    value={x.orderedBy} 
                    onChange={e => handleDropdownChange(e, i)} 
                    required={true}
                  >
                    <option value="" disabled selected>Select your option</option>
                    {/* <option value="SY">SY</option>
                    <option value="SF">SF</option>
                    <option value="EA">EA</option>
                    <option value="LF">LF</option> */}
                    {SoldBy.map((s, i) => (
                      <option value={s.soldById} key={i}>{s.soldByName}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Ordered By is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="soldBy">
                  <Form.Label>Sold By</Form.Label>
                  <Form.Select 
                    aria-label="Default select example"
                    onChange={e => handleDropdownChange(e, i)} 
                    value={x.soldBy} 
                    required={true}
                  >
                    <option value="" disabled selected>Select your option</option>
                    {/*Commented on 29th November for all dropdown similar functionality*/}
                    {/* <option>Select SoldBy</option> */}
                    {SoldBy.map((s, i) => (
                      <option value={s.soldById} key={i}>{s.soldByName}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Sold By is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <div className="btn-box">
              {formFieldsList.length !== 1 && 
                <Button
                  className="remove-btn mt-2 me-2"
                  onClick={() => handleRemoveClick(i)}
                >
                  Remove
                </Button>
              }
              {formFieldsList.length - 1 === i && 
                <Button
                  className="add-btn mt-2" 
                  onClick={handleAddClick}>
                    Add
                </Button>
              }
            </div>
          </div>
        );
      })}
    </div>
  );
}
 
export default AddDynamicFields;