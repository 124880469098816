import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateContacts";
import ConfirmDelete from "./models/ConfirmDelete";
import Edit from "./models/EditContacts";
import {
  CONTACTS_REQUEST,
  CONTACTS_CREATE,
  CONTACTS_DELETE,
  CONTACTS_EDIT,
  CONTACTS_STATE_CHANGE,

} from "../../../../_utils/constants/ProductCatalog";

import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const Contacts = ({
  auth: { auth, isAuthenticated },
  Contacts: { valueList, isCreated, isDeleted, isEdited, isLoading },

  fetchContacts,
  submitContactsCreate,
  submitContactsDelete,
  submitContactsEdit,
  changeReducerState,

}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [salesContactsName, setContactsName] = useState('');
  const [sortOrder, setSortOrder] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteContactsId, setDeleteContactsId] = useState('');
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState("");
  const [state, setState] = useState({
    "contactName": "",
    "contactType": "",
    "contactDetail": "",
    "contactFor": "",
    "contactForPersonId": "",
  }
  )
  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchContacts(auth[0].token);
    }
  }, [auth, isAuthenticated]);



  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("Contacts Created Successfully.");
      fetchContacts(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("Contacts Deleted Successfully.");
      fetchContacts(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("Contacts Data Updated Successfully.");
      fetchContacts(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value })

  }

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
    setShow(false)
    setIds("");
    setState({
      "contactName": "",
      "contactType": "",
      "contactDetail": "",
      "contactFor": "",
      "contactForPersonId": "",
    })
  };

  // function called for input value change of create modal
  const handleSalesStageNameChange = (e) => {
    setContactsName(e.target.value);
  }
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (state.contactName === '') {
      toast.error("ContactName cannot be empty.");
    } else {
      submitContactsCreate({
        "data": {
          "ContactName": state.contactName,
          "ContactType": state.contactType,
          "ContactDetail": state.contactDetail,
          "ContactFor": state.contactFor,
          "ContactForPersonId": parseInt(state.contactForPersonId),
          "CreatedBy": auth[0].userId,
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteContactsId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteContactsId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitContactsDelete({
      "ContactsId": deleteContactsId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setState(data);
    setIds(data.contactsId);
    setShow(true)
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (state.contactName === '') {
      toast.error("ContactName cannot be empty.");
    } else {

      submitContactsEdit({
        "data": {
          "ContactsId": ids,
          "ContactName": state.contactName,
          "ContactType": state.contactType,
          "ContactDetail": state.contactDetail,
          "ContactFor": state.contactFor,
          "ContactForPersonId": parseInt(state.contactForPersonId),
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>Contacts</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`Contacts`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              salesStageName={salesContactsName}
              handleSalesStageNameChange={handleSalesStageNameChange}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
              handleChange={handleChange}
              state={state}

            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>ContactName</th>
                <th className="text-center">ContactType</th>
                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (

                  <tr key={index}>
                    <td>
                      {item.contactName}
                    </td>
                    <td width="150">
                      {item.contactType}
                    </td>
                    <td className="actions">
                      <div className="btn-group">
                        <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                        <ConfirmDelete variant="primary" title={`Contacts`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.contactsId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                      </div>
                    </td>
                  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
      <Edit
        state={state}
        handleChange={handleChange}
        handleEditSaveClick={handleEditSaveClick}
        show={show}
        hideCreateModal={hideCreateModal}
        title={`Contacts`}
      />
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, Contacts }) => {
  return {
    auth,
    Contacts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {

    fetchContacts: (token) => dispatch({ type: CONTACTS_REQUEST, token }),
    submitContactsCreate: (data) => dispatch({ type: CONTACTS_CREATE, payload: data }),
    submitContactsDelete: (data) => dispatch({ type: CONTACTS_DELETE, payload: data }),
    submitContactsEdit: (data) => dispatch({ type: CONTACTS_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: CONTACTS_STATE_CHANGE }),


  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);