import { put, takeLatest, call, all } from "redux-saga/effects";
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  PERSONAL_USER_REQUEST,
  PERSONAL_USER_SUCCESS,
  PERSONAL_USER_FAILED,
  PERSONAL_USER_DELETE,
  PERSONAL_USER_DELETE_SUCCESS,
  PERSONAL_USER_DELETE_FAILED,
  PERSONAL_USER_CREATE,
  PERSONAL_USER_CREATE_SUCCESS,
  PERSONAL_USER_CREATE_FAILED,
  PERSONAL_USER_EDIT,
  PERSONAL_USER_EDIT_SUCCESS,
  PERSONAL_USER_EDIT_FAILED
} from "../../../_utils/constants/ValueList";


function* fetchPersonalUserItem({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/ValueList/api/PersonnelUser/GetPersonnelUser`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    yield put({ type: PERSONAL_USER_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PERSONAL_USER_FAILED, payload: error.response });
    }
  }
}

function* deletePersonalUserItem(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/ValueList/api/PersonnelUser/RemovePersonnelUser?PersonnelUserId=${encodeURIComponent(action.payload.PersonalUserId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: PERSONAL_USER_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PERSONAL_USER_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}
function* createPersonalUser(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/PersonnelUser/CreatePersonnelUser`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PERSONAL_USER_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PERSONAL_USER_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editPersonalUser(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/ValueList/api/PersonnelUser/AddGeneralInfo`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PERSONAL_USER_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PERSONAL_USER_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
export default function* rootPersonalUserItem() {
  yield all([
    takeLatest(PERSONAL_USER_REQUEST, fetchPersonalUserItem),
    takeLatest(PERSONAL_USER_DELETE, deletePersonalUserItem),
    takeLatest(PERSONAL_USER_CREATE, createPersonalUser),
    takeLatest(PERSONAL_USER_EDIT, editPersonalUser)
  ]);
}