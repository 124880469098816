import React, { Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreatePersonalUser = ({
  title,
  createModalOpen,
  showCreateModal,
  hideCreateModal,
  handleCreateModalSubmit,
  state,
  handleChange
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} >
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="personalName">
              <Form.Label>PersonalName</Form.Label>
              <Form.Control type="text" name="personalName" onChange={handleChange} value={state.personalName} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="password" onChange={handleChange} value={state.password} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" name="title" onChange={handleChange} value={state.title} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" name="email" onChange={handleChange} value={state.email} />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateModalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreatePersonalUser;