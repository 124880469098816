import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import Dashboard from "../components/pages/dashboard/Dashboard";
import Contact from "../components/pages/contacts/Contacts";
import Admin from "../components/pages/admin/Admin";
import AgeDemographic from "../components/pages/admin/valuelist/AgeDemographic";
import AlternateNames from "../components/pages/admin/valuelist/AlternateNames";
import BinLocation from "../components/pages/admin/valuelist/BinLocation";
import Area from "../components/pages/admin/valuelist/Area";
import BuyingGroupSetup from "../components/pages/admin/valuelist/BuyingGroupSetup";
import Cashback from "../components/pages/admin/valuelist/Cashback";
import Competitors from "../components/pages/admin/valuelist/Competitors";
import FormValueLists from "../components/pages/admin/valuelist/FormValueLists";
import FormTemplates from "../components/pages/admin/valuelist/FormTemplates";
import Forms from "../components/pages/admin/valuelist/Forms";
import IDStatus from "../components/pages/admin/valuelist/IDStatus";
import Lost from "../components/pages/admin/valuelist/Lost";
import InvoiceFlags from "../components/pages/admin/valuelist/InvoiceFlags";
import MarketSector from "../components/pages/admin/valuelist/MarketSector";
import Marketing from "../components/pages/admin/valuelist/Marketing";
import NoteTemplates from "../components/pages/admin/valuelist/NoteTemplates";
import ProductInterest from "../components/pages/admin/valuelist/ProductInterest";
import Region from "../components/pages/admin/valuelist/Region";
import SalesStages from "../components/pages/admin/valuelist/SalesStages";
import SICCodes from "../components/pages/admin/valuelist/SICCodes";
import Vendor from "../components/pages/admin/valuelist/Vendor";
import VendorType from "../components/pages/admin/valuelist/VendorType";
import Warehouses from "../components/pages/admin/valuelist/Warehouses";
import ShippingMethods from "../components/pages/admin/valuelist/ShippingMethods";
import Won from "../components/pages/admin/valuelist/Won";
import JobTypes from "../components/pages/admin/valuelist/JobTypes";
import Defaults from "../components/pages/admin/adminlist/Defaults";
import Orders from "../components/pages/orders/Order";
import ProposalsList from "../components/pages/Proposals/proposalsList";
import ProductCatalog from "../components/pages/productcatalog/productCatalog";
import PricingContracts from "../components/pages/pricingcontract/pricingcontract";
import ProductPricing from "../components/pages/Proposals/ProductPricing";
import PersonalUser from "../components/pages/admin/valuelist/PersonalUser";
import ExistingOrders from "../components/pages/existingOrders/ExistingOrders";
import PlaceOrder from "../components/pages/placeOrder/PlaceOrder";

const IndexRoute = () => {
    return (
        <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/signup" exact element={<Register />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route path="/reset-password" exact element={<ResetPassword />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/contact-us" element={<Contact />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/AgeDemographic" element={<AgeDemographic />} />
            <Route exact path="/alternate-names" element={<AlternateNames />} />
            <Route exact path="/bin-location" element={<BinLocation />} />
            <Route exact path="/area" element={<Area />} />
            <Route exact path="/buying-group-setup" element={<BuyingGroupSetup />} />
            <Route exact path="/cashback" element={<Cashback />} />
            <Route exact path="/competitors" element={<Competitors />} />
            <Route exact path="/form-value-lists" element={<FormValueLists />} />
            <Route exact path="/form-templates" element={<FormTemplates />} />
            <Route exact path="/forms" element={<Forms />} />
            <Route exact path="/IDStatus" element={<IDStatus />} />
            <Route exact path="/JobTypes" element={<JobTypes />} />
            <Route exact path="/Lost" element={<Lost />} />
            <Route exact path="/InvoiceFlags" element={<InvoiceFlags />} />
            <Route exact path="/MarketSector" element={<MarketSector />} />
            <Route exact path="/Marketing" element={<Marketing />} />
            <Route exact path="/NoteTemplates" element={<NoteTemplates />} />
            <Route exact path="/ProductInterest" element={<ProductInterest />} />
            <Route exact path="/Region" element={<Region />} />
            <Route exact path="/SalesStages" element={<SalesStages />} />
            <Route exact path="/SICCodes" element={<SICCodes />} />
            <Route exact path="/Vendor" element={<Vendor />} />
            <Route exact path="/veder-type" element={<VendorType />} />
            <Route exact path="/Warehouses" element={<Warehouses />} />
            <Route exact path="/ShippingMethods" element={<ShippingMethods />} />
            <Route exact path="/Won" element={<Won />} />
            <Route exact path="/orders" element={<Orders />} />
            <Route exact path="/defaults" element={<Defaults />} />
            <Route exact path="/ProposalsList" element={<ProposalsList />} />
            <Route exact path="/ProductCatalog" element={<ProductCatalog />} />
            <Route exact path="/PricingContracts" element={<PricingContracts />} />
            <Route exact path="/ProductPricing" element={<ProductPricing />} />
            <Route exact path="/personal-user" element={<PersonalUser />} />
            <Route exact path="/existing-orders" element={<ExistingOrders />} />
            <Route exact path="/place-order" element={<PlaceOrder />} />

        </Routes>
    );
};

export default IndexRoute;