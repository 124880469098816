import React, { useState, useEffect, useMemo } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { connect } from 'react-redux';
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Button, Table, InputGroup, Form } from 'react-bootstrap';
import ConfirmDelete from "./models/ConfirmDelete";
import {
	AREA_REQUEST,
	AREA_CHANGE,
	AREA_CREATE_REQUEST,
	AREA_DELETE_REQUEST,
	AREA_EDIT_REQUEST,

} from "../../../../_utils/constants/ValueList";
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Loader from "../../../layouts/Loader";
import Create from "./models/CreateArea";
import _ from "lodash";
const Area = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isLoading, isCreated, isEdited, isDeleted },
	fetchArea,
	CreateAreaRequest,
	changeReducerState,
	submitAreaDelete,
	submitAreaEdit
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [name, setName] = useState("");
	const [editAreaId, setEditAreaId] = useState("");
	const [editAreaName, setEditAreaName] = useState("");
	const [deleteAreaId, setDeleteAreaId] = useState("");
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const results = useMemo(() => {
		return valueList;
	}, [valueList]);
	useEffect(() => {
		if (isAuthenticated) {
			fetchArea(auth[0].token);
		}
	}, [auth, isAuthenticated]);
	useMemo(() => {
		if (!isAuthenticated) return <Navigate to="/login" />;
	}, [isAuthenticated]);

	const handleModel = () => setIsOpen(!isOpen);
	const hideModel = () => setIsOpen(false);
	// use effect function called after api success
	useMemo(() => {
		if (isCreated === true) {
			toast.success("Area Created Successfully.");
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Area Deleted Successfully.");
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Area Data Updated Successfully.")
			changeReducerState();
		}
		fetchArea(auth[0].token);
	}, [isCreated, isDeleted, isEdited]);
	const handleCreate = (e) => {
		if (!name) {
			toast.error("Area name cannot be empty.");
		} else {

			CreateAreaRequest({
				"data": {
					"Area": name,
					"CreatedBy": auth[0].userId
				},
				"token": auth[0].token
			})
		}

	}
	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteAreaId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteAreaId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete age demographic delete api
	const handleConfirmDeleteSubmit = () => {
		submitAreaDelete({
			"AreaId": deleteAreaId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditAreaId(data.areaId);
		setEditAreaName(data.area);
	}
	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editAreaName === '') {
			toast.error("Area cannot be empty.");
		} else {
			submitAreaEdit({
				"data": {
					"AreaId": data.areaId,
					"Area": editAreaName
				},
				"token": auth[0].token
			});
			setEditAreaId('');
			setEditAreaName('');
		}
	}
	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<div className="sub-header mt-5 mb-3">
						<h2>Area <span>(Type Specific)</span></h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Area`}
							handleModel={handleModel}
							isOpen={isOpen}
							hideModel={hideModel}
							setName={setName}
							name={name}
							handleCreate={handleCreate}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Area</th>
								<th width="50">Delete</th>
							</tr>
						</thead>
						<tbody>
							{!_.isEmpty(results) ? _.map(results, (item, i) => (
								<tr key={i}>
									{editAreaId === item.areaId ? (
										<td>
											<Form.Group>
												<InputGroup>
													<Form.Control type="text" value={editAreaName} onChange={(evt) => setEditAreaName(evt.target.value)} />
													<Button variant="outline-secondary">
														<Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
													</Button>
												</InputGroup>
											</Form.Group>
										</td>
									) : (
										<td>
											{item.area}
											<Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
										</td>
									)}
									<td>
										<ConfirmDelete variant="primary" title={`Area`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.areaId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
									</td>
								</tr>
							)) : (
								<tr>
									<td colSpan={2}>
										No Data Found.
									</td>
								</tr>
							)}


						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	);
}


const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchArea: (token) => dispatch({ type: AREA_REQUEST, token }),
		changeReducerState: () => dispatch({ type: AREA_CHANGE }),
		CreateAreaRequest: (data) => dispatch({ type: AREA_CREATE_REQUEST, payload: data }),
		submitAreaDelete: (data) => dispatch({ type: AREA_DELETE_REQUEST, payload: data }),
		submitAreaEdit: (data) => dispatch({ type: AREA_EDIT_REQUEST, payload: data }),
	};
};
Area.propTypes = {
	auth: PropTypes.object,
	isAuthenticated: PropTypes.bool,
	valueList: PropTypes.object,
	isLoading: PropTypes.bool,

}
export default connect(mapStateToProps, mapDispatchToProps)(Area);