import {
  SALES_PERSON_CATEGORY_REQUEST,
  SALES_PERSON_CATEGORY_SUCCESS,
  SALES_PERSON_CATEGORY_FAILED,
  SALES_PERSON_CATEGORY_CREATE,
  SALES_PERSON_CATEGORY_CREATE_SUCCESS,
  SALES_PERSON_CATEGORY_CREATE_FAILED,
  SALES_PERSON_CATEGORY_DELETE,
  SALES_PERSON_CATEGORY_DELETE_SUCCESS,
  SALES_PERSON_CATEGORY_DELETE_FAILED,
  SALES_PERSON_CATEGORY_EDIT,
  SALES_PERSON_CATEGORY_EDIT_SUCCESS,
  SALES_PERSON_CATEGORY_EDIT_FAILED,
  SALES_PERSON_CATEGORY_STATE_CHANGE,
  SALES_PERSON_COMMISSION_REQUEST,
  SALES_PERSON_COMMISSION_SUCCESS,
  SALES_PERSON_COMMISSION_FAILED,
  SALES_PERSON_REQUEST,
  SALES_PERSON_SUCCESS,
  SALES_PERSON_FAILED
} from "../../../_utils/constants/SalesPerson";


const initialState = {
  salesPersonCategory: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const SalesPersonCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SALES_PERSON_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case SALES_PERSON_CATEGORY_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        salesPersonCategory: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case SALES_PERSON_CATEGORY_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case SALES_PERSON_COMMISSION_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case SALES_PERSON_COMMISSION_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        salesCommission: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case SALES_PERSON_COMMISSION_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case SALES_PERSON_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case SALES_PERSON_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        salesPerson: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case SALES_PERSON_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case SALES_PERSON_CATEGORY_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case SALES_PERSON_CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case SALES_PERSON_CATEGORY_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case SALES_PERSON_CATEGORY_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case SALES_PERSON_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case SALES_PERSON_CATEGORY_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case SALES_PERSON_CATEGORY_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case SALES_PERSON_CATEGORY_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case SALES_PERSON_CATEGORY_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case SALES_PERSON_CATEGORY_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default SalesPersonCategoryReducer;