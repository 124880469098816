import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';

const EditPhone = ({
  title,
  show,
  hideCreateModal,
  handleEditSaveClick,
  handleChange,
  state,
}) => {

  return (
    <Fragment>

      <Modal show={show} size="xl">
        <Modal.Header>Edit {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="phoneType">
                  <Form.Label>PhoneType</Form.Label>
                  <Form.Control type="text" name="phoneType" onChange={handleChange} value={state.phoneType} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="areaCode">
                  <Form.Label>AreaCode</Form.Label>
                  <Form.Control type="text" name="areaCode" onChange={handleChange} value={state.areaCode} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="phoneNumber">
                  <Form.Label>PhoneNumber</Form.Label>
                  <Form.Control type="text" name="phoneNumber" onChange={handleChange} value={state.phoneNumber} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="extension">
                  <Form.Label>Extension</Form.Label>
                  <Form.Control type="text" name="extension" onChange={handleChange} value={state.extension} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="phoneDetailFor">
                  <Form.Label>PhoneDetailFor</Form.Label>
                  <Form.Control type="text" name="phoneDetailFor" onChange={handleChange} value={state.phoneDetailFor} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="contactPersonId">
                  <Form.Label>ContactPersonId</Form.Label>
                  <Form.Control type="number" name="contactPersonId" onChange={handleChange} value={state.contactPersonId} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default EditPhone;