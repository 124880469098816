import {
  PRODUCT_CATALOG_COPY_PRODUCT,
  PRODUCT_CATALOG_COPY_PRODUCT_SUCCESS,
  PRODUCT_CATALOG_COPY_PRODUCT_FAILED,
  PRODUCT_CATALOG_STATE_CHANGE
} from "../../../_utils/constants/ProductCatalog";

const initialState = {
  isCopy: false,
  errors: {},
};


const CopyProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_CATALOG_COPY_PRODUCT:
      return {
        ...state,
        isCopy: false,
        errors: {}
      }
    case PRODUCT_CATALOG_COPY_PRODUCT_SUCCESS:
      return {
        ...state,
        isCopy: true,
        errors: {}
      }
    case PRODUCT_CATALOG_COPY_PRODUCT_FAILED:
      return {
        ...state,
        isCopy: false,
        errors: action.payload
      }
    case PRODUCT_CATALOG_STATE_CHANGE:
      return {
        ...state,
        isCopy: false,

      }
  }
  return state;
}

export default CopyProductReducer;