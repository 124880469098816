import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateProductPrice";
import ConfirmDelete from "./models/ConfirmDelete";
import Edit from "./models/EditProductPrice";
import {
  PRODUCT_PRICE_REQUEST,
  PRODUCT_PRICE_CREATE,
  PRODUCT_PRICE_DELETE,
  PRODUCT_PRICE_EDIT,
  PRODUCT_PRICE_STATE_CHANGE,
  PRODUCT_TYPE_REQUEST
} from "../../../../_utils/constants/ProductCatalog";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const ProductPrice = ({
  auth: { auth, isAuthenticated },
  ProductPrice: { valueList, isCreated, isDeleted, isEdited, isLoading },
  ProductType,
  fetchProductPrice,
  submitProductPriceCreate,
  submitProductPriceDelete,
  submitProductPriceEdit,
  changeReducerState,
  fetchProductType
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [salesProductPriceName, setProductPriceName] = useState('');
  const [sortOrder, setSortOrder] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteProductPriceId, setDeleteProductPriceId] = useState('');
  const [show, setShow] = useState(false);
  const [ids, setIds] = useState("");
  const [state, setState] = useState({
    "productTypeId": "",
    "priceType": ""
  })
  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchProductPrice(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchProductType(auth[0].token);
    }
  }, [auth, isAuthenticated]);
  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("ProductPrice Created Successfully.");
      fetchProductPrice(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("ProductPrice Deleted Successfully.");
      fetchProductPrice(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("ProductPrice Data Updated Successfully.");
      fetchProductPrice(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ ...state, [id]: value })
  }

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
    setShow(false)
    setIds("");
    setState({
      "productTypeId": "",
      "PriceType": "",
    })
  };

  // function called for input value change of create modal
  const handleSalesStageNameChange = (e) => {
    setProductPriceName(e.target.value);
  }
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (state.priceType === '') {
      toast.error("PRODUCT TYPE name cannot be empty.");
    } else {
      submitProductPriceCreate({
        "data": {
          "ProductTypeId": parseInt(state.productTypeId),
          "PriceType": state.priceType,
          "CreatedBy": auth[0].userId
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteProductPriceId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteProductPriceId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitProductPriceDelete({
      "ProductPriceId": deleteProductPriceId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setState(data);
    setIds(data.productPriceId);
    setShow(true)
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (state.priceType === '') {
      toast.error("PRODUCT_PRICE name cannot be empty.");
    } else {
      submitProductPriceEdit({
        "data": {
          "ProductPriceId": ids,
          "ProductTypeId": parseInt(state.productTypeId),
          "PriceType": state.priceType,
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>ProductPrice</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`ProductPrice`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              salesStageName={salesProductPriceName}
              handleSalesStageNameChange={handleSalesStageNameChange}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
              handleChange={handleChange}
              state={state}
              ProductType={ProductType.valueList}

            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>ProductPrice</th>

                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (

                  <tr key={index}>
                    <td>
                      {item.priceType}
                    </td>

                    <td className="actions">
                      <div className="btn-group">
                        <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                        <ConfirmDelete variant="primary" title={`ProductPrice`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.productPriceId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                      </div>
                    </td>
                  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
      <Edit
        state={state}
        handleChange={handleChange}
        handleEditSaveClick={handleEditSaveClick}
        show={show}
        hideCreateModal={hideCreateModal}
        ProductType={ProductType.valueList}
        title={`ProductPrice`}
      />
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, ProductPrice, ProductType }) => {
  return {
    auth,
    ProductPrice,
    ProductType
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductPrice: (token) => dispatch({ type: PRODUCT_PRICE_REQUEST, token }),
    submitProductPriceCreate: (data) => dispatch({ type: PRODUCT_PRICE_CREATE, payload: data }),
    submitProductPriceDelete: (data) => dispatch({ type: PRODUCT_PRICE_DELETE, payload: data }),
    submitProductPriceEdit: (data) => dispatch({ type: PRODUCT_PRICE_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: PRODUCT_PRICE_STATE_CHANGE }),
    fetchProductType: (token) => dispatch({ type: PRODUCT_TYPE_REQUEST, token }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductPrice);