import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import "../../assets/css/Main.css";
// import Icon from "react-icons-kit";
// import { dollar } from "react-icons-kit/fa";
// import { ic_dashboard } from 'react-icons-kit/md/'

import { Navigation } from "react-minimal-side-navigation";
import { useNavigate, useLocation } from "react-router-dom";
import { USER_MENU_LIST_REQUEST } from "../../_utils/constants/admin/Admin";
import Loader from "./Loader";
import _ from "lodash";
import { LOGOUT_REQUEST } from "../../_utils/constants/Auth";

const Sidebar = ({
  auth: { auth, isAuthenticated },
  Admin: { isLoading, userMenuList },
  fetchUserMenuList,
  handleLogout
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Handle user permission api call
  useEffect(() => {
    if (auth[0]?.token && auth[0]?.roleid && auth[0]?.userId) {
      fetchUserMenuList(auth[0]?.token, auth[0]?.roleid, auth[0]?.userId);
    }
    // Function to redirect unauthenticated user to login page
    if (!isAuthenticated) return <Navigate to="/login" />;
  }, [auth, isAuthenticated]);

  // function to handle user menu permissions
  let menuList = useMemo(() => {
    if (userMenuList) {
      let list = userMenuList[0]?.permission;
      let modifiedList = [];
      if (!_.isEmpty(list)) {
        list.map((data) => {
          modifiedList.push({
            title: data?.menuName,
            itemId: data?.menuUrl,
            elemBefore: () => "",
          });
        });
      }
      if (userMenuList[0] === null) {
        handleLogout();
      }
      return modifiedList;
    }
  }, [userMenuList]);

  // previous static aside left menu
  // let menuList = [
  //   {
  //     title: "Dashboard",
  //     itemId: "/dashboard",
  //     // use your own custom Icon component as well
  //     elemBefore: () => "",
  //   },
  //   // {
  //   //   title: "Contacts Us",
  //   //   itemId: "/contact-us",
  //   //   // use your own custom Icon component as well
  //   //   elemBefore: () => <Icon size={24} icon={ic_contacts} />,
  //   // },
  //   // {
  //   //   title: "Proposals",
  //   //   // itemId: "/category",
  //   //   elemBefore: () => <Icon size={24} icon={fileText} />,
  //   //   subNav: [
  //   //     {
  //   //       title: "Proposals List",
  //   //       itemId: "/ProposalsList",
  //   //     },
  //   //     {
  //   //       title: "Labor Catalog",
  //   //       itemId: "/category/React",
  //   //     },
  //   //     // {
  //   //     //   title: "Product Catalog",
  //   //     //   itemId: "/ProductCatalog",
  //   //     // },
  //   //   ],
  //   // },
  //   {
  //     title: "Place an Order",
  //     itemId: "/place-order",
  //     elemBefore: () => "",
  //   },
  //   {
  //     title: "Existing Orders",
  //     itemId: "/existing-orders",
  //     elemBefore: () => "",
  //   },
  //   {
  //     title: "Customer Info",
  //     itemId: "/customer-list",
  //     elemBefore: () => "",
  //   },
  //   {
  //     title: "Pricing Contracts",
  //     itemId: "/PricingContracts",
  //     elemBefore: () => "",
  //   },
  //   {
  //     title: "Product Catalog",
  //     itemId: "/ProductCatalog",
  //     elemBefore: () => "",
  //   },
  //   {
  //     title: "Labor Catalog",
  //     itemId: "/admin/labor/items",
  //     elemBefore: () => "",
  //   },
  //   {
  //     title: "Kits",
  //     itemId: "/kits",
  //     elemBefore: () => "",
  //   },
  //   // {
  //   //   title: "Orders",
  //   //   itemId: "/contact",
  //   //   elemBefore: () => <Icon size={24} icon={dollar} />,
  //   //   subNav: [
  //   //     {
  //   //       title: "About",
  //   //       itemId: "/contact/about",
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   title: "Invoices A/R",
  //   //   itemId: "/home",
  //   //   // use your own custom Icon component as well
  //   //   elemBefore: () => <Icon size={24} icon={dollar} />,
  //   // },
  //   // {
  //   //   title: "Orders",
  //   //   itemId: "/orders",
  //   //   // use your own custom Icon component as well
  //   //   elemBefore: () => <Icon size={24} icon={dollar} />,
  //   // },
  //   {
  //     title: "Admin",
  //     itemId: "/admin",
  //     // use your own custom Icon component as well
  //     elemBefore: () => "",
  //   },
  // ]

  return (
    <div className="asideLeft">
      <Loader loading={isLoading}>
        <div className="company">
          <h2 className="text-center sarina">NancyERP</h2>
        </div>
        {!_.isEmpty(menuList) && (
          <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              navigate(itemId);
            }}
            items={menuList}
          />
        )}
      </Loader>
    </div>
  );
};
// export default Sidebar;
const mapStateToProps = ({ auth, Admin }) => {
  return {
    auth,
    Admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserMenuList: (token, roleId, userId) =>
      dispatch({ type: USER_MENU_LIST_REQUEST, token, roleId, userId }),
    handleLogout: () => dispatch({ type: LOGOUT_REQUEST }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
