import React, { Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Select from "../../../elements/CustomSelect";

const AddEditContractForm = ({
  handleSelectChange,
  handleContactChange,
  contractName,
  salesPersonId,
  salesPersonList
}) => {
  return(
    <Fragment>
      <Row className="my-5">
        <Col md={{span:3, offset:3}}>
          <Form.Group>
            <Form.Label>Contract Name</Form.Label>
            <Form.Control type="input" onChange={handleContactChange} value={contractName} />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Select label={`Salesperson`} value={salesPersonId} handleChange={handleSelectChange} result={salesPersonList} ids="salesPersonId" validate={false} />
        </Col>
      </Row>
    </Fragment>
  )
}

export default AddEditContractForm;