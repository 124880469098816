import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  PRODUCT_INSTALLED_PRICE_REQUEST,
  PRODUCT_INSTALLED_PRICE_SUCCESS,
  PRODUCT_INSTALLED_PRICE_FAILED,
  PRODUCT_INSTALLED_PRICE_CREATE,
  PRODUCT_INSTALLED_PRICE_CREATE_SUCCESS,
  PRODUCT_INSTALLED_PRICE_CREATE_FAILED,
  PRODUCT_INSTALLED_PRICE_DELETE,
  PRODUCT_INSTALLED_PRICE_DELETE_SUCCESS,
  PRODUCT_INSTALLED_PRICE_DELETE_FAILED,
  PRODUCT_INSTALLED_PRICE_EDIT,
  PRODUCT_INSTALLED_PRICE_EDIT_SUCCESS,
  PRODUCT_INSTALLED_PRICE_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * ProductInstalledPrice Actions
 */

function* fetchProductInstalledPrice({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ProductInstalledPrice/GetProductInstalledPrice`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: PRODUCT_INSTALLED_PRICE_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: PRODUCT_INSTALLED_PRICE_FAILED, payload: error.response });
    }
  }
}

function* createProductInstalledPrice(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ProductInstalledPrice/CreateProductInstalledPrice`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_INSTALLED_PRICE_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_INSTALLED_PRICE_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editProductInstalledPrice(action) {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/Product/api/ProductInstalledPrice/UpdateProductInstalledPrice`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: PRODUCT_INSTALLED_PRICE_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_INSTALLED_PRICE_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteProductInstalledPrice(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/ProductInstalledPrice/RemoveProductInstalledPrice?ProductInstalledPriceId=${encodeURIComponent(action.payload.ProductInstalledPriceId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: PRODUCT_INSTALLED_PRICE_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: PRODUCT_INSTALLED_PRICE_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootProductInstalledPriceList() {
  yield all([
    takeLatest(PRODUCT_INSTALLED_PRICE_REQUEST, fetchProductInstalledPrice),
    takeLatest(PRODUCT_INSTALLED_PRICE_CREATE, createProductInstalledPrice),
    takeLatest(PRODUCT_INSTALLED_PRICE_DELETE, deleteProductInstalledPrice),
    takeLatest(PRODUCT_INSTALLED_PRICE_EDIT, editProductInstalledPrice),
  ]);
}