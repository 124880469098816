import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateFormTemplate";
import ConfirmDelete from "./models/ConfirmDelete";
// import { encrypted } from '../../../../_utils/helpers';
import {
	FORMTEMPLATE_REQUEST,
	FORMTEMPLATE_CREATE,
	FORMTEMPLATE_DELETE,
	FORMTEMPLATE_EDIT,
	FORMTEMPLATE_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const FormTemplates = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchFormTemplates,
	submitFormTemplatesCreate,
	submitFormTemplateDelete,
	submitFormTemplateEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [formName, setFormName] = useState('');
	const [templateName, setTemplateName] = useState("");
	const [templateText, setTemplateText] = useState("");
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [deleteTemplateId, setDeleteTemplateId] = useState('');
	const [editTemplateId, setEditTemplateId] = useState('');
	const [editFormName, setEditFormName] = useState('');
	const [editTemplateName, setEditTemplateName] = useState('');
	const [editTemplateText, setEditTemplateText] = useState('');

	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchFormTemplates(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("Form template Created Successfully.");
			fetchFormTemplates(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Form template Deleted Successfully.");
			fetchFormTemplates(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Form template Data Updated Successfully.");
			fetchFormTemplates(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleFormNameChange = (e) => {
		setFormName(e.target.value);

	}
	const handleTemplateNameChange = (e) => {
		setTemplateName(e.target.value);
	}
	const handleTemplateTextChange = (e) => {
		setTemplateText(e.target.value);
	}

	// function called for create form template create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (formName === '') {
			toast.error("Form template name cannot be empty.");
		} else {
			submitFormTemplatesCreate({
				"data": {
					"FormName": formName,
					"FormTemplate": templateName,
					"TemplateText": templateText,
					"CreatedBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteTemplateId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteTemplateId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete form template delete api
	const handleConfirmDeleteSubmit = () => {
		submitFormTemplateDelete({
			"formTemplateId": deleteTemplateId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditTemplateId(data.formTemplateId);
		setEditFormName(data.formName);
		setEditTemplateName(data.formTemplate);
		setEditTemplateText(data.templateText);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editFormName === '') {
			toast.error("Form template name cannot be empty.");
		} else {
			submitFormTemplateEdit({
				"data": {
					"FormTemplateId": data.formTemplateId,
					"FormName": editFormName,
					"FormTemplate": editTemplateName,
					"TemplateText": editTemplateText
				},
				"token": auth[0].token
			});
			setEditTemplateId('');
			setEditFormName('');
			setEditTemplateName('');
			setEditTemplateText('');
		}
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<ToastContainer />
					<div className="sub-header mt-5 mb-3">
						<h2>Form Templates</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Form Template`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							formName={formName}
							handleFormNameChange={handleFormNameChange}
							templateName={templateName}
							handleTemplateNameChange={handleTemplateNameChange}
							templateText={templateText}
							handleTemplateTextChange={handleTemplateTextChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Form Name</th>
								<th>Template Name</th>
								<th>Info</th>
								<th width="50" className="actions">
									<div className="d-flex">
										<span>Edit</span>
										<span>Delete</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									editTemplateId === item.formTemplateId ? (
										<tr key={index}>
											<td width="200">
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editFormName} onChange={(evt) => setEditFormName(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editTemplateName} onChange={(evt) => setEditTemplateName(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editTemplateText} onChange={(evt) => setEditTemplateText(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td className="actions">
												<div className="btn-group">
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
													<ConfirmDelete variant="primary" title={`Form Template`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.formTemplateId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									) : (
										<tr key={index}>
											<td width="200">
												{item.formName}
											</td>
											<td>
												{item.formTemplate}
											</td>
											<td>
												{item.templateText}
											</td>
											<td className="actions">
												<div className="btn-group">
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
													<ConfirmDelete variant="primary" title={`Form Template`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.formTemplateId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									)
								))
							) : (
								<tr>
									<td colSpan={4}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	)
}

const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchFormTemplates: (token) => dispatch({ type: FORMTEMPLATE_REQUEST, token }),
		submitFormTemplatesCreate: (data) => dispatch({ type: FORMTEMPLATE_CREATE, payload: data }),
		submitFormTemplateDelete: (data) => dispatch({ type: FORMTEMPLATE_DELETE, payload: data }),
		submitFormTemplateEdit: (data) => dispatch({ type: FORMTEMPLATE_EDIT, payload: data }),
		changeReducerState: () => dispatch({ type: FORMTEMPLATE_STATE_CHANGE })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(FormTemplates);