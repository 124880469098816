import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, Button, InputGroup } from 'react-bootstrap';
// import { encrypted } from '../../../../_utils/helpers';
import { connect } from 'react-redux';
import Create from "./models/Create";
import ConfirmDelete from "./models/ConfirmDelete";
import {
	WON_REQUEST,
	WON_CREATE,
	WON_DELETE,
	WON_EDIT,
	WON_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { toast } from 'react-toastify';

const Won = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchWon,
	submitWonCreate,
	submitWonDelete,
	submitWonEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [inputVal, setInputVal] = useState('');
	const [deleteWonId, setDeleteWonId] = useState('');
	const [editWonId, setEditWonId] = useState('');
	const [editWonName, setEditWonName] = useState('');

	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchWon(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("Won Created Successfully.");
			fetchWon(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Won Deleted Successfully.");
			fetchWon(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Won Data Updated Successfully.");
			fetchWon(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleCreateModalChange = (e) => {
		setInputVal(e.target.value);
	}

	// function called for create age demographic create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (inputVal === '') {
			toast.error("Won name cannot be empty.");
		} else {
			submitWonCreate({
				"data": {
					"ReasonWon": inputVal,
					"CreatedBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteWonId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteWonId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete age demographic delete api
	const handleConfirmDeleteSubmit = () => {
		submitWonDelete({
			"ReasonWonId": deleteWonId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditWonId(data.reasonWonId);
		setEditWonName(data.reasonWon);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editWonName === '') {
			toast.error("Won name cannot be empty.");
		} else {
			submitWonEdit({
				"data": {
					"ReasonWonId": data.reasonWonId,
					"ReasonWon": editWonName,
				},
				"token": auth[0].token
			});
			setEditWonId('');
			setEditWonName('');
		}
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">

					<div className="sub-header mt-5 mb-3">
						<h2>Won</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Won`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							inputVal={inputVal}
							handleCreateModalChange={handleCreateModalChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Won</th>
								<th width="50">Delete</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									<tr key={index}>
										{editWonId === item.reasonWonId ? (
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editWonName} onChange={(evt) => setEditWonName(evt.target.value)} />
														<Button variant="outline-secondary">
															<Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
														</Button>
													</InputGroup>
												</Form.Group>
											</td>
										) : (
											<td>
												{item.reasonWon}
												<Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
											</td>
										)}
										<td>
											<ConfirmDelete variant="primary" title={`Won`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.reasonWonId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={2}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	);
}

const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchWon: (token) => dispatch({ type: WON_REQUEST, token }),
		submitWonCreate: (data) => dispatch({ type: WON_CREATE, payload: data }),
		submitWonDelete: (data) => dispatch({ type: WON_DELETE, payload: data }),
		submitWonEdit: (data) => dispatch({ type: WON_EDIT, payload: data }),
		changeReducerState: () => dispatch({ type: WON_STATE_CHANGE })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Won);