import React, { memo } from "react";
import { Form } from 'react-bootstrap';
const CustomForm = ({ children, handleSubmit, validated }) => {
  return (

    <Form onSubmit={handleSubmit} noValidate validated={validated}>
      {children}
    </Form>

  )
}


export default memo(CustomForm);