import { ENCRYPT_REQUEST, ENCRYPT_SUCCESS, ENCRYPT_FAILED } from '../../_utils/constants/Encrypt';



const initialState = {
  result: "",
  isLoading: false,
  isEncrypted:false,
  errors: {},
}

const encryptReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ENCRYPT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isEncrypted:false,
        errors: {},
      }
    case ENCRYPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEncrypted:true,
        result: payload
      }
    case ENCRYPT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEncrypted:false,
        errors: payload,
      }
    default:
      return state;
  }

}

export default encryptReducer;