import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from 'react-bootstrap';

const EditProductPrice = ({
  title,
  show,
  hideCreateModal,
  handleEditSaveClick,
  handleChange,
  state,
  ProductType
}) => {

  return (
    <Fragment>

      <Modal show={show} size="sm">
        <Modal.Header>Edit {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="productTypeId">
                  <Form.Label>ProductType</Form.Label>
                  <Form.Select aria-label="productTypeId" name="productTypeId" onChange={handleChange} value={state.productTypeId}>
                    <option>Select ProductType</option>
                    {ProductType.map((u, i) => (
                      <option value={u.productTypeId} key={i}>{u.productType}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="priceType">
                  <Form.Label>Enter New {title}</Form.Label>
                  <Form.Control type="text" name="priceType" onChange={handleChange} value={state.priceType} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSaveClick}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default EditProductPrice;