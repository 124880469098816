import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
	CUSTOMER_LIST_REQUEST,
	CUSTOMER_LIST_SUCCESS,
	CUSTOMER_LIST_FAILED,
	CUSTOMER_UPDATE_REQUEST,
	CUSTOMER_UPDATE_SUCCESS,
	CUSTOMER_UPDATE_FAILED,
	CUSTOMER_IMPORT_REQUEST,
	CUSTOMER_IMPORT_SUCCESS,
	CUSTOMER_IMPORT_FAILED,
	CUSTOMER_IMPORT_UNIT_MIX_SAVE_REQUEST,
	CUSTOMER_IMPORT_UNIT_MIX_SAVE_SUCCESS,
	CUSTOMER_IMPORT_UNIT_MIX_SAVE_FAILED,
	CUSTOMER_IMPORT_IMAGE_REQUEST,
	CUSTOMER_IMPORT_IMAGE_SUCCESS,
	CUSTOMER_IMPORT_IMAGE_FAILED,
	CUSTOMER_GET_IMAGE_REQUEST,
	CUSTOMER_GET_IMAGE_SUCCESS,
	CUSTOMER_GET_IMAGE_FAILED,
	CUSTOMER_CONTRACT_ADDED_LIST_REQUEST,
	CUSTOMER_CONTRACT_ADDED_LIST_SUCCESS,
	CUSTOMER_CONTRACT_ADDED_LIST_FAILED,
	CUSTOMER_UNIT_DETAIL_REQUEST,
	CUSTOMER_UNIT_DETAIL_SUCCESS,
	CUSTOMER_UNIT_DETAIL_FAILED,
	CUSTOMER_ORDER_DETAIL_REQUEST,
	CUSTOMER_ORDER_DETAIL_SUCCESS,
	CUSTOMER_ORDER_DETAIL_FAILED,
	SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST,
	SAVE_CUSTOMER_CONTRACT_DETAILS_SUCCESS,
	SAVE_CUSTOMER_CONTRACT_DETAILS_FAILED,
	CUSTOMER_EXISTING_ORDER_LIST_REQUEST,
	CUSTOMER_EXISTING_ORDER_LIST_SUCCESS,
	CUSTOMER_EXISTING_ORDER_LIST_FAILED,
	CUSTOMER_UNIT_MIX_LIST_REQUEST,
	CUSTOMER_UNIT_MIX_LIST_SUCCESS,
	CUSTOMER_UNIT_MIX_LIST_FAILED,
	CUSTOMER_UPDATE_UNIT_MIX_REQUEST,
	CUSTOMER_UPDATE_UNIT_MIX_SUCCESS,
	CUSTOMER_UPDATE_UNIT_MIX_FAILED,
	CUSTOMER_ORDER_UNIT_CHANGE_REQUEST,
	CUSTOMER_ORDER_UNIT_CHANGE_SUCCESS,
	CUSTOMER_ORDER_UNIT_CHANGE_FAILED,
	CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST,
	CUSTOMER_ORDER_UNIT_TYPE_CHANGE_SUCCESS,
	CUSTOMER_ORDER_UNIT_TYPE_CHANGE_FAILED,
	CUSTOMER_DELETE_UNIT_TYPE_REQUEST,
	CUSTOMER_DELETE_UNIT_TYPE_SUCCESS,
	CUSTOMER_DELETE_UNIT_TYPE_FAILED,
	SAVE_SELECTED_EXISTING_ORDER_REQUEST,
	SAVE_SELECTED_EXISTING_ORDER_SUCCESS,
	SAVE_SELECTED_EXISTING_ORDER_FAILED,
	CUSTOMER_ORDER_RESCHEDULE_REQUEST,
	CUSTOMER_ORDER_RESCHEDULE_SUCCESS,
	CUSTOMER_ORDER_RESCHEDULE_FAILED,
	CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST,
	CUSTOMER_ORDER_RESCHEDULE_DATE_SUCCESS,
	CUSTOMER_ORDER_RESCHEDULE_DATE_FAILED,
	CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST,
	CUSTOMER_ORDER_ADD_ON_DETAILS_SUCCESS,
	CUSTOMER_ORDER_ADD_ON_DETAILS_FAILED,
	CUSTOMER_ORDER_ADDON_SAVE_REQUEST,
	CUSTOMER_ORDER_ADDON_SAVE_SUCCESS,
	CUSTOMER_ORDER_ADDON_SAVE_FAILED,
	GET_SHORTAGE_REASON_REQUEST,
	GET_SHORTAGE_REASON_SUCCESS,
	GET_SHORTAGE_REASON_FAILED,
	SAVE_ORDER_SHORTAGE_DETAIL_REQUEST,
	SAVE_ORDER_SHORTAGE_DETAIL_SUCCESS,
	SAVE_ORDER_SHORTAGE_DETAIL_FAILED ,
	UPDATE_SHORTAGE_REASON_REQUEST,
	UPDATE_SHORTAGE_REASON_SUCCESS,
	UPDATE_SHORTAGE_REASON_FAILED
} from "../../../_utils/constants/Customer";

function* fetchCustomerList({ token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked }) {
	try {
		const response = yield call(axios.get, `${API_URL}/User/api/CustomerInfo/GetCustomerInfo?PageIndex=${page}&PageSize=${paginate}&userId=${userId}${listParamState.salesPerson ? '&salesPerson='+listParamState.salesPerson : ''}&sort=${sort}${listParamState.sortOn ? '&sortOn=' + listParamState.sortOn : ""}${listParamState.contactInfoId ? '&contactInfoId=' + listParamState.contactInfoId : ""}${listParamState.shipToPropertyName ? '&shipToPropertyName=' + listParamState.shipToPropertyName : ""}${listParamState.shipToManagementCompany ? '&shipToManagementCompany=' + listParamState.shipToManagementCompany : ""}${listParamState.shipToAddress ? '&shipToAddress=' + listParamState.shipToAddress : ""}${listParamState.shipToState ? '&shipToState=' + listParamState.shipToState : ""}${listParamState.shipToCity ? '&shipToCity=' + listParamState.shipToCity : ""}${listParamState.StoreId ? '&StoreId=' + listParamState.StoreId : ""}${salesPersonId ? '&SalesPersonId=' + salesPersonId : ""}${GrpPcId ? '&groupPricingContractId=' + GrpPcId : ""}${isChecked ? '&propertyFilter=' + isChecked : ""}`, { headers: { Authorization: `Bearer ${token}` } });
		yield put({ type: CUSTOMER_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_LIST_FAILED, payload: error.response });
		}
	}
}

function* updateCustomerDetails(action) {
	try {
		const response = yield call(
			axios.put,
			`${API_URL}/User/api/CustomerInfo/UpdateCustomerInfo`,
			action.data, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		  }
		  );
		yield put({ type: CUSTOMER_UPDATE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_UPDATE_FAILED, payload: error.response });
		}
	}
}

function* submitImportCustomerRequest(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/User/api/User/ImportCustomerUnitMix`,
			action.data.data, {
			headers: {
			  Authorization: `Bearer ${action.data.token}`,
			  'Content-Type': 'multipart/form-data'
			},
		  }
		  );
		yield put({ type: CUSTOMER_IMPORT_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_IMPORT_FAILED, payload: error.response });
		}
	}
}

function* saveImportCustomerUnitMixRequest(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/User/api/User/SaveImportCustomerUnitMix?contactInfoId=${action.contactInfoId}`,
			action.payload, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		  }
		  );
		yield put({ type: CUSTOMER_IMPORT_UNIT_MIX_SAVE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_IMPORT_UNIT_MIX_SAVE_FAILED, payload: error.response });
		}
	}
}

function* submitImportImageCustomerRequest(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/User/api/User/UploadUnitMixImage?contactInfoId=${action.contactInfoId}&UnitTypeId=${action.unitTypeId}`,
			action.data.data, {
			headers: {
			  Authorization: `Bearer ${action.data.token}`
			},
		  }
		  );
		yield put({ type: CUSTOMER_IMPORT_IMAGE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_IMPORT_IMAGE_FAILED, payload: error.response });
		}
	}
}

function* fetchUploadedImageRequest(action) {
	try {
		const response = yield call(
			axios.get,
			`${API_URL}/User/api/User/GetUnitMixDataByContactInfo?PageIndex=${action.page}&PageSize=${action.paginate}&sort=${action.sort}&ContactInfoId=${action.contactInfoId}&UserId=${action?.userId}`,
			{
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		  }
		  );
		yield put({ type: CUSTOMER_GET_IMAGE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_GET_IMAGE_FAILED, payload: error.response });
		}
	}
}

function* fetchCustomerContractAddedList({ token, sort, page, paginate, listParamState, userId, salesPersonId }) {
	try {
		const response = yield call(axios.get, `${API_URL}/User/api/CustomerInfo/GetCustomerInfo?PageIndex=${page}&PageSize=${paginate}&userId=${userId}&sort=${sort}${listParamState.contactInfoId ? '&contactInfoId=' + listParamState.contactInfoId : ""}${salesPersonId ? '&SalesPersonId=' + salesPersonId : ""}`, { headers: { Authorization: `Bearer ${token}` } });
		yield put({ type: CUSTOMER_CONTRACT_ADDED_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_CONTRACT_ADDED_LIST_FAILED, payload: error.response });
		}
	}
}

function* fetchOrderInstallationDetail({ token, jobNum, checkOrder }) {
	try {
		let tempCheckOrder = checkOrder ? 'OrderSelection' : 'OrderConfirmation'
		const response = yield call(axios.get, `${API_URL}/Ordering/api/Order/GetOrderInstallationDetailByJobNo?JobNumber=${jobNum}&request=${tempCheckOrder}`, { headers: { Authorization: `Bearer ${token}` } });
		yield put({ type: CUSTOMER_UNIT_DETAIL_SUCCESS, payload: response.data });
	} catch (error) {
		if (error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_UNIT_DETAIL_FAILED, payload: error.response });
		}
	}
}

function* saveCustomerOrderDetails(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/Ordering/api/Order/SaveInstallationDetail`,
			action.data, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		  }
		  );
		yield put({ type: CUSTOMER_ORDER_DETAIL_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_ORDER_DETAIL_FAILED, payload: error.response });
		}
	}
}

function* saveOrderDetailsForPricingContract(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/Ordering/api/Order/SaveOrder`,
			action.data, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		  }
		  );
		yield put({ type: SAVE_CUSTOMER_CONTRACT_DETAILS_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: SAVE_CUSTOMER_CONTRACT_DETAILS_FAILED, payload: error.response });
		}
	}
}

function* fetchExistingOrdersList({ token, sort, page, paginate, listParamState }) {
	try {
		const response = yield call(axios.get, `${API_URL}/Ordering/api/Order/GetAllOrderInstallationDetail?PageIndex=${page}&PageSize=${paginate}&sort=${sort}${listParamState.sortOn ? '&sortOn=' + listParamState.sortOn : ""}${listParamState.jobNumber ? '&JobNo=' + listParamState.jobNumber : ""}${listParamState.shipToPropertyName ? '&ShipToPropertyName=' + listParamState.shipToPropertyName : ""}${listParamState.StoreId ? '&Store=' + listParamState.StoreId : ""}${listParamState.unitName ? '&UnitName=' + listParamState.unitName : ""}${listParamState.salesPerson ? '&salesPerson='+listParamState.salesPerson : ''}${listParamState.installationDate ? '&OrderInstallationDate=' + listParamState.installationDate : ""}`, { headers: { Authorization: `Bearer ${token}` } });
		// const response = yield call(axios.get, `${API_URL}/Ordering/api/Order/GetAllOrderInstallationDetail?PageIndex=${page}&PageSize=${paginate}&sort=${sort}${listParamState.sortOn ? '&sortOn=' + listParamState.sortOn : ""}${listParamState.jobNumber ? '&JobNo=' + listParamState.jobNumber : ""}${listParamState.shipToPropertyName ? '&ShipToPropertyName=' + listParamState.shipToPropertyName : ""}${listParamState.StoreId ? '&Store=' + listParamState.StoreId : ""}${listParamState.unitName ? '&UnitName=' + listParamState.unitName : ""}${listParamState.salesPerson ? '&salesPerson='+listParamState.salesPerson : ''}${listParamState.installationDate ? '&OrderInstallationDate=' + listParamState.installationDate : ""}${listParamState.orderedDate ? '&OrderDate=' + listParamState.orderedDate : ""}`, { headers: { Authorization: `Bearer ${token}` } });
		yield put({ type: CUSTOMER_EXISTING_ORDER_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_EXISTING_ORDER_LIST_FAILED, payload: error.response });
		}
	}
}

function* fetchUnitMixListRequest({ token, payload }) {
	try {
		const response = yield call(axios.get, `${API_URL}/User/api/User/getunitnumberslist?unittype_id=${payload.unitTypeId}&customerinfoid=${payload.contactInfoId}`, { headers: { Authorization: `Bearer ${token}` } });
		yield put({ type: CUSTOMER_UNIT_MIX_LIST_SUCCESS, payload: response.data });
	} catch (error) {
		if (error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_UNIT_MIX_LIST_FAILED, payload: error.response });
		}
	}
}

function* updateUnitMixListRequest(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/User/api/User/updateunitnumberslist`,
			action.payload, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		  }
		  );
		yield put({ type: CUSTOMER_UPDATE_UNIT_MIX_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_UPDATE_UNIT_MIX_FAILED, payload: error.response });
		}
	}
}

function* saveCustomerOrderUnit(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/Ordering/api/Order/UpdateUnit`,
			action.data, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		});
		yield put({ type: CUSTOMER_ORDER_UNIT_CHANGE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_ORDER_UNIT_CHANGE_FAILED, payload: error.response });
		}
	}
}

function* saveCustomerOrderUnitTypeId(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/Ordering/api/Order/UpdateUnitType`,
			action.data, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		});
		yield put({ type: CUSTOMER_ORDER_UNIT_TYPE_CHANGE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_ORDER_UNIT_TYPE_CHANGE_FAILED, payload: error.response });
		}
	}
}

function* deleteUnitTypeRequest(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/User/api/User/DeleteFloorPlan`,
			action.payload, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		});
		yield put({ type: CUSTOMER_DELETE_UNIT_TYPE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_DELETE_UNIT_TYPE_FAILED, payload: error.response });
		}
	}
}

function* saveExistingOrdersList({token, payload}) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/Ordering/api/Order/SendBulkJobToD365BC`,
			payload, {
			headers: {
			  Authorization: `Bearer ${token}`
			},
		});
		yield put({ type: SAVE_SELECTED_EXISTING_ORDER_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: SAVE_SELECTED_EXISTING_ORDER_FAILED, payload: error.response });
		}
	}
}

function* saveCustomerOrderRescheduleData(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/Ordering/api/Order/RescheduleOrderDate`,
			action.data, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		});
		yield put({ type: CUSTOMER_ORDER_RESCHEDULE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_ORDER_RESCHEDULE_FAILED, payload: error.response });
		}
	}
}

function* fetchCustomerOrderRescheduledData(action) {
	try {
		const response = yield call(
			axios.get,
			`${API_URL}/Ordering/api/Order/GetRescheduleDetail?OrderInstallationDetailId=${action?.id}`,
			{
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		  }
		  );
		yield put({ type: CUSTOMER_ORDER_RESCHEDULE_DATE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_ORDER_RESCHEDULE_DATE_FAILED, payload: error.response });
		}
	}
}

function* fetchCustomerOrderAddOnDetails(action) {
	try {
		const response = yield call(
			axios.get,
			`${API_URL}/Ordering/api/Order/GetAddOnDetailsByJobNo?JobNumber=${action?.id}`,
			{
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		  }
		  );
		yield put({ type: CUSTOMER_ORDER_ADD_ON_DETAILS_SUCCESS, payload: response.data });
	} catch (error) {
		if (error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_ORDER_ADD_ON_DETAILS_FAILED, payload: error.response });
		}
	}
}

function* saveCustomerOrderAddOnDetails(action) {
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/Ordering/api/Order/SaveAddOnDetails`,
			action.data, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		});
		yield put({ type: CUSTOMER_ORDER_ADDON_SAVE_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: CUSTOMER_ORDER_ADDON_SAVE_FAILED, payload: error.response });
		}
	}
}

function* getShortageReasonRequest(action){
	try {
		const response = yield call(
			axios.get,
			`${API_URL}/Ordering/api/Order/GetShortageReason`,
			{
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		  }
		  );
		yield put({ type: GET_SHORTAGE_REASON_SUCCESS, payload: response.data });
	} catch (error) {
		if (error?.response?.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: GET_SHORTAGE_REASON_FAILED, payload: error.response });
		}
	}
}

function* saveOrderShortageRequest(action){
	try {
		const response = yield call(
			axios.post,
			`${API_URL}/Ordering/api/Order/SaveOrderShortageDetail`,
			action.data, {
				headers: {
				  Authorization: `Bearer ${action.token}`
				},
			}
		  );
		yield put({ type: SAVE_ORDER_SHORTAGE_DETAIL_SUCCESS, payload: response.data });
	} catch (error) {
			if (error && error.response && error.response.status && error.response.status === 401) {
				yield put({ type: SESSION_EXPIRED });
			} else {
				yield put({ type: SAVE_ORDER_SHORTAGE_DETAIL_FAILED, payload: error.response });
			}
		
	}
}

function* updateShortageReasonRequest(action) {
	try {
		const response = yield call(
			axios.put,
			`${API_URL}/Ordering/api/Order/UpdateShortageReason`,
			action.data, {
			headers: {
			  Authorization: `Bearer ${action.token}`
			},
		});
		yield put({ type: UPDATE_SHORTAGE_REASON_SUCCESS, payload: response.data });
	} catch (error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			yield put({ type: SESSION_EXPIRED });
		} else {
			yield put({ type: UPDATE_SHORTAGE_REASON_FAILED, payload: error.response });
		}
	}
}

export default function* rootCustomerInfo() {
	yield all([
		takeLatest(CUSTOMER_LIST_REQUEST, fetchCustomerList),
		takeLatest(CUSTOMER_UPDATE_REQUEST, updateCustomerDetails),
		takeLatest(CUSTOMER_IMPORT_REQUEST, submitImportCustomerRequest),
		takeLatest(CUSTOMER_IMPORT_UNIT_MIX_SAVE_REQUEST, saveImportCustomerUnitMixRequest),
		takeLatest(CUSTOMER_IMPORT_IMAGE_REQUEST, submitImportImageCustomerRequest),
		takeLatest(CUSTOMER_GET_IMAGE_REQUEST, fetchUploadedImageRequest),
		takeLatest(CUSTOMER_CONTRACT_ADDED_LIST_REQUEST, fetchCustomerContractAddedList),
		takeLatest(CUSTOMER_UNIT_DETAIL_REQUEST, fetchOrderInstallationDetail),
		takeLatest(CUSTOMER_ORDER_DETAIL_REQUEST, saveCustomerOrderDetails),
		takeLatest(SAVE_CUSTOMER_CONTRACT_DETAILS_REQUEST, saveOrderDetailsForPricingContract),
		takeLatest(CUSTOMER_EXISTING_ORDER_LIST_REQUEST, fetchExistingOrdersList),
		takeLatest(CUSTOMER_UNIT_MIX_LIST_REQUEST, fetchUnitMixListRequest),
		takeLatest(CUSTOMER_UPDATE_UNIT_MIX_REQUEST, updateUnitMixListRequest),
		takeLatest(CUSTOMER_ORDER_UNIT_CHANGE_REQUEST, saveCustomerOrderUnit),
		takeLatest(CUSTOMER_ORDER_UNIT_TYPE_CHANGE_REQUEST, saveCustomerOrderUnitTypeId),
		takeLatest(CUSTOMER_DELETE_UNIT_TYPE_REQUEST, deleteUnitTypeRequest),
		takeLatest(SAVE_SELECTED_EXISTING_ORDER_REQUEST, saveExistingOrdersList),
		takeLatest(CUSTOMER_ORDER_RESCHEDULE_REQUEST, saveCustomerOrderRescheduleData),
		takeLatest(CUSTOMER_ORDER_RESCHEDULE_DATE_REQUEST, fetchCustomerOrderRescheduledData),
		takeLatest(CUSTOMER_ORDER_ADD_ON_DETAILS_REQUEST, fetchCustomerOrderAddOnDetails),
		takeLatest(CUSTOMER_ORDER_ADDON_SAVE_REQUEST, saveCustomerOrderAddOnDetails),
		takeLatest(GET_SHORTAGE_REASON_REQUEST, getShortageReasonRequest),
		takeLatest(SAVE_ORDER_SHORTAGE_DETAIL_REQUEST, saveOrderShortageRequest),
		takeLatest(UPDATE_SHORTAGE_REASON_REQUEST, updateShortageReasonRequest),
		
	])
}