import React from "react";
import { Modal } from 'react-bootstrap';

const ModelBody = ({ children }) => {
  return (
    <Modal.Body>
      {children}
    </Modal.Body>
  )
}


export default ModelBody;