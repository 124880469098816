import React, { Fragment } from "react";
import Model from "../../../elements/CustomModel";
import { Modal } from "react-bootstrap";
import ModelBody from "../../../elements/ModelBody";
import ModelFooter from "../../../elements/ModelFooter";
import Form from "../../../elements/CustomForm";
import Input from "../../../elements/CustomInput";
import Button from "../../../elements/CustomButton";
import Select from "../../../elements/CustomSelect";
import "../../../../App.css"
const AddEdit = ({
  isOpen,
  size,
  hideModel,
  handleSubmit,
  validated,
  handleChange,
  productStructure,
  state,
  employeeId,
  isLoading,
}) => {

  return (
    <Fragment>
      <Model isOpen={isOpen} size={size}>
        <Modal.Header closeButton onClick={hideModel}>
          <Modal.Title>{employeeId ? 'Edit Employee Role' : "Add Employee Role"}</Modal.Title>
        </Modal.Header>
        <Form handleSubmit={handleSubmit} validated={validated}>
          <ModelBody>
            <Input label={"First Name"} ids={'firstName'} handleChange={handleChange} value={state.firstName} validate={true} />
            <Input label={"Last Name"} ids={'lastName'} handleChange={handleChange} value={state.lastName} validate={true} />
            {employeeId ?
              <Input disabled label={"Email"} ids={'email'} handleChange={handleChange} value={state.email} validate={true} />
              :
              <Input label={"Email"} ids={'email'} handleChange={handleChange} value={state.email} validate={true} />}

            <Select label={`Select Role`} handleChange={handleChange} value={state.empRole} result={productStructure} ids={"empRole"} />
          </ModelBody>

          <ModelFooter>
            <Button handleClick={hideModel} variant="secondary" type="button">
              Close
            </Button>
            <Button variant="primary" type="submit">
              {isLoading ? (<div className="spinner-border"></div>) : "Save"}
            </Button>
          </ModelFooter>
        </Form>
      </Model>
    </Fragment>
  )
}
export default AddEdit;