export const LABOR_CATEGORY_REQUEST = "@LABOR_CATEGORY_REQUEST";
export const LABOR_CATEGORY_SUCCESS = "@LABOR_CATEGORY_SUCCESS";
export const LABOR_CATEGORY_FAILED = "@LABOR_CATEGORY_FAILED";

export const LABOR_CATEGORY_CREATE = "@LABOR_CATEGORY_CREATE";
export const LABOR_CATEGORY_CREATE_SUCCESS = "@LABOR_CATEGORY_CREATE_SUCCESS";
export const LABOR_CATEGORY_CREATE_FAILED = "@LABOR_CATEGORY_CREATE_FAILED";

export const LABOR_CATEGORY_DELETE = "@LABOR_CATEGORY_DELETE";
export const LABOR_CATEGORY_DELETE_SUCCESS = "@LABOR_CATEGORY_DELETE_SUCCESS";
export const LABOR_CATEGORY_DELETE_FAILED = "@LABOR_CATEGORY_DELETE_FAILED";

export const LABOR_CATEGORY_EDIT = "@LABOR_CATEGORY_EDIT";
export const LABOR_CATEGORY_EDIT_SUCCESS = "@LABOR_CATEGORY_EDIT_SUCCESS";
export const LABOR_CATEGORY_EDIT_FAILED = "@LABOR_CATEGORY_EDIT_FAILED";

export const LABOR_CATEGORY_STATE_CHANGE = "@LABOR_CATEGORY_STATE_CHANGE";


export const LABOR_ITEM_REQUEST = "@LABOR_ITEM_REQUEST";
export const LABOR_ITEM_SUCCESS = "@LABOR_ITEM_SUCCESS";
export const LABOR_ITEM_FAILED = "@LABOR_ITEM_FAILED";

export const LABOR_ITEM_CREATE = "@LABOR_ITEM_CREATE";
export const LABOR_ITEM_CREATE_SUCCESS = "@LABOR_ITEM_CREATE_SUCCESS";
export const LABOR_ITEM_CREATE_FAILED = "@LABOR_ITEM_CREATE_FAILED";

export const LABOR_ITEM_DELETE = "@LABOR_ITEM_DELETE";
export const LABOR_ITEM_DELETE_SUCCESS = "@LABOR_ITEM_DELETE_SUCCESS";
export const LABOR_ITEM_DELETE_FAILED = "@LABOR_ITEM_DELETE_FAILED";

export const LABOR_ITEM_EDIT = "@LABOR_ITEM_EDIT";
export const LABOR_ITEM_EDIT_SUCCESS = "@LABOR_ITEM_EDIT_SUCCESS";
export const LABOR_ITEM_EDIT_FAILED = "@LABOR_ITEM_EDIT_FAILED";

export const LABOR_ITEM_STATE_CHANGE = "@LABOR_ITEM_STATE_CHANGE";

export const LABOR_ITEM_UPDATE = "@LABOR_ITEM_UPDATE";
export const LABOR_ITEM_UPDATE_SUCCESS = "@LABOR_ITEM_UPDATE_SUCCESS";
export const LABOR_ITEM_UPDATE_FAILED = "@LABOR_ITEM_UPDATE_FAILED";

export const LABOR_ITEM_FILTER_REQUEST = "@LABOR_ITEM_FILTER_REQUEST";
export const LABOR_ITEM_FILTER_SUCCESS = "@LABOR_ITEM_FILTER_SUCCESS";
export const LABOR_ITEM_FILTER_FAILED = "@LABOR_ITEM_FILTER_FAILED";