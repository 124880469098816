import React, { Fragment, memo } from "react";
import { Modal, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
const CreateArea = ({ title, isOpen, handleModel, hideModel,
  name, setName, handleCreate
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={handleModel}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={isOpen}>
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicInputVal">
              <Form.Label>Enter New {title}</Form.Label>
              <Form.Control type="text" name="inputVal" onChange={(e) => setName(e.target.value)} value={name} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModel}>
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleCreate}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
CreateArea.PropTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired
}
export default memo(CreateArea);