export const EMPLOYEE_LIST_REQUEST = "@EMPLOYEE_LIST_REQUEST";
export const EMPLOYEE_LIST_SUCCESS = "@EMPLOYEE_LIST_SUCCESS";
export const EMPLOYEE_LIST_FAILED = "@EMPLOYEE_LIST_FAILED";

export const EMPLOYEE_CREATE_REQUEST = "@EMPLOYEE_CREATE_REQUEST";
export const EMPLOYEE_CREATE_SUCCESS = "@EMPLOYEE_CREATE_SUCCESS";
export const EMPLOYEE_CREATE_FAILED = "@EMPLOYEE_CREATE_FAILED";

export const EMPLOYEE_EDIT_REQUEST = "@EMPLOYEE_EDIT_REQUEST";
export const EMPLOYEE_EDIT_SUCCESS = "@EMPLOYEE_EDIT_SUCCESS";
export const EMPLOYEE_EDIT_FAILED = "@EMPLOYEE_EDIT_FAILED";

export const EMPLOYEE_DELETE_REQUEST = "@EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "@EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_FAILED = "@EMPLOYEE_DELETE_FAILED";

export const EMPLOYEE_PASSWORD_REQUEST = "@EMPLOYEE_PASSWORD_REQUEST";
export const EMPLOYEE_PASSWORD_SUCCESS = "@EMPLOYEE_PASSWORD_SUCCESS";
export const EMPLOYEE_PASSWORD_FAILED = "@EMPLOYEE_PASSWORD_FAILED";

export const EMPLOYEE_DROPDOWN_REQUEST = "@EMPLOYEE_DROPDOWN_REQUEST";
export const EMPLOYEE_DROPDOWN_SUCCESS = "@EMPLOYEE_DROPDOWN_SUCCESS";
export const EMPLOYEE_DROPDOWN_FAILED = "@EMPLOYEE_DROPDOWN_FAILED";

export const EMPLOYEE_STATE_CHANGE = "@EMPLOYEE_STATE_CHANGE";