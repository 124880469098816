import React, { Fragment } from "react"
import Model from "../../../elements/CustomModel"
import { Modal, Row, Col  } from "react-bootstrap"
import ModelBody from "../../../elements/ModelBody"
import ModelFooter from "../../../elements/ModelFooter"
import Form from "../../../elements/CustomForm"
import Input from "../../../elements/CustomInput"
import Button from "../../../elements/CustomButton"
import Select from "../../../elements/CustomSelect"
import "../../../../App.css"

const AddEdit = ({
	isOpen,
	size,
	hideModel,
	handleSubmit,
	validated,
	isLoading,
	roleType,
	orderMenuData,
	erpMenuData,
	handleChange,
	state,
  handlePermissions,
  disableMenuValue,
  hideCloseModel
}) => {

  // handle menu selection
  const checkValue = (e, data) => {
    handlePermissions(e, data)
  }

  // function to handle auto selection for menu
  const autoCheckVAl = (value) => {
    let menuName = []
    if (state.menuId && state.menuId.length > 0) {
      state.menuId.map((item) => menuName.push(item.menuName))

      if (menuName.includes(value.menuName)) {
        return menuName.includes(value.menuName)
      }
    }
  }

	// render the AddEdit component
	return (
		<Fragment>
			<Model isOpen={isOpen} size={size}>
        <Modal.Header closeButton onClick={hideModel}>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>
        <Form handleSubmit={handleSubmit} validated={validated}>
          <ModelBody>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Input label={"Role Name"} ids={'roleName'} handleChange={handleChange} value={state.roleName} validate={true} />
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <Select label={`Select Role`} ids={"parentRoleId"} handleChange={handleChange} value={state.parentRoleId} result={roleType} validate={true} />
              </Col>
            </Row>
						{state.roleType === 'Employee' ?
              <div className="d-flex flex-wrap Employee">
                <div className="mb-2 me-3">
                  {erpMenuData && erpMenuData.length > 0 && erpMenuData.map((option) => (
                    <div className="form-check" key={option.menuId}>
                      <input className="form-check-input" type="checkbox" id={option.menuId} onChange={e => checkValue(e, option)} defaultChecked={autoCheckVAl(option)} disabled={disableMenuValue && disableMenuValue.length > 0 && disableMenuValue.includes(option.menuName)} />
                      <label className="form-check-label">
                        {option.menuName}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              :
              null
            }
            {state.roleType === 'Customer' ?
              <>
                <div className="d-flex flex-wrap Employee">
                  <div className="mb-2 me-3">
                    {orderMenuData && orderMenuData.length > 0 && orderMenuData.map((option) => (
                      <div className="form-check" key={option.menuId}>
                        <input className="form-check-input" type="checkbox" id={option.menuId} onChange={e => checkValue(e, option)} defaultChecked={autoCheckVAl(option)} />
                        <label className="form-check-label">
                          {option.menuName}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </> :
              <>
              </>
            }
          </ModelBody>
          <ModelFooter>
            <Button handleClick={hideCloseModel} variant="secondary" type="button">
              Close
            </Button>
            <Button variant="primary" type="submit">
              {isLoading ? (<div className="spinner-border"></div>) : "Save"}
            </Button>
          </ModelFooter>
        </Form>
      </Model>
		</Fragment>
	)
}
export default AddEdit;