import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateSalesStage";
import ConfirmDelete from "./models/ConfirmDelete";
// import { encrypted } from '../../../../_utils/helpers';
import {
	MARKETING_REQUEST,
	MARKETING_CREATE,
	MARKETING_DELETE,
	MARKETING_EDIT,
	MARKETING_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const Marketing = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchMarketing,
	submitMarketingCreate,
	submitMarketingDelete,
	submitMarketingEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [salesMarketingName, setMarketingName] = useState('');
	const [sortOrder, setSortOrder] = useState("");
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [deleteMarketingId, setDeleteMarketingId] = useState('');
	const [editMarketingId, setEditMarketingId] = useState('');
	const [editMarketingName, setEditMarketingName] = useState('');
	const [editSortOrder, setEditSortOrder] = useState('');

	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchMarketing(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("Marketing Created Successfully.");
			fetchMarketing(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Marketing Deleted Successfully.");
			fetchMarketing(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Marketing Data Updated Successfully.");
			fetchMarketing(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleSalesStageNameChange = (e) => {
		setMarketingName(e.target.value);
	}
	const handleSortOrderChange = (e) => {
		setSortOrder(e.target.value);
	}

	// function called for create sales stage create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (salesMarketingName === '') {
			toast.error("MARKETING name cannot be empty.");
		} else {
			submitMarketingCreate({
				"data": {
					"MarketSource": salesMarketingName,
					"MonthlyAmount": parseInt(sortOrder),
					"CreatedBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteMarketingId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteMarketingId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete sales stage delete api
	const handleConfirmDeleteSubmit = () => {
		submitMarketingDelete({
			"MarketingId": deleteMarketingId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditMarketingId(data.marketingId);
		setEditMarketingName(data.marketSource);
		setEditSortOrder(data.monthlyAmount);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editMarketingName === '') {
			toast.error("MARKETING name cannot be empty.");
		} else {
			submitMarketingEdit({
				"data": {
					"MarketingId": data.marketingId,
					"MarketSource": editMarketingName,
					"MonthlyAmount": parseInt(editSortOrder)
				},
				"token": auth[0].token
			});
			setEditMarketingId('');
			setEditMarketingName('');
			setEditSortOrder('');
		}
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<ToastContainer />
					<div className="sub-header mt-5 mb-3">
						<h2>Marketing</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Marketing`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							salesStageName={salesMarketingName}
							handleSalesStageNameChange={handleSalesStageNameChange}
							sortOrder={sortOrder}
							handleSortOrderChange={handleSortOrderChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Source</th>
								<th className="text-center">Monthly Amount</th>
								<th width="50" className="actions">
									<div className="d-flex">
										<span>Edit</span>
										<span>Delete</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									editMarketingId === item.marketingId ? (
										<tr key={index}>
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editMarketingName} onChange={(evt) => setEditMarketingName(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td width="150">
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editSortOrder} onChange={(evt) => setEditSortOrder(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td className="actions">
												<div className="btn-group">
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
													<ConfirmDelete variant="primary" title={`Marketing`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.marketingId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									) : (
										<tr key={index}>
											<td>
												{item.marketSource}
											</td>
											<td width="150">
												{item.monthlyAmount}
											</td>
											<td className="actions">
												<div className="btn-group">
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
													<ConfirmDelete variant="primary" title={`Marketing`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.marketingId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									)
								))
							) : (
								<tr>
									<td colSpan={4}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	)
}

const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchMarketing: (token) => dispatch({ type: MARKETING_REQUEST, token }),
		submitMarketingCreate: (data) => dispatch({ type: MARKETING_CREATE, payload: data }),
		submitMarketingDelete: (data) => dispatch({ type: MARKETING_DELETE, payload: data }),
		submitMarketingEdit: (data) => dispatch({ type: MARKETING_EDIT, payload: data }),
		changeReducerState: () => dispatch({ type: MARKETING_STATE_CHANGE })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Marketing);