import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateSalesStage";
import ConfirmDelete from "./models/ConfirmDelete";
// import { encrypted } from '../../../../_utils/helpers';
import {
  PAYMENT_ACCOUNT_REQUEST,
  PAYMENT_ACCOUNT_CREATE,
  PAYMENT_ACCOUNT_DELETE,
  PAYMENT_ACCOUNT_EDIT,
  PAYMENT_ACCOUNT_STATE_CHANGE
} from "../../../../_utils/constants/ProductCatalog";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const PaymentAccount = ({
  auth: { auth, isAuthenticated },
  PaymentAccount: { valueList, isCreated, isDeleted, isEdited, isLoading },
  fetchPaymentAccount,
  submitPaymentAccountCreate,
  submitPaymentAccountDelete,
  submitPaymentAccountEdit,
  changeReducerState
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [salesPaymentAccountName, setPaymentAccountName] = useState('');
  const [sortOrder, setSortOrder] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deletePaymentAccountId, setDeletePaymentAccountId] = useState('');
  const [editPaymentAccountId, setEditPaymentAccountId] = useState('');
  const [editPaymentAccountName, setEditPaymentAccountName] = useState('');
  const [editSortOrder, setEditSortOrder] = useState('');

  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchPaymentAccount(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("PaymentAccount Created Successfully.");
      fetchPaymentAccount(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("PaymentAccount Deleted Successfully.");
      fetchPaymentAccount(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("PaymentAccount Data Updated Successfully.");
      fetchPaymentAccount(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
  };

  // function called for input value change of create modal
  const handleSalesStageNameChange = (e) => {
    setPaymentAccountName(e.target.value);
  }
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  }

  // function called for create sales stage create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (salesPaymentAccountName === '') {
      toast.error("PAYMENT_ACCOUNT name cannot be empty.");
    } else {
      submitPaymentAccountCreate({
        "data": {
          "PaymentAccountName": salesPaymentAccountName,
          "Status": parseInt(sortOrder),
          "CreatedBy": auth[0].userId
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeletePaymentAccountId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeletePaymentAccountId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete sales stage delete api
  const handleConfirmDeleteSubmit = () => {
    submitPaymentAccountDelete({
      "PaymentAccountId": deletePaymentAccountId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setEditPaymentAccountId(data.paymentAccountId);
    setEditPaymentAccountName(data.paymentAccountName);
    setEditSortOrder(data.status);
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (editPaymentAccountName === '') {
      toast.error("PAYMENT_ACCOUNT name cannot be empty.");
    } else {
      submitPaymentAccountEdit({
        "data": {
          "PaymentAccountId": data.paymentAccountId,
          "PaymentAccountName": editPaymentAccountName,
          "Status": parseInt(editSortOrder)
        },
        "token": auth[0].token
      });
      setEditPaymentAccountId('');
      setEditPaymentAccountName('');
      setEditSortOrder('');
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">
          <ToastContainer />
          <div className="sub-header mt-5 mb-3">
            <h2>PaymentAccount</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`PaymentAccount`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              salesStageName={salesPaymentAccountName}
              handleSalesStageNameChange={handleSalesStageNameChange}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>PaymentAccountName</th>
                <th className="text-center">Status</th>
                <th width="50" className="actions">
                  <div className="d-flex">
                    <span>Edit</span>
                    <span>Delete</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (
                  editPaymentAccountId === item.paymentAccountId ? (
                    <tr key={index}>
                      <td>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control type="text" value={editPaymentAccountName} onChange={(evt) => setEditPaymentAccountName(evt.target.value)} />
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td width="150">
                        <Form.Group>
                          <InputGroup>
                            <Form.Control type="text" value={editSortOrder} onChange={(evt) => setEditSortOrder(evt.target.value)} />
                          </InputGroup>
                        </Form.Group>
                      </td>
                      <td className="actions">
                        <div className="btn-group">
                          <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
                          <ConfirmDelete variant="primary" title={`PaymentAccount`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.paymentAccountId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr key={index}>
                      <td>
                        {item.paymentAccountName}
                      </td>
                      <td width="150">
                        {item.status}
                      </td>
                      <td className="actions">
                        <div className="btn-group">
                          <Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                          <ConfirmDelete variant="primary" title={`PaymentAccount`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.paymentAccountId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                        </div>
                      </td>
                    </tr>
                  )
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
    </MainWrapper>
  )
}

const mapStateToProps = ({ auth, PaymentAccount }) => {
  return {
    auth,
    PaymentAccount
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPaymentAccount: (token) => dispatch({ type: PAYMENT_ACCOUNT_REQUEST, token }),
    submitPaymentAccountCreate: (data) => dispatch({ type: PAYMENT_ACCOUNT_CREATE, payload: data }),
    submitPaymentAccountDelete: (data) => dispatch({ type: PAYMENT_ACCOUNT_DELETE, payload: data }),
    submitPaymentAccountEdit: (data) => dispatch({ type: PAYMENT_ACCOUNT_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: PAYMENT_ACCOUNT_STATE_CHANGE })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentAccount);