import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateProduct = ({
  title,
  createModalOpen,
  showCreateModal,
  hideCreateModal,
  handleCreateModalSubmit,
  handleChange,
  state,
  ProductInfo
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} size="xl">
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="colors">
                  <Form.Label>Colors</Form.Label>
                  <Form.Control type="text" name="colors" onChange={handleChange} value={state.colors} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="privateColor">
                  <Form.Label>PrivateColor</Form.Label>
                  <Form.Control type="text" name="privateColor" onChange={handleChange} value={state.privateColor} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="colorNumber">
                  <Form.Label>ColorNumber</Form.Label>
                  <Form.Control type="text" name="colorNumber" onChange={handleChange} value={state.colorNumber} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="sku">
                  <Form.Label>Sku</Form.Label>
                  <Form.Control type="text" name="sku" onChange={handleChange} value={state.sku} />
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="alternateId">
                  <Form.Label>AlternateId</Form.Label>
                  <Form.Control type="text" name="alternateId" onChange={handleChange} value={state.alternateId} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="slot">
                  <Form.Label>Slot</Form.Label>
                  <Form.Control type="text" name="slot" onChange={handleChange} value={state.slot} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="dropingColor">
                  <Form.Label>DropingColor</Form.Label>
                  <Form.Control type="text" name="dropingColor" onChange={handleChange} value={state.dropingColor} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="fOB">
                  <Form.Label>FOB</Form.Label>
                  <Form.Control type="text" name="fOB" onChange={handleChange} value={state.fOB} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="qualityRating">
                  <Form.Label>QualityRating</Form.Label>
                  <Form.Control type="text" name="qualityRating" onChange={handleChange} value={state.qualityRating} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="productInfoId">
                  <Form.Label>ProductInfo</Form.Label>
                  <Form.Select aria-label="productInfoId" name="productInfoId" onChange={handleChange} value={state.productInfoId}>
                    <option>Select ProductInfo</option>
                    {ProductInfo.map((p, i) => (
                      <option key={i} value={p.productInfoId}>{p.productStyle}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="glaccountId">
                  <Form.Label>Glaccount</Form.Label>
                  <Form.Select aria-label="glaccountId" name="glaccountId" onChange={handleChange} value={state.glaccountId}>
                    <option>Select Glaccount</option>
                    <option value="6">Glaccount 6</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateModalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreateProduct;