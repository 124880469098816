import '../../assets/css/Main.css';
import Icon from 'react-icons-kit';
import { ic_dehaze, ic_clear } from 'react-icons-kit/md';
import { Button, Dropdown } from 'react-bootstrap';
import React, { useEffect, useRef } from "react";
import { LOGOUT_REQUEST } from "../../_utils/constants/Auth";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ORDER_USER_REQUEST } from "../../_utils/constants/Auth";
import { useNavigate, Navigate } from "react-router-dom";

function Header({
	auth: { isAuthenticated },
	handleLogout,
	handleIsActive,
	handleCookie,
	isActive
}) {

	let navigate = useNavigate();
	const menuRef = useRef(null);
	// const [isMenuActive, setIsMenuActive] = useState(false);

	useEffect(() => {
		if(document.cookie !== "" && document.cookie !== "orderingLogin=") {
		  handleCookie("orderingLogin");
		} else {
		  if (!isAuthenticated && (document.cookie === "" || document.cookie === "orderingLogin=")) 
		  {
			return navigate("/login");
		  }
		}
		if (!isAuthenticated) return <Navigate to="/login" />;
	}, [isAuthenticated])

	const handleToggle = () => {
		handleIsActive(!isActive);
	};
	const handleClickLogout = (e) => {
		e.preventDefault();
		handleLogout();
		navigate("/login");
	};

	return (
		<div className="main-header" ref={menuRef}>
			<ToastContainer />
			<Button className="toggle-button" onClick={handleToggle}>
				<Icon size={24} icon={ic_clear} />
				<Icon size={24} icon={ic_dehaze} />
			</Button>
			<h2 className="title">Dashboard</h2>
			{isAuthenticated ? (
				<div className="ms-auto d-flex align-items-center username">
					{/* <span className="me-2">First Name</span> */}
					<Dropdown>
						<Dropdown.Toggle variant="light" id="dropdown-basic">
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{/* <Dropdown.Item href="">Profile</Dropdown.Item> */}
							<Dropdown.Item onClick={handleClickLogout}>Logout</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			) : ''}
		</div>
	);
}
Header.propTypes = {
	auth: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleLogout: () => dispatch({ type: LOGOUT_REQUEST }),
		handleCookie: (data) => dispatch({ type: ORDER_USER_REQUEST, data }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
