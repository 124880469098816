import React, { Fragment, useEffect, useMemo, useState } from "react";
import MainWrapper from "../../../MainWrapper";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back } from 'react-icons-kit/md';
import Loader from ".././../../layouts/Loader";
import CustomButton from "../../../elements/CustomButton";
import {
  EMPLOYEE_LIST_REQUEST,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_EDIT_REQUEST,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DROPDOWN_REQUEST,
  EMPLOYEE_STATE_CHANGE
} from "../../../../_utils/constants/Employee";
import CustomTable from "./CustomTable";
import AddEdit from "./AddEdit";
import { toast } from 'react-toastify';

const EmployeeList = ({
  auth: { auth, isAuthenticated },
  Employee: { employeeList, productStructures, isCreated, isDeleted, isEdited, isLoading, errors },
  fetchEmployeeList,
  employeeCreateRequest,
  fetchEmployeeDropdownDataList,
  employeeEditRequest,
  employeeDeleteRequest,
  changeReducerState
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [state, setState] = useState({
    "firstName": "",
    "lastName": "",
    "email": "",
    "empRole": "",
  })
  const [employeeId, setEmployeeId] = useState("");

  useEffect(() => {
    if (!isAuthenticated) return <Navigate to="/login" />;
    if (isAuthenticated) {
      fetchEmployeeList(auth[0].token);
      fetchEmployeeDropdownDataList(auth[0].token);
    }
  }, [isAuthenticated, auth])
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState({ 
      ...state, 
      [id]: value 
    })
  }
  const handleEdit = (e, is) => {
    e.preventDefault();
    setEmployeeId(is.employeeId);
    setState({
      "firstName":is.firstName,
      "lastName": is.lastName,
      "email": is.email,
      "empRole": is.roleId,
    })
    setIsOpen(true);
  }

  const handleModal = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen)
    setValidated(false)
  }
  const hideModel = () => {
    setIsOpen(false)
    setValidated(false);
    setEmployeeId("");
    setState({
      "firstName": "",
      "lastName": "",
      "email": "",
      "empRole": "",
    })
  }

  useMemo(() => {
    if (isCreated === true) {
      toast.success("Employee created  successfully. An invitation link is sent to Employee.");
      changeReducerState();
      hideModel();
    }
    if (isDeleted === true) {
      toast.success("Employee deleted successfully.");
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("Employee updated successfully.")
      changeReducerState();
      hideModel();
    }
    if (isAuthenticated) {
      fetchEmployeeList(auth[0].token)
    }
  }, [isCreated, isDeleted, isEdited]);
  useMemo(() => {
    if (!_.isEmpty(errors)) {
      toast.error(errors.data.result[0])
      changeReducerState();
      fetchEmployeeList(auth[0].token)
    }
  }, [errors])

  const employeeListing = useMemo(() => employeeList, [employeeList]);
  const editForm = useMemo(() => employeeId, [employeeId])

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (employeeId) {
        var requestData =
        {
          "data": {
              "EmployeeId":employeeId,
              "FirstName":state.firstName,
              "LastName": state.lastName,
              "RoleId":state.empRole
          },
          "token": auth[0].token
        }
        employeeEditRequest(requestData)
      } else {
        var requestData = {
          "data": {
            "FirstName": state.firstName,
            "LastName": state.lastName,
            "Email": state.email,
            "RoleId": state.empRole
          },
          "token": auth[0].token
        }
        employeeCreateRequest(requestData);
      }
    }
    setValidated(true)
  }

  const productStructure = useMemo(() => {
    if (productStructures.length > 0) {
      let drpData = productStructures;
      return drpData.map(v => ({ label: v.roleName, value: v.roleId }));
    }
  }, [productStructures])

  //function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteCategoryId(id);
    setConfirmDeleteModal(true);
  };

  //function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteCategoryId('');
    setConfirmDeleteModal(false);
  };

  //function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    employeeDeleteRequest({
      "employeeId": deleteCategoryId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }
  return (
    <Fragment>
      <MainWrapper>
        <Loader loading={isLoading}>
          <div className="container">
            <div className="sub-header mt-5 mb-3">
              <h2>Employee</h2>
              <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
              <CustomButton
                handleClick={handleModal}
                variant="primary"
              >Create New
              </CustomButton>
            </div>
            <CustomTable
              productStructure={productStructure}
              employeeListing={employeeListing}
              showConfirmDeleteModal={showConfirmDeleteModal}
              hideConfirmDeleteModal={hideConfirmDeleteModal}
              handleConfirmDeleteSubmit={handleConfirmDeleteSubmit}
              confirmDeleteModal={confirmDeleteModal}
              handleEdit={handleEdit}
            />
          </div>
        </Loader>
        <AddEdit
          productStructure={productStructure}
          employeeListing={employeeListing}
          isOpen={isOpen}
          size={'sm'}
          state={state}
          handleChange={handleChange}
          validated={validated}
          handleSubmit={handleSubmit}
          hideModel={hideModel}
          employeeId={editForm}
          isLoading={isLoading}
        />
      </MainWrapper>
    </Fragment>
  )
}
const mapStateToProps = ({ auth, Employee }) => {
  return {
    auth, Employee
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchEmployeeList: (token) => dispatch({ type: EMPLOYEE_LIST_REQUEST, token }),
    fetchEmployeeDropdownDataList: (token) => dispatch({ type: EMPLOYEE_DROPDOWN_REQUEST, token }),
    changeReducerState: () => dispatch({ type: EMPLOYEE_STATE_CHANGE }),
    employeeCreateRequest: (data) => dispatch({ type: EMPLOYEE_CREATE_REQUEST, payload: data }),
    employeeDeleteRequest: (data) => dispatch({ type: EMPLOYEE_DELETE_REQUEST, payload: data }),
    employeeEditRequest: (data) => dispatch({ type: EMPLOYEE_EDIT_REQUEST, payload: data }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);