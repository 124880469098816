export const MATERIAL_ADDONS_REQUEST = "@MATERIAL_ADDONS_REQUEST";
export const MATERIAL_ADDONS_SUCCESS = "@MATERIAL_ADDONS_SUCCESS";
export const MATERIAL_ADDONS_FAILED = "@MATERIAL_ADDONS_FAILED";

export const LABOR_ADDONS_REQUEST = "@LABOR_ADDONS_REQUEST";
export const LABOR_ADDONS_SUCCESS = "@LABOR_ADDONS_SUCCESS";
export const LABOR_ADDONS_FAILED = "@LABOR_ADDONS_FAILED";


export const DEFAULT_PRODUCT_STRUCTURE_REQUEST = "@DEFAULT_PRODUCT_STRUCTURE_REQUEST";
export const DEFAULT_PRODUCT_STRUCTURE_SUCCESS = "@DEFAULT_PRODUCT_STRUCTURE_SUCCESS";
export const DEFAULT_PRODUCT_STRUCTURE_FAILED = "@DEFAULT_PRODUCT_STRUCTURE_FAILED";

export const KIT_REQUEST = "@KIT_REQUEST";
export const KIT_SUCCESS = "@KIT_SUCCESS";
export const KIT_FAILED = "@KIT_FAILED";

export const KIT_CREATE = "@KIT_CREATE";
export const KIT_CREATE_SUCCESS = "@KIT_CREATE_SUCCESS";
export const KIT_CREATE_FAILED = "@KIT_CREATE_FAILED";


export const KIT_DELETE = "@KIT_DELETE";
export const KIT_DELETE_SUCCESS = "@KIT_DELETE_SUCCESS";
export const KIT_DELETE_FAILED = "@KIT_DELETE_FAILED";

export const KIT_EDIT_REQUEST = "@KIT_EDIT_REQUEST";
export const KIT_EDIT_SUCCESS = "@KIT_EDIT_SUCCESS";
export const KIT_EDIT_FAILED = "@KIT_EDIT_FAILED";

export const KIT_UPDATE_REQUEST = "@KIT_UPDATE_REQUEST";
export const KIT_UPDATE_SUCCESS = "@KIT_UPDATE_SUCCESS";
export const KIT_UPDATE_FAILED = "@KIT_UPDATE_FAILED";

export const KIT_CREATED_REQUEST = "@KIT_CREATED_REQUEST";
export const KIT_CREATED_SUCCESS = "@KIT_CREATED_SUCCESS";
export const KIT_CREATED_FAILED = "@KIT_CREATED_FAILED";

export const KIT_STATE_CHANGE = "@KIT_STATE_CHANGE";