import {
  PAYMENT_ACCOUNT_REQUEST,
  PAYMENT_ACCOUNT_SUCCESS,
  PAYMENT_ACCOUNT_FAILED,
  PAYMENT_ACCOUNT_CREATE,
  PAYMENT_ACCOUNT_CREATE_SUCCESS,
  PAYMENT_ACCOUNT_CREATE_FAILED,
  PAYMENT_ACCOUNT_DELETE,
  PAYMENT_ACCOUNT_DELETE_SUCCESS,
  PAYMENT_ACCOUNT_DELETE_FAILED,
  PAYMENT_ACCOUNT_EDIT,
  PAYMENT_ACCOUNT_EDIT_SUCCESS,
  PAYMENT_ACCOUNT_EDIT_FAILED,
  PAYMENT_ACCOUNT_STATE_CHANGE,
} from "../../../_utils/constants/ProductCatalog";


const initialState = {
  valueList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const PaymentAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case PAYMENT_ACCOUNT_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        valueList: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case PAYMENT_ACCOUNT_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case PAYMENT_ACCOUNT_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case PAYMENT_ACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case PAYMENT_ACCOUNT_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case PAYMENT_ACCOUNT_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case PAYMENT_ACCOUNT_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case PAYMENT_ACCOUNT_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case PAYMENT_ACCOUNT_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case PAYMENT_ACCOUNT_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case PAYMENT_ACCOUNT_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case PAYMENT_ACCOUNT_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default PaymentAccountReducer;