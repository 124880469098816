import React, { Fragment, useEffect, useState } from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import { toast } from 'react-toastify';

const EditLayoutModal = ({
	handleLayoutModelOpen,
	handleLayoutModelClose,
	handleLayoutModalSave,
  customerUnitMixData,
	duplicatesUnitData,
	handleDuplicatesData
}) => {

	const [unitMixData, setUnitMixData] = useState()

	// function to modify modal listing
	const handleUnitMixData = (value) => {
		let obj = {}
		let arr =[]
		value.map(data => {
			obj.unitType = data.unitType
			arr.push({"unitName": data.unitName, "unitId": data.unitId})
		})
		obj.unitName = arr
		setUnitMixData(obj)
	}

	useEffect(() => {
		if (customerUnitMixData && customerUnitMixData.length > 0) {
			handleUnitMixData(customerUnitMixData)
		}
	}, [customerUnitMixData])

	// function to modify unit name
	const handleUnitNameChange = (customerData, value, index) => {
		if (customerData && customerData.length > 0) {		
			customerData.map((data, position) => {
				if (index === position) {
					data.unitName = value
				}
			})
			handleUnitMixData(customerData)
		}
	}

	// function to handle unit name change
	const handleUnitMixNameChange = (e, index, data) => {
		handleUnitNameChange(customerUnitMixData, e.target.value, index)
	}

	// function to modify unit type
	const handleUniTypeChange = (customerData, value) => {
		if(customerData && customerData.length > 0) {
			customerData.map(data => {
				data.unitType = value
			})
		}
		handleUnitMixData(customerData)
	}

	// function to handle unit type change
	const handleUnitMixTypeChange = (e, value) => {
		handleUniTypeChange(customerUnitMixData, e.target.value)
	}

	// function to handle duplicate unit name value 
	const handleDuplicateUnitName = () => {
		let uniqueValues = new Set(customerUnitMixData.map(v => v.unitName));
		if (uniqueValues.size < customerUnitMixData.length) {
			return true
		} else {
			return false
		}
	}

	// function to handle save unit mix
	const handleLayoutUnitMixSave = () => {
		if (customerUnitMixData.some(e => e.unitName === "") || customerUnitMixData.some(e => e.unitType === "")) {
			toast.error("Unit name or unit type cannot be left empty");
		} else {
			let isChecked = handleDuplicateUnitName()
			if (isChecked) {
				handleDuplicatesData(customerUnitMixData)
				toast.error("Unit name already exists");
			} else {
				handleLayoutModalSave(customerUnitMixData)
			}
		}
	}

	// function to handle close unit mix
	const handleLayoutUnitMixClose = () => {
		handleLayoutModelClose()
	}

	return (
		<Fragment>
			<Modal show={handleLayoutModelOpen} size="md">
				<Form>
					<Modal.Header closeButton onClick={handleLayoutUnitMixClose}>
						<Modal.Title>Edit Unit Mix</Modal.Title>
					</Modal.Header>
          <Modal.Body>
          	<div className="overflow-auto unit-mix-table-block" style={{ maxHeight: '500px'}}>
							<Table responsive bordered>
								<thead>
									<tr>
										<th className="w-50">Unit Type</th>
										<th className="w-50">Unit Name</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										{unitMixData ?
											<td>
												<input
													className="form-control form-control-sm"
													value={unitMixData.unitType ? unitMixData.unitType : null}
													onChange={e=>handleUnitMixTypeChange(e, unitMixData.unitType)}
												/>
											</td> : null
										}
										<td className="p-0">
											<div className="unit-number-list small p-2">
												{ unitMixData && unitMixData.unitName && unitMixData.unitName.length > 0 && unitMixData.unitName.map((data, index) => (
													<div key={index}>
														<input 
															className="form-control form-control-sm unit-number-box" 
															value={data.unitName} 
															onChange={e=>handleUnitMixNameChange(e, index, data)} 
														/>
													</div>
												))}
											</div>
										</td>
									</tr>
								</tbody>
        			</Table>
							{ duplicatesUnitData && duplicatesUnitData.length > 0 ? 
								<div>
									Duplicate unit name/s, rename to make it unique:
									{duplicatesUnitData.map((data, index) => (
										<div key={index}>
											<span>{data.name ? ' ' + data.name+'('+data.count+')' : null}</span>
										</div>
									))}
								</div> : null
							}
        		</div>
        	</Modal.Body>
					<Modal.Footer className="justify-content-center">
						<Button variant="primary" onClick={handleLayoutUnitMixSave}>Save</Button>
						<Button variant="secondary" onClick={handleLayoutUnitMixClose}>No</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	)
};

export default EditLayoutModal;