import React, { Fragment } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateNote = ({
  title,
  createModalOpen,
  showCreateModal,
  hideCreateModal,
  handleCreateModalSubmit,
  handleChange,
  state,

}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} size="xl">
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="notes">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control type="text" name="notes" onChange={handleChange} value={state.notes} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="notesDetailFor">
                  <Form.Label>NotesDetailFor</Form.Label>
                  <Form.Control type="text" name="notesDetailFor" onChange={handleChange} value={state.notesDetailFor} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="noteForPersonId">
                  <Form.Label>NoteForPerson</Form.Label>
                  <Form.Control type="text" name="noteForPersonId" onChange={handleChange} value={state.noteForPersonId} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="creationComment">
                  <Form.Label>CreationComment</Form.Label>
                  <Form.Control type="text" name="creationComment" onChange={handleChange} value={state.creationComment} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateModalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreateNote;