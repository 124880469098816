import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  UNIT_REQUEST,
  UNIT_SUCCESS,
  UNIT_FAILED,
  UNIT_CREATE,
  UNIT_CREATE_SUCCESS,
  UNIT_CREATE_FAILED,
  UNIT_DELETE,
  UNIT_DELETE_SUCCESS,
  UNIT_DELETE_FAILED,
  UNIT_EDIT,
  UNIT_EDIT_SUCCESS,
  UNIT_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * Unit Actions
 */

function* fetchUnit({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Master/api/Unit/GetUnit`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: UNIT_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: UNIT_FAILED, payload: error.response });
    }
  }
}

function* createUnit(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/Unit/CreateUnit`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: UNIT_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: UNIT_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editUnit(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/Unit/UpdateUnit`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: UNIT_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: UNIT_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteUnit(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/Unit/RemoveUnit?UnitId=${encodeURIComponent(action.payload.UnitId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: UNIT_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: UNIT_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootUnitList() {
  yield all([
    takeLatest(UNIT_REQUEST, fetchUnit),
    takeLatest(UNIT_CREATE, createUnit),
    takeLatest(UNIT_DELETE, deleteUnit),
    takeLatest(UNIT_EDIT, editUnit),
  ]);
}