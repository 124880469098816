import {
  CONTACTS_REQUEST,
  CONTACTS_SUCCESS,
  CONTACTS_FAILED,
  CONTACTS_CREATE,
  CONTACTS_CREATE_SUCCESS,
  CONTACTS_CREATE_FAILED,
  CONTACTS_DELETE,
  CONTACTS_DELETE_SUCCESS,
  CONTACTS_DELETE_FAILED,
  CONTACTS_EDIT,
  CONTACTS_EDIT_SUCCESS,
  CONTACTS_EDIT_FAILED,
  CONTACTS_STATE_CHANGE,
} from "../../../_utils/constants/ProductCatalog";


const initialState = {
  valueList: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  errors: {},
};

const ContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case CONTACTS_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        valueList: decryptedData.result,
        isLoading: false,
        isSuccess: true,
        errors: {}
      }
    case CONTACTS_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        errors: action.payload
      }
    case CONTACTS_CREATE:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case CONTACTS_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case CONTACTS_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }

    case CONTACTS_DELETE:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case CONTACTS_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDeleted: true,
        errors: {}
      }
    case CONTACTS_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case CONTACTS_EDIT:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case CONTACTS_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case CONTACTS_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }

    case CONTACTS_STATE_CHANGE:
      return {
        ...state,
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
  };
  return state;
};


export default ContactsReducer;