import React, { Fragment } from "react";
import { Modal, Form } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add } from 'react-icons-kit/md';
import { Button } from 'react-bootstrap';

const CreateVendor = ({
  title,
  createModalOpen,
  showCreateModal,
  hideCreateModal,
  jobTypeName,
  handleJobTypeNameChange,
  jobTypeColor,
  handleJobTypeColorChange,
  jobTypeStatus,
  handleJobTypeStatusChange,
  jobActiveStatus,
  handleActiveStatusChange,
  handleCreateModalSubmit,
  handleChange,
  state
}) => {

  return (
    <Fragment>
      <Button variant="primary" onClick={showCreateModal}><Icon size={24} icon={ic_add} /></Button>
      <Modal show={createModalOpen} >
        <Modal.Header>Create {title}</Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="CompanyName">
              <Form.Label>CompanyName</Form.Label>
              <Form.Control type="text" name="CompanyName" onChange={handleChange} value={state.CompanyName} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Account1">
              <Form.Label>Account1</Form.Label>
              <Form.Control type="text" name="Account1" onChange={handleChange} value={state.Account1} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Address1">
              <Form.Label>Address1</Form.Label>
              <Form.Control type="text" name="Address1" onChange={handleChange} value={state.Address1} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="City1">
              <Form.Label>City1</Form.Label>
              <Form.Control type="text" name="City1" onChange={handleChange} value={state.City1} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="State1">
              <Form.Label>State1</Form.Label>
              <Form.Control type="text" name="State1" onChange={handleChange} value={state.State1} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="ZipCode1">
              <Form.Label>ZipCode1</Form.Label>
              <Form.Control type="text" name="ZipCode1" onChange={handleChange} value={state.ZipCode1} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Country1">
              <Form.Label>Country1</Form.Label>
              <Form.Control type="text" name="Country1" onChange={handleChange} value={state.Country1} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="NameForPaymentPrint1">
              <Form.Label>NameForPaymentPrint1</Form.Label>
              <Form.Control type="text" name="Country1" onChange={handleChange} value={state.NameForPaymentPrint1} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CarrierId1">
              <Form.Label>CarrierId1</Form.Label>
              <Form.Control type="number" name="Country1" onChange={handleChange} value={state.CarrierId1} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Account2">
              <Form.Label>Account2</Form.Label>
              <Form.Control type="text" name="Account2" onChange={handleChange} value={state.Account2} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Address2">
              <Form.Label>Address2</Form.Label>
              <Form.Control type="text" name="Address2" onChange={handleChange} value={state.Address2} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="City2">
              <Form.Label>City2</Form.Label>
              <Form.Control type="text" name="City2" onChange={handleChange} value={state.City2} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="State2">
              <Form.Label>State2</Form.Label>
              <Form.Control type="text" name="State2" onChange={handleChange} value={state.State2} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="ZipCode2">
              <Form.Label>ZipCode2</Form.Label>
              <Form.Control type="text" name="ZipCode2" onChange={handleChange} value={state.ZipCode2} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Country2">
              <Form.Label>Country2</Form.Label>
              <Form.Control type="text" name="Country2" onChange={handleChange} value={state.Country2} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="NameForPaymentPrint2">
              <Form.Label>NameForPaymentPrint2</Form.Label>
              <Form.Control type="text" name="NameForPaymentPrint2" onChange={handleChange} value={state.NameForPaymentPrint2} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CarrierId2">
              <Form.Label>CarrierId2</Form.Label>
              <Form.Control type="number" name="CarrierId2" onChange={handleChange} value={state.CarrierId2} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsAcceptes">
              <Form.Check type="checkbox" label="Active Status" onChange={handleChange} checked={state.IsAcceptes} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCreateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateModalSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
};

export default CreateVendor;