import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import Create from "./models/CreateSalesStage";
import ConfirmDelete from "./models/ConfirmDelete";
// import { encrypted } from '../../../../_utils/helpers';
import {
	SALESSTAGE_REQUEST,
	SALESSTAGE_CREATE,
	SALESSTAGE_DELETE,
	SALESSTAGE_EDIT,
	SALESSTAGE_STATE_CHANGE
} from "../../../../_utils/constants/ValueList";
import Loader from "../../../layouts/Loader";
import { ToastContainer, toast } from 'react-toastify';

const SalesStages = ({
	auth: { auth, isAuthenticated },
	valueList: { valueList, isCreated, isDeleted, isEdited, isLoading },
	fetchSalesStages,
	submitSalesStageCreate,
	submitSalesStageDelete,
	submitSalesStageEdit,
	changeReducerState
}) => {

	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [salesStageName, setSalesStageName] = useState('');
	const [sortOrder, setSortOrder] = useState("");
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [deleteSalesStageId, setDeleteSalesStageId] = useState('');
	const [editSalesStageId, setEditSalesStageId] = useState('');
	const [editSalesStageName, setEditSalesStageName] = useState('');
	const [editSortOrder, setEditSortOrder] = useState('');

	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchSalesStages(auth[0].token);
		}
	}, [auth, isAuthenticated]);

	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("Sales Stage Created Successfully.");
			fetchSalesStages(auth[0].token);
			changeReducerState();
		}
		if (isDeleted === true) {
			toast.success("Sales Stage Deleted Successfully.");
			fetchSalesStages(auth[0].token);
			changeReducerState();
		}
		if (isEdited === true) {
			toast.success("Sales Stage Data Updated Successfully.");
			fetchSalesStages(auth[0].token);
			changeReducerState();
		}
	}, [isCreated, isDeleted, isEdited]);

	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};

	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};

	// function called for input value change of create modal
	const handleSalesStageNameChange = (e) => {
		setSalesStageName(e.target.value);
	}
	const handleSortOrderChange = (e) => {
		setSortOrder(e.target.value);
	}

	// function called for create sales stage create api
	const handleCreateModalSubmit = (e) => {
		e.preventDefault();
		if (salesStageName === '') {
			toast.error("Sales stage name cannot be empty.");
		} else {
			submitSalesStageCreate({
				"data": {
					"SalesStages": salesStageName,
					"Sort": parseInt(sortOrder),
					"CreatedBy": auth[0].userId
				},
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}

	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteSalesStageId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteSalesStageId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete sales stage delete api
	const handleConfirmDeleteSubmit = () => {
		submitSalesStageDelete({
			"salesStageId": deleteSalesStageId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}

	// function called on edit icon click
	const handleEditClick = (data) => {
		setEditSalesStageId(data.salesStageId);
		setEditSalesStageName(data.salesStages);
		setEditSortOrder(data.sort);
	}

	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (editSalesStageName === '') {
			toast.error("Sales stage name cannot be empty.");
		} else {
			submitSalesStageEdit({
				"data": {
					"SalesStageId": data.salesStageId,
					"SalesStages": editSalesStageName,
					"Sort": editSortOrder
				},
				"token": auth[0].token
			});
			setEditSalesStageId('');
			setEditSalesStageName('');
			setEditSortOrder('');
		}
	}

	if (!isAuthenticated) return <Navigate to="/login" />;

	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<ToastContainer />
					<div className="sub-header mt-5 mb-3">
						<h2>Sales Stages</h2>
						<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
						<Create
							variant="primary"
							title={`Sales Stage`}
							createModalOpen={createModalOpen}
							showCreateModal={showCreateModal}
							hideCreateModal={hideCreateModal}
							salesStageName={salesStageName}
							handleSalesStageNameChange={handleSalesStageNameChange}
							sortOrder={sortOrder}
							handleSortOrderChange={handleSortOrderChange}
							handleCreateModalSubmit={handleCreateModalSubmit}
						/>
					</div>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Sales Stages</th>
								<th className="text-center">Sort Order</th>
								<th width="50" className="actions">
									<div className="d-flex">
										<span>Edit</span>
										<span>Delete</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{valueList && valueList.length > 0 ? (
								valueList.map((item, index) => (
									editSalesStageId === item.salesStageId ? (
										<tr key={index}>
											<td>
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editSalesStageName} onChange={(evt) => setEditSalesStageName(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td width="150">
												<Form.Group>
													<InputGroup>
														<Form.Control type="text" value={editSortOrder} onChange={(evt) => setEditSortOrder(evt.target.value)} />
													</InputGroup>
												</Form.Group>
											</td>
											<td className="actions">
												<div className="btn-group">
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
													<ConfirmDelete variant="primary" title={`Sales Stage`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.salesStageId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									) : (
										<tr key={index}>
											<td>
												{item.salesStages}
											</td>
											<td width="150">
												{item.sort}
											</td>
											<td className="actions">
												<div className="btn-group">
													<Icon role="button" className="mt-2 w-54 d-flex justify-content-center" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
													<ConfirmDelete variant="primary" title={`Sales Stage`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.salesStageId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
												</div>
											</td>
										</tr>
									)
								))
							) : (
								<tr>
									<td colSpan={4}>
										No Data Found.
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Loader>
		</MainWrapper>
	)
}

const mapStateToProps = ({ auth, valueList }) => {
	return {
		auth,
		valueList
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchSalesStages: (token) => dispatch({ type: SALESSTAGE_REQUEST, token }),
		submitSalesStageCreate: (data) => dispatch({ type: SALESSTAGE_CREATE, payload: data }),
		submitSalesStageDelete: (data) => dispatch({ type: SALESSTAGE_DELETE, payload: data }),
		submitSalesStageEdit: (data) => dispatch({ type: SALESSTAGE_EDIT, payload: data }),
		changeReducerState: () => dispatch({ type: SALESSTAGE_STATE_CHANGE })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SalesStages);