import {
  ROLE_LIST_REQUEST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_FAILED,
  ROLE_CREATE_REQUEST,
  ROLE_CREATE_SUCCESS,
  ROLE_CREATE_FAILED,
  ROLE_EDIT_REQUEST,
  ROLE_EDIT_SUCCESS,
  ROLE_EDIT_FAILED,
  ROLE_DELETE_REQUEST,
  ROLE_DELETE_SUCCESS,
  ROLE_DELETE_FAILED,
  ROLE_DROPDOWN_REQUEST,
  ROLE_DROPDOWN_SUCCESS,
  ROLE_DROPDOWN_FAILED,
  ROLE_STATE_CHANGE
} from "../../../_utils/constants/Role";

const initialState = {
  roleList: [],
  productStructures: [],
  isLoading: false,
  isSuccess: false,
  isCreated: false,
  isDeleted: false,
  isEdited: false,
  isDeletedData: [],
  errors: {},
};

const RoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROLE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        errors: {}
      }
    case ROLE_LIST_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        roleList: decryptedData.result,
        isLoading: false,
        errors: {}
      }
    case ROLE_LIST_FAILED:
      return {
        ...state,
        isLoading:false,
        errors: action.payload
      }
      case ROLE_DROPDOWN_REQUEST:
        return {
          ...state,
          isLoading: true,
          isSuccess: false,
          errors: {}
        }
      case ROLE_DROPDOWN_SUCCESS:
        var decryptedData = action.payload;
        return {
          ...state,
          productStructures: decryptedData.result,
          isLoading: false,
          isSuccess: true,
          errors: {}
        }
      case ROLE_DROPDOWN_FAILED:
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          errors: action.payload
        }
      case ROLE_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isCreated: false,
        errors: {}
      }
    case ROLE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCreated: true,
        errors: {}
      }
    case ROLE_CREATE_FAILED:
      return {
        ...state,
        isLoading: false,
        isCreated: false,
        errors: action.payload
      }
    case ROLE_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
        errors: {}
      }
    case ROLE_DELETE_SUCCESS:
      var decryptedData = action.payload;
      return {
        ...state,
        isLoading: false,
        isDeletedData: decryptedData,
        isDeleted: true,
        errors: {}
      }
    case ROLE_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        isDeleted: false,
        errors: action.payload
      }
    case ROLE_EDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isEdited: false,
        errors: {}
      }
    case ROLE_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEdited: true,
        errors: {}
      }
    case ROLE_EDIT_FAILED:
      return {
        ...state,
        isLoading: false,
        isEdited: false,
        errors: action.error
      }
    case ROLE_STATE_CHANGE:
      return {
        ...state,
        roleList: [],
        isCreated: false,
        isDeleted: false,
        isEdited: false,
      }
    };
  return state;
}

export default RoleReducer;