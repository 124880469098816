import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";

const MeasureModal = ({
	handleMeasureModelOpen,
	handleMeasureModelClose,
	measureUrl
}) => {

	return (
		<Fragment>
			<Modal show={handleMeasureModelOpen} size="fullscreen" onHide={handleMeasureModelClose}>
				<Modal.Header closeButton>
					<Modal.Title>Seam & Cut Plan</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-0">
					{
      					<iframe
							className="measuremodal"
							scrolling="yes"
							width="100%"
							height="550"
							src={measureUrl}
						/>
          			}
				</Modal.Body>
			</Modal>
		</Fragment>
	)
};

export default MeasureModal;