import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Product from "./_routes/productRoute";
import IndexRoute from "./_routes/indexRoute";
import CustomerRoute from "./_routes/customerRoute";
import AdminRoute from "./_routes/adminRoute";
// // import NotFound from "./components/pages/notFound/NotFound";
import { pusher } from "./config";

export const Context = React.createContext({ value: null, setValue: () => {} });

const App = () => {
  const [pushervalue, setPushervalue] = useState(pusher);

  return (
    <div className="App">
      <Context.Provider value={{pushervalue, setPushervalue}}>
        <BrowserRouter>
          <Product />
          <IndexRoute />
          <CustomerRoute />
          <AdminRoute />
          {/* <Routes>
            <Route path="*" element={<NotFound />} />
          </Routes> */}

        </BrowserRouter>
      </Context.Provider>
    </div>
  );
};

export default App;
