import React, { useEffect, useMemo } from "react";
import { Link, Navigate } from "react-router-dom";
import '../../../assets/css/admin.css';
import MainWrapper from "../../MainWrapper";
import { Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
	ASSIGNED_ACCESS_REQUEST,
	MENU_LIST_REQUEST,
	USER_MENU_LIST_REQUEST
} from "../../../_utils/constants/admin/Admin";
import _ from "lodash";
import Loader from "../../layouts/Loader";
import { LOGOUT_REQUEST } from "../../../_utils/constants/Auth";

const Admin = (
	{
		auth: { auth, isAuthenticated },
		Admin: { menuList, isLoading, userMenuList },
		fetchMenuList,
		fetchUserMenuList,
		handleLogout
	}) => {

	// handle user permission api call
	useEffect(() => {
		//previous permission api
		// fetchMenuList(auth[0].token)
		fetchUserMenuList(auth[0]?.token, auth[0]?.roleid, auth[0]?.userId)
	}, [])

	// function to handle user menu permissions 
	let menu = useMemo(() => {
		if (userMenuList) {
			let list = userMenuList[0]?.permission.filter(p => p.menuName === 'Admin');
			return list
		}
		if (userMenuList[0] === null) {
			handleLogout();
		}
	}, [userMenuList])

	if (!isAuthenticated) return <Navigate to="/login" />;
	return (
		<MainWrapper>
			<Loader loading={isLoading}>
				<div className="container">
					<Tabs defaultActiveKey="Admin" id="uncontrolled-tab-example" className="">
						{_.map(menu, (m, i) => (
							<Tab eventKey={`${m.menuName}`} title={`${m.menuName}`} className="px-3 pt-3 pb-1 admin-tabs">
								{_.map(m.subMenu, (subM) => (
									<Link className="btn btn-primary" key={subM.subMenuId} to={`${subM.subMenuUrl}`} variant="primary" type="submit">{subM.subMenuName}</Link>
								))}
							</Tab>
						))}
						{/* 					
					<Tab eventKey="admin" title="Admin" className="px-3 pt-3 pb-1 admin-tabs">
						{_.map(menuList, (m, i) => (
							<Link className="btn btn-primary" key={i} to="/product-catalog/product-type" variant="primary" type="submit">{m.subMenuName}</Link>
						))}
							<Link className="btn btn-primary" to="/labor/catgeory" variant="primary" type="submit">Labor Categories</Link>
						<Link className="btn btn-primary" to="/personal-user" variant="primary" type="submit">User Profiles</Link>
						<Link className="btn btn-primary" to="/product-catalog/product-type" variant="primary" type="submit">Product Structure</Link>
						<Link className="btn btn-primary" to="/product-catalog/store-list" variant="primary" type="submit">Store Information</Link>
						<Button className="btn btn-primary" type="submit">1099</Button>
						<Button className="btn btn-primary" type="submit">Activation</Button>
						<Button className="btn btn-primary" type="submit">Activity Log</Button>
						<Button className="btn btn-primary" type="submit">Avalara Settings</Button>
						<Button className="btn btn-primary" type="submit">B2B Products Catalog</Button>
						<Button className="btn btn-primary" type="submit">Budget Gauge Settings</Button>
						<Button className="btn btn-primary" type="submit">Close Books</Button>
						<Button className="btn btn-primary" type="submit">Conditions</Button>
						<Link className="btn btn-primary" to="/Defaults" type="submit">Defaults</Link>
						<Button className="btn btn-primary" type="submit">Email Templates</Button>
						<Button className="btn btn-primary" type="submit">Employee/Subs</Button>
						<Button className="btn btn-primary" type="submit">Enterprise Control Panel</Button>
						<Button className="btn btn-primary" type="submit">Fiscal Period</Button>
						<Button className="btn btn-primary" type="submit">Group Discounts</Button>
						<Button className="btn btn-primary" type="submit">Hourly Labor Matrix</Button>
						<Button className="btn btn-primary" type="submit">Import GL</Button>
						<Button className="btn btn-primary" type="submit">Job Audit Tool</Button>
						<Button className="btn btn-primary" type="submit">Languages</Button>
						<Button className="btn btn-primary" type="submit">JR Data</Button>
						<Button className="btn btn-primary" type="submit">Ledger Accounts</Button>
						<Button className="btn btn-primary" type="submit">Personnel/Access</Button>
						<Button className="btn btn-primary" type="submit">Print Options</Button>
						<Button className="btn btn-primary" type="submit">Printer Defaults</Button>
						<Button className="btn btn-primary" type="submit">Product Kits</Button>
						<Button className="btn btn-primary" type="submit">Product Structure</Button>
						<Button className="btn btn-primary" type="submit">PS Account Profile</Button>
						<Button className="btn btn-primary" type="submit">Report/Admin Options</Button>
						<Button className="btn btn-primary" type="submit">Royalty Setup</Button>
						<Button className="btn btn-primary" type="submit">Scheduled Reports</Button>
						<Button className="btn btn-primary" type="submit">Security Profile</Button>
						<Button className="btn btn-primary" type="submit">Software Agreement</Button>
						<Button className="btn btn-primary" type="submit">Tax Authorities</Button>
						<Button className="btn btn-primary" type="submit">Tax Codes</Button>
						<Button className="btn btn-primary" type="submit">Staged Pick Tickets</Button>
						<Button className="btn btn-primary" type="submit">Terms</Button>
					</Tab>
					<Tab eventKey="maintenance" title="Maintenance" className="p-3 admin-tabs">
						<Button className="btn btn-primary" type="submit">Schedule</Button>
						<Button className="btn btn-primary" type="submit">Address Value Lists</Button>
						<Button className="btn btn-primary" type="submit">Batch Invoicing</Button>
						<Button className="btn btn-primary" type="submit">Close Sales</Button>
						<Button className="btn btn-primary" type="submit">Confirm AP</Button>
						<Button className="btn btn-primary" type="submit">Confirm AR</Button>
						<Button className="btn btn-primary" type="submit">Confirm Inventory</Button>
						<Button className="btn btn-primary" type="submit">Confirm invoiced</Button>
						<Button className="btn btn-primary" type="submit">Confirm Jobs</Button>
						<Button className="btn btn-primary" type="submit">Confirm Payments</Button>
						<Button className="btn btn-primary" type="submit">Confirm Rec/Del</Button>
						<Button className="btn btn-primary" type="submit">Confirm Rev Rec GL</Button>
						<Button className="btn btn-primary" type="submit">Diagnose AP</Button>
						<Button className="btn btn-primary" type="submit">Diagnose AR</Button>
						<Button className="btn btn-primary" type="submit">Find All For Update</Button>
						<Button className="btn btn-primary" type="submit">Import Customers</Button>
						<Button className="btn btn-primary" type="submit">Import Inventory</Button>
						<Button className="btn btn-primary" type="submit">Import Vendors</Button>
						<Button className="btn btn-primary" type="submit">Remove Inv Holds</Button>
						<Button className="btn btn-primary" type="submit">Reset Quotes/Prop</Button>
						<Button className="btn btn-primary" type="submit">Reset Sub Accounts</Button>
						<Button className="btn btn-primary" type="submit">Reset Types</Button>
						<Button className="btn btn-primary" type="submit">Reset Users</Button>
						<Button className="btn btn-primary" type="submit">Reset Window Size</Button>
						<Button className="btn btn-primary" type="submit">Inventory Adj. Correction</Button>
						<Button className="btn btn-primary" type="submit">Inventory AOD Conversion</Button>
						<Button className="btn btn-primary" type="submit">Set Commissions</Button>
						<Button className="btn btn-primary" type="submit">Update AR Aging SP</Button>
						<Button className="btn btn-primary" type="submit">Update Customers</Button>
						<Button className="btn btn-primary" type="submit">Update Rev Rec AR</Button>
					</Tab>
					<Tab eventKey="valuelist" title="Value List" className="p-3 admin-tabs">
						<Link className="btn btn-primary" to="/AgeDemographic" variant="primary" type="submit">Age Demographics</Link>
						<Link className="btn btn-primary" to="/alternate-names" variant="primary" type="submit">Alternate Names</Link>
						<Link className="btn btn-primary" to="/area" variant="primary" type="submit">Area</Link>
						<Link className="btn btn-primary" to="/bin-location" variant="primary" type="submit">Bin Location</Link>
						<Link className="btn btn-primary" to="/buying-group-setup" variant="primary" type="submit">Buying Group Setup</Link>
						<Link className="btn btn-primary" to="/cashback" variant="primary" type="submit">Cashback</Link>
						<Link className="btn btn-primary" to="/competitors" type="submit">Competitors</Link>
						<Link className="btn btn-primary" to="/form-value-lists" type="submit">Form Value Lists</Link>
						<Link className="btn btn-primary" to="/form-templates" type="submit">Form Templates</Link>
						<Link className="btn btn-primary" to="/forms" type="submit">Forms</Link>
						<Link className="btn btn-primary" to="/IDStatus" type="submit">ID Status</Link>
						<Link className="btn btn-primary" to="/JobTypes" type="submit">Job Types</Link>
						<Link className="btn btn-primary" to="/Lost" type="submit">Lost</Link>
						<Link className="btn btn-primary" to="/InvoiceFlags" type="submit">Invoice Flags</Link>
						<Link className="btn btn-primary" to="/MarketSector" type="submit">Market Sector</Link>
						<Link className="btn btn-primary" to="/Marketing" type="submit">Marketing</Link>
						<Link className="btn btn-primary" to="/NoteTemplates" type="submit">Note Templates</Link>
						<Link className="btn btn-primary" to="/ProductInterest" type="submit">Product Interest</Link>
						<Link className="btn btn-primary" to="/Region" type="submit">Region</Link>
						<Link className="btn btn-primary" to="/SalesStages" type="submit">Sales Stages</Link>
						<Link className="btn btn-primary" to="/SICCodes" type="submit">SIC Codes</Link>
						<Link className="btn btn-primary" to="/Vendor" type="submit">Vendors</Link>
						<Link className="btn btn-primary" to="/veder-type" type="submit">Vendor Type</Link>
						<Link className="btn btn-primary" to="/Warehouses" type="submit">Warehouses</Link>
						<Link className="btn btn-primary" to="/ShippingMethods" type="submit">Shipping Methods</Link>
						<Link className="btn btn-primary" to="/Won" type="submit">Won</Link>
					</Tab>
					<Tab eventKey="productCatalog" title="Product Catalog" className="p-3 admin-tabs">
						<Link className="btn btn-primary" to="/product-catalog/unit" variant="primary" type="submit">Unit</Link>
						<Link className="btn btn-primary" to="/product-catalog/carrier" variant="primary" type="submit">Carrier</Link>
						<Link className="btn btn-primary" to="/product-catalog/productsource" variant="primary" type="submit">Product Source</Link>
						<Link className="btn btn-primary" to="/product-catalog/property" variant="primary" type="submit">Property</Link>
						<Link className="btn btn-primary" to="/product-catalog/ship" variant="primary" type="submit">Ship</Link>
						<Link className="btn btn-primary" to="/product-catalog/ship-payment" variant="primary" type="submit">ShipPayment</Link>
						<Link className="btn btn-primary" to="/product-catalog/manufacturer" variant="primary" type="submit">Manufacturer</Link>
						<Link className="btn btn-primary" to="/product-catalog/payment-account" variant="primary" type="submit">PaymentAccount</Link>
						<Link className="btn btn-primary" to="/product-catalog/sold-by" variant="primary" type="submit">SoldBy</Link>
						<Link className="btn btn-primary" to="/product-catalog/product-type" variant="primary" type="submit">ProductType</Link>
						<Link className="btn btn-primary" to="/product-catalog/product-price" variant="primary" type="submit">ProductPrice</Link>
						<Link className="btn btn-primary" to="/product-catalog/product-price-detail" variant="primary" type="submit">ProductPriceDetail</Link>
						<Link className="btn btn-primary" to="/product-catalog/product-info" variant="primary" type="submit">ProductInfo</Link>
						<Link className="btn btn-primary" to="/product-catalog/phone" variant="primary" type="submit">Phone</Link>
						<Link className="btn btn-primary" to="/product-catalog/note" variant="primary" type="submit">Note</Link>
						<Link className="btn btn-primary" to="/product-catalog/contacts" variant="primary" type="submit">Contacts</Link>
						<Link className="btn btn-primary" to="/product-catalog/product-spec" variant="primary" type="submit">ProductSpec</Link>
						<Link className="btn btn-primary" to="/product-catalog/product-installed-price" variant="primary" type="submit">ProductInstalledPrice</Link>
						<Link className="btn btn-primary" to="/product-catalog/product" variant="primary" type="submit">Product</Link>
					</Tab>
				 */}
					</Tabs>
				</div>
			</Loader>
		</MainWrapper>
	);
};

const mapStateToProps = ({ auth, Admin }) => {
	return {
		auth,
		Admin
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAccessLavel: (token, userId) => dispatch({ type: ASSIGNED_ACCESS_REQUEST, token, userId }),
		fetchMenuList: (token) => dispatch({ type: MENU_LIST_REQUEST, token }),
		fetchUserMenuList: (token, roleId, userId) => dispatch({ type: USER_MENU_LIST_REQUEST, token, roleId, userId }),
		handleLogout: () => dispatch({ type: LOGOUT_REQUEST }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
