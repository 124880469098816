import { DECRYPT_REQUEST, DECRYPT_SUCCESS, DECRYPT_FAILED } from '../../_utils/constants/Decrypt';

const initialState = {
  result: "",
  isLoading: false,
  isDecrypted:false,
  errors: {},
}

const decryptReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DECRYPT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isDECRYPTed:false,
        errors: {},
      }
    case DECRYPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDECRYPTed:true,
        result: payload
      }
    case DECRYPT_FAILED:
      return {
        ...state,
        isLoading: false,
        isDECRYPTed:false,
        errors: payload,
      }
    default:
      return state;
  }

}

export default decryptReducer;