import React, { Fragment, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import MainWrapper from "../../../MainWrapper";
import { Link, useNavigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back  } from 'react-icons-kit/md';
import { Accordion, Table, Button, Row, Col } from "react-bootstrap";
import { toast } from 'react-toastify';
import {
  CUSTOMER_APPROVAL_LIST_REQUEST,
  CUSTOMER_APPROVAL_UPDATE_REQUEST,
  CUSTOMER_APPROVAL_REJECT_REQUEST,
  CUSTOMER_APPROVAL_DELETE_REQUEST
} from "../../../../_utils/constants/CustomerApproval";
import Input from "../../../elements/CustomInput";

const CustomerApproval = ({
  auth: { auth, isAuthenticated },
  CustomerApproval: { 
    customerApprovalList, 
    errors, 
    isLoading, 
    customerUpdationData, 
    customerRejectedData, 
    customerDeletedData,
    customerExistData
  },
  fetchCustomerApprovalList,
  updateCustomerApprovalStatus,
  rejectCustomerApprovalRequest,
  deleteCustomerApprovalRequest
}) => {

  //state declaration
  const [customerApprovalData, setCustomerApprovalData] = useState()
  const [listParamState, setListParamState] = useState({
		"firstName": "",
		"lastName": "",
		"email": "",
		"pageIndex": 0,
		"pageSize": 0
	});
  const [responseData, setResponseData] = useState()
  const [responseDeleteData, setResponseDeleteData] = useState()
  const [isLoaded, setIsLoaded] = useState(false)
  const approvedMessage = "Sign up request for customer is successfully approved. Email with verification link will be sent to customer."
  const rejectedMessage = "Sign up request is successfully rejected."
  // const [selectedPropertyIds, setSelectedPropertyIds] = useState([]) 
  const navigate = useNavigate();

  // function to handle customer approval list api call
  useEffect(() => {
    if (!isAuthenticated) {
			navigate("/login")
		}
    if (isAuthenticated) {
      fetchCustomerApprovalList(auth[0].token, listParamState)
    }
  }, [isAuthenticated, auth, listParamState])

  // function to handle customer approval list
  useEffect(() => {
    setCustomerApprovalData(customerApprovalList)
  }, [customerApprovalList])

  // handle customer approval updation
  useEffect(() => {
    if(!_.isEmpty(customerUpdationData)) {
      setResponseData(customerUpdationData)
    }
  }, [customerUpdationData])

  // handle customer rejection
  useEffect(() => {
    if(!_.isEmpty(customerRejectedData)) {
      setResponseData(customerRejectedData)
    }
  }, [customerRejectedData])

  // handle response data
  useEffect(() => {
    if (customerExistData) {
      toast.error(customerExistData)
    }
  }, [customerExistData])

  //handle response data
  useEffect(() => {
    if (responseData && isLoaded) {
      if (responseData[0] === "Rejected") {
        toast.success(rejectedMessage)
        setIsLoaded(false)
      } else if (responseData[0] === "saved") {
        toast.success(approvedMessage)
        setIsLoaded(false)
      } else {
        toast.success(responseData[0])
        setIsLoaded(false)
      }
      fetchCustomerApprovalList(auth[0].token, listParamState)
    }
  }, [responseData])

  // handle api errors
  useMemo(() => {
    if(!_.isEmpty(errors)) {
      toast.error(errors?.data?.result ? errors?.data?.result[0]: "Error")
    }
  }, [errors])

  // handle delete customer api data
  useEffect(() => {
    if(customerDeletedData && customerDeletedData.length > 0) {
      setResponseDeleteData(customerDeletedData)
    }
  }, [customerDeletedData])

  useEffect(() => {
    if (responseDeleteData && isLoaded) {
      if (responseDeleteData[0] === 'Not Delete') {
        toast.success("Customer not deleted")
        setIsLoaded(false)
      } else {
        toast.success(customerDeletedData[0])
        setIsLoaded(false)
      }
      fetchCustomerApprovalList(auth[0].token, listParamState)
    }
  }, [responseDeleteData])

  // function called on any filter value change
	const handleFilterChange = (e) => {
		const { id, value } = e.target;
		setListParamState(
			{
				...listParamState,
				[id]: value
			}
		)
	}

  // handle approve click
  const handleApproveClick = (data) => {
    setIsLoaded(true)
    updateCustomerApprovalStatus(auth[0].token, data)
  }

  // handle reject click
  const handleRejectClick = (data) => {
    setIsLoaded(true)
    rejectCustomerApprovalRequest(auth[0].token, data)
  }

  //handle customer delete
  const handleDelete = (data) => {
    setIsLoaded(true)
    if(data) {
      deleteCustomerApprovalRequest(auth[0].token, data)
    }
  }

  return (
    <Fragment>
      <MainWrapper>
      {isLoading ? (<div className="loading-container"><div className="spinner-border"></div></div>) : null}
      <div className="container">
        <div className="sub-header mt-5 mb-3">
          <h2>Customer approval</h2>
          <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back</Link>
        </div>
        
        <div className="p-3 bg-light customer-approval-filter">
          <Row>
            <Col md={4}><Input label={`First Name`} ids={`firstName`} value={listParamState.firstName} validate={false} handleChange={handleFilterChange} /></Col>
            <Col md={4}><Input label={`Last Name`} ids={`lastName`} value={listParamState.lastName} validate={false} handleChange={handleFilterChange} /></Col>
            <Col md={4}><Input label={`Email`} ids={`email`} value={listParamState.email} validate={false} handleChange={handleFilterChange} /></Col>
          </Row>
        </div>
        <Accordion defaultActiveKey="0">
          {!_.isEmpty(customerApprovalData) ? _.map(customerApprovalData, (data, index) => (
            <Accordion.Item eventKey={index}>
              <Accordion.Header key={index}>{data.firstName} {data.lastName} - {data.email} - {data.role}</Accordion.Header>
              <Accordion.Body>
                <Table bordered responsive className="mb-3">
                  <thead>
                    <tr>
                      <th>Property Name</th>
                      <th>Management Company</th>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Zip</th>
                      <th>Phone No.</th>
                    </tr>
                  </thead>
                  {!_.isEmpty(data.customerProperty) ? _.map(data.customerProperty, (value, position) => (
                    <tbody>
                      <tr key={position}>
                        <td>{_.get(value, "propertyName", "")}</td>
                        <td>{_.get(value, "managementCompany", "")}</td>
                        <td>{_.get(value, "address", "")}</td>
                        <td>{_.get(value, "city", "")}</td>
                        <td>{_.get(value, "state", "")}</td>
                        <td>{_.get(value, "zip", "")}</td>
                        <td>{_.get(value, "phoneNo", "")}</td>
                      </tr>
                    </tbody> )) : "null" 
                  }
                </Table>
                <div>
                  Status: {_.get(data, "status", "")}
                </div>
                <Button className="me-2" variant="primary" disabled={data?.status === "Pending Request" ? false : true} onClick={()=>handleApproveClick(data)}>Approve</Button>
                <Button className="me-2" variant="secondary" disabled={data?.status === "Pending Request" ? false : true} onClick={()=>handleRejectClick(data)}>Reject</Button>
                <Button className="me-2" variant="danger" onClick={()=>handleDelete(data)}>Delete</Button>
              </Accordion.Body>
            </Accordion.Item> )) : null
          }
        </Accordion>
      </div>
      </MainWrapper>
    </Fragment>
  )
}

const mapStateToProps = ({ auth, CustomerApproval }) => {
  return {
    auth, CustomerApproval
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomerApprovalList: (token, listParamState) => dispatch({ type: CUSTOMER_APPROVAL_LIST_REQUEST, token, listParamState }),
    updateCustomerApprovalStatus: (token, data) => dispatch({ type: CUSTOMER_APPROVAL_UPDATE_REQUEST, token, data }),
    rejectCustomerApprovalRequest: (token, data) => dispatch({ type: CUSTOMER_APPROVAL_REJECT_REQUEST, token, data }),
    deleteCustomerApprovalRequest: (token, data) => dispatch({ type: CUSTOMER_APPROVAL_DELETE_REQUEST, token, data })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerApproval);