export const CUSTOMER_APPROVAL_LIST_REQUEST = "@CUSTOMER_APPROVAL_LIST_REQUEST";
export const CUSTOMER_APPROVAL_LIST_SUCCESS = "@CUSTOMER_APPROVAL_LIST_SUCCESS";
export const CUSTOMER_APPROVAL_LIST_FAILED = "@CUSTOMER_APPROVAL_LIST_FAILED";

export const CUSTOMER_APPROVAL_UPDATE_REQUEST = "@CUSTOMER_APPROVAL_UPDATE_REQUEST";
export const CUSTOMER_APPROVAL_UPDATE_SUCCESS = "@CUSTOMER_APPROVAL_UPDATE_SUCCESS";
export const CUSTOMER_APPROVAL_UPDATE_FAILED = "@CUSTOMER_APPROVAL_UPDATE_FAILED";

export const CUSTOMER_APPROVAL_REJECT_REQUEST = "@CUSTOMER_APPROVAL_REJECT_REQUEST";
export const CUSTOMER_APPROVAL_REJECT_SUCCESS = "@CUSTOMER_APPROVAL_REJECT_SUCCESS";
export const CUSTOMER_APPROVAL_REJECT_FAILED = "@CUSTOMER_APPROVAL_REJECT_FAILED";

export const CUSTOMER_APPROVAL_DELETE_REQUEST = "@CUSTOMER_APPROVAL_DELETE_REQUEST";
export const CUSTOMER_APPROVAL_DELETE_SUCCESS = "@CUSTOMER_APPROVAL_DELETE_SUCCESS";
export const CUSTOMER_APPROVAL_DELETE_FAILED = "@CUSTOMER_APPROVAL_DELETE_FAILED";

export const CUSTOMER_APPROVAL_STATE_CHANGE = "@CUSTOMER_APPROVAL_STATE_CHANGE";