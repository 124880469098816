import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import { API_URL } from "../../../config";
import { SESSION_EXPIRED } from "../../../_utils/constants/Auth";
import {
  SHIP_PAYMENT_REQUEST,
  SHIP_PAYMENT_SUCCESS,
  SHIP_PAYMENT_FAILED,
  SHIP_PAYMENT_CREATE,
  SHIP_PAYMENT_CREATE_SUCCESS,
  SHIP_PAYMENT_CREATE_FAILED,
  SHIP_PAYMENT_DELETE,
  SHIP_PAYMENT_DELETE_SUCCESS,
  SHIP_PAYMENT_DELETE_FAILED,
  SHIP_PAYMENT_EDIT,
  SHIP_PAYMENT_EDIT_SUCCESS,
  SHIP_PAYMENT_EDIT_FAILED,
} from "../../../_utils/constants/ProductCatalog";


/*****
 * ShipPayment Actions
 */

function* fetchShipPayment({ token }) {
  try {
    const response = yield call(axios.get, `${API_URL}/Product/api/ShipPayment/GetAllShipPayment`, { headers: { Authorization: `Bearer ${token}` } });
    yield put({ type: SHIP_PAYMENT_SUCCESS, payload: response.data });
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({ type: SHIP_PAYMENT_FAILED, payload: error.response });
    }
  }
}

function* createShipPayment(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ShipPayment/CreateShipPayment`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SHIP_PAYMENT_CREATE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SHIP_PAYMENT_CREATE_FAILED,
        payload: error.response
      });
    }
  }
}
function* editShipPayment(action) {
  try {
    const response = yield call(
      axios.post,
      `${API_URL}/Product/api/ShipPayment/UpdateShipPayment`,
      action.payload.data, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      },
    }
    );
    yield put({
      type: SHIP_PAYMENT_EDIT_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SHIP_PAYMENT_EDIT_FAILED,
        payload: error.response
      });
    }
  }
}
function* deleteShipPayment(action) {
  try {
    const response = yield call(
      axios.delete,
      `${API_URL}/Product/api/ShipPayment/RemoveShipPayment?ShipPaymentId=${encodeURIComponent(action.payload.ShipPaymentId)}`, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    },
    );
    yield put({
      type: SHIP_PAYMENT_DELETE_SUCCESS,
      payload: response.data
    });
  }
  catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put({ type: SESSION_EXPIRED });
    } else {
      yield put({
        type: SHIP_PAYMENT_DELETE_FAILED,
        payload: error.response
      });
    }
  }
}



export default function* rootShipPaymentList() {
  yield all([
    takeLatest(SHIP_PAYMENT_REQUEST, fetchShipPayment),
    takeLatest(SHIP_PAYMENT_CREATE, createShipPayment),
    takeLatest(SHIP_PAYMENT_DELETE, deleteShipPayment),
    takeLatest(SHIP_PAYMENT_EDIT, editShipPayment),
  ]);
}