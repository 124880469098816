import React, { Fragment, useMemo, useEffect, useState } from "react";
import Model from "../../../../elements/CustomModel";
import { Modal } from "react-bootstrap";
import ModelBody from "../../../../elements/ModelBody";
import ModelFooter from "../../../../elements/ModelFooter";
import { Form as BootForm } from 'react-bootstrap';
import Form from "../../../../elements/CustomForm";
import Input from "../../../../elements/CustomInput";
// import NumaricInput from "../../../../elements/NumaricInput";
import Button from "../../../../elements/CustomButton";
import { Table } from "react-bootstrap";
import Select from "../../../../elements/CustomSelect";
import { SALES_PERSON_COMMISSION_REQUEST } from "../../../../../_utils/constants/SalesPerson";
import { connect } from "react-redux";

const AddEdit = ({
  SalesPersonCategory: { salesCommission },
  auth,
  isOpen,
  size,
  hideModel,
  handleSubmit,
  validated,
  handleChange,
  state,
  salesPersonId,
  isLoading,
  storeList,
  fetchSalesCommission,
}) => {
  const [salesStoreCommission, setSalesStoreCommission] = useState([]);
  const [tmpSelectedStoreList, setTmpSelectedStoreList] = useState([]);

  useEffect(() => {
    if(salesPersonId){
      fetchSalesCommission(auth[0]?.token,salesPersonId);  
    }
  }, [salesPersonId])

  const addDisabledKey = (originalArray, comparisonArray) => {
    let tmpMatchedKey = []
    originalArray.map(item => {
        const match = comparisonArray.find(element => {
          // console.log('parseInt(element.StoreId)', parseInt(element.StoreId), item.value)
          return parseInt(element.StoreId) === item.value
        });
        if (match) {
            tmpMatchedKey.push(item.value)
        }
    });
    return tmpMatchedKey;
  }

  useEffect(() => {
    if(salesPersonId){
      let tmpCommission = salesCommission?.[0]?.SalesCommission || []
      let tmpList = addDisabledKey(storeList, tmpCommission)
      setTmpSelectedStoreList(tmpList)
      // setTmpSelectedStoreList(storeList)
      tmpCommission = tmpCommission.map(item => ({
        ...item,
        StoreId: parseInt(item.StoreId),
        Commission: parseFloat(item.Commission).toFixed(1),
        OverHead: parseFloat(item.OverHead).toFixed(1)
      }));
      setSalesStoreCommission(tmpCommission);  
    }
  }, [salesPersonId, salesCommission])

  useEffect(() => {
    let tmpList = addDisabledKey(storeList, salesStoreCommission)
    setTmpSelectedStoreList(tmpList)
  }, [salesStoreCommission])

  // const commissionData = useMemo(() => salesCommission?.[0]?.SalesCommission || [], [salesCommission]);

  // Function to handle change in input fields
  const handleSelectChange = (index, key, value) => {
    setSalesStoreCommission(prevState => {
      // Create a copy of the salesCommission array
      let updatedSalesCommission = [...prevState];

      // Check if the array doesn't have an item at the specified index
      if (!updatedSalesCommission[index]) {
        // Create a new item with default values
        updatedSalesCommission[index] = {
          "IsDeleted": false,
          StoreId: '', // Provide default value for StoreId
          Commission: '', // Provide default value for Commission
          OverHead: '' // Provide default value for OverHead
        };
      }

      // Update the value at the specified index and key
      const regex = /^\d*\.?\d{0,1}$/;
      if (key !=='StoreId') {
        if(regex.test(value)){
          updatedSalesCommission[index][key] = value;
        }
      }
      else{
        updatedSalesCommission[index][key] = parseInt(value);
      }
      
      return updatedSalesCommission;
    });

  };

  // setting store list data using memo
  const storeData = useMemo(() => {
    var stores = [];
    return stores.concat(storeList);
  }, [storeList])

  const hideModelData = () => {
    setSalesStoreCommission([])
    setTmpSelectedStoreList([])
    hideModel()
  }
  
  return (
    <Fragment>
      <Model isOpen={isOpen} dialogClassName="modal-90w">
        <Modal.Header closeButton onClick={hideModelData} className="border-0 pb-0">
          <Modal.Title>{salesPersonId ? 'Edit Sales-Person' : "Add Sales-Person"}</Modal.Title>
        </Modal.Header>

        <Form handleSubmit={e => handleSubmit(e, {userName: state.userName, salesStoreCommission})} validated={validated}>
          <ModelBody>
            <Table responsive className="no-wrap bg-border-none">
              <tbody>
                <tr>
                  <td colSpan="3"><Input label={"Name"} ids={'salesPersonCategory'} handleChange={handleChange} value={state.userName} validate={true} /></td>
                </tr>
                <tr>
                  <th className="w-100">
                    <BootForm.Label className="mb-0">Select Store</BootForm.Label>
                  </th>
                  <th>
                    <BootForm.Label className="mb-0">Commission %</BootForm.Label>
                  </th>
                  <th>
                    <BootForm.Label className="mb-0">Overhead %</BootForm.Label>
                  </th>
                </tr>
                {!_.isEmpty(storeData) ? _.map(storeList, (val, index) => (
                  <tr key={val.value}>
                    <td>
                      <Select result={storeData} ids="StoreId" validate={(index === 0 ? true : false)} value={salesStoreCommission?.[index]?.StoreId} disabled={salesStoreCommission?.[index]?.IsAssignedToCustomer} handleChange={e => handleSelectChange(index, 'StoreId', e.target.value)} optdisabled={tmpSelectedStoreList} />
                    </td>
                    <td>
                      <Input ids={`Commission`} value={salesStoreCommission?.[index]?.Commission} handleChange={e => handleSelectChange(index, 'Commission', e.target.value)} />
                    </td>
                    <td>
                      <Input ids={`OverHead`} value={salesStoreCommission?.[index]?.OverHead} handleChange={e => handleSelectChange(index, 'OverHead', e.target.value)} />
                    </td>
                  </tr>
                )) : null}
              </tbody>
            </Table>
          </ModelBody>
          <ModelFooter>
            <Button handleClick={hideModelData} variant="secondary" type="button">
              Close
            </Button>
            <Button variant="primary" type="submit">
              {isLoading ? (<div className="spinner-border"></div>) : "Save"}
            </Button>
          </ModelFooter>
        </Form>
      </Model>
    </Fragment>
  )
}

// export default AddEdit;
const mapStateToProps = ({ SalesPersonCategory }) => {
  return {
    SalesPersonCategory
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSalesCommission: (token, userId) => dispatch({ type: SALES_PERSON_COMMISSION_REQUEST, token, userId }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);