import React, { useEffect, useState } from "react";
import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit, ic_save } from 'react-icons-kit/md';
import { Table, Form, Button, InputGroup } from 'react-bootstrap';
// import { encrypted } from '../../../../_utils/helpers';
import { connect } from 'react-redux';
import Create from "./models/Create";
import ConfirmDelete from "./models/ConfirmDelete";
import {
  CARRIER_REQUEST,
  CARRIER_CREATE,
  CARRIER_DELETE,
  CARRIER_EDIT,
  CARRIER_STATE_CHANGE
} from "../../../../_utils/constants/ProductCatalog";
import Loader from "../../../layouts/Loader";
import { toast } from 'react-toastify';

const Carrier = ({
  auth: { auth, isAuthenticated },
  carrier: { valueList, isCreated, isDeleted, isEdited, isLoading },
  fetchCarrier,
  submitCarrierCreate,
  submitCarrierDelete,
  submitCarrierEdit,
  changeReducerState
}) => {

  // states declarations
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [deleteCarrierId, setDeleteCarrierId] = useState('');
  const [editCarrierId, setEditCarrierId] = useState('');
  const [editCarrier, setEditCarrier] = useState('');

  // use effect function called for authentication
  useEffect(() => {
    if (isAuthenticated) {
      fetchCarrier(auth[0].token);
    }
  }, [auth, isAuthenticated]);

  // use effect function called after api success
  useEffect(() => {
    if (isCreated === true) {
      toast.success("Carrier Created Successfully.");
      fetchCarrier(auth[0].token);
      changeReducerState();
    }
    if (isDeleted === true) {
      toast.success("Carrier Deleted Successfully.");
      fetchCarrier(auth[0].token);
      changeReducerState();
    }
    if (isEdited === true) {
      toast.success("Carrier Data Updated Successfully.");
      fetchCarrier(auth[0].token);
      changeReducerState();
    }
  }, [isCreated, isDeleted, isEdited]);

  // function to open create modal
  const showCreateModal = () => {
    setCreateModalOpen(true);
  };

  // function to close create modal
  const hideCreateModal = () => {
    setCreateModalOpen(false);
  };

  // function called for input value change of create modal
  const handleCreateModalChange = (e) => {
    setInputVal(e.target.value);
  }

  // function called for create age demographic create api
  const handleCreateModalSubmit = (e) => {
    e.preventDefault();
    if (inputVal === '') {
      toast.error("Carrier name cannot be empty.");
    } else {
      submitCarrierCreate({
        "data": {
          "Carrier": inputVal,
        },
        "token": auth[0].token
      });
      hideCreateModal();
    }
  }

  // function to open confirm delete modal
  const showConfirmDeleteModal = (id) => {
    setDeleteCarrierId(id);
    setConfirmDeleteModal(true);
  };

  // function to close confirm delete modal
  const hideConfirmDeleteModal = () => {
    setDeleteCarrierId('');
    setConfirmDeleteModal(false);
  };

  // function called for delete age demographic delete api
  const handleConfirmDeleteSubmit = () => {
    submitCarrierDelete({
      "CarrierId": deleteCarrierId,
      "token": auth[0].token
    });
    hideConfirmDeleteModal();
  }

  // function called on edit icon click
  const handleEditClick = (data) => {
    setEditCarrierId(data.carrierId);
    setEditCarrier(data.carrier);
  }

  // function called to save edited data
  const handleEditSaveClick = (data) => {
    if (editCarrier === '') {
      toast.error("Carrier name cannot be empty.");
    } else {
      submitCarrierEdit({
        "data": {
          "CarrierId": data.carrierId,
          "Carrier": editCarrier,
        },
        "token": auth[0].token
      });
      setEditCarrierId('');
      setEditCarrier('');
    }
  }

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <MainWrapper>
      <Loader loading={isLoading}>
        <div className="container">

          <div className="sub-header mt-5 mb-3">
            <h2>Carrier</h2>
            <Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
            <Create
              variant="primary"
              title={`Carrier`}
              createModalOpen={createModalOpen}
              showCreateModal={showCreateModal}
              hideCreateModal={hideCreateModal}
              inputVal={inputVal}
              handleCreateModalChange={handleCreateModalChange}
              handleCreateModalSubmit={handleCreateModalSubmit}
            />
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Carrier</th>
                <th width="50">Delete</th>
              </tr>
            </thead>
            <tbody>
              {valueList && valueList.length > 0 ? (
                valueList.map((item, index) => (
                  <tr key={index}>
                    {editCarrierId === item.carrierId ? (
                      <td>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control type="text" value={editCarrier} onChange={(evt) => setEditCarrier(evt.target.value)} />
                            <Button variant="outline-secondary">
                              <Icon size={24} icon={ic_save} onClick={() => handleEditSaveClick(item)} />
                            </Button>
                          </InputGroup>
                        </Form.Group>
                      </td>
                    ) : (
                      <td>
                        {item.carrier}
                        <Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={() => handleEditClick(item)} />
                      </td>
                    )}
                    <td>
                      <ConfirmDelete variant="primary" title={`Carrier`} showConfirmDeleteModal={() => showConfirmDeleteModal(item.carrierId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2}>
                    No Data Found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Loader>
    </MainWrapper>
  );
}

const mapStateToProps = ({ auth, carrier }) => {
  return {
    auth,
    carrier
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchCarrier: (token) => dispatch({ type: CARRIER_REQUEST, token }),
    submitCarrierCreate: (data) => dispatch({ type: CARRIER_CREATE, payload: data }),
    submitCarrierDelete: (data) => dispatch({ type: CARRIER_DELETE, payload: data }),
    submitCarrierEdit: (data) => dispatch({ type: CARRIER_EDIT, payload: data }),
    changeReducerState: () => dispatch({ type: CARRIER_STATE_CHANGE })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Carrier);