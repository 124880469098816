import '../../../../assets/css/valuelist.css';
import MainWrapper from "../../../MainWrapper";
import React, { useState, useEffect, Fragment } from "react";
import { Link, Navigate } from "react-router-dom";
import Icon from "react-icons-kit";
import { ic_arrow_back, ic_mode_edit } from 'react-icons-kit/md';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import Create from "./models/CreateFormValue";
// import Edit from "./models/EditFormValue";
import { connect } from 'react-redux';
import _ from "lodash";
import {
	FORM_VALUE_LIST_REQUEST,
	FORM_VALUE_LIST_DELETE_REQUEST,
	FORM_VALUE_LIST_CREATE_REQUEST,
	FORM_VALUE_LIST_EDIT_REQUEST
} from "../../../../_utils/constants/ValueList";
import ConfirmDelete from "./models/ConfirmDelete";
import Loader from "../../../layouts/Loader";
import { toast } from 'react-toastify';
const FormValueLists = ({
	auth: { auth, isAuthenticated },
	formValueListItem: { formValueList, isLoading, isCreated, isEdited, isDeleted },
	fetchFormValueList,
	submitFormValueDelete,
	submitFormValueCreate,
	submitFormValueEdit
}) => {
	// states declarations
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [deleteFormValueId, setDeleteFormValueId] = useState("");
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [editFormId, setEditFormId] = useState("");
	const [state, setState] = useState({

		ValueListItemHeader: "",
		ValueList: "",
		Field1: "",
		Field2: "",
		Field3: "",
		Field4: "",
		Field5: ""
	})
	// use effect function called for authentication
	useEffect(() => {
		if (isAuthenticated) {
			fetchFormValueList(auth[0].token);
		}
	}, [auth, isAuthenticated]);
	// function to open create modal
	const showCreateModal = () => {
		setCreateModalOpen(true);
	};
	// function to open create modal
	const showEditModal = (e, data) => {
		e.preventDefault();
		setEditFormId(data.formValueListId);
		setState({
			...state,
			ValueListItemHeader: data.valueListItemHeader,
			ValueList: data.valueList,
			Field1: data.field1,
			Field2: data.field2,
			Field3: data.field3,
			Field4: data.field4,
			Field5: data.field5,
		});
		setEditModalOpen(true);
	};
	// function to close create modal
	const hideCreateModal = () => {
		setCreateModalOpen(false);
	};
	// function to close create modal
	const hideEditModal = () => {
		setEditModalOpen(false);
	};
	const handleChange = ({ target: { id, value } }) => {
		setState({
			...state,
			[id]: value,
		});
	};
	// function to open confirm delete modal
	const showConfirmDeleteModal = (id) => {
		setDeleteFormValueId(id);
		setConfirmDeleteModal(true);
	};

	// function to close confirm delete modal
	const hideConfirmDeleteModal = () => {
		setDeleteFormValueId('');
		setConfirmDeleteModal(false);
	};

	// function called for delete age demographic delete api
	const handleConfirmDeleteSubmit = () => {
		submitFormValueDelete({
			"FormValueListId": deleteFormValueId,
			"token": auth[0].token
		});
		hideConfirmDeleteModal();
	}
	// use effect function called after api success
	useEffect(() => {
		if (isCreated === true) {
			toast.success("FormValue List Item Created Successfully.");
			fetchFormValueList(auth[0].token);

		}
		if (isDeleted === true) {
			toast.success("FormValue List Item Deleted Successfully.");
			fetchFormValueList(auth[0].token);

		}
		if (isEdited === true) {
			toast.success("FormValue List Item Data Updated Successfully.");
			fetchFormValueList(auth[0].token);

		}
		setState({
			...state,
			ValueListItemHeader: "",
			ValueList: "",
			Field1: "",
			Field2: "",
			Field3: "",
			Field4: "",
			Field5: ""
		});
		setEditModalOpen(false);
	}, [isCreated, isEdited, isDeleted]);

	const handleSubmit = (e) => {
		e.preventDefault();

		if (_.get(state, "ValueListItemHeader", "") === '') {
			toast.error("Value List Header cannot be empty.");
		} else {
			submitFormValueCreate({
				"data": state,
				"token": auth[0].token
			});
			hideCreateModal();
		}
	}
	// function called to save edited data
	const handleEditSaveClick = (data) => {
		if (_.get(state, "ValueListItemHeader", "") === '') {
			toast.error("Value List Header cannot be empty.");
		} else {
			submitFormValueEdit({
				"data": {
					"FormValueListId": editFormId,
					"ValueList": state.ValueList,
					"ValueListItemHeader": state.ValueListItemHeader,
					"Field1": state.Field1,
					"Field2": state.Field2,
					"Field3": state.Field3,
					"Field4": state.Field4,
					"Field5": state.Field5,
				},
				"token": auth[0].token
			});
			setEditModalOpen(false);
			hideCreateModal();
		}

	}
	if (!isAuthenticated) return <Navigate to="/login" />;
	return (
		<MainWrapper>
			<div className="container">
				<div className="sub-header mt-5 mb-3">
					<h2>Form Value List</h2>
					<Link className="btn btn-primary me-1" to="/admin"><Icon icon={ic_arrow_back} />Back to Value List</Link>
					<Create
						variant="primary"
						title={`Form Value List`}
						createModalOpen={createModalOpen}
						showCreateModal={showCreateModal}
						hideCreateModal={hideCreateModal}
						setState={setState}
						state={state}
						handleChange={handleChange}
						handleSubmit={handleSubmit}
					/>
				</div>
				<Loader loading={isLoading}>
					<Table responsive bordered>
						<thead>
							<tr>
								<th>Value List Hader</th>
								<th>Value List Type</th>
								<th>Field 1</th>
								<th>Field 2</th>
								<th>Field 3</th>
								<th>Field 4</th>
								<th>Field 5</th>
								<th width="50" className="actions">
									<div className="d-flex">
										<span>Info</span>
										<span>Delete</span>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{_.map(formValueList, (formValue, i) => (
								<tr key={i}>
									<td>{_.get(formValue, "valueList", '')}</td>
									<td>{_.get(formValue, "valueListItemHeader", '')}</td>
									<td>{_.get(formValue, "field1", '')}</td>
									<td>{_.get(formValue, "field2", '')}</td>
									<td>{_.get(formValue, "field3", '')}</td>
									<td>{_.get(formValue, "field4", '')}</td>
									<td>{_.get(formValue, "field5", '')}</td>
									<td className="actions">
										<div className="btn-group">
											<Icon role="button" className="float-end" size={24} icon={ic_mode_edit} onClick={(e) => showEditModal(e, formValue)} />

											<ConfirmDelete variant="primary" title={`FormValue List Item`} showConfirmDeleteModal={() => showConfirmDeleteModal(formValue.formValueListId)} hideConfirmDeleteModal={hideConfirmDeleteModal} handleConfirmDeleteSubmit={handleConfirmDeleteSubmit} confirmDeleteModal={confirmDeleteModal} />
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Loader>
			</div>
			<Fragment>

				<Modal show={editModalOpen} >
					<Modal.Header>Edit Form Value List</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group className="mb-3" controlId="ValueList">
								<Form.Label>Value List Type</Form.Label>
								<Form.Control type="text" name="ValueList" value={state.ValueList} onChange={handleChange} />
							</Form.Group>
							<Form.Group className="mb-3" controlId="ValueListItemHeader">
								<Form.Label>Value List Header</Form.Label>
								<Form.Control type="text" name="ValueListItemHeader" value={state.ValueListItemHeader} onChange={handleChange} />
							</Form.Group>
							<Form.Group className="mb-3" controlId="Field1">
								<Form.Label>Field 1</Form.Label>
								<Form.Control type="text" name="Field1" value={state.Field1} onChange={handleChange} />
							</Form.Group>
							<Form.Group className="mb-3" controlId="Field2">
								<Form.Label>Field 2</Form.Label>
								<Form.Control type="text" name="Field2" value={state.Field2} onChange={handleChange} />
							</Form.Group>
							<Form.Group className="mb-3" controlId="Field3">
								<Form.Label>Field 3</Form.Label>
								<Form.Control type="text" name="Field3" value={state.Field3} onChange={handleChange} />
							</Form.Group>
							<Form.Group className="mb-3" controlId="Field4">
								<Form.Label>Field 4</Form.Label>
								<Form.Control type="text" name="Field4" value={state.Field4} onChange={handleChange} />
							</Form.Group>
							<Form.Group className="mb-3" controlId="Field5">
								<Form.Label>Field 5</Form.Label>
								<Form.Control type="text" name="Field5" value={state.Field5} onChange={handleChange} />
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={hideEditModal}>
							Close
						</Button>
						<Button variant="primary" onClick={handleEditSaveClick}>
							Save
						</Button>
					</Modal.Footer>
				</Modal>
			</Fragment>
		</MainWrapper>
	)
}
const mapStateToProps = ({ auth, formValueListItem }) => {
	return {
		auth,
		formValueListItem
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchFormValueList: (token) => dispatch({ type: FORM_VALUE_LIST_REQUEST, token }),
		submitFormValueDelete: (data) => dispatch({ type: FORM_VALUE_LIST_DELETE_REQUEST, payload: data }),
		submitFormValueCreate: (data) => dispatch({ type: FORM_VALUE_LIST_CREATE_REQUEST, payload: data }),
		submitFormValueEdit: (data) => dispatch({ type: FORM_VALUE_LIST_EDIT_REQUEST, payload: data }),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(FormValueLists);